import React from "react";

const SelectCustom = ({
  label,
  name,
  id,
  options = [],
  disabled = false,
  placeHolder,
  classes,
  error,
  handleOnChange,
}) => {
  return (
    <>
      <div
        className={`tw-relative tw-mt-2 ${
          !disabled && "tw-text-gray-300"
        } tw-rounded-[1.5px] tw-shadow-sm`}
      >
        {label && (
          <label
            htmlFor={id}
            className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-primary"
          >
            {label} :
          </label>
        )}
        <div className="tw-relative tw-mt-2">
          <select
            disabled={disabled}
            id={id}
            name={name}
            onChange={handleOnChange}
            className={`tw-block tw-w-full tw-appearance-none tw-h-[34px] md:tw-h-[44px] tw-rounded-lg tw-border-0 tw-py-1.5 tw-ring-2 tw-ring-inset placeholder:tw-text-gray-300 tw-text-sm tw-leading-6 tw-outline-none focus:tw-caret-primary tw-p-4 
          ${
            disabled
              ? "tw-text-gray-400 tw-bg-gray-200"
              : "tw-text-primary tw-bg-white"
          }  
          ${
            error
              ? "tw-ring-red-300 focus:tw-ring-red-300 tw-text-red-400"
              : "tw-ring-gray-300 focus:tw-ring-primary-600 "
          }`}
          >
            {options}
          </select>
          {error && (
            <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3 tw-text-red-300 tw-pointed-events-none">
              <i
                className={`pi pi-info-circle tw-rotate-180 tw-ml-1`}
                style={{ fontSize: "1.5rem" }}
              ></i>
            </div>
          )}
        </div>
      </div>
      {error && (
        <span className="tw-ml-1 tw-text-sm tw-text-red-300">{error}</span>
      )}
    </>
  );
};

export default SelectCustom;

import React, { useEffect, useState } from "react";
import MainLayout from "../../../../layouts/MainLayout";
import Header from "../../../../components/frontv2.0/common/header/Header";
import { TabMenu } from "primereact/tabmenu";
import UserProfile from "../../../../components/frontv2.0/common/profile/UserProfile";
import UserEditProfile from "../../../../components/frontv2.0/common/profile/UserEditProfile";
import UserEditPassword from "../../../../components/frontv2.0/common/profile/UserEditPassword";
import UserEditCardPayment from "../../../../components/frontv2.0/common/profile/UserEditCardPayment";
import SubHeader from "../../../../components/frontv2.0/common/header/SubHeader";
import { getUser } from "../../../../network/auth/Auth";
import { useLocation, useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import ButtonCustom from "../../../../components/frontv2.0/common/button/ButtonCustom";

const Profile = () => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(isDesktop ? 1 : 0);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const loadData = () => {
    let user = getUser();
    setUser(user);
  };

  const onTabChange = (e) => {
    setActiveIndex(e.index);
    navigate("/workspace/profile?tab=" + e.index);
  };

  const onTabChangeMobile = (e) => {
    setActiveIndex(e);
    navigate("/workspace/profile?tab=" + e);
  };

  const items = [
    { label: "Edit Informations", icon: "pi pi-fw pi-user" },
    { label: "Change Password", icon: "pi pi-fw pi-lock" },
    { label: "Card Detail", icon: "pi pi-fw pi-credit-card" },
  ];

  const content = [
    <div key="tab1" className="sm:tw-overflow-y-auto">
      <table className="tw-pt-12 tw-text-gray-500 sm:tw-text-md tw-text-xs ">
        <tbody className="tw-space-y-10">
          <tr>
            <td className="tw-w-[110px] tw-py-2">First Name :</td>
            <td className="tw-font-bold">
              {user?.firstName ? user.firstName : ""}
            </td>
          </tr>

          <tr>
            <td className="tw-w-[110px] tw-py-2">Last Name :</td>
            <td className="tw-font-bold">{user?.name ? user.name : ""}</td>
          </tr>

          <tr>
            <td className="tw-w-[110px] tw-py-2">Email :</td>
            <td className="tw-font-bold">{user?.email ? user.email : ""}</td>
          </tr>

          <tr>
            <td className="tw-w-[110px] tw-py-2">Gender :</td>
            <td className="tw-font-bold">{user?.gender ? user.gender : ""}</td>
          </tr>

          <tr>
            <td className="tw-w-[110px] tw-py-2">Address :</td>
            <td className="tw-font-bold">
              {user?.address ? user.address : ""}
            </td>
          </tr>

          <tr>
            <td className="tw-w-[110px] tw-py-2">Cell Phone :</td>
            <td className="tw-font-bold">{user?.phone ? user.phone : ""}</td>
          </tr>

          <tr>
            <td className="tw-w-[110px] tw-py-2">City :</td>
            <td className="tw-font-bold">{user?.city ? user.city : ""}</td>
          </tr>

          <tr>
            <td className="tw-w-[110px] tw-py-2">Region :</td>
            <td className="tw-font-bold">{user?.region ? user.region : ""}</td>
          </tr>

          <tr>
            <td className="tw-w-[110px] tw-py-2">Country :</td>
            <td className="tw-font-bold">
              {user?.country ? user.country : ""}
            </td>
          </tr>

          <tr>
            <td className="tw-w-[110px] tw-py-2">Zip Code :</td>
            <td className="tw-font-bold">
              {user?.zipcode ? user.zipcode : ""}
            </td>
          </tr>
        </tbody>
      </table>
    </div>,
    <div key="tab1" className="sm:tw-px-8 tw-py-2 tw-px-[3px]">
      <UserEditProfile updateData={loadData} />
    </div>,
    <div key="tab2" className="sm:tw-px-8 tw-py-2 tw-px-[3px]">
      <UserEditPassword />
    </div>,
    <div key="tab3" className="sm:tw-px-8 tw-py-2">
      <UserEditCardPayment />
    </div>,
  ];

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (isDesktop && activeIndex > 2) {
      setActiveIndex(2);
      navigate("/workspace/profile?tab=" + 2);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    let current = queryParams.get("tab");
    if (current) {
      setActiveIndex(parseInt(current));
    }
  }, [location.search]);

  return (
    <>
      <MainLayout>
        <MainLayout.Content>
          <div className="tw-w-full tw-h-full  lg:tw-py-1 sm:tw-flex sm:tw-space-x-4">
            <div className="tw-w-full tw-h-full md:tw-px-2 tw-rounded-md lg:tw-w-1/2 lg:tw-bg-accent-gray lg:tw-p-3 md:tw-p-2 tw-overflow-y-auto">
              <UserProfile userInfos={user} loadData={loadData} />
              <div className="lg:tw-hidden tw-flex tw-space-x-2 tw-mt-2">
                <ButtonCustom
                  classes={
                    "tw-bg-primary hover:tw-bg-primary-900 tw-text-white tw-text-[11px] sm:tw-text-sm tw-flex tw-items-center tw-justify-center truncate ..."
                  }
                  label="Infos"
                  onClick={() => onTabChangeMobile(0)}
                />
                <ButtonCustom
                  classes={
                    "tw-bg-primary hover:tw-bg-primary-900 tw-text-white tw-text-[11px] sm:tw-text-sm tw-flex tw-items-center tw-justify-center truncate ..."
                  }
                  label="Profile"
                  onClick={() => onTabChangeMobile(1)}
                />
                <ButtonCustom
                  classes={
                    "tw-bg-white hover:tw-bg-primary-100 tw-text-primary tw-border tw-border-primary tw-text-[11px] sm:tw-text-sm tw-flex tw-items-center tw-justify-center truncate ..."
                  }
                  label="Password "
                  onClick={() => onTabChangeMobile(2)}
                />
                <ButtonCustom
                  classes={
                    "tw-bg-white hover:tw-bg-primary-100 tw-text-primary tw-border tw-border-primary tw-text-[11px] sm:tw-text-sm tw-flex tw-items-center tw-justify-center truncate ..."
                  }
                  label="Card"
                  onClick={() => onTabChangeMobile(3)}
                />
              </div>
              <hr className="tw-bg-gray-300 tw-h-[1px] tw-my-4" />
              <div className="lg:tw-hidden tab-content tw-block tw-overflow-hidden tw-overflow-y-auto">
                {content[activeIndex]}
              </div>
              <table className="tw-hidden tw-text-gray-500 sm:tw-text-md tw-text-xs lg:tw-block ">
                <tbody className="tw-space-y-10">
                  <tr>
                    <td className="tw-w-[110px] tw-py-2">First Name :</td>
                    <td className="tw-font-bold">
                      {user?.firstName ? user.firstName : ""}
                    </td>
                  </tr>

                  <tr>
                    <td className="tw-w-[110px] tw-py-2">Last Name :</td>
                    <td className="tw-font-bold">
                      {user?.name ? user.name : ""}
                    </td>
                  </tr>

                  <tr>
                    <td className="tw-w-[110px] tw-py-2">Email :</td>
                    <td className="tw-font-bold">
                      {user?.email ? user.email : ""}
                    </td>
                  </tr>

                  <tr>
                    <td className="tw-w-[110px] tw-py-2">Gender :</td>
                    <td className="tw-font-bold">
                      {user?.gender ? user.gender : ""}
                    </td>
                  </tr>

                  <tr>
                    <td className="tw-w-[110px] tw-py-2">Address :</td>
                    <td className="tw-font-bold">
                      {user?.address ? user.address : ""}
                    </td>
                  </tr>

                  <tr>
                    <td className="tw-w-[110px] tw-py-2">Cell Phone :</td>
                    <td className="tw-font-bold">
                      {user?.phone ? user.phone : ""}
                    </td>
                  </tr>

                  <tr>
                    <td className="tw-w-[110px] tw-py-2">City :</td>
                    <td className="tw-font-bold">
                      {user?.city ? user.city : ""}
                    </td>
                  </tr>

                  <tr>
                    <td className="tw-w-[110px] tw-py-2">Region :</td>
                    <td className="tw-font-bold">
                      {user?.region ? user.region : ""}
                    </td>
                  </tr>

                  <tr>
                    <td className="tw-w-[110px] tw-py-2">Country :</td>
                    <td className="tw-font-bold">
                      {user?.country ? user.country : ""}
                    </td>
                  </tr>

                  <tr>
                    <td className="tw-w-[110px] tw-py-2">Zip Code :</td>
                    <td className="tw-font-bold">
                      {user?.zipcode ? user.zipcode : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {isDesktop && (
              <div className="tw-hidden tw-overflow-y-auto tw-rounded-md lg:tw-w-1/2 tw-bg-accent-gray lg:tw-block">
                <div className="tw-relative tw-top-0 tw-z-10 tw-bg-accent-gray">
                  <div className="tw-p-col-12 tw-border tw-border-gray-300 tw-rounded-t-md">
                    <TabMenu
                      baseZIndex={0}
                      className="tw-rounded-t-md tw-text-xs md:tw-text-sm tw-w-full tw-z-10"
                      model={items}
                      activeIndex={activeIndex}
                      onTabChange={onTabChange}
                    />
                  </div>
                </div>
                <div className="tab-content">
                  {
                    content[
                      activeIndex > items.length
                        ? items.length - 1
                        : activeIndex + 1
                    ]
                  }
                </div>
              </div>
            )}
          </div>
        </MainLayout.Content>

        <MainLayout.Header>
          <Header />
        </MainLayout.Header>

        <MainLayout.SubHeader>
          <SubHeader
            data={[
              { value: "Home", url: "/workspace" },
              { value: "Profile", url: "#" },
            ]}
          />
        </MainLayout.SubHeader>
      </MainLayout>
    </>
  );
};

export default Profile;


import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../../../../layouts/MainLayout";
import Header from "../../../../components/frontv2.0/common/header/Header";
import SubHeader from "../../../../components/frontv2.0/common/header/SubHeader";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import UserService from "../../../../network/services/UserService";
import { useNavigate } from "react-router-dom";
import ButtonCustom from "../../../../components/frontv2.0/common/button/ButtonCustom";
import SelectCustom from "../../../../components/frontv2.0/common/form/SelectCustom";
import Alert from "../../../../components/frontv2.0/common/alert/Alert";
import { Toast } from "primereact/toast";
import Welcome from "../../../../components/frontv2.0/common/welcome/Welcome";
import Loader from "../../../../components/frontv2.0/common/loader/Loader";
import { Message } from 'primereact/message';
import moment from "moment/moment";



const AskDiscount = () => {
    /**
     * States
     */
    const stripe = useStripe();
    const elements = useElements();
    const toast = useRef(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [hasOnGoingSub, setHasOnGoingSub] = useState(false);
    const [isEligibleToDiscount, setIsEligibleToDiscount] = useState(false);
    const [sessions, setSessions] = useState([]);
    const [visible, setVisible] = useState(false);
    const [info, setInfo] = useState(null);
    const [subscriptionId, setSubscriptionId] = useState(null);
    const [disabledSelect, setDisabledSelect] = useState(false);
    const [showDateField, setShowDateField] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [sessionId, setSessionId] = useState(null);
    const cardElementRef = useRef(null);
    const [firstLoading, setFirstLoading] = useState(true);
    const [promotionEndDate, setPromotionEndDate] = useState(true);


    const handleButtonClick = () => {
        setVisible(!visible);
    };

    const showSuccess = (message) => {
        toast.current.show({
            severity: "success",
            summary: "Success",
            detail: message,
            life: 3000,
        });
    };

    const showError = (message) => {
        toast.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            life: 3000,
        });
    };

    const notification = (message, status) => {
        if (status) {
            showSuccess(message);
        } else {
            showError(message);
        }
    };
    const handleCardFocus = () => {
        const cardElement = elements.getElement(CardElement);

        if (!isEligibleToDiscount) {

            const cardElement = elements.getElement(CardElement);

            cardElement.update({ disabled: true });
        } else {
            setIsFormValid(true)
            cardElement.update({ disabled: false });
        }

        // setIsFormValid(true)
        // console.log("Card Element focused");
    };
    const handleSessionChange = (event) => {
        event.preventDefault();
        const subscriptionId = event.target.value;
        console.log(subscriptionId)
        if (subscriptionId) {
            let session = sessions.filter((e) => e.subscription_id === parseInt(subscriptionId))[0]
            console.log(session)

            if (!session.isEligibleToDiscount) {
                setIsEligibleToDiscount(false);
                setIsFormValid(false);
                setShowDateField(false);
                setDisabledSelect(false);
                return;
            }

            setSubscriptionId(subscriptionId);

            const data = {
                subscription_id: subscriptionId
            }

            UserService.getSubscribedSessionInfo(data, (response) => {
                if (response) {
                    console.log(response)
                    if (response.success) {
                        setInfo(response.data);
                        setSessionId(response.data.session_id);
                        setIsFormValid(response.data.installement_amount > 0);


                        if (response.data.installement_amount > 0) {
                            setShowDateField(event.target.value !== '');
                        }
                    } else {
                        setIsFormValid(false);
                    }
                }

                // setIsLoading(false);
            });

            
        } else {
            setInfo(null);
            setSessionId(null);
            setIsFormValid(false);
            setShowDateField(false);
        }
    };

    function checkDiscountEligibility(objects) {
        for (let i = 0; i < objects.length; i++) {
            if (!objects[i].isEligibleToDiscount) {
                return false;
            }
        }
        return true;
    }

   
      const content = (

        <h1 className="text-center mx-auto " style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div className="text-center mx-auto tw-text-primary-900" style={{ fontSize: '23px' }}>
        Take advantage of the promotion and enjoy a significant benefit valid until {promotionEndDate
                ? moment(promotionEndDate).utc().format("DD-MM-YYYY")
                : "-"}        </div>
      </h1>
    );
   

    const getAllSubscribed = () => {
        // setIsLoading(true);

        setFirstLoading(true);
        UserService.getAllSessions((response) => {
            if (response) {
                if (response.success) {
                    // console.log(response)
                    if (response?.data && response.data.length > 0) {
                        setSessions(response?.data)
                        let promotionEndDate = "";

                        response.data.forEach((item) => {
                            promotionEndDate = item.expired_per_session;
                        });

                        // console.log(promotionEndDate)
                        setPromotionEndDate(promotionEndDate)

                        let test = checkDiscountEligibility(response?.data);
                        // console.log(test)
                        if (test === true) {
                            setIsEligibleToDiscount(true);
                            setHasOnGoingSub(true);
                            setDisabledSelect(false);
                        } else {
                            setIsEligibleToDiscount(false);
                            setHasOnGoingSub(true);
                            setDisabledSelect(true);
                            setIsFormValid(false);
                        }
                    } else {
                        setHasOnGoingSub(false);
                        setIsFormValid(false);
                    }
                    setFirstLoading(false);
                    return;
                }

            }
            setIsEligibleToDiscount(false);
            setIsFormValid(false);
            setDisabledSelect(true);
            setFirstLoading(false);
        });
    };

    const initiatePayment = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const data = {
            subscription_id: subscriptionId
        };

        if (subscriptionId === null || isEligibleToDiscount === false) {
            setIsEligibleToDiscount(false);
            setIsFormValid(false);
            return;
        }

        UserService.paymentDiscount(data, (response) => {
            if (response) {
                // let confirmationData = {
                //     paymentHistoryId: parseInt(this.state.subscriptionId),
                //     amount: parseInt(this.state.amount),
                //     changeCredential : this.state.updatePaymentMethod
                // }
                const cardElement = elements.getElement(CardElement);

                cardElement.update({ disabled: true });

                confirmPaymentSuccess(
                    response.data.paymentIntentClientSecret,
                    response.data.paymentHistory
                );
            }
        });
    };

    /*This function take stripe function and contact the stripe server with user client secret to
        have the payment informations
        So if the user has paid the status will be true and the payment will be confirm to backend
        */
    const stripePaymentCheck = async (client_secret) => {
        if (!stripe || !elements) {
            //alert('Stripe.js has not yet loaded.');
            return;
        }

        const result = await stripe.confirmCardPayment(client_secret, {
            payment_method: {
                card: elements.getElement(CardElement),
            },
            setup_future_usage: "off_session",
        });

        if (result.error) {
            return {
                paymentSuccess: false,
                error: result.error.message,
                paymentMethod: "",
            };
        } else {
            return {
                paymentSuccess: true,
                error: null,
                paymentMethod: result.paymentIntent.payment_method,
            };
        }
    };

    /*When the payment has been done we have to tell the backend
        And also inform the client that his operation is correct*/
    const confirmPaymentSuccess = async (client_secret, payment_history) => {
        const { paymentSuccess, error, paymentMethod } = await stripePaymentCheck(
            client_secret
        );

        // if (!paymentSuccess && error) {
        //   notification(error, false);
        //   setLoading(false);
        //   await showLastInterval();
        //   return;
        // }
        const data = {
            paymentHistoryId: payment_history,
        };

        if (paymentSuccess) {
            const { success, message } = await UserService.confirmDiscountSuccess(data);
            setIsLoading(false);
            // console.log("payment status");
            // console.log(success, message);
            if (success === true) {
                notification("Discount has been done successfully", true);
                setTimeout(() => {
                    //navigate(`/workspace/transactions/details?sessionId=${sessionId}&subscriptionId=${subscriptionId}`)
                    navigate(`/workspace/ask-discount/success/${subscriptionId}/${sessionId}`)
                }, 2000);
                // return;
            } else {
                notification(message, false);
                navigate(`/workspace/ask-discount/success/${subscriptionId}/${sessionId}`)
            }

            //await showLastInterval();
            // this.setState({disabled: false});
            elements.getElement(CardElement).clear();
            elements.getElement(CardElement).update({ disabled: false });
            //setLoading(false);
        }
    };

    const sessionsOptions = sessions.map((value, index) => {
        const sessionsOptions = [];

        sessionsOptions.push(
            <option selected key={0} value="">
                Select a session
            </option>
        );
        if (sessions.length > 0) {
            sessions.forEach((value, index) => {
                sessionsOptions.push(
                    <option
                        //selected={sessions.length > 0 && index === 0}
                        key={index} value={value.subscription_id}>
                        {value.session_name}
                    </option>
                );
            });
        }
        return sessionsOptions;
    });

    useEffect(() => {
        getAllSubscribed();
    }, []);



    return (
        <>
            <Toast ref={toast} position="top-right" />
            <MainLayout>
                <MainLayout.Content>
                    {firstLoading ? (
                        <div
                            className="tw-flex tw-h-full tw-flex-col md:tw-justify-center tw-justify-center tw-items-center">
                            <Loader />
                        </div>
                    ) : (
                        !hasOnGoingSub ?
                            <>
                                <div className="tw-w-full tw-py-auto">
                                    <div className="sm:tw-flex">
                                        <Welcome link={"/workspace/next-sessions"} />
                                    </div>
                                </div>
                            </> :
                            <>
                                <div className="card">
                                    <Message
                                        style={{
                                            border: 'solid #696cff',
                                            borderWidth: '0 0 0 6px',
                                            color: '#696cff'
                                        }}
                                        className="border-primary w-full justify-content-start"
                                        severity="info"
                                        content={content} />
                                </div>


                                <div
                                    className="tw-w-full tw-h-full ">
                                    {/*<div className="tw-flex tw-flex-row tw-justify-center tw-items-center">*/}
                                    <div className="md:tw-w-2/3 md:tw-mx-[15%] tw-mx-0">

                                        <div className="">
                                            <form>
                                                <div className="tw-flex tw-justify-center tw-mt-2">
                                                    <h1 className="tw-font-bold tw-text-3xl">Special Offer</h1>
                                                </div>
                                                {!isEligibleToDiscount && <div className="tw-w-full tw-mb-4">
                                                    <Alert
                                                        type="danger"
                                                        message="Oops, you're not eligible for this special offer"
                                                        position="text-center" />
                                                </div>}


                                                <div className="tw-mb-4">
                                                    <label htmlFor="session"
                                                        className="tw-block tw-text-center tw-text-lg">
                                                        Which session do you want to choose?
                                                    </label>
                                                    <SelectCustom
                                                        id={"sessionchoose"}
                                                        name={"sessionchoose"}
                                                        label={""}
                                                        options={sessionsOptions}
                                                        disabled={disabledSelect}
                                                        handleOnChange={handleSessionChange} />
                                                </div>

                                                <div className="tw-mt-4">
                                                    {showDateField &&
                                                        <>
                                                            <h3 className="tw-block tw-text-center">Pay
                                                                <span
                                                                    className="tw-font-bold"> ${info?.installement_amount}</span> now
                                                                and take advantage of
                                                                <span
                                                                    className="tw-font-bold"> ${info?.discount_amount} </span> discount.
                                                            </h3>
                                                            <p className=" tw-font-bold tw-text-center">{info?.session_name} - {info?.subscription_plan_name}</p>
                                                            <div className="tw-flex tw-justify-between">
                                                                <div className="tw-w-1/3">
                                                                    <p>
                                                                        Price of the initial subscription plan <br />
                                                                        <span
                                                                            className="tw-font-bold"> ${info?.subscription_price}</span>
                                                                    </p>
                                                                </div>
                                                                <div className="tw-w-1/3">
                                                                    <h2 className="tw-text-5xl md:tw-text-7xl md:tw-mx-12 tw-text-center md:tw-mt-0 tw-mt-5"
                                                                        style={{
                                                                            //marginTop: "-4%",
                                                                            transform: 'scaleX(3)'
                                                                        }}>&#8594;</h2>
                                                                </div>
                                                                <div className="tw-w-1/3">
                                                                    <p>
                                                                        Subscription amount after discount <br />
                                                                        <span
                                                                            className="tw-font-bold"> ${3000}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </>}

                                                    <div
                                                        className="tw-block tw-justify-center tw-w-full tw-h-[50px] tw-rounded-lg tw-border-0 tw-py-3 tw-text-primary tw-ring-2 tw-ring-gray-300 tw-bg-white tw-px-4 tw-text-xs sm:tw-text-sm sm:tw-leading-6 tw-outline-none hover:tw-ring-primary-600 hover:tw-bg-gray-100 focus:tw-ring-primary-600 focus:tw-bg-gray-100 tw-ring-inset tw-mt-2">
                                                        <CardElement
                                                            options={{
                                                                style: {
                                                                    base: {
                                                                        iconColor: "#1e4e70",
                                                                        fontWeight: "400",
                                                                        fontFamily: "Poppins, Open Sans, Segoe UI, sans-serif",
                                                                        fontSize: "20px",
                                                                        fontSmoothing: "antialiased",
                                                                        ":-webkit-autofill": {
                                                                            color: "#1e4e70",
                                                                        },
                                                                    },
                                                                },
                                                            }}
                                                            onFocus={handleCardFocus}
                                                            ref={cardElementRef} />
                                                    </div>

                                                </div>


                                                <div className="tw-pt-2">
                                                    {isLoading ? (
                                                        <ButtonCustom
                                                            classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
                                                            label="Loading..."
                                                            icon={"pi pi-spin pi-spinner"}
                                                            disabled={true} />
                                                    ) : (
                                                        <ButtonCustom
                                                            classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
                                                            label="Request a Discount"
                                                            icon={"pi pi-save"}
                                                            disabled={!isFormValid}
                                                            onClick={initiatePayment} />)}
                                                </div>
                                            </form>

                                        </div>

                                    </div>
                                </div></>
                    )}
                </MainLayout.Content>
                <MainLayout.Header>
                    <Header />
                </MainLayout.Header>
                <MainLayout.SubHeader>
                    <SubHeader
                        data={[
                            { value: "Home", url: "/workspace" },
                            {
                                value: "Ask a Discount",
                                url: "#",
                            },
                        ]}
                    />
                </MainLayout.SubHeader>
            </MainLayout>
        </>
    );
};

export default AskDiscount;




import * as React from "react";
import {forwardRef, useImperativeHandle} from "react";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import TransactionService from "../../../network/services/TransactionService";

const textField = "ContactName";
const keyField = "CustomerID";

const emptyItem = {
    [textField]: "loading ..."
};
const pageSize = 10;
const loadingData = [];
while (loadingData.length < pageSize) {
    loadingData.push({
        ...emptyItem
    });
}
const baseUrl = `https://odatasampleservices.azurewebsites.net/V4/Northwind/Northwind.svc/`;
const init = {
    method: "GET",
    accept: "application/json",
    headers: []
};

const FilterComponent2 = forwardRef((props, ref) => {
    const dataCaching = React.useRef([]);
    const pendingRequest = React.useRef();
    const requestStarted = React.useRef(false);
    const [data, setData] = React.useState([]);
    const [value, setValue] = React.useState(null);
    const [total, setTotal] = React.useState(0);
    const [filter, setFilter] = React.useState("");
    const skipRef = React.useRef(0);

    const defaultItem = {
        [textField]: props.parentTitle,
        [keyField]: null
    };

    useImperativeHandle(ref, () => ({
        clear() {
            setValue(null);
        },
    }))

    // const resetCach = () => {
    //     dataCaching.current.length = 0;
    // };
    const requestData = React.useCallback((skip, filter) => {
        // if (requestStarted.current) {
        //     clearTimeout(pendingRequest.current);
        //     pendingRequest.current = setTimeout(() => {
        //         requestData(skip, filter);
        //     }, 50);
        //     return;
        // }
        // //const url = baseUrl + `Customers?$filter=contains(ContactName,'${filter}')&$skip=${skip}&$top=${pageSize}&$count=true`;
        // requestStarted.current = true;
        TransactionService.getLightSessionForFilter(filter, (data) => {
            if (data) {
                console.log("les donnes pour la session")
                console.log(data)

                const total = data.length;
                const items = [];
                data.forEach((element, index) => {
                    const {
                        id,
                        name
                    } = element;
                    const item = {
                        [keyField]: id,
                        [textField]: name
                    };
                    items.push(item);
                    //dataCaching[index + skip] = item;
                });
                if (skip === skipRef.current) {
                    setData(items);
                    setTotal(total);
                }
                //this.requestStarted = false;
            }
        });
    }, []);

    const chargeData = () => {
        setData(props.parentData);
        setTotal((props.parentData).length);
    }

    React.useEffect(() => {
        if (props.parentData) {
            chargeData();
        } else {
            requestData(0, filter);
            // return () => {
            //     resetCach();
            // };
        }

    }, [filter, requestData]);

    const onFilterChange = React.useCallback(event => {
        const filter = event.filter.value;
        // resetCach();
        requestData(0, filter);
        setData(loadingData);
        skipRef.current = 0;
        setFilter(filter);
    }, [requestData]);

    const shouldRequestData = React.useCallback(skip => {
        for (let i = 0; i < pageSize; i++) {
            if (!dataCaching.current[skip + i]) {
                return true;
            }
        }
        return false;
    }, []);

    const getCachedData = React.useCallback(skip => {
        const data = [];
        for (let i = 0; i < pageSize; i++) {
            data.push(dataCaching.current[i + skip] || {
                ...emptyItem
            });
        }
        return data;
    }, []);

    const pageChange = React.useCallback(event => {
        const newSkip = event.page.skip;
        if (shouldRequestData(newSkip)) {
            requestData(newSkip, filter);
        }
        const data = getCachedData(newSkip);
        setData(data);
        skipRef.current = newSkip;
    }, [filter, getCachedData, requestData, shouldRequestData]);

    const onChange = React.useCallback(event => {
        const value = event.target.value;
        if (value && value[textField] === emptyItem[textField]) {
            return;
        }
        setValue(value);
        props.parentCallback(value);
    }, []);

    return <DropDownList data={data} value={value}
                         onChange={onChange} dataItemKey={keyField}
                         textField={textField}
                         defaultItem={defaultItem}
        // filterable={true}
                         onFilterChange={onFilterChange} virtual={{
        pageSize: pageSize,
        skip: skipRef.current,
        total: total
    }} onPageChange={pageChange} style={{
        width: "200px"
    }}/>;
});
export default FilterComponent2;
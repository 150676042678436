import React, { useState } from "react";
import MainLayout from "../../../../layouts/MainLayout";
import Header from "../../../../components/frontv2.0/common/header/Header";
import { TabMenu } from "primereact/tabmenu";
import CurrentSession from "./tabs/CurrentSession";
import SubHeader from "../../../../components/frontv2.0/common/header/SubHeader";
import OtherSession from "./tabs/OtherSession";

const MyTransaction = () => {
  /**
   * States
   */
  const [activeIndex, setActiveIndex] = useState(0);

  /**
   * Comportments
   */
  const onTabChange = (e) => {
    setActiveIndex(e.index);
  };
  const items = [{ label: "Current" }, { label: "Other" }];

  const content = [
    <div key="tab1">
      <CurrentSession />
    </div>,
    <div key="tab2">
      <OtherSession />
    </div>,
  ];

  /**
   * Render
   */
  return (
    <>
      <MainLayout>
        <MainLayout.Content>
          <div className="tw-w-full tw-h-full ">
            <div className="tw-sticky tw-top-0 tw-z-20 tw-bg-accent-gray tw-mb-3">
              <TabMenu
                model={items}
                activeIndex={activeIndex}
                onTabChange={onTabChange}
                className="tw-text-xs md:tw-text-sm"
              />
            </div>
            <div className="tab-content tw-overflow-y-auto tw-h-[80%] tw-pr-2">{content[activeIndex]}</div>
          </div>
        </MainLayout.Content>

        <MainLayout.Header>
          <Header />
        </MainLayout.Header>

        <MainLayout.SubHeader>
          <SubHeader
            data={[
              { value: "Home", url: "/workspace" },
              {
                value: "My Transaction",
                url: "/workspace/transactions",
              },
            ]}
          />
        </MainLayout.SubHeader>
      </MainLayout>
    </>
  );
};

export default MyTransaction;

import AdminFooter from "../../footer/AdminFooter";
import React, {useEffect, useState} from "react";
import UserService from "../../../../network/services/UserService";

const CourseStatistics = () => {

    const [showStatistics, setShowStatistics] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [total, setTotal] = useState(null);
    const [statistics, setStatistics] = useState([]);

    const collapseStatistics = () => {
        setShowStatistics(!showStatistics);
    }

    const getCourseStatistics = () => {
        const queryParams = new URLSearchParams(window.location.search);
        let id = queryParams.get('courseId');

        setIsLoading(true);

        let data = {
            courseId: id,
        }

        UserService.showCourseStatistics(data, (response) => {
            if (response) {
                setResponse(response);
                setStatistics(response.statistics);
                setTotal(response.total);
            }
            setIsLoading(false)
        });
    }

    useEffect(() => {
        getCourseStatistics();
    }, []);

    return (
        <>
            <div className="row mb-5 mt-0">
                <div className="col-12">
                    <nav
                        className="navbar navbar-expand-lg blur border-radius-lg top-0 z-index-3 shadow position-absolute mt-4 py-2 start-0 end-0 mx-4">
                        <div className="container-fluid ps-2 pe-0">
                            <a className="navbar-brand font-weight-bolder ms-lg-0 ms-3 "
                               href="#">
                                Statistics
                            </a>
                            <button className="navbar-toggler shadow-none ms-2" type="button"
                                    data-bs-toggle="collapse" data-bs-target="#navigation2"
                                    aria-controls="navigation" aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    onClick={collapseStatistics}
                            >
                                {!showStatistics ? "open" : "close"}
                            </button>
                            <div className="collapse navbar-collapse" id="navigation2">
                                <ul className="navbar-nav mx-auto">
                                    {/*<li className="nav-item">*/}
                                    {/*    <a className="nav-link d-flex align-items-center me-2 active"*/}
                                    {/*       aria-current="page" href="../pages/dashboard.html">*/}
                                    {/*        <i className="fa fa-chart-pie opacity-6 text-dark me-1"></i>*/}
                                    {/*        Dashboard*/}
                                    {/*    </a>*/}
                                    {/*</li>*/}
                                    {/*<li className="nav-item">*/}
                                    {/*    <a className="nav-link me-2" href="../pages/profile.html">*/}
                                    {/*        <i className="fa fa-user opacity-6 text-dark me-1"></i>*/}
                                    {/*        Profile*/}
                                    {/*    </a>*/}
                                    {/*</li>*/}
                                    {/*<li className="nav-item">*/}
                                    {/*    <a className="nav-link me-2" href="../pages/sign-up.html">*/}
                                    {/*        <i className="fas fa-user-circle opacity-6 text-dark me-1"></i>*/}
                                    {/*        Sign Up*/}
                                    {/*    </a>*/}
                                    {/*</li>*/}
                                    {/*<li className="nav-item">*/}
                                    {/*    <a className="nav-link me-2" href="../pages/sign-in.html">*/}
                                    {/*        <i className="fas fa-key opacity-6 text-dark me-1"></i>*/}
                                    {/*        Sign In*/}
                                    {/*    </a>*/}
                                    {/*</li>*/}
                                </ul>
                                <ul className="navbar-nav d-lg-block d-none">
                                    <li className="nav-item">
                                                    <span className="material-icons" style={{
                                                        cursor: 'pointer'
                                                    }} onClick={collapseStatistics}>
                                                        {!showStatistics ? "expand_more" : "expand_less"}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
            <div className="container-fluid py-4">

                {showStatistics &&
                    <div className="row mt-4">
                        <div className="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                            <div className="card">
                                <div className="card-header p-3 pt-2">
                                    <div
                                        className="icon icon-lg icon-shape bg-gradient-info shadow-info text-center border-radius-xl mt-n4 position-absolute">
                                        <i className="material-icons opacity-10">table_chart</i>
                                    </div>

                                    <div className="text-end pt-1">
                                        <table className="table align-items-center mb-0">

                                            <thead>
                                            <tr>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                                                    colSpan="2">All plans {
                                                    isLoading ? (
                                                            <div className="spinner-border text-primary"
                                                                 role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>

                                                        ) :
                                                        null
                                                }
                                                </th>
                                            </tr>
                                            </thead>

                                            <tbody>

                                            <tr>
                                                <td>
                                                    <p className="text-sm mb-0 text-capitalize">all
                                                        users </p>
                                                </td>
                                                <td>
                                                    <p className="text-xs font-weight-bold ">{total.numberOfStudents}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="text-sm mb-0 text-capitalize">Total paid
                                                        amount </p>
                                                </td>
                                                <td>
                                                    <p className="text-xs font-weight-bold mb-0">{total.actualPaidAmount}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="text-sm mb-0 text-capitalize">Total
                                                        expected
                                                        amount </p>
                                                </td>
                                                <td>
                                                    <p className="text-xs font-weight-bold mb-0">{total.expectedAmount}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <p className="text-sm mb-0 text-capitalize">Total
                                                        remaining
                                                        amount </p>
                                                </td>
                                                <td>
                                                    <p className="text-xs font-weight-bold mb-0">{total.remainingAmount}</p>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {statistics && statistics.map((plan) => (
                            <div className="col-xl-3 col-sm-6 mb-xl-0 " key={plan.id}>
                                <div className="card">
                                    <div className="card-header p-3 pt-2">
                                        <div
                                            className="icon icon-lg icon-shape bg-gradient-success shadow-success text-center border-radius-xl mt-n4 position-absolute">
                                            <i className="material-icons opacity-10">pie_chart</i>
                                        </div>
                                        <div className="text-end pt-1">
                                            <table className="table align-items-center mb-0">

                                                <thead>
                                                <tr>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                                                        colSpan="2">{plan.subscriptionPlanName}</th>

                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        <p className="text-sm mb-0 text-capitalize">number
                                                            of
                                                            users </p>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">{plan.numberOfStudents}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="text-sm mb-0 text-capitalize">Total
                                                            paid
                                                            amount </p>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">{plan.actualPaidAmount}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="text-sm mb-0 text-capitalize">Total
                                                            expected
                                                            amount </p>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">{plan.expectedAmount}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p className="text-sm mb-0 text-capitalize">Total
                                                            remaining amount </p>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">{plan.remainingAmount}</p>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>}
                <AdminFooter/>
            </div>
        </>
    )
}
export default CourseStatistics;
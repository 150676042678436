import React, {Component, useEffect, useState} from "react";
import Sidebar from "../sidebar/Sidebar";
import AdminFooter from "../footer/AdminFooter";
import moment from "moment";
import ReactPaginate from "react-paginate";
import AdminHeader from "../header/AdminHeader";
import TransactionService from "../../../network/services/TransactionService";
import {useRef} from "react";
import FilterComponent2 from "./FilterComponent";

const SimpleTransaction = () => {
    

    const [resultPaymentHistory, setResultPaymentHistory] = useState([]);
    const [resultUser, setResultUser] = useState('');
    const [result2, setResult2] = useState([]);
    const [success, setSuccess] = useState('');
    const [userEmail, setUserEmail] = useState('');  
    const [researchIndicator, setResearchIndicator] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    


    useEffect(() => {
        filter();
        userdetails();
    }, []);

    const filter = () => {
        const queryParams = new URLSearchParams(window.location.search);
        let userEmail = queryParams.get('userEmail');
        console.log(userEmail);
        setUserEmail(userEmail);
        const data = {
            search_value: userEmail,
        }
        
        setIsLoading(true);
        TransactionService.searchTransaction(data, (response) => {
           
            
                if (response) {
                    if (response.success == true) {
                   
                    setResultPaymentHistory(response.result);
                    setResultUser(response.user);
                 
                    }
                   else {                 
                    setResultPaymentHistory();
                    setResultUser();
                    }
                }
            setIsLoading(false);
            // this.setState({isLoading: false, isSend: false, isClear: false})
        });
    }

    const userdetails = () => {
        const queryParams = new URLSearchParams(window.location.search);
        let userEmail = queryParams.get('userEmail');
        const data = {
            search_value: userEmail,
        }
        console.log(data);
        console.log(userEmail);
        TransactionService.searchUserTransaction(data, (response) => {                   
                if (response) {
                    if (response.success == true) {
                   
                    setResult2(response.result);
       
                    }
                }
            
        });
    }

   
    return (
        <div>
            <>
                <Sidebar/>
                <main className="mainAdmin r ms-100">
                    <AdminHeader location="Transactions / Support / Details"/>

                    <div className="container-fluid py-4">
                        <div className="row">
                            <div className="col-lg-12 col-md-10 mx-auto">
                                <div className="card ">
                                    <div className="card-body p-3">
                                    
                                
                            


                                       <div className="container-fluid py-4">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-10 mx-auto">
                                                <div className="item-icon-title">
                                            
                                                    <div className="item-title">
                                                        
                                                        <h6>
                                                            <i className="fa fa-circle text-primary"
                                                            aria-hidden="true"></i>
                                                                <span></span>  User details
                                                        </h6>
                                                    </div>
                                                </div>
                                    <div className="card mt-4">
                                        <div className="card-header p-3">
                                            <div className="card-body p-3">
                                                <p className="text-sm mb-0">
                                                    <table className="table align-items-center mb-0">
                                                        <tbody>
                                                        <tr>
                                                            <td>Email</td>
                                                            <td>
                                                                <p className="text-xs font-weight-bold mb-0">{resultUser.email}</p>
                                                            </td>

                                                            <td>Phone number</td>
                                                            <td>
                                                                <p className="text-xs font-weight-bold mb-0">{resultUser.phoneNumber}</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Full name</td>
                                                            <td>
                                                                <p className="text-xs font-weight-bold mb-0">
                                                                {resultUser.name}
                                                                </p>
                                                            </td>
                                                            <td>City</td>
                                                            <td>
                                                                <p className="text-xs font-weight-bold mb-0">{resultUser.city}</p>
                                                            </td>
                                                            
                                                        </tr>
                                                        
                                                        <tr>
                                                            <td>Country</td>
                                                            <td>
                                                                <p className="text-xs font-weight-bold mb-0">{resultUser.country}</p>
                                                            </td>
                                                            <td>Join on</td>
                                                            <td>
                                                                <p className="text-xs font-weight-bold mb-0">
                                                                {moment(resultUser.createdAt).utc().format('DD-MM-YYYY HH:mm')}
                                                                   </p>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>

                                                </p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div> 
                            </div> 
                            <br></br>

                            <div className="item-icon-title">
                               
                               <div className="item-title">
                                   
                                   <h6>
                                       <i className="fa fa-circle text-primary"
                                       aria-hidden="true"></i>
                                         <span></span>  Payment history
                                   </h6>
                                </div>
                            </div>
                            <br></br>
                                        <div className="table-responsive p-0">
                                        
                                           
                                        <table className="table align-items-center mb-0">
                                                <thead>
                                                <tr>
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Product
                                                        name
                                                    </th>
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Total subscription amount</th>
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Installment plan</th> 
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Access type</th>
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Access</th>
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Transaction amount</th>
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Transaction status</th>                  
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Payment
                                                        Date
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody> 
                                                {
                                                    resultPaymentHistory && resultPaymentHistory.map((order) => (
                                                        
                                                        
                                                        <tr key={order.id}>
                                                            <td className="align-middle text-center text-sm">
                                                                    <span
                                                                        className="text-xs font-weight-bold">
                                                                            {order.paymentHistory.sessionName != "" ?
                                                                    <span
                                                                        className="text-xs font-weight-bold"> {order.sessionName} </span>
                                                                        :
                                                                      <span></span> 
                                                                } 
                                                                {order.paymentHistory.courseName != "" ?
                                                                    <span
                                                                        className="text-xs font-weight-bold"> {order.courseName} </span>
                                                                        :
                                                                      <span></span> 
                                                                }  </span>
                                                            </td>
                                                            <td className="align-middle text-center text-sm">
                                                                    <span
                                                                        className="text-xs font-weight-bold"> {order.price} </span>
                                                            </td>     
                                                            <td className="align-middle text-center text-sm">
                                                                {order.subscriptionPlan.numberInstallment} 
                                                            </td>   
                                                            <td className="align-middle text-center text-sm">
                                                                <span
                                                                        className="text-xs font-weight-bold"> 
                                                                        {order.accessType === 'NORMAL' && <span
                                                                        className="badge badge-success"> normal
                                                                    </span>}
                                                                    {order.accessType === 'MANUAL' && <span
                                                                        className="badge badge-warning">manual</span>}
                                                                        {order.accessType === 'CANCELLED' && <span
                                                                        className="badge badge-warning">canceled</span>}
                                                                </span>
                                                            </td>
                                                            <td className="align-middle text-center text-sm">
                                                                {order.isInGoodStanding == true ?
                                                                                    <span style={{
                                                                                        backgroundColor: "#e7f3eb",
                                                                                        borderRadius: "5px",
                                                                                        fontWeight: "500",
                                                                                        color: "#309255",
                                                                                        padding: "3px 12px"
                                                                                    }}>Access to course</span>
                                                                                    :
                                                                                    <span style={{
                                                                                        backgroundColor: "#e12b6b",
                                                                                        borderRadius: "5px",
                                                                                        fontWeight: " 500",
                                                                                        color: "#ffffff",
                                                                                        padding: "3px 12px",
                                                                                    }}>No access to course</span>
                                                                }
                                                            </td>
                                                            <td className="align-middle text-center text-sm">
                                                            {
                                                             order.paymentHistory && order.paymentHistory.map((paymentHistoryAmount) => 
                                                               <p>
                                                                    <span
                                                                    className="text-xs font-weight-bold"> {paymentHistoryAmount.transactionAmount} </span>
                                                                </p>     
                                                            )}  
                                                            </td>  
                                                            <td className="align-middle text-center text-sm">   
                                                            {
                                                             order.paymentHistory && order.paymentHistory.map((paymentHistoryStatus) => 
                                                             
                                                                <p>
                                                                    {paymentHistoryStatus.transactionStatus === 'succeed' && <span
                                                                        className="badge badge-success"> succeeded
                                                                    </span>}
                                                                    {paymentHistoryStatus.transactionStatus === 'fail' && <span
                                                                        className="badge badge-pill badge-danger">failed</span>}
                                                                    {paymentHistoryStatus.transactionStatus === 'refunded' && <span
                                                                        className="badge badge-pill badge-warning">refunded</span>}
                                                                </p>
                                                                   
                                                            )}  
                                                            </td>  
                                                            <td className="align-middle text-center text-sm">
                                                            {
                                                             order.paymentHistory && order.paymentHistory.map((paymentHistoryDate) => 
                                                               <p>
                                                                <span
                                                                className="text-xs font-weight-bold"> {moment(paymentHistoryDate.transactionDate).utc().format('DD-MM-YYYY HH:mm:ss')} </span>
                                                               </p> 
                                                            )} 
                                                             </td> 
                                                        </tr>
                                                    ))
                                                }
                                                </tbody>
                                            </table>  
                                        
                                                                                  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col">
                                {isLoading ? (
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>

                                    ) :
                                    null}
                            </div>
                           
                        </div>
                        <AdminFooter/>
                    </div>
                 
                    
                </main>
            </>
        </div>
    )

}

export default SimpleTransaction;
import {MOTIF_LOGIN} from "../constants/images";

const AuthLayout = (props) => {

    /**
     * States
     */
    const {children} = props;

    /**
     * Comportments
     */
    const illustration = Array.isArray(children)
        ? children.find(child => child.type === Illustration)
        : children.type === Illustration
            ? children
            : null;
    const body = Array.isArray(children)
        ? children.find(child => child.type === Body)
        : children.type === Body
            ? children
            : null;

    /**
     * States
     */
    return (
        <div className="tw-flex tw-flex-row tw-h-screen tw-text-white tw-w-full">
            <div className="tw-w-full tw-h-full lg:tw-w-1/3" style={{backgroundImage: `url(${MOTIF_LOGIN})`}}>
                {body
                    ? body.props.children
                    : "Please define the AuthLayout.Body slot in this component"}
            </div>
            <div className="tw-hidden lg:tw-block lg:tw-w-2/3">
                {illustration
                    ? illustration.props.children
                    : "Please define the AuthLayout.Illustration slot in this component"}
            </div>
        </div>
    );
};

// SubComponents to simulate slots
const Body = () => null;
const Illustration = () => null;

// We introduce the subcomponents in the current component
AuthLayout.Body = Body;
AuthLayout.Illustration = Illustration;

export default AuthLayout;
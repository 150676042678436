import React, {Component} from "react";
import {Link} from "react-router-dom";
import {isAuthenticated, logout} from "../../network/auth/Auth";
import backendUrls from "../../network/urls/backendUrls";

import './header.css';
import {logoutRedirectUrl} from "../../constants/Constants";

class LinkBar extends Component {

    constructor(props) {
        super(props);

    }

    disconnect(e) {
        // e.preventDefault();
        // logout()
        // window.location.reload();
        e.preventDefault();
        logoutRedirectUrl();
    }

    render() {
        return (
            <>

                <div className="header-main">
                    <div className="container">

                        <div className="header-main-wrapper bordermenu heightmenu">

                            <div className="header-logo ">
                                <a href={backendUrls.Wordpress.root}><img src="assets/images/logo2.png" width={156}
                                                                          height={35} alt="Logo"/></a>
                            </div>

                            <div className="header-menu d-none d-lg-block">
                                <ul className="nav-menu">

                                </ul>

                            </div>

                            <div className="header-sign-in-up d-lg-block d-none">
                                <br></br>
                                {isAuthenticated() ?
                                    (<ul>
                                        <li><Link className="sign-up signup " to="/workspace/mysessions">My
                                            account</Link></li>
                                    </ul>) :
                                    (<ul>
                                        <li><Link className="sign-up signup " to="/login">Login</Link></li>
                                        <li><Link className="sign-up signin " to="/register">Sign Up</Link></li>
                                    </ul>)}

                            </div>

                            <div className="header-toggle d-lg-none">

                                
                            

                                <span className="nav-item ">
                                <a className="nav-link menu-toggle dropdown-toggle" href="#" role="button"
                                   id="dropdownenuLink" data-bs-display="static" data-bs-toggle="dropdown"
                                   aria-expanded="false">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </a>
                            
                                
                                <ul className="nav-menu dropdown-menu dro dropdown-menu-end dropdown-large">
                                   
                                  
                                    {isAuthenticated() ?
                                        (<>
                                            <li><Link className="sign-up dropdown-item" to="/workspace/mysessions">My
                                                account</Link></li>
                                            <li>
                                                <a onClick={this.disconnect.bind(this)} className="dropdown-item"
                                                   href="#" data-toggle="modal" data-target="#logoutModal">
                                                    Logout
                                                </a>
                                            </li>
                                        </>) :
                                        (<>
                                            <li><Link className="sign-up dropdown-item" to="/login">Login</Link></li>
                                            <li><Link className="sign-up dropdown-item" to="/register">SignUp</Link>
                                            </li>
                                        </>)}
                                </ul>

                           
                                </span>

                            </div>


                        </div>

                    </div>

                </div>

                <div className="mobile-menu">

                    <a className="menu-close" href="javascript:void(0)">
                        <i className="icofont-close-line"></i>
                    </a>

                    <div className="mobile-top">
                        <p><i className="flaticon-phone-call"></i> <a href="tel:9702621413">(970) 262-1413</a></p>
                        <p><i className="flaticon-email"></i> <a href="mailto:address@gmail.com">address@gmail.com</a>
                        </p>
                    </div>

                    <div className="mobile-sign-in-up">
                        <ul>
                            <li><a className="sign-in" href="login.html">Sign In</a></li>
                            <li><a className="sign-up" href="register.html">Sign Up</a></li>
                        </ul>
                    </div>

                    <div className="mobile-menu-items">
                        <ul className="nav-menu">
                            <li><a href="index.html">Home</a></li>
                            <li>
                                <a href="#">All Course</a>
                                <ul className="sub-menu">
                                    <li><a href="courses.html">Courses</a></li>
                                    <li><a href="courses-details.html">Courses Details</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="#">Pages </a>
                                <ul className="sub-menu">
                                    <li><a href="about.html">About</a></li>
                                    <li><a href="register.html">Register</a></li>
                                    <li><a href="login.html">Login</a></li>
                                    <li><a href="faq.html">FAQ</a></li>
                                    <li><a href="404-error.html">404 Error</a></li>
                                    <li><a href="after-enroll.html">After Enroll</a></li>
                                    <li><a href="courses-admin.html">Instructor Dashboard (Course List)</a></li>
                                    <li><a href="overview.html">Instructor Dashboard (Performance)</a></li>
                                    <li><a href="students.html">Students</a></li>
                                    <li><a href="reviews.html">Reviews</a></li>
                                    <li><a href="engagement.html">Course engagement</a></li>
                                    <li><a href="traffic-conversion.html">Traffic & conversion</a></li>
                                    <li><a href="messages.html">Messages</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="#">Blog</a>
                                <ul className="sub-menu">
                                    <li>
                                        <a href="#">Blog</a>
                                        <ul className="sub-menu">
                                            <li><a href="blog-grid.html">Blog</a></li>
                                            <li><a href="blog-left-sidebar.html">Blog Left Sidebar</a></li>
                                            <li><a href="blog-right-sidebar.html">Blog Right Sidebar</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="#">Blog Details</a>
                                        <ul className="sub-menu">
                                            <li><a href="blog-details-left-sidebar.html">Blog Details Left Sidebar</a>
                                            </li>
                                            <li><a href="blog-details-right-sidebar.html">Blog Details Right Sidebar</a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li><a href="contact.html">Contact</a></li>
                        </ul>

                    </div>

                    <div className="mobile-social">
                        <ul className="social">
                            <li><a href="#"><i className="flaticon-facebook"></i></a></li>
                            <li><a href="#"><i className="flaticon-twitter"></i></a></li>
                            <li><a href="#"><i className="flaticon-skype"></i></a></li>
                            <li><a href="#"><i className="flaticon-instagram"></i></a></li>
                        </ul>
                    </div>

                </div>
                <div className="overlay"></div>

            </>
        )
    }
}

export default LinkBar;
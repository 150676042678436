import React, { useEffect, useState } from "react";
import SubscriptionPlan from "../subscriptions/SubscriptionPlan";
import { IMAGE_2, MOTIF_4 } from "../../../../constants/images";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { MEDIA_URL_ACCOUNTING } from "../../../../network/urls/backendUrls";

const SessionDetails = ({ data, checkoutLink }) => {
  /**
   * States
   */
  const navigate = useNavigate();
  const [pricesPlan, setPricesPlan] = useState([]);

  /**
   * Comportments
   */
  const handleClick = (planId) => {
    navigate(checkoutLink + "/" + data.id + "/" + planId);
  };

  const encodeUrl = (url) => {
    return url.replace(/ /g, "%20");
  };

  useEffect(() => {
    let allSubscriptionPlanPrices = [];
    data.subscriptionPlans &&
      data.subscriptionPlans.length > 0 &&
      data.subscriptionPlans.map((item, index) => {
        return allSubscriptionPlanPrices.push(item.price);
      });
    setPricesPlan(allSubscriptionPlanPrices);
  }, [data]);

  /**
   * Render
   */
  return (
    <>
      <div className="tw-items-center tw-justify-center tw-w-full tw-py-3">
        <div className="lg:tw-flex-row lg:tw-flex ">
          <img
            className="tw-shadow-md tw-object-cover tw-object-center lg:tw-w-1/2 tw-w-full tw-max-h-[280px] tw-rounded-[12px] "
            src={
              data.image === null
                ? IMAGE_2
                : encodeUrl(`${MEDIA_URL_ACCOUNTING}/sessions/${data.image}`)
            }
            alt="image_6"
            height={100}
          />
          <div className="lg:tw-ml-[25px] tw-h-[full] tw-mt-8 lg:tw-w-1/2 tw-px-3 lg:tw-mt-0 sm:tw-bg-accent-gray tw-py-[16px] lg:tw-px-[26px] tw-rounded-[12px]">
            <div className="tw-flex tw-justify-between tw-w-full tw-text-primary ">
              <div className="tw-flex tw-flex-col tw-text-lg">
                <span className="tw-font-bold lg:tw-text-[22px] tw-mb-1">
                  {data.name}
                </span>
                <span className="tw-text-sm lg:tw-text-md">
                  {/* Devops Expert Training */}
                  {data.title !== "" ? data.title : ""}
                </span>
              </div>
              <div
                className={`tw-flex justify-end ${
                  data.openStatus === "OPENED"
                    ? "tw-text-green-600"
                    : "tw-text-primary"
                }  tw-text-[16px]`}
              >
                <div className="tw-flex tw-items-center">
                    <span
                      className={`tw-mr-[6px] pi  ${
                        data.openStatus === "OPENED"
                          ? "pi-lock-open"
                          : "pi-lock"
                      } `}
                    ></span>
                    <span>
                      {data.openStatus === "OPENED" ? "Opened" : "Closed"}
                    </span>
                </div>
              </div>
            </div>
            <div className="tw-flex tw-flex-col tw-w-full md:tw-items-center lg:tw-flex-row">
              <div className="tw-flex tw-flex-col tw-pt-4 tw-text-sm tw-text-primary">
                <div className="tw-flex tw-items-center">
                  <span className="tw-mr-[6px] pi pi-calendar tw-text-green-700"></span>
                  <span>
                    Start date :{" "}
                    {moment(data?.startDate).format("MMMM Do, YYYY") ??
                      "23, March 2023"}
                  </span>
                </div>

                <div className="tw-flex tw-items-center">
                  <span className="tw-mr-[6px] pi pi-calendar tw-tw-text-primary"></span>
                  <span>
                    End date :{" "}
                    {moment(data?.endDate).format("MMMM Do, YYYY") ??
                      "23, March 2023"}
                  </span>
                </div>
              </div>
            </div>
            <div className="tw-text-primary tw-mt-2">
              <h1 className="tw-text-lg tw-font-bold">Description</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.description ? data.description : "",
                }}
              />
            </div>
          </div>
        </div>

        <div
          className="tw-flex tw-font-bold tw-items-center tw-justify-center tw-font-semibold tw-text-center tw-h-20 md:tw-text-[32px] tw-text-[24px]  tw-my-[32px] tw-bg-center tw-bg-no-repeat tw-bg-cover"
          style={{ background: `url(${MOTIF_4})` }}
        >
          <span
            className="tw-text-primary"
            style={{
              fontFamily: "Advent Pro",
            }}
          >
            Choose Your &nbsp;
          </span>
          <span
            className="tw-text-green-700"
            style={{
              fontFamily: "Advent Pro",
            }}
          >
            {" "}
            Payment Option
          </span>
        </div>

        <div className="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 lg:tw-grid-cols-3">
          {data.subscriptionPlans && data.subscriptionPlans.length > 0 ? (
            data.subscriptionPlans.map((item, index) => {
              return (
                  <SubscriptionPlan
                    index={index}
                    type={`${
                      item.numberInstallment === 6
                        ? "classic"
                        : item.numberInstallment === 3
                        ? "medium"
                        : "premium"
                    }`}
                    onClick={() => handleClick(item.id)}
                    data={item}
                    sessionName={data.name}
                    allPricesPlan={pricesPlan}
                  />
              );
            })
          ) : (
            <p>Subscription plan not available</p>
          )}
        </div>
      </div>
    </>
  );
};

export default SessionDetails;

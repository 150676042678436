import React, {Component} from "react";
import PageBanner from "../common/PageBanner";

class NotFound extends Component {
    render() {
        return (
            <>
                <PageBanner location="Not Found"/>
                <div className="section section-padding mt-n10">
                    <div className="container">

                        <div className="error-wrapper">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="error-images">
                                        <img src="assets/images/error.png" alt="AlreadySubscripePage"/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="error-content">
                                        <h5 className="sub-title">This Page is Not Found.</h5>
                                        <h2 className="main-title">We are very sorry for error. We <span> can’t find this</span> page.
                                        </h2>
                                        <p>It has survived not only five centuries but also the leap into electronic
                                            typesetting.</p>
                                        <a href="index.html" className="btn btn-primary btn-hover-dark">Back To
                                            Home</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }
}

export default NotFound;
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SessionService from '../../../../network/services/SessionService';
import moment from 'moment';
import RefundingService from "../../../../network/services/RefundService";
import SubscriptionService from "../../../../network/services/SubscriptionService";
import ButtonCustom from "../../../frontv2.0/common/button/ButtonCustom"
import InputCustom from "../../../frontv2.0/common/form/InputCustom";
import { Dialog } from 'primereact/dialog';
import { Toast } from "primereact/toast";
import { createRef } from 'react';
import { Message } from 'primereact/message';


export default class Other extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: '',
            sessionId: '',
            value: '',
            valueAmount: '',
            message: '',
            showInfo: false,
            isMatchingId: '',
            visible: false,
            subscriptionId: '',
            paymentHistoryId: '',
            payment_status: [],
            payment_date: [],
            sources: [],
            size: '',
            date_already_paid: [],
            next_payment_date: [],
            installment_total_paginate1: [],
            //installment_total_paginate2: [],
            // installment_total_paginate3: [],
            transaction_details: [],
            installment_refunded: '',
            accessType: '',

            isLoading: false,
            collapseExample1: false,
            collapseExample2: false,
            collapseExample3: false,
            collapseExample4: false,
            collapseExample5: false,
            isRefunding: false,
            isRefundingTransaction: false,
            isRefundingAndCanceling: false,
            cancelingMessage: 'Restoring...',
            successMessage: false,
            isCancelable: false,
            isRefundableAndCancelable: false,
            isSynchronizing: false,
            deleteMessage: false,
            hasBeenCanceled: false,
            lastPage: 1,
            pageItem: 6,

        }
        this.toast = createRef();
        this.msgs = createRef();
    }

    collapseExample = (index) => {
        this.setState({
            collapseExample1: index === 'collapseExample1',
            collapseExample2: index === 'collapseExample2',
            collapseExample3: index === 'collapseExample3',
            collapseExample4: index === 'collapseExample4',
            collapseExample5: index === 'collapseExample5'
        })
    }

    showPayHist = () => {
        const queryParams = new URLSearchParams(window.location.search);
        let sessionId = queryParams.get('sessionId');
        let userId = queryParams.get('userId');
        SessionService.getStatutPayment(sessionId, userId, (data) => {
            if (data) {
                this.setState({ payment_status: data });
                // console.log('lobject du backend');
                // console.log(data);
            }
        });
    }

    showPayDate = () => {
        this.setState({ isLoading: true })
        const queryParams = new URLSearchParams(window.location.search);
        let sessionId = queryParams.get('sessionId');
        let userId = queryParams.get('userId');
        SessionService.getDatePaymentSecureAdmin(sessionId, userId, (data) => {
            if (data) {
                console.log(data)
                // console.log("Les data du fail and other ")
                console.log(data.transactionDetails)
                //alert(JSON.stringify(data));
                this.props.parentCallbackData(data.transactionDetails[0]);
                this.setState({
                    subscriptionId: data.subscriptionId,
                    accessType: data.access_type,
                    installment_refunded: (data.transactionDetails[0].installment_refunded).length,
                    payment_refunded: data.transactionDetails[0].installment_refunded,
                    hasBeenCanceled: data.transactionDetails[0].price_total === data.transactionDetails[0].remaining,
                    sources: data.transactionDetails[0].source,
                    size: data.transactionDetails[0].source.length,
                });
                if (data.difference) {
                    if (data.difference.length > 0) {
                        console.log(data)

                        this.setState({

                            date_already_paid: data.dateAlreadyPaid,
                            next_payment_date: data.difference,
                            installment_done: data.transactionDetails[0].installment_done,
                            installment_total: data.transactionDetails[0].installment_total,
                            installment_total_paginate1: (data.difference).slice(this.state.lastPage, this.state.pageItem),
                            //installment_total_paginate2: (data.difference).slice((this.state.lastPage + this.state.pageItem), (this.state.pageItem + this.state.pageItem)),
                            //installment_total_paginate3: (data.difference).slice((this.state.lastPage + this.state.pageItem + this.state.pageItem), (this.state.pageItem + this.state.pageItem + this.state.pageItem)),

                            installment_remaining: data.transactionDetails[0].installment_remaining,
                            installment_fail: data.transactionDetails[0].installment_fail,
                            total_pay: data.transactionDetails[0].total_paid,
                            remaining: data.transactionDetails[0].remaining,

                            price_total: data.transactionDetails[0].price_total,
                            //remaining_payment_date:moment.utc(new Date(data.difference[0].date)).format('Y-m-d')
                            remaining_payment_date: moment(data.difference[0].date).utc().format("L"),
                            lastPage: (data.difference).length / this.state.pageItem

                        });
                    } else {
                        // console.log("no")
                        this.setState({
                            date_already_paid: data.dateAlreadyPaid,
                            installment_done: data.transactionDetails[0].installment_done,
                            installment_total: data.transactionDetails[0].installment_total,
                            // installment_total_paginate: (data.transactionDetails[0].installment_total).slice(this.state.lastPage, this.state.pageItem),
                            installment_remaining: data.transactionDetails[0].installment_remaining,
                            total_pay: data.transactionDetails[0].total_paid,
                            remaining: data.transactionDetails[0].remaining,
                            price_total: data.transactionDetails[0].price_total,
                        });
                    }
                }
            }
            this.setState({ isLoading: false })
        });
    }




    showHistoryFail = () => {
        this.setState({ isLoading: true })
        const queryParams = new URLSearchParams(window.location.search);
        let sessionId = queryParams.get('sessionId');
        let userId = queryParams.get('userId');
        SessionService.getHistoryFailSecureAdmin(sessionId, userId, (data) => {
            if (data) {

                this.setState({
                    installment_fail: data.paymentHistoryFail.length,
                    payment_fail: data.paymentHistoryFail,

                });
            }
            this.setState({ isLoading: false })
        });
    }

    saveSessionIdToRemove = (e) => {

        const id = e.target.getAttribute("id");
        this.setState({ subscriptionId: id })
        console.log(id)

    }



    handleCancelAndRefundClick = () => {
        console.log("Le bouton Cancel and refund the subscription a été cliqué !");

        const dateAlreadyPaid = this.state.date_already_paid
        const hasNonNullSource = dateAlreadyPaid.some(objet => objet.source !== null);

        console.log(hasNonNullSource);
        this.setState({ showInfo: hasNonNullSource })
        return hasNonNullSource;

    }

    savePaymentHistoryIdToRefund = (e) => {

        const id = parseInt(e.target.getAttribute("id"), 10);
        console.log(id)

        console.log(this.state.date_already_paid)
        const dateAlreadyPaid = this.state.date_already_paid || [];
        const isMatchingId = dateAlreadyPaid.some(item => item.id === id && item.source !== null);
        console.log('isMatchingId:', isMatchingId);

        console.log("voici mon id " + id)
        this.setState({ showInfo: isMatchingId })
        this.setState({ paymentHistoryId: id })
    }


    refundPayment = async () => {

        this.setState({ isRefundingTransaction: true })

        const id = this.state.paymentHistoryId;

        console.log(id)
        const toSend = {
            subscriptionId: null,
            paymentHistoryId: id
        }
        console.log(toSend)
        const response = await RefundingService.refunding(toSend);

        if (response) {

            // console.log("la reponse")
            // console.log(response)

            if (response.success) {
                const data = response.refunds[0];
                // console.log(data);
                this.setState({
                    isRefundingTransaction: false,
                    successMessage: true,
                    message: 'The transaction has been successfully refunded'
                });
                await this.showPayDate();
                await this.showHistoryFail();
                this.props.parentCallbackCheck();
            }
        }
    }

    refundSubscription = async () => {

        this.setState({ isRefundingAndCanceling: true })

        const id = this.state.subscriptionId;

        // console.log('voici le id ' + id);

        const toSend = {
            subscriptionId: id,
            paymentHistoryId: null
        }

        const response = await RefundingService.refunding(toSend);

        if (response) {
            if (response.success) {
                const data = response.refunds[0];
                this.setState({
                    isRefundingAndCanceling: false,
                    successMessage: true,
                    isRefundableAndCancelable: false,
                    isCancelable: false,
                    message: 'The subscription has been successfully refunded and canceled'
                });
                this.onTrigger();
                await this.showPayDate();
                await this.showHistoryFail();
            }
        }
    }

    cancelSubscription = async () => {

        this.setState({ isRefundingAndCanceling: true })

        const id = this.state.subscriptionId;

        // console.log('voici le id ' + id);

        const toSend = {
            subscriptionId: id,
            paymentHistoryId: null
        }

        const response = await RefundingService.cancel(toSend);
        if (response) {
            // console.log("la reponse")
            // console.log(response)
            if (response.success) {
                this.setState({
                    isRefundingAndCanceling: false,
                    successMessage: true,
                    isRefundableAndCancelable: false,
                    isCancelable: false,
                    message: response.message
                });
                this.onTrigger();
                await this.showPayDate();
                await this.showHistoryFail();
            }
        }
    }

    manualSynchronization = () => {

        this.setState({ isSynchronizing: true })

        const id = this.state.subscriptionId;
        SubscriptionService.subscriptionSynchronization(id, (data) => {
            if (data) {
                if (data.success) {
                    this.setState({
                        message: "The subscription has been successfully synchronized",
                        isSynchronizing: false,
                        successMessage: true,
                    });
                    return;
                }
                this.setState({
                    deleteMessageContent: "Synchronization failed",
                    isSynchronizing: false,
                    deleteMessage: true,
                });
            }
        });

    }

    onTrigger = () => {
        this.props.parentCallback();
    }

    componentWillMount() {
        //this.showPayHist();
        this.showPayDate();
        this.showHistoryFail();
    }

    onHide = () => {
        this.setState({ visible: false });
        this.resetForm();
    };


    handleButtonClick = () => {
        this.setState({ visible: true });
    };

    onValueChange = (e) => {
        const value = e.target.value;
        console.log(value);
        this.setState({ value });

        // if (value === "maximum reschedule date") {
        //   this.setState({ rescheduleDateSelected: true });
        // } else if (value === "maximum discount date") {
        //   this.setState({ rescheduleDateSelected: false });
        // }
    }

    onValueAmountChange = (e) => {
        const valueAmount = e.target.value;
        console.log(valueAmount);
        if (valueAmount >= 0) {
            this.setState({ valueAmount: valueAmount });
        }
        // this.setState({ valueAmount });

        // if (value === "maximum reschedule date") {
        //   this.setState({ rescheduleDateSelected: true });
        // } else if (value === "maximum discount date") {
        //   this.setState({ rescheduleDateSelected: false });
        // }
    }


    showSuccess = (message) => {
        this.toast.current.show({
            severity: "success",
            summary: "Success",
            detail: message,
            life: 3000,
        });
    };

    showError = (message) => {
        this.toast.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            life: 3000,
        });
    };

    resetForm = () => {
        this.setState({
            value: "",
            valueAmount: ""
        });
    }



    onOptionClick = (value) => {
        console.log(value)
        this.setState({ value });
    }

    CreateManualHistoryToUser = () => {
        const data = {
            subscriptionId: this.state.subscriptionId,
            total_amount_paid: this.state.valueAmount,
            source: this.state.value

        }
        console.log(data)
        SessionService.CreateHistoryManually(data, (response) => {
            if (response) {
                if (response.success) {
                    this.setState({
                        message: response.message

                    });
                    this.showSuccess(response.message)
                    console.log("voici les fails installments ")
                    console.log(response)

                } else {
                    this.setState({
                        message: response.message

                    });
                    this.showError(response.message)
                }

            }
            this.showPayDate();
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.resetForm();
        this.onHide();

    };







    render() {
        // const msgs = createRef();

        const content = (
            // <div className="flex items-center justify-center">
            //     <div className=" tw-text-primary-900" style={{  textAlign: 'center' }}>

            //         <h1 className=""> You are about to refund a payment made outside of the payment platform. If you proceed, you will need to refund using your own means</h1>

            //     </div>
            // </div>
            <div className="flex items-center justify-center">
                <div className="tw-text-primary-900" style={{ fontSize: "18px", textAlign: 'center' }}>
                    <h1 className="" >
                        You are about to initiate a refund for one or more payments. If you proceed with this operation, only the payments made through the payment platform will be returned to the student. Payments made outside of the platform must be refunded using the original payment method by which they were initiated.
                    </h1>
                </div>
            </div>
        );
        const { showInfo } = this.state;
        const msgs = createRef();
        const { visible } = this.state;
        const { value } = this.state;
        const handlePageClick = (data) => {
            this.setState({
                isLoading: true
            });

            const pageUrl = data.selected + 1;

            const indexOfLastRecord = pageUrl * this.state.pageItem;
            const indexOfFirstRecord = indexOfLastRecord - this.state.pageItem;

            //const end = this.state.pageItem * pageUrl;
            //const start = (this.state.pageItem + pageUrl) - this.state.pageItem;

            // console.log("debut et fin " + indexOfFirstRecord + " " + indexOfLastRecord)
            const lastPage = Math.ceil(this.state.installment_total / this.state.pageItem);

            this.setState({
                installment_total_paginate: (this.state.next_payment_date).slice(indexOfFirstRecord, indexOfLastRecord),
                lastPage: lastPage,
                isLoading: false
            })
        }
        return (
            <>
                <Toast ref={this.toast} position="top-right" />

                <div className="row">

                    {/* <div className="card">
                        <Messages ref={msgs} />
                    </div> */}



                    <div className="col-lg-12 col-md-10 mx-auto">
                        <div className="card mt-4">
                            <div className="card-header p-3">
                                <div className="section-padding mt-n6">
                                    <div className="container">
                                        <div className="about-items-wrapper">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="about-item" style={{ border: "0" }}>
                                                        <p>
                                                            {this.state.accessType === "CANCELLED" ?

                                                                this.state.hasBeenCanceled === true ?
                                                                    <center>
                                                                        <h3 className="title bg-gradient-warning text-white">This
                                                                            subscription has been refunded and
                                                                            canceled</h3>
                                                                    </center> :
                                                                    <center><h3
                                                                        className="title bg-gradient-warning text-white">This
                                                                        subscription has been canceled</h3>
                                                                    </center> : ''

                                                            }


                                                            <div>

                                                                <Dialog
                                                                    header="Set up the history for this user."
                                                                    visible={visible}
                                                                    style={{
                                                                        width: '54vw',
                                                                        height: '54vh',
                                                                        marginLeft: '10vh',
                                                                        marginTop: "-10vh"
                                                                    }}
                                                                    onHide={this.onHide}
                                                                    onSubmit={this.handleSubmit}

                                                                >
                                                                    <hr />
                                                                    <form>

                                                                        <div className="tw-mb-0">
                                                                            <h3 className="tw-block tw-font-bold tw-text-primary-800 mt-3 tw-text-center tw-text-xl">

                                                                                Create a payment history for this user
                                                                            </h3>
                                                                        </div>

                                                                        <div style={{
                                                                            marginTop: "10px",
                                                                            width: "700px"
                                                                        }}>
                                                                            <span>Payment source</span>
                                                                            <div style={{ marginTop: "px" }}>

                                                                                <InputCustom
                                                                                    id="valueSelect"
                                                                                    name="Payment source"
                                                                                    placeholder="Payment source"
                                                                                    onChange={this.onValueChange}
                                                                                    value={this.state.value}
                                                                                    type="text"

                                                                                />
                                                                            </div>
                                                                            <span>Amount</span>

                                                                            <div style={{ marginBottom: "10px" }}>
                                                                                <InputCustom
                                                                                    id="valueSelect"
                                                                                    name="Amount"
                                                                                    placeholder="Amount"
                                                                                    onChange={this.onValueAmountChange}
                                                                                    value={this.state.valueAmount}
                                                                                    type="number"
                                                                                    min="1" // Set the minimum value to 0
                                                                                />
                                                                            </div>


                                                                            <ButtonCustom

                                                                                classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white "}
                                                                                label="Save"
                                                                                icon={"pi pi-sync"}
                                                                                onClick={this.CreateManualHistoryToUser}
                                                                                disabled={this.state.value === "" || this.state.valueAmount === ""}

                                                                            />
                                                                        </div>
                                                                    </form>
                                                                </Dialog>

                                                            </div>



                                                        </p><br />
                                                        <div className="row">
                                                            <div className="col-4">
                                                                <div className="item-icon-title fs-4">
                                                                    <div className="item-icon">
                                                                        <i className="flaticon-tutor"></i>
                                                                    </div>
                                                                    <div className="item-title">
                                                                        <h3 className="title">Payment history</h3>
                                                                    </div>
                                                                </div>
                                                            </div>



                                                            <div className="col-3">
                                                                {this.state.isSynchronizing && (
                                                                    <div>
                                                                        <div className="spinner-border text-primary" role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                        Synchronization...
                                                                    </div>
                                                                )}
                                                            </div>


                                                            <div className="tw-flex tw-justify-end ">
                                                                <div className="tw-col-span-0 tw-hidden lg:tw-block">
                                                                    <ButtonCustom
                                                                        onClick={this.handleButtonClick}
                                                                        classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
                                                                        label="ADD HISTORY FOR THIS USER"
                                                                    />
                                                                </div>
                                                            </div>


                                                            <div className="col-3">
                                                                <div className="item-icon-title">
                                                                    <button className="btn btn-success" type="button" disabled={this.state.isSynchronizing} onClick={this.manualSynchronization}>
                                                                        Launch manual synchronization
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p>
                                                            <table className="table align-items-center mb-0">
                                                                <tr>
                                                                    <td style={{ width: "70%" }}>
                                                                        <i className="fa fa-calculator text-primary"
                                                                            aria-hidden="true"></i>
                                                                        Installments
                                                                    </td>
                                                                    <td style={{ width: "15%" }}>
                                                                        <span
                                                                            className="font-weight-bold">{this.state.installment_total}</span>
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            href="javascript:document.getElementById('plan').scrollIntoView(true);">
                                                                            <button className="btn btn-primary"
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    this.collapseExample('collapseExample1');
                                                                                }}
                                                                            >
                                                                                Details here
                                                                            </button>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <i className="fa fa-check text-success"
                                                                            aria-hidden="true"></i>
                                                                        Successful installments
                                                                    </td>
                                                                    <td>
                                                                        <span
                                                                            className="font-weight-bold">{this.state.installment_done}</span>
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            href="javascript:document.getElementById('plan').scrollIntoView(true);">
                                                                            <button className="btn btn-primary"
                                                                                type="button"
                                                                                onClick={() => this.collapseExample('collapseExample2')}
                                                                            >
                                                                                Details here
                                                                            </button>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <i className="fa fa-plus-circle text-warning"
                                                                            aria-hidden="true"></i>
                                                                        Refunded installments
                                                                    </td>
                                                                    <td>
                                                                        <span
                                                                            className="font-weight-bold">{this.state.installment_refunded}</span>
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            href="javascript:document.getElementById('plan').scrollIntoView(true);">
                                                                            <button className="btn btn-primary"
                                                                                type="button"
                                                                                onClick={() => this.collapseExample('collapseExample3')}
                                                                            >
                                                                                Details here
                                                                            </button>
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <i className="fa fa-close text-danger"
                                                                            aria-hidden="true"></i>
                                                                        Failed transactions
                                                                    </td>
                                                                    <td>
                                                                        <span
                                                                            className="font-weight-bold">{this.state.installment_fail}</span>
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            href="javascript:document.getElementById('plan').scrollIntoView(true);">
                                                                            <button className="btn btn-primary"
                                                                                type="button"
                                                                                onClick={() => this.collapseExample('collapseExample4')}
                                                                            >
                                                                                Details here
                                                                            </button>
                                                                        </a>
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        {/* <i className="fa fa-check text-success"
                                                                            aria-hidden="true"></i> */}
                                                                        <i className="fa fa-check  fa-hand-paper-o" aria-hidden="true"></i>
                                                                        Manual transactions

                                                                    </td>
                                                                    <td>
                                                                        <span
                                                                            className="font-weight-bold">{this.state.size}</span>
                                                                    </td>
                                                                    <td>
                                                                        <a
                                                                            href="javascript:document.getElementById('plan').scrollIntoView(true);">
                                                                            <button className="btn btn-primary"
                                                                                type="button"
                                                                                onClick={() => this.collapseExample('collapseExample5')}
                                                                            >
                                                                                Details here
                                                                            </button>
                                                                        </a>
                                                                    </td>
                                                                </tr>

                                                            </table>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="card-body p-3">
                            <div className="row">
                                {this.state.collapseExample1 && <div>
                                    <div className="card card-body" id="plan">
                                        <div className="row mb-4">
                                            <div className="about-items-wrapper">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="about-item" style={{ border: "0" }}>
                                                            <div className="item-icon-title">
                                                                <div className="item-icon">
                                                                    <i className="fa fa-calculator text-primary"
                                                                        aria-hidden="true" />
                                                                </div>
                                                                <div className="item-title">
                                                                    <h3 className="title">Payment information</h3>
                                                                </div>
                                                            </div>
                                                            <p>
                                                                <div className="card">
                                                                    <div className="card-header pb-0">
                                                                        <div className="row">
                                                                            <div className="col-lg-12 col-12">
                                                                                <h6>Payment information</h6> {
                                                                                    this.state.isLoading ? (
                                                                                        <div
                                                                                            className="spinner-border text-primary"
                                                                                            role="status">
                                                                                            <span
                                                                                                className="sr-only">Loading...</span>
                                                                                        </div>

                                                                                    ) :
                                                                                        null
                                                                                }
                                                                                <span className="text-sm mb-0">
                                                                                    <i className="material-icons opacity-10 text-success">credit_score</i>
                                                                                    <span className="design-text-s"> Payment done<span
                                                                                        className="font-weight-bold ms-1"> {this.state.installment_done}</span></span>
                                                                                </span>
                                                                                <span className="text-sm mb-0">
                                                                                    <i className="material-icons opacity-10 text-danger">credit_card_off</i>
                                                                                    <span className="design-text-s"> Remaining payment<span
                                                                                        className="font-weight-bold ms-1">{this.state.installment_remaining}</span></span>
                                                                                </span>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body px-0 pb-2">
                                                                        <div className="timeline timeline-one-side">

                                                                            {this.state.date_already_paid && this.state.date_already_paid.map((date_already_paid, index) => (
                                                                                <div className="timeline-block mb-3"
                                                                                    key={index}>
                                                                                    <span className="timeline-step">
                                                                                        <i className="material-icons text-success text-gradient">credit_score</i>
                                                                                    </span>
                                                                                    <div
                                                                                        className="timeline-content">
                                                                                        <h6 className="text-dark text-sm font-weight-bold mb-0">${date_already_paid.amount}</h6>
                                                                                        <p className="mb-0"> Installment <span
                                                                                            className="badge badge-sm bg-gradient-success">{index + 1}</span>
                                                                                        </p>
                                                                                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">{moment(date_already_paid.date).utc().format('DD  MMMM  YYYY') + ' at ' + moment.utc(date_already_paid.date).format("HH:mm")}</p>
                                                                                    </div>
                                                                                </div>
                                                                            ))}

                                                                            {/*{this.state.next_payment_date && this.state.next_payment_date.map((next_payment_date, index) => (*/}
                                                                            <div className="row">
                                                                                <div className="col-lg-12">
                                                                                    {/*{this.state.installment_total_paginate1 && this.state.installment_total_paginate1.map((next_payment_date, index) => (*/}
                                                                                    {this.state.next_payment_date && this.state.next_payment_date.map((next_payment_date, index) => (

                                                                                        <div
                                                                                            className="timeline-block mb-3"
                                                                                            key={index}>
                                                                                            <span
                                                                                                className="timeline-step">
                                                                                                <i className="material-icons text-danger text-gradient">credit_card_off</i>
                                                                                            </span>
                                                                                            <div
                                                                                                className="timeline-content">
                                                                                                <h6 className="text-dark text-sm font-weight-bold mb-0">Next
                                                                                                    payment
                                                                                                    date</h6>
                                                                                                <p className="mb-0"> price: <span
                                                                                                    className="badge badge-sm bg-gradient-info">${next_payment_date.amount}</span>
                                                                                                </p>
                                                                                                <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">{moment.utc((next_payment_date.date)).format('DD  MMMM  YYYY') + ' at ' + moment.utc(next_payment_date.date).format("HH:mm")} </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/*<p>*/}
                                                                {/*    <div className="card-footer py-4">*/}
                                                                {/*        <nav aria-label="...">*/}
                                                                {/*            <ReactPaginate*/}
                                                                {/*                previousLabel={"<"}*/}
                                                                {/*                nextLabel={">"}*/}
                                                                {/*                breakLabel={'...'}*/}
                                                                {/*                pageCount={this.state.lastPage}*/}
                                                                {/*                marginPagesDisplayed={3}*/}
                                                                {/*                pageRangeDisplayed={6}*/}
                                                                {/*                onPageChange={handlePageClick}*/}
                                                                {/*                containerClassName={"pagination justify-content-end mb-0"}*/}
                                                                {/*                pageClassName={"page-item"}*/}
                                                                {/*                previousClassName={"page-item"}*/}
                                                                {/*                previousLinkClassName={"page-link"}*/}
                                                                {/*                pageLinkClassName={"page-link"}*/}
                                                                {/*                nextClassName={"page-item"}*/}
                                                                {/*                nextLinkClassName={"page-link"}*/}
                                                                {/*                breakClassName={"page-item"}*/}
                                                                {/*                breakLinkClassName={"page-link"}*/}
                                                                {/*                activeClassName={"active"}*/}

                                                                {/*            />*/}
                                                                {/*        </nav>*/}
                                                                {/*    </div>*/}
                                                                {/*</p>*/}
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>}
                                {this.state.collapseExample2 && <div>
                                    <div className="card card-body" id="plan">
                                        <div className="row mb-4">
                                            <div className="about-items-wrapper">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="about-item" style={{ border: "0" }}>
                                                            <div className="item-icon-title">
                                                                <div className="item-icon">
                                                                    <i className="fa fa-check text-success"
                                                                        aria-hidden="true"></i>
                                                                </div>
                                                                <div className="item-title">
                                                                    <h3 className="title">Payment history</h3>
                                                                </div>
                                                            </div>
                                                            <p>
                                                                <div className="card h-100">
                                                                    <div className="card-header pb-0">
                                                                        <h6>Payment history</h6> {
                                                                            this.state.isLoading ? (
                                                                                <div
                                                                                    className="spinner-border text-primary"
                                                                                    role="status">
                                                                                    <span
                                                                                        className="sr-only">Loading...</span>
                                                                                </div>

                                                                            ) :
                                                                                null
                                                                        }
                                                                        <p className="text-sm">

                                                                            <i className="fa fa-check text-success"
                                                                                aria-hidden="true"></i>
                                                                            <span
                                                                                className="font-weight-bold">{this.state.installment_done}/{this.state.installment_total}</span> Payment
                                                                            done
                                                                        </p>
                                                                        {this.state.isRefundingTransaction &&
                                                                            <div>
                                                                                <div
                                                                                    className="spinner-border text-primary"
                                                                                    role="status">
                                                                                    <span
                                                                                        className="sr-only">Loading...</span>

                                                                                </div>
                                                                                Refunding...
                                                                            </div>

                                                                        }
                                                                    </div>
                                                                    <div className="card-body p-3">
                                                                        <div className="timeline timeline-one-side">

                                                                            {this.state.date_already_paid && this.state.date_already_paid.map((date_already_paid, index) => (
                                                                                <div className="row">
                                                                                    <div className="col-lg-6">
                                                                                        <div
                                                                                            className="timeline-block mb-3"
                                                                                            key={index}>
                                                                                            <span
                                                                                                className="timeline-step">
                                                                                                <i className="material-icons text-success text-gradient">credit_score</i>
                                                                                            </span>
                                                                                            <div
                                                                                                className="timeline-content">
                                                                                                <h6 className="text-dark text-sm font-weight-bold mb-0">{date_already_paid.amount}$</h6>
                                                                                                <p className="mb-0"> Installment <span
                                                                                                    className="badge badge-sm bg-gradient-success">{index + 1}</span>
                                                                                                </p>
                                                                                                <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">{moment(date_already_paid.date).utc().format('DD  MMMM  YYYY') + ' at ' + moment.utc(date_already_paid.date).format("HH:mm")}</p>

                                                                                                <h6 className="text-dark text-sm font-weight-bold mb-0 mt-1">{date_already_paid.source}</h6>

                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                    {this.state.accessType !== "CANCELLED" &&
                                                                                        <div className="col-lg-6">
                                                                                            <button
                                                                                                className="btn btn-primary"
                                                                                                type="button"
                                                                                                id={date_already_paid.id}
                                                                                                disabled={this.state.isRefundingTransaction}
                                                                                                onClick={this.savePaymentHistoryIdToRefund}
                                                                                                style={{ cursor: 'pointer' }}
                                                                                                data-bs-toggle="modal"
                                                                                                data-bs-target="#confirmationRefundPaymentHistory"
                                                                                            >
                                                                                                Refund this
                                                                                                transaction
                                                                                            </button>

                                                                                        </div>}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>}
                                {this.state.collapseExample3 && <div>
                                    <div className="card card-body" id="plan">
                                        <div className="row mb-4">
                                            <div
                                                className="about-items-wrapper">
                                                <div className="row">
                                                    <div
                                                        className="col-lg-12">
                                                        <div
                                                            className="about-item"
                                                            style={{ border: "0" }}>
                                                            <div
                                                                className="item-icon-title">
                                                                <div
                                                                    className="item-icon">
                                                                    <i className="fa fa-plus-circle text-warning"
                                                                        aria-hidden="true"></i>
                                                                </div>
                                                                <div
                                                                    className="item-title">
                                                                    <h3 className="title">Payment
                                                                        refunded</h3>
                                                                </div>
                                                            </div>
                                                            <p>
                                                                <div
                                                                    className="card h-100">
                                                                    <div
                                                                        className="card-header pb-0">
                                                                        <h6>Payment
                                                                            refunded</h6> {
                                                                            this.state.isLoading ? (
                                                                                <div
                                                                                    className="spinner-border text-primary"
                                                                                    role="status">
                                                                                    <span
                                                                                        className="sr-only">Loading...</span>
                                                                                </div>

                                                                            ) :
                                                                                null
                                                                        }
                                                                        <p className="text-sm">

                                                                            <i className="fa fa-plus-circle text-warning"
                                                                                aria-hidden="true"></i>
                                                                            <span
                                                                                className="font-weight-bold">{this.state.installment_refunded}</span> Payment
                                                                            refunded
                                                                        </p>
                                                                    </div>
                                                                    <div
                                                                        className="card-body p-3">
                                                                        <div
                                                                            className="timeline timeline-one-side">
                                                                            {this.state.payment_refunded && this.state.payment_refunded.map((payment_fail, index) => (
                                                                                <div
                                                                                    className="timeline-block mb-3"
                                                                                    key={index}>
                                                                                    <span className="timeline-step">
                                                                                        <i className="fa fa-plus-circle text-warning"></i>
                                                                                    </span>
                                                                                    <div
                                                                                        className="timeline-content">
                                                                                        <h6 className="text-dark text-sm font-weight-bold mb-0">{payment_fail.amount}$</h6>
                                                                                        <p className="mb-0"> Installment <span
                                                                                            className="badge badge-sm bg-gradient-success">{index + 1}</span>
                                                                                        </p>
                                                                                        <h6 className="text-dark text-sm font-weight-bold mb-0">{payment_fail.source}</h6>


                                                                                        <p className="text-secondary font-weight-bold text-xs mt-1 mb-0"> {moment(payment_fail.paymentDate).utc().format('DD  MMMM  YYYY') + ' at ' + moment.utc(payment_fail.paymentDate).format("HH:mm")}</p>
                                                                                    </div>
                                                                                </div>
                                                                            ))}


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>}
                                {this.state.collapseExample4 && <div>
                                    <div className="card card-body" id="plan">
                                        <div className="row mb-4">
                                            <div
                                                className="about-items-wrapper">
                                                <div className="row">
                                                    <div
                                                        className="col-lg-12">
                                                        <div
                                                            className="about-item"
                                                            style={{ border: "0" }}>
                                                            <div
                                                                className="item-icon-title">
                                                                <div
                                                                    className="item-icon">
                                                                    <i className="fa fa-close text-danger"
                                                                        aria-hidden="true"></i>
                                                                </div>
                                                                <div
                                                                    className="item-title">
                                                                    <h3 className="title">Payment
                                                                        failed</h3>
                                                                </div>
                                                            </div>
                                                            <p>
                                                                <div
                                                                    className="card h-100">
                                                                    <div
                                                                        className="card-header pb-0">
                                                                        <h6>Payment
                                                                            failed</h6> {
                                                                            this.state.isLoading ? (
                                                                                <div
                                                                                    className="spinner-border text-primary"
                                                                                    role="status">
                                                                                    <span
                                                                                        className="sr-only">Loading...</span>
                                                                                </div>

                                                                            ) :
                                                                                null
                                                                        }
                                                                        <p className="text-sm">

                                                                            <i className="fa fa-close text-danger"
                                                                                aria-hidden="true"></i>
                                                                            <span
                                                                                className="font-weight-bold">{this.state.installment_fail}</span> Payment
                                                                            failed
                                                                        </p>
                                                                    </div>
                                                                    <div
                                                                        className="card-body p-3">
                                                                        <div
                                                                            className="timeline timeline-one-side">
                                                                            {this.state.payment_fail && this.state.payment_fail.map((payment_fail, index) => (
                                                                                <div
                                                                                    className="timeline-block mb-3"
                                                                                    key={index}>
                                                                                    <span className="timeline-step">
                                                                                        <i className="material-icons text-danger text-gradient">clear</i>
                                                                                    </span>
                                                                                    <div
                                                                                        className="timeline-content">
                                                                                        <h6 className="text-dark text-sm font-weight-bold mb-0">{payment_fail.amount}$</h6>
                                                                                        <p className="mb-0"> Installment <span
                                                                                            className="badge badge-sm bg-gradient-success">{index + 1}</span>
                                                                                        </p>
                                                                                        <p className="text-secondary font-weight-bold text-xl mt-1 mb-0">Charge
                                                                                            failure code : <span
                                                                                                className="text-primary text-xl font-weight-bold mb-0">{payment_fail.stripe_charge_failure_code}</span>
                                                                                        </p>
                                                                                        <p className="text-secondary font-weight-bold text-xl mt-1 mb-0">Payment
                                                                                            intent decline code
                                                                                            : <span
                                                                                                className="text-primary text-xl font-weight-bold mb-0">{payment_fail.stripe_payment_intent_decline_code} </span>
                                                                                        </p>
                                                                                        <p className="text-secondary font-weight-bold text-xl mt-1 mb-0">Charge
                                                                                            failure message : <span
                                                                                                className="text-primary text-xl font-weight-bold mb-0">{payment_fail.stripe_charge_failure_message} </span>
                                                                                        </p>
                                                                                        <p className="text-secondary font-weight-bold text-xl mt-1 mb-0">Charge
                                                                                            outcome type : <span
                                                                                                className="text-primary text-xl font-weight-bold mb-0">{payment_fail.stripe_charge_outcome_type} </span>
                                                                                        </p>
                                                                                        <p className="text-secondary font-weight-bold text-xl mt-1 mb-0">Charge
                                                                                            outcome network status
                                                                                            : <span
                                                                                                className="text-primary text-xl font-weight-bold mb-0">{payment_fail.stripe_charge_outcome_network_status}</span>
                                                                                        </p>
                                                                                        <p className="text-secondary font-weight-bold text-xl mt-1 mb-0">Charge
                                                                                            outcome seller message
                                                                                            : <span
                                                                                                className="text-primary text-xl font-weight-bold mb-0">{payment_fail.stripe_charge_outcome_seller_message}</span>
                                                                                        </p>
                                                                                        <p className="text-secondary font-weight-bold text-xl mt-1 mb-0">Payment
                                                                                            date : <span
                                                                                                className="text-primary text-xl font-weight-bold mb-0">{moment(payment_fail.paymentDate).utc().format('DD  MMMM  YYYY') + ' at ' + moment.utc(payment_fail.paymentDate).format("HH:mm")}</span>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>}


                                {this.state.collapseExample5 && <div>
                                    <div className="card card-body" id="plan">
                                        <div className="row mb-4">
                                            <div
                                                className="about-items-wrapper">
                                                <div className="row">
                                                    <div
                                                        className="col-lg-12">
                                                        <div
                                                            className="about-item"
                                                            style={{ border: "0" }}>
                                                            <div
                                                                className="item-icon-title">
                                                                <div
                                                                    className="item-icon">
                                                                    <i className="fa fa-check text-success"
                                                                        aria-hidden="true"></i>
                                                                </div>
                                                                <div
                                                                    className="item-title">
                                                                    <h3 className="title">Payment
                                                                        Outside of system</h3>
                                                                </div>
                                                            </div>
                                                            <p>
                                                                <div
                                                                    className="card h-100">

                                                                    <div
                                                                        className="card-header pb-0">
                                                                        <h6>Payment made outside the system</h6> {
                                                                            this.state.isLoading ? (
                                                                                <div
                                                                                    className="spinner-border text-primary"
                                                                                    role="status">
                                                                                    <span
                                                                                        className="sr-only">Loading...</span>
                                                                                </div>

                                                                            ) :
                                                                                null
                                                                        }


                                                                        <div
                                                                            className="card-body p-3">


                                                                            <div className="timeline timeline-one-side">
                                                                                {this.state.sources && this.state.sources
                                                                                    .filter(source => source.source !== "Biling plateform" && source.source !== null)
                                                                                    .map((sources, index) => (
                                                                                        <div className="timeline-block mb-3" key={index}>
                                                                                            <span className="timeline-step">
                                                                                                <i className="material-icons text-success text-gradient">credit_score</i>
                                                                                            </span>
                                                                                            <div className="timeline-content">
                                                                                                <h6 className="text-dark text-sm font-weight-bold mb-0">{sources.amount}$</h6>
                                                                                                <p className="mb-0">Installment <span className="badge badge-sm bg-gradient-success">{index + 1}</span></p>
                                                                                            </div>
                                                                                            <div className="timeline-content">
                                                                                                <h6 className="text-dark text-sm font-weight-bold mb-0">{sources.source}</h6>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>}


                                {this.state.accessType !== "CANCELLED" ?
                                    <div className="section-padding mt-n6">
                                        <div className="container">
                                            <div className="about-items-wrapper">
                                                <div className="row">
                                                    <div className="col-lg-8">
                                                        <div className="about-item" style={{ border: "0" }}>
                                                            <table className="table align-items-center mb-0">
                                                                <tr>
                                                                    <td>
                                                                        {
                                                                            this.state.isRefundingAndCanceling ? (
                                                                                <div>
                                                                                    <div
                                                                                        className="spinner-border text-primary"
                                                                                        role="status">
                                                                                        <span
                                                                                            className="sr-only">Loading...</span>

                                                                                    </div>
                                                                                    {this.state.cancelingMessage}
                                                                                </div>
                                                                            ) :
                                                                                <div className="item-icon-title">
                                                                                    {/*<div className="item-icon">*/}
                                                                                    {/*    <i className="icofont-repair bg-danger text-white "></i>*/}
                                                                                    {/*</div>*/}
                                                                                </div>
                                                                        }

                                                                    </td>
                                                                    <td>
                                                                    </td>
                                                                    <td>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="about-item" style={{ border: "0" }}>
                                                            <table className="table align-items-center mb-0">
                                                                <tr>
                                                                    <td>
                                                                    </td>
                                                                    <td>
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            className="btn btn-primary bg-danger"
                                                                            type="button"
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={this.saveSessionIdToRemove}
                                                                            data-bs-target="#confirmationDeleteSessionModal"
                                                                            data-bs-toggle="modal"
                                                                            id={this.state.subscriptionId}
                                                                        >
                                                                            Cancel subscription
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className="section-padding mt-n6">
                                        <div className="container">
                                            <div className="about-items-wrapper">
                                                <div className="row">
                                                    <div className="col-lg-8">
                                                        <div className="about-item" style={{ border: "0" }}>
                                                            <table className="table align-items-center mb-0">
                                                                <tr>
                                                                    <td>
                                                                        {
                                                                            this.state.isRefundingAndCanceling ? (
                                                                                <div>
                                                                                    <div
                                                                                        className="spinner-border text-primary"
                                                                                        role="status">
                                                                                        <span
                                                                                            className="sr-only">Loading...</span>

                                                                                    </div>
                                                                                    {this.state.cancelingMessage}
                                                                                </div>
                                                                            ) :
                                                                                <div className="item-icon-title">
                                                                                    {/*<div className="item-icon">*/}
                                                                                    {/*    <i className="icofont-repair bg-success text-white "></i>*/}
                                                                                    {/*</div>*/}
                                                                                </div>
                                                                        }

                                                                    </td>
                                                                    <td>
                                                                    </td>
                                                                    <td>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3">
                                                        <div className="about-item" style={{ border: "0" }}>
                                                            <table className="table align-items-center mb-0">
                                                                <tr>
                                                                    <td>
                                                                    </td>
                                                                    <td>
                                                                    </td>
                                                                    <td>
                                                                        <button
                                                                            className="btn btn-primary bg-success"
                                                                            type="button"
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={this.saveSessionIdToRemove}
                                                                            data-bs-target="#confirmationCancelSubscription"
                                                                            data-bs-toggle="modal"
                                                                            id={this.state.subscriptionId}
                                                                        >
                                                                            Restore subscription
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div
                            className="modal fade"
                            id="confirmationDeleteSessionModal"
                            tabIndex="-1"
                            aria-labelledby="confirmationDeleteSessionModal"
                            aria-hidden="true"
                        >
                            <div
                                className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title"
                                            id="exampleModalLabel">
                                            Cancel subscription
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    {!this.state.isCancelable ?
                                        <>
                                            <div className="modal-body">
                                                Confirm the action you want to make?
                                            </div>
                                            <div className="modal-footer">

                                                <button
                                                    type="button"
                                                    className="btn btn-warning  btn-block text-white"
                                                    onClick={() => {
                                                        this.setState({
                                                            isCancelable: true,
                                                            cancelingMessage: 'Cancelling...'
                                                        });
                                                    }}
                                                >
                                                    Only cancel the subscription
                                                </button>
                                                {/* <button
                                                    type="button"
                                                    className="btn btn-danger btn-block"
                                                    onClick={() => this.setState({
                                                        isRefundableAndCancelable: true,
                                                        isCancelable: true,
                                                        cancelingMessage: 'Refunding and cancelling...',
                                                    })}
                                                >
                                                    Cancel and refund the subscription
                                                </button> */}
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-block"
                                                    onClick={() => {
                                                        this.setState({
                                                            isRefundableAndCancelable: true,
                                                            isCancelable: true,
                                                            cancelingMessage: 'Refunding and cancelling...',
                                                        });
                                                        this.handleCancelAndRefundClick();
                                                    }}
                                                >
                                                    Cancel and refund the subscription
                                                </button>
                                            </div>
                                        </>
                                        :
                                        !this.state.isRefundableAndCancelable ?
                                            <>
                                                <div className="modal-body">
                                                    Do you really want to cancel this subscription?
                                                </div>
                                                <div className="modal-footer">
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger  btn-block"
                                                        onClick={this.cancelSubscription}
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        Confirm
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary btn-block"
                                                        onClick={() => this.setState({ isCancelable: false })}
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </> :
                                            <>

                                                <div className="modal-footer">
                                                    {showInfo && (
                                                        <div className="card">
                                                            <Message
                                                                style={{
                                                                    border: 'solid',
                                                                    borderWidth: '0 0 0 6px',
                                                                    color: '#696cff',
                                                                    display: 'flex',
                                                                    alignItems: 'center'
                                                                }}
                                                                className="border-primary w-full justify-content-start"
                                                                sticky="true"
                                                                severity="error"
                                                                content={
                                                                    <React.Fragment>
                                                                        <i
                                                                            className="fa fa-fw fa-exclamation-triangle"
                                                                            aria-hidden="true"
                                                                            style={{
                                                                                marginRight: '1 rem',
                                                                                fontSize: '5rem',
                                                                                color: 'red'
                                                                            }}
                                                                        ></i>
                                                                        {content}
                                                                    </React.Fragment>
                                                                }
                                                            />
                                                        </div>
                                                    )}



                                                    <button
                                                        type="button"
                                                        className="btn btn-danger  btn-block"
                                                        onClick={this.refundSubscription}
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        Confirm
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary btn-block"
                                                        onClick={() => this.setState({
                                                            isRefundableAndCancelable: false,
                                                            isCancelable: false,
                                                        })}
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>

                                            </>
                                    }
                                </div>

                            </div>
                        </div>

                        <div
                            className="modal fade"
                            id="confirmationRefundPaymentHistory"
                            tabIndex="-1"
                            aria-labelledby="confirmationRefundPaymentHistory"
                            aria-hidden="true"
                        >

                            <div
                                className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">

                                        <h5 className="modal-title"
                                            id="exampleModalLabel">
                                            Refund transaction
                                        </h5>
                                        
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    {!showInfo && (
                                                <div className="modal-header" style={{
                                                    border: 'none',
                                                   marginTop:"12px",
                                                }}>
                                                    <h5>Do you really want to refund this transaction?</h5>
                                                </div>
                                            )}
                                    <div className="modal-footer">
                                        {showInfo && (
                                            <div className="card">
                                                <Message
                                                    style={{
                                                        border: 'solid',
                                                        borderWidth: '0 0 0 6px',
                                                        color: '#696cff',
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                    className="border-primary w-full justify-content-start"
                                                    sticky="true"
                                                    severity="error"
                                                    content={
                                                        <React.Fragment>
                                                            <i
                                                                className="fa fa-fw fa-exclamation-triangle"
                                                                aria-hidden="true"
                                                                style={{
                                                                    marginRight: '1 rem',
                                                                    fontSize: '5rem',
                                                                    color: 'red'
                                                                }}
                                                            ></i>
                                                            {content}
                                                        </React.Fragment>
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>



                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-danger  btn-block"
                                            onClick={this.refundPayment}
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Confirm
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary btn-block"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Cancel
                                        </button>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div
                            className="modal fade"
                            id="confirmationCancelSubscription"
                            tabIndex="-1"
                            aria-labelledby="confirmationCancelSubscription"
                            aria-hidden="true"
                        >
                            <div
                                className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title"
                                            id="exampleModalLabel">
                                            Restore subscription
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        Do you really want to restore this
                                        subscription?
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-danger  btn-block"
                                            onClick={this.cancelSubscription}
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Confirm
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary btn-block"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div
                            className="modal fade"
                            id="confirmationRefundPaymentChoice1"
                            tabIndex="-1"
                            aria-labelledby="confirmationRefundPaymentChoice1"
                            aria-hidden="true"
                        >
                            <div
                                className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title"
                                            id="exampleModalLabel">
                                            Confirm Refund
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        Do you really want to cancel this
                                        subscription?
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-danger  btn-block"
                                            onClick={this.cancelSubscription}
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Confirm
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary btn-block"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div
                            className="modal fade"
                            id="confirmationRefundPaymentChoice2"
                            tabIndex="-1"
                            aria-labelledby="confirmationRefundPaymentChoice2"
                            aria-hidden="true"
                        >
                            <div
                                className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title"
                                            id="exampleModalLabel">
                                            Confirm Refund
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        Do you really want to refund this
                                        subscription?
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-danger  btn-block"
                                            onClick={this.refundSubscription}
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Confirm
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary btn-block"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div className="position-fixed bottom-1 end-1 z-index-2">
                    {this.state.successMessage ? (
                        <div
                            className="toast fade show p-2 mt-2 bg-gradient-info"
                            role="alert"
                            aria-live="assertive" id="infoToast"
                            aria-atomic="true">
                            <div
                                className="toast-header bg-transparent border-0">
                                <i className="material-icons text-white me-2">
                                    check
                                </i>
                                <span
                                    className="me-auto text-white font-weight-bold">Subscription </span>
                                <small className="text-white">just now</small>
                                <i className="fas fa-times text-md text-white ms-3 cursor-pointer"
                                    data-bs-dismiss="toast" aria-label="Close"
                                    onClick={(e) => this.setState({ successMessage: false })}
                                ></i>
                            </div>
                            <hr className="horizontal light m-0" />
                            <div className="toast-body text-white">
                                {this.state.message}
                            </div>
                        </div>
                    ) :
                        (<></>)}
                    {this.state.deleteMessage ? (
                        <div
                            className="toast fade show p-2 mt-2 bg-gradient-danger"
                            role="alert"
                            aria-live="assertive"
                            id="infoToast"
                            aria-atomic="true"
                        >
                            <div className="toast-header bg-transparent border-0">
                                <i className="material-icons text-white me-2">
                                    check
                                </i>
                                <span className="me-auto text-white font-weight-bold">
                                    Session
                                </span>
                                <small className="text-white">just now</small>
                                <i
                                    className="fas fa-times text-md ms-3 cursor-pointer"
                                    data-bs-dismiss="toast"
                                    aria-label="Close"
                                    onClick={(e) =>
                                        this.setState({ deleteMessage: false })
                                    }
                                ></i>
                            </div>
                            <hr className="horizontal light m-0" />
                            <div className="toast-body text-white">
                                {this.state.deleteMessageContent}
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </>
        )
    }
}

import React, { useEffect, useRef, useState } from "react";
import ButtonCustom from "../../../../components/frontv2.0/common/button/ButtonCustom";
import StatsAmount from "../../../../components/frontv2.0/common/stats/StatsAmount";
import StatsInstallmentDate from "../../../../components/frontv2.0/common/stats/StatsInstallmentDate";
import { IMAGE_2, MOTIF_LOGIN } from "../../../../constants/images";
import MainLayout from "../../../../layouts/MainLayout";
import Header from "../../../../components/frontv2.0/common/header/Header";
import SubHeader from "../../../../components/frontv2.0/common/header/SubHeader";
import SessionService from "../../../../network/services/SessionService";
import moment from "moment/moment";
import UserService from "../../../../network/services/UserService";
import { getUser } from "../../../../network/auth/Auth";
import Installments from "../../../../components/frontv2.0/common/table/Installments";
import { useNavigate } from "react-router";
import Loader from "../../../../components/frontv2.0/common/loader/Loader";
import PageOops from "../../../../components/frontv2.0/common/opps/PageOops";
import { encodeUrl } from "../../../../constants/Constants";
import { MEDIA_URL_ACCOUNTING } from "../../../../network/urls/backendUrls";

const MyTransactionDetails = () => {
  /**
   * States
   */
  const [currentUserSessions, setCurrentUserSessions] = useState(null);
  const [isInGoodStanding, setIsInGoodStanding] = useState(false);
  const [accessType, setAccessType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [installment_done, setInstallment_done] = useState(null);
  const [installment_total, setInstallment_total] = useState(null);
  const [remainingTransaction, setRemainingTransaction] = useState(null);
  const [total_pay, setTotal_pay] = useState(null);
  const [remaining, setRemaining] = useState(null);
  const [price_total, setPrice_total] = useState(null);
  const [remaining_payment_date, setRemaining_payment_date] = useState("");
  const [instalmentHistory, setInstalmentHistory] = useState([]);
  const [subscriptionId, setSubscriptionId] = useState("");
  const [isCancelled, setIsCancelled] = useState(false);


  const navigate = useNavigate();

  const showPayDate = () => {
    const queryParams = new URLSearchParams(window.location.search);
    let sessionId = queryParams.get("sessionId");
    SessionService.getDatePaymentSecure(sessionId, (data) => {
      if (data) {
      //  console.log(data)
        let arrayDiff = [];
        let arrayPaid = [];
        data.dateAlreadyPaid.map((item) => {
          return arrayPaid.push({
            amount: item.amount,
            status: true,
            date: item.date,
          });
        });
        data.difference.map((item) => {
          return arrayDiff.push({
            amount: item.amount,
            status: false,
            date: item.date,
            next_payment_date: item.next_installement_date,
          });
        });

        const mergedArray = arrayPaid.concat(arrayDiff);

        setInstalmentHistory(mergedArray);

        // setDate_already_paid(data.dateAlreadyPaid ? next_payment_date : data.difference);
        setInstallment_done(data.transactionDetails[0].installment_done);
        setInstallment_total(data.transactionDetails[0].installment_total);
        setTotal_pay(data.transactionDetails[0].total_paid);
        setRemaining(data.transactionDetails[0].remaining);
        setPrice_total(data.transactionDetails[0].price_total);
        setRemainingTransaction(data.transactionDetails[0].installment_remaining);
        // setRemaining_payment_date(
        //   moment(data.difference[0].date).utc().format("L")
        // );
        setRemaining_payment_date(
          data.difference[0].next_installement_date
            ? moment(data.difference[0].next_installement_date).utc().format("L")
            : moment(data.difference[0].date).utc().format("L")
        );
      }
    });
  };

  const showCurrentUserSession = () => {
    const queryParams = new URLSearchParams(window.location.search);
    let sessionId = queryParams.get("sessionId");
    let subscriptionId = queryParams.get("subscriptionId");
    setSubscriptionId(subscriptionId);
    SessionService.getCurrentSessionByUserSecure(sessionId, (data) => {
      if (data) {
        // console.log(data)
        setIsCancelled(data.access_type === "CANCELLED");
        setCurrentUserSessions(data.session[0]);
        setIsInGoodStanding(data.isInGoodStanding);
        setAccessType(data.access_type);
        setIsLoading(false);
      }
    });
  };

  const getInvoice = () => {
    const queryParams = new URLSearchParams(window.location.search);
    let sessionId = queryParams.get("sessionId");
    let user = getUser();
    let data = {
      user_id: user.id,
      session_id: sessionId,
    };
    UserService.getInvoice("Utrains-subscription-invoice.pdf", data);
  };

  const minuteSeconds = 60;
  const hourSeconds = minuteSeconds * 60;
  const daySeconds = hourSeconds * 24;

  const date = new Date(remaining_payment_date);
  const dateToSecond = date.getTime() / 1000; //1440516958

  const startTime = Date.now() / 1000; // use UNIX timestamp in seconds
  const endTime = dateToSecond; // use UNIX timestamp in seconds
  const remainingTime = endTime - startTime;
  const days = Math.ceil(remainingTime / daySeconds);

  const effectRan = useRef(false);

  const handleRedirectMakeAPayment = () => {
    navigate(
      `/workspace/make-payment/details?subscriptionId=${subscriptionId}`
    );
  };

  useEffect(() => {
    if (effectRan.current === false) {
      showCurrentUserSession();
      showPayDate();
    }

    return () => {
      effectRan.current = true;
    };
  }, []);

  /**
   * Render
   */
  return (
    <>
      <MainLayout>
        <MainLayout.Content>
          {isLoading ? (
            <div className="tw-flex tw-h-full tw-flex-col md:tw-justify-center tw-justify-center tw-items-center">
              <Loader />
            </div>
          ) : isCancelled ? (
            <>
              <div
                className="tw-flex tw-h-full tw-flex-col md:tw-justify-center tw-justify-center tw-items-center"
                style={{ backgroundImage: `url(${MOTIF_LOGIN})` }}
              >
                <PageOops
                  text="This subscription has been canceled"
                  textBtn="Go back"
                  onClick={() => {
                    navigate(`/workspace/transactions`);
                  }}
                />
              </div>
            </>
          ) : (
            <div className="tw-space-y-4">
              <>
                <div>
                  <h1 className="tw-text-lg tw-font-bold sm:tw-text-2xl">
                    Transaction details
                  </h1>
                </div>
                <div className="tw-flex tw-flex-col tw-space-x-8 tw-space-y-4">
                  <div className="tw-flex-col tw-w-full tw-overflow-tw-hidden md:tw-flex md:tw-flex-row  tw-rounded-[12px]  ">
                    <div
                      className="tw-hidden sm:tw-block md:tw-my-3 tw-bg-cover tw-bg-center tw-rounded-[12px]  md:tw-w-[500px] tw-w-full"
                      style={{
                        backgroundImage: `url(${!currentUserSessions?.image
                            ? IMAGE_2
                            : encodeUrl(
                              `${MEDIA_URL_ACCOUNTING}/sessions/${currentUserSessions?.image}`
                            )
                          })`,
                      }}
                    />
                    <img
                      className="sm:tw-hidden md:tw-m-3 tw-bg-cover tw-bg-center tw-rounded-[12px] tw-max-h-[220px]  md:tw-w-[500px] tw-w-full"
                      src={`${!currentUserSessions?.image
                          ? IMAGE_2
                          : encodeUrl(
                            `${MEDIA_URL_ACCOUNTING}/sessions/${currentUserSessions?.image}`
                          )
                        }`}
                      alt="session"
                    />
                    <div className="tw-flex tw-flex-col tw-w-full tw-p-4 md:tw-m-3 tw-bg-accent-gray tw-rounded-b-[12px] md:tw-rounded-[12px]">
                      <div className="tw-w-full tw-h-full md:tw-flex md:tw-items-center md:tw-justify-between">
                        <div>
                          <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-text-primary tw-pb-[31px]">
                            <div className="tw-flex tw-flex-col tw-text-lg">
                              <span className="tw-font-bold">
                                {currentUserSessions?.name}
                              </span>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: currentUserSessions?.description,
                                }}
                              />
                            </div>
                          </div>
                          <div className="tw-flex tw-flex-col tw-w-full md:tw-items-center md:tw-flex-row md:tw-justify-between">
                            <div className="tw-flex tw-flex-col tw-pt-4 tw-text-primary tw-text-md">
                              <div className="tw-flex tw-items-center">
                                <span className="tw-mr-[6px] pi pi-calendar tw-text-green-700"></span>
                                <span>
                                  {moment(currentUserSessions?.start_date)
                                    .utc()
                                    .format("MMMM Do, YYYY")}
                                </span>
                              </div>

                              <div className="tw-flex tw-items-center">
                                <span className="tw-mr-[6px] pi pi-calendar tw-text-primary"></span>
                                <span>
                                  {moment(currentUserSessions?.end_date)
                                    .utc()
                                    .format("MMMM Do, YYYY")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <ButtonCustom
                            onClick={getInvoice}
                            classes={
                              "tw-bg-accent-blue hover:tw-bg-primary-100 tw-text-primary tw-border tw-border-dashed tw-border-primary sm:tw-text-base"
                            }
                            label="Download Invoice"
                            icon={"pi pi-download tw-text-xs"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tw-space-y-4 sm:tw-flex sm:tw-flex-row sm:tw-space-x-8 sm:tw-space-y-0">
                  <StatsAmount
                    status="all"
                    amount={price_total}
                    totalInstallment={installment_total}
                  />
                  <StatsAmount
                    status="done"
                    amount={total_pay}
                    totalInstallment={installment_done}
                  />
                  <StatsAmount
                    status="remain"
                    amount={remaining}
                    totalInstallment={remainingTransaction}
                  />
                  <div className="tw-flex tw-items-center tw-justify-center">
                    <StatsInstallmentDate
                      numberDate={days && days > 0 ? days : 0}
                    />
                  </div>

                 
                </div>
                <div className="tw-w-full">
                  <Installments
                    datas={instalmentHistory}
                    redirect={handleRedirectMakeAPayment}
                  />
                </div>
              </>
            </div>
          )}
        </MainLayout.Content>

        <MainLayout.Header>
          <Header />
        </MainLayout.Header>

        <MainLayout.SubHeader>
          <SubHeader
            data={[
              { value: "Home", url: "/workspace" },
              {
                value: "My Transaction",
                url: "/workspace/transactions",
              },
              {
                value: currentUserSessions?.name,
                url: "#",
              },
            ]}
          />
        </MainLayout.SubHeader>
      </MainLayout>
    </>
  );
};

export default MyTransactionDetails;

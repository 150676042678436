import MainLayout from "../../../../layouts/MainLayout";
import Header from "../../../../components/frontv2.0/common/header/Header";
import SessionDetails from "../../../../components/frontv2.0/common/sessions/SessionDetails";
import SubHeader from "../../../../components/frontv2.0/common/header/SubHeader";
import { useEffect, useState } from "react";
import SessionService from "../../../../network/services/SessionService";
import Loader from "../../../../components/frontv2.0/common/loader/Loader";
import { useParams } from "react-router";

const NextSessionDetails = () => {
  const { sessionId } = useParams();
  /**
   * States
   */

  const [isLoading, setIsLoading] = useState(false);
  const [nextSessionsDetail, setNextSessionsDetail] = useState(null);

  /**
   * Comportments
   */
  const getNextSessionsDetails = () => {
    setIsLoading(true);
    SessionService.getOneSessionById(
      { session_id: parseInt(sessionId) },
      (data) => {
        if (data != null) {
          setNextSessionsDetail(data.session);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    getNextSessionsDetails();
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  /**
   * render
   */
  return (
    <>
      <MainLayout>
        <MainLayout.Content>
          {!isLoading ? (
            nextSessionsDetail ? (
              <SessionDetails
                checkoutLink={`/workspace/next-sessions/details/checkout`}
                data={nextSessionsDetail}
              />
            ) : (
              <div className="tw-justify-center tw-flex tw-items-center tw-py-16">
                <p>Session Details Not Found</p>
              </div>
            )
          ) : (
            <div className="tw-flex tw-h-full tw-flex-col md:tw-justify-center tw-justify-center tw-items-center">
              <Loader />
            </div>
          )}
        </MainLayout.Content>

        <MainLayout.Header>
          <Header />
        </MainLayout.Header>

        <MainLayout.SubHeader>
          <SubHeader
            data={[
              { value: "Home", url: "/workspace" },
              {
                value: "Next Sessions",
                url: "/workspace/next-sessions",
              },
              { value: nextSessionsDetail?.name, url: "#" },
            ]}
          />
        </MainLayout.SubHeader>
      </MainLayout>
    </>
  );
};

export default NextSessionDetails;

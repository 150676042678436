import AppLayout from "../../layouts/AppLayout";
import AppHeader from "../../components/frontv2.0/common/header/AppHeader";
import SubHeaderOffLine from "../../components/frontv2.0/common/header/SubHeaderOffLine";
import {useNavigate} from "react-router-dom";
import {WARNING_ILLUSTRATION} from "../../constants/images";
import ButtonCustom from "../../components/frontv2.0/common/button/ButtonCustom";

const PageErrorPaymentIncomplete = () => {

    /**
     * States
     */
    const navigate = useNavigate();

    // eslint-disable-next-line
    const handleOnClickItem = () => {
        return navigate('/workspace')
    };

    /**
     * Render
     */
    return (
        <AppLayout>
            <AppLayout.Content>
                <div
                    className="tw-flex tw-flex-col-reverse md:tw-flex-row tw-justify-between tw-items-center tw-h-full tw-px-8 tw-py-8 md:tw-py-0 md:tw-px-40"
                    style={{height: 'calc(100vh - 22rem)'}}>
                    <div className="tw-flex tw-flex-col tw-space-y-10 tw-text-primary tw-items-center md:items-start">
                        <div className="tw-flex tw-flex-col tw-space-y-2">
                            <p className="md:tw-w-2/3 tw-text-lg tw-font-medium tw-text-center md:tw-text-left"> You
                                cannot access to this page. Your payments are incomplete. </p>
                            <p className="md:tw-w-2/3 tw-text-sm  tw-text-center md:tw-text-left tw-text-gray-400"> Check
                                your transaction by clicking on the following button. </p>
                        </div>
                        <ButtonCustom
                            label="Back to the Home Page"
                            onClick={handleOnClickItem}
                            classes="tw-bg-secondary-orange hover:bg-orange-700 tw-text-white tw-max-w-[237px] tw-max-h-[44px]"
                        />
                    </div>
                    <div className="tw-py-6 md:tw-mb-0">
                        <img src={WARNING_ILLUSTRATION} alt="Page not found illustration" width={'350px'}/>
                    </div>
                </div>
            </AppLayout.Content>

            <AppLayout.Header>
                <AppHeader/>
            </AppLayout.Header>

            <AppLayout.SubHeader>
                <SubHeaderOffLine data={[{value: 'Home', url: '/'}, {value: 'Incomplete Payment', url: '#'}]}/>
            </AppLayout.SubHeader>
        </AppLayout>
    );
};

export default PageErrorPaymentIncomplete;

import React, {Component} from "react";

import CourseService from "../../../network/services/CourseService";
import Sidebar from "../sidebar/Sidebar";
import AdminFooter from "../footer/AdminFooter";
import ResourceService from "../../../network/services/ResourceService";

import ReactPaginate from "react-paginate";
import AdminHeader from "../header/AdminHeader";
import "../Dashboard/AdminHome.css";

class AdminCourseDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courseId: "",
            course: "",
            planIdToDelete: [],
            planIdToRemove: "",
            resources: [],
            coursePlans: [],
            courses: [],
            resourcesToShow: [],
            selectedResources: [],
            removeResources: [],
            currentCourse: {
                id: null,
                title: "",
                description: "",
                resources: [],
            },
        };
    }

    retrieveResourse() {
        this.setState({isLoading: true});
        ResourceService.getAll((data) => {
            // console.log(data);
            this.setState({resources: data, isLoading: false});
        });
    }

    removeResource = (e) => {
        // console.log("on est entrer");
        this.getId(e);

        const tabRes = this.state.currentCourse.resources;

        console.log("on entre avec  " + tabRes.length);

        tabRes.forEach((o) => {
            let elt = "/api/resources/" + o.id;
            this.state.removeResources.push(elt);
        });

        console.log("les resources sont ici");
        console.log(this.state.removeResources);

        let toRemove = e.target.getAttribute("value");

        let tabCourseNew = this.state.removeResources.filter((e) => e !== toRemove);

        let TabData = {
            resources: tabCourseNew,
        };

        CourseService.update(this.state.id, TabData, () => {
            this.setState({
                removeResources: [],
            });
            this.getId(e);
        });
    };

    sendData = (e) => {
        const courseId = this.state.id;
        const tabRes = this.state.currentCourse.resources;
        const tabOut = [];

        tabRes.forEach((o) => {
            let elt = "/api/resources/" + o.id;
            this.state.selectedResources.push(elt);
        });

        const toRegister = e.target.value;

        this.state.selectedResources.push(toRegister);

        let tabData = {
            resources: this.state.selectedResources,
        };

        CourseService.update(courseId, tabData, () => {
            this.setState({
                selectedResources: [],
            });
            this.getId();
        });
    };

    getId = (courseId) => {
        this.setState({isLoading: true});
        CourseService.getOneById(courseId, (response) => {
            if (response) {
                console.log(response);

                this.setState({
                    course: response.data,
                    coursePlans: response.data.subscriptionPlans,
                    isLoading: false,
                });
            }
        });
    };

    componentDidMount() {
        this.setState({isLoading: true});
        const queryParams = new URLSearchParams(window.location.search);
        let courseId = queryParams.get("courseId");
        console.log(courseId);
        this.setState({courseId: courseId});

        this.getId(courseId);
        // this.getplan(courseId);
    }

    /*  savePlanIdToRemove = (e) => {
         const idv = e.target.getAttribute("value");
         console.log("the id of plan is: " + idv);

         this.setState({ planIdToRemove: idv });
       }; */

    savePlanIdToRemove = (e) => {
        const idCourse = e.target.getAttribute("id");
        const idPlan = e.target.getAttribute("value");

        if (!this.state.planIdToDelete.includes(idPlan)) {
            this.setState({planIdToDelete: [...this.state.planIdToDelete, idPlan]});
        } else {
            let tabId = this.state.planIdToDelete.filter((e) => e !== idPlan);
            this.setState({planIdToDelete: tabId});
        }
        this.setState({courseIdToRemove: idCourse});
    };

    removePlan = (e) => {
        let tabData = {
            course_id: this.state.courseId,
            subscription_plan_ids: this.state.planIdToDelete,
        };

        CourseService.deletePlan(tabData, (response) => {
            if (response) {
                if (response.success) {
                    this.setState({
                        message: "Subscription has been removed to course.",
                        delete: true,
                    });
                    this.getId(this.state.courseId);
                }
            }
        });
    };

    /* retrievePlan = () => {
            SubscriptionPlanService.getAll((data) => {
                console.log(data);
                this.setState({coursePlans: data.subscriptionPlans});
            });
        }; */

    render() {
        return (
            <div className="g-sidenav-show  bg-gray-200">
                <Sidebar/>
                <main className="mainAdmin r ms-100  ">
                    <AdminHeader location="Courses / details"/>

                    <div className="container-fluid py-4">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="row">
                                    <div className="col-md-12 mb-lg-0 mb-4">
                                        <div className="card mt-4">
                                            <div className="card-header pb-0 p-3">
                                                <div className="row">
                                                    <div className="col-6 d-flex align-items-center">
                                                        <h6 className="mb-0">
                                                            Details on Course :{" "}
                                                            {this.state.currentCourse.title}
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <table className="table table-borderless">
                                                    <tbody>
                                                    <tr>
                                                        <td>
                                                            <img
                                                                src="../assets/img/team-2.jpg"
                                                                className="avatar border-radius-lg"
                                                                alt={this.state.course.name}
                                                            />
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td> ID</td>
                                                        <td>{this.state.course.id}</td>
                                                    </tr>
                                                    <tr>
                                                        <td> name</td>
                                                        <td>{this.state.course.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td> Description</td>
                                                        <td>{this.state.course.description}</td>
                                                    </tr>

                                                    {/* <tr>
                              <td> Start date</td>
                              <td>
                                {moment(this.state.course.startDate)
                                  .utc()
                                  .format("DD-MM-YYYY") +
                                  " at " +
                                  moment
                                    .utc(this.state.course.startDate)
                                    .format("HH:mm")}
                              </td>
                            </tr> */}
                                                    {/*   <tr>
                              <td> End date</td>
                              <td>
                                {moment(this.state.course.endDate)
                                  .utc()
                                  .format("DD-MM-YYYY") +
                                  " at " +
                                  moment
                                    .utc(this.state.course.endDate)
                                    .format("HH:mm")}
                              </td>
                            </tr> */}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="card h-100">
                                    <div className="card-header pb-0 p-3">
                                        <div className="row">
                                            <div className="col-6 d-flex align-items-center">
                                                <h6 className="mb-0">Associated plans</h6>
                                            </div>
                                            {/*<div className="col-6 text-end">*/}
                                            {/*    <button className="btn btn-outline-primary btn-sm mb-0">View All*/}
                                            {/*    </button>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    <div className="card-body p-3 pb-0">
                                        <table className="table table-borderless">
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>...</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.isLoading ? (
                                                <div
                                                    className="spinner-border text-primary"
                                                    role="status"
                                                >
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            ) : null}
                                            {this.state.coursePlans?.map((plan) => (
                                                <tr key={plan.id}>
                                                    <td>{plan.id}</td>
                                                    <td>{plan.name}</td>
                                                    <td>
                                                        <button
                                                            style={{
                                                                cursor: "pointer",
                                                                color: "firebrick",
                                                            }}
                                                            value={plan.id}
                                                            className="badge "
                                                            data-bs-target="#confirmationRemoveModal"
                                                            data-bs-toggle="modal"
                                                            onClick={this.savePlanIdToRemove}
                                                            //onClick={this.removePlan}
                                                        >
                                                            Remove
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>

                                        <div className="card-footer py-4">
                                            <nav aria-label="...">
                                                <ReactPaginate
                                                    previousLabel={"<"}
                                                    nextLabel={">"}
                                                    breakLabel={"..."}
                                                    pageCount={1}
                                                    marginPagesDisplayed={3}
                                                    pageRangeDisplayed={6}
                                                    onPageChange={this.handleChange}
                                                    containerClassName={
                                                        "pagination justify-content-end mb-0"
                                                    }
                                                    pageClassName={"page-item"}
                                                    previousClassName={"page-item"}
                                                    previousLinkClassName={"page-link"}
                                                    pageLinkClassName={"page-link"}
                                                    nextClassName={"page-item"}
                                                    nextLinkClassName={"page-link"}
                                                    breakClassName={"page-item"}
                                                    breakLinkClassName={"page-link"}
                                                    activeClassName={"active"}
                                                />
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="position-fixed bottom-1 end-1 z-index-2">
                            {this.state.delete ? (
                                <div
                                    className="toast fade show p-2 mt-2 bg-gradient-info"
                                    role="alert"
                                    aria-live="assertive"
                                    id="infoToast"
                                    aria-atomic="true"
                                >
                                    <div className="toast-header bg-transparent border-0">
                                        <i className="material-icons text-white me-2">check</i>
                                        <span className="me-auto text-white font-weight-bold">
                      course
                    </span>
                                        <small className="text-white">just now</small>
                                        <i
                                            className="fas fa-times text-md ms-3 cursor-pointer"
                                            data-bs-dismiss="toast"
                                            aria-label="Close"
                                            onClick={(e) => this.setState({delete: false})}
                                        ></i>
                                    </div>
                                    <hr className="horizontal light m-0"/>
                                    <div className="toast-body text-white ">
                                        {this.state.message}
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>

                        <div className="row">
                            <div className="col-md-12 mt-4">
                                <div className="card">
                                    <div className="card-header pb-0 px-3">
                                        <h6 className="mb-0">Associated Resources</h6>
                                    </div>
                                    <div className="card-body pt-4 p-3">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>...</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            {this.state.currentCourse.resources.map((resource) => (
                                                <tr key={resource.id}>
                                                    <td value={"/api/resources/" + resource.id}>
                                                        {resource.id}
                                                    </td>
                                                    <td>{resource.title}</td>
                                                    <td>
                                                        {" "}
                                                        <span
                                                            className="material-icons"
                                                            style={{
                                                                cursor: "pointer",
                                                                color: "firebrick",
                                                            }}
                                                            id={resource.id}
                                                            key={resource.id}
                                                            value={"/api/resources/" + resource.id}
                                                            onClick={this.removeResource}
                                                        >
                                cancel
                              </span>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            className="modal fade"
                            id={"confirmationRemoveModal"}
                            tabIndex="-1"
                            aria-labelledby="confirmationRemoveModal"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">
                                            Dissociate subscription plan
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        Do you want to remove the selected subscription plan from
                                        the course ?
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-danger  btn-block"
                                            onClick={this.removePlan}
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Confirm
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary btn-block"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <AdminFooter/>
                    </div>
                </main>
            </div>
        );
    }
}

export default AdminCourseDetails;

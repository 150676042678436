import React from "react";

const CourseLoader = () => {
  return (
    <>
      <div
        className={`tw-animate-pulse tw-rounded-[12px] tw-border tw-min-w-[220px] tw-text-primary tw-w-full hover:tw-bg-accent-gray tw-shadow-sm hover:tw-transition hover:tw-ease-in-out hover:tw-delay-150 hover:tw-duration-250`}
      >
        <div className="tw-bg-cover tw-bg-center tw-bg-gray-200 tw-rounded-[12px] tw-h-[220px] tw-w-full" />

        <div className="tw-flex tw-flex-col sm:tw-flex-row sm:tw-justify-between sm:tw-items-center tw-p-4">
          <div className="tw-mb-2 tw-flex tw-flex-col">
            <div className="tw-flex tw-flex-col tw-text-lg tw-w-16 sm:tw-24 tw-space-y-1">
              <div class="tw-h-2 tw-bg-gray-200 tw-rounded tw-col-span-2"></div>
              <div class="tw-h-2 tw-bg-gray-200 tw-rounded tw-col-span-2"></div>
            </div>
          </div>
          <div>
            <div class=" tw-bg-gray-200 tw-h-10 tw-flex tw-justify-center tw-items-center tw-transition tw-ease-in-out tw-delay-100 tw-duration-200 tw-rounded-[9px] tw-px-4 tw-py-2  tw-font-bold tw-shadow-sm focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 tw-w-[97px]"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseLoader;

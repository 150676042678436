import PaymentAlert from "../../../../components/frontv2.0/common/status/PaymentAlert";
import OrderSummary from "../../../../components/frontv2.0/common/sessions/OrderSummary";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LastSubscriptionService from "../../../../network/services/LastSubscriptionService";
import Loader from "../../../../components/frontv2.0/common/loader/Loader";
import { getUser } from "../../../../network/auth/Auth";
import UserService from "../../../../network/services/UserService";
import AppLayout from "../../../../layouts/AppLayout";
import SubHeaderOffLine from "../../../../components/frontv2.0/common/header/SubHeaderOffLine";
import AppHeader from "../../../../components/frontv2.0/common/header/AppHeader";

const OpenedSessionSuccessFullyCheckOutApp = () => {
  const navigate = useNavigate();
  /**
   * States
   */
  const [firstLoader, setFirstLoader] = useState(false);
  const [paymentSuccessInfo, setPaymentSuccessInfo] = useState({});
  const [SummaryData, setSummaryData] = useState({});
  const [sessionId, setSessionId] = useState("");
  const [data, setData] = useState({});

  /**
   * Comportments
   */
  const showLastsubscription = () => {
    setFirstLoader(true);

    LastSubscriptionService.getAll((data) => {
      if (data) {
        setData(data);
        const paymentSuccesInfo = {
          title: `Thanks for subscribing to session : ${data.subscription?.session_name}`,
          subTitle: `You will get an email confirmation sent to ${data.subscription?.user_email}`,
          enableButtonAction: true,
          redirectButtonLabel: "Go to My Sessions",
          downloadButtonLabel: "Download Invoice",
          urlToRedirect: "/workspace/mysessions",
        };
        const summaryData = {
          title: "Order Summary",
          product: `${data.subscription.session_name} – ${data.subscription.plan.name}`,
          paymentMethod: "Card",
          currency: `${data.subscription.currency}`,
          orderTotal: `${data.subscription.payment_amount}`,
        };
        setSessionId(data.subscription.session_id);
        setPaymentSuccessInfo(paymentSuccesInfo);
        setSummaryData(summaryData);
      }
      setFirstLoader(false);
    });
  };

  const getUserInvoice = () => {
    let user = getUser();
    let data = {
      user_id: user.id,
      session_id: sessionId,
    };
    UserService.getInvoice("Utrains-subscription-invoice.pdf", data);
  };

  useEffect(() => {
    showLastsubscription();
  }, []);

  const handleOnclick = () => {
    navigate("/workspace/mysessions");
  };

  /**
   * Render
   */
  return (
    <>
      <AppLayout>
        <AppLayout.Content>
          {firstLoader ? (
            <div className="tw-flex tw-h-full tw-flex-col md:tw-justify-center tw-justify-center tw-items-center">
              <Loader />
            </div>
          ) : (
            <div
              className={`tw-flex md:tw-flex-row tw-flex-col tw-w-full md:tw-space-x-2 md:tw-space-y-0'`}
            >
              <PaymentAlert
                data={paymentSuccessInfo}
                onClickToDownload={getUserInvoice}
                onClickToRedirect={handleOnclick}
              />

              {/* SectionLeft */}
              <OrderSummary data={SummaryData} />
            </div>
          )}
        </AppLayout.Content>

        <AppLayout.Header>
          <AppHeader />
        </AppLayout.Header>

        <AppLayout.SubHeader>
          <SubHeaderOffLine
            data={[
              { value: "Home", url: "/" },
              { value: "Opened Sessions", url: "/open-sessions" },
              { value: data?.subscription?.session_name, url: "/open-sessions/details/"+data?.subscription?.session_id },
              {
                value: "Checkout",
                url: `/open-sessions/details/checkout/${data?.subscription?.session_id}/${data?.subscription?.plan?.id}`,
              },
              { value: "Success", url: "#" },
            ]}
          />
        </AppLayout.SubHeader>
      </AppLayout>
    </>
  );
};

export default OpenedSessionSuccessFullyCheckOutApp;

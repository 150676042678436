import React from 'react';

const Avatar = ({src, alt, size, classes}) => {
    return (
        <>
            <div className="tw-flex tw-items-center">
                <img
                    className={`tw-object-cover tw-rounded-full tw-ring-[1px] tw-primary ${classes}`}
                    src={src}
                    alt={alt}
                    style={{
                        height: `${size}`,
                        width: `${size}`
                    }}
                />
            </div>
        </>
    );
};

export default Avatar;
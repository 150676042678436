import React, {Component} from "react";
import Sidebar from "../sidebar/Sidebar";
import "../../../assets/subscription_plan.css";
import SessionService from "../../../network/services/SessionService";
import {useNavigate} from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment/moment";
import AdminHeader from "../header/AdminHeader";
import '../Dashboard/AdminHome.css';

class SessionManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sessions: [],
            courses: [],
            plans: [],
            name: '',
            description: '',
            start_date: null,
            end_date: null,
            course: null,
            selectedCourse: '/api/courses/1',
            selectedCourses: [],
            selectedPlans: [],
            allPlans: [],
            selectedPlan: '',
            selectedPlanId: '',
            selectedSessionId: '',
            expiredAt: null,
            currentSession: {
                id: null,
                name: '',
                description: '',
                startDate: '',
                endDate: '',
                courses: [],
                subscription: [],
                subscriptionPlans: [],
                updatedAt: '',
                createdAt: '',
            }
        }
    }

    getId(e) {
        const id = e.target.getAttribute("id");
        SessionService.getById(id, (data) => {
            //console.log(data);
            this.setState({
                currentSession: data,
                // isLoading:false
            })
        });
    }

    openListSubscription = (e) => {

        const id = e.target.getAttribute("id");
        //console.log("the selected session is: " + id);

        const navigate = this.props.myNavigate;
        //alert(navigate);
        navigate({
            pathname: "/users_transaction",
            search: '?sessionId=' + id
        })
    }

    selectSession = (e) => {
        const id = e.target.getAttribute("id");
        // console.log("the selected session is: " + id);
        SessionService.getById(id, (data) => {
            // console.log(data);
            this.setState({
                currentSession: data,
                // isLoading:false
            });
        });

        this.setState({selectedSessionId: id});

        // console.log("nous sortons pour le sendaata");
        // console.log(this.state.currentSession);
    }

    showSession = (e) => {
        this.setState({
            isLoading: true
        });
        const page_url = 1;
        const nombre_items = 3;
        SessionService.getAllByPage(page_url, nombre_items, (data) => {
            // console.log(data)
            if (data["hydra:totalItems"] > 3) {
               
                const datas = data["hydra:member"];
                const lPage = (data["hydra:view"]["hydra:last"]).slice(34);
                
                this.setState({
                    sessions: datas,
                    lastPage: lPage,
                    isLoading: false
                });
            } else {
                const datas = data["hydra:member"];
                // console.log("egale a "+data["hydra:totalItems"]);
                this.setState({
                    sessions: datas,
                    lastPage: 1,
                    isLoading: false
                });
            }
            // console.log(data)
        })
    }

    componentDidMount() {
        this.showSession();
    }

    render() {
        const handlePageClick = (data) => {
            this.setState({
                isLoading: true
            });
            // console.log(data.selected);
            const page_url = data.selected + 1;
            const nombre_items = 3;
            SessionService.getAllByPage(page_url, nombre_items, (data) => {
                // console.log(data["hydra:member"]);
                this.setState({sessions: data["hydra:member"], isLoading: false});


            });
        }
        return (
            <div className="g-sidenav-show  bg-gray-200">
                <Sidebar/>
                <main className="mainAdmin r ms-100">
                    <AdminHeader location="Session manager"/>
                    <div className="container-fluid py-4">
                        <div className="row mb-4">
                            <div className="col-lg-12 col-md-6 mb-md-0 mb-4">
                                <div className="card">
                                    <div className="card-header pb-0">
                                        <div className="row">
                                            <div className="col-lg-6 col-7">
                                                <h6>All sessions</h6>
                                            </div>
                                        </div>
                                        <div className="table-responsive p-0">
                                            <table className="table align-items-center mb-0">
                                                <thead>
                                                <tr>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">ID</th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">NAME</th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">START
                                                        DATE

                                                    </th>
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">END
                                                        DATE
                                                    </th>
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">CREATED
                                                        AT DATE
                                                    </th>
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Management
                                                    </th>

                                                </tr>
                                                </thead>

                                                <tbody>
                                                {
                                                    this.state.isLoading ? (
                                                        <div className="spinner-border text-primary" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>

                                                    ) : (
                                                        <></>
                                                    )
                                                }

                                                {this.state.sessions && this.state.sessions.map((session) => (
                                                    <tr key={session.id}>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                {session.id}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex px-2 py-1">
                                                                {/*<div>*/}
                                                                {/*    <img src="../assets/img/team-2.jpg"*/}
                                                                {/*         className="avatar avatar-sm me-3 border-radius-lg"*/}
                                                                {/*         alt="user1"/>*/}
                                                                {/*</div>*/}
                                                                <div
                                                                    className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-sm">{session.name}</h6>
                                                                    {/*<p className="text-xs text-secondary mb-0">john@creative-tim.com</p>*/}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <p className="text-xs font-weight-bold mb-0">{moment(session.startDate).utc().format('DD-MM-YYYY')}</p>
                                                            {/*<p className="text-xs text-secondary mb-0">Organization</p>*/}
                                                        </td>
                                                        <td className="align-middle text-center text-sm">
                                                            <p className="text-xs font-weight-bold mb-0">{moment(session.endDate).utc().format('DD-MM-YYYY')}</p>
                                                        </td>
                                                        <td className="align-middle text-center text-sm">
                                                            <p className="text-xs font-weight-bold mb-0">
                                                                {session.createdAt ? moment(session.createdAt).utc().format('DD-MM-YYYY') : '-'}
                                                            </p>
                                                        </td>
                                                        <td className="align-middle text-center">
                                                            <button type="button"
                                                                    className="btn btn-secondary" id={session.id}
                                                                    onClick={this.openListSubscription}>list of
                                                                subscriptions
                                                            </button>
                                                        </td>

                                                    </tr>

                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer py-4">
                        <nav aria-label="...">
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={'...'}
                                pageCount={this.state.lastPage}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={6}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination justify-content-end mb-0"}
                                pageClassName={"page-item"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                pageLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </nav>
                    </div>
                </main>
            </div>
        );
    }
}

export default function (props) {
    const navigation = useNavigate();
    //const {state} = useLocation();
    return <SessionManager {...props} myNavigate={navigation}/>
}

export const getDomain = (url) => {
    let domain = null;
    // eslint-disable-next-line
    let domainRegularExpression = /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^\/?\n]+)/igm;
    let domainMatch = domainRegularExpression.exec(url);
    //let sDomain = domain.toString();
    if (domainMatch) {
        domain = domainMatch[1];
    }

    return domain;
}


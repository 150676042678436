import {sPOST_FETCH} from "../protocol/HTTP";
import backendUrls from "../urls/backendUrls";

const CheckoutService = {

    createSubscriptionIntent: async function (data) {
        const url = backendUrls.Checkout.createSubscriptionIntent;
        const {id, stripePaymentClientSecret} = await sPOST_FETCH(url, data).then(r => r.json());
        return {subscriptionIntentId: id, stripeClientSecret: stripePaymentClientSecret};
    },

    confirmSubscriptionIntent: async function (data) {
        const url = backendUrls.Checkout.confirmSubscriptionIntent;
        const {success, message} = await sPOST_FETCH(url, data).then(r => r.json());
        return {status: success, message: message};
    },

    createSubscriptionIntentCourse: async function (data) {
        const url = backendUrls.Checkout.createSubscriptionIntentCourse;
        const {id, stripePaymentClientSecret} = await sPOST_FETCH(url, data).then(r => r.json());
        return {subscriptionIntentId: id, stripeClientSecret: stripePaymentClientSecret};
    },
    confirmSubscriptionIntentCourse: async function (data) {
        const url = backendUrls.Checkout.confirmSubscriptionIntentCourse;
        const {success, message} = await sPOST_FETCH(url, data).then(r => r.json());
        return {status: success, message: message};
    }

}
export default CheckoutService;
import {
  POST,
  sGET,
  sDELETE,
  sGET_FETCH,
  sPOST,
  sPOST_FETCH,
  sPOST_FILE,
  sPUT,
  sDownloadFile,
  GET
} from '../protocol/HTTP';
import backendUrls from '../urls/backendUrls';

const UserService = {
  createAccount: function (data, cbs, cbe) {
    const url = backendUrls.Authentication.register;
    POST(url, data, cbs, cbe);
  },
  activateAccount: function (data, cbs, cbe) {
    const url = backendUrls.Authentication.activateAccount;
    POST(url, data, cbs, cbe);
  },
  tryPasswordReset: function (data, cbs, cbe) {
    const url = backendUrls.Authentication.tryResetPassword;
    POST(url, data, cbs, cbe);
  },
  checkPasswordResetToken: function (data, cbs, cbe) {
    const url = backendUrls.Authentication.checkResetPasswordToken;
    POST(url, data, cbs, cbe);
  },
  resetPassword: function (data, cbs, cbe) {
    const url = backendUrls.Authentication.resetPassword;
    POST(url, data, cbs, cbe);
  },
  login: function (data, cbs, cbe) {
    const url = backendUrls.Authentication.login;
    POST(url, data, cbs, cbe);
  },
  checkAdminOtp: function (data, cbs, cbe) {
    const url = backendUrls.Authentication.CONFIRM_ADMIN_IDENTITY;
    POST(url, data, cbs, cbe);
  },
  logout: function (cbs, cbe) {
    const url = backendUrls.Authentication.logout;
    //alert(getBearerAccessToken());
    sGET(url, cbs, cbe);
  },
  logoutAsync: async function () {
    const url = backendUrls.Authentication.logout;
    const { success, message } = await sGET_FETCH(url, []).then((r) =>
      r.json()
    );
    return { success: success, message: message };
  },
  resendActivationEmail(data, cbs, cbe) {
    const url = backendUrls.Authentication.resentActivationMail;
    sPOST(url, data, cbs, cbe);
  },
  updateInformation: function (id, data, cb) {
    const url = backendUrls.USER.updateInformations;
    sPUT(url, data, cb);
  },
  updatePassword: function (id, data, cb) {
    const url = backendUrls.USER.updatePassword;
    sPUT(url, data, cb);
  },
  refreshUser: async function () {
    const url = backendUrls.Authentication.userRefresh;
    const user = await sGET_FETCH(url).then((r) => r.json());
    return user;
  },
  getAllUsers: function (data, cb) {
    const url = backendUrls.USER.getAllUsers;
    sPOST(url, data, cb);
  },
  changeUserStatus: function (data, cb) {
    const url = backendUrls.USER.changeStatus;
    sPOST(url, data, cb);
  },
  enableUser: function (data, cb) {
    const url = backendUrls.USER.enableUserAccount;
    sPOST(url, data, cb);
  },
  changeUserRoleSimple: function (data, cb) {
    const url = backendUrls.USER.changeRole;
    sPOST(url, data, cb);
  },
  getById: function (id, cb) {
    const url = backendUrls.USER.GET_ONE_AUTHENTICATION_USER + '/' + `${id}`;
    sGET(url, cb);
    console.log('url: ' + url);
  },
  changeEmail: function (data, cb) {
    const url = backendUrls.USER.updateEmail;
    //
    POST(url, data, cb);
  },
  avatar: function (data, cbs, cbe) {
    // console.log("avant de send");
    // console.log(data);
    const url = backendUrls.USER.avatarChangeRequest;
    sPOST_FILE(url, data, cbs, cbe);
  },
  contact: function (data, cb) {
    const url = backendUrls.USER.contactus;
    //
    POST(url, data, cb);
  },
  minMaxAmount: function (data, cb) {
    const url = backendUrls.USER.minMax;
    //
    sPOST(url, data, cb);
  },
  subscriptionToPay: function (cb) {
    const url = backendUrls.USER.toPaySubscriptionList;

    sGET(url, cb);
  },
  toPayPaymentIntent: function (data, cb) {
    const url = backendUrls.USER.toPayPaymentIntent;

    sPOST(url, data, cb);
  },
  confirmPaymentSuccess: async function (data) {
    const url = backendUrls.USER.confirmSuccessPayment;
    const { success, message } = await sPOST_FETCH(url, data).then((r) =>
      r.json()
    );
    return { status: success, message: message };
  },
  filterBySubscriptionPlan: function (data, cb) {
    const url = backendUrls.USER.filterBySubscriptionPlan;

    sPOST(url, data, cb);
  },
  showSubscription: function (data, cb) {
    const url = backendUrls.USER.showSubscription;
    sPOST(url, data, cb);
  },
  showCourseSubscription: function (id, data, cb) {
    const url = backendUrls.USER.showCourseSubscription+"/"+`${id}`;
    sPOST(url, data, cb);
  },
  showStatistics: function (data, cb) {
    const url = backendUrls.USER.showStatistics;
    sPOST(url, data, cb);
  },
  showCourseStatistics: function (data, cb) {
    const url = backendUrls.COURSE.showCourseStats;
    sPOST(url, data, cb);
  },
  subscriptionToConfirm: function (cb) {
    const url = backendUrls.USER.subscriptionToConfirm;
    sGET(url, cb);
  },
  courseSubscriptionToConfirm: function (cb) {
    const url = backendUrls.USER.courseSubscriptionToConfirm;
    sGET(url, cb);
  },
  checkExistingSubscription: function (data, cb) {
    const url = backendUrls.USER.checkSubscription;

    sPOST(url, data, cb);
  },
  checkExistingSubscriptionCourse: function (data, cb) {
    const url = backendUrls.USER.checkSubscriptionCourse;

    sPOST(url, data, cb);
  },
  overview: function (cb) {
    const url = backendUrls.USER.overview;

    sGET(url, cb);
  },
  registerForNewsletter: function (data, cb) {
    const url = backendUrls.USER.registerNewsletter;

    POST(url, data, cb);
  },
  getAllSubscribers: function (data, cb) {
    const url = backendUrls.USER.newsletterSubscribers;
    sPOST(url, data, cb);
  },
  addSubscription: function (data, cb) {
    const url = backendUrls.USER.manualSubscription;

    sPOST(url, data, cb);
  },
  addCourseSubscription: function (data, cb) {
    const url = backendUrls.USER.courseManualSubscription;

    sPOST(url, data, cb);
  },

  getProfilUser: function (id, cb){
    const url=backendUrls.USER.getProfilUser+"/"+`${id}`;

    sGET(url, cb);
},

getInvoice: function (fileName,data){
  const url = backendUrls.USER.invoice;
  sDownloadFile(url,fileName,data);
},

  getCourseInvoice: function (fileName,data){
    const url = backendUrls.USER.courseInvoice;
    sDownloadFile(url,fileName,data);
  },

updateProfilUser: function (data, cb) {
  const url=backendUrls.USER.updateProfilUser;
  sPUT(url, data, cb)
},

updatePasswordUser: function (data, cb) {
  const url=backendUrls.USER.updatePasswordUser;
  sPUT(url, data, cb)
},

  async generateOtp() {
    const url = backendUrls.USER.GET_OTP;
    const { success, otp } = await sGET_FETCH(url, []).then((r) => r.json());

    if (success) {
      return { otp: otp.otp };
    }
    return { otp: null };
  },

  async verifyAccountOnWordpress(data) {
    const url = backendUrls.USER.VERIFY_EXIST_WP_ACCOUNT;
    //console.log(url)
    return await sPOST_FETCH(url, data).then((r) => r.json());
  },

  editSubscription: function (data, cb) {
    const url = backendUrls.USER.editSubscription;

    sPOST(url, data, cb);
  },

  getAllSessions: function (cb) {
    const url = backendUrls.USER.GET_ALL_SESSION;
    sGET(url, cb);
  },

  getSubscribedSessionInfo: function (data, cb) {
    const url = backendUrls.USER.GET_SUBSCRIBED_SESSION_INFO;
    sPOST(url, data, cb);
  },

  paymentDiscount: function (data, cb) {
    const url = backendUrls.USER.PAYMENT_DISOUNT;
    sPOST(url, data, cb);
  },
  confirmDiscountSuccess: async function (data) {
    const url = backendUrls.USER.CONFIRM_DISOUNT;
    const { success, message } = await sPOST_FETCH(url, data).then((r) =>
        r.json()
    );
    return { status: success, message: message };
  },

  getAuthSetting: function (cb, AUTH_REGISTER= "") {
    const url = backendUrls.USER.GET_ONE_AHTH_PAGE +( (AUTH_REGISTER!="")?`/${AUTH_REGISTER}`:"");
    GET(url, cb);
},


CheckEligibilityCoupon: function ( cb) {
  //const url=backendUrls.SESSION.GET_USER_SUBSCRIPTION;
  const url = backendUrls.USER.GET_ELIGIBILITY_COUPON ;
  console.log(url)
  sGET(url, cb);
},

createAuthSetting: function (data, cb) {
  const url = backendUrls.USER.CREATE_NEW_IMAGE;
  sPOST_FILE(url, data, cb);
},

deleteAuthSetting: function (id, cb) {
  // eslint-disable-next-line
  const url = backendUrls.USER.DELETE + `/${id}`;
  sDELETE(url, cb);
},
getDiscountCode: function (datas, cb) {
  const url = `${backendUrls.USER.GET_COUPON_CODE}/${datas.code}/${datas.subscriptionPlanId}/${datas.Course || datas.Session}`; 
  sGET(url, cb);
},

// getDiscountCodes: function (datas, cb) {
//   const url = `${backendUrls.USER.GET_COUPON_CODE}/${datas.code}/${datas.subscriptionPlanId}/${datas.Course}`; 
//   sGET(url, cb);
// },

};
export default UserService;

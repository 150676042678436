import React, {Component} from "react";
import "primereact/resources/themes/mira/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import {
    getDiscountEligibility,
    getUser,
    hasCredential, isDiscountEligibility,
    redirectionUrl,
    removeLastUrl,
    setLastUrl
} from "./network/auth/Auth";
import {BrowserRouter as Router, Navigate, Route, Routes,} from "react-router-dom";
import listRoutes from "./routes/routes";
import ScrollToTop from "./components/common/ScrollToTop";
import UserService from "./network/services/UserService";

const PrivateRoute = ({children, role, path}) => {
    //const auth = useAuth();
    let user = getUser();
    let defaultUrl = "/login";
    if(user){
        defaultUrl= redirectionUrl(user.role);
    }
    const auth = hasCredential(role);
    const loc = window.location.href;
    //const location =path;
    const location = loc;
    if (!auth) {
        setLastUrl(location);
    } else {
        removeLastUrl();
    }
    return auth ? children : <Navigate to={{pathname: defaultUrl}}/>;
};

class App extends Component {


    componentDidMount = () => {
        window.scrollTo(0, 0);

    };

    render() {
        //parse and arrange routes;
        let allRoutes = [];
        listRoutes.map(({spaceName, secure, role, routes}) => {
            return routes.map(({path, component}) => {
                return allRoutes.push({
                    path: path,
                    component: component,
                    role: role,
                    secure: secure,
                });
            });
        });

        //allRoutes is an array of objects where each object is constituted of a path, a component, a secure boolean and a role.
        const routeComponents = allRoutes.map(
            ({path, component, secure, role}, key) => {
                return secure ? (
                    <Route
                        exact
                        path={path}
                        element={
                            <PrivateRoute role={role} path={path}>
                                {component}
                            </PrivateRoute>
                        }
                        key={key}
                    />
                ) : (
                    <Route exact path={path} element={component} key={key}/>
                );
            }
        );



        return (
            <Router>
                <ScrollToTop>
                    <Routes>{routeComponents}</Routes>
                </ScrollToTop>
            </Router>
        );
    }
}

export default App;

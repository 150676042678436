import {sDELETE, sGET, sPOST, sPUT} from "../protocol/HTTP";
import backendUrls from "../urls/backendUrls";

const SubscriptionPlanService = {
    getAll: function (cb) {
        const url = backendUrls.SessionPlan.subscription_plans;
        sGET(url, cb);

    },
    getById: function (id, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SessionPlan.subscription_plans + "/" + `${id}`;
        sGET(url, cb);
    },
    unsecureGetById: function (id, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SessionPlan.GET_ONE + "/" + `${id}`;
        sGET(url, cb);
    },
    create: function (data, cb) {
        const url = backendUrls.SessionPlan.subscription_plans;
        sPOST(url, data, cb);

    },
    update: function (id, data, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SessionPlan.subscription_plans + "/" + `${id}`;
        sPUT(url, data, cb);

    },
    delete: function (id, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SessionPlan.subscription_plans + "/" + `${id}`;
        sDELETE(url, cb);
    },

    getMostUsedPlan: function (cb) {
        const url = backendUrls.SubscriptionPlan.GET_MOST_USED_PLAN;
        sGET(url, cb);
    },

}

export default SubscriptionPlanService;
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthLayout from "../../../layouts/AuthLayout";
import Illustration from "../../../components/frontv2.0/common/illustration/Illustration";
import { FAVICON_WITHE, IMAGE_3 } from "../../../constants/images";
import Logo from "../../../components/frontv2.0/common/logo/Logo";
import TextHeader from "../../../components/frontv2.0/common/text/TextHeader";
import InputCustom from "../../../components/frontv2.0/common/form/InputCustom";
import ButtonCustom from "../../../components/frontv2.0/common/button/ButtonCustom";
import Alert from "../../../components/frontv2.0/common/alert/Alert";
import UserService from "../../../network/services/UserService";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../../constants/Constants";
import {getAuthSettings, isAuthenticated, saveAuthSettings} from "../../../network/auth/Auth";
import Loader from "../../../components/frontv2.0/common/loader/Loader";
import {MEDIA_URL_ACCOUNTING_AUTH_SETTINGS} from "../../../network/urls/backendUrls";

const SignUp = () => {
  const [user, setUser] = useState({
    name: "",
    firstName: "",
    email: "",
    password: "",
    passwordConfirm: "",
  });
  const [loading, setLoading] = useState(false);
  const [toShow, setToShow] = useState("form");
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [registrationAttempt, setRegistrationAttempt] = useState(false);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [fileUrl, setfileUrl] = useState("");
  const [registerMessage, seteRegisterMessage] = useState("");
  const [firstLoading, setFirstLoading] = useState(true)

  const handleOnClickRegister = () => {
    setToShow("form");
    setRegistrationAttempt(false);
  };

  const validateEmail = (email) => {
    return EMAIL_REGEX.test(email);
  };

  const validatePassword = (password) => {
    const passwordRegex = PASSWORD_REGEX;
    return passwordRegex.test(password);
  };

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setMessage("");
    setRegistrationAttempt(false);

    const newErrors = {};

    if (user.firstName.trim() === "") {
      newErrors.firstName = "Please enter your first name.";
    }

    if (user.name.trim() === "") {
      newErrors.name = "Please enter your name.";
    }

    if (!validateEmail(user.email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    if (!validatePassword(user.password)) {
      newErrors.password =
          "The password must be at least 6 characters and contain at least one uppercase letter, one lowercase letter and one number.";
    }

    if (user.password !== user.passwordConfirm) {
      newErrors.passwordConfirm = "Passwords do not match.";
    }

    if (Object.keys(newErrors).length === 0) {
      const userUpload = {
        firstName: user.firstName,
        name: user.name,
        email: user.email,
        password: user.password,
      };

      UserService.createAccount(
          userUpload,
          (data) => {
            setLoading(false);
            if (data !== null) {
              const message = data.message;
              if (data.success) {
                setRegistrationSuccess(true);
                setMessage(message);
                setToShow(message);
                setRegistrationAttempt(true);
                setUser({
                  ...user,
                  name: "",
                  firstName: "",
                  email: "",
                  password: "",
                  passwordConfirm: "",
                });
              } else {
                setRegistrationSuccess(false);
                setMessage(message);
                setRegistrationAttempt(true);
              }
            } else {
              setRegistrationSuccess(false);
              setMessage("Error : Unable to connect");
              setRegistrationAttempt(true);
            }
          },
          (err) => {
            setRegistrationSuccess(false);
            setMessage(err.message);
            setRegistrationAttempt(true);
          }
      );
    } else {
      setErrors(newErrors);
      setLoading(false);
    }
  }

  function updateField(e) {
    const {name, value} = e.target;

    setErrors({
      ...errors,
      [name]: "",
    });

    setUser({
      ...user,
      [name]: value,
    });
  }

  const filterSettings = (response) => {
    const elt = response.filter((e) => e.page === "AUTH_REGISTER")[0];
    if(elt){
    seteRegisterMessage(elt.message)
    setfileUrl(elt.file);
    }
  }
  const getLoginAuthSettings = () => {
    setFirstLoading(true);
    const settings = getAuthSettings();

    if (settings && settings.length > 0) {
      filterSettings(settings);
      //seteRegisterMessage(settings.message);
      //setfileUrl(settings.file);
    } else {

      UserService.getAuthSetting( (response) => {
        if (response) {
          if (response.success) {
            saveAuthSettings(response.data);
            filterSettings(response.data);
          }
        }
      });
    }
    setFirstLoading(false);
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const renderContent = () => {
    return (
        <>
          {toShow === "form" ? (
              <>
                <div className="">
                  <InputCustom
                      id={"fisrtName"}
                      type={"text"}
                      icon={"pi pi-user"}
                      placeHolder={"First name."}
                      name={"firstName"}
                      onChange={updateField}
                      value={user.firstName || ""}
                      error={errors?.firstName ? errors.firstName : ""}
                  />
                </div>

                <div className="tw-mt-[18px]">
                  <InputCustom
                      id={"name"}
                      type={"text"}
                      icon={"pi pi-user"}
                      placeHolder={"Last name."}
                      name={"name"}
                      onChange={updateField}
                      value={user.name || ""}
                      error={errors?.name ? errors.name : ""}
                  />
                </div>

                <div className="tw-mt-[18px]">
                  <InputCustom
                      id={"email"}
                      type={"text"}
                      icon={"pi pi-envelope"}
                      placeHolder={"E-mail"}
                      name={"email"}
                      onChange={updateField}
                      value={user.email || ""}
                      error={errors.email ? errors.email : ""}
                  />
                </div>

                <div className="tw-mt-[18px]">
                  <InputCustom
                      id={"password"}
                      type={"password"}
                      icon={"pi pi-lock"}
                      placeHolder={"Password"}
                      name={"password"}
                      onChange={updateField}
                      showPassword={showPassword}
                      handleShowPassword={handleShowPassword}
                      error={errors?.password ? errors.password : ""}
                  />
                </div>

                <div className="tw-mt-[18px]">
                  <InputCustom
                      id={"passwordConfirm"}
                      type={"password"}
                      icon={"pi pi-lock"}
                      placeHolder={"Confirm password."}
                      name={"passwordConfirm"}
                      onChange={updateField}
                      showPassword={showPassword}
                      handleShowPassword={handleShowPassword}
                      error={errors?.passwordConfirm ? errors.passwordConfirm : ""}
                  />
                </div>

                <div className="tw-mt-[26px]">
                  {loading ? (
                      <ButtonCustom
                          classes={
                            "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                          }
                          label="Loading..."
                          icon={"pi pi-spin pi-spinner"}
                          disabled={true}
                      />
                  ) : (
                      <ButtonCustom
                          classes={
                            "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                          }
                          label="Create an Account"
                          onClick={handleSubmit}
                          disabled={
                            user.email && user.password && user.passwordConfirm
                                ? false
                                : true
                          }
                      />
                  )}
                </div>

                <p className="tw-mt-3 tw-text-sm tw-text-center tw-text-gray-500">
                  I have an account,{" "}
                  <Link to="/login">
                <span className="tw-text-[#309255] tw-underline hover:tw-text-primary-700">
                  login
                </span>
                  </Link>
                </p>
              </>
          ) : (
              <>
                <div className="tw-mt-[42px]">
                  <Link to="/login">
                    <ButtonCustom
                        classes={
                          "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                        }
                        label="Login"
                    />
                  </Link>
                </div>
                <div className="tw-mt-6">
                  <Link to="/register">
                    <ButtonCustom
                        onClick={handleOnClickRegister}
                        classes={
                          "tw-border-[1.5px] tw-border-[#309255] tw-text-[#309255] hover:tw-text-white hover:tw-bg-[#309255]"
                        }
                        label="Sign up here"
                    />
                  </Link>
                </div>
              </>
          )}
        </>
    );
  };

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/workspace/mysessions");
    }
    getLoginAuthSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
      <>
        {firstLoading ?
            <div className="tw-h-screen tw-w-full tw-flex tw-justify-center tw-items-center">
              <Loader/>
            </div> :
            <AuthLayout>
              <AuthLayout.Body>
                <div className="tw-flex tw-flex-col tw-justify-center tw-px-6 tw-py-10 lg:tw-px-8 tw-h-full">
                  <div
                      className="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-sm tw-items-center tw-flex tw-justify-center sm:tw-justify-start">
                    <Logo width={200}/>
                  </div>
                  <div
                      className="tw-w-full sm:tw-mx-auto sm:tw-max-w-sm tw-flex sm:tw-items-center sm:tw-my-auto tw-pt-4 tw-overflow-y-auto">
                    <div className="tw-w-full">
                      <div
                          className="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-sm sm:tw-justify-start tw-flex tw-justify-center tw-items-center tw-mt-4 sm:tw-mt-0 ">
                        <div className="tw-w-full tw-justify-center tw-items-center tw-flex tw-flex-col sm:tw-block">
                          <TextHeader
                              label={"Registration"}
                              textUnderline={"Now"}
                              size={3}
                          />
                          <p className="tw-mb-2 tw-text-gray-500 tw-text-[16px] tw-font-normal tw-text-center sm:tw-text-start">
                            To subscribe and access the course, you must create an
                            account.
                          </p>
                        </div>
                      </div>
                      <div className="tw-w-full">
                        {registrationAttempt ? (
                            <Alert
                                type={registrationSuccess ? "success" : "danger"}
                                message={message}
                            />
                        ) : null}
                        <form className="tw-px-[2px]">{renderContent()}</form>
                      </div>
                    </div>
                  </div>
                </div>
              </AuthLayout.Body>

              <AuthLayout.Illustration>
                <Illustration
                    favIcon={FAVICON_WITHE}
                    image={fileUrl?
                        MEDIA_URL_ACCOUNTING_AUTH_SETTINGS + fileUrl
                        :IMAGE_3}
                    label={registerMessage ? registerMessage : `"Linux, AWS and DevOps Training."`}
                />
              </AuthLayout.Illustration>
            </AuthLayout>}
      </>
  )};

export default SignUp;
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import LastSubscriptionService from "../../../../network/services/LastSubscriptionService";
import {getUser} from "../../../../network/auth/Auth";
import UserService from "../../../../network/services/UserService";
import MainLayout from "../../../../layouts/MainLayout";
import Loader from "../../../../components/frontv2.0/common/loader/Loader";
import PaymentAlert from "../../../../components/frontv2.0/common/status/PaymentAlert";
import OrderSummary from "../../../../components/frontv2.0/common/sessions/OrderSummary";
import Header from "../../../../components/frontv2.0/common/header/Header";
import SubHeader from "../../../../components/frontv2.0/common/header/SubHeader";
import DiscountOrderSummary from "./DiscountOrderSummary";

const DiscountSuccess = () => {
    const navigate = useNavigate();
    /**
     * States
     */
    const [firstLoader, setFirstLoader] = useState(false);
    const [paymentSuccessInfo, setPaymentSuccessInfo] = useState({});
    const [SummaryData, setSummaryData] = useState({});
    const [courseId, setCourseId] = useState("");
    const [course, setCourse] = useState({})
    const { subscriptionId, sessionId } = useParams();
    /**
     * Comportments
     */
    const showLastsubscription = () => {
        setFirstLoader(true);
        const data = {
            subscription_id:subscriptionId
        }
        LastSubscriptionService.GET_DISCOUNT_INFO(data,(response) => {
            if (response) {
                const paymentSuccesInfo = {
                    title: `Congratulations, you've just completed your payment with discount`,
                    subTitle: `You will get an email confirmation sent to ${response.data.user_email}`,
                    enableButtonAction: true,
                    redirectButtonLabel: "Go to My Transactions",
                    downloadButtonLabel: "Download Invoice",
                    urlToRedirect: "/workspace/transactions",
                };
                const summaryData = {
                    title: "Order Summary",
                    product: `${response.data.session_name}`,
                    paymentMethod: "Card",
                    totalPaid: `${response.data.total_paid_amount}`,
                    previous_subscription_price: `${response.data.previous_subscription_price}`,
                    installmentAmount: `${response.data.installment_amount}`,
                    discountAmount: `${response.data.discount_amount}`,
                };
                 setCourseId("66");
                 setPaymentSuccessInfo(paymentSuccesInfo);
                 setSummaryData(summaryData);
                setCourse(response.data)
            }
            setFirstLoader(false);
        });
    };

    const getInvoice = () => {
        let user = getUser();
        let data = {
            user_id: user.id,
            session_id: sessionId,
        };
        UserService.getInvoice("Utrains-subscription-invoice.pdf", data);
    };

    useEffect(() => {
        showLastsubscription();
    }, []);

    const handleOnclick = () => {
        navigate("/workspace/transactions");
    };

    /**
     * Render
     */
    return (
        <>
            <MainLayout>
                <MainLayout.Content>
                    <div
                        className={`tw-flex md:tw-flex-row tw-flex-col tw-w-full md:tw-space-x-2 md:tw-space-y-0'`}
                    >
                        {firstLoader ? (
                            <div className="tw-flex tw-flex-col md:tw-justify-center tw-justify-center tw-items-center tw-py-16">
                                <Loader />
                            </div>
                        ) : (
                            <>
                                <PaymentAlert
                                    data={paymentSuccessInfo}
                                    onClickToDownload={getInvoice}
                                    onClickToRedirect={handleOnclick}
                                />

                                {/* SectionLeft */}
                                <DiscountOrderSummary data={SummaryData} />
                            </>
                        )}
                    </div>
                </MainLayout.Content>

                <MainLayout.Header>
                    <Header />
                </MainLayout.Header>

                <MainLayout.SubHeader>
                    <SubHeader
                        data={[
                            { value: "Home", url: "/workspace" },
                            { value: "Available Courses", url: "/workspace/courses" },
                            //{ value: course, url: "/workspace/courses/details/" },
                            { value: "Checkout", url: "#" },
                        ]}
                    />
                </MainLayout.SubHeader>
            </MainLayout>
        </>
    );
};

export default DiscountSuccess;

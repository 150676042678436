import React, { useEffect, useRef, useState } from "react";
import ButtonCustom from "../../frontv2.0/common/button/ButtonCustom";
import InputCustom from "../../frontv2.0/common/form/InputCustom";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import Sidebar from "../sidebar/Sidebar";
import SessionService from "../../../network/services/SessionService";
import { UNIT_OPTIONS, INTERVAL_OPTIONS } from '../ConfigConstants/ConfigConstants';
import Loader from "../../../components/frontv2.0/common/loader/Loader";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { PrimeIcons } from 'primereact/api';
import { Button } from 'primereact/button';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import AdminHeader from "../header/AdminHeader";


const ConfigDelayAndDiscount = () => {
    const [state, setState] = useState({
        sessionId: "",
        name: "",
        sessionName: "",
        attempt: "",
        unit: "",
        discount_expired_at: "",
        isFormVisible: true,
        edition: false,

    });
    const toast = useRef(null);
    const [id, setId] = useState("");
    const [attempt, setAttempt] = useState("");
    const [value, setValue] = useState("");
    const [sessionId, setSessionId] = useState("");
    const [sessionName, setSessionName] = useState("");
    const [session, setSession] = useState("");
    const [newDate, setNewDate] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [rescheduleDateSelected, setRescheduleDateSelected] = useState(false);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [postponeInterval, setpostponeInterval] = useState("");
    const [daysDifference, setDaysDifference] = useState("");
    const [chooseDays, setChooseDays] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [calendarMinDate, setCalendarMinDate] = useState('');
    const [calendarMaxDate, setCalendarMaxDate] = useState('');
    const [selectedSessionStartDate, setSelectedSessionStartDate] = useState('');
    const [isDateSelectable, setIsDateSelectable] = useState(false);
    const [selectedDate, setSelectedDate] = useState('');
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    let [nextSessionsData, setNextSessionsData] = useState([]);
    let [tableData, setTableData] = useState([]);
    let [nextSessionsDataError, setNextSessionsDataError] = useState(null);
    const [weeksDifference, setWeeksDifference] = useState('');


    const futureInterval = nextSessionsData.map((item, index) => {
        return {
            id: item.id,
            name: item.name,
            interval: item.maximum_reporting_interval,
            attempt: item.payment_postponement_attempt_count
            ,
            expiresAt: item.discount_expired_at,

        };

    });



    const handleNewDateChange = (event) => {
        const value = event.target.value;
        setSelectedSessionStartDate(event.target.value);
        const timeDifference = new Date(value).getTime() - new Date(startDate).getTime();
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
        setDaysDifference(daysDifference);
        setpostponeInterval(value);

        const weeksDifference = Math.ceil(daysDifference / 7);
        setWeeksDifference(weeksDifference)

    };

    const onSessionChange = (event) => {
        const sessionName = event.target.value;
        const selectedOption = event.target;
        const sessionId = selectedOption.options[selectedOption.selectedIndex].dataset.optionId;
        setSessionName(sessionName);
        setSessionId(sessionId);
        setSession(sessionName);

        if (sessionName) {
            const selectedSession = tableData.find((option) => option.name === sessionName);
            if (selectedSession) {
                const { start_date, end_date } = selectedSession;
                setStartDate(start_date);
                setEndDate(end_date);

                setCalendarMinDate(start_date);
                setCalendarMaxDate(end_date);
                setSelectedSessionStartDate(start_date.split('T')[0]);
                setIsDateSelectable(true);
            }
        } else {
            setIsDateSelectable(false); // Désactiver le champ de date si aucune session n'est sélectionnée
        }
    }


    const handleChooseDaysChange = (event) => {
        const chooseDays = event.target.value;
        setChooseDays(chooseDays)
    }

    const confirm1 = (event) => {
        event.preventDefault();
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            // accept: this.state.accept,
            accept: () => {
                const id = state.sessionId;
                deleteDiscount()
                SessionService.DeleteDelays(id, (response) => {
                    if (response != null) {
                        if (response.success) {
                            showSuccess("Deletion successful");
                            // console.log(nextSessionsData);
                            showSession();
                        } else {
                            showError(response.message);
                        }
                    } else {
                        showError("Erreur inconnue");
                    }
                });
                resetForm();
                // setVisible(false)
                setIsFormOpen(false)
                // console.log('User clicked on Yes');
                setIsLoading({ isLoading: false });
            },

            reject: () => {
                // setVisible(false)
                setIsFormOpen(false)
                resetForm();
                // console.log('User clicked on No');

            }
        });
    };



    const handleCancelClick = () => {
        setIsFormOpen(false)
        resetForm();
    };

    const handleEdit = (sessionId) => {
        setIsFormOpen(true)
        // console.log(sessionId)
        const session = nextSessionsData.find((session) => session.id === sessionId);
        if (session) {
            const { name, id, payment_postponement_attempt_count
                , maximum_reporting_interval, discount_expired_at
            } = session;

            setState({
                ...state,
                sessionName: name,
                attempt: payment_postponement_attempt_count
                ,
                unit: maximum_reporting_interval,
                discount_expired_at: discount_expired_at,
                sessionId: id

            });
        }
    };

    const handleCloseForm = () => {
        setShowForm(false);
        setIsFormOpen(false)

    };


    const renderRows = () => {
        return futureInterval;

    }
    const show = (e) => {
        e.preventDefault();
        setVisible(true);
    };

    const showSuccess = (message) => {
        toast.current.show({
            severity: "success",
            summary: "Success",
            detail: message,
            life: 5000,
        });
    };

    const showError = (message) => {
        toast.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            life: 3000,
        });
    };

    const handleButtonClick = () => {
        setVisible(true);
    };

    const onHide = () => {
        resetForm();

    };

    const hide = () => {
        handleClose()


        setState({
            ...state,
            isFormVisible: false
        });
    }

    const resetForm = () => {
        setValue("");
        setpostponeInterval('');
        setSession('');
        setAttempt('');
        setVisible(false);
        setIsDateSelectable(false);
        setSelectedSessionStartDate('');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsFormOpen(false);
        setShowForm(false);
        setpostponeInterval('');
        setSession('');
        setAttempt('');
        onHide();
        setSessionId('');
        setIsDateSelectable(false);
        setSelectedSessionStartDate('');
    };

    const showSession = () => {
        setIsLoading(true);
        const page_url = 1;
        const nombre_items = 5;

        SessionService.getSessionWithoutPagination((response) => {
            if (response) {
                console.log(response)
                const reversedResponse = response.reverse();

                setTableData(reversedResponse);
                const filteredSessions = response.filter(session =>
                    session.payment_postponement_attempt_count
                    !== null ||
                    session.maximum_reporting_interval !== null ||
                    session.discount_expired_at !== null
                ).reverse();

                setNextSessionsData(filteredSessions);
                setIsLoading(false);
            } else {
                showError("Erreur inconnue");
            }

        });
    };
    const handleClose = () => {
        setState({
            ...state,
            sessionName: "",
            attempt: "",
            unit: "",
            discount_expired_at: "",
            setChooseDays: ""

        });
    };

    // const onSessionChange = (event) => {
    //     const sessionName = event.target.value;
    //     const selectedOption = event.target;
    //     const sessionId = selectedOption.options[selectedOption.selectedIndex].dataset.optionId;
    //     setSessionName(sessionName)
    //     setSessionId(sessionId)
    //     setSession(sessionName)
    //     if (sessionName) {
    //         const { start_date,end_date

    //         } = sessionName;

    //         setState({
    //             ...state,
    //             startDate: start_date,

    //             endDate: end_date

    //         });
    //     }

    //     console.log(startDate)
    // };

    const onValueChange = (e) => {
        const value = e.target.value;
        setValue(value);
        if (value === "maximum reschedule date") {
            setRescheduleDateSelected(true);
        } else if (value === "maximum discount date") {
            setRescheduleDateSelected(false);
        }

        setpostponeInterval('');
        setSession('');
        setAttempt('');
        setSelectedDate('')
        setChooseDays('')
        setIsDateSelectable(false);

    };


    const onAttemptChange = (e) => {
        const attempt = e.target.value;
        setAttempt(attempt);
    };


    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setState({
            ...state,
            [name]: value,
        });

    };

    const handleEditClick = (sessionId) => {
        setShowForm(true);
        const session = nextSessionsData.find((session) => session.id === sessionId);
        if (session) {
            const { name, id, payment_postponement_attempt_count
                , maximum_reporting_interval, discount_expired_at
            } = session;

            setState({
                ...state,
                sessionName: name,
                attempt: payment_postponement_attempt_count
                ,
                unit: maximum_reporting_interval,
                discount_expired_at: discount_expired_at,
                sessionId: id

            });
        }
    };

    const CreateSettingsDelays = () => {
        if (value === "Maximum reschedule date") {
            let data = {
                sessionId: sessionId,
                numberOfAttempt: attempt,
                maximumReportingInterval: chooseDays
            };


            SessionService.CreateSettingsDelay(data, (response) => {
                if (response != null) {
                    if (response.success) {

                        showSuccess(response.message);
                        showSession()
                    } else {
                        showError(response.message);
                    }
                } else {
                    showError("Erreur inconnue");
                }
            });
        } else if (value === "Maximum discount date") {
            let data = {
                sessionId: sessionId,
                expiredAt: daysDifference
            };

            SessionService.CreateSettingsDiscount(data, (response) => {
                if (response != null) {
                    if (response.success) {
                        showSession()
                        showSuccess(response.message);

                        showSession()
                    } else {
                        showError(response.message);
                    }
                } else if (response != null && response.success === false) {
                    showError(response.message);
                } else {
                    setNextSessionsDataError({ message: "Erreur inconnue" });
                }
            });
        }
    };


    const EditDelays = () => {
        EditDiscount()

        let data = {
            sessionId: state.sessionId,
            expiredAt: state.discount_expired_at,
            maximumReportingInterval: state.unit,
            numberOfAttempt: state.attempt
        };


        SessionService.CreateSettingsDelay(data, (response) => {
            if (response != null) {
                if (response.success) {

                    showSuccess(response.message);
                    showSession()
                } else {
                    showError(response.message);
                }
            } else {
                showError("Erreur inconnue");
            }
        });
    }

    const EditDiscount = () => {

        let data = {
            sessionId: state.sessionId,
            expiredAt: state.discount_expired_at,
        };


        SessionService.CreateSettingsDiscount(data, (response) => {
            if (response != null) {
                if (response.success) {

                    showSession()
                } else {
                    showError(response.message);
                }
            } else {
                showError("Erreur inconnue");
            }
        });

    };



    const deleteDiscount = (sessionId) => {
        const id = state.sessionId;

        SessionService.DeleteDiscount(id, (response) => {
            if (response != null) {
                if (response.success) {

                    // showSuccess(response.message);
                    showSession()
                } else {
                    showError(response.message);
                }
            } else {
                showError("Erreur inconnue");
            }
        });

    };

    useEffect(() => {
        showSession();

    }, []);


    return (
        <>
            <Sidebar />
            <Toast ref={toast} position="top-right" />
            {isLoading ? (

                <div className="tw-flex tw-h-full tw-flex-col md:tw-justify-center tw-justify-center tw-items-center mt-3">

                    <Loader />

                </div>
            ) : (

                <>
                    <main className="mainAdmin r ms-100">
                        <AdminHeader location="Configurations"/>

                        <div className="tw-flex tw-flex-row tw-space-x-4 mt-3" style={{ marginLeft: '20px', marginRight: '20px' }}>
                            <ButtonCustom
                                label="ADD CONFIGURATION"
                                onClick={handleButtonClick}
                                classes="tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                            />
                        </div>
                        <Dialog
                            header="Please set up the rescheduling and discount dates for a session."
                            visible={visible}
                            style={{
                                width: '62vw',
                                height: '76vh',
                                marginLeft: '3vh',
                            }}
                            onHide={onHide}

                        >
                            <form
                                id="create-session-form"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    minHeight: "12vh",

                                }}
                                onSubmit={handleSubmit}
                            >
                                <div style={{
                                    marginTop: "1%",
                                    width: "700px"
                                }}>
                                    <span>Manage dates</span>
                                    <select
                                        id="valueSelect"
                                        className="tw-border tw-border-gray-300 tw-rounded-lg tw-py-2 tw-px-1 tw-w-full tw-bg-white focus:tw-ring-2 focus:tw-ring-blue-500 tw-outline-none"
                                        // value={value}

                                        onChange={onValueChange}
                                        type="text"
                                    >
                                        <option value="">Select Here</option>
                                        <option value="Maximum reschedule date">Maximum reschedule date</option>
                                        <option value="Maximum discount date">Maximum discount date</option>
                                        {/* Add more options if needed */}
                                    </select>
                                </div>

                                {value === "Maximum reschedule date" && (
                                    <>

                                        <div style={{
                                            marginTop: "10px",
                                            width: "700px"
                                        }}>
                                            <span>Choose a session</span>
                                            <select
                                                style={{ marginTop: "1px" }}
                                                id="session"
                                                className="tw-border tw-border-gray-300 tw-rounded-lg tw-py-2 tw-px-1 tw-w-full tw-bg-white focus:tw-ring-2 focus:tw-ring-blue-500 tw-outline-none"
                                                // value={session}
                                                type="text"
                                                name="session"
                                                onChange={onSessionChange}
                                            >
                                                <option value="">Choose a session</option>
                                                {tableData.map((option, index) => (
                                                    <option
                                                        key={index}
                                                        value={option.name}
                                                        data-option-id={option.id}
                                                    >
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                       



                                        <div style={{
                                            marginTop: "15px",
                                            width: "700px"
                                        }}>
                                            <span >Maximum number of reports for users</span>
                                            <select

                                                style={{
                                                    marginTop: "0px",

                                                }}
                                                id="attempt"
                                                type="number"
                                                className="tw-border tw-border-gray-300 tw-rounded-lg tw-py-2 tw-px-1 tw-w-full tw-bg-white focus:tw-ring-2 focus:tw-ring-blue-500 tw-outline-none"
                                                // value={attempt}
                                                onChange={onAttemptChange}
                                            >
                                                {INTERVAL_OPTIONS.map(option => (
                                                    <option key={option.value} value={option.value}>{option.label}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="tw-mb-4" style={{ width: "700px" }}>
                                            <label htmlFor=""
                                                className="tw-block"
                                                style={{ marginTop: "13px", fontSize: "16px" }}>
                                                Maximum duration of postponement expressed in (Days)
                                            </label>
                                            <input style={{ marginTop: "-7px" }}
                                                type="number"
                                                id="newNumber"
                                                min="1"
                                                max="1000"
                                                className="tw-border tw-border-gray-300 tw-rounded-lg tw-py-2 tw-px-1 tw-w-full tw-bg-white focus:tw-ring-2 focus:tw-ring-blue-500 focus:tw-outline-none"
                                                onChange={handleChooseDaysChange}
                                            />
                                        </div>

                                        <div style={{
                                            marginTop: "20px",
                                            width: "700px",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}>
                                            <ButtonCustom
                                                classes={

                                                    !rescheduleDateSelected && (attempt === '' || session === '' || chooseDays === "")
                                                        ? "tw-bg-gray-300 tw-text-gray-500" : "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                                                }
                                                label="Save"
                                                onClick={CreateSettingsDelays}
                                                icon="pi pi-sync"
                                                disabled={
                                                    !rescheduleDateSelected && (attempt === '' || session === '' && chooseDays === "")
                                                }
                                            />
                                            {/* <ButtonCustom
                                                classes={!rescheduleDateSelected && (attempt === '' || session === '' || chooseDays === '') ? "tw-bg-gray-300 tw-text-gray-500" : "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
                                                label="Save"
                                                onClick={CreateSettingsDelays}
                                                icon="pi pi-sync"
                                                disabled={!rescheduleDateSelected || attempt === '' || session === '' || chooseDays === ''}
                                            /> */}
                                        </div>
                                    </>
                                )}

                                {value === "Maximum discount date" && (
                                    <>

                                        <div style={{
                                            marginTop: "20px",
                                            width: "700px"
                                        }}>
                                            <span>Choose a session</span>
                                            <select
                                                style={{ marginTop: "0px" }}
                                                id="session"
                                                className="tw-border tw-border-gray-300 tw-rounded-lg tw-py-2 tw-px-1 tw-w-full tw-bg-white focus:tw-ring-2 focus:tw-ring-blue-500 tw-outline-none"
                                                value={session}
                                                onChange={onSessionChange}

                                            >
                                                <option value="">Choose a session</option>
                                                {tableData.map((option, index) => (
                                                    <option
                                                        key={index}
                                                        value={option.name}
                                                        data-option-id={option.id}
                                                    >
                                                        {option.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>


                                        <div className="tw-mt-4" style={{ width: "700px" }}>
                                            <label htmlFor="selectedDate"
                                                className="tw-block"
                                                style={{ marginTop: "5px", fontSize: "16px" }}>
                                                <span>maximum Reporting Interval</span>
                                            </label>

                                            <input style={{ marginTop: "-5px" }}
                                                type="date"


                                                value={selectedSessionStartDate}
                                                onChange={handleNewDateChange}
                                                className="tw-border tw-border-gray-300 tw-rounded-lg tw-py-2 tw-px-1 tw-w-full tw-bg-white focus:tw-ring-2 focus:tw-ring-blue-500 focus:tw-outline-none"
                                                min={calendarMinDate ? new Date(calendarMinDate).toISOString().split('T')[0] : ''}
                                                max={calendarMaxDate ? new Date(calendarMaxDate).toISOString().split('T')[0] : ''}
                                                disabled={!isDateSelectable}
                                            // onSelect={(event) => handleCalendarDateSelect(event.target.value)}

                                            />

                                            {!rescheduleDateSelected && (postponeInterval === '' || session === '') ? (
                                                <p style={{ marginTop: "5px", fontSize: "14px" }}>

                                                </p>
                                            ) : (
                                                <p style={{ marginTop: "5px", fontSize: "20px" }}>
                                                    You are about to allocate {weeksDifference} week(s) of promotion duration
                                                </p>
                                            )}
                                        </div>



                                        <div style={{
                                            marginTop: "20px",
                                            width: "700px",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}>
                                            <ButtonCustom
                                                classes={
                                                    (!rescheduleDateSelected && (postponeInterval === '' || session === ''))
                                                        ? "tw-bg-gray-300 tw-text-gray-500"
                                                        : "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                                                }
                                                label="Save"
                                                onClick={CreateSettingsDelays}
                                                icon="pi pi-sync"
                                                disabled={
                                                    (!rescheduleDateSelected && (postponeInterval === '' || session === ''))
                                                }
                                            />
                                        </div>
                                    </>
                                )}

                            </form>
                        </Dialog>

                        <hr></hr>

                        <div className="card" style={{ marginTop: "1%", marginLeft: "19px", marginRight: "19px" }}>
                            <div className="tw-flex tw-flex-col">
                                <div style={{ marginLeft: "", marginBottom: "0%" }}>
                                    <DataTable value={renderRows()} tableStyle={{ minWidth: '10rem' }} scrollable scrollHeight="550px" scrollWidth="100%">
                                        <Column field="name" header={<div style={{ width: '100%' }}>Session name&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>}></Column>
                                        <Column field="interval" header="Maximum date rescheduling interval (Days)"></Column>
                                        <Column field="attempt" header="Number of reschedule attempts"></Column>
                                        <Column field="expiresAt" header="Expiration date of the discount (Days)"></Column>
                                        <Column header="Actions" body={(rowData) => (
                                            <div>
                                                <Button label="Edit" icon="pi pi-pencil" className="p-button-sm p-button-primary-800 mb-2" onClick={() => handleEditClick(rowData.id)} />
                                                <Button label="DELETE" icon="pi-times" className="p-button-sm p-button-danger" onClick={() => handleEdit(rowData.id)} />
                                            </div>
                                        )}></Column>
                                    </DataTable>
                                    <Dialog
                                        header="Configurations Promotions"
                                        visible={showForm}
                                        style={{
                                            width: '54vw',
                                            height: '80vh',
                                            marginLeft: '10vh',
                                            marginTop: "-10vh"
                                            // visible={showForm} 

                                        }}

                                        onHide={handleCloseForm}
                                        onSubmit={handleSubmit}
                                    >
                                        <hr />
                                        <form>

                                            <div className="tw-mb-0">
                                                <h3 className="tw-block tw-font-bold tw-text-primary-800 mt-3 tw-text-center tw-text-xl">

                                                    Edit settings
                                                </h3>
                                            </div>

                                            <div style={{
                                                marginTop: "0px",
                                                width: "700px"
                                            }}>

                                                <span>Session name</span>
                                                <div style={{ marginBottom: "10px" }}>

                                                    <InputCustom
                                                        id="sessionName"
                                                        name="sessionName"
                                                        placeholder="Session Name"
                                                        value={state.sessionName}
                                                        type="text"
                                                        readOnly
                                                    />
                                                </div>
                                                <span>Number attempts</span>
                                                <div style={{ marginTop: "5px",marginBottom: "10px" }}>

                                                    <InputCustom
                                                        id="attempt"
                                                        name="attempt"
                                                        placeholder="Enter the number of attempts"
                                                        value={state.attempt}
                                                        type="number"
                                                        onChange={handleOnChange}
                                                    />
                                                </div>

                                                <span>Maximum interval</span>
                                                <div style={{ marginBottom: "10px" }}>

                                                    <InputCustom
                                                        id="unit"
                                                        name="unit"
                                                        placeholder="Enter the maximum interval"
                                                        value={state.unit}
                                                        type="number"
                                                        onChange={handleOnChange}
                                                    />
                                                </div>

                                                <span>Discount expired at</span>
                                                <div style={{ marginBottom: "20px" }}>

                                                    <InputCustom
                                                        id="discount_expired_at"
                                                        name="discount_expired_at"
                                                        placeholder="discount_expired_at"
                                                        value={state.discount_expired_at}
                                                        type="number"
                                                        onChange={handleOnChange}

                                                    />
                                                </div>
                                                <ButtonCustom

                                                    classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white "}
                                                    label="Update"
                                                    icon={"pi pi-sync"}
                                                    onClick={EditDelays}

                                                />
                                            </div>
                                        </form>
                                    </Dialog>

                                    <Dialog
                                        header="DELETE MODAL"
                                        visible={isFormOpen}
                                        style={{
                                            width: '54vw',
                                            height: '35vh',
                                            marginLeft: '10vh',
                                            marginTop: "-10vh"
                                            // visible={showForm} 

                                        }}

                                        onHide={handleCloseForm}
                                        onSubmit={handleSubmit}
                                    >
                                        <hr />
                                        <h1 className="tw-block tw-font-bold mt-3 tw-text-primary-800 tw-text-center">
                                            Are you sure you want to delete this line?
                                        </h1>



                                        <div className="tw-flex tw-justify-center mt-3">
                                            <ConfirmPopup />
                                            <div className="card button-container">
                                                <Button onClick={handleCancelClick} icon="pi pi-times" label="Cancel" className="p-button-danger"></Button>
                                            </div>
                                            &emsp;
                                            <Button onClick={confirm1} icon="pi pi-check" label="Confirm"></Button>
                                        </div>
                                    </Dialog>
                                    <div>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div>
                        </div>
                    </main>
                </>
            )
            }

        </>
    );
};

export default ConfigDelayAndDiscount;
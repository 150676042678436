const countries = [
    {label: "Afghanistan", value: "Afghanistan"},
    {label: "Åland Islands", value: "Åland Islands"},
    {label: "Albania", value: "Albania"},
    {label: "Algeria", value: "Algeria"},
    {label: "American Samoa", value: "American Samoa"},
    {label: "Andorra", value: "Andorra"},
    {label: "Angola", value: "Angola"},
    {label: "Anguilla", value: "Anguilla"},
    {label: "Antarctica", value: "Antarctica"},
    {label: "Antigua and Barbuda", value: "Antigua and Barbuda"},
    {label: "Argentina", value: "Argentina"},
    {label: "Armenia", value: "Armenia"},
    {label: "Aruba", value: "Aruba"},
    {label: "Australia", value: "Australia"},
    {label: "Azerbaijan", value: "Azerbaijan"},
    {label: "Bahamas", value: "Bahamas"},
    {label: "Bahrain", value: "Bahrain"},
    {label: "Bangladesh", value: "Bangladesh"},
    {label: "Barbados", value: "Barbados"},
    {label: "Belarus", value: "Belarus"},
    {label: "Belau", value: "Belau"},
    {label: "Belgium", value: "Belize"},
    {label: "Benin", value: "Benin"},
    {label: "Bermuda", value: "Bermuda"},
    {label: "Bhutan", value: "Bhutan"},
    {label: "Bolivia", value: "Bolivia"},
    {label: "Bonaire", value: "Bonaire"},
    {label: "Botswana", value: "Botswana"},
    {label: "Bouvet Island", value: "Bouvet Island"},
    {label: "Brazil", value: "Brazil"},
    {label: "British Indian", value: "British Indian"},
    {label: "Brunei", value: "Brunei"},
    {label: "Bulgaria", value: "Bulgaria"},
    {label: "Burkina Faso", value: "Burkina Faso"},
    {label: "Burundi", value: "Burundi"},
    {label: "Cambodia", value: "Cambodia"},
    {label: "Cameroon", value: "Cameroon"},
    {label: "Canada", value: "Canada"},
    {label: "Cape Verde", value: "Cape Verde"},
    {label: "Cayman Islands", value: "Cayman Islands"},
    {label: "Central African Republic", value: "Central African Republic"},
    {label: "Chad", value: "Chad"},
    {label: "Chile", value: "Chile"},
    {label: "China", value: "China"},
    {label: "Christmas Island", value: "Christmas Island"},
    {label: "Cocos", value: "Cocos"},
    {label: "Colombia", value: "Colombia"},
    {label: "Comoros", value: "Comoros"},
    {label: "Congo (Brazzaville)", value: "Congo (Brazzaville)"},
    {label: "ongo (Kinshasa)", value: "Congo (Kinshasa)"},
    {label: "Cook Islands<", value: "Cook Islands<"},
    {label: "Costa Rica", value: "Costa Rica"},
    {label: "Croatia", value: "Croatia"},
    {label: "Cuba ao", value: "Cuba ao"},
    {label: "Cyprus", value: "Cyprus"},
    {label: "Czech Republic", value: "Czech Republic"},
    {label: "Denmark", value: "Denmark"},
    {label: "Djibouti", value: "Djibouti"},
    {label: "Dominica", value: "Dominica"},
    {label: "Dominican Republi", value: "Dominican Republi"},
    {label: "Ecuador", value: "Ecuador"},
    {label: "Egypt", value: "Egypt"},
    {label: "El Salvador", value: "El Salvador"},
    {label: "Equatorial Guinea", value: "Equatorial Guinea"},
    {label: "Eritrea", value: "Eritrea"},
    {label: "Estonia", value: "Estonia"},
    {label: "Ethiopia", value: "Ethiopia"},
    {label: "Falkland Islands", value: "Falkland Islands"},
    {label: "Faroe Islands", value: "Faroe Islands"},
    {label: "Finland", value: "Finland"},
    {label: "France", value: "France"},
    {label: "French Guiana", value: "French Guiana"},
    {label: "French Polynesia", value: "French Polynesia"},
    {label: "French Southern Territories", value: "French Southern Territories"},
    {label: "Gabon", value: "Gabon"},
    {label: "Gambia", value: "Gambia"},
    {label: "Georgia", value: "Georgia"},
    {label: "Germany", value: "Germany"},
    {label: "Ghana", value: "Ghana"},
    {label: "Gibraltar", value: "Gibraltar"},
    {label: "Greece", value: "Greece"},
    {label: "Greenland", value: "Greenland"},
    {label: "Grenada", value: "Grenada"},
    {label: "Guadeloupe", value: "Guadeloupe"},
    {label: "Guam", value: "Guam"},
    {label: "Guatemala", value: "Guatemala"},
    {label: "Guernsey", value: "Guernsey"},
    {label: "Guinea", value: "Guinea"},
    {label: "Guinea-Bissau", value: "Guinea-Bissau"},
    {label: "Guyana", value: "Guyana"},
    {label: "Haiti", value: "Haiti"},
    {label: "Heard Island and McDonald Islands", value: "Heard Island and McDonald Islands"},
    {label: "Honduras", value: "Honduras"},
    {label: "Hong Kong", value: "Hong Kong"},
    {label: "Hungary", value: "Hungary"},
    {label: "Iceland", value: "Iceland"},
    {label: "India", value: "India"},
    {label: "Indonesia", value: "Indonesia"},
    {label: "Iran", value: "Iran"},
    {label: "Iraq", value: "Iraq"},
    {label: "Ireland", value: "Ireland"},
    {label: "Isle of Man", value: "Isle of Man"},
    {label: "Israel", value: "Israel"},
    {label: "Italy", value: "Italy"},
    {label: "Ivory Coast", value: "Ivory Coast"},
    {label: "Jamaica", value: "Jamaica"},
    {label: "Japan", value: "Japan"},
    {label: "Jersey", value: "Jersey"},
    {label: "Jordan", value: "Jordan"},
    {label: "Kazakhstan", value: "Kazakhstan"},
    {label: "Kenya", value: "Kenya"},
    {label: "Kiribati", value: "Kiribati"},
    {label: "Kuwait", value: "Kuwait"},
    {label: "Kyrgyzstan", value: "Kyrgyzstan"},
    {label: "Laos", value: "Laos"},
    {label: "Latvia", value: "Latvia"},
    {label: "Lebanon", value: "Lebanon"},
    {label: "Lesotho", value: "Lesotho"},
    {label: "Liberia", value: "Liberia"},
    {label: "Libya", value: "Libya"},
    {label: "Liechtenstein", value: "Liechtenstein"},
    {label: "Lithuania", value: "Lithuania"},
    {label: "Luxembourg", value: "Luxembourg"},
    {label: "Macao", value: "Macao"},
    {label: "Madagascar", value: "Madagascar"},
    {label: "Malawi", value: "Malawi"},
    {label: "Malaysia", value: "Malaysia"},
    {label: "Maldives", value: "Maldives"},
    {label: "Mali", value: "Mali"},
    {label: "Malta", value: "Malta"},
    {label: "Marshall Islands", value: "Marshall Islands"},
    {label: "Martinique", value: "Martinique"},
    {label: "Mauritania", value: "Mauritania"},
    {label: "Mauritius", value: "Mauritius"},
    {label: "Mayotte", value: "Mayotte"},
    {label: "Mexico", value: "Mexico"},
    {label: "Micronesia", value: "Micronesia"},
    {label: "Moldova", value: "Moldova"},
    {label: "Monaco", value: "Monaco"},
    {label: "Mongolia", value: "Mongolia"},
    {label: "Montenegro", value: "Montenegro"},
    {label: "Montserrat", value: "Montserrat"},
    {label: "Morocco", value: "Morocco"},
    {label: "Mozambique", value: "Mozambique"},
    {label: "Myanmar", value: "Myanmar"},
    {label: "Namibia", value: "Namibia"},
    {label: "Nauru", value: "Nauru"},
    {label: "Nepal", value: "Nepal"},
    {label: "Netherlands", value: "Netherlands"},
    {label: "New Caledonia", value: "New Caledonia"},
    {label: "New Zealand", value: "New Zealand"},
    {label: "Nicaragua", value: "Nicaragua"},
    {label: "Niger", value: "Niger"},
    {label: "Nigeria", value: "Nigeria"},
    {label: "Niue", value: "Niue"},
    {label: "Norfolk Island", value: "Norfolk Island"},
    {label: "North Korea", value: "North Korea"},
    {label: "North Macedonia", value: "North Macedonia"},
    {label: "Northern Mariana Islands", value: "Northern Mariana Islands"},
    {label: "Norway", value: "Norway"},
    {label: "Oman", value: "Oman"},
    {label: "Pakistan", value: "Pakistan"},
    {label: "Palestinian Territory", value: "Palestinian Territory"},
    {label: "Panama", value: "Panama"},
    {label: "Papua New Guinea", value: "Papua New Guinea"},
    {label: "Paraguay", value: "Paraguay"},
    {label: "Peru", value: "Peru"},
    {label: "Philippines", value: "Philippines"},
    {label: "Pitcairn", value: "Pitcairn"},
    {label: "Poland", value: "Poland"},
    {label: "Portugal", value: "Portugal"},
    {label: "Puerto Rico", value: "Puerto Rico"},
    {label: "Qatar", value: "Qatar"},
    {label: "Reunion", value: "Reunion"},
    {label: "Romania", value: "Romania"},
    {label: "Russia", value: "Russia"},
    {label: "Rwanda", value: "Rwanda"},
    {label: "Saotome", value: "Saotome"},
    {label: "Saint Barth lemy", value: "Saint Barth lemy"},
    {label: "Saint Helena", value: "Saint Helena"},
    {label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis"},
    {label: "Saint Lucia", value: "Saint Lucia"},
    {label: "Saint Martin (Dutch part)", value: "Saint Martin (Dutch part)"},
    {label: "Saint Martin (French part)", value: "Saint Martin (French part)"},
    {label: "Saint Pierre and Miquelon", value: "Saint Pierre and Miquelon"},
    {label: "Saint Vincent and the Grenadines", value: "Saint Vincent and the Grenadines"},
    {label: "Samoa", value: "Samoa"},
    {label: "San Marino", value: "San Marino"},
    {label: "Saudi Arabia", value: "Saudi Arabia"},
    {label: "Senegal", value: "Senegal"},
    {label: "Serbia", value: "Serbia"},
    {label: "Seychelles", value: "Seychelles"},
    {label: "Sierra Leone", value: "Sierra Leone"},
    {label: "Singapore", value: "Singapore"},
    {label: "Slovakia", value: "Slovakia"},
    {label: "Slovenia", value: "Slovenia"},
    {label: "Solomon Islands", value: "Solomon Islands"},
    {label: "Somalia", value: "Somalia"},
    {label: "South Africa", value: "South Africa"},
    {label: "South Georgia/Sandwich Islands", value: "South Georgia/Sandwich Islands"},
    {label: "South Korea", value: "South Korea"},
    {label: "South Sudan", value: "South Sudan"},
    {label: "Spain", value: "Spain"},
    {label: "Sri Lanka", value: "Sri Lanka"},
    {label: "Sudan", value: "Sudan"},
    {label: "Suriname", value: "Suriname"},
    {label: "Svalbard and Jan Mayen", value: "Svalbard and Jan Mayen"},
    {label: "Swaziland", value: "Swaziland"},
    {label: "Sweden", value: "Sweden"},
    {label: "Switzerland", value: "Switzerland"},
    {label: "Syria", value: "Syria"},
    {label: "Taiwan", value: "Taiwan"},
    {label: "Tajikistan", value: "Tajikistan"},
    {label: "Tanzania", value: "Tanzania"},
    {label: "Thailand", value: "Thailand"},
    {label: "Timor-Leste", value: "Timor-Leste"},
    {label: "Togo", value: "Togo"},
    {label: "Tokelau", value: "Tokelau"},
    {label: "Tonga", value: "Tonga"},
    {label: "Trinidad and Tobago", value: "Trinidad and Tobago"},
    {label: "Tunisia", value: "Tunisia"},
    {label: "Turkey", value: "Turkey"},
    {label: "Turkmenistan", value: "Turkmenistan"},
    {label: "Turks and Caicos Islands", value: "Turks and Caicos Islands"},
    {label: "Tuvalu", value: "Tuvalu"},
    {label: "Uganda", value: "Uganda"},
    {label: "Ukraine", value: "Ukraine"},
    {label: "United Arab Emirates", value: "United Arab Emirates"},
    {label: "United Kingdom", value: "United Kingdom "},
    {label: "United States", value: "United States"},
    {label: "United States Minor Outlying Islands", value: "United States Minor Outlying Islands"},
    {label: "Uruguay", value: "Uruguay"},
    {label: "Uzbekistan", value: "Uzbekistan"},
    {label: "Vanuatu", value: "Vanuatu"},
    {label: "Vatican", value: "Vatican"},
    {label: "Venezuela", value: "Venezuela"},
    {label: "Vietnam", value: "Vietnam"},
    {label: "Virgin Islands (British)", value: "Virgin Islands (British)"},
    {label: "Virgin Islands (US)", value: "Virgin Islands (US)"},
    {label: "Wallis and Futuna", value: "Wallis and Futuna"},
    {label: "Western Sahara", value: "Western Sahara"},
    {label: "Yemen", value: "Yemen"},

]
export default countries;
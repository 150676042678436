import MainLayout from "../../../../layouts/MainLayout";
import Header from "../../../../components/frontv2.0/common/header/Header";
import InputCustom from "../../../../components/frontv2.0/common/form/InputCustom";
import ButtonCustom from "../../../../components/frontv2.0/common/button/ButtonCustom";
import React, { useEffect, useRef, useState } from "react";
import OrderSession from "../../../../components/frontv2.0/common/sessions/OrderSessionCheckout";
import PaymentAlert from "../../../../components/frontv2.0/common/status/PaymentAlert";
import SubHeader from "../../../../components/frontv2.0/common/header/SubHeader";
import UserService from "../../../../network/services/UserService";
import { CardElement } from "@stripe/react-stripe-js";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { Toast } from "primereact/toast";
import Loader from "../../../../components/frontv2.0/common/loader/Loader";
import {useNavigate} from "react-router-dom";
import {MOTIF_LOGIN} from "../../../../constants/images";
import PageOops from "../../../../components/frontv2.0/common/opps/PageOops";

const MakePaymentDetails = () => {
  const stripe = useStripe();
  const elements = useElements();
  const toast = useRef(null);
  const navigate = useNavigate();
  /**
   * States
   */
  const [firstLoading, setFirstLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [showPaymentForm, setshowPaymentForm] = useState(true);
  const [isLastInstallment, setIsLastInstallment] = useState(false);
  const [amountToPay, setAmountToPay] = useState(0);
  const [updatePaymentMethod, setUpdatePaymentMethod] = useState(false);
  const [sessionId, setSessionId] = useState("")
  const [isCancelled, setIsCancelled] = useState(false);

  // eslint-disable-next-line
  const [orderSessionInfos, setOrderSessionInfos] = useState({
    sessionName: "",
    access_type: "",
    actualPaidAmount: "",
    expectedPaidAmount: "",
    isInGoodStanding: "",
    maxAmount: "",
    minAmount: "",
    remainingAmountToBeInGoodStanding: "",
    subscriptionPlanPrice: "",
    subscriptionPrice: "",
  });

  const paymentSuccesInfo = {
    title: "You have all paid for this session",
    subTitle: "Access to course granted",
    enableButtonAction: false,
    urlToRedirect: "/workspace/mysessions",
  };

  /**
   * Comportments
   */
  const showSuccess = (message) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };

  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };

  const notification = (message, status) => {
    if (status) {
      showSuccess(message);
    } else {
      showError(message);
    }
  };

  const showLastInterval = async () => {
    setFirstLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    let id = queryParams.get("subscriptionId");

    setSubscriptionId(id);
    let data = {
      subscriptionId: parseInt(id),
    };

    UserService.minMaxAmount(data, (response) => {
      if (response) {
        setSessionId(response?.sessionId)
        setOrderSessionInfos(response);
        setIsCancelled(response.access_type === "CANCELLED");
        setshowPaymentForm(response.minAmount>0 && response.maxAmount>0);
        setAmountToPay(
          response.minAmount > 0
            ? response.minAmount
            : response.maxAmount - response.minAmount
        );
        setIsLastInstallment(
          parseInt(response.minAmount) === parseInt(response.maxAmount)
        );
        setFirstLoading(false);
      }
    });
  };

  /*This function is called to initiate the payment request
    The function verify the values in which the typed amount is between and choose to process or not
    Inside the function we call function that has to contact stripe and confirm the operation to the backend
    Before showing the reult in modals to client*/
  const initiatePayment = async () => {
    //this.setState({disabled: true});

    if (
      !(
        orderSessionInfos.actualPaidAmount <
        orderSessionInfos.subscriptionPlanPrice
      )
    ) {
      notification("You have paid all for this session", false);
      await showLastInterval();
      elements.getElement(CardElement).clear();
      return;
    }

    if (
      !(
        amountToPay >= orderSessionInfos.minAmount &&
        amountToPay <= orderSessionInfos.maxAmount
      )
    ) {
      notification(
        "The amount you want to pay must be between " +
          orderSessionInfos.minAmount +
          " and " +
          orderSessionInfos.maxAmount,
        false
      );
      elements.getElement(CardElement).clear();
      //this.setState({disabled: false});
      return;
    }

    //this.setState({isLoading: true});
    setLoading(true);
    let data = {
      subscriptionId: subscriptionId,
      amount: amountToPay,
    };

    UserService.toPayPaymentIntent(data, (response) => {
      if (response) {
        // let confirmationData = {
        //     paymentHistoryId: parseInt(this.state.subscriptionId),
        //     amount: parseInt(this.state.amount),
        //     changeCredential : this.state.updatePaymentMethod
        // }
        confirmPaymentSuccess(
          response.paymentIntentClientSecret,
          response.paymentHistoryId
        );
      }
    });
  };

  const stripePaymentCheck = async (client_secret) => {
    if (!stripe || !elements) {
      //alert('Stripe.js has not yet loaded.');
      return;
    }

    const result = await stripe.confirmCardPayment(client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
      setup_future_usage: "off_session",
    });

    if (result.error) {
      return {
        paymentSuccess: false,
        error: result.error.message,
        paymentMethod: "",
      };
    } else {
      return {
        paymentSuccess: true,
        error: null,
        paymentMethod: result.paymentIntent.payment_method,
      };
    }
  };

  const confirmPaymentSuccess = async (client_secret, payment_history) => {
    const { paymentSuccess, error, paymentMethod } = await stripePaymentCheck(
      client_secret
    );

    if (!paymentSuccess && error) {
      notification(error, false);
      setLoading(false);
      await showLastInterval();
      return;
    }
    const data = {
      paymentHistoryId: payment_history,
      paymentMethodId: paymentMethod,
      changeCredential: updatePaymentMethod,
      updatePaymentCredential: updatePaymentMethod,
    };

    if (paymentSuccess) {
      const { status, message } = await UserService.confirmPaymentSuccess(data);

      if (status) {
        notification("Payment has been done successfully", true);
        setTimeout(() => {
          navigate(`/workspace/transactions/details?sessionId=${sessionId}&subscriptionId=${subscriptionId}`)
        }, 2000);
      } else {
        notification(message, false);
      }

      await showLastInterval();
      // this.setState({disabled: false});
      elements.getElement(CardElement).clear();
      setLoading(false);
    }
  };

  useEffect(() => {
    showLastInterval();
    
  }, []);
  /**
   * Render
   */
  return (
    <>
      <Toast ref={toast} position="top-right" />
      <MainLayout>
        <MainLayout.Content>
          {firstLoading ? (
            <div className="tw-flex tw-h-full tw-flex-col md:tw-justify-center tw-justify-center tw-items-center">
              <Loader />
            </div>
          ) : (
              isCancelled ?
                  <>
                    <div
                        className="tw-flex tw-h-full tw-flex-col md:tw-justify-center tw-justify-center tw-items-center"
                        style={{ backgroundImage: `url(${MOTIF_LOGIN})` }}
                    >
                      <PageOops
                          text="This session is not available again."
                          textBtn="Go to My sessions"
                          onClick={() => {
                            navigate(`/workspace/mysessions`);
                          }}
                      />
                    </div>
                  </>
            :
            <div
              className={`tw-flex md:tw-flex-row ${
                showPaymentForm ? "tw-flex-col" : "tw-flex-col-reverse"
              } ' tw-w-full md:tw-space-x-2 md:tw-space-y-0'`}
            >
              {/* SectionLeft */}
              <OrderSession
                makePayment={true}
                data={orderSessionInfos}
                showPaymentForm={showPaymentForm}
                enableForm={false}
              />

              {showPaymentForm ? (
                // Section Right : Payment form
                <div className="md:tw-w-1/2 md:tw-bg-accent-gray tw-rounded-[12px] md:tw-p-4  tw-w-full tw-mt-10 md:tw-mt-0 ">
                  <p className="tw-text-lg tw-font-semibold tw-text-primary tw-mb-2">
                    You want to make a payment
                  </p>
                  <div className="tw-flex tw-flex-col tw-mt-4 tw-text-gray-700">
                    {isLastInstallment ? (
                      <p>
                        The transaction amount of the remaining installment is :
                        &nbsp;
                        <b>{orderSessionInfos.maxAmount}</b>
                      </p>
                    ) : (
                      <p>
                        Type the transaction amount here between{" "}
                        <b>{orderSessionInfos.minAmount}</b>
                        &nbsp; and &nbsp;
                        <b> {orderSessionInfos.maxAmount}</b> or leave it as
                        default
                      </p>
                    )}
                    {/*<p>The transaction amount of the remaining installment is : <span*/}
                    {/*    className='tw-font-semibold'>$2200</span></p>*/}
                    <div className="tw-space-y-3 tw-py-2">
                      <div className="">
                        <InputCustom
                          name="validation"
                          id={orderSessionInfos.subscriptionPlanPrice}
                          type={"number"}
                          placeHolder={"Amount"}
                          value={amountToPay}
                          onChange={(e) => {
                            const value = e.target.value;
                            setAmountToPay(value);
                          }}
                        />
                      </div>
                      <div className="single-form ">
                        <div className="tw-block tw-justify-center tw-w-full tw-h-[50px] tw-rounded-lg tw-border-0 tw-py-3 tw-text-primary tw-ring-2 tw-ring-gray-300 tw-bg-white focus:tw-ring-primary-600 tw-ring-inset tw-px-4 tw-text-xs sm:tw-text-sm sm:tw-leading-6 tw-outline-none">
                          <CardElement
                            options={{
                              style: {
                                base: {
                                  iconColor: "#1e4e70",
                                  fontWeight: "400",
                                  fontFamily:
                                    "Poppins, Open Sans, Segoe UI, sans-serif",
                                  fontSize: "20px",
                                  fontSmoothing: "antialiased",
                                  ":-webkit-autofill": {
                                    color: "#1e4e70",
                                  },
                                },
                              },
                            }}
                          />
                        </div>
                      </div>

                      <label
                        htmlFor="nextPayId"
                        className="checkform tw-flex tw-items-center tw-my-2"
                      >
                        <input
                          type="checkbox"
                          id="nextPayId"
                          name="verifNextPayId"
                          onClick={() => {
                            setUpdatePaymentMethod(!updatePaymentMethod);
                          }}
                          className="tw-mr-2"
                        />
                        Do you want to use this card for next transaction?
                      </label>
                    </div>
                    <div className="tw-mt-2">
                      {loading ? (
                        <ButtonCustom
                          classes={
                            "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                          }
                          label="Loading..."
                          icon={"pi pi-spin pi-spinner"}
                          disabled={true}
                        />
                      ) : (
                        <ButtonCustom
                          classes={
                            "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                          }
                          label="Pay"
                          disabled={!stripe || isCancelled}
                          onClick={initiatePayment}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <PaymentAlert data={paymentSuccesInfo} />
              )}
            </div>
          )}
        </MainLayout.Content>

        <MainLayout.Header>
          <Header />
        </MainLayout.Header>

        <MainLayout.SubHeader>
          <SubHeader
            data={[
              { value: "Home", url: "/workspace" },
              {
                value: "Make a Payment",
                url: "/workspace/make-payment",
              },
              { value: "Details", url: `?subscriptionId=${subscriptionId}` },
            ]}
          />
        </MainLayout.SubHeader>
      </MainLayout>
    </>
  );
};

export default MakePaymentDetails;

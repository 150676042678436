import React, { useEffect, useState } from "react";
import MainLayout from "../../../../layouts/MainLayout";
import SubHeader from "../../../../components/frontv2.0/common/header/SubHeader";
import Header from "../../../../components/frontv2.0/common/header/Header";
import CourseDetails from "../../../../components/frontv2.0/common/courses/CourseDetails";
import Loader from "../../../../components/frontv2.0/common/loader/Loader";
import CourseService from "../../../../network/services/CourseService";
import { useParams } from "react-router";

const AvalaibleCoursesDetails = (props) => {
  const { courseId } = useParams();
  const [state, setState] = useState({
    id: "",
    resources: [],
    course: "",
    courses: [],
    courseId: courseId,
    resourcesToShow: [],
    selectedResources: [],
    removeResources: [],
    currentCourse: {
      id: null,
      title: "",
      descritpion: "",
      resources: [],
    },
    isLoading: false,
  });

  const fetchData = async () => {
    setState((prevState) => ({
      ...prevState,
      courseId: courseId,
      isLoading: true,
    }));
    try {
      CourseService.getOneUnsecureById(courseId, (response) => {
        if (response && response.data) {
          setState((prevState) => ({
            ...prevState,
            courses: response.data,
            isLoading: false,
          }));
        }
      });
    } catch (error) {
      console.log(error);
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <MainLayout.Content>
        {/* Content Avalaible courses */}
        {state.isLoading ? (
          <div className="tw-flex tw-h-full tw-flex-col md:tw-justify-center tw-justify-center tw-items-center">
            <Loader />
          </div>
        ) : (
          <CourseDetails
            data={state.courses}
            checkoutLink="/workspace/courses/details/checkout"
          />
        )}
      </MainLayout.Content>

      <MainLayout.Header>
        <Header />
      </MainLayout.Header>

      <MainLayout.SubHeader>
        <SubHeader
          data={[
            { value: "Home", url: "/workspace" },
            { value: "Avalaible Courses", url: "/workspace/courses" },
            { value: state?.courses.name, url: "#" },
          ]}
        />
      </MainLayout.SubHeader>
    </MainLayout>
  );
};

export default AvalaibleCoursesDetails;

import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import SessionService from "../../../../network/services/SessionService";
import moment from "moment";
import RefundCourseService from "../../../../network/services/RefundCourseService";
import CourseService from "../../../../network/services/CourseService";

export default class Other extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: "",
      courseId: "",
      subscriptionId: "",
      paymentHistoryId: "",
      payment_status: [],
      payment_date: [],
      date_already_paid: [],
      next_payment_date: [],
      installment_total_paginate1: [],
      historytab: [],
      
      installment_done: "",
      installment_total: "",
      installment_remaining: "",
      installment_fail: "",
      price_total: "",
      total_pay: "",
      length: "",
      remaining: "",
      installment_date: "",
      transaction_details: [],
      installment_refunded: "",
      accessType: "",
      isLoading: false,
      collapseExample1: false,
      collapseExample2: false,
      collapseExample3: false,
      collapseExample4: false,
      isRefunding: false,
      isRefundingTransaction: false,
      isRefundingAndCanceling: false,
      cancelingMessage: "Restoring...",
      successMessage: false,
      isCancelable: false,
      isRefundableAndCancelable: false,
      hasBeenCanceled: false,
      lastPage: 1,
      pageItem: 6,
    };
  }

  collapseExample = (index) => {
    this.setState({
      collapseExample1: index === "collapseExample1",
      collapseExample2: index === "collapseExample2",
      collapseExample3: index === "collapseExample3",
      collapseExample4: index === "collapseExample4",
    });
  };


  showPayHist = () => {
    const queryParams = new URLSearchParams(window.location.search);
    let sessionId = queryParams.get("sessionId");
    let userId = queryParams.get("userId");
    SessionService.getStatutPayment(sessionId, userId, (data) => {
      if (data) {
        console.log(data)
        this.setState({ payment_status: data });
        // console.log('lobject du backend');
        // console.log(data);
      }
    });
  };

  showPayDate = () => {
    this.setState({ isLoading: true });
    const queryParams = new URLSearchParams(window.location.search);
    let courseId = queryParams.get("courseId");
    let userId = queryParams.get("userId");
    let datas = {
      user_id: userId,

      course_id: courseId,
    };
    CourseService.getDatePaymentSecureAdmin(datas, (data) => {
      console.log(data);
      console.log(data.transactionDetails[0].total_paid);
      if (data) {
        this.setState({
          length: data.transactionDetails[0].installment_refunded.length,
          installment_done: data.transactionDetails[0].installment_done,
          installment_total: data.transactionDetails[0].installment_total,
          remaining: data.transactionDetails[0].remaining,
          //installment_fail: data.transactionDetails[0].installment_fail,
          installment_fail: data.transactionDetails[0].installment_fail,
          subscriptionId: data.subscriptionId,
          accessType: data.access_type,
          installment_remaining:
            data.transactionDetails[0].installment_remaining,
          total_pay: data.transactionDetails[0].total_paid,
          hasBeenCanceled:
            data.transactionDetails[0].price_total ===
            data.transactionDetails[0].remaining,
          installment_refunded:
            data.transactionDetails[0].installment_refunded.length,
          payment_refunded: data.transactionDetails[0].installment_refunded,

          date_already_paid: data.dateAlreadyPaid,
        });
      }
    });
  };

  showHistoryFail = () => {
    this.setState({ isLoading: true });
    const queryParams = new URLSearchParams(window.location.search);
    let courseId = queryParams.get("courseId");
    let userId = queryParams.get("userId");
   

    console.log(courseId);
    console.log(userId);
    let data = {
      user_id: userId,

      course_id: courseId,
    };

    console.log(data);
    CourseService.getHistoryFailSecureAdmin(data, (data) => {
      console.log(data);
      if (data) {
        this.setState({
          payment_fail: data.paymentHistoryFail,
        });
      }
      this.setState({ isLoading: false });
    });
  };

  saveCourseIdToRemove = (e) => {
    const id = e.target.getAttribute("id");
    this.setState({ subscriptionId: id });
    console.log(this.state.subscriptionId);

  };

  savePaymentHistoryIdToRefund = (e) => {
    const id = e.target.getAttribute("id");
    console.log("voici mon id " + id);
    this.setState({ paymentHistoryId: id });
  };

  

  refundPayment = async () => {
    this.setState({ isRefundingTransaction: true });

    const id = this.state.paymentHistoryId;

    console.log("voici le id " + id);

    const data = {
      subscriptionId: null,
      paymentHistoryId: id,
    };

    this.setState({ isLoading: true });
    CourseService.refundPayment(data, (response) => {
      if (response) {
        if (response.success) {
          this.setState({
            isRefundingTransaction: false,
            successMessage: true,
            message: "The transaction has been successfully refunded",
          });
          this.showPayDate();
          this.showHistoryFail();
          this.props.parentCallbackCheck();
        }
      }
    });
  };

  refundSubscription = async () => {
    this.setState({ isRefundingAndCanceling: true });

    const id = this.state.subscriptionId;

    console.log("voici le id " + id);

    const toSend = {
      subscriptionId: id,
      paymentHistoryId: null,
    };

    const response = await RefundCourseService.refunding(toSend);

    if (response) {
      if (response.success) {
        const data = response.refunds[0];
        console.log(data);
        this.setState({
          isRefundingAndCanceling: false,
          successMessage: true,
          isRefundableAndCancelable: false,
          isCancelable: false,
          message:
            "The subscription has been successfully refunded and canceled",
        });
        this.onTrigger();
        await this.showPayDate();
        await this.showHistoryFail();
      }
    }
  };

  cancelSubscription = async () => {
    this.setState({ isRefundingAndCanceling: true });

    const id = this.state.subscriptionId;

    console.log("voici le id " + id);

    const toSend = {
      subscriptionId: id,
      paymentHistoryId: null,
    };

    const response = await RefundCourseService.cancel(toSend);
    if (response) {
      // console.log("la reponse")
      // console.log(response)
      if (response.success) {
        this.setState({
          isRefundingAndCanceling: false,
          successMessage: true,
          isRefundableAndCancelable: false,
          isCancelable: false,
          message: response.message,
        });
        this.onTrigger();
        await this.showPayDate();
        await this.showHistoryFail();
      }
    }
  };

  onTrigger = () => {
    this.props.parentCallback();
  };

  componentWillMount() {
    this.showPayDate();
    this.showHistoryFail();
   
  }

  /*  componentDidMount() {
        this.showHistoryFail();
        this.showPayDate();
    } */

  render() {
    const handlePageClick = (data) => {
      this.setState({
        isLoading: true,
      });
      console.log("page " + data.selected);
      const pageUrl = data.selected + 1;

      const indexOfLastRecord = pageUrl * this.state.pageItem;
      const indexOfFirstRecord = indexOfLastRecord - this.state.pageItem;

      //const end = this.state.pageItem * pageUrl;
      //const start = (this.state.pageItem + pageUrl) - this.state.pageItem;

      // console.log("debut et fin " + indexOfFirstRecord + " " + indexOfLastRecord)
      const lastPage = Math.ceil(
        this.state.installment_total / this.state.pageItem
      );

      this.setState({
        installment_total_paginate: this.state.next_payment_date.slice(
          indexOfFirstRecord,
          indexOfLastRecord
        ),
        lastPage: lastPage,
        isLoading: false,
      });
    };
    return (
      <>
        <div className="row">
          <div className="col-lg-12 col-md-10 mx-auto">
            <div className="card mt-4">
              <div className="card-header p-3">
                <div className="section-padding mt-n6">
                  <div className="container">
                    <div className="about-items-wrapper">
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="about-item" style={{ border: "0" }}>
                            <p>
                              {this.state.accessType === "CANCELLED" ? (
                                // <center>
                                //     <h3 className="title bg-gradient-warning text-white">This
                                //         subscription has been refunded and
                                //         canceled</h3>
                                // </center>
                                this.state.hasBeenCanceled === true ? (
                                  <center>
                                    <h3 className="title bg-gradient-warning text-white">
                                      This subscription has been refunded and
                                      canceled
                                    </h3>
                                  </center>
                                ) : (
                                  <center>
                                    <h3 className="title bg-gradient-warning text-white">
                                      This subscription has been canceled
                                    </h3>
                                  </center>
                                )
                              ) : (
                                ""
                              )}
                            </p>
                            <br />
                            <div className="item-icon-title fs-4">
                              <div className="item-icon">
                                <i className="flaticon-tutor"></i>
                              </div>

                              <div className="item-title">
                                <h3 className="title">Payment history</h3>
                              </div>
                            </div>
                            <p>
                              <table className="table align-items-center mb-0">
                                <tr>
                                  <td style={{ width: "70%" }}>
                                    <i
                                      className="fa fa-calculator text-primary"
                                      aria-hidden="true"
                                    ></i>
                                    Installments
                                  </td>
                                  <td style={{ width: "15%" }}>
                                    <span className="font-weight-bold">
                                      {this.state.installment_total}
                                    </span>
                                  </td>
                                  <td>
                                    <a href="javascript:document.getElementById('plan').scrollIntoView(true);">
                                      <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() => {
                                          this.collapseExample(
                                            "collapseExample1"
                                          );
                                        }}
                                      >
                                        Details here
                                      </button>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i
                                      className="fa fa-check text-success"
                                      aria-hidden="true"
                                    ></i>
                                    Successful installments
                                  </td>
                                  <td>
                                    <span className="font-weight-bold">
                                      {this.state.installment_done}
                                    </span>
                                  </td>
                                  <td>
                                    <a href="javascript:document.getElementById('plan').scrollIntoView(true);">
                                      <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() =>
                                          this.collapseExample(
                                            "collapseExample2"
                                          )
                                        }
                                      >
                                        Details here
                                      </button>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i
                                      className="fa fa-plus-circle text-warning"
                                      aria-hidden="true"
                                    ></i>
                                    Refunded installments
                                  </td>
                                  <td>
                                    <span className="font-weight-bold">
                                      {this.state.installment_refunded}
                                    </span>
                                  </td>
                                  <td>
                                    <a href="javascript:document.getElementById('plan').scrollIntoView(true);">
                                      <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() =>
                                          this.collapseExample(
                                            "collapseExample3"
                                          )
                                        }
                                      >
                                        Details here
                                      </button>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <i
                                      className="fa fa-close text-danger"
                                      aria-hidden="true"
                                    ></i>
                                    Failed transactions
                                  </td>
                                  <td>
                                    <span className="font-weight-bold">
                                      {this.state.installment_fail}
                                    </span>
                                  </td>
                                  <td>
                                    <a href="javascript:document.getElementById('plan').scrollIntoView(true);">
                                      <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() =>
                                          this.collapseExample(
                                            "collapseExample4"
                                          )
                                        }
                                      >
                                        Details here
                                      </button>
                                    </a>
                                  </td>
                                </tr>
                              
                              </table>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body p-3">
              <div className="row">
                {this.state.collapseExample1 && (
                  <div>
                    <div className="card card-body" id="plan">
                      <div className="row mb-4">
                        <div className="about-items-wrapper">
                          <div className="row">
                            <div className="col-lg-12">
                              <div
                                className="about-item"
                                style={{ border: "0" }}
                              >
                                <div className="item-icon-title">
                                  <div className="item-icon">
                                    <i
                                      className="fa fa-calculator text-primary"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <div className="item-title">
                                    <h3 className="title">
                                      Payment information
                                    </h3>
                                  </div>
                                </div>
                                <p>
                                  <div className="card">
                                    <div className="card-header pb-0">
                                      <div className="row">
                                        <div className="col-lg-12 col-12">
                                          <h6>Payment information</h6>{" "}
                                          {this.state.isLoading ? (
                                            <div
                                              className="spinner-border text-primary"
                                              role="status"
                                            >
                                              <span className="sr-only">
                                                Loading...
                                              </span>
                                            </div>
                                          ) : null}
                                          <span className="text-sm mb-0">
                                            <i className="material-icons opacity-10 text-success">
                                              credit_score
                                            </i>
                                            <span className="design-text-s">
                                              {" "}
                                              Payment done{" "}
                                              <span className="font-weight-bold ms-1">
                                                {" "}
                                                {this.state.installment_done}
                                              </span>
                                            </span>
                                          </span>
                                          <span className="text-sm mb-0">
                                            <i className="material-icons opacity-10 text-danger">
                                              credit_card_off
                                            </i>
                                            <span className="design-text-s">
                                              {" "}
                                              Remaining payment
                                              <span className="font-weight-bold ms-1">
                                                {
                                                  this.state
                                                    .installment_remaining
                                                }
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card-body px-0 pb-2">
                                      <div className="timeline timeline-one-side">
                                        <div className="timeline-content">
                                          <h6 className="text-dark text-sm font-weight-bold mb-0">
                                            ${this.state.total_pay}
                                          </h6>
                                          <p className="mb-0">
                                            {" "}
                                            Installment{" "}
                                            <span className="badge badge-sm bg-gradient-success">
                                              {this.state.installment_done}
                                            </span>
                                          </p>
                                          <br></br>
                                          <h6 className="text-dark text-sm font-weight-bold mb-0">
                                            Remaining amount to be paid
                                          </h6>
                                          <p className="mb-0">
                                            price:{" "}
                                            <span className="badge badge-sm bg-gradient-info">
                                              ${this.state.remaining}
                                            </span>
                                          </p>
                                        </div>

                                     
                                      </div>
                                    </div>
                                  </div>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.collapseExample2 && (
                  <div>
                    <div className="card card-body" id="plan">
                      <div className="row mb-4">
                        <div className="about-items-wrapper">
                          <div className="row">
                            <div className="col-lg-12">
                              <div
                                className="about-item"
                                style={{ border: "0" }}
                              >
                                <div className="item-icon-title">
                                  <div className="item-icon">
                                    <i
                                      className="fa fa-check text-success"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                  <div className="item-title">
                                    <h3 className="title">Payment history</h3>
                                  </div>
                                </div>
                                <p>
                                  <div className="card h-100">
                                    <div className="card-header pb-0">
                                      <h6>Payment history</h6>{" "}
                                      {this.state.isLoading ? (
                                        <div
                                          className="spinner-border text-primary"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      ) : null}
                                      <p className="text-sm">
                                        <i
                                          className="fa fa-check text-success"
                                          aria-hidden="true"
                                        ></i>
                                        <span className="font-weight-bold">
                                          {this.state.installment_done}/
                                          {this.state.installment_total}
                                        </span>{" "}
                                        Payment done
                                      </p>
                                      {this.state.isRefundingTransaction && (
                                        <div>
                                          <div
                                            className="spinner-border text-primary"
                                            role="status"
                                          >
                                            <span className="sr-only">
                                              Loading...
                                            </span>
                                          </div>
                                          Refunding...
                                        </div>
                                      )}
                                    </div>
                                    <div className="card-body p-3">
                                      <div className="timeline timeline-one-side">
                                        {this.state.date_already_paid &&
                                          this.state.date_already_paid.map(
                                            (date_already_paid, index) => (
                                              <div className="row">
                                                <div className="col-lg-6">
                                                  <div
                                                    className="timeline-block mb-3"
                                                    key={index}
                                                  >
                                                    <span className="timeline-step">
                                                      <i className="material-icons text-success text-gradient">
                                                        credit_score
                                                      </i>
                                                    </span>
                                                    <div className="timeline-content">
                                                      <h6 className="text-dark text-sm font-weight-bold mb-0">
                                                        {
                                                          date_already_paid.amount
                                                        }
                                                        $
                                                      </h6>
                                                      <p className="mb-0">
                                                        {" "}
                                                        Installment{" "}
                                                        <span className="badge badge-sm bg-gradient-success">
                                                          {index + 1}
                                                        </span>
                                                      </p>
                                                      <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                                                        {moment(
                                                          date_already_paid.date
                                                        )
                                                          .utc()
                                                          .format(
                                                            "DD  MMMM  YYYY"
                                                          ) +
                                                          " at " +
                                                          moment
                                                            .utc(
                                                              date_already_paid.date
                                                            )
                                                            .format("HH:mm")}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>

                                                {this.state.accessType !==
                                                  "CANCELLED" && (
                                                  <div className="col-lg-6">
                                                    <button
                                                      className="btn btn-primary"
                                                      type="button"
                                                      id={date_already_paid.id}
                                                      disabled={
                                                        this.state
                                                          .isRefundingTransaction
                                                      }
                                                      onClick={
                                                        this
                                                          .savePaymentHistoryIdToRefund
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      data-bs-toggle="modal"
                                                      data-bs-target="#confirmationRefundPaymentHistory"
                                                    >
                                                      Refund this transaction
                                                    </button>
                                                  </div>
                                                )}
                                              </div>
                                            )
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.collapseExample3 && (
                  <div>
                    <div className="card card-body" id="plan">
                      <div className="row mb-4">
                        <div className="about-items-wrapper">
                          <div className="row">
                            <div className="col-lg-12">
                              <div
                                className="about-item"
                                style={{ border: "0" }}
                              >
                                <div className="item-icon-title">
                                  <div className="item-icon">
                                    <i
                                      className="fa fa-plus-circle text-warning"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                  <div className="item-title">
                                    <h3 className="title">Payment refunded</h3>
                                  </div>
                                </div>
                                <p>
                                  <div className="card h-100">
                                    <div className="card-header pb-0">
                                      <h6>Payment refunded</h6>{" "}
                                      {this.state.isLoading ? (
                                        <div
                                          className="spinner-border text-primary"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      ) : null}
                                      <p className="text-sm">
                                        <i
                                          className="fa fa-plus-circle text-warning"
                                          aria-hidden="true"
                                        ></i>
                                        <span className="font-weight-bold">
                                          {this.state.installment_refunded}
                                        </span>{" "}
                                        Payment refunded
                                      </p>
                                    </div>

                                    <div className="timeline-content">
                                      <p className="mb-0">
                                        {" "}
                                        Installment{" "}
                                        <span className="badge badge-sm bg-gradient-success">
                                          {this.state.length}
                                        </span>
                                      </p>
                                      {/* <p className="text-secondary font-weight-bold text-xs mt-1 mb-0"> {moment(this.state.installment_date).utc().format('DD  MMMM  YYYY') + ' at ' + moment.utc(this.state.installment_date).format("HH:mm")}</p> */}
                                    </div>

                                    <div className="card-body p-3">
                                      <div className="timeline timeline-one-side">
                                        {this.state.payment_refunded &&
                                          this.state.payment_refunded.map(
                                            (payment_fail, index) => (
                                              <div
                                                className="timeline-block mb-3"
                                                key={index}
                                              >
                                                <span className="timeline-step">
                                                  <i className="fa fa-plus-circle text-warning"></i>
                                                </span>
                                                <div className="timeline-content">
                                                  <h6 className="text-dark text-sm font-weight-bold mb-0">
                                                    {payment_fail.amount}$
                                                  </h6>
                                                  <p className="mb-0">
                                                    {" "}
                                                    Installment{" "}
                                                    <span className="badge badge-sm bg-gradient-success">
                                                      {index + 1}
                                                    </span>
                                                  </p>
                                                  <p className="text-secondary font-weight-bold text-xs mt-1 mb-0">
                                                    {" "}
                                                    {moment(
                                                      payment_fail.paymentDate
                                                    )
                                                      .utc()
                                                      .format(
                                                        "DD  MMMM  YYYY"
                                                      ) +
                                                      " at " +
                                                      moment
                                                        .utc(
                                                          payment_fail.paymentDate
                                                        )
                                                        .format("HH:mm")}
                                                  </p>
                                                </div>
                                              </div>
                                            )
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.collapseExample4 && (
                  <div>
                    <div className="card card-body" id="plan">
                      <div className="row mb-4">
                        <div className="about-items-wrapper">
                          <div className="row">
                            <div className="col-lg-12">
                              <div
                                className="about-item"
                                style={{ border: "0" }}
                              >
                                <div className="item-icon-title">
                                  <div className="item-icon">
                                    <i
                                      className="fa fa-close text-danger"
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                  <div className="item-title">
                                    <h3 className="title">Payment failed</h3>
                                  </div>
                                </div>
                                <p>
                                  <div className="card h-100">
                                    <div className="card-header pb-0">
                                      <h6>Payment failed</h6>{" "}
                                      {this.state.isLoading ? (
                                        <div
                                          className="spinner-border text-primary"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      ) : null}
                                      <p className="text-sm">
                                        <i
                                          className="fa fa-close text-danger"
                                          aria-hidden="true"
                                        ></i>
                                        <span className="font-weight-bold">
                                          {this.state.installment_fail}
                                        </span>{" "}
                                        Payment failed
                                      </p>
                                    </div>
                                    <div className="card-body p-3">
                                      <div className="timeline timeline-one-side">
                                        {this.state.payment_fail &&
                                          this.state.payment_fail.map(
                                            (payment_fail, index) => (
                                              <div
                                                className="timeline-block mb-3"
                                                key={index}
                                              >
                                                <span className="timeline-step">
                                                  <i className="material-icons text-danger text-gradient">
                                                    clear
                                                  </i>
                                                </span>
                                                <div className="timeline-content">
                                                  <h6 className="text-dark text-sm font-weight-bold mb-0">
                                                    {payment_fail.amount}$
                                                  </h6>
                                                  <p className="mb-0">
                                                    {" "}
                                                    Installment{" "}
                                                    <span className="badge badge-sm bg-gradient-success">
                                                      {index + 1}
                                                    </span>
                                                  </p>
                                                  <p className="text-secondary font-weight-bold text-xl mt-1 mb-0">
                                                    Charge failure code :{" "}
                                                    <span className="text-primary text-xl font-weight-bold mb-0">
                                                      {
                                                        payment_fail.stripe_charge_failure_code
                                                      }
                                                    </span>
                                                  </p>
                                                  <p className="text-secondary font-weight-bold text-xl mt-1 mb-0">
                                                    Payment intent decline code
                                                    :{" "}
                                                    <span className="text-primary text-xl font-weight-bold mb-0">
                                                      {
                                                        payment_fail.stripe_payment_intent_decline_code
                                                      }{" "}
                                                    </span>
                                                  </p>
                                                  <p className="text-secondary font-weight-bold text-xl mt-1 mb-0">
                                                    Charge failure message :{" "}
                                                    <span className="text-primary text-xl font-weight-bold mb-0">
                                                      {
                                                        payment_fail.stripe_charge_failure_message
                                                      }{" "}
                                                    </span>
                                                  </p>
                                                  <p className="text-secondary font-weight-bold text-xl mt-1 mb-0">
                                                    Charge outcome type :{" "}
                                                    <span className="text-primary text-xl font-weight-bold mb-0">
                                                      {
                                                        payment_fail.stripe_charge_outcome_type
                                                      }{" "}
                                                    </span>
                                                  </p>
                                                  <p className="text-secondary font-weight-bold text-xl mt-1 mb-0">
                                                    Charge outcome network
                                                    status :{" "}
                                                    <span className="text-primary text-xl font-weight-bold mb-0">
                                                      {
                                                        payment_fail.stripe_charge_outcome_network_status
                                                      }
                                                    </span>{" "}
                                                  </p>
                                                  <p className="text-secondary font-weight-bold text-xl mt-1 mb-0">
                                                    Charge outcome seller
                                                    message :{" "}
                                                    <span className="text-primary text-xl font-weight-bold mb-0">
                                                      {
                                                        payment_fail.stripe_charge_outcome_seller_message
                                                      }
                                                    </span>{" "}
                                                  </p>
                                                  <p className="text-secondary font-weight-bold text-xl mt-1 mb-0">
                                                    Payment date :{" "}
                                                    <span className="text-primary text-xl font-weight-bold mb-0">
                                                      {moment(
                                                        payment_fail.paymentDate
                                                      )
                                                        .utc()
                                                        .format(
                                                          "DD  MMMM  YYYY"
                                                        ) +
                                                        " at " +
                                                        moment
                                                          .utc(
                                                            payment_fail.paymentDate
                                                          )
                                                          .format("HH:mm")}
                                                    </span>
                                                  </p>
                                                </div>
                                              </div>
                                            )
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.accessType !== "CANCELLED" ? (
                  <div className="section-padding mt-n6">
                    <div className="container">
                      <div className="about-items-wrapper">
                        <div className="row">
                          <div className="col-lg-8">
                            <div className="about-item" style={{ border: "0" }}>
                              <table className="table align-items-center mb-0">
                                <tr>
                                  <td>
                                    {this.state.isRefundingAndCanceling ? (
                                      <div>
                                        <div
                                          className="spinner-border text-primary"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                        {this.state.cancelingMessage}
                                      </div>
                                    ) : (
                                      <div className="item-icon-title">
                                        {/*<div className="item-icon">*/}
                                        {/*    <i className="icofont-repair bg-danger text-white "></i>*/}
                                        {/*</div>*/}
                                      </div>
                                    )}
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="about-item" style={{ border: "0" }}>
                              <table className="table align-items-center mb-0">
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <button
                                      className="btn btn-primary bg-danger"
                                      type="button"
                                      style={{ cursor: "pointer" }}
                                      onClick={this.saveCourseIdToRemove}
                                      data-bs-target="#confirmationDeleteSessionModal"
                                      data-bs-toggle="modal"
                                      id={this.state.subscriptionId}
                                    >
                                      Cancel subscription
                                    </button>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="section-padding mt-n6">
                    <div className="container">
                      <div className="about-items-wrapper">
                        <div className="row">
                          <div className="col-lg-8">
                            <div className="about-item" style={{ border: "0" }}>
                              <table className="table align-items-center mb-0">
                                <tr>
                                  <td>
                                    {this.state.isRefundingAndCanceling ? (
                                      <div>
                                        <div
                                          className="spinner-border text-primary"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                        {this.state.cancelingMessage}
                                      </div>
                                    ) : (
                                      <div className="item-icon-title">
                                        {/*<div className="item-icon">*/}
                                        {/*    <i className="icofont-repair bg-success text-white "></i>*/}
                                        {/*</div>*/}
                                      </div>
                                    )}
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="about-item" style={{ border: "0" }}>
                              <table className="table align-items-center mb-0">
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    <button
                                      className="btn btn-primary bg-success"
                                      type="button"
                                      style={{ cursor: "pointer" }}
                                      onClick={this.saveCourseIdToRemove}
                                      data-bs-target="#confirmationCancelSubscription"
                                      data-bs-toggle="modal"
                                      id={this.state.subscriptionId}
                                    >
                                      Restore subscription
                                    </button>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              className="modal fade"
              id="confirmationDeleteSessionModal"
              tabIndex="-1"
              aria-labelledby="confirmationDeleteSessionModal"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Cancel subscription
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  {!this.state.isCancelable ? (
                    <>
                      <div className="modal-body">
                        Confirm the action you want to make?
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-warning  btn-block text-white"
                          onClick={() => {
                            this.setState({
                              isCancelable: true,
                              cancelingMessage: "Cancelling...",
                            });
                          }}
                        >
                          Only cancel the subscription
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger btn-block"
                          onClick={() =>
                            this.setState({
                              isRefundableAndCancelable: true,
                              isCancelable: true,
                              cancelingMessage: "Refunding and cancelling...",
                            })
                          }
                        >
                          Cancel and refund the subscription
                        </button>
                      </div>
                    </>
                  ) : !this.state.isRefundableAndCancelable ? (
                    <>
                      <div className="modal-body">
                        Do you really want to cancel this subscription?
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-danger  btn-block"
                          onClick={this.cancelSubscription}
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Confirm
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary btn-block"
                          onClick={() => this.setState({ isCancelable: false })}
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="modal-body">
                        Do you really want to refund and cancel this
                        subscription?
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-danger  btn-block"
                          onClick={this.refundSubscription}
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Confirm
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary btn-block"
                          onClick={() =>
                            this.setState({
                              isRefundableAndCancelable: false,
                              isCancelable: false,
                            })
                          }
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="confirmationRefundPaymentHistory"
              tabIndex="-1"
              aria-labelledby="confirmationRefundPaymentHistory"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Refund transaction
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    Do you really want to refund this transaction?
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-danger  btn-block"
                      onClick={this.refundPayment}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary btn-block"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="confirmationCancelSubscription"
              tabIndex="-1"
              aria-labelledby="confirmationCancelSubscription"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Restore subscription
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    Do you really want to restore this subscription?
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-danger  btn-block"
                      onClick={this.cancelSubscription}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary btn-block"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="confirmationRefundPaymentChoice1"
              tabIndex="-1"
              aria-labelledby="confirmationRefundPaymentChoice1"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Confirm Refund
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    Do you really want to cancel this subscription?
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-danger  btn-block"
                      onClick={this.cancelSubscription}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary btn-block"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="modal fade"
              id="confirmationRefundPaymentChoice2"
              tabIndex="-1"
              aria-labelledby="confirmationRefundPaymentChoice2"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Confirm Refund
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="modal-body">
                    Do you really want to refund this subscription?
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-danger  btn-block"
                      onClick={this.refundSubscription}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Confirm
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary btn-block"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="position-fixed bottom-1 end-1 z-index-2">
          {this.state.successMessage ? (
            <div
              className="toast fade show p-2 mt-2 bg-gradient-info"
              role="alert"
              aria-live="assertive"
              id="infoToast"
              aria-atomic="true"
            >
              <div className="toast-header bg-transparent border-0">
                <i className="material-icons text-white me-2">check</i>
                <span className="me-auto text-white font-weight-bold">
                  Subscription{" "}
                </span>
                <small className="text-white">just now</small>
                <i
                  className="fas fa-times text-md text-white ms-3 cursor-pointer"
                  data-bs-dismiss="toast"
                  aria-label="Close"
                  onClick={(e) => this.setState({ successMessage: false })}
                ></i>
              </div>
              <hr className="horizontal light m-0" />
              <div className="toast-body text-white">{this.state.message}</div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}

import {sGET} from "../protocol/HTTP";
import backendUrls from "../urls/backendUrls";

const DashboardService = {
    getAll: function (cb) {
        const url = backendUrls.DashboardAdmin.GET_ALL;
        sGET(url, cb);

    }
}

export default DashboardService;
import React, {Component} from "react";
import Sidebar from "../sidebar/Sidebar";
import "../../../assets/subscription_plan.css";
import resourceService from "../../../network/services/ResourceService";
import ReactPaginate from "react-paginate";
import moment from "moment/moment";
import AdminHeader from "../header/AdminHeader";
import '../Dashboard/AdminHome.css';

class Resources extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resources: [],
            title: '',
            description: '',
            link: '',
            selectedFile: null,
            currentResource: {
                id: null,
                title: '',
                descritpion: '',
            }
        }
    }

    onLinkChange = (e) => {
        const link = e.target.value;

        this.setState(function (prevState) {
            return {
                currentResource: {
                    ...prevState.currentResource,
                    link: link,
                },
            };
        });
    }

    onTitleChange = (e) => {
        const title = e.target.value;

        console.log(title);

        this.setState(function (prevState) {
            return {
                currentResource: {
                    ...prevState.currentResource,
                    title: title,
                },
            };
        });
    }

    onDescriptionChange = (e) => {
        const description = e.target.value;

        this.setState(function (prevState) {
            return {
                currentResource: {
                    ...prevState.currentResource,
                    description: description,
                },
            };
        });
    }


    getId = (e) => {
        const id = e.target.getAttribute("id");
        resourceService.getById(id, (data) => {
            let trans = {
                id: data.id,
                title: data.title,
                description: data.description
            }
            this.setState({
                currentResource: data,
            });
        });
    }

    handleChange = ($event) => {
        const {name, value} = $event.target
        this.setState({[name]: value});
    }

    saveResource = () => {
        let d = new Date();
        let date = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
        let hours = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
        let fullDate = date + 'T' + hours + 'Z';

        console.log("title " + this.state.title);
        console.log("title " + this.state.link);

        if (this.state.title && this.state.description) {
            const formdata = new FormData();
            formdata.append("title", this.state.title);
            formdata.append("description", this.state.description);
            formdata.append("link", this.state.link);
            if (this.state.selectedFile) {
                formdata.append("file", this.state.selectedFile, this.state.selectedFile.name);
            }

            resourceService.create(formdata, (dat) => {
                this.setState({
                    submitted: false,
                    edited: false,
                    successMessage: true
                });
                this.showResource();
            });
        }

    }

    updateResource = () => {

        const formdata = new FormData();
        formdata.append("title", this.state.currentResource.title);
        formdata.append("description", this.state.currentResource.description);
        formdata.append("link", this.state.currentResource.link);
        if (this.state.selectedFile) {
            formdata.append("file", this.state.selectedFile, this.state.selectedFile.name);
        }

        // let data = {
        //     title: this.state.currentResource.title,
        //     description: this.state.currentResource.description,
        //    // link: this.state.currentResource.link,
        //     link : this.state.link,
        // };

        // console.log(data);

        resourceService.update(
            this.state.currentResource.id,
            formdata,
            (response) => {
                console.log(response);
                this.setState({
                    updateMessage: true,
                });
                this.showResource();
            }
        );
    }

    deleteResource = (e) => {
        const id = e.target.getAttribute("id");

        resourceService.delete(id, () => {
            this.setState({
                deleteMessage: true,
            });
            this.showResource();
        });
    }

    showResource = () => {
        this.setState({isLoading: true})

        resourceService.getAll((data) => {
            console.log(data);
            this.setState({resources: data, isLoading: false});
        });
    }

    componentDidMount() {
        this.showResource();
    }

    // On file select (from the pop up)
    onFileChange = (event) => {

        // Update the state
        let file = event.target.files[0];
        this.setState({selectedFile: file});

    };

    render() {
        const {currentResource} = this.state;
        return (
            <div className="g-sidenav-show  bg-gray-200">
                <Sidebar/>
                <main className="mainAdmin r ms-100">
                    <AdminHeader location="Resources"/>


                    <div className="container-fluid py-4">

                        <div
                            className="modal fade"
                            id="exampleModal"
                            tabIndex="-1"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true">

                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">
                                            Create a new resource
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <form action="app/src/components/Admin/Resource/Resources#">
                                            <div className="single-form">
                                                <label>Title <span style={{color: "red"}}>*</span></label>
                                                <input
                                                    type="text"
                                                    placeholder="name"
                                                    name="title"
                                                    value={this.state.title}
                                                    onChange={this.handleChange}

                                                />
                                            </div>
                                            <div className="single-form">
                                                <label>Description <span style={{color: "red"}}>*</span></label>
                                                <input
                                                    type="text"
                                                    placeholder="description"
                                                    name="description"
                                                    value={this.state.description}
                                                    onChange={this.handleChange}

                                                />
                                            </div>
                                            <div className="single-form">
                                                <label>Link</label>
                                                <input
                                                    type="text"
                                                    placeholder="Link"
                                                    name="link"
                                                    value={this.state.link}
                                                    onChange={this.handleChange}

                                                />
                                            </div>


                                            <div class="mb-3">
                                                <label htmlFor="formFile" class="form-label">File </label>
                                                <input class="form-control" type="file" id="formFile"
                                                       onChange={this.onFileChange} required/>
                                            </div>


                                            <div className="single-form">
                                                <button
                                                    type="submit"
                                                    className="btn btn-success btn-lg btn-block"
                                                    onClick={this.saveResource}
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    Add
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-12 col-md-6 mb-md-0 mb-4">
                                <div className="card">
                                    <div className="card-header pb-0">
                                        <div className="row">
                                            <div className="col-lg-6 col-7">
                                                <h6>All resources</h6>
                                            </div>
                                            <div className="col-lg-6 col-5 my-auto text-end">
                                                <div className="dropdown float-lg-end pe-4">
                                                    <a
                                                        href="javascript:;"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                    >
                                                        <button type="button"
                                                                className="btn btn-secondary">New resource
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="table-responsive p-0">
                                        <table className="table align-items-center mb-0">
                                            <thead>
                                            <tr>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">ID</th>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">NAME</th>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">description</th>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Link</th>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">File</th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">created
                                                    on
                                                </th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">updated
                                                    on
                                                </th>
                                                <th className="text-secondary opacity-7">...</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.isLoading ? (
                                                    <div className="spinner-border text-primary" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>

                                                ) : (
                                                    <></>
                                                )
                                            }
                                            {this.state.resources && this.state.resources.map((resource) => (
                                                <tr key={resource.id}>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            {resource.id}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            <div
                                                                className="d-flex flex-column justify-content-center">
                                                                <h6 className="mb-0 text-sm">{resource.title}</h6>
                                                                {/*<p className="text-xs text-secondary mb-0">john@creative-tim.com</p>*/}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0">{resource.description}</p>
                                                        {/*<p className="text-xs text-secondary mb-0">Organization</p>*/}
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0"><a
                                                            href={resource.link} target="_blank">{resource.link}</a></p>
                                                        {/*<p className="text-xs text-secondary mb-0">Organization</p>*/}
                                                    </td>
                                                    <td>
                                                        <p className="text-xs font-weight-bold mb-0"><a
                                                            href={process.env.REACT_APP_ACCOUNTING_SERVICE + "/media/" + resource.filePath}
                                                            target="_blank">file</a></p>
                                                        {/*<p className="text-xs text-secondary mb-0">Organization</p>*/}
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <p className="text-xs font-weight-bold mb-0">{moment(resource.createdAt).utc().format('DD  MMMM  YYYY')}</p>
                                                    </td>
                                                    <td className="align-middle text-center text-sm">
                                                        <p className="text-xs font-weight-bold mb-0">{moment(resource.updatedAt).utc().format('DD  MMMM  YYYY')}</p>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex px-2 py-1">
                                                            <div
                                                                className="d-flex flex-column justify-content-center">
                                                                <a
                                                                    className="text-dark"
                                                                    href="javascript:;"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target={
                                                                        "#exampleModal" + resource.id
                                                                    }
                                                                >
                                                                                <span
                                                                                    className="material-icons"
                                                                                    onClick={this.getId}
                                                                                    id={resource.id}
                                                                                    style={{color: 'dodgerblue'}}>edit</span>
                                                                </a>

                                                                <span
                                                                    className='material-icons'
                                                                    onClick={this.deleteResource}
                                                                    id={resource.id} style={{
                                                                    cursor: 'pointer',
                                                                    color: 'firebrick'
                                                                }}>delete</span>

                                                                <span
                                                                    className='material-icons danger' style={{
                                                                    cursor: 'pointer',
                                                                    color: 'dodgerblue'
                                                                }}>visibility</span>
                                                                <div
                                                                    className="modal fade"
                                                                    id={"exampleModal" + resource.id}
                                                                    tabIndex="-1"
                                                                    aria-labelledby="exampleModalLabel"
                                                                    aria-hidden="true"
                                                                >
                                                                    <div
                                                                        className="modal-dialog modal-dialog-centered">
                                                                        <div className="modal-content">
                                                                            <div
                                                                                className="modal-header">
                                                                                <h5
                                                                                    className="modal-title"
                                                                                    id="exampleModalLabel"
                                                                                >
                                                                                    Edit resource
                                                                                </h5>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn-close"
                                                                                    data-bs-dismiss="modal"
                                                                                    aria-label="Close"
                                                                                ></button>
                                                                            </div>
                                                                            <form
                                                                                action="app/src/components/Admin/Resource/Resources#">
                                                                                <div
                                                                                    className="single-form">
                                                                                    <input
                                                                                        type="text"
                                                                                        placeholder="Title"
                                                                                        name="title"
                                                                                        value={currentResource.title}
                                                                                        onChange={this.onTitleChange}
                                                                                    />
                                                                                </div>

                                                                                <div
                                                                                    className="single-form">
                                                                                    <input
                                                                                        type="text"
                                                                                        placeholder="Description"
                                                                                        name="description"
                                                                                        value={currentResource.description}
                                                                                        onChange={
                                                                                            this.onDescriptionChange
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div
                                                                                    className="single-form">
                                                                                    <input
                                                                                        type="text"
                                                                                        placeholder="Link"
                                                                                        name="link"
                                                                                        value={currentResource.link}
                                                                                        onChange={
                                                                                            this.onLinkChange
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="mb-3">
                                                                                    <label htmlFor="formFile"
                                                                                           className="form-label">File </label>
                                                                                    <input className="form-control"
                                                                                           type="file" id="formFile"
                                                                                           onChange={this.onFileChange}
                                                                                           required/>
                                                                                </div>
                                                                                <div
                                                                                    className="single-form">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-success btn-lg btn-block"
                                                                                        onClick={this.updateResource}
                                                                                        id={resource.id}
                                                                                        data-bs-dismiss="modal"
                                                                                        aria-label="Close"
                                                                                    >
                                                                                        Update
                                                                                    </button>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="position-fixed bottom-1 end-1 z-index-2">

                                        {this.state.successMessage ? (
                                                <div className="toast fade show p-2 mt-2 bg-gradient-info" role="alert"
                                                     aria-live="assertive" id="infoToast" aria-atomic="true">
                                                    <div className="toast-header bg-transparent border-0">
                                                        <i className="material-icons text-white me-2">
                                                            check
                                                        </i>
                                                        <span
                                                            className="me-auto text-white font-weight-bold">Resource </span>
                                                        <small className="text-white">just now</small>
                                                        <i className="fas fa-times text-md text-white ms-3 cursor-pointer"
                                                           data-bs-dismiss="toast" aria-label="Close"></i>
                                                    </div>
                                                    <hr className="horizontal light m-0"/>
                                                    <div className="toast-body text-white">
                                                        Resource successfully created.
                                                    </div>
                                                </div>
                                            ) :
                                            (<></>)}
                                        {this.state.updateMessage ? (
                                                <div className="toast fade show p-2 mt-2 bg-white" role="alert"
                                                     aria-live="assertive" id="warningToast" aria-atomic="true">
                                                    <div className="toast-header border-0">
                                                        <i className="material-icons text-warning me-2">
                                                            check
                                                        </i>
                                                        <span className="me-auto font-weight-bold">Resource</span>
                                                        <small className="text-body">just now</small>
                                                        <i className="fas fa-times text-md ms-3 cursor-pointer"
                                                           data-bs-dismiss="toast" aria-label="Close"></i>
                                                    </div>
                                                    <hr className="horizontal dark m-0"/>
                                                    <div className="toast-body">
                                                        updated Resource .
                                                    </div>
                                                </div>
                                            ) :
                                            (<></>)}

                                        {this.state.deleteMessage ? (
                                                <div className="toast fade show p-2 mt-2 bg-white" role="alert"
                                                     aria-live="assertive" id="warningToast" aria-atomic="true">
                                                    <div className="toast-header border-0">
                                                        <i className="material-icons text-warning me-2">
                                                            check
                                                        </i>
                                                        <span className="me-auto font-weight-bold">Resource </span>
                                                        <small className="text-body">just now</small>
                                                        <i className="fas fa-times text-md ms-3 cursor-pointer"
                                                           data-bs-dismiss="toast" aria-label="Close"></i>
                                                    </div>
                                                    <hr className="horizontal dark m-0"/>
                                                    <div className="toast-body">
                                                        Resource delete.
                                                    </div>
                                                </div>
                                            ) :
                                            (<></>)}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-footer py-4">
                            <nav aria-label="...">
                                <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={'...'}
                                    pageCount={1}
                                    marginPagesDisplayed={3}
                                    pageRangeDisplayed={6}
                                    onPageChange={this.handleChange}
                                    containerClassName={"pagination justify-content-end mb-0"}
                                    pageClassName={"page-item"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    pageLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            </nav>
                        </div>

                    </div>

                </main>
            </div>

        );
    }
}

export default Resources;
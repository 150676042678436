import PaymentAlert from "../../../../components/frontv2.0/common/status/PaymentAlert";
import SubHeader from "../../../../components/frontv2.0/common/header/SubHeader";
import OrderSummary from "../../../../components/frontv2.0/common/sessions/OrderSummary";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import LastSubscriptionService from "../../../../network/services/LastSubscriptionService";
import Loader from "../../../../components/frontv2.0/common/loader/Loader";
import { getUser } from "../../../../network/auth/Auth";
import UserService from "../../../../network/services/UserService";
import MainLayout from "../../../../layouts/MainLayout";
import Header from "../../../../components/frontv2.0/common/header/Header";

const AvalaibleCourseCheckoutSuccess = () => {
  const navigate = useNavigate();
  /**
   * States
   */
  const [firstLoader, setFirstLoader] = useState(false);
  const [paymentSuccessInfo, setPaymentSuccessInfo] = useState({});
  const [SummaryData, setSummaryData] = useState({});
  const [courseId, setCourseId] = useState("");
  const [course, setCourse] = useState({})

  /**
   * Comportments
   */
  const showLastsubscription = () => {
    setFirstLoader(true);

    LastSubscriptionService.GET_LAST_SUBSCRIPTION_COURSE((data) => {
      console.log(data)
      if (data) {
        const paymentSuccesInfo = {
          title: `Thanks for subscribing to course : ${data.subscription?.course_name}`,
          subTitle: `You will get an email confirmation sent to ${data.subscription?.user_email}`,
          enableButtonAction: true,
          redirectButtonLabel: "Go to My Courses",
          downloadButtonLabel: "Download Invoice",
          urlToRedirect: "/workspace/mycourses",
        };
        const summaryData = {
          title: "Order Summary",
          // product: `${data.subscription.course_name} – ${data.subscription.plan.name}`,
          product: `${data.subscription.course_name} – ${data.subscription.plan.userDisplayName ? data.subscription.plan.userDisplayName : data.subscription.plan.name}`,
          paymentMethod: "Card",
          currency: `${data.subscription.currency}`,
          orderTotal: `${data.subscription.payment_amount}`,
        };
        setCourseId(data.subscription.course_id);
        setPaymentSuccessInfo(paymentSuccesInfo);
        setSummaryData(summaryData);
        setCourse(data.subscription)
      }
      setFirstLoader(false);
    });
  };

  const getInvoice = () => {
    let user = getUser();
    let data = {
      user_id: user.id,
      course_id: courseId,
    };
    UserService.getCourseInvoice("Utrains-subscription-invoice.pdf", data);
  };

  useEffect(() => {
    showLastsubscription();
  }, []);

  const handleOnclick = () => {
    navigate("/workspace/mycourses");
  };

  /**
   * Render
   */
  return (
    <>
      <MainLayout>
        <MainLayout.Content>
          <div
            className={`tw-flex md:tw-flex-row tw-flex-col tw-w-full md:tw-space-x-2 md:tw-space-y-0'`}
          >
            {firstLoader ? (
              <div className="tw-flex tw-flex-col md:tw-justify-center tw-justify-center tw-items-center tw-py-16">
                <Loader />
              </div>
            ) : (
              <>
                <PaymentAlert
                  data={paymentSuccessInfo}
                  onClickToDownload={getInvoice}
                  onClickToRedirect={handleOnclick}
                />

                {/* SectionLeft */}
                <OrderSummary data={SummaryData} />
              </>
            )}
          </div>
        </MainLayout.Content>

        <MainLayout.Header>
          <Header />
        </MainLayout.Header>

        <MainLayout.SubHeader>
          <SubHeader
            data={[
              { value: "Home", url: "/workspace" },
              { value: "Available Courses", url: "/workspace/courses" },
              { value: course?.course_name, url: "/workspace/courses/details/"+courseId },
              { value: "Checkout", url: "#" },
            ]}
          />
        </MainLayout.SubHeader>
      </MainLayout>
    </>
  );
};

export default AvalaibleCourseCheckoutSuccess;

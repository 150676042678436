import { useEffect, useState } from "react";
import Alert from "../../../components/frontv2.0/common/alert/Alert";
import Illustration from "../../../components/frontv2.0/common/illustration/Illustration";
import Logo from "../../../components/frontv2.0/common/logo/Logo";
import TextHeader from "../../../components/frontv2.0/common/text/TextHeader";
import { FAVICON_WITHE, IMAGE_4 } from "../../../constants/images";
import AuthLayout from "../../../layouts/AuthLayout";
import { Link, useNavigate } from "react-router-dom";
import ButtonCustom from "../../../components/frontv2.0/common/button/ButtonCustom";
import InputCustom from "../../../components/frontv2.0/common/form/InputCustom";
import UserService from "../../../network/services/UserService";
import { EMAIL_REGEX } from "../../../constants/Constants";
import { isAuthenticated } from "../../../network/auth/Auth";

const ForgottenPass = () => {
  const [email, setEmail] = useState("");
  const [callSuccess, setCallSuccess] = useState(false);
  const [callAttempt, setCallAttempt] = useState(false);
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const renderContent = () => {
    return (
      <>
        <div className="tw-mt-[22px]">
          <InputCustom
            name={"email"}
            id={"email"}
            type={"text"}
            icon={"pi pi-lock"}
            placeHolder={"E-mail Adress"}
            value={email}
            onChange={handleEmailChange}
            error={error ? error : ""}
          />
        </div>

        <div className="tw-mt-[42px]">
          {isLoading ? (
            <ButtonCustom
              classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
              label="Loading..."
              icon={"pi pi-spin pi-spinner"}
              disabled={true}
            />
          ) : (
            <ButtonCustom
              onClick={handleOnSubmit}
              classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
              label="Reset password"
              disabled={disabled}
            />
          )}
        </div>

        <p className="tw-mt-10 tw-text-sm tw-text-center tw-text-gray-500">
          I have an account,{" "}
          <Link to="/login">
            <span className="tw-text-green-800 tw-underline hover:tw-text-primary-700">
              login
            </span>
          </Link>
        </p>
      </>
    );
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let message = "";
    if (email.match(EMAIL_REGEX)) {
      setIsLoading(true);
      UserService.tryPasswordReset(
        { email },
        (data) => {
          if (data !== null) {
            message = data.message;

            if (data.success) {
              setCallSuccess(true);
              setIsLoading(false);
              setDisabled(true);
            } else {
              setCallSuccess(false);
              setIsLoading(false);
            }
          } else {
            setCallSuccess(false);
            setIsLoading(false);
            message = "Error: Unable to connect";
          }

          setMessage(message);
          setCallAttempt(true);
          setIsLoading(false);
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      setError("The email address is invalid.");
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setError("")
    setEmail(value);
  };

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/workspace/mysessions");
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    const queryParams = new URLSearchParams(window.location.search);
    let emailUrl = queryParams.get("email");

    setEmail(emailUrl ? emailUrl : "");
    setCallSuccess(false);
    setCallAttempt(false);
    setMessage("");
    setDisabled(false);

    if (emailUrl) {
      handleOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <AuthLayout>
      <AuthLayout.Body>
        <div>
          <div className="tw-bottom-0 tw-flex tw-flex-col tw-justify-center tw-px-6 tw-py-10 lg:tw-px-8">
            <div className="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-sm">
              <Logo width={200} />
            </div>
            <div className="tw-h-full tw-mt-10 sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-sm">
              <div className="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-sm">
                <div className="tw-w-full tw-mt-24">
                  <p className="tw-mt-2 tw-mb-8 tw-text-gray-500 tw-text-[16px]"></p>
                  <TextHeader label={"Change"} textUnderline={"Now"} size={4} />
                  <p className="tw-mt-2 tw-mb-8 tw-text-gray-500 tw-text-[16px]">
                    {
                      "Enter your email address and we'll send you instructions on how to reset your password."
                    }
                  </p>
                </div>
              </div>
              {callAttempt && !isLoading && (
                <Alert
                  type={callSuccess ? "success" : "danger"}
                  message={message}
                />
              )}
              <form className="">{renderContent()}</form>
            </div>
          </div>
        </div>
      </AuthLayout.Body>

      <AuthLayout.Illustration>
        <Illustration
          favIcon={FAVICON_WITHE}
          image={IMAGE_4}
          label={`"Linux, AWS and DevOps Training."`}
        />
      </AuthLayout.Illustration>
    </AuthLayout>
  );
};

export default ForgottenPass;

import React from "react";
import RoundedIcon from "../icons/RoundedIcon";
import ButtonCustom from "../button/ButtonCustom";

const PaymentAlert = ({ data, onClickToDownload, onClickToRedirect }) => {
  return (
    <>
      <div
        className={`tw-flex tw-flex-col md:tw-justify-center tw-items-center md:tw-bg-accent-gray tw-rounded-[12px] md:tw-p-8 tw-mt-8 md:tw-mt-0 md:tw-w-1/2`}
      >
        <div
          className={`tw-flex md:tw-flex-col tw-flex-row md:tw-justify-center tw-items-center `}
        >
          <div className="tw-flex tw-flex-col tw-pr-4">
            <RoundedIcon
              icon="pi-check"
              iconSize="2rem"
              css="tw-w-16 tw-h-16 tw-bg-secondary-green tw-text-white"
            />
          </div>
          <div className="tw-flex tw-flex-col tw-space-y-2 md:tw-text-center md:tw-justify-center md:tw-items-center">
            <p className="md:tw-text-xl tw-text-sm tw-font-bold tw-text-primary md:tw-mt-2 md:tw-w-full ">
              {" "}
              {data.title}{" "}
            </p>
            <p className="md:tw-text-base tw-text-xs tw-text-secondary-green">
              {data.subTitle}
            </p>
          </div>
        </div>
        {data.enableButtonAction ? (
          <div className="tw-flex md:tw-flex-col md:tw-space-y-2 tw-space-x-2 md:tw-space-x-0 tw-mt-8 md:tw-w-2/3 md:tw-items-center md:tw-justify-center tw-w-full">
            <ButtonCustom
              classes={
                "tw-bg-accent-blue hover:tw-bg-primary-100 tw-text-primary tw-border tw-border-dashed tw-border-primary sm:tw-text-base"
              }
              label={data.downloadButtonLabel}
              onClick={onClickToDownload}
              icon={"pi pi-download tw-text-xs"}
            />
            <ButtonCustom
              classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
              label={data.redirectButtonLabel}
              onClick={onClickToRedirect}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default PaymentAlert;

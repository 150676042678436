import React from "react";
import ButtonCustom from "../button/ButtonCustom";
import { singleName } from "../../../../constants/Constants";

const SubscriptionPlan = ({
    subLength,
    data,
    sessionName,
    index,
    allPricesPlan,
    type = "classic",
    onClick,
}) => {
    /**
     * Render
     */


    return (
        <>
            {subLength <= 2 ? (
                <div
                    className={`tw-rounded-[12px] tw-w-full 
      ${type === "classic" && "tw-text-green-800 tw-bg-accent-green"} 
      ${type === "medium" && "tw-text-primary-800 tw-bg-accent-blue"} 
      ${type === "premium" && "tw-text-white tw-bg-primary"}  
      tw-p-[18px] tw-w-full sm:tw-flex sm:tw-flex-row tw-items-center`}
                >
                    <div className="tw-flex tw-flex-col sm:tw-w-1/4">
                        {/* <span className="tw-font-semibold tw-text-[22px]">{data.name}</span> */}
                        <span className="tw-font-semibold tw-text-[22px]">
                            {data.userDisplayName ? data.userDisplayName : data.name}
                        </span>
                        <span className="tw-text-[14px] tw-mb-[15px]"> {sessionName}</span>
                        <span className="tw-text-[32px]">${data.price}</span>
                    </div>
                    <div className="tw-mt-[15px] tw-mb-[40px] tw-text-[14px] sm:tw-w-2/4">
                        <h1
                            className={`                    ${type === "classic" && "tw-text-green-700"
                                } 
                    ${type === "medium" && "tw-text-primary"} 
                    ${type === "premium" && "tw-text-white"
                                }  tw-font-medium lg:tw-text-xl tw-mb-[15px]`}
                        >
                            This plan includes:
                        </h1>
                        <div className="sm:tw-grid sm:tw-grid-cols-2 tw-mt-4 tw-space-y-[6px]">
                            <div className="tw-flex tw-items-center">
                                <div
                                    className={`
                  ${type === "classic" && "tw-text-white tw-bg-green-700"} 
                  ${type === "medium" && "tw-text-white tw-bg-primary"} 
                  ${type === "premium" && "tw-text-primary tw-bg-white"} 
                  tw-rounded-full
             `}
                                >
                                    <i
                                        className="tw-m-1 pi pi-check"
                                        style={{ fontSize: "0.7rem" }}
                                    ></i>
                                </div>
                                {/*  <span className="tw-mx-[8px]">
                  Pay in {data.numberInstallment} installment(s)
                </span>*/}
                                <span className="tw-mx-[8px]">Pay in one time</span>
                            </div>
                            {/* <div className="tw-flex tw-items-center">
                    <div
                        className={`
                  ${type === "classic" && "tw-text-white tw-bg-green-700"} 
                  ${type === "medium" && "tw-text-white tw-bg-primary"} 
                  ${type === "premium" && "tw-text-primary tw-bg-white"} 
                  tw-rounded-full
             `}
                    >
                      <i
                          className="tw-m-1 pi pi-check"
                          style={{ fontSize: "0.7rem" }}
                      ></i>
                    </div>
                    <span className="tw-mx-[8px]">
                  ${data.paymentAmount} per Installment
                </span>
                    <span className="tw-mx-[8px]">
                  ${data.paymentAmount} per Installment
                </span>

                  </div>*/}
                            <div className="tw-flex tw-items-center">
                                <div
                                    className={`
                  ${type === "classic" && "tw-text-white tw-bg-green-700"} 
                  ${type === "medium" && "tw-text-white tw-bg-primary"} 
                  ${type === "premium" && "tw-text-primary tw-bg-white"} 
                  tw-rounded-full
             `}
                                >
                                    <i
                                        className="tw-m-1 pi pi-check"
                                        style={{ fontSize: "0.7rem" }}
                                    ></i>
                                </div>
                                <span className="tw-mx-[8px]">No recurrent billing</span>
                            </div>
                            <div className="tw-flex tw-items-center">
                                <div
                                    className={`
                  ${type === "classic" && "tw-text-white tw-bg-green-700"} 
                  ${type === "medium" && "tw-text-white tw-bg-primary"} 
                  ${type === "premium" && "tw-text-primary tw-bg-white"} 
                  tw-rounded-full
             `}
                                >
                                    <i
                                        className="tw-m-1 pi pi-check"
                                        style={{ fontSize: "0.7rem" }}
                                    ></i>
                                </div>
                                <span className="tw-mx-[8px]">${data.price} due now</span>
                            </div>
                        </div>
                    </div>
                    <div className="sm:tw-w-1/4">
                        <ButtonCustom
                            classes={`
              ${type === "classic" &&
                                "tw-text-white tw-bg-[#309255] hover:tw-bg-[#055021]"
                                } 
              ${type === "medium" &&
                                "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                                } 
              ${type === "premium" &&
                                "tw-border-[1.5px] tw-text-[#1E4E70] tw-bg-[#FFFFFF] hover:tw-bg-[#FBFBFB]"
                                }
              
          `}
                            label="Enroll Now"
                            onClick={onClick}
                        />
                    </div>
                </div>
            ) : (
                <div
                    className={`tw-flex tw-flex-col  tw-justify-between tw-rounded-[12px] tw-min-w-[220px] 
        ${type === "classic" ? "tw-text-green-800 tw-bg-accent-green" : ""} 
        ${type === "medium" ? "tw-text-primary-800 tw-bg-accent-blue" : ""} 
        ${type === "premium" ? "tw-text-white tw-bg-primary" : ""}  
        tw-p-[18px] tw-w-[100%] tw-h-full`}
                >
                    <div className="tw-flex tw-flex-col ">
                    <span className="tw-font-semibold tw-text-[22px]">
                            {data.lightPlan.userDisplayName ? data.lightPlan.userDisplayName : data.name}
                        </span>
                        {/* <span className="tw-font-semibold tw-text-[22px]">{data.name}</span> */}
                        <span className="tw-text-[14px] tw-mb-[15px]"> {sessionName}</span>
                        <span className="tw-text-[32px]">${data.price}</span>
                    </div>
                    <div className="tw-mt-[15px] tw-mb-[40px] tw-text-[14px]">
                        <h1
                            className={`                    ${type === "classic" ? "tw-text-green-700" : ""
                                } 
                    ${type === "medium" ? "tw-text-primary" : ""} 
                    ${type === "premium" ? "tw-text-white" : ""
                                }  tw-font-medium lg:tw-text-xl tw-mb-[15px]`}
                        >
                            This plan includes:
                        </h1>
                        <div className="tw-mt-4 tw-space-y-[6px]">
                            {data.price > 3000 ? (
                                <>
                                    {data.price <= 3300 ? (
                                        <div className="tw-flex tw-items-center">
                                            <div
                                                className={`
                                            ${type === "classic"
                                                        ? "tw-text-white tw-bg-green-700"
                                                        : ""
                                                    } 
                                            ${type === "medium"
                                                        ? "tw-text-white tw-bg-primary"
                                                        : ""
                                                    } 
                                            ${type === "premium"
                                                        ? "tw-text-primary tw-bg-white"
                                                        : ""
                                                    } 
                                            tw-rounded-full
                                       `}
                                            >
                                                <i
                                                    className="tw-m-1 pi pi-check"
                                                    style={{ fontSize: "0.7rem" }}
                                                ></i>
                                            </div>
                                            <span className="tw-mx-[8px]">Save $200</span>
                                        </div>
                                    ) : null}
                                </>
                            ) : (
                                <div className="tw-flex tw-items-center">
                                    <div
                                        className={`
                      ${type === "classic"
                                                ? "tw-text-white tw-bg-green-700"
                                                : ""
                                            } 
                      ${type === "medium" ? "tw-text-white tw-bg-primary" : ""} 
                      ${type === "premium" ? "tw-text-primary tw-bg-white" : ""
                                            } 
                      tw-rounded-full
                 `}
                                    >
                                        <i
                                            className="tw-m-1 pi pi-check"
                                            style={{ fontSize: "0.7rem" }}
                                        ></i>
                                    </div>
                                    <span className="tw-mx-[8px]">Save $500</span>
                                </div>
                            )}

                            {type !== "premium" && (
                                <>
                                    <div className="tw-flex tw-items-center">
                                        <div
                                            className={`
                    ${type === "classic" && "tw-text-white tw-bg-green-700"} 
                    ${type === "medium" && "tw-text-white tw-bg-primary"} 
                    ${type === "premium" && "tw-text-primary tw-bg-white"} 
                    tw-rounded-full
               `}
                                        >
                                            <i
                                                className="tw-m-1 pi pi-check"
                                                style={{ fontSize: "0.7rem" }}
                                            ></i>
                                        </div>
                                        <span className="tw-mx-[8px]">
                                            Pay in {data.numberInstallment} installment(s)
                                        </span>
                                    </div>
                                    <div className="tw-flex tw-items-center">
                                        <div
                                            className={`
                    ${type === "classic" && "tw-text-white tw-bg-green-700"} 
                    ${type === "medium" && "tw-text-white tw-bg-primary"} 
                    ${type === "premium" && "tw-text-primary tw-bg-white"} 
                    tw-rounded-full
               `}
                                        >
                                            <i
                                                className="tw-m-1 pi pi-check"
                                                style={{ fontSize: "0.7rem" }}
                                            ></i>
                                        </div>
                                        <span className="tw-mx-[8px]">
                                            ${data.paymentAmount} per installment
                                        </span>
                                    </div>
                                </>
                            )}

                            <div className="tw-flex tw-items-center">
                                <div
                                    className={`
                    ${type === "classic" ? "tw-text-white tw-bg-green-700" : ""
                                        } 
                    ${type === "medium" ? "tw-text-white tw-bg-primary" : ""} 
                    ${type === "premium" ? "tw-text-primary tw-bg-white" : ""} 
                    tw-rounded-full
               `}
                                >
                                    <i
                                        className="tw-m-1 pi pi-check"
                                        style={{ fontSize: "0.7rem" }}
                                    ></i>
                                </div>
                                <span className="tw-mx-[8px]">
                                    ${data.paymentAmount} due now
                                </span>
                            </div>
                            {type === "premium" && (
                                <div className="tw-flex tw-items-center">
                                    <div
                                        className={`
                    ${type === "classic" ? "tw-text-white tw-bg-green-700" : ""
                                            } 
                    ${type === "medium" ? "tw-text-white tw-bg-primary" : ""} 
                    ${type === "premium" ? "tw-text-primary tw-bg-white" : ""} 
                    tw-rounded-full
               `}
                                    >
                                        <i
                                            className="tw-m-1 pi pi-check"
                                            style={{ fontSize: "0.7rem" }}
                                        ></i>
                                    </div>
                                    <span className="tw-mx-[8px]">Pay in one time</span>
                                </div>
                            )}
                            <div className="tw-flex tw-items-center">
                                <div
                                    className={`
                    ${type === "classic" ? "tw-text-white tw-bg-green-700" : ""
                                        } 
                    ${type === "medium" ? "tw-text-white tw-bg-primary" : ""} 
                    ${type === "premium" ? "tw-text-primary tw-bg-white" : ""} 
                    tw-rounded-full
               `}
                                >
                                    <i
                                        className="tw-m-1 pi pi-check"
                                        style={{ fontSize: "0.7rem" }}
                                    ></i>
                                </div>
                                {type === "premium" ? (
                                    <span className="tw-mx-[8px]">No recurrent billing</span>
                                ) : (
                                    <span className="tw-mx-[8px]">
                                        ${data.paymentAmount + ' every ' + data.intervalBtwInstallment} {data.intervalBtwInstallment > 1 ?
                                            data.paymentIntervalType : singleName(data.paymentIntervalType)}{" "}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="tw-w-full">
                        <ButtonCustom
                            classes={`
                ${type === "classic" &&
                                "tw-text-white tw-bg-[#309255] hover:tw-bg-[#055021]"
                                } 
                ${type === "medium" &&
                                "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                                } 
                ${type === "premium" &&
                                "tw-border-[1.5px] tw-text-[#1E4E70] tw-bg-[#FFFFFF] hover:tw-bg-[#FBFBFB]"
                                }
                
            `}
                            label="Enroll Now"
                            onClick={onClick}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default SubscriptionPlan;

import { Link, useNavigate } from "react-router-dom";
import { GLOBAL_PADDING_STYLE } from "../../../../constants/styles";
import ButtonCustom from "../button/ButtonCustom";
import Logo from "../logo/Logo";
import Avatar from "../avatar/Avatar";
import { getUser, isAuthenticated } from "../../../../network/auth/Auth";
import { MEDIA_URL } from "../../../../network/urls/backendUrls";
import { USER_AVATAR_FEMALE, USER_AVATAR_MALE } from "../../../../constants/images";
import { useEffect, useState } from "react";

const AppHeader = () => {
  /**
   * States
   */
  const globalStyle = GLOBAL_PADDING_STYLE;
  const navigate = useNavigate();

  const [user, setUser] = useState(null);

  const loadData = () => {
    let user = getUser();
    setUser(user);
  };

  /**
   * Comportments
   */
  const handleOnclick = () => {
    navigate("/workspace");
  };

  useEffect(() => {
    loadData();
  }, []);

  /**
   * Render
   */
  return (
    <>
      <div className={`tw-container ${globalStyle}`}>
        <div
          className={`tw-flex lg:tw-flex-row tw-justify-between tw-items-center  tw-py-[4px]`}
        >
          <Logo classes={"sm:tw-h-16 tw-h-12 tw-p-2"} />
          {isAuthenticated() ? (
            <>
              <button
                onClick={handleOnclick}
                className="tw-flex tw-items-center tw-space-x-[10px] tw-justify-center tw-py-2 tw-text-sm tw-font-medium tw-leading-5 tw-text-gray-700 tw-transition tw-duration-150 tw-ease-in-out hover:tw-text-gray-500 focus:tw-outline-none"
                type="button"
                aria-haspopup="true"
                aria-expanded="true"
                aria-controls="headlessui-menu-items-117"
              >
                <span className="tw-text-[14px] tw-hidden md:tw-block tw-font-bold">
                Go to the workspace
                </span>
                <Avatar
                  alt={"avatar"}
                  size={"35px"}
                  src={
                    user?.profilePicture
                      ? MEDIA_URL + user.profilePicture
                      : user?.gender && user.gender === "F"
                      ? USER_AVATAR_FEMALE
                      : USER_AVATAR_MALE
                  }
                />
              </button>
            </>
          ) : (
            <div className="md:tw-flex tw-flex-row tw-space-x-4 tw-tw-h-[44px] tw-hidden sm:tw-block">
              <Link to={"/register"}>
                <ButtonCustom
                  classes={
                    "tw-border-[1.5px] tw-border-[#309255] tw-text-[#309255] hover:tw-text-white hover:tw-bg-[#309255]"
                  }
                  label="Sign up"
                />
              </Link>
              <Link to={"/login"}>
                <ButtonCustom
                  classes={
                    "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                  }
                  label="Log In"
                />
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AppHeader;

import React from "react";
import ButtonCustom from "../button/ButtonCustom";
import { CardElement } from "@stripe/react-stripe-js";
import moment from "moment";
import { singleName } from "../../../../constants/Constants";
import InputCustom from "../form/InputCustom";
import { useState, useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import UserService from "../../../../network/services/UserService";


const OrderCourseCheckout = ({
  isSession = true,
  data,
  enableForm,
  stepperEnabled,
  isNextStep,
  onClickActionToPreviousStep,
  onClickAction,
  makePayment = false,
  sessionName,
  nextPaymentDate,
  firstPaymentDate,
  isLoading,
  setIdCode,
}) => {
 
  const toast = useRef(null);

  const [code, setCode] = useState("");
  const [isCardElementDisabled, setCardElementDisabled] = useState(false);

  const [remainingAmount, setRemainingAmount] = useState("");
  const [showDiscountMessage, setShowDiscountMessage] = useState(false);
  const [amountAfterDiscount, setAmountAfterDiscount] = useState("");
  const [amountOfDiscount, setAmountOfDiscount] = useState("");
  const [visibleDiscount, setVisibleDiscount] = useState(false);


  const ApplyCode = () => {


    setCardElementDisabled(true);
    let isSession = "Course"
    let datas = {
      code: code,
      subscriptionPlanId: data.id,
      Course: isSession
    };
    console.log(datas);

    UserService.getDiscountCode(datas, (response) => {
      console.log(response);

      if (response != null) {
        if (response.success) {
          console.log(response.success)
          setCardElementDisabled(false);
          showSuccess("The code entered is valid!")
          setShowDiscountMessage(true)
          console.log(response.data.coupon.value)
          console.log(response.data.coupon.id)

          // setRemainingAmount(response.data.remaining)
          // setDiscountValue(response.data.coupon.value)
          setIdCode(response.data.coupon.id)
          setAmountAfterDiscount(response.data.currentAmountAfterDiscountToBePaid)
          setAmountOfDiscount(response.data.amountOfTheDiscount           )

        } else {

          showError(response.message)
          setShowDiscountMessage(false)
          setCardElementDisabled(false);
        }
      } else {
        showError("Unknown error");
        setShowDiscountMessage(false)
        setCardElementDisabled(false);

      }
      // resetForm();
    });
  };


  const resetForm = () => {
    setCode("");
    // setShowError(false);
    // setErrorMessage("");
    setShowDiscountMessage(false)
  };

  const onCodeChange = (e) => {
    const code = e.target.value;
    setCode(code);
    if (code.trim() === "") {
      resetForm();
    }

  };

  const showSuccess = (message) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 5000,
    });
  };

  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };

  const checkEligibilityCoupon = () => {


    UserService.CheckEligibilityCoupon((response) => {
      console.log(response);

      if (response != null) {
        if (response.success) {
          console.log(response.success)
          console.log(response.isActiveCoupon)
          setVisibleDiscount(response.isActiveCoupon)
        } else {

          console.log("")
        }
      }
    });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");
    setCode(code);
  
    checkEligibilityCoupon()
    applyCodeReloadPage(code)
  }, []);

  const applyCodeReloadPage = (code) => {

    setCardElementDisabled(true);
    let isSession = "Course"
    let datas = {
      code: code,
      subscriptionPlanId: data.id,
      Session: isSession
    };
    console.log(datas);

    UserService.getDiscountCode(datas, (response) => {
      console.log(response);
      if (response != null) {
        if (response.success) {
          console.log(response.success)
          setCardElementDisabled(false);
          setShowDiscountMessage(true)
          console.log(response.data.coupon.value)
          console.log(response.data.coupon.id)
          setIdCode(response.data.coupon.id)
          setAmountAfterDiscount(response.data.currentAmountAfterDiscountToBePaid)
          setAmountOfDiscount(response.data.amountOfTheDiscount           )
        }  else {
          setShowDiscountMessage(false)
          setCardElementDisabled(false);
        }
      } else {
        setShowDiscountMessage(false)
        setCardElementDisabled(false);
      }
    });
  };

  return (
    <>
      {/* {idCode? <AvalailbleCourseCheckout idCode={idCode}/> :null} */}

      <Toast ref={toast} position="top-right" />
      <div
        className={`xl:tw-w-1/2 xl:tw-bg-accent-gray tw-rounded-[12px] xl:tw-p-8  tw-w-full tw-mt-8 xl:tw-mt-0 ${stepperEnabled
          ? isNextStep
            ? "xl:tw-block"
            : "tw-hidden"
          : " xl:tw-block"
          } `}
      >
        {makePayment ? (
          <>
            <p className="tw-text-lg tw-font-semibold tw-text-primary tw-mb-2">
              Your order: {sessionName}{" "}
            </p>

            <div className="tw-flex tw-flex-col tw-divide tw-divide-y-2 tw-space-y-4 tw-mt-4 tw-text-gray-700">
              <div className="tw-flex tw-flex-row tw-justify-between tw-pt-2">
                <p>Subscription Plan Price </p>
                <p>
                  {data.subscriptionPrice?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </p>
              </div>
              <div className="tw-flex tw-flex-row tw-justify-between tw-pt-2">
                <p>Expected amount paid </p>
                <p>
                  {data.expectedPaidAmount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </p>
              </div>
              <div className="tw-flex tw-flex-row tw-justify-between tw-pt-2">
                <p>Actual amount paid</p>
                <p>
                  {data.actualPaidAmount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </p>
              </div>
              <div className="tw-flex tw-flex-row tw-justify-between tw-pt-2">
                <p>Minimum transaction amount</p>
                <p>
                  {data.minAmount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </p>
              </div>
              <div className="tw-flex tw-flex-row tw-justify-between tw-pt-2">
                <p>Maximum transaction amount </p>
                <p>
                  {data.maxAmount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </p>
              </div>
              <div className="tw-flex tw-flex-row tw-justify-between tw-pt-2">
                <p>Remaining amount to be in good standing </p>
                <p>
                  {" "}
                  {data.remainingAmountToBeInGoodStanding?.toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: "USD",
                    }
                  )}
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <p className="tw-text-lg tw-font-semibold tw-text-primary tw-mb-2">
              Your order
            </p>

            <div className="tw-flex tw-flex-col tw-divide tw-divide-y-2 tw-space-y-4 tw-mt-4 tw-text-gray-700">
              <div className="tw-flex tw-flex-row tw-justify-between tw-pt-2">
                <p className="tw-w-1/3 tw-font-bold">Product :</p>
                <p className="tw-w-2/3 tw-font-medium tw-justify-end tw-text-end">
                  {" "}
                  {/* {`${sessionName} - ${data?.name}`}{" "} */}
                  {`${sessionName} - ${data.lightPlan.userDisplayName ? data.lightPlan.userDisplayName: data?.name}`}{" "} 

                  
                </p>
              </div>

              <div className="tw-flex tw-flex-row tw-justify-between tw-pt-2">
                <p className="tw-font-bold">Subtotal :</p>
                <p className="tw-font-medium">
                  {" "}
                  {data?.numberInstallment > 1
                    ? `${data?.paymentAmount?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })} ${isSession
                      ? "every " +
                      data?.intervalBtwInstallment +
                      " " +
                      (data?.paymentIntervalType > 1 ? data.paymentIntervalType : singleName(data.paymentIntervalType))
                      : ""
                    }`
                    : data?.paymentAmount?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}{" "}
                </p>
              </div>
              {data?.numberInstallment === 1 ? null : isSession && (
                <>
                  <div className="tw-flex tw-flex-row tw-justify-between tw-pt-2">
                    <p className="tw-font-bold">Start date :</p>
                    <p className="tw-font-medium">
                      {" "}
                      {moment(firstPaymentDate)
                        .utc()
                        .format("MMMM Do, YYYY")}{" "}
                    </p>
                  </div>
                  <div className="tw-flex tw-flex-row tw-justify-between tw-pt-2">
                    <p className="tw-font-bold">Recurring Total:</p>
                    <p className="tw-font-medium tw-flex tw-flex-col tw-space-y-1 tw-items-end">
                      <span>
                        {`${data?.paymentAmount?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })} every ${data?.intervalBtwInstallment} 
                        ${data.intervalBtwInstallment > 1 ?
                            data.paymentIntervalType : singleName(data.paymentIntervalType)}
                       `}
                      </span>
                      <span className="tw-text-xs">
                        {`First renewal : ${moment(nextPaymentDate)
                          .utc()
                          .format("MMMM Do, YYYY")}`}
                      </span>
                    </p>
                  </div>
                </>
              )}

{visibleDiscount &&
              <div>

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center",marginTop:"10px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <label style={{ marginRight: "6px" }}>A discount code?</label>&nbsp;&nbsp;
                    <InputCustom
                      onChange={onCodeChange}
                      placeHolder={"Enter it here"}
                      name="code"
                      value={code}
                      type={"text"}
                      disabled={showDiscountMessage}
                    />
                  </div> &nbsp;&nbsp; &nbsp;&nbsp;
                  <div className="tw-flex tw-justify-end">
                    <ButtonCustom
                      onClick={ApplyCode}
                      classes="tw-bg-primary hover:tw-bg-primary-900 tw-text-white sm:tw-max-w-[500px] tw-max-h-[300px]"
                      label="Apply"
                      disabled={showDiscountMessage}
                    />
                  </div>
                </div>
              
                <div className="tw-mt-4">
                  {showDiscountMessage &&
                    <>

                      <div className="tw-flex tw-flex-row tw-justify-between tw-pt-2  ">

                        <p> Discount amount:</p>
                        <p>

                          <span className="tw-font-bold"> ${amountOfDiscount}</span>

                        </p>
                      </div>

                      <div className="tw-flex tw-flex-row tw-justify-between tw-pt-2  ">


                        <p> Due now:</p>
                        <p>
                          <h3 >

                            <span className="tw-font-bold"> ${amountAfterDiscount}</span>
                          </h3>

                        </p>
                      </div>

                    </>}

                </div>

              </div>
                  }


              <div className="tw-flex tw-flex-row tw-justify-between tw-pt-2 tw-font-bold tw-text-xl">


                <p>Total:</p>
                <p>

                  {" "}
                  {/* {data?.paymentAmount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })} */}
                  {
                    amountAfterDiscount && visibleDiscount
                      ? (amountAfterDiscount)?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                      : data?.paymentAmount?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })
                  }
                </p>
              </div>
            </div>
          </>
        )}

        {enableForm ? (
          <>
            <p className="tw-mt-8 tw-text-lg tw-font-semibold tw-text-primary tw-mb-2">
              Credit card / debit card
            </p>

            <div className={`tw-block tw-justify-center tw-w-full tw-h-[50px] tw-rounded-lg tw-border-0 tw-py-3 tw-text-primary tw-ring-2 tw-ring-gray-300 tw-bg-white focus:tw-ring-primary-600 tw-ring-inset tw-px-4 tw-text-xs sm:tw-text-sm sm:tw-leading-6 tw-outline-none ${isCardElementDisabled ? 'tw-opacity-50 tw-pointer-events-none' : ''}`}>
              <CardElement
                options={{
                  style: {
                    base: {
                      iconColor: "#1e4e70",
                      fontWeight: "400",
                      fontFamily: "Poppins, Open Sans, Segoe UI, sans-serif",
                      fontSize: "20px",
                      fontSmoothing: "antialiased",
                      ":-webkit-autofill": {
                        color: "#1e4e70",
                      },
                    },
                  },
                }}
              />
            </div>

            <div className="tw-mt-4 tw-flex tw-flex-col tw-space-y-3">
              {isLoading ? (
                <ButtonCustom
                  classes={
                    "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                  }
                  label="Loading..."
                  icon={"pi pi-spin pi-spinner"}
                  disabled={true}
                />
              ) : (
                <ButtonCustom
                  classes={
                    "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                  }
                  label="Pay"
                  onClick={onClickAction}
                  disabled={isCardElementDisabled}
                />
              )}


              {isNextStep && (
                <ButtonCustom
                  classes={
                    "tw-border-[1.5px] tw-border-[#309255] tw-text-[#309255] hover:tw-text-white hover:tw-bg-[#309255]"
                  }
                  label="Previous Step"
                  icon={"pi pi-arrow-left tw-pr-4"}
                  onClick={onClickActionToPreviousStep}
                />
              )}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default OrderCourseCheckout;

// Header.js
import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  getActiveTab,
  getCollapseKey,
  getRole,
  getUser,
  logout,
  setActiveTab,
  setCollapseKey
} from '../../../network/auth/Auth';
import {useNavigate} from 'react-router';
import {Link} from "react-router-dom";
import './AdminHeader.css';
import routeAdmin from "../../../Roles routes/routeAdmin";
import routeCourse from "../../../Roles routes/routeCourse";
import routeAccounting from "../../../Roles routes/routeAccounting";
import routeSupport from "../../../Roles routes/routeSupport";
import backendUrls, {MEDIA_URL} from "../../../network/urls/backendUrls";

class AdminHeader extends Component {

  constructor(props) {
    super(props);

    this.state = {
      user: null,
      role: '',
      active: '0',
      routeToShow: [],
      activeTab: ''
    }
  }

  loadData = () => {
    let user = getUser();
    this.setState({
      user: user,
      ...user,
    });
  };

  signOut = async (event) => {
    event.preventDefault();
    await logout();
    const navigate = this.props.navigation;
    navigate({
      pathname: '/'
    })
  }

  createLinks = (routes, section) => {

    const routesHere = routes.filter(e => e.section === section);
    return routesHere.map((prop, key) => {
      return (
          <div key={key}>
          <li className="nav-item" onClick={() => {
            setActiveTab(prop.path);
            setCollapseKey(prop.section - 1)
          }}>
            <Link to={prop.path}
                  className={this.state.activeTab !== prop.path ? "nav-link text-white" : "nav-link text-white active bg-gradient-info"}>
              <div
                  className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                {/*<i className="material-icons opacity-10 hide-icon">{prop.icon}</i>*/}
                <i className="material-icons opacity-10">{prop.icon}</i>
              </div>
              <span className="nav-link-text text-white">{prop.name}</span>
            </Link>
          </li>
          </div>
      );
    });
  };

  componentWillMount() {
    this.loadData();
    const collapseIndex = getCollapseKey();

    console.log('la section est ' + collapseIndex)

    if (collapseIndex) {
      this.setState({active: collapseIndex});
    }

    // setCollapseKey('1');
    //console.log('la valeur qui vient du local est '+collapseIndex);

    const currentTab = getActiveTab();
    //this.setState({active: collapseIndex, activeTab: currentTab});
    this.setState({activeTab: currentTab});
  }


  componentDidMount() {
    const userRole = getRole();
    this.setState({role: userRole});
    if (userRole === 'ROLE_ADMIN') {
      this.setState({routeToShow: routeAdmin})
    }
    if (userRole === 'ROLE_COURSE') {
      this.setState({routeToShow: routeCourse})
    }
    if (userRole === 'ROLE_ACCOUNTING') {
      this.setState({routeToShow: routeAccounting})
    }
    if (userRole === 'ROLE_SUPPORT') {
      this.setState({routeToShow: routeSupport})
    }
  }

  render() {
    const {user} = this.state;
    return (
        <nav className='navbar navbar-expand navbar-light white border-bottom topbar mb-4 static-top shadow bg-gradient-dark'>
          <div className='container-fluid'>
         
            <div>
              <h6>
                <span className="text-white fw-bold fs-5"> Dashboard / {this.props.location}</span>
              </h6>
            </div>

            <form
                className='d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search'>
              <div className='input-group'>
                {/*<input type="text" class="form-control bg-light border-0 small" placeholder="Search for..."*/}
                {/*       aria-label="Search" aria-describedby="basic-addon2"/>*/}
                {/*<div class="input-group-append">*/}
                {/*    <button class="btn btn-primary" type="button">*/}
                {/*        <i class="fas fa-search fa-sm"></i>*/}
                {/*    </button>*/}
                {/*</div>*/}
              </div>
            </form>

            <ul className='btn-group '>
              <div className='topbar-divider d-none d-sm-block'></div>

              <li className='nav-item '>
                <a
                    className='nav-link dropdown-toggle'
                    href='app/src/components/Admin/header/AdminHeader#'
                    role='button'
                    id='dropdownMenuLink'
                    data-bs-display='static'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                >
                  {this.state.user.profilePicture === null ? (
                      <img
                          className='img-profile rounded-circle h-95 w-375'
                          src='assets/images/author/author-17.png'
                          alt='Shape'
                      />
                  ) : (
                      <img
                          className='img-profile  rounded-circle h-95 w-375'
                          src={MEDIA_URL + this.state.user.profilePicture}
                          width='50'
                          height='50'
                          alt='Shape'
                      />
                  )}

                  <span className='mr-2 d-none d-lg-inline text-gray-600 small'>
                  {' '}
                    Hi, {user.firstName === '' ? user.name : user.firstName}{' '}
                </span>
                </a>

                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-dark center">
                  <div className = ' hide-header-links'>
                    {this.createLinks(this.state.routeToShow, 1)}
                    <hr/>
                    {this.createLinks(this.state.routeToShow, 2)}
                    <hr/>
                    {this.createLinks(this.state.routeToShow, 3)}
                  </div>
                  <br/>
                  <li className="nav-item " align="center">
                    <button className='btn text-white bg-primary'
                            onClick={this.signOut}>Logout
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>


    )
  }
}


//export default AdminHeader
// Wrap and export
export default function (props) {
  const navigation = useNavigate();
  //const {state} = useLocation();
  return <AdminHeader {...props} navigation={navigation}/>;
}

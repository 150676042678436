import React, {Component} from "react";
import Sidebar from "../sidebar/Sidebar";
import "../../../assets/subscription_plan.css";
import SessionService from "../../../network/services/SessionService";
import CourseService from "../../../network/services/CourseService";
import SubscriptionPlanService from "../../../network/services/SubscriptionPlanService";
import {useNavigate} from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment/moment";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AdminHeader from "../header/AdminHeader";
import "../Dashboard/AdminHome.css";
import DatePicker from "react-datepicker";
import {computeDateToTimeZone} from "../../../constants/Constants";

class Session extends Component {
    constructor(props) {
        super(props);
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        //console.log(timezone);
        this.state = {
            sessions: [],
            curSession: "",
            courses: [],
            coursesToShow: [],
            plansToShow: [],
            plans: [],
            name: null,
            description: "",
            start_date: "",
            end_date: "",
            course: null,
            customSessionPaymentDate: null,
            customSessionPaymentDate2: "",
            customDateUpdate: "",
            selectedCourses: [],
            selectedPlans: [],
            allPlans: [],
            selectedPlan: "",
            selectedPlanId: "",
            selectedSessionId: "",
            ckeditor: "",
            wordpressCourse: "",
            moddleGroupId: "",
            descriptionLink: null,
            wordpressGroupId: "",
            expiredAt: null,
            isLoadingUpdate: false,
            showConfigurationPanel: false,
            isLoading: false,
            firstLoader: false,
            updateMessage: false,
            updateMessageText: " Session successfully updated.",
            lastPage: "",
            planIdToDelete: [],
            sessionIdToRemove: "",
            deleteMessageContent: "",
            message: "",
            courseIdToDelete: "",
            timezone: timezone,
            sessionIds: [],
            planIds: [],
            image: null,
            imageUrl: "",
            currentOpenSession: {
                session: [],
            },
            sessionFilter: {
                data: [],
            },
            openStatus: "",
            publicationStatus: "",
            sessionSubscriptionStatusSetting: "",
            sessionPublicationStatusSetting: "",
            sessionProgressionStatusSetting: "",
            isCurrentSessionSetting: "",
            sessionSubscriptionStatusFilter: "",
            sessionPublicationStatusFilter: "",
            sessionProgressionStatusFilter: "",
            isCurrentSessionFilter: "",
            currentSession: {
                id: null,
                name: "",
                description: "",
                descriptionLink: "",
                moodleGroupId: "",
                ckeditor: "",
                wordpressCourse: "",
                wordpressGroupId: "",
                start_date: "",
                end_date: "",
                image: null,
                imageUrl: "",
                courses: [],
                subscription: [],
                subscriptionPlans: [],
                customSessionPaymentDate: "",
                updatedAt: "",
                createdAt: "",
                openStatus: "",
                publicationStatus: ""
            },
        };
    }

    openDetails = (e) => {
        const id = e.target.getAttribute("id");
        // console.log("the selected course is: " + id);

        const navigate = this.props.myNavigate;
        //alert(navigate);
        navigate({
            pathname: "/session-details",
            search: "?sessionId=" + id,
        });
    };

    handleFormChange = ($event) => {
        const {name, value} = $event.target;
        this.setState({[name]: value});
    };

    handleUploadImage = (event) => {
        const uplaodImage = event.target.files[0];
        const imageUrl = URL.createObjectURL(uplaodImage);
        this.setState({imageUrl: imageUrl});
        this.setState({image: uplaodImage});
    };

    getId = (e) => {
        this.setState({isLoadingUpdate: true});
        const id = e.target.getAttribute("id");
        SessionService.getById(id, (data) => {
            // console.log("les data")
            // console.log(data);
            if (data != null) {
                this.setState({
                    currentSession: data,
                    isLoadingUpdate: false,
                });
                if (data.customSessionPaymentDate) {
                    // const moment = require('moment-timezone');

                    let tp = moment.tz(
                        data.customSessionPaymentDate,
                        this.state.timeZone
                    );
                    let lastDate = tp.utc().format();
                    let date = new Date();
                    let offset = date.getTimezoneOffset();
                    // console.log(offset);
                    let tp2 = moment.tz(
                        data.customSessionPaymentDate,
                        this.state.timeZone
                    );
                    //console.log(tp2.valueOf());
                    //let lastDate2 = tp2.utc().format();
                    let MS_PER_MINUTE = 60000;
                    let datepickerFormatedDate = new Date(
                        tp2.valueOf() + offset * MS_PER_MINUTE
                    );
                    //let test = moment(data.customSessionPaymentDate).utcOffset(offset).format();
                    //console.log("le bon format")
                    //console.log(datepickerFormatedDate);

                    this.setState({customDateUpdate: datepickerFormatedDate});
                }
                // else { this.setState({ customDateUpdate: null }); }
            }
        });
    };

    onUpdateCourse = (e) => {
        const courseId = e.target.value;
        // console.log("la valeur du course appeler est : ");
        // console.log(courseId);
        CourseService.getById(courseId, (data) => {
            // this.setState({
            //     updateMessage: true,
            // });
            this.setState(function (prevState) {
                return {
                    currentSession: {
                        ...prevState.currentSession,
                        course: data,
                    },
                };
            });
        });
    };

    onNameChange = (e) => {
        const name = e.target.value;

        this.setState(function (prevState) {
            return {
                currentSession: {
                    ...prevState.currentSession,
                    name: name,
                },
            };
        });
    };

    onCustomPaymentDateChange = (date) => {
        this.setState(function (prevState) {
            return {
                currentSession: {
                    ...prevState.currentSession,
                    customSessionPaymentDate: date,
                },
            };
        });
    };

    onDescriptionChange = (e) => {
        const description = e.target.value;

        this.setState(function (prevState) {
            return {
                currentSession: {
                    ...prevState.currentSession,
                    description: description,
                },
            };
        });
    };

    onWordpressCourseChange = (e) => {
        const text = e.target.value;

        this.setState(function (prevState) {
            return {
                currentSession: {
                    ...prevState.currentSession,
                    wordpressCourse: text,
                },
            };
        });
    };

    onDescriptionLinkChange = (e) => {
        const text = e.target.value;

        this.setState(function (prevState) {
            return {
                currentSession: {
                    ...prevState.currentSession,
                    descriptionLink: text,
                },
            };
        });
    };

    onWordpressCourseIdChange = (e) => {
        const text = e.target.value;

        this.setState(function (prevState) {
            return {
                currentSession: {
                    ...prevState.currentSession,
                    wordpressGroupId: text,
                },
            };
        });
    };

    onMoodleCourseIdChange = (e) => {
        const text = e.target.value;

        this.setState(function (prevState) {
            return {
                currentSession: {
                    ...prevState.currentSession,
                    moodleGroupId: text,
                },
            };
        });
    };

    onAdviceChange = (e) => {
        const words = e.target.value;

        this.setState(function (prevState) {
            return {
                currentSession: {
                    ...prevState.currentSession,
                    ckeditor: words,
                },
            };
        });
    };

    onDescriptionNewChange = (e) => {
        const words = e.target.value;

        this.setState(function (prevState) {
            return {
                currentSession: {
                    ...prevState.currentSession,
                    description: words,
                },
            };
        });
    };

    onStartDateChange = (e) => {
        const startDate = e.target.value;

        this.setState(function (prevState) {
            return {
                currentSession: {
                    ...prevState.currentSession,
                    startDate: startDate,
                },
            };
        });
    };

    onEndDateChange = (e) => {
        const endDate = e.target.value;

        this.setState(function (prevState) {
            return {
                currentSession: {
                    ...prevState.currentSession,
                    endDate: endDate,
                },
            };
        });
    };

    onSessionPublicationStatus = (e) => {
        const text = e.target.value;

        this.setState(function (prevState) {
            return {
                currentSession: {
                    ...prevState.currentSession,
                    publicationStatus: text
                },
            };
        });
    };

    onSessionSubscriptionStatus = (e) => {
        const text = e.target.value;

        this.setState(function (prevState) {
            return {
                currentSession: {
                    ...prevState.currentSession,
                    openStatus: text
                },
            };
        });
    };

    saveSession = () => {
        const formData = new FormData();
        let data;
        let d = new Date();
        let date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
        let hours = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
        let fullDate = date + "T" + hours + "Z";

        if (
            this.state.start_date !== null &&
            this.state.end_date !== null &&
            this.state.name !== null &&
            this.state.descriptionLink !== null
        ) {
            formData.append("file", this.state.image/* , this.state.imageUrl */);
            formData.append("name", this.state.name);
            formData.append("description", this.state.description);
            formData.append("ckeditor", this.state.ckeditor);
            formData.append("wordpressCourse", this.state.wordpressCourse);
            formData.append("wordpressGroup_id", this.state.wordpressGroupId);
            formData.append("moodleGroupId", this.state.moddleGroupId);
            formData.append("descriptionLink", this.state.descriptionLink);
            formData.append("startDate", this.state.start_date);
            formData.append("endDate", this.state.end_date);
            // formData.append("updatedAt", fullDate);
            // formData.append("createdAt", fullDate);
            formData.append("customSessionPaymentDate", this.state.customSessionPaymentDate2);
            formData.append("openStatus", this.state.openStatus);
            formData.append("publicationStatus", this.state.publicationStatus);

            /*  data = {
               name: this.state.name,
               description: this.state.description,
               ckeditor: this.state.ckeditor,
               wordpressCourse: this.state.wordpressCourse,
               wordpressGroupId: this.state.wordpressGroupId,
               moodleGroupId: this.state.moddleGroupId,
               descriptionLink: this.state.descriptionLink,
               startDate: this.state.start_date,
               endDate: this.state.end_date,
               updatedAt: fullDate,
               createdAt: fullDate,
               customSessionPaymentDate: this.state.customSessionPymentDate2,
               openStatus: this.state.openStatus,
               publicationStatus: this.state.publicationStatus,
               file: this.state.file
             }; */
        } else {
            this.setState({
                deleteMessage: !this.state.deleteMessage,
                deleteMessageContent: "Please fill all required fields",
            });
            return;
        }

        SessionService.create(formData, (response) => {
            if (response) {
                if (response.success) {
                    this.setState({
                        successMessage: true,
                        message: "Session created successfully",
                        name: null,
                        start_date: null,
                        end_date: null,
                        description: "",
                        ckeditor: "",
                        wordpressCourse: "",
                        wordpressGroupId: "",
                        moodleGroupId: "",
                        descriptionLink: "",
                        customSessionPaymentDate: "",
                        openStatus: "",
                        publicationStatus: "",
                        image: "",
                        imageUrl: ""
                    });
                    const form = document.getElementById("create-session-form");
                    form.reset();
                    this.showSession();
                } else{
                    this.setState({
                        deleteMessage: true,
                        deleteMessageContent: response.message
                    });
                }
            }else {
                this.setState({
                    deleteMessage: true,
                    deleteMessageContent:  "An error has occurred, please try again later"
                });
            }
        })
    }

    updateSession = () => {
        const formData = new FormData();
        let d = new Date();
        let date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
        let hours = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
        let fullDate = date + "T" + hours + "Z";

        formData.append("file", this.state.image/* , this.state.imageUrl */);
        formData.append("name", this.state.currentSession.name,);
        formData.append("description", this.state.currentSession.description);
        formData.append("ckeditor", this.state.currentSession.ckeditor);
        formData.append("wordpressCourse", this.state.currentSession.wordpressCourse);
        formData.append("wordpressGroup_id", this.state.currentSession.wordpressGroupId);
        formData.append("moodleGroupId", this.state.currentSession.moodleGroupId);
        formData.append("descriptionLink", this.state.currentSession.descriptionLink);
        formData.append("startDate", this.state.currentSession.startDate);
        formData.append("endDate", this.state.currentSession.endDate);
        // formData.append("updatedAt", fullDate);
        // formData.append("createdAt", fullDate);
        formData.append("customSessionPaymentDate", this.state.customDateUpdate2);
        formData.append("openStatus", this.state.currentSession.openStatus);
        formData.append("publicationStatus", this.state.currentSession.publicationStatus);

        SessionService.update(this.state.currentSession.id, formData, (response) => {
            this.showSession();
            //this.getCurrentSession();
            this.getCurrentSession();
            if (response) {
                this.setState({
                    updateMessage: true,
                });
            }
        });
        const form = document.getElementById("update-session-form");
        form.reset();
    };

    deleteSession = (e) => {
        //const id = e.target.getAttribute("id");
        const id = this.state.sessionIdToRemove;

        SessionService.delete(id, (response) => {
            // console.log("reponse du serveur")
            // console.log(response)
            if (response) {
                if (response.success == true) {
                    this.setState({
                        successMessage: true,
                        sessionIdToRemove: "",
                        message: "The session has been deleted successfully.",
                    });
                    this.showSession();
                } else {
                    this.setState({
                        deleteMessageContent:
                            "Impossible to delete the session! The session already has subscriptions.",
                        deleteMessage: true,
                        sessionIdToRemove: "",
                    });
                }
            }
        });
    };

    // retrieveCourse = () => {
    //     //this.setState({ isLoading:true});
    //     CourseService.getAll((data) => {
    //         //console.log(data);
    //         this.setState({courses: data});
    //     });
    // };

    retrievePlan = () => {
        SubscriptionPlanService.getAll((data) => {
            // console.log(data);
            this.setState({plans: data});
        });
    };

    savePlanIdToRemove = (e) => {

        const idSession = e.target.getAttribute("id");
        const idPlan = e.target.getAttribute("value");

        if (!this.state.planIdToDelete.includes(idPlan)) {
            this.setState({planIdToDelete: [...this.state.planIdToDelete, idPlan]});
        } else {
            let tabId = this.state.planIdToDelete.filter((e) => e !== idPlan);
            this.setState({planIdToDelete: tabId})
        }
        this.setState({sessionIdToRemove: idSession});
    };

    saveCourseIdToRemove = (e) => {
        const id = e.target.getAttribute("id");
        // console.log('voici le id ' + id);

        const idv = e.target.getAttribute("value");
        //console.log("the adaee course is: " + idv);

        this.setState({sessionIdToRemove: id, courseIdToDelete: idv});
    };

    saveSessionIdToRemove = (e) => {
        const id = e.target.getAttribute("id");
        // console.log('voici le id ' + id);

        this.setState({sessionIdToRemove: id});
    };

    

    removePlan = (e) => {

        const IdPlan = this.state.planIdToDelete;
        const IdSession = this.state.sessionIdToRemove;

        //console.log('lid dans la session de sortie cest ' + IdSession);
        //console.log('lid du plan la session de sortie cest ' + IdPlan);

        let data = {
            session_id: IdSession,
            subscription_plan_id_array: IdPlan
        };

        SessionService.delPlan(data, () => {

            console.log(data);
            //this.selectSession(e);
            this.setState({sessionIdToRemove: "", planIdToDelete: ""});
            this.setState({
                message: "The subscription plan has been removed successfully",
                successMessage: true,
            });
            this.showSession();
        });

        /* let planId = event.target.value.toString();
        if (!this.state.planIds.includes(planId)) {
            this.setState({planIds: [...this.state.planIds, planId]});
        } else {
            let tabId = this.state.planIds.filter((e) => e !== planId);
            this.setState({planIds: tabId});
        } */
        // const id = e.target.getAttribute("id");


        /*     let found = this.state.sessions.find(
                (element) => element.id === parseInt(id)
            );

            let toRemove = parseInt(this.state.planIdToDelete);
            // let toRemove = parseInt(e.target.getAttribute("value"));

            // console.log('lid dans la plan de sortie cest ' + toRemove)

            let tabPlan = found.subscriptionPlans;

            let tabPlanNew = [];

            let tabPlanEnd = [];

            tabPlan.forEach((o) => {
                tabPlanNew.push(o.id);
            });

            //console.log(tabPlanNew);
            tabPlanNew = tabPlanNew.filter((e) => e !== toRemove);
            // console.log(tabPlanNew);

            tabPlanNew.forEach((id) => {
                let elt = "/api/subscription_plans/" + id;
                tabPlanEnd.push(elt);
            });

            console.log(tabPlanEnd); */

        //console.log("La session choisie est " + sessionId);
        //console.log("Les plan choisie " + planId);


    };

    removeCourse = (e) => {
        //const id = e.target.getAttribute("id");
        const id = this.state.sessionIdToRemove;

        let found = this.state.sessions.find(
            (element) => element.id === parseInt(id)
        );

        //let toRemove = parseInt(e.target.getAttribute("value"));courseIdToDelete
        let toRemove = parseInt(this.state.courseIdToDelete);

        let tabCourse = found.courses;

        let tabCourseNew = [];

        let tabCourseEnd = [];

        tabCourse.forEach((o) => {
            tabCourseNew.push(o.id);
        });

        // console.log(tabCourseNew);
        tabCourseNew = tabCourseNew.filter((e) => e !== toRemove);
        //  console.log(tabCourseNew);

        tabCourseNew.forEach((id) => {
            let elt = "/api/courses/" + id;
            tabCourseEnd.push(elt);
        });

        //console.log(tabCourseEnd);

        let tabData = {
            courses: tabCourseEnd,
        };

        SessionService.update(id, tabData, () => {
            //this.selectSession(e);
            this.setState({
                message: "The course has been removed successfully",
                successMessage: true,
            });
            this.showSession();
        });
    };

    selectSession = (e) => {
        const id = e.target.getAttribute("id");
        // console.log("the selected session is: " + id);
        SessionService.getById(id, (data) => {
            // console.log(data);
            this.setState({
                currentSession: data,
                //coursesToShow: data.courses
                coursesToShow: this.transformByDifference(
                    this.state.courses,
                    data.courses
                ),
                plansToShow: this.transformByDifference(
                    this.state.plans,
                    data.subscriptionPlans
                ),
            });
        });

        this.setState({selectedSessionId: id});

        // console.log("nous sortons pour le sendaata");
        // console.log(this.state.currentSession);
    };

    sendPlanData = (e) => {
        const planId = this.state.planIds;
        const sessionId = this.state.selectedSessionId;

        if ((this.state.planIds).length < 1) {
            this.setState({
                message: "You have selected no subscription plan",
                deleteMessage: true,
            });
        }
        //console.log("La session choisie est " + sessionId);
        //console.log("Les plan choisie " + planId);

        let tabData = {

            session_id: sessionId,
            subscription_plan_id_array: planId
        };

        SessionService.atriplan(tabData, () => {
            //console.log(tabData);
            this.setState({
                planIds: [],
                selectedSessionId: "",
            });

            SessionService.getById(sessionId, (data) => {
                //console.log(data);
                // console.log(data);
                this.setState({
                    message: "The subscription plan has been associated successfully",
                    successMessage: true,
                });
                this.setState({
                    currentSession: data,
                    // isLoading:false
                });

            });

            let inputs2 = document.querySelectorAll("#search-field");
            for (let i = 0; i < inputs2.length; i++) {
                inputs2[i].checked = false;
            }

            this.showSession();
        });
    };

    transformByDifference = (tab1, tab2) => {
        // console.log("taille du tableau 1 ");
        // console.log(tab1);
        // console.log("taille du tableau 2 ");
        // console.log(tab2);
        let courseIds = tab2.map((c) => c.id);

        //console.log(courseIds);

        let availableCourses = tab1.filter((ac) => !courseIds.includes(ac.id));

        //console.log("taille du tableau de sortie " + availableCourses.length);
        //console.log(availableCourses);
        return availableCourses;
    };

    sendCourseData = (e) => {
        const sessionId = this.state.selectedSessionId;

        const tabRes = this.state.currentSession.courses;
        tabRes.forEach((o) => {
            let elt = "/api/courses/" + o.id;
            this.state.selectedCourses.push(elt);
        });
        const toAdd = e.target.value;
        this.state.selectedCourses.push(toAdd);
        let TabData = {
            courses: this.state.selectedCourses,
        };

        let tabCourseObject = tabRes;
        tabCourseObject.push(toAdd);
        this.setState({
            selectedCourses: [],
            coursesToShow: [],
        });
        SessionService.update(sessionId, TabData, () => {
            SessionService.getById(sessionId, (data) => {
                // console.log(data);
                this.setState({
                    currentSession: data,
                });
                this.setState({
                    message: "The course has been associated successfully",
                    successMessage: true,
                });
            });
            this.showSession();
        });
    };

    /* showSession = () => {
      this.setState({
          isLoading: true,
      });
      const page_url = 1;
      const nombre_items = 3;
      SessionService.getAllSession(page_url, nombre_items, (data) => {
          // console.log("les sessions chargées")
          // console.log(data)
          // console.log(data["hydra:member"]);
          if (data) {
              if (data["hydra:totalItems"] > 3) {
                  // console.log("superieur a " + data["hydra:totalItems"]);
                  // console.log("data");
                  // console.log(data["hydra:member"]);
                  // console.log(data["hydra:view"]["hydra:last"]);
                  // console.log(data["hydra:view"]["hydra:last"].slice(34));
                  const datas = data["hydra:member"];
                  const lPage = data["hydra:view"]["hydra:last"].slice(34);
                  // console.log("Last page is " + lPage);
                  // console.log(datas);
                  this.setState({
                      sessions: datas,
                      lastPage: lPage,
                      isLoading: false,
                  });
              } else {
                  const datas = data["hydra:member"];
                  // console.log("egale a " + data["hydra:totalItems"]);
                  this.setState({
                      sessions: datas,
                      lastPage: 1,
                      isLoading: false,
                  });
              }
          }
          // console.log(data);
      });
  };
 */

    /*  showSession = () => {
       const page_url = 1;
       const nombre_items = 10;
       this.setState({
         isLoading: true,
       });
       let data = {
         session_subscription_status: this.state.sessionSubscriptionStatusFilter,
         session_publication_status: this.state.sessionPublicationStatusFilter,
         session_progression_status: this.state.sessionProgressionStatusFilter,
         is_current_session: this.state.isCurrentSessionFilter
       }
       SessionService.getAllSession(page_url, nombre_items, data, (response)=> {

         if (response) {
           if (response["total_items_count"] > 10) {
             console.log("les donnees de la session Filtree");
             console.log(data);
             console.log(response);
             const datas = response["data"];
             const lPage = response["total_pages"];
             this.setState({
               sessions: datas,
               sessionSubscriptionStatusFilter: "",
               sessionPublicationStatusFilter: "",
               sessionProgressionStatusFilter: "",
               isCurrentSessionFilter: "",
               lastPage: lPage,
               isLoading: false
           });
           }else {
                   const datas = response["data"];
                   // console.log("egale a " + data["hydra:totalItems"]);
                   this.setState({
                     sessions: datas,
                     sessionSubscriptionStatusFilter: "",
                     sessionPublicationStatusFilter: "",
                     sessionProgressionStatusFilter: "",
                     isCurrentSessionFilter: "",
                     lastPage: 1,
                     isLoading: false
                 });
               }


         }
       }); */

    showSession = () => {
        this.setState({
            isLoading: true,
            firstLoader: true
        });
        const page_url = 1;
        const nombre_items = 5;

        /*SessionService.getAllByPage(page_url, nombre_items, (response) => {
            // console.log("liste session");
            // console.log(response);
            if (response) {
                if (response["total_items"] > response["items_peer_page"]) {
                    const datas = response["data"];
                    const lPage = response["total_pages"];
                    this.setState({
                        sessions: datas,
                        lastPage: lPage,
                        isLoading: false,
                    });
                } else {
                    const datas = response["data"];
                    this.setState({
                        sessions: datas,
                        lastPage: 1,
                        isLoading: false,
                    });
                }
            }
        });*/

        let data = {
            session_subscription_status: this.state.sessionSubscriptionStatusFilter,
            session_publication_status: this.state.sessionPublicationStatusFilter,
            session_progression_status: this.state.sessionProgressionStatusFilter,
            // is_current_session: this.state.isCurrentSessionFilter
        }
        console.log(data)
        SessionService.getSessionFilter(data, (response) => {

            if (response) {
                if (response.status === "success") {
                    console.log(response)
                    // console.log("les donnees de la session Filtree");
                    // console.log(data);
                    this.setState({
                        sessions: response.data,
                        lastPage: response.total_pages,
                        firstLoader: false,
                        isLoading: false
                    });
                }else{

                    this.setState({
                        sessions: "",
                        lastPage: "",
                        firstLoader: false,
                        isLoading: false
                    });
                }
            }
        });
    };


    handleChangeCustomPaymentDate = (date) => {
        // console.log(date)

        const today = new Date();

        //if(date){
        // if (today.getTime() < date.getTime()) {
        const lastDate = computeDateToTimeZone(date, this.state.timezone);
        this.setState({
            customSessionPaymentDate: date,
            customSessionPaymentDate2: lastDate
        });
        //  }
        // }
    };

    handleChangeCustomUpdatePaymentDate = (date) => {

        let lastDate = '';

        if (date != null) {
            lastDate = computeDateToTimeZone(date, this.state.timezone);
        }

        this.setState({
            customDateUpdate: date,
            customDateUpdate2: lastDate,
        });
    };

    componentDidMount = () => {
        this.showSession();
        // this.getCurrentSession();
        // this.getCurrentSession();
        // this.retrieveCourse();
        this.retrievePlan();
        this.setState({isLoading: false});
    }

    openListSubscription = (e) => {
        const id = e.target.getAttribute("id");
        console.log("the selected session is: " + id);

        const navigate = this.props.myNavigate;
        //alert(navigate);
        navigate({
            pathname: "/users_transaction",
            search: "?sessionId=" + id,
        });
    };

    handleChangePlan = (event) => {
        let planId = event.target.value.toString();
        if (!this.state.planIds.includes(planId)) {
            this.setState({planIds: [...this.state.planIds, planId]});
        } else {
            let tabId = this.state.planIds.filter((e) => e !== planId);
            this.setState({planIds: tabId});
        }
    };

    handleChangeCategory = (event) => {
        let sessionId = event.target.value.toString();
        if (!this.state.sessionIds.includes(sessionId)) {
            this.setState({sessionIds: [...this.state.sessionIds, sessionId]});
        } else {
            let tabSession = this.state.sessionIds.filter((e) => e !== sessionId);
            this.setState({sessionIds: tabSession});
        }
    };


    cancelSetting = (e) => {
        this.setState({
            sessionIds: [],
            sessionSubscriptionStatusSetting: "",
            sessionProgressionStatusSetting: "",
            sessionPublicationStatusSetting: "",
            isCurrentSessionSetting: ""
        });
    };

    cancelFilter = (e) => {
        this.setState({
            sessionSubscriptionStatusFilter: "",
            sessionPublicationStatusFilter: "",
            sessionProgressionStatusFilter: "",
            isCurrentSessionFilter: ""
        });
    };

    getSessionFilter = () => {
        this.setState({
            isLoading: true,
        });
        let data = {
            session_subscription_status: this.state.sessionSubscriptionStatusFilter,
            session_publication_status: this.state.sessionPublicationStatusFilter,
            session_progression_status: this.state.sessionProgressionStatusFilter,
            is_current_session: this.state.isCurrentSessionFilter
        }
        SessionService.getSessionFilter(data, (response) => {

            if (response) {
                // console.log("les donnees de la session Filtree");
                // console.log(data);
                const datas = response["data"];
                this.setState({
                    sessions: datas,
                    isLoading: false
                });

            }
        });
    };

    // getCurrentSession = () => {
    //   SessionService.getNextSession((data) => {
    //     console.log("les donnees de la session");
    //     console.log(data);
    //
    //   });
    // };

    getCurrentSession = () => {
        SessionService.getCurrentSession((data) => {
            // console.log("les donnees de la session courante");
            // console.log(data);
            if (data) {
                this.setState({
                    currentOpenSession: data,
                });
            }
        });
    };

    defineCurrentSession = () => {
        if (this.state.sessionIds.length > 1) {
            if (this.state.isCurrentSessionSetting) {
                this.setState({
                    deleteMessageContent:
                        "Impossible to set many session as current.",
                    deleteMessage: true,
                });
                return;
            }
        }

        if (this.state.sessionIds.length < 1) {
            this.setState({
                deleteMessageContent: "Please select a session.",
                deleteMessage: true,
            });
            return;
        }

        //  if(this.state.sessionSubscriptionStatusSetting == "" || this.state.sessionPublicationStatusSetting == ""){
        //   this.setState({
        //     deleteMessageContent: "Make sure you have selected the subscription status and the publication status.",
        //     deleteMessage: true,
        //   });
        //   return;
        // };

        const data = {
            sessions_id: this.state.sessionIds,
            is_current_session: this.state.isCurrentSessionSetting,
            session_subscription_status: this.state.sessionSubscriptionStatusSetting,
            session_publication_status: this.state.sessionPublicationStatusSetting
            //session_progression_status: this.state.sessionProgressionStatus,
        };
        SessionService.updateCurrentSession(data, (response) => {
            if (response) {
                if (response.success) {
                    this.setState({
                        sessionIds: []
                    });
                    this.showSession();
                    //this.getCurrentSession();
                    this.getCurrentSession();
                    this.setState({
                        message: "Session successfully updated.",
                        successMessage: true,
                    });

                } else {
                    this.setState({
                        deleteMessageContent: response.message,
                        deleteMessage: true,
                    });
                }

                let inputs2 = document.querySelectorAll("#search-field");
                for (let i = 0; i < inputs2.length; i++) {
                    inputs2[i].checked = false;
                }
            }
        })
    }

    render() {
        const {currentSession, selectedCourse, selectedPlan} = this.state;

        const handlePageClick = (response) => {
            this.setState({
                isLoading: true,
            });
            // console.log(data.selected);
            const page_url = response.selected + 1;
            const nombre_items = 5;
            SessionService.getAllByPage(page_url, nombre_items, (response) => {
                this.setState({sessions: response["data"], isLoading: false});
            });
        };

        const subscriptionStatusOpen = {
            open: "OPENED",
            close: "CLOSED",
        };

        const publicationClose = {
            published: "PUBLISHED",
            unpublished: "UNPUBLISHED",
        };

        return (
            <>
                <Sidebar/>
                <main className="mainAdmin r ms-100">
                    <AdminHeader location="Sessions"/>
                    {
                        this.state.firstLoader ? (
                                <center>
                                    <div className="spinner-border"
                                         role="status" style={{
                                        width: '100px',
                                        height: '100px',
                                        marginTop: "20%"
                                    }}>
                                                                                        <span
                                                                                            className="sr-only">Loading...</span>
                                    </div>
                                </center>
                            ) :
                            <>
                                <div className="row mb-7 mt-0">
                                    <div className="col-12">
                                        <nav
                                            className="navbar navbar-expand-lg blur border-radius-lg top-0
                                    z-index-3 shadow position-absolute mt-4 py-2 start-0 end-0 mx-4"
                                        >
                                            <div className="container-fluid ps-2 pe-0">
                                                <a
                                                    className="navbar-brand font-weight-bolder ms-lg-0 ms-3 "
                                                    href="app/src/components/Admin/session/Session#"
                                                >
                                                    Session configuration panel
                                                </a>
                                                <button
                                                    className="navbar-toggler shadow-none ms-2"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#navigation2"
                                                    aria-controls="navigation"
                                                    aria-expanded="false"
                                                    aria-label="Toggle navigation"
                                                    onClick={() =>
                                                        this.setState({
                                                            showConfigurationPanel:
                                                                !this.state.showConfigurationPanel,
                                                        })
                                                    }
                                                >
                                                    {!this.state.showConfigurationPanel ? "open" : "close"}
                                                </button>
                                                <div className="collapse navbar-collapse" id="navigation2">
                                                    <ul className="navbar-nav mx-auto">
                                                        <li className="nav-item">
                                                            <a
                                                                href="javascript:;"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#exampleModal"
                                                            >
                                                                <button type="button" className="btn btn-secondary">
                                                                    New session
                                                                </button>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <ul className="navbar-nav d-lg-block d-none">
                                                        <li className="nav-item">
                        <span
                            className="material-icons"
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                this.setState({
                                    showConfigurationPanel:
                                        !this.state.showConfigurationPanel,
                                })
                            }
                        >
                          {!this.state.showConfigurationPanel
                              ? "expand_more"
                              : "expand_less"}
                        </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                                {this.state.showConfigurationPanel ? (
                                    <div className="row">
                                        <div className="col-lg-12 col-md-10 mx-auto">
                                            <div className="card ">
                                                <div className="card-header p-3">
                                                    <div>
                                                        <h5>Settings</h5>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <select
                                                                className="form-control"
                                                                name="openStatus"
                                                                value={this.state.sessionSubscriptionStatusSetting}
                                                                onChange={(e) =>
                                                                    this.setState({
                                                                        sessionSubscriptionStatusSetting: e.target.value,
                                                                    })
                                                                }
                                                            >
                                                                <option value="">
                                                                    --Subscription status--
                                                                </option>
                                                                <option value="1">
                                                                    {subscriptionStatusOpen.open}
                                                                </option>
                                                                <option value="0">
                                                                    {subscriptionStatusOpen.close}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <select
                                                                className="form-control"
                                                                name="publicationStatus"
                                                                value={this.state.sessionPublicationStatusSetting}
                                                                onChange={(e) =>
                                                                    this.setState({
                                                                        sessionPublicationStatusSetting: e.target.value,
                                                                    })
                                                                }
                                                            >
                                                                <option value="">
                                                                    --Publication Status--
                                                                </option>
                                                                <option value="1">
                                                                    {publicationClose.published}
                                                                </option>
                                                                <option value="0">
                                                                    {publicationClose.unpublished}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={this.defineCurrentSession}

                                                            >
                                                                Apply settings
                                                            </button>
                                                            &nbsp;&nbsp;
                                                            <button
                                                                type="button"
                                                                className="btn btn-outline-secondary"
                                                                onClick={this.cancelSetting}

                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <hr/>
                                                    <div>
                                                        <h5>Filter</h5>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <select
                                                                className="form-control"
                                                                name="openStatus"
                                                                value={this.state.sessionSubscriptionStatusFilter}
                                                                onChange={(e) =>
                                                                    this.setState({
                                                                        sessionSubscriptionStatusFilter: e.target.value,
                                                                    })
                                                                }
                                                            >
                                                                <option value="">--Subscription status--</option>
                                                                <option value="OPENED">
                                                                    {subscriptionStatusOpen.open}
                                                                </option>
                                                                <option value="CLOSED">
                                                                    {subscriptionStatusOpen.close}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <select
                                                                className="form-control"
                                                                name="publicationStatus"
                                                                value={this.state.sessionPublicationStatusFilter}
                                                                onChange={(e) =>
                                                                    this.setState({
                                                                        sessionPublicationStatusFilter: e.target.value,
                                                                    })
                                                                }
                                                            >
                                                                <option value="">--Publication Status--</option>
                                                                <option value="PUBLISHED">
                                                                    {publicationClose.published}
                                                                </option>
                                                                <option value="UNPUBLISHED">
                                                                    {publicationClose.unpublished}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={this.getSessionFilter}
                                                                // onClick={filter}
                                                            >
                                                                Apply filter
                                                            </button>
                                                            &nbsp;&nbsp;
                                                            <button
                                                                type="button"
                                                                className="btn btn-outline-secondary"
                                                                onClick={this.cancelFilter}

                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                <>
                                    <div className="container-fluid py-4">
                                        <div className="row mb-4">
                                            <div className="col-lg-12 col-md-6 mb-md-0 mb-4">
                                                <div className="card">
                                                    <div className="card-header pb-0">
                                                        <div className="row">
                                                            <div className="col-lg-6 col-7">
                                                                {/*<h6>All sessions</h6>*/}
                                                            </div>
                                                            <div className="col-lg-6 col-5 my-auto text-end">
                                                                {/*<div className="dropdown float-lg-end pe-4">*/}
                                                                {/*    <a*/}
                                                                {/*        href="javascript:;"*/}
                                                                {/*        data-bs-toggle="modal"*/}
                                                                {/*        data-bs-target="#exampleModal"*/}
                                                                {/*    >*/}
                                                                {/*        <button type="button"*/}
                                                                {/*                className="btn btn-secondary">New session*/}
                                                                {/*        </button>*/}
                                                                {/*    </a>*/}

                                                                {/*</div>*/}
                                                            </div>
                                                        </div>
                                                        <div className="table-responsive p-0">
                                                            <table className="table align-items-center mb-0">
                                                                <thead>
                                                                <tr>
                                                                    {/*<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">ID</th>*/}
                                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                                        ...
                                                                    </th>
                                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                                        NAME
                                                                    </th>

                                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                        WP GROUP ID
                                                                    </th>
                                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                        MD GROUP ID
                                                                    </th>
                                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                                        COURSE link
                                                                    </th>
                                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                                                                        START DATE
                                                                    </th>
                                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                                        END DATE
                                                                    </th>
                                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                                        CREATED AT
                                                                    </th>
                                                                    {/*<th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">UPDATED*/}
                                                                    {/*    AT*/}
                                                                    {/*</th>*/}
                                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                                        CUSTOM payment date
                                                                    </th>
                                                                    {/*<th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Courses*/}
                                                                    {/*</th>*/}
                                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                                        SESSION STATUS
                                                                    </th>

                                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                                                                        Subscription plan{" "}
                                                                    </th>

                                                                    <th className="text-secondary opacity-7 text-center">
                                                                        ...
                                                                    </th>
                                                                </tr>
                                                                </thead>

                                                                <tbody>
                                                                {this.state.isLoading ? (
                                                                    <div
                                                                        className="spinner-border text-primary"
                                                                        role="status"
                                                                    >
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                ) : (
                                                                    <></>
                                                                )}

                                                                {this.state.sessions &&
                                                                    this.state.sessions.map((sessions) => (
                                                                        <tr key={sessions.id}>
                                                                            {/*<td>*/}
                                                                            {/*    <div className="d-flex px-2 py-1">*/}
                                                                            {/*        */}
                                                                            {/*        {session.id}*/}
                                                                            {/*    </div>*/}
                                                                            {/*</td>   */}
                                                                            <td>
                                                                                <div className="d-flex px-2 py-1">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="search-field"
                                                                                        value={sessions.id}
                                                                                        onClick={this.handleChangeCategory}
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="d-flex px-2 py-1">
                                                                                    {/*<div>*/}
                                                                                    {/*    <img src="../assets/img/team-2.jpg"*/}
                                                                                    {/*         className="avatar avatar-sm me-3 border-radius-lg"*/}
                                                                                    {/*         alt="user1"/>*/}
                                                                                    {/*</div>*/}
                                                                                    <div
                                                                                        className="d-flex flex-column justify-content-center">
                                                                                        <h6 className="mb-0 text-sm"
                                                                                            id={sessions.id}
                                                                                            onClick={this.openListSubscription}
                                                                                            style={{cursor: 'pointer'}}
                                                                                        >
                                                                                            {sessions.name}
                                                                                        </h6>
                                                                                        {/*<p className="text-xs text-secondary mb-0">john@creative-tim.com</p>*/}
                                                                                    </div>
                                                                                </div>
                                                                            </td>

                                                                            <td>
                                                                                <p className="text-xs font-weight-bold mb-0">
                                                                                    {sessions.wordpressGroupId != "" ?
                                                                                        <span>{sessions.wordpressGroupId} </span> : <>-</>}
                                                                                </p>
                                                                            </td>
                                                                            <td>
                                                                                <p className="text-xs font-weight-bold mb-0">
                                                                                    {sessions.moodleGroupId != "" ?
                                                                                        <span>{sessions.moodleGroupId} </span> : <>-</>}
                                                                                </p>
                                                                            </td>
                                                                            <td>
                                                                                <p className="text-xs font-weight-bold mb-0">
                                                                                    {sessions.wordpressCourse != "" ?
                                                                                        <span>{sessions.wordpressCourse} </span> : <>-</>}
                                                                                </p>
                                                                            </td>

                                                                            <td>
                                                                                <p className="text-xs font-weight-bold mb-0">
                                                                                    {moment(sessions.start_date)
                                                                                        .utc()
                                                                                        .format("DD-MM-YYYY")}
                                                                                </p>
                                                                                {/*<p className="text-xs text-secondary mb-0">Organization</p>*/}
                                                                            </td>
                                                                            <td className="align-middle text-center text-sm">
                                                                                <p className="text-xs font-weight-bold mb-0">
                                                                                    {moment(sessions.end_date)
                                                                                        .utc()
                                                                                        .format("DD-MM-YYYY")}
                                                                                </p>
                                                                            </td>
                                                                            <td className="align-middle text-center text-sm">
                                                                                <p className="text-xs font-weight-bold mb-0">
                                                                                    {sessions.created_at
                                                                                        ? moment(sessions.created_at)
                                                                                            .utc()
                                                                                            .format("DD-MM-YYYY")
                                                                                        : "-"}
                                                                                </p>
                                                                            </td>

                                                                            <td className="align-middle text-center text-sm">
                                                                                <p className="text-xs font-weight-bold mb-0">
                                                                                    {sessions.customSessionPaymentDate
                                                                                        ? "Every " +
                                                                                        moment
                                                                                            .localeData()
                                                                                            .ordinal(
                                                                                                parseInt(
                                                                                                    moment(
                                                                                                        sessions.customSessionPaymentDate
                                                                                                    )
                                                                                                        .utc()
                                                                                                        .format("DD")
                                                                                                )
                                                                                            ) +
                                                                                        " at " +
                                                                                        moment
                                                                                            .utc(
                                                                                                sessions.customSessionPaymentDate
                                                                                            )
                                                                                            .format("HH:mm") +
                                                                                        " from " +
                                                                                        moment
                                                                                            .utc(
                                                                                                sessions.customSessionPaymentDate
                                                                                            )
                                                                                            .format("DD MMMM YYYY")
                                                                                        : "-"}
                                                                                </p>
                                                                            </td>

                                                                            <td className="align-middle text-center">
                                                                                <div>

                                                                                    {sessions.publicationStatus === "PUBLISHED" ?
                                                                                        <span
                                                                                            className="badge badge-sm bg-gradient-success text-center">PUBLISHED</span> : <></>}

                                                                                    {sessions.publicationStatus === "UNPUBLISHED" ?
                                                                                        <span
                                                                                            className="badge badge-sm bg-gradient-warning text-center">UNPUBLISHED</span> : <></>}
                                                                                    {sessions.publicationStatus === "" ?
                                                                                        <>-</> : <></>}
                                                                                </div>
                                                                                <div>
                                                                                    {sessions.openStatus === "OPENED" ?
                                                                                        <span
                                                                                            className="badge badge-sm bg-gradient-success text-center">OPENED</span> : <></>}

                                                                                    {sessions.openStatus === "CLOSED" ?
                                                                                        <span
                                                                                            className="badge badge-sm bg-gradient-danger text-center">CLOSED</span> : <></>}
                                                                                    {sessions.openStatus === "" ?
                                                                                        <>-</> : <></>}
                                                                                </div>
                                                                            </td>

                                                                            <td className="align-middle text-center">
                                                                                {sessions.subscriptionPlans &&
                                                                                    sessions.subscriptionPlans.map((plan) => (
                                                                                        <div key={plan.id}>
                                          <span className="badge badge-sm bg-gradient-primary">
                                            {plan.name}
                                          </span>
                                                                                            {/*<a*/}
                                                                                            {/*    href="javascript:;"*/}
                                                                                            {/*    data-bs-toggle="modal"*/}
                                                                                            {/*    data-bs-target="#confirmationRemoveModal"*/}
                                                                                            {/*>*/}
                                                                                            <span
                                                                                                className="material-icons danger"
                                                                                                id={sessions.id}
                                                                                                value={plan.id}
                                                                                                onClick={this.savePlanIdToRemove}
                                                                                                data-bs-target="#confirmationRemoveModal"
                                                                                                data-bs-toggle="modal"
                                                                                                style={{
                                                                                                    cursor: "pointer",
                                                                                                    color: "firebrick",
                                                                                                }}
                                                                                            >
                                            cancel
                                          </span>

                                                                                            <br/>
                                                                                        </div>
                                                                                    ))}
                                                                                <a
                                                                                    href="javascript:;"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#exampleModalAddPlan"
                                                                                >
                                                                                    <p
                                                                                        className="badge badge-sm bg-gradient-success"
                                                                                        id={sessions.id}
                                                                                        onClick={this.selectSession}
                                                                                    >
                                                                                        Add
                                                                                    </p>
                                                                                </a>
                                                                            </td>
                                                                            <td className="text-center">
                                                                                <div className="d-flex px-2 py-1">
                                                                                    <div
                                                                                        className="d-flex flex-column justify-content-center">
                                                                                        <a
                                                                                            className="text-dark"
                                                                                            href="javascript:;"
                                                                                            data-bs-toggle="modal"
                                                                                            data-bs-target={
                                                                                                "#exampleModal" + sessions.id
                                                                                            }
                                                                                        >
                                          <span
                                              className="material-icons"
                                              onClick={this.getId}
                                              id={sessions.id}
                                              value={"/api/"}
                                              style={{
                                                  cursor: "pointer",
                                                  color: "dodgerblue",
                                              }}
                                          >
                                            edit
                                          </span>
                                                                                        </a>
                                                                                        <span
                                                                                            className="material-icons"
                                                                                            style={{
                                                                                                cursor: "pointer",
                                                                                                color: "firebrick",
                                                                                            }}
                                                                                            onClick={this.saveSessionIdToRemove}
                                                                                            data-bs-target="#confirmationDeleteSessionModal"
                                                                                            data-bs-toggle="modal"
                                                                                            id={sessions.id}
                                                                                        >
                                          delete
                                        </span>

                                                                                        <span
                                                                                            className="material-icons danger"
                                                                                            id={sessions.id}
                                                                                            onClick={this.openDetails}
                                                                                            style={{
                                                                                                cursor: "pointer",
                                                                                                color: "dodgerblue",
                                                                                            }}
                                                                                        >
                                          visibility
                                        </span>
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-secondary"
                                                                                            id={sessions.id}
                                                                                            onClick={this.openListSubscription}
                                                                                        >
                                                                                            Manage
                                                                                        </button>
                                                                                    </div>
                                                                                </div>

                                                                                <div
                                                                                    className="modal fade"
                                                                                    id={"exampleModal" + sessions.id}
                                                                                    tabIndex="-1"
                                                                                    aria-labelledby="exampleModalLabel"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    <div
                                                                                        className="modal-dialog modal-dialog-centered">
                                                                                        <div className="modal-content">
                                                                                            <div
                                                                                                className="modal-header">
                                                                                                <h5
                                                                                                    className="modal-title"
                                                                                                    id="exampleModalLabel"
                                                                                                >
                                                                                                    Edit Session
                                                                                                </h5>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn-close"
                                                                                                    data-bs-dismiss="modal"
                                                                                                    aria-label="Close"
                                                                                                ></button>
                                                                                            </div>
                                                                                            <form
                                                                                                className="ml-4 mr-4"
                                                                                                action="app/src/components/Admin/session/Session#"
                                                                                                id="update-session-form"
                                                                                            >
                                                                                                {this.state.isLoadingUpdate ? (
                                                                                                    <div
                                                                                                        className="spinner-border text-primary"
                                                                                                        role="status"
                                                                                                    >
                                                <span className="sr-only">
                                                  Loading...
                                                </span>
                                                                                                    </div>
                                                                                                ) : null}
                                                                                                <div className="row">
                                                                                                    <div
                                                                                                        className="col-md-3 mt-5">
                                                                                                        Name
                                                                                                        <span
                                                                                                            className="red">*</span>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-md-9">
                                                                                                        <div
                                                                                                            className="single-form">
                                                                                                            <input
                                                                                                                type="text"
                                                                                                                placeholder="Name"
                                                                                                                name="name"
                                                                                                                onChange={this.onNameChange}
                                                                                                                value={currentSession.name}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div
                                                                                                        className="col-md-3 mt-5">
                                                                                                        Description
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-md-9">
                                                                                                        <div
                                                                                                            className="single-form">
                                                                                                            <div className="single-form">
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    placeholder="Description"
                                                                                                                    name="ckeditor"
                                                                                                                    value={
                                                                                                                        currentSession.description
                                                                                                                    }
                                                                                                                    onChange={
                                                                                                                        this.onDescriptionChange
                                                                                                                    }
                                                                                                                />
                                                                                                            </div>
                                                                                                            {/*<h2>Using CKEditor 5 build in React</h2>*/}
                                                                                                            {/*<CKEditor
                                                                                                                editor={ClassicEditor}
                                                                                                                data={
                                                                                                                    currentSession.description
                                                                                                                        ? currentSession.description
                                                                                                                        : "<p></p>"
                                                                                                                }
                                                                                                                // onReady={ editor => {
                                                                                                                //     // You can store the "editor" and use when it is needed.
                                                                                                                //     console.log( 'Editor is ready to use!', editor );
                                                                                                                // } }
                                                                                                                onChange={(
                                                                                                                    event,
                                                                                                                    editor
                                                                                                                ) => {
                                                                                                                    const data =
                                                                                                                        editor.getData();
                                                                                                                    this.setState(function (
                                                                                                                        prevState
                                                                                                                    ) {
                                                                                                                        return {
                                                                                                                            currentSession: {
                                                                                                                                ...prevState.currentSession,
                                                                                                                                description: data,
                                                                                                                            },
                                                                                                                        };
                                                                                                                    });

                                                                                                                    // this.setState({textData : data});
                                                                                                                    // // console.log( { event, editor, data } );
                                                                                                                }}
                                                                                                            />*/}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="row">
                                                                                                    <div
                                                                                                        className="col-md-3 mt-5">
                                                                                                        Instructor words
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-md-9">
                                                                                                        <div
                                                                                                            className="single-form">
                                                                                                            <input
                                                                                                                type="text"
                                                                                                                placeholder="Instructor words"
                                                                                                                name="ckeditor"
                                                                                                                onChange={
                                                                                                                    this.onAdviceChange
                                                                                                                }
                                                                                                                value={
                                                                                                                    currentSession.ckeditor
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="row">
                                                                                                    <div
                                                                                                        className="col-md-3 mt-5">
                                                                                                        Course link
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-md-9">
                                                                                                        <div
                                                                                                            className="single-form">
                                                                                                            <input
                                                                                                                type="text"
                                                                                                                placeholder="Course link"
                                                                                                                name="wordpressCourse"
                                                                                                                onChange={
                                                                                                                    this
                                                                                                                        .onWordpressCourseChange
                                                                                                                }
                                                                                                                value={
                                                                                                                    currentSession.wordpressCourse
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div
                                                                                                        className="col-md-3 mt-5">
                                                                                                        Session
                                                                                                        description link
                                                                                                        <span
                                                                                                            className="red">*</span>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-md-9">
                                                                                                        <div
                                                                                                            className="single-form">
                                                                                                            <input
                                                                                                                type="text"
                                                                                                                placeholder="Session description link"
                                                                                                                name="wordpressCourse"
                                                                                                                onChange={
                                                                                                                    this
                                                                                                                        .onDescriptionLinkChange
                                                                                                                }
                                                                                                                value={
                                                                                                                    currentSession.descriptionLink
                                                                                                                        ? currentSession.descriptionLink
                                                                                                                        : ""
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="row">
                                                                                                    <div
                                                                                                        className="col-md-3 mt-5">
                                                                                                        WP GROUP ID
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-md-9">
                                                                                                        <div
                                                                                                            className="single-form">
                                                                                                            <input
                                                                                                                type="number"
                                                                                                                placeholder="WP GROUP ID"
                                                                                                                name="wordpressGroupId"
                                                                                                                onChange={
                                                                                                                    this
                                                                                                                        .onWordpressCourseIdChange
                                                                                                                }
                                                                                                                value={
                                                                                                                    currentSession.wordpressGroupId
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div
                                                                                                        className="col-md-3 center mt-5">
                                                                                                        MD GROUP ID
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-md-9">
                                                                                                        <div
                                                                                                            className="single-form">
                                                                                                            <input
                                                                                                                type="number"
                                                                                                                placeholder="MD GROUP ID"
                                                                                                                name="moddleGroupId"
                                                                                                                onChange={
                                                                                                                    this
                                                                                                                        .onMoodleCourseIdChange
                                                                                                                }
                                                                                                                value={
                                                                                                                    currentSession.moodleGroupId
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="row">
                                                                                                    <div
                                                                                                        className="col-md-3 mt-5">
                                                                                                        Custom payment
                                                                                                        date
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-md-9">
                                                                                                        <div
                                                                                                            className="single-form">
                                                                                                            <div>
                                                                                                                <style>
                                                                                                                    {`.date-picker input{
                                                                                       display: block;
                                                                                      width: 100%;
                                                                                      padding: 0.375rem 0.75rem;
                                                                                      font-size: 1rem;
                                                                                      font-weight: 400;
                                                                                      line-height: 1.5;
                                                                                      color: #212529;
                                                                                      background-color: #fff;
                                                                                      background-clip: padding-box;
                                                                                      border: 1px solid #ced4da;
                                                                                      -webkit-appearance: none;
                                                                                         -moz-appearance: none;
                                                                                              appearance: none;
                                                                                      border-radius: 0.25rem;
                                                                                      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                                                                                  }`}
                                                                                                                </style>
                                                                                                                <DatePicker
                                                                                                                    // selected={(this.state.customDateUpdate) ? new Date(this.state.customDateUpdate) : null}
                                                                                                                    selected={
                                                                                                                        this.state
                                                                                                                            .customDateUpdate
                                                                                                                    }
                                                                                                                    onChange={
                                                                                                                        this
                                                                                                                            .handleChangeCustomUpdatePaymentDate
                                                                                                                    }
                                                                                                                    showTimeSelect
                                                                                                                    timeFormat="HH:mm"
                                                                                                                    timeIntervals={5}
                                                                                                                    timeCaption="time"
                                                                                                                    dateFormat="MMMM d,yyyy h:mm aa"
                                                                                                                    wrapperClassName="date-picker"
                                                                                                                    popperPlacement="top"
                                                                                                                    popperModifiers={{
                                                                                                                        flip: {
                                                                                                                            behavior: ["left"], // don't allow it to flip to be above
                                                                                                                        },
                                                                                                                        preventOverflow: {
                                                                                                                            enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                                                                                        },
                                                                                                                        hide: {
                                                                                                                            enabled: false, // turn off since needs preventOverflow to be enabled
                                                                                                                        },
                                                                                                                    }}
                                                                                                                    placeholderText="Custom payment date"
                                                                                                                    isClearable={true}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div
                                                                                                        className="col-md-3 mt-5">
                                                                                                        Start date
                                                                                                        <span
                                                                                                            className="red">*</span>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-md-9">
                                                                                                        <div
                                                                                                            className="single-form">
                                                                                                            <input
                                                                                                                type="date"
                                                                                                                name="start_date"
                                                                                                                placeholder="Start Date"
                                                                                                                onChange={
                                                                                                                    this.onStartDateChange
                                                                                                                }
                                                                                                                value={moment(
                                                                                                                    currentSession.startDate
                                                                                                                ).format("YYYY-MM-DD")}
                                                                                                            />

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div
                                                                                                        className="col-md-3 mt-5">
                                                                                                        End date
                                                                                                        <span
                                                                                                            className="red">*</span>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-md-9">
                                                                                                        <div
                                                                                                            className="single-form">
                                                                                                            <input
                                                                                                                type="date"
                                                                                                                name="end_date"
                                                                                                                placeholder="End Date"
                                                                                                                onChange={
                                                                                                                    this.onEndDateChange
                                                                                                                }
                                                                                                                value={moment(
                                                                                                                    currentSession.endDate
                                                                                                                )
                                                                                                                    .format("YYYY-MM-DD")}


                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <div className="row">
                                                                                                    <div
                                                                                                        className="col-md-3 center mt-5">
                                                                                                        Publication
                                                                                                        status
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-md-9 center mt-5">
                                                                                                        <select
                                                                                                            className="form-control"
                                                                                                            name="publicationStatus"
                                                                                                            value={
                                                                                                                currentSession.publicationStatus
                                                                                                            }
                                                                                                            onChange={
                                                                                                                this.onSessionPublicationStatus
                                                                                                            }
                                                                                                        >
                                                                                                            <option>
                                                                                                                --Publication
                                                                                                                Status--
                                                                                                            </option>
                                                                                                            <option
                                                                                                                value={publicationClose.published}>
                                                                                                                {publicationClose.published}
                                                                                                            </option>
                                                                                                            <option
                                                                                                                value={publicationClose.unpublished}>
                                                                                                                {publicationClose.unpublished}
                                                                                                            </option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </div>


                                                                                                <div className="row">
                                                                                                    <div
                                                                                                        className="col-md-3 center mt-5">
                                                                                                        Subscription
                                                                                                        status
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-md-9 center mt-5">
                                                                                                        <select
                                                                                                            className="form-control"
                                                                                                            name="openStatus"
                                                                                                            value={
                                                                                                                currentSession.openStatus
                                                                                                            }
                                                                                                            onChange={
                                                                                                                this.onSessionSubscriptionStatus
                                                                                                            }
                                                                                                        >
                                                                                                            <option>
                                                                                                                --Subscription
                                                                                                                status--
                                                                                                            </option>
                                                                                                            <option
                                                                                                                value={subscriptionStatusOpen.open}>
                                                                                                                {subscriptionStatusOpen.open}
                                                                                                            </option>
                                                                                                            <option
                                                                                                                value={subscriptionStatusOpen.close}>
                                                                                                                {subscriptionStatusOpen.close}
                                                                                                            </option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="row">
                                                                                                    <div
                                                                                                        className="col-md-3 center mt-5">
                                                                                                        FILE
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="col-md-9">
                                                                                                        <div
                                                                                                            className="single-form">
                                                                                                            <input
                                                                                                                type="file"
                                                                                                                name="file"
                                                                                                                onChange={this.handleUploadImage}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>


                                                                                                <div
                                                                                                    className="single-form">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-success btn-lg btn-block"
                                                                                                        onClick={this.updateSession}
                                                                                                        id={sessions.id}
                                                                                                        data-bs-dismiss="modal"
                                                                                                        aria-label="Close"
                                                                                                    >
                                                                                                        Update
                                                                                                    </button>
                                                                                                </div>
                                                                                            </form>
                                                                                            <div
                                                                                                className="modal-footer">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-secondary"
                                                                                                    data-bs-dismiss="modal"
                                                                                                >
                                                                                                    Close
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div
                                                                                    className="modal fade"
                                                                                    id={"confirmationRemoveModal"}
                                                                                    tabIndex="-1"
                                                                                    aria-labelledby="confirmationRemoveModal"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    <div
                                                                                        className="modal-dialog modal-dialog-centered">
                                                                                        <div className="modal-content">
                                                                                            <div
                                                                                                className="modal-header">
                                                                                                <h5
                                                                                                    className="modal-title"
                                                                                                    id="exampleModalLabel"
                                                                                                >
                                                                                                    Dissociate
                                                                                                    subscription plan
                                                                                                </h5>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn-close"
                                                                                                    data-bs-dismiss="modal"
                                                                                                    aria-label="Close"
                                                                                                ></button>
                                                                                            </div>
                                                                                            <div className="modal-body">
                                                                                                Do you want to remove
                                                                                                the selected
                                                                                                subscription plan from
                                                                                                the session ?
                                                                                            </div>
                                                                                            <div
                                                                                                className="modal-footer">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-danger  btn-block"
                                                                                                    onClick={this.removePlan}
                                                                                                    data-bs-dismiss="modal"
                                                                                                    aria-label="Close"
                                                                                                >
                                                                                                    Confirm
                                                                                                </button>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-secondary btn-block"
                                                                                                    data-bs-dismiss="modal"
                                                                                                    aria-label="Close"
                                                                                                >
                                                                                                    Cancel
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="modal fade"
                                                                                    id="confirmationDeleteSessionModal"
                                                                                    tabIndex="-1"
                                                                                    aria-labelledby="confirmationDeleteSessionModal"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    <div
                                                                                        className="modal-dialog modal-dialog-centered">
                                                                                        <div className="modal-content">
                                                                                            <div
                                                                                                className="modal-header">
                                                                                                <h5
                                                                                                    className="modal-title"
                                                                                                    id="exampleModalLabel"
                                                                                                >
                                                                                                    Delete session
                                                                                                </h5>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn-close"
                                                                                                    data-bs-dismiss="modal"
                                                                                                    aria-label="Close"
                                                                                                ></button>
                                                                                            </div>
                                                                                            <div className="modal-body">
                                                                                                Do you want to delete
                                                                                                the selected
                                                                                                session ?
                                                                                            </div>
                                                                                            <div
                                                                                                className="modal-footer">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-danger  btn-block"
                                                                                                    onClick={this.deleteSession}
                                                                                                    data-bs-dismiss="modal"
                                                                                                    aria-label="Close"
                                                                                                >
                                                                                                    Confirm
                                                                                                </button>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-secondary btn-block"
                                                                                                    data-bs-dismiss="modal"
                                                                                                    aria-label="Close"
                                                                                                >
                                                                                                    Cancel
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="modal fade"
                                                                                    id="confirmationRemoveCourseModal"
                                                                                    tabIndex="-1"
                                                                                    aria-labelledby="confirmationRemoveCourseModal"
                                                                                    aria-hidden="true"
                                                                                >
                                                                                    <div
                                                                                        className="modal-dialog modal-dialog-centered">
                                                                                        <div className="modal-content">
                                                                                            <div
                                                                                                className="modal-header">
                                                                                                <h5
                                                                                                    className="modal-title"
                                                                                                    id="exampleModalLabel"
                                                                                                >
                                                                                                    Dissociate course
                                                                                                </h5>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn-close"
                                                                                                    data-bs-dismiss="modal"
                                                                                                    aria-label="Close"
                                                                                                ></button>
                                                                                            </div>
                                                                                            <div className="modal-body">
                                                                                                Do you want to remove
                                                                                                the selected
                                                                                                course from the session
                                                                                                ?
                                                                                            </div>
                                                                                            <div
                                                                                                className="modal-footer">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-danger  btn-block"
                                                                                                    onClick={this.removeCourse}
                                                                                                    data-bs-dismiss="modal"
                                                                                                    aria-label="Close"
                                                                                                >
                                                                                                    Confirm
                                                                                                </button>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-secondary btn-block"
                                                                                                    data-bs-dismiss="modal"
                                                                                                    aria-label="Close"
                                                                                                >
                                                                                                    Cancel
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>

                                                        <div
                                                            className="modal fade"
                                                            id="exampleModal"
                                                            tabIndex="-1"
                                                            aria-labelledby="exampleModalLabel"
                                                            aria-hidden="true"
                                                        >
                                                            <div className="modal-dialog modal-dialog-centered">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5
                                                                            className="modal-title"
                                                                            id="exampleModalLabel"
                                                                        >
                                                                            Create a new session
                                                                        </h5>
                                                                        <button
                                                                            type="button"
                                                                            className="btn-close"
                                                                            data-bs-dismiss="modal"
                                                                            aria-label="Close"
                                                                        ></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <form
                                                                            action="app/src/components/Admin/session/Session#"
                                                                            id="create-session-form">
                                                                            <div className="row">
                                                                                <div className="col-md-3 center mt-5">
                                                                                    Name <span className="red"
                                                                                               style={{color: "red"}}>*</span>
                                                                                </div>
                                                                                <div className="col-md-9">
                                                                                    <div className="single-form">
                                                                                        <input
                                                                                            type="text"
                                                                                            placeholder="Name"
                                                                                            name="name"
                                                                                            onChange={this.handleFormChange}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-3 center mt-5">
                                                                                    Description
                                                                                </div>
                                                                                <div className="col-md-9">
                                                                                    <div className="single-form">
                                                                                        <div className="single-form">
                                                                                            <input
                                                                                                type="text"
                                                                                                placeholder="Description"
                                                                                                name="description"
                                                                                                onChange={this.handleFormChange}
                                                                                            />
                                                                                        </div>
                                                                                        {/*<h2>Using CKEditor 5 build in React</h2>*/}
                                                                                     {/*   <CKEditor
                                                                                            editor={ClassicEditor}
                                                                                            data="<p></p>"
                                                                                            // onReady={ editor => {
                                                                                            //     // You can store the "editor" and use when it is needed.
                                                                                            //     console.log( 'Editor is ready to use!', editor );
                                                                                            // } }
                                                                                            onChange={(event, editor) => {
                                                                                                const data = editor.getData();
                                                                                                this.setState({description: data});
                                                                                                // console.log( { event, editor, data } );
                                                                                            }}
                                                                                        />*/}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row">
                                                                                <div className="col-md-3 center mt-5">
                                                                                    Instructor words
                                                                                </div>
                                                                                <div className="col-md-9">
                                                                                    <div className="single-form">
                                                                                        <input
                                                                                            type="text"
                                                                                            placeholder="Instructor words"
                                                                                            name="ckeditor"
                                                                                            onChange={this.handleFormChange}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-3 center mt-5">
                                                                                    Course link
                                                                                </div>
                                                                                <div className="col-md-9">
                                                                                    <div className="single-form">
                                                                                        <input
                                                                                            type="text"
                                                                                            placeholder="course link"
                                                                                            name="wordpressCourse"
                                                                                            onChange={this.handleFormChange}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-3 center mt-5">
                                                                                    Session description link
                                                                                    <span className="red"
                                                                                          style={{color: "red"}}>*</span>
                                                                                </div>
                                                                                <div className="col-md-9">
                                                                                    <div className="single-form">
                                                                                        <input
                                                                                            type="text"
                                                                                            placeholder="Session description link"
                                                                                            name="descriptionLink"
                                                                                            onChange={this.handleFormChange}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-3 center mt-5">
                                                                                    WP GROUP ID
                                                                                </div>
                                                                                <div className="col-md-9">
                                                                                    <div className="single-form">
                                                                                        <input
                                                                                            type="number"
                                                                                            placeholder="WP GROUP ID"
                                                                                            name="wordpressGroupId"
                                                                                            onChange={this.handleFormChange}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row">
                                                                                <div className="col-md-3 center mt-5">
                                                                                    MD GROUP ID
                                                                                </div>
                                                                                <div className="col-md-9">
                                                                                    <div className="single-form">
                                                                                        <input
                                                                                            type="number"
                                                                                            placeholder="MD GROUP ID"
                                                                                            name="moddleGroupId"
                                                                                            onChange={this.handleFormChange}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row">
                                                                                <div className="col-md-3 center mt-5">
                                                                                    Custom payment date
                                                                                </div>
                                                                                <div className="col-md-9">
                                                                                    <div className="single-form">
                                                                                        <div>
                                                                                            <style>
                                                                                                {`.date-picker input{
                                                                                       display: block;
                                                                                      width: 100%;
                                                                                      padding: 0.375rem 0.75rem;
                                                                                      font-size: 1rem;
                                                                                      font-weight: 400;
                                                                                      line-height: 1.5;
                                                                                      color: #212529;
                                                                                      background-color: #fff;
                                                                                      background-clip: padding-box;
                                                                                      border: 1px solid #ced4da;
                                                                                      -webkit-appearance: none;
                                                                                         -moz-appearance: none;
                                                                                              appearance: none;
                                                                                      border-radius: 0.25rem;
                                                                                      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                                                                                  }`}
                                                                                            </style>
                                                                                            <DatePicker
                                                                                                selected={
                                                                                                    this.state.customSessionPaymentDate
                                                                                                }
                                                                                                onChange={
                                                                                                    this.handleChangeCustomPaymentDate
                                                                                                }
                                                                                                showTimeSelect
                                                                                                timeFormat="HH:mm"
                                                                                                timeIntervals={5}
                                                                                                timeCaption="time"
                                                                                                dateFormat="MMMM d,yyyy h:mm aa"
                                                                                                wrapperClassName="date-picker"
                                                                                                placeholderText="Custom payment date"
                                                                                                isClearable={true}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row">
                                                                                <div className="col-md-3 center mt-5">
                                                                                    Start Date date{" "}
                                                                                    <span className="red"
                                                                                          style={{color: "red"}}>*</span>
                                                                                </div>
                                                                                <div className="col-md-9">
                                                                                    <div className="single-form">
                                                                                        <input
                                                                                            type="date"
                                                                                            name="start_date"
                                                                                            placeholder="Start Date"
                                                                                            onChange={this.handleFormChange}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-3 center mt-5">
                                                                                    End date date <span className="red"
                                                                                                        style={{color: "red"}}>*</span>
                                                                                </div>
                                                                                <div className="col-md-9">
                                                                                    <div className="single-form">
                                                                                        <input
                                                                                            type="date"
                                                                                            name="end_date"
                                                                                            placeholder="End Date"
                                                                                            onChange={this.handleFormChange}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row">
                                                                                <div className="col-md-3 center mt-5">
                                                                                    Publication Status
                                                                                </div>
                                                                                <div className="col-md-9 center mt-5">
                                                                                    <select
                                                                                        className="form-control"
                                                                                        name="publicationStatus"
                                                                                        value={
                                                                                            this.state.publicationStatus
                                                                                        }
                                                                                        onChange={(e) =>
                                                                                            this.setState({
                                                                                                publicationStatus:
                                                                                                e.target.value,
                                                                                            })
                                                                                        }
                                                                                    >
                                                                                        <option value="">
                                                                                            --Publication Status--
                                                                                        </option>
                                                                                        <option
                                                                                            value={publicationClose.published}>
                                                                                            {publicationClose.published}
                                                                                        </option>
                                                                                        <option
                                                                                            value={publicationClose.unpublished}>
                                                                                            {publicationClose.unpublished}
                                                                                        </option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>

                                                                            <div className="row">
                                                                                <div className="col-md-3 center mt-5">
                                                                                    Subscription status
                                                                                </div>
                                                                                <div className="col-md-9 center mt-5">
                                                                                    <select
                                                                                        className="form-control"
                                                                                        name="openStatus"
                                                                                        value={
                                                                                            this.state.openStatus
                                                                                        }
                                                                                        onChange={(e) =>
                                                                                            this.setState({
                                                                                                openStatus:
                                                                                                e.target.value,
                                                                                            })
                                                                                        }
                                                                                    >
                                                                                        <option value="">
                                                                                            --Subscription status--
                                                                                        </option>
                                                                                        <option
                                                                                            value={subscriptionStatusOpen.open}> {subscriptionStatusOpen.open}</option>
                                                                                        <option
                                                                                            value={subscriptionStatusOpen.close}> {subscriptionStatusOpen.close}</option>
                                                                                    </select>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div
                                                                                        className="col-md-3 center mt-5">
                                                                                        FILE
                                                                                        {/*<span*/}
                                                                                        {/*    className="red">*</span>*/}
                                                                                    </div>
                                                                                    <div className="col-md-9">
                                                                                        <div className="single-form">
                                                                                            <input
                                                                                                type="file"
                                                                                                name="file"
                                                                                                onChange={this.handleUploadImage}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="single-form">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-success btn-lg btn-block"
                                                                                    onClick={this.saveSession}
                                                                                    data-bs-dismiss="modal"
                                                                                    aria-label="Close"
                                                                                >
                                                                                    Add
                                                                                </button>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-secondary"
                                                                            data-bs-dismiss="modal"
                                                                        >
                                                                            Close
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="modal fade"
                                                            id="exampleModalAdd"
                                                            tabIndex="-1"
                                                            aria-labelledby="exampleModalLabel"
                                                            aria-hidden="true"
                                                        >
                                                            <div className="modal-dialog modal-dialog-centered">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5
                                                                            className="modal-title"
                                                                            id="exampleModalLabel"
                                                                        >
                                                                            Associated Courses
                                                                        </h5>
                                                                        <button
                                                                            type="button"
                                                                            className="btn-close"
                                                                            data-bs-dismiss="modal"
                                                                            aria-label="Close"
                                                                        ></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <table className="table table-borderless">
                                                                            <thead>
                                                                            <tr>
                                                                                <th>ID</th>
                                                                                <th>Name</th>
                                                                                <th>...</th>
                                                                            </tr>
                                                                            </thead>

                                                                            <tbody>
                                                                            {
                                                                                //this.state.coursesToShow && this.state.coursesToShow.map((course) => (
                                                                                this.state.coursesToShow &&
                                                                                this.state.coursesToShow.map((course) => (
                                                                                    <tr key={course.id}>
                                                                                        <td
                                                                                            value={"/api/courses/" + course.id}
                                                                                        >
                                                                                            {course.id}
                                                                                        </td>
                                                                                        <td>{course.title}</td>
                                                                                        <td>
                                                                                            <button
                                                                                                value={
                                                                                                    "/api/courses/" + course.id
                                                                                                }
                                                                                                className="badge badge-sm bg-gradient-success"
                                                                                                data-bs-dismiss="modal"
                                                                                                onClick={this.sendCourseData}
                                                                                            >
                                                                                                Associate
                                                                                            </button>
                                                                                        </td>

                                                                                    </tr>
                                                                                ))
                                                                            }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-secondary"
                                                                            data-bs-dismiss="modal"
                                                                        >
                                                                            Close
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div
                                                            className="modal fade"
                                                            id="exampleModalAddPlan"
                                                            tabIndex="-1"
                                                            aria-labelledby="exampleModalLabel"
                                                            aria-hidden="true"
                                                        >
                                                            <div className="modal-dialog modal-dialog-centered">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        <h5
                                                                            className="modal-title"
                                                                            id="exampleModalLabel"
                                                                        >
                                                                            Associated Plans
                                                                        </h5>
                                                                        <button
                                                                            type="button"
                                                                            className="btn-close"
                                                                            data-bs-dismiss="modal"
                                                                            aria-label="Close"
                                                                        ></button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        <table className="table table-borderless">
                                                                            <thead>
                                                                            <tr>
                                                                                {/*<th>ID</th>*/}
                                                                                <th>...</th>
                                                                                <th>Name</th>

                                                                            </tr>
                                                                            </thead>

                                                                            <tbody>
                                                                            {this.state.plansToShow &&
                                                                                this.state.plansToShow.map((plan) => (
                                                                                    <tr key={plan.id}>
                                                                                        {/*<td*/}
                                                                                        {/*    value={*/}
                                                                                        {/*        "/api/subscription_plans/" + plan.id*/}
                                                                                        {/*    }*/}
                                                                                        {/*>*/}
                                                                                        {/*    {plan.id}*/}
                                                                                        {/*</td>*/}
                                                                                        <td>
                                                                                            <div
                                                                                                className="d-flex px-2 py-1">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id="search-field"
                                                                                                    value={plan.id}
                                                                                                    onClick={this.handleChangePlan}
                                                                                                />
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>{plan.name}</td>
                                                                                    </tr>
                                                                                ))}

                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div className="modal-footer">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-sm btn-success"
                                                                            data-bs-dismiss="modal"
                                                                            id="search-Field"
                                                                            disabled={(this.state.planIds).length < 1}
                                                                            onClick={this.sendPlanData}
                                                                        >
                                                                            Associate
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-secondary"
                                                                            data-bs-dismiss="modal"
                                                                        >
                                                                            Close
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="position-fixed bottom-1 end-1 z-index-2">
                                                    {this.state.successMessage ? (
                                                        <div
                                                            className="toast fade show p-2 mt-2 bg-gradient-info"
                                                            role="alert"
                                                            aria-live="assertive"
                                                            id="infoToast"
                                                            aria-atomic="true"
                                                        >
                                                            <div className="toast-header bg-transparent border-0">
                                                                <i className="material-icons text-white me-2">
                                                                    check
                                                                </i>
                                                                <span className="me-auto text-white font-weight-bold">
                            Session{" "}
                          </span>
                                                                <small className="text-white">just now</small>
                                                                <i
                                                                    className="fas fa-times text-md text-white ms-3 cursor-pointer"
                                                                    data-bs-dismiss="toast"
                                                                    aria-label="Close"
                                                                    onClick={(e) =>
                                                                        this.setState({successMessage: false})
                                                                    }
                                                                ></i>
                                                            </div>
                                                            <hr className="horizontal light m-0"/>
                                                            <div className="toast-body text-white">
                                                                {this.state.message}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {this.state.updateMessage ? (
                                                        <div
                                                            className="toast fade show p-2 mt-2 bg-white"
                                                            role="alert"
                                                            aria-live="assertive"
                                                            id="warningToast"
                                                            aria-atomic="true"
                                                        >
                                                            <div className="toast-header border-0">
                                                                <i className="material-icons text-warning me-2">
                                                                    check
                                                                </i>
                                                                <span className="me-auto font-weight-bold">
                            Session
                          </span>
                                                                <small className="text-body">just now</small>
                                                                <i
                                                                    className="fas fa-times text-md ms-3 cursor-pointer"
                                                                    data-bs-dismiss="toast"
                                                                    aria-label="Close"
                                                                    onClick={(e) =>
                                                                        this.setState({updateMessage: false})
                                                                    }
                                                                ></i>
                                                            </div>
                                                            <hr className="horizontal dark m-0"/>
                                                            <div className="toast-body">
                                                                {this.state.updateMessageText}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {this.state.deleteMessage ? (
                                                        <div
                                                            className="toast fade show p-2 mt-2 bg-gradient-danger"
                                                            role="alert"
                                                            aria-live="assertive"
                                                            id="infoToast"
                                                            aria-atomic="true"
                                                        >
                                                            <div className="toast-header bg-transparent border-0">
                                                                <i className="material-icons text-white me-2">
                                                                    check
                                                                </i>
                                                                <span className="me-auto text-white font-weight-bold">
                            Session
                          </span>
                                                                <small className="text-white">just now</small>
                                                                <i
                                                                    className="fas fa-times text-md ms-3 cursor-pointer"
                                                                    data-bs-dismiss="toast"
                                                                    aria-label="Close"
                                                                    onClick={(e) =>
                                                                        this.setState({deleteMessage: false})
                                                                    }
                                                                ></i>
                                                            </div>
                                                            <hr className="horizontal light m-0"/>
                                                            <div className="toast-body text-white">
                                                                {this.state.deleteMessageContent}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-footer py-4">
                                        <nav aria-label="...">
                                            <ReactPaginate
                                                previousLabel={"<"}
                                                nextLabel={">"}
                                                breakLabel={"..."}
                                                pageCount={this.state.lastPage}
                                                marginPagesDisplayed={3}
                                                pageRangeDisplayed={6}
                                                onPageChange={handlePageClick}
                                                containerClassName={"pagination justify-content-end mb-0"}
                                                pageClassName={"page-item"}
                                                previousClassName={"page-item"}
                                                previousLinkClassName={"page-link"}
                                                pageLinkClassName={"page-link"}
                                                nextClassName={"page-item"}
                                                nextLinkClassName={"page-link"}
                                                breakClassName={"page-item"}
                                                breakLinkClassName={"page-link"}
                                                activeClassName={"active"}
                                            />
                                        </nav>
                                    </div>
                                </>
                            </>}
                </main>
            </>
        );
    }
}

export default function (props) {
    const navigation = useNavigate();
    //const {state} = useLocation();
    return <Session {...props} myNavigate={navigation}/>;
}

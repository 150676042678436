import React, {Component} from "react";
import Other from "../contents/dashboard/other/Other";
import AdminHeader from "../header/AdminHeader";
import Sidebar from "../sidebar/Sidebar";
import AdminFooter from "../footer/AdminFooter";
import './AdminHome.css';

class AdminHome extends Component {
    render() {
        return (

            <>
                <Sidebar/>
                <main className="mainAdmin r ms-100">
                    <AdminHeader/>
                    <div className="container-fluid py-4">

                        {/*<Figure/>*/}
                        {/*<Graphe/>*/}
                        <Other/>
                        <AdminFooter/>
                    </div>
                </main>
            </>

        )
    }
}

export default AdminHome;


import {sGET, sPOST} from "../protocol/HTTP";
import backendUrls from "../urls/backendUrls";

const LastSubscriptionService = {

    getAll: function (cb) {
        const url = backendUrls.LastSubscription.GET_ALL;
        sGET(url, cb);

    },

    GET_LAST_SUBSCRIPTION_COURSE: function (cb) {
        const url = backendUrls.LastSubscriptionCourse.GET_ALL;
        sGET(url, cb);
    },

    GET_DISCOUNT_INFO: function (data, cb) {
        const url = backendUrls.USER.SUCCESS_DISOUNT;
        sPOST(url, data, cb);
    }
}

export default LastSubscriptionService;
import React, {Component} from 'react';
import Sidebar from "../sidebar/Sidebar";
import AdminHeader from "../header/AdminHeader";
import UserService from "../../../network/services/UserService";

class AdminUserProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            name: '',
            firstName: '',
            email: '',
            phone: '',
            error: false,
            isLoading: false,
            loading: false,
            messageInfo: '',
            messagePass: '',
            /*      updateInfoMessage: false,
                 updatePasswordMessage: false, */
            userId: null,
            newPassword: '',
            characters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%&*',

        }

    }

    handleFormChange = ($event) => {
        const {name, value} = $event.target
        this.setState({[name]: value});
        this.setState({error: false})

    }

    componentDidMount() {
        //console.log(this.state.isChecked)
        const queryParams = new URLSearchParams(window.location.search);
        let userId = queryParams.get('userId');
        this.setState({userId: userId});
        //console.log("voici id " + userId);
        this.getProfilUser(userId);

    }

    getProfilUser = (userId) => {
        this.setState({isLoading: true});
        //console.log(userId);
        UserService.getProfilUser(userId,
            (data) => {
                this.setState({isLoading: false});
                this.setState({name: data.name, firstName: data.firstName, email: data.email, phone: data.phone});

            })
      
    }

    updateDetailUser = () => {
        this.setState({isLoading: true});
        let data = {
            user_id: this.state.userId,
            name: this.state.name,
            firstName: this.state.firstName,
            email: this.state.email,
            phone: this.state.phone

        }
        //console.log(data);
        UserService.updateProfilUser(data,
            () => {
                this.setState({isLoading: false, updateInfoMessage: true});
                this.setState({messageInfo: "Informations successfully updated"})
            }
        )

    }

    updatePassword = () => {
        this.setState({loading: true});
        let data = {
            user_id: this.state.userId,
            new_password: this.state.newPassword,


        }
        //console.log(data);
        UserService.updatePasswordUser(data,
            () => {
                this.setState({loading: false, updatePasswordMessage: true});
                this.setState({messagePass: "Password successfully updated"})
                this.setState({newPassword: ""});
            }
        )
    }


    generepassword = (length) => {
        let result = '';
        const charactersLength = (this.state.characters).length;
        for (let i = 0; i < 15; i++) {
            result += this.state.characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        /* console.log(charactersLength);
        console.log(this.state.result); */
        this.setState({newPassword: result})
    }


    render() {
      //  console.log("on arrive ici ---")
        return (
            <div class="g-sidenav-show  bg-gray-200">
                <Sidebar/>
                {/* <h1>Profile</h1> */}
                <main className="mainAdmin r ms-100  ">
                    <AdminHeader location="profile"/>

                    <div className="position-fixed bottom-1 end-1 z-index-2">
                        {this.state.updateInfoMessage ? (
                                <div className="toast fade show p-2 mt-2 bg-gradient-info" role="alert"
                                     aria-live="assertive" id="infoToast" aria-atomic="true">
                                    <div className="toast-header bg-transparent border-0">
                                        <i className="material-icons text-white me-2">
                                            check
                                        </i>
                                        <span className="me-auto text-white font-weight-bold">Notification </span>
                                        <small className="text-white">just now</small>
                                        <i className="fas fa-times text-md text-white ms-3 cursor-pointer"
                                           data-bs-dismiss="toast" aria-label="Close"></i>
                                    </div>
                                    <hr className="horizontal light m-0"/>
                                    <div className="toast-body text-white">
                                        {this.state.messageInfo}
                                    </div>
                                </div>
                            ) :
                            (<></>)}


                        {this.state.updatePasswordMessage ? (
                                <div className="toast fade show p-2 mt-2 bg-gradient-info" role="alert"
                                     aria-live="assertive" id="warningToast" aria-atomic="true">
                                    <div className="toast-header bg-transparent border-0">
                                        <i className="material-icons text-warning me-2">
                                            check
                                        </i>
                                        <span className="me-auto text-white font-weight-bold">Notification </span>
                                        <small className="text-white">just now</small>
                                        <i className="fas fa-times text-md ms-3 cursor-pointer" data-bs-dismiss="toast"
                                           aria-label="Close"></i>
                                    </div>
                                    <hr className="horizontal dark m-0"/>
                                    <div className="toast-body text-white">
                                        {this.state.messagePass}
                                    </div>
                                </div>
                            ) :
                            (<></>)}
                    </div>

                    <div class="container-fluid px-2 px-md-4">
                        <div class="page-header min-height-300 border-radius-xl mt-4"
                             style={{backgroundImage: `url("https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80")`}}>
                            <span class="mask  bg-gradient-primary  opacity-6"></span>
                        </div>
                        <div class="card card-body mx-3 mx-md-4 mt-n6">
                            <div class="row gx-4 mb-3">
                                <div class="col-auto">
                                    <div class="avatar avatar-xl position-relative">
                                        <img src="../assets/img/bruce-mars.jpg" alt="profile_image"
                                             class="w-100 border-radius-lg shadow-sm"/>
                                    </div>
                                </div>
                                <div class="col-auto my-auto">
                                    <div class="h-100">
                                        <h5 class="mb-1">
                                            Edit User {this.state.firstName} {this.state.name}
                                        </h5>
                                        {/* <p class="mb-0 font-weight-normal text-sm">

                                        Of the November 2022 session
                                    </p> */}
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0 mx-auto mt-3">
                                    {/* <div class="nav-wrapper position-relative end-0">
                                    <ul class="nav nav-pills nav-fill p-1" role="tablist">
                                        <li class="nav-item">
                                        <a class="nav-link mb-0 px-0 py-1 active " data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="true">
                                            <i class="material-icons text-lg position-relative">home</i>
                                            <span class="ms-1">App</span>
                                        </a>
                                        </li>
                                        <li class="nav-item">
                                        <a class="nav-link mb-0 px-0 py-1 " data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="false">
                                            <i class="material-icons text-lg position-relative">email</i>
                                            <span class="ms-1">Messages</span>
                                        </a>
                                        </li>
                                        <li class="nav-item">
                                        <a class="nav-link mb-0 px-0 py-1 " data-bs-toggle="tab" href="javascript:;" role="tab" aria-selected="false">
                                            <i class="material-icons text-lg position-relative">settings</i>
                                            <span class="ms-1">Settings</span>
                                        </a>
                                        </li>
                                    </ul>
                                    </div> */}
                                </div>
                            </div>
                            <div class="row">
                                <div class="row d-flex align-items-center justify-content-center">
                                    {/* <div class="col-12 col-xl-4">
                                    <div class="card card-plain h-100">
                                        <div class="card-header pb-0 p-3">
                                        <h6 class="mb-0">Platform Settings</h6>
                                        </div>
                                        <div class="card-body p-3">
                                        <h6 class="text-uppercase text-body text-xs font-weight-bolder">Account</h6>
                                        <ul class="list-group">
                                            <li class="list-group-item border-0 px-0">
                                            <div class="form-check form-switch ps-0">
                                                <input class="form-check-input ms-auto" type="checkbox" id="flexSwitchCheckDefault" checked />
                                                <label class="form-check-label text-body ms-3 text-truncate w-80 mb-0" for="flexSwitchCheckDefault">Email me when someone follows me</label>
                                            </div>
                                            </li>
                                            <li class="list-group-item border-0 px-0">
                                            <div class="form-check form-switch ps-0">
                                                <input class="form-check-input ms-auto" type="checkbox" id="flexSwitchCheckDefault1" />
                                                <label class="form-check-label text-body ms-3 text-truncate w-80 mb-0" for="flexSwitchCheckDefault1">Email me when someone answers on my post</label>
                                            </div>
                                            </li>
                                            <li class="list-group-item border-0 px-0">
                                            <div class="form-check form-switch ps-0">
                                                <input class="form-check-input ms-auto" type="checkbox" id="flexSwitchCheckDefault2" checked />
                                                <label class="form-check-label text-body ms-3 text-truncate w-80 mb-0" for="flexSwitchCheckDefault2">Email me when someone mentions me</label>
                                            </div>
                                            </li>
                                        </ul>
                                        <h6 class="text-uppercase text-body text-xs font-weight-bolder mt-4">Application</h6>
                                        <ul class="list-group">
                                            <li class="list-group-item border-0 px-0">
                                            <div class="form-check form-switch ps-0">
                                                <input class="form-check-input ms-auto" type="checkbox" id="flexSwitchCheckDefault3" />
                                                <label class="form-check-label text-body ms-3 text-truncate w-80 mb-0" for="flexSwitchCheckDefault3">New launches and projects</label>
                                            </div>
                                            </li>
                                            <li class="list-group-item border-0 px-0">
                                            <div class="form-check form-switch ps-0">
                                                <input class="form-check-input ms-auto" type="checkbox" id="flexSwitchCheckDefault4" checked />
                                                <label class="form-check-label text-body ms-3 text-truncate w-80 mb-0" for="flexSwitchCheckDefault4">Monthly product updates</label>
                                            </div>
                                            </li>
                                            <li class="list-group-item border-0 px-0 pb-0">
                                            <div class="form-check form-switch ps-0">
                                                <input class="form-check-input ms-auto" type="checkbox" id="flexSwitchCheckDefault5" />
                                                <label class="form-check-label text-body ms-3 text-truncate w-80 mb-0" for="flexSwitchCheckDefault5">Subscribe to newsletter</label>
                                            </div>
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div> */}
                                    <br></br>
                                    <div class="col-12">
                                        <div class="card card-plain h-100 ">
                                            <div class="card-header pb-0 p-3">
                                                <div class="row">
                                                    <div class="col-md-8 d-flex align-items-center">
                                                        <h6 class="mb-0">Profile Information</h6>
                                                    </div>
                                                    <div class="col-md-4 text-end">
                                                        <a href="javascript:;">
                                                            <i class="fas fa-user-edit text-secondary text-sm"
                                                               data-bs-toggle="tooltip" data-bs-placement="top"
                                                               title="Edit Profile"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <form action="app/src/components/Admin/Profile/AdminUserProfile#">
                                                <div class="container card-body p-3">
                                                    <>
                                                        {
                                                            this.state.isLoading ? (
                                                                <div class="spinner-border text-primary" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>

                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                    </>
                                                    <hr class="horizontal gray-light my-4"/>
                                                    <div className='row'>
                                                        <div className="col-md-12 col-sm-12 col-xs-12 col-lg-2 mb-3">
                                                            <label className="form-label">First Name</label>
                                                        </div>
                                                        <div className="col-md-12 col-sm-12 col-xs-12 col-lg-10 mb-3">
                                                            <input type="Text" width="20px"
                                                                   className="form-control"
                                                                   name="firstName"
                                                                   value={this.state.firstName}
                                                                   onChange={this.handleFormChange}
                                                                   placeholder="First Name"/>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-md-12 col-sm-12 col-xs-12 col-lg-2 mb-3">
                                                            <label className="form-label">Last Name</label>
                                                        </div>
                                                        <div className="col-md-12 col-sm-12 col-xs-12 col-lg-10 mb-3">
                                                            <input type="Text" width="20px"
                                                                   className="form-control"
                                                                   name="name"
                                                                   value={this.state.name}
                                                                   onChange={this.handleFormChange}
                                                                   placeholder="Last Name"/>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-md-12 col-sm-12 col-xs-12 col-lg-2 mb-3">
                                                            <label className="form-label">Email</label>
                                                        </div>
                                                        <div className="col-md-12 col-sm-12 col-xs-12 col-lg-10 mb-3">
                                                            <input type="Text" width="20px"
                                                                   className="form-control"
                                                                   name="email"
                                                                   value={this.state.email}
                                                                   onChange={this.handleFormChange}
                                                                   placeholder="Email address"/>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-md-12 col-sm-12 col-xs-12 col-lg-2 mb-3">
                                                            <label className="form-label">Phone</label>
                                                        </div>
                                                        <div className="col-md-12 col-sm-12 col-xs-12 col-lg-10 mb-3">
                                                            <input type="Text" width="20px"
                                                                   className="form-control"
                                                                   name="phone"
                                                                   value={this.state.phone}
                                                                   onChange={this.handleFormChange}
                                                                   placeholder="Phone"/>
                                                        </div>
                                                    </div>
                                                    <div className='row'>

                                                        <center>
                                                            <button type="button" className="btn btn-primary"
                                                                    onClick={this.updateDetailUser}>Update information
                                                            </button>
                                                        </center>

                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <br></br>
                                        <div class="card card-plain h-100 ">
                                            <div class="card-header pb-0 p-3">
                                                <div class="row">
                                                    <div class="col-md-8 d-flex align-items-center">
                                                        <h6 class="mb-0">Update password</h6>
                                                    </div>
                                                    <div class="col-md-4 text-end">
                                                        <a href="javascript:;">
                                                            <i class="fas fa-user-edit text-secondary text-sm"
                                                               data-bs-toggle="tooltip" data-bs-placement="top"
                                                               title="Edit Profile"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <form action="app/src/components/Admin/Profile/AdminUserProfile#">
                                                <div class="container card-body p-3">
                                                    <>
                                                        {
                                                            this.state.loading ? (
                                                                <div class="spinner-border text-primary" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>

                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                    </>
                                                    <hr class="horizontal gray-light my-4"/>
                                                    <div className='row'>
                                                        <div className="col-md-12 col-sm-12 col-xs-12 col-lg-2 mb-3">
                                                            <label className="form-label">New password</label>
                                                        </div>
                                                        <div className="col-md-12 col-sm-12 col-xs-12 col-lg-7 mb-3">
                                                            <input type="Text" width="20px"
                                                                   className="form-control"
                                                                   name="newPassword"
                                                                   value={this.state.newPassword}
                                                                   onChange={this.handleFormChange}
                                                                   placeholder="New password"/>
                                                        </div>
                                                        <div className="col-md-12 col-sm-12 col-xs-12 col-lg-3 mb-3">
                                                            <button type="button" className="btn btn-primary"
                                                                    onClick={this.generepassword}
                                                            >Click to generate password
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <center>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                data-bs-toggle="modal"
                                                                data-bs-target="#myModal"
                                                                disabled={!(this.state.newPassword.length > 0)}
                                                            >Update password
                                                            </button>
                                                        </center>
                                                    </div>
                                                    <div class="modal" id="myModal">
                                                        <div
                                                            class="modal-dialog modal-dialog-centered modal-sm">
                                                            <div class="modal-dialog">
                                                                <div class="modal-content">
                                                                    <div
                                                                        class="modal-header bg-primary">
                                                                        <h1 class="modal-title text-white">Confirmation</h1>
                                                                        <button type="button"
                                                                                class="btn-close"
                                                                                data-bs-dismiss="modal"
                                                                                aria-label="Close"></button>
                                                                    </div>
                                                                    <div class="modal-body">
                                                                        <h5>Are you sure you want to
                                                                            change the
                                                                            password?</h5>
                                                                    </div>
                                                                    <div class="modal-footer">
                                                                        <button type="button"
                                                                                class="btn btn-secondary"
                                                                                data-bs-dismiss="modal">Close
                                                                        </button>
                                                                        <button type="button"
                                                                                class="btn btn-primary save"
                                                                                onClick={this.updatePassword}
                                                                                data-bs-dismiss="modal">Save
                                                                            changes
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                    {/* <div class="col-12 col-xl-4">
                                    <div class="card card-plain h-100">
                                        <div class="card-header pb-0 p-3">
                                        <h6 class="mb-0">Conversations</h6>
                                        </div>
                                        <div class="card-body p-3">
                                        <ul class="list-group">
                                            <li class="list-group-item border-0 d-flex align-items-center px-0 mb-2 pt-0">
                                            <div class="avatar me-3">
                                                <img src="../assets/img/kal-visuals-square.jpg" alt="kal" class="border-radius-lg shadow" />
                                            </div>
                                            <div class="d-flex align-items-start flex-column justify-content-center">
                                                <h6 class="mb-0 text-sm">Sophie B.</h6>
                                                <p class="mb-0 text-xs">Hi! I need more information..</p>
                                            </div>
                                            <a class="btn btn-link pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto" href="javascript:;">Reply</a>
                                            </li>
                                            <li class="list-group-item border-0 d-flex align-items-center px-0 mb-2">
                                            <div class="avatar me-3">
                                                <img src="../assets/img/marie.jpg" alt="kal" class="border-radius-lg shadow" />
                                            </div>
                                            <div class="d-flex align-items-start flex-column justify-content-center">
                                                <h6 class="mb-0 text-sm">Anne Marie</h6>
                                                <p class="mb-0 text-xs">Awesome work, can you..</p>
                                            </div>
                                            <a class="btn btn-link pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto" href="javascript:;">Reply</a>
                                            </li>
                                            <li class="list-group-item border-0 d-flex align-items-center px-0 mb-2">
                                            <div class="avatar me-3">
                                                <img src="../assets/img/ivana-square.jpg" alt="kal" class="border-radius-lg shadow" />
                                            </div>
                                            <div class="d-flex align-items-start flex-column justify-content-center">
                                                <h6 class="mb-0 text-sm">Ivanna</h6>
                                                <p class="mb-0 text-xs">About files I can..</p>
                                            </div>
                                            <a class="btn btn-link pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto" href="javascript:;">Reply</a>
                                            </li>
                                            <li class="list-group-item border-0 d-flex align-items-center px-0 mb-2">
                                            <div class="avatar me-3">
                                                <img src="../assets/img/team-4.jpg" alt="kal" class="border-radius-lg shadow" />
                                            </div>
                                            <div class="d-flex align-items-start flex-column justify-content-center">
                                                <h6 class="mb-0 text-sm">Peterson</h6>
                                                <p class="mb-0 text-xs">Have a great afternoon..</p>
                                            </div>
                                            <a class="btn btn-link pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto" href="javascript:;">Reply</a>
                                            </li>
                                            <li class="list-group-item border-0 d-flex align-items-center px-0">
                                            <div class="avatar me-3">
                                                <img src="../assets/img/team-3.jpg" alt="kal" class="border-radius-lg shadow" />
                                            </div>
                                            <div class="d-flex align-items-start flex-column justify-content-center">
                                                <h6 class="mb-0 text-sm">Nick Daniel</h6>
                                                <p class="mb-0 text-xs">Hi! I need more information..</p>
                                            </div>
                                            <a class="btn btn-link pe-3 ps-0 mb-0 ms-auto w-25 w-md-auto" href="javascript:;">Reply</a>
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div> */}
                                    {/* <div class="col-12 mt-4">
                                    <div class="mb-5 ps-3">
                                        <h6 class="mb-1">Projects</h6>
                                        <p class="text-sm">Architects design houses</p>
                                    </div>
                                    <div class="row">
                                        <div class="col-xl-3 col-md-6 mb-xl-0 mb-4">
                                        <div class="card card-blog card-plain">
                                            <div class="card-header p-0 mt-n4 mx-3">
                                            <a class="d-block shadow-xl border-radius-xl">
                                                <img src="../assets/img/home-decor-1.jpg" alt="img-blur-shadow" class="img-fluid shadow border-radius-xl" />
                                            </a>
                                            </div>
                                            <div class="card-body p-3">
                                            <p class="mb-0 text-sm">Project #2</p>
                                            <a href="javascript:;">
                                                <h5>
                                                Modern
                                                </h5>
                                            </a>
                                            <p class="mb-4 text-sm">
                                                As Uber works through a huge amount of internal management turmoil.
                                            </p>
                                            <div class="d-flex align-items-center justify-content-between">
                                                <button type="button" class="btn btn-outline-primary btn-sm mb-0">View Project</button>
                                                <div class="avatar-group mt-2">
                                                <a href="javascript:;" class="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Elena Morison" >
                                                    <img alt="Image placeholder" src="../assets/img/team-1.jpg" />
                                                </a>
                                                <a href="javascript:;" class="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Ryan Milly">
                                                    <img alt="Image placeholder" src="../assets/img/team-2.jpg" />
                                                </a>
                                                <a href="javascript:;" class="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Nick Daniel">
                                                    <img alt="Image placeholder" src="../assets/img/team-3.jpg" />
                                                </a>
                                                <a href="javascript:;" class="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Peterson">
                                                    <img alt="Image placeholder" src="../assets/img/team-4.jpg" />
                                                </a>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="col-xl-3 col-md-6 mb-xl-0 mb-4">
                                        <div class="card card-blog card-plain">
                                            <div class="card-header p-0 mt-n4 mx-3">
                                            <a class="d-block shadow-xl border-radius-xl">
                                                <img src="../assets/img/home-decor-2.jpg" alt="img-blur-shadow" class="img-fluid shadow border-radius-lg" />
                                            </a>
                                            </div>
                                            <div class="card-body p-3">
                                            <p class="mb-0 text-sm">Project #1</p>
                                            <a href="javascript:;">
                                                <h5>
                                                Scandinavian
                                                </h5>
                                            </a>
                                            <p class="mb-4 text-sm">
                                                Music is something that every person has his or her own specific opinion about.
                                            </p>
                                            <div class="d-flex align-items-center justify-content-between">
                                                <button type="button" class="btn btn-outline-primary btn-sm mb-0">View Project</button>
                                                <div class="avatar-group mt-2">
                                                <a href="javascript:;" class="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Nick Daniel">
                                                    <img alt="Image placeholder" src="../assets/img/team-3.jpg" />
                                                </a>
                                                <a href="javascript:;" class="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Peterson">
                                                    <img alt="Image placeholder" src="../assets/img/team-4.jpg" />
                                                </a>
                                                <a href="javascript:;" class="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Elena Morison">
                                                    <img alt="Image placeholder" src="../assets/img/team-1.jpg" />
                                                </a>
                                                <a href="javascript:;" class="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Ryan Milly">
                                                    <img alt="Image placeholder" src="../assets/img/team-2.jpg" />
                                                </a>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="col-xl-3 col-md-6 mb-xl-0 mb-4">
                                        <div class="card card-blog card-plain">
                                            <div class="card-header p-0 mt-n4 mx-3">
                                            <a class="d-block shadow-xl border-radius-xl">
                                                <img src="../assets/img/home-decor-3.jpg" alt="img-blur-shadow" class="img-fluid shadow border-radius-xl" />
                                            </a>
                                            </div>
                                            <div class="card-body p-3">
                                            <p class="mb-0 text-sm">Project #3</p>
                                            <a href="javascript:;">
                                                <h5>
                                                Minimalist
                                                </h5>
                                            </a>
                                            <p class="mb-4 text-sm">
                                                Different people have different taste, and various types of music.
                                            </p>
                                            <div class="d-flex align-items-center justify-content-between">
                                                <button type="button" class="btn btn-outline-primary btn-sm mb-0">View Project</button>
                                                <div class="avatar-group mt-2">
                                                <a href="javascript:;" class="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Peterson">
                                                    <img alt="Image placeholder" src="../assets/img/team-4.jpg" />
                                                </a>
                                                <a href="javascript:;" class="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Nick Daniel">
                                                    <img alt="Image placeholder" src="../assets/img/team-3.jpg" />
                                                </a>
                                                <a href="javascript:;" class="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Ryan Milly">
                                                    <img alt="Image placeholder" src="../assets/img/team-2.jpg" />
                                                </a>
                                                <a href="javascript:;" class="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Elena Morison">
                                                    <img alt="Image placeholder" src="../assets/img/team-1.jpg" />
                                                </a>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div class="col-xl-3 col-md-6 mb-xl-0 mb-4">
                                        <div class="card card-blog card-plain">
                                            <div class="card-header p-0 mt-n4 mx-3">
                                            <a class="d-block shadow-xl border-radius-xl">
                                                <img src="https://images.unsplash.com/photo-1606744824163-985d376605aa?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80" alt="img-blur-shadow" class="img-fluid shadow border-radius-xl" />
                                            </a>
                                            </div>
                                            <div class="card-body p-3">
                                            <p class="mb-0 text-sm">Project #4</p>
                                            <a href="javascript:;">
                                                <h5>
                                                Gothic
                                                </h5>
                                            </a>
                                            <p class="mb-4 text-sm">
                                                Why would anyone pick blue over pink? Pink is obviously a better color.
                                            </p>
                                            <div class="d-flex align-items-center justify-content-between">
                                                <button type="button" class="btn btn-outline-primary btn-sm mb-0">View Project</button>
                                                <div class="avatar-group mt-2">
                                                <a href="javascript:;" class="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Peterson">
                                                    <img alt="Image placeholder" src="../assets/img/team-4.jpg" />
                                                </a>
                                                <a href="javascript:;" class="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Nick Daniel">
                                                    <img alt="Image placeholder" src="../assets/img/team-3.jpg" />
                                                </a>
                                                <a href="javascript:;" class="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Ryan Milly">
                                                    <img alt="Image placeholder" src="../assets/img/team-2.jpg" />
                                                </a>
                                                <a href="javascript:;" class="avatar avatar-xs rounded-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Elena Morison">
                                                    <img alt="Image placeholder" src="../assets/img/team-1.jpg" />
                                                </a>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>


            </div>
        );
    }
}


export default AdminUserProfile;
import moment from "moment";
import ButtonCustom from "../button/ButtonCustom";

const computedDate = (item) => {
    if (item?.next_payment_date) {
        return (
            <>
                <s>
                    {moment(item.date).format("MMMM Do, YYYY [at] hh:mm A     ")}

                </s>
                <i
                    className="tw-ml-4 pi pi-arrow-right tw-mr-4"
                    style={{fontSize: "0.8rem"}}
                ></i>
                {moment(item.next_payment_date).format("MMMM Do, YYYY [at] hh:mm A")}
            </>
        )
    } else {
        return (
            moment(item.date).format("MMMM Do, YYYY [at] hh:mm A")
        )
    }
}
const Installments = ({datas = [], redirect}) => {
    return (
        <>
            <div className="tw-w-full">
                {datas.map((item, key) => {
                    return (
                        <div className="tw-flex" key={key}>
                            <div className="tw-flex tw-flex-col tw-items-center tw-mr-4">
                                <div>
                                    <div
                                        className={`tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6 tw-text-white tw-rounded-full ${
                                            item.status ? "tw-bg-[#309255]" : "tw-bg-primary"
                                        }`}
                                    >
                                        <i
                                            className={item.status ? "pi pi-check" : "pi pi-times"}
                                            style={{fontSize: "0.8rem"}}
                                        ></i>
                                    </div>
                                </div>
                                <div
                                    className={`tw-w-[3.8px] tw-h-full ${
                                        item.status ? "tw-bg-[#309255]" : "tw-bg-primary"
                                    } -tw-my-[1px]`}
                                />
                                {datas.length - 1 === key && (
                                    <div
                                        className="tw-flex tw-items-center tw-justify-center tw-w-4 tw-h-4 -tw-mt-2 tw-text-white tw-rounded-full">
                                        <svg
                                            className={`tw-rotate-90 ${
                                                item.status ? "tw-text-[#309255]" : "tw-text-primary"
                                            }`}
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                            aria-hidden="true"
                                        >
                                            <path
                                                d="M6.3 2.841A1.5 1.5 0 004 4.11V15.89a1.5 1.5 0 002.3 1.269l9.344-5.89a1.5 1.5 0 000-2.538L6.3 2.84z"></path>
                                        </svg>
                                    </div>
                                )}
                            </div>
                            <div
                                className={`tw-w-full tw-flex tw-justify-between tw-items-center tw-p-3 tw-mb-4 tw-rounded-lg tw-bg-accent-gray ${
                                    item.status ? "tw-text-[#309255]" : "tw-text-primary"
                                }`}
                            >
                                <div className="">
                                    <p className="">Installment {key + 1}</p>
                                    <p className="tw-mb-2 tw-text-xl tw-font-bold ">
                                        {item.amount.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                        })}
                                    </p>
                                    <p className="tw-text-xs sm:tw-text-sm">
                                        {item.status ? "" : "Next Payment Date :"}{" "}
                                        {item.date
                                            ?computedDate(item)
                                            : "21-04-2023 at 15PM"}
                                    </p>
                                </div>
                                <div className="tw">
                                    <ButtonCustom
                                        value={item.id}
                                        onClick={redirect}
                                        disabled={item.status}
                                        classes={item.status ?
                                            "tw-text-white tw-bg-[#929292]" : "tw-bg-primary hover:tw-bg-primary-900 tw-text-white tw-text-xs sm:tw-text-lg"
                                        }
                                        label={item.status ? "Payment Done" : "Make a Payment"}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default Installments;

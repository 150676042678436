import React, {Component} from 'react';
import {logout} from '../../../network/auth/Auth';
import {useNavigate} from 'react-router-dom';

class LogoutUrl extends Component {
    componentDidMount = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        let wordPressUrl = queryParams.get('origin');

        //Logout the connected user only if the request is from wordpress
        // if (wordPressUrl) {
        //     let domain = wordPressUrl.match(
        //         /^(?:http?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/gim
        //     );
        //     let sDomain = domain.toString();
        //     if (sDomain === process.env.REACT_APP_WORDPRESS_DOMAIN) {
        //     await logout();
        //         window.location = process.env.REACT_APP_WORDPRESS;
        //      }
        // }
        //   console.log(' Deconnexion en cours')
        await logout();
        window.location = process.env.REACT_APP_WORDPRESS;
    };

    render() {
        return <></>;
    }
}

export default function (props) {
    const navigation = useNavigate();
    //const {state} = useLocation();
    return <LogoutUrl {...props} myNavigate={navigation}/>;
}

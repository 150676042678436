import { useMediaQuery } from "react-responsive";
import Footer from "../components/frontv2.0/common/footer/Footer";
import AsideMenu from "../components/frontv2.0/common/menu/AsideMenu";
import { useContext } from "react";
import { SidebarContext } from "../context/SidebarContext";

const MainLayout = (props) => {
  /**
   * States
   */
  const { children } = props;
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext);
  /**
   * Comportments
   */
  const content = Array.isArray(children)
    ? children.find((child) => child.type === Content)
    : children.type === Content
    ? children
    : null;
  const subHeader = Array.isArray(children)
    ? children.find((child) => child.type === SubHeader)
    : children.type === SubHeader
    ? children
    : null;
  const header = Array.isArray(children)
    ? children.find((child) => child.type === Header)
    : children.type === Header
    ? children
    : null;

  /**
   * Render
   */
  return (
    <div className={`tw-flex tw-flex-col tw-h-screen tw-text-white `}>
      {/* Bottom Section */}
      <div className="tw-flex tw-h-full">
        {/* Menu Section */}
        <menu
          className={`tw-hidden sm:tw-flex tw-items-center tw-justify-center ${
            !isSidebarOpen && "sm:tw-w-1/6"
          } tw-text-center`}
        >
          <AsideMenu
            isDesktop={isDesktop}
            expanded={isSidebarOpen}
            toggleExpanded={toggleSidebar}
          />
        </menu>

        <section className="tw-flex tw-flex-col tw-w-full">
          {/* Top Section */}
          <header className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-h-[127px] tw-p-0 tw-m-0 tw-bg-gray-500 tw-sticky tw-top-0">
            {/* Header and SubHeader Section */}
            <div className="tw-flex tw-items-center tw-justify-center tw-w-full tw-h-full tw-bg-white">
              {header
                ? header.props.children
                : "Please define the MainLayout.Header slot in this component"}
            </div>

            {/* Header and SubHeader Section */}
            <div className="tw-w-full tw-text-black tw-bg-gray-200">
              {subHeader
                ? subHeader.props.children
                : "Please define the MainLayout.SubHeader slot in this component"}
            </div>
          </header>

          {/* Bottom Section */}
          <div className="tw-flex tw-flex-col tw-justify-between tw-h-full -tw-mt-[127px] tw-pt-[127px]">
            {/* Content Section */}
            <div className="tw-w-full tw-h-full tw-overflow-y-auto tw-text-primary tw-px-6 tw-pt-3">
              {content
                ? content.props.children
                : "Please define the MainLayout.Content slot in this component"}
            </div>

            {/* Footer Section */}
            <footer className="tw-flex tw-items-center tw-justify-start tw-w-full tw-bg-white">
              <Footer auth={true} />
            </footer>
          </div>
        </section>
      </div>
    </div>
  );
};

// SubComponents to simulate slots
const Header = () => null;
const SubHeader = () => null;
const Content = () => null;

// We introduce the subcomponents in the current component
MainLayout.Header = Header;
MainLayout.SubHeader = SubHeader;
MainLayout.Content = Content;

export default MainLayout;

import {sGET_FETCH, sPOST_FETCH} from "../protocol/HTTP";
import backendUrls from "../urls/backendUrls";

const StripeService = {
    getPublicKey: async function () {
        const url = backendUrls.Stripe.publicKey;
        const {publishableKey} = await fetch(url).then(r => r.json());

        return publishableKey;
    },
    createPaymentIntent: async function () {
        const url = backendUrls.Stripe.paymentIntent;
        const {clientSecret} = await sPOST_FETCH(url, []).then(r => r.json());
        return clientSecret;
    },
    requestSecretKey: async function () {
        const url = backendUrls.Stripe.requestSecretKey;
        console.log(url);
        const {client_secret, setup_intent_id} = await sGET_FETCH(url, []).then(r => r.json());
        return {client_secret: client_secret, setup_intent_id: setup_intent_id};
    },
    requestPaymentMethodSecretKey: async function (data) {
        const url = backendUrls.Stripe.requestSecretKey;
        return await sPOST_FETCH(url, data).then(r => r.json());
    },
    updatePaymentMethod: async function (data) {
        const url = backendUrls.Stripe.updatePaymentMethod;
        console.log(url);
        const status = await sPOST_FETCH(url, data).then(r => r.json());
        return status;
    },
    // getDigit: function (cb){
    //     const url=backendUrls.Stripe.getLastDigit;
    //     console.log(url);
    //     sGET(url,cb);
    // },
    getDigit: async function (data) {
        const url = backendUrls.Stripe.getLastDigit;
        const result = await sPOST_FETCH(url, data).then(r => r.json());
        return result;
    }

}
export default StripeService;
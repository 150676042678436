
import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../../../../layouts/MainLayout";
import Header from "../../../../components/frontv2.0/common/header/Header";
import SubHeader from "../../../../components/frontv2.0/common/header/SubHeader";
import { Dialog } from 'primereact/dialog';
import SessionService from "../../../../network/services/SessionService";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from "moment/moment";
import { Toast } from "primereact/toast";
import ButtonCustom from "../../../../components/frontv2.0/common/button/ButtonCustom";
import './style.css';
import Welcome from "../../../../components/frontv2.0/common/welcome/Welcome";
import UserService from "../../../../network/services/UserService";
import Loader from "../../../../components/frontv2.0/common/loader/Loader";
import { useNavigate } from "react-router-dom";
import { Message } from 'primereact/message';
import DatePicker from "react-datepicker";

const AskDelay = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [sessionsData, setSessionsData] = useState([]);
    const [delays, setDelays] = useState([]);
    const [subscriptionId, setsubscriptionId] = useState("");
    const [postponePaymentDate, setpostponePaymentDate] = useState("");
    const [visible, setVisible] = useState(false);
    const [session, setSession] = useState('');
    const [newDate, setNewDate] = useState("");
    const [isEligibleToPostponement, setIsEligibleToPostponement] = useState(false);
    const [numberReport, setNumberReport] = useState(0);
    const [isShow, setIsShow] = useState(0);
    const [message, setMessage] = useState("");
    const [nameSession, setNameSession] = useState("");
    const [nextInstallmentDate, setNextInstallmentDate] = useState(null)
    const [sessionEndDate, setSessionEndDate] = useState("")
    const [minDate, setMinDate] = useState('');
    const [maxDate, setMaxDate] = useState('');
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [firstLoading, setFirstLoading] = useState(true);
    const [lastDate, setLastDate] = useState('');
    const [isSessionSelected, setIsSessionSelected] = useState(false);
    const [userNotFound, setUserNotFound] = useState(false);
    const [attempts, setAttempts] = useState("");
    const [maximumInterval, setMaximumInterval] = useState("");



    const navigate = useNavigate();
    const futureDate = delays.map((delay, index) => {
        return {
            name: delay.session_name,
            category: "Done",
            date: moment(delay.postponement_date).format("MMMM DD, YYYY")

        };

    });

    const TwoDelays = () => {
        return (
            <>
                <div className="lg:tw-grid tw-flex-col tw-items-center tw-justify-center tw-flex lg:tw-grid-cols-3 md:tw-grid-col-3 tw-gap-5">
                    <div className="tw-col-span-1 flex">
                        <h3 className="tw-font-bold tw-text-primary-800 " style={{ textAlign: "left" }}>
                            Summary of your Deferred payment date
                        </h3>
                    </div>
                    <div className="tw-col-span-0 tw-hidden lg:tw-block tw-flex tw-justify-center tw-items-center">
                        <div
                            id="message-div"
                            className="tw-mb-[3%] tw-border tw-rounded-full tw-border-red-500 small-screen"
                            style={{
                                marginTop: "1%",
                                padding: "0.6rem",
                                color: "red",
                                fontSize: "0.8rem",
                                height: "3rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <div className="tw-col-span-1">
                                <p className="message" style={{ textAlign: "center", marginTop: "-7vh" }}>
                                    You've reached your maximum attempts
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="tw-flex tw-justify-end ">
                        <div className="tw-col-span-0 tw-hidden lg:tw-block">
                            <ButtonCustom
                                disabled={attempts ? numberReport === attempts : numberReport === 2 || !isEligibleToPostponement}
                                onClick={() => {
                                    setVisible(true);
                                }}
                                classes="tw-bg-gray-400 hover:tw-bg-primary-900 tw-text-white tw-w-full tw-max-h-[44px]"
                                label="New request"
                            />
                        </div>
                    </div>
                </div>

                <div className="tw-flex tw-flex-col" style={{ marginTop: "4%" }}>
                    <div className="tw-flex tw-flex-col">
                        <div className="" style={{ marginLeft: "1%", marginBottom: "0%" }}>
                            <DataTable value={renderRows()} tableStyle={{ minWidth: '10rem' }}>
                                <Column field="name" header="Session"></Column>
                                <Column field="date" header="Postponement requested"></Column>
                                <Column field="category" header="Status" body={categoryBodyTemplate}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>

                <div className=" hide-on-large-screens tw-w-full">
                    <div className="hide-on-large-screens tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-5">

                        <div className="tw-mb-3 tw-col-span-1 tw-bg-red-300 tw-rounded-lg tw-w-full h-auto"
                            style={{ marginTop: "2%" }}>
                            <p className="tw-text-red-500 tw" style={{ textAlign: "center", marginTop: "-7vh" }}>
                                You've reached your maximum two attempts
                            </p>
                        </div>

                        <ButtonCustom
                            disabled={numberReport === 2 || !isEligibleToPostponement}
                            onClick={() => {
                                setVisible(true);
                            }}
                            classes="tw-bg-gray{attempts && numberReport === attempts && (
                                <TwoDelays />
                            )}-400 hover:tw-bg-primary-900 tw-text-white tw-w-full tw-max-h-[44px]"
                            label="New request"
                        />
                    </div>
                </div>
            </>
        )
    }

    const categoryBodyTemplate = (rowData) => {
        return <span style={{ color: 'green', fontWeight: "bold" }}>{rowData?.category}</span>;
    };

    const renderRows = () => {
        return futureDate;
    }

    const Postponed = () => {

        const data =
        {
            subscriptionId: subscriptionId,
            postponePaymentDate: postponePaymentDate
        }

        SessionService.getPotsponed(data, (response) => {
            // console.log(response)
            if (response) {
                if (response.success) {
                    setMessage(response.message);
                    AllUserHistory();
                    showSuccess(response.message);
                    setShowSuccessModal(true)

                } else {
                    setMessage(response.message);
                    showError(response.message);
                }
            }
            setIsSessionSelected(false);
            setNewDate('');
            setSession('');
        });
    }


    const getAllSessionsSubscribed = () => {
        setIsLoading(true);
        setFirstLoading(true);
        UserService.getAllSessions((response) => {
            if (response) {
                setFirstLoading(false);
                // console.log(response)
                const isShow = parseInt(response.data.length, 10);
                setIsShow(isShow);
                if (response.success) {
                    if (response?.data && response.data.length > 0) {
                        let test = checkPostponementEligibility(response?.data);
                        setLastDate(response.data[0].last_date)
                        if (test === true) {
                            setIsEligibleToPostponement(true);
                        } else {
                            setIsEligibleToPostponement(false);
                            return;
                        }

                        let lastAttempt = "";
                        let maximumInterval = "";
                        response.data.forEach((item) => {
                            lastAttempt = item.payment_postponement_attempt_count ;
                            maximumInterval = item.maximum_reporting_interval;
                        });
                        // response.data.forEach((item) => {
                        //     maximumInterval = item.maximum_reporting_interval;
                        // });
                        setMaximumInterval(maximumInterval)
                        setAttempts(lastAttempt)

                        setSessionsData(response.data);
                        setNextInstallmentDate(response.data[0].next_installment_date);
                        setSessionEndDate(response.data[0].session_end_date);
                        setIsDataLoaded(true);
                        setIsLoading(false);
                    }
                }
            }

        });
    };

    function checkPostponementEligibility(objects) {
        for (let i = 0; i < objects.length; i++) {
            if (!objects[i].isEligibleToPostponement) {
                return false;
            }
        }
        return true;
    }



    const AllUserHistory = () => {

        setFirstLoading(true);
        SessionService.getUserHistoryReport((response) => {

            if (response) {
                // console.log(response)
                if (response.success === false) {
                    setIsEligibleToPostponement(false)
                    setFirstLoading(false);
                }
                let lgt = parseInt(response?.data.length);
                let lastAttempt = "";
                let maximumInterval = "";
                response.data.forEach((item) => {
                    lastAttempt = item.payment_postponement_attempt_count;
                    maximumInterval = item.maximum_reporting_interval;
                });

                setMaximumInterval(maximumInterval)
                setAttempts(lastAttempt)
                lastAttempt = lastAttempt > 0 ? lastAttempt : 2;
                if (lgt < lastAttempt) {
                    getAllSessionsSubscribed();
                }

                setAttempts(lastAttempt)
                setNumberReport(lgt)
                setIsShow(lgt)

                setIsEligibleToPostponement(true);

                if (lgt > 0) {
                    setDelays(response.data);
                    setNameSession(response.data[0].session_name)
                    setFirstLoading(false)
                }

            }
            //  setFirstLoading(false);
        });
    };


    const handleSessionChange = (event) => {
        const installmentDate = event.target.value;
        const selectedOption = event.target;
        const subscriptionId = selectedOption.options[selectedOption.selectedIndex].dataset.optionId;
        computeMiMaxDate({ next_installment_date: installmentDate })
        setsubscriptionId(subscriptionId);
        setSession(event.target.value)
        if (installmentDate) {
            setIsSessionSelected(true);
        } else {
            setIsSessionSelected(false);
        }

    };

   
    const handleNewDateChange = (date) => {
        setNewDate(date);
        setpostponePaymentDate(date);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (session === '' || newDate === '') {

            return;
        }
        setSession('');
        setVisible(false);
        setNewDate('')
    };

    const handleCancelClick = (event) => {
        event.preventDefault();
        setVisible(false);
        setSession('');
        setNewDate('')
        setIsSessionSelected(false);
    };


    const computeMiMaxDate = (session) => {
        let nextInstallmentDate = session.next_installment_date;
        let nextInstallmentDateObj = new Date(nextInstallmentDate);
        let minDate = new Date(nextInstallmentDate);

        if (isNaN(nextInstallmentDateObj.getTime())) {
            return;
        }

        minDate.setDate(minDate.getDate() + 1);

        let maxDate = new Date(lastDate);
        maxDate.setMonth(maxDate.getMonth());
        maxDate.setDate(maxDate.getDate());
        setMinDate(minDate.toISOString().split('T')[0]);
        setMaxDate(maxDate.toISOString().split('T')[0]);
       
    }

    useEffect(() => {
        AllUserHistory();

        const today = new Date();


    }, []);


    const toast = useRef(null);

    const show = () => {
        setVisible(true);
    };

    const showSuccess = (message) => {
        toast.current.show({
            severity: "success",
            summary: "Success",
            detail: message,
            life: 5000,
        });
    };

    const showError = (message) => {
        toast.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            life: 3000,
        });
    };


    // const content = (

    //     <h1 className="" style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    //     <div className=" tw-text-primary-900" style={{ fontSize: '23px' }}>
    //       You still have {attempts - numberReport} report(s) remaining, and the maximum allowed postponement interval is {maximumInterval ? maximumInterval : 21} Day(s)
    //     </div>
    //   </h1>
    // );
    const content = (

        <h1 className="text-center mx-auto " style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className="text-center mx-auto tw-text-primary-900" style={{ fontSize: '23px' }}>
                You still have {attempts - numberReport} deferral(s) remaining, and the maximum allowed postponement interval is {maximumInterval ? maximumInterval : 21} Day(s)
            </div>
        </h1>
    );

    return (
        <>

            <Toast ref={toast} position="top-right" />
            <MainLayout>
                <MainLayout.Content>
                    {firstLoading ? (
                        <div className="tw-flex tw-h-full tw-flex-col md:tw-justify-center tw-justify-center tw-items-center">

                            <Loader />
                        </div>
                    ) : (
                        <>

                            {isEligibleToPostponement ? (

                                isShow >= 0 ? (

                                    <>
                                        {numberReport === 0 ?
                                            <>
                                                <div className="card">
                                                    <Message
                                                        style={{
                                                            border: 'solid #696cff',
                                                            borderWidth: '0 0 0 6px',
                                                            color: '#696cff'
                                                        }}
                                                        className="border-primary w-full justify-content-start"
                                                        severity="info"
                                                        content={content} />
                                                </div>
                                                <div className="flex flex-row">

                                                    <div className="tw-flex tw-justify-center">
                                                        <div className="lg:tw-grid tw-flex-col tw-items-center tw-justify-center tw-flex lg:tw-grid-cols-5 md:tw-grid-col-2 tw-gap-5" style={{ marginTop: "10%" }}>
                                                            <div className="tw-col-span-0 tw-hidden lg:tw-block">

                                                            </div>
                                                            <div className="tw-col-span-1 tw-flex tw-justify-center tw-items-center mt-6">
                                                                <svg className="custom-svg" style={{ width: '119.157px', height: '113.944px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 119.157 113.944">
                                                                    <path id="Tracé_1115" data-name="Tracé 1115" d="M103.131,76.648A45.579,45.579,0,0,0,38.511,19.4L32.858,9.507a56.972,56.972,0,0,1,80.2,72.873l7.643,4.413L96.977,99.4l-.94-26.853ZM19.116,41.3A45.579,45.579,0,0,0,83.735,98.546l5.653,9.893a56.972,56.972,0,0,1-80.2-72.873L1.545,31.153,25.27,18.541l.94,26.853ZM69.179,75.084,53.068,58.972,36.956,75.084,28.9,67.029,53.068,42.861,69.179,58.972,85.291,42.861l8.056,8.055Z" fill="#1e4e70" opacity="0.7" />
                                                                </svg>
                                                            </div>
                                                            <div className="tw-col-span-1 tw-flex-col tw-flex">
                                                                <h3 className="tw-text-center tw-font-bold"
                                                                >
                                                                    You have not yet requested a deferment of payment date
                                                                </h3>

                                                                <div className="tw-flex tw-justify-center">
                                                                    <ButtonCustom
                                                                        onClick={() => {
                                                                            setVisible(true);

                                                                        }}
                                                                        disabled={!isEligibleToPostponement}
                                                                        classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white sm:tw-max-w-[230px] tw-max-h-[270px]"}
                                                                        label="Request a postpone date" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Dialog header="New postponement request" visible={visible}
                                                            style={{ width: '50vw', marginTop: "3%" }}
                                                            onHide={() => setVisible(false)}>
                                                            <form onSubmit={handleSubmit}>
                                                                <div className="tw-mb-4" style={{ marginTop: "3%" }}>


                                                                    <label htmlFor="session"
                                                                        className="tw-font-bold  tw-text-primary-800">
                                                                        Which session do you want to postpone?
                                                                    </label>

                                                                    <select style={{ marginTop: "1%" }}
                                                                        id="sessionchoose"
                                                                        className="tw-border tw-border-gray-300 tw-rounded-lg tw-py-2 tw-px-1 tw-w-full tw-bg-white focus:tw-ring-2 focus:tw-ring-blue-500 tw-outline-none"
                                                                        value={session}
                                                                        onChange={handleSessionChange}
                                                                    >
                                                                        <option value="">Choose a session</option>
                                                                        {sessionsData.map((option, index) => (
                                                                            <option
                                                                                key={index}

                                                                                value={option.next_installment_date}
                                                                                data-option-id={option.subscription_id}
                                                                            >
                                                                                {option.session_name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div className="tw-mb-4">

                                                                    <label htmlFor="newDate"
                                                                        className="tw-block tw-font-bold tw-text-primary-800"
                                                                        style={{ marginTop: "3%" }}>
                                                                        Choose the postpone date
                                                                    </label>


                                                                    <div>
                                                                        <style>
                                                                            {`.date-picker input {
            display: block;
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1;
            color: #212529;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }`}
                                                                        </style>
                                                                        <DatePicker
                                                                            className="tw-border tw-border-gray-300 tw-rounded-lg tw-py-2 tw-px-1 tw-w-full tw-bg-white focus:tw-ring-2 focus:tw-ring-blue-500 tw-outline-none"
                                                                            onChange={handleNewDateChange}
                                                                            disabled={!isSessionSelected}
                                                                            minDate={new Date(minDate)}
                                                                            selected={newDate}
                                                                            maxDate={new Date(maxDate)}
                                                                            timeFormat="HH:mm"
                                                                            timeIntervals={5}
                                                                            timeCaption="time"
                                                                            dateFormat="dd/MM/yyyy" // Modifier le format de date ici
                                                                            wrapperClassName="date-picker"
                                                                            popperPlacement="top"
                                                                            popperModifiers={{
                                                                                flip: {
                                                                                    behavior: ["left"],
                                                                                },
                                                                                preventOverflow: {
                                                                                    enabled: false,
                                                                                },
                                                                                hide: {
                                                                                    enabled: false,
                                                                                },
                                                                            }}
                                                                            placeholderText="Date"
                                                                        />
                                                                    </div>



                                                                </div>




                                                                {/* <h3 className="tw-block tw-font-bold tw-text-primary-800">
                                                                        Information: The maximum postponed interval is 3 weeks.</h3> */}

                                                                <h3 className="tw-font-bold tw-text-primary-800">
                                                                    Information: The maximum postponed interval is {maximumInterval ? maximumInterval : 21} Day(s).
                                                                </h3>
                                                                <div className="tw-flex tw-justify-end">
                                                                    <button
                                                                        type="button"
                                                                        className="tw-text-white tw-bg-primary tw-rounded tw-px-4 tw-py-2 tw-mr-2"
                                                                        onClick={handleCancelClick}
                                                                    >
                                                                        Cancel
                                                                    </button>

                                                                    <button
                                                                        type="submit"
                                                                        className={`tw-text-white ${session === '' || newDate === '' ? 'tw-bg-gray-400' : 'tw-bg-primary'} tw-rounded-lg tw-px-4 tw-py-2`}
                                                                        onClick={Postponed}
                                                                    >
                                                                        Submit
                                                                    </button>

                                                                </div>
                                                            </form>
                                                        </Dialog>
                                                    </div>
                                                </div></>
                                            :
                                            <>
                                                {firstLoading ? (
                                                    <div
                                                        className="tw-flex tw-h-full tw-flex-col md:tw-justify-center tw-justify-center tw-items-center">
                                                        <Loader />
                                                    </div>
                                                ) : (
                                                    <>
                                                        {attempts <= numberReport && (
                                                            <TwoDelays />
                                                        )}


                                                        {/* {!attempts && numberReport === 2 && (
                                                        <TwoDelays />
                                                    )} */}
                                                    </>

                                                )}

                                                {isLoading ? (
                                                    <div
                                                        className="tw-flex tw-h-full tw-flex-col md:tw-justify-center tw-justify-center tw-items-center">
                                                        <Loader />
                                                    </div>
                                                ) : (

                                                    <>
                                                        {attempts > numberReport ? (
                                                            // Contenu lorsque attempts est différent de numberReport
                                                            <>
                                                                <div className="card">
                                                                    <Message
                                                                        style={{
                                                                            border: 'solid #696cff',
                                                                            borderWidth: '0 0 0 6px',
                                                                            color: '#696cff'
                                                                        }}
                                                                        className="border-primary w-full justify-content-start"
                                                                        severity="info"
                                                                        content={content}
                                                                    />
                                                                </div>

                                                                <div className="lg:tw-grid tw-flex-col tw-items-center tw-justify-center tw-flex lg:tw-grid-cols-3 md:tw-grid-col-3 tw-gap-5">
                                                                    <div className="tw-col-span-1 mt-3">
                                                                        <h3 className="tw-font-bold tw-text-primary-800 " style={{ textAlign: "left" }}>
                                                                            Summary of your Deferred payment date
                                                                        </h3>
                                                                    </div>
                                                                    <div className="tw-col-span-0 tw-hidden lg:tw-block tw-flex tw-justify-center tw-items-center">

                                                                    </div>
                                                                    <div className="tw-flex tw-justify-end ">
                                                                        <div className="tw-col-span-0 tw-hidden lg:tw-block mt-3">
                                                                            <ButtonCustom
                                                                                disabled={(numberReport === attempts || !isEligibleToPostponement)}
                                                                                onClick={() => {
                                                                                    setVisible(true);
                                                                                }}
                                                                                classes="tw-bg-primary hover:tw-bg-primary-900 tw-text-white sm:tw-w-auto tw-max-h-[44px]"
                                                                                label="New request"
                                                                            />
                                                                        </div>

                                                                        <Dialog header="New postponement request" visible={visible}
                                                                            style={{ width: '50vw', marginTop: "3%" }}
                                                                            onHide={() => setVisible(false)}>
                                                                            <form onSubmit={handleSubmit} >
                                                                                <div className="tw-mb-4" style={{ marginTop: "3%" }}>
                                                                                    <label htmlFor="session"
                                                                                        className="tw-font-bold tw-text-primary-800">
                                                                                        Which session do you want to postpone?
                                                                                    </label>
                                                                                    <select style={{ marginTop: "1%" }}
                                                                                        id="sessionchoose"
                                                                                        className="tw-border tw-border-gray-300 tw-rounded-lg tw-py-2 tw-px-1 tw-w-full tw-bg-white focus:tw-ring-2 focus:tw-ring-blue-500 tw-outline-none"
                                                                                        value={session}
                                                                                        onChange={handleSessionChange}
                                                                                    >
                                                                                        <option value="">Choose a session</option>
                                                                                        {sessionsData.map((option, index) => (
                                                                                            <option
                                                                                                key={index}

                                                                                                value={option.next_installment_date}
                                                                                                data-option-id={option.subscription_id}
                                                                                            >
                                                                                                {option.session_name}
                                                                                            </option>
                                                                                        ))}
                                                                                    </select>
                                                                                </div>


                                                                                <div className="tw-mb-4">

                                                                                    <label htmlFor="newDate"
                                                                                        className="tw-block tw-font-bold tw-text-primary-800"
                                                                                        style={{ marginTop: "3%" }}>
                                                                                        Choose the postpone date
                                                                                    </label>


                                                                                    <div>
                                                                                        <style>
                                                                                            {`.date-picker input {
display: block;
width: 100%;
padding: 0.375rem 0.75rem;
font-size: 1rem;
font-weight: 400;
line-height: 1;
color: #212529;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #ced4da;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
border-radius: 0.25rem;
transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}`}
                                                                                        </style>
                                                                                        <DatePicker
                                                                                            className="tw-border tw-border-gray-300 tw-rounded-lg tw-py-2 tw-px-1 tw-w-full tw-bg-white focus:tw-ring-2 focus:tw-ring-blue-500 tw-outline-none"
                                                                                            disabled={!isSessionSelected}
                                                                                            onChange={handleNewDateChange}
                                                                                            minDate={new Date(minDate)}
                                                                                            selected={newDate}
                                                                                            maxDate={new Date(maxDate)}
                                                                                            timeFormat="HH:mm"
                                                                                            timeIntervals={5}
                                                                                            timeCaption="time"
                                                                                            dateFormat="dd/MM/yyyy" // Modifier le format de date ici
                                                                                            wrapperClassName="date-picker"
                                                                                            popperPlacement="top"
                                                                                            popperModifiers={{
                                                                                                flip: {
                                                                                                    behavior: ["left"],
                                                                                                },
                                                                                                preventOverflow: {
                                                                                                    enabled: false,
                                                                                                },
                                                                                                hide: {
                                                                                                    enabled: false,
                                                                                                },
                                                                                            }}
                                                                                            placeholderText="Date"
                                                                                        />
                                                                                    </div>



                                                                                </div>

                                                                                <h3 className="tw-font-bold tw-text-primary-800">
                                                                                    Information: The maximum postponed interval is {maximumInterval ? maximumInterval : 21} Day(s).
                                                                                </h3>


                                                                                <div className="tw-flex tw-justify-end">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="tw-text-white tw-bg-primary tw-rounded tw-px-4 tw-py-2 tw-mr-2"
                                                                                        onClick={handleCancelClick}
                                                                                    >
                                                                                        Cancel
                                                                                    </button>
                                                                                    <button
                                                                                        type="submit"
                                                                                        className={`tw-text-white ${session === '' || newDate === '' ? 'tw-bg-gray-400' : 'tw-bg-primary'} tw-rounded-lg tw-px-4 tw-py-2`}
                                                                                        onClick={Postponed}
                                                                                    >
                                                                                        Submit
                                                                                    </button>
                                                                                </div>
                                                                            </form>
                                                                        </Dialog>

                                                                    </div>

                                                                </div>

                                                                <div className="tw-flex tw-flex-col" style={{ marginTop: "1%" }}>
                                                                    <div className="tw-flex tw-flex-col">
                                                                        <div className="" style={{ marginLeft: "1%", marginBottom: "0%" }}>
                                                                            <DataTable value={renderRows()} tableStyle={{ minWidth: '10rem' }}>
                                                                                <Column field="name" header="Session"></Column>
                                                                                <Column field="date" header="Postponement requested"></Column>
                                                                                <Column field="category" header="Status" body={categoryBodyTemplate}></Column>
                                                                            </DataTable>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className=" hide-on-large-screens tw-w-full">
                                                                    <div className="hide-on-large-screens tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-5">


                                                                        <ButtonCustom
                                                                            disabled={numberReport === attempts || !isEligibleToPostponement}
                                                                            onClick={() => {
                                                                                setVisible(true);
                                                                            }}
                                                                            classes="tw-bg-primary hover:tw-bg-primary-900 tw-text-white tw-w-full tw-max-h-[44px]"
                                                                            label="New request"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : (

                                                            // <TwoDelays />
                                                            <h1></h1>

                                                        )}


                                                    </>
                                                )}

                                            </>
                                        }
                                    </>

                                ) :
                                    null

                            ) : (

                                <div className="lg:tw-grid tw-flex-col tw-items-center tw-justify-center tw-flex lg:tw-grid-cols-5 md:tw-grid-col-2 tw-gap-5" style={{ marginTop: "17%" }}>
                                    <div className="tw-col-span-0 tw-hidden lg:tw-block">

                                    </div>
                                    <div className="tw-col-span-1 tw-flex tw-justify-center tw-items-center">
                                        <svg className="custom-svg" style={{ width: '119.157px', height: '113.944px' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 119.157 113.944">
                                            <path id="Tracé_1115" data-name="Tracé 1115" d="M103.131,76.648A45.579,45.579,0,0,0,38.511,19.4L32.858,9.507a56.972,56.972,0,0,1,80.2,72.873l7.643,4.413L96.977,99.4l-.94-26.853ZM19.116,41.3A45.579,45.579,0,0,0,83.735,98.546l5.653,9.893a56.972,56.972,0,0,1-80.2-72.873L1.545,31.153,25.27,18.541l.94,26.853ZM69.179,75.084,53.068,58.972,36.956,75.084,28.9,67.029,53.068,42.861,69.179,58.972,85.291,42.861l8.056,8.055Z" fill="salmon" opacity="0.7" />
                                        </svg>
                                    </div>
                                    <div className="tw-col-span-1 tw-flex-col tw-flex">
                                        <h1 className="tw-text-center tw-font-bold" style={{ color: 'salmon', }}>
                                            Oops, you are not eligible for this special offer.
                                        </h1>
                                        <div className="tw-flex tw-justify-center">
                                            <ButtonCustom
                                                onClick={() => {
                                                    navigate(`/workspace/mysessions`);
                                                }}
                                                classes="tw-bg-primary hover:tw-bg-primary-900 tw-text-white sm:tw-max-w-[230px] tw-max-h-[270px]"
                                                label="Go to dashboard"
                                            />
                                        </div>
                                    </div>
                                </div>

                            )}

                        </>
                    )}

                </MainLayout.Content>

                <MainLayout.Header>
                    <Header />
                </MainLayout.Header>

                <MainLayout.SubHeader>
                    <SubHeader
                        data={[
                            { value: "Home", url: "/workspace" },
                            {
                                value: "Ask Delay",
                                url: "#",
                            },
                        ]}
                    />
                </MainLayout.SubHeader>
            </MainLayout>
        </>
    );
};

export default AskDelay;


import React, { useEffect, useState } from "react";
import MainLayout from "../../../../layouts/MainLayout";
import Header from "../../../../components/frontv2.0/common/header/Header";
import SubHeader from "../../../../components/frontv2.0/common/header/SubHeader";
import CourseService from "../../../../network/services/CourseService";
import { useNavigate } from "react-router";
import CardCourse from "../../../../components/frontv2.0/common/card/CardCourse";
import CourseLoader from "../../../../components/frontv2.0/common/loader/CourseLoader";

const AvalaibleCourses = () => {
  const navigate = useNavigate();
  /**
   * States
   */
  let [isLoading, setIsLoading] = useState(false);
  let [courses, setCourses] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [lastPage, setLastPage] = useState("");
  // eslint-disable-next-line
  let [coursesError, setCoursesError] = useState(null);

  const getAllCourses = () => {
    setIsLoading(true);
    CourseService.allUnsecureCourses(1, (data) => {
      if (data != null) {
        setCourses(data.data);
      } else if (data?.success && data?.success === true) {
        setCoursesError(data);
        setLastPage(data.total_pages);
      } else {
        setCoursesError({ message: "Erreur Inconnu" });
      }
      setIsLoading(false);
    });
  };

  const handleOnClickItem = (id) => {
    navigate(`/workspace/courses/details/${id}`);
  };

  // const handlePageClick = (page) => {
  //   let index = page.selected + 1;

  //   CourseService.allCourses(index, (response) => {
  //     setLastPage(response.total_pages);
  //     setCourses(response.data);
  //   });
  // };

  useEffect(() => {
    getAllCourses();
    window.scrollTo(0, 0);

    // eslint-disable-next-line
  }, []);
  return (
    <MainLayout>
      <MainLayout.Content>
        {/* Content Avalaible courses */}
        {!isLoading ? (
          // courses ?
          courses.length > 0 ? (
            <div className="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 2xl:tw-grid-cols-5">
              {courses.map((item, key) => {
                return (
                  <CardCourse
                    key={key}
                    onClickAction={handleOnClickItem}
                    data={item}
                  />
                );
              })}
            </div>
          ) : (
            <p className="tw-text-primary">No Courses Available</p>
          )
        ) : (
          <div className="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4">
            {[1, 2, 3, 4, 5, 6, 7, 8].map(() => {
              return <CourseLoader />;
            })}
          </div>
        )}
      </MainLayout.Content>

      <MainLayout.Header>
        <Header />
      </MainLayout.Header>

      <MainLayout.SubHeader>
        <SubHeader
          data={[
            { value: "Home", url: "/workspace" },
            { value: "Avalaible Courses", url: "#" },
          ]}
        />
      </MainLayout.SubHeader>
    </MainLayout>
  );
};

export default AvalaibleCourses;

import React from 'react';

const OrderSummary = ({data}) => {

    return (
        <>
            <div
                className={`md:tw-w-1/2 md:tw-bg-accent-gray tw-rounded-[12px] md:tw-p-4  tw-w-full md:tw-mt-0 tw-mt-12`}>
                <p className='tw-text-lg tw-font-semibold tw-text-primary tw-mb-2 '>{data.title} </p>
                <div className='tw-hidden md:tw-flex md:tw-flex-col tw-space-y-1 tw-mt-4 tw-text-gray-700'>
                    <div
                        className='tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-bg-white tw-py-2 tw-px-4 tw-text-sm tw-rounded-md'>
                        <div className='tw-w-2/4 md:tw-w-1/4'>Product</div>
                        <div className='tw-w-2/4 md:tw-w-1/4'>Payment method</div>
                        <div className='tw-w-2/4 md:tw-w-1/4'>Currency</div>
                        <div className='tw-w-2/4 md:tw-w-1/4'>Order Total</div>
                    </div>
                    <div
                        className='tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-bg-white tw-p-4 tw-font-bold tw-rounded-md tw-text-sm'>
                        <div className='md:tw-tw-w-1/4'>{data.product}</div>
                        <div className='md:tw-tw-w-1/4'>{data.paymentMethod}</div>
                        <div className='md:tw-tw-w-1/4'>{data.currency}</div>
                        <div className='md:tw-tw-w-1/4'>${data.orderTotal}</div>
                    </div>
                </div>

                {/* For Mobile */}
                <div className='md:tw-hidden tw-flex tw-flex-col tw-space-y-4 tw-mt-8 tw-text-gray-700 tw-bg-white'>
                    <div className='tw-flex'>
                        <p className='tw-w-1/3'>Product</p>
                        <p className='tw-w-2/3 tw-font-bold'>{data.product}</p>
                    </div>
                    <div className='tw-flex'>
                        <p className='tw-w-1/3'>Payment Method</p>
                        <p className='tw-w-2/3 tw-font-bold'>{data.paymentMethod}</p>
                    </div>
                    <div className='tw-flex'>
                        <p className='tw-w-1/3'>Currency</p>
                        <p className='tw-w-2/3 tw-font-bold'>{data.currency}</p>
                    </div>
                    <div className='tw-flex'>
                        <p className='tw-w-1/3'>Order Total</p>
                        <p className='tw-w-2/3 tw-font-bold'>${data.orderTotal}</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OrderSummary;
import {sDELETE, sGET, sPOST, sPOST_FILE, sPUT} from "../protocol/HTTP";
import backendUrls from "../urls/backendUrls";

const SubscriptionService = {
    getAll: function (cb) {
        const url = backendUrls.SessionPlan.subscription;
        sGET(url, cb);
    },
    getById: function (id, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SessionPlan.subscription + "/" + `${id}`;
        sGET(url, cb);
    },
    create: function (data, cb) {
        const url = backendUrls.SessionPlan.subscription;
        sPOST(url, data, cb);
    },
    update: function (id, data, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SessionPlan.subscription + "/" + `${id}`;
        sPUT(url, data, cb);

    },
    delete: function (id, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SessionPlan.subscription + "/" + `${id}`;
        sDELETE(url, cb);
    },

    addDiscount: function (data, cb) {
        const url = backendUrls.Subscription.ADD_SUBSCRIPTION;
        sPOST(url, data, cb);
    },

    subscriptionSynchronization: function (id, cb) {
        // eslint-disable-next-line
        const url = backendUrls.Subscription.MANUAL_SYNCHRONIZATION + "/" + `${id}`;
        console.log("urm "+url);
        sGET(url, cb);
    },
    accountExcel: function (data, cbs, cbe) {
        // console.log("avant de send");
        // console.log(data);
        const url = backendUrls.Subscription.ACCOUNT_SYNCHRONIZATION;
        sPOST_FILE(url, data, cbs, cbe);
    },
}

export default SubscriptionService;
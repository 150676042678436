import {sDELETE, sGET, spGET, sPOST, sPUT} from "../protocol/HTTP";
import backendUrls from "../urls/backendUrls";

const ChannelService = {
    getAllByPage: function (page_id, nbreItems, cb) {
        //  const url=backendUrls.SessionPlan.currency;
        // eslint-disable-next-line
        const url = backendUrls.SessionPlan.channel + "?page=" + `${page_id}` + "&itemsPerPage=" + `${nbreItems}`;
        spGET(url, cb);

    },
    getAll: function (cb) {
        const url = backendUrls.SessionPlan.channel;
        sGET(url, cb);

    },
    getById: function (id, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SessionPlan.channel + "/" + `${id}`;
        sGET(url, cb);
    },
    create: function (data, cb) {
        const url = backendUrls.SessionPlan.channel;
        sPOST(url, data, cb);

    },
    update: function (id, data, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SessionPlan.channel + "/" + `${id}`;
        sPUT(url, data, cb);

    },
    delete: function (id, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SessionPlan.channel + "/" + `${id}`;
        sDELETE(url, cb);

    },

}

export default ChannelService;

import MainLayout from "../../../../layouts/MainLayout";
import Header from "../../../../components/frontv2.0/common/header/Header";
import { useNavigate } from "react-router-dom";
import CardXSmall from "../../../../components/frontv2.0/common/card/CardXSmall";
import SubHeader from "../../../../components/frontv2.0/common/header/SubHeader";
import { useEffect, useState } from "react";
import UserService from "../../../../network/services/UserService";
import { MOTIF_PAYMENT } from "../../../../constants/images";
import MakePaymentLoader from "../../../../components/frontv2.0/common/loader/MakePaymentLoader";

const MakePayment = () => {
  /**
   * States
   */
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const goToSessionPaidDetail = "/workspace/make-payment/details";
  const navigate = useNavigate();

  /**
   * Comportments
   */
  const handleCLick = () => {
    navigate(goToSessionPaidDetail);
  };

  const showSubscriptions = async () => {
    setLoading(true);
    UserService.subscriptionToPay((response) => {
      if (response) {
        let tab = response.unSettledSubscriptions;
        if (tab.length > 0) {
          let tabSessions = tab.filter((e) => e.subscription.session !== null);
          setSubscriptions(tabSessions);
          setLoading(false);
        }
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    showSubscriptions();
  }, []);
  /**
   * Render
   */
  return (
    <>
      <MainLayout>
        <MainLayout.Content>
          <div className="tw-grid tw-grid-cols-1 tw-gap-3">
            {loading ? (
              [1, 2, 3, 4, 5, 6, 7, 8].map(() => {
                return <MakePaymentLoader />;
              })
            ) : (
              <>
                {!(subscriptions.length > 0) ? (
                  <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-h-full tw-p-6">
                    <img src={`${MOTIF_PAYMENT}`} alt="" />
                    <div>
                      <h1>Empty Subscriptions</h1>
                    </div>
                  </div>
                ) : (
                  <div className="tw-space-y-4">
                    {subscriptions.map((item, key) => {
                      return (
                        <CardXSmall
                          data={item}
                          onClikAction={handleCLick}
                          key={key}
                        />
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </div>
        </MainLayout.Content>

        <MainLayout.Header>
          <Header />
        </MainLayout.Header>

        <MainLayout.SubHeader>
          <SubHeader
            data={[
              { value: "Home", url: "/workspace" },
              { value: "Make a Payment", url: "#" },
            ]}
          />
        </MainLayout.SubHeader>
      </MainLayout>
    </>
  );
};

export default MakePayment;

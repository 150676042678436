import React from "react";
import { SHAPE_UNDERLINE } from "../../../../constants/images";

const TextHeader = ({
  label,
  textUnderline,
  shape = true,
  size = 2,
  textColor = "tw-text-primary",
}) => {
  return (
    <>
      <div className="tw-flex ">
        <h1
          className={`tw-mr-2 lg:tw-text-2xl md:tw-text-2xl  tw-text-xl tw-font-bold ${textColor}`}
          style={{ font: "normal normal 600 32px/48px Poppins" }}
        >
          {label}
        </h1>
        {shape && textUnderline && (
          <div className="tw-w-full tw-mb-4">
            <div className="tw-flex tw-flex-col">
              <h1
                className={`tw-font-bold lg:tw-text-2xl md:tw-text-2xl  tw-text-xl tw-text-[#309255]`}
                style={{ font: "normal normal 600 32px/48px Poppins" }}
              >
                {textUnderline}
                <img
                  className={`-tw-ml-4 -tw-mt-1 tw-w-[65px] sm:tw-w-[75px]`}
                  src={SHAPE_UNDERLINE}
                  alt=""
                  // width={95}
                />
              </h1>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TextHeader;

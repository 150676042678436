export const UNIT_OPTIONS = [
    { value: "", label: "Select the interval" },
    { value: "1", label: "1 week" },
    { value: "2", label: "2 weeks" },
    { value: "3", label: "3 weeks" },
    { value: "4", label: "4 weeks" },
    { value: "5", label: "5 weeks" },
    { value: "6", label: "6 weeks" },
    { value: "7", label: "7 weeks" },
    { value: "8", label: "8 weeks" },
    { value: "9", label: "9 weeks" },
    { value: "10", label: "10 weeks" },
    { value: "11", label: "11 weeks" },
    { value: "12", label: "12 weeks" },
    // { value: "13", label: "13 weeks" },
    // { value: "14", label: "14 weeks" },
    // { value: "15", label: "15 weeks" },
    // { value: "16", label: "16 weeks" },
 
  ];

  export const INTERVAL_OPTIONS = [
    { value: "", label: "Select the number of reports" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
  
  ];
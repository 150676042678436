import SubHeaderItem from "../menu/SubHeaderItem";

const BreadCrumb = ({
  data = [
    { value: "No content", url: "/" },
    { value: "defined", url: "/" },
  ],
}) => {
  /**
   * States
   */

  /**
   * Render
   */
  return (
    <>
      <div className="tw-w-full  tw-h-[56px]">
        <div className="tw-flex tw-items-center tw-py-4 tw-overflow-x-auto tw-whitespace-nowrap">
          {data.map((item, key) => {
            return (
              <div className="tw-flex tw-flex-row tw-items-center" key={key}>
                <SubHeaderItem label={item.value} href={item.url} />

                {data.length - 1 !== key ? (
                  <>
                    <span className="tw-mx-1 tw-text-primary">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="tw-w-5 tw-h-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default BreadCrumb;

// for stripe
import StripeService from './network/services/StripeService';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import "./index.scss";
import './index.css';
import App from "./App";
import {createRoot} from "react-dom/client";
import { SidebarProvider } from './context/SidebarContext';

const container = document.getElementById("root");
const root = createRoot(container);


(async () => {
    const publishableKey = await StripeService.getPublicKey();
    const stripePromise = loadStripe(publishableKey);
    root.render(
        <Elements stripe={stripePromise}>
            <SidebarProvider>
            <App/>
            </SidebarProvider>
        </Elements>
    );
})();

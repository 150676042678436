const routeCourse = [

    {
        path: '/admin',
        name: 'Dashboard',
        icon: 'dashboard',
        section: 1
    },
    {
        path: '/sessions',
        name: 'Courses sessions',
        icon: 'school',
        section: 1
    },
    {
        path: '/admin-courses',
        name: 'Courses',
        icon : 'book',
        section: 1
    },
    {
        path: '/search-user',
        name: 'Transactions',
        icon: 'list',
        section: 1
    },
    {
        path: '/admin-resources',
        name: 'Resources',
        icon : 'attach_file',
        section: 1
    },
    // {
    //     path: '/discounts',
    //     name: "Discounts",
    //     icon: 'discount',
    //     section: 1
    // },
    {
        path: '/configure-login-register',
        name: 'Authentication Setup',
        icon: 'list',
        section: 3
    },
];
export default routeCourse;

import React, { useEffect, useRef, useState } from "react";
import InputCustom from "../form/InputCustom";
import ButtonCustom from "../button/ButtonCustom";
import { getUser, refreshAndGetUser } from "../../../../network/auth/Auth";
import SelectCustom from "../form/SelectCustom";
import UserService from "../../../../network/services/UserService";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import countries from "../../../../constants/countries";

const UserEditProfile = ({ updateData }) => {
  const [state, setState] = useState({
    id: "",
    name: "",
    firstName: "",
    email: "",
    phone: "",
    gender: "",
    address: "",
    country: "",
    region: "",
    city: "",
    zipcode: "",
    password: "",
  });
  const [enableEdit, setEnableEdit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);  
  const [showPassword, setShowPassword] = useState(false);  
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const genders = Object.entries({
    Male: "Male",
    Female: "Female",
  }).map(([key, value]) => {
    if (value === state?.gender) {
      return (
        <option selected key={key} value={value}>
          {value}
        </option>
      );
    } else {
      return (
        <option key={key} value={value}>
          {value}
        </option>
      );
    }
  });

  const countriesOptions = countries.map((value, index) => {
    if (value.value === state?.country) {
      return (
        <option selected key={index} value={value.value}>
          {value.label}
        </option>
      );
    } else {
      return (
        <option key={index} value={value.value}>
          {value.label}
        </option>
      );
    }
  });

  const loadData = () => {
    let user = getUser();
    setState({
      ...state,
      id: user.id,
      name: user.name,
      firstName: user.firstName,
      email: user.email,
      phone: user.phone,
      gender: user.gender ? user.gender : "Male",
      address: user.address,
      country: user.country ? user.country : "United States",
      region: user.region,
      city: user.city,
      zipcode: user.zipcode,
      emailChangeAttemptValue: user.emailChangeAttemptValue,
    });
  };

  const showSuccess = (message) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };

  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const updateUserInformations = () => {
    setLoading(true);
    const data = {
      lastName: state.name,
      firstName: state.firstName,
      email: state.email,
      address: state.address,
      phone: state.phone,
      city: state.city,
      region: state.region,
      zipcode: state.zipcode,
      country: state.country,
      gender: state.gender,
      password: state.password,
    };
    try {
      UserService.updateInformation(state.id, data, (response) => {
        if (!response.success) {
          setVisible(false);
          showError(response.message);
          setLoading(false);
        } else {
          setVisible(false);
          refreshAndGetUser().then((user) => {
            if (user) {
            if(user.emailChangeAttemptValue){
              showSuccess("We have sent a confirmation link to the email you provided. " +
                  "Please confirm your email address in order to update it");
            }else{
              showSuccess("Your informations have been updated successfully");
            }
              loadData();
              updateData();
              setLoading(false);
            }
          });
        }
      });
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const footerContent = (
    <div className="tw-flex tw-flex-row tw-space-x-4 ">
      <ButtonCustom
        label="Close"
        icon={"pi pi-times-circle"}
        onClick={() => setVisible(false)}
        autoFocus
        classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
      />
      {loading ? (
        <ButtonCustom
          classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
          label="Loading..."
          icon={"pi pi-spin pi-spinner"}
          disabled={true}
        />
      ) : (
        <ButtonCustom
          classes={"tw-text-white tw-bg-[#309255] hover:tw-bg-[#055021]"}
          label="Save Changes"
          icon={"pi pi-save"}
          onClick={updateUserInformations}
        />
      )}
    </div>
  );

  return (
    <>
      <Toast ref={toast} position="top-right" />
      <div className="tw-my-3 tw-w-full tw-space-y-3">
        <div>
          <InputCustom
            id={"firstName"}
            name={"firstName"}
            label={"First Name"}
            placeHolder={"First Name"}
            value={state.firstName}
            type={"text"}
            onChange={handleOnChange}
          />
        </div>
        <div>
          <InputCustom
            id={"name"}
            name={"name"}
            label={"Last Name"}
            placeHolder={"Last Name"}
            value={state.name}
            type={"text"}
            onChange={handleOnChange}
          />
        </div>
        <div>
          <InputCustom
            id={"email"}
            name={"email"}
            label={"Email"}
            placeHolder={"Email"}
            value={state.email}
            type={"text"}
            onChange={handleOnChange}
          />
          {state?.emailChangeAttemptValue ? (
            <span className="tw-text-xs">
              Your new email{" "}
              <span
                style={{
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                {state?.emailChangeAttemptValue}{" "}
              </span>
              must be confirmed before it can be used. Please check your email.
            </span>
          ) : (
            ""
          )}
        </div>
        <div>
          <InputCustom
            id={"phone"}
            name={"phone"}
            label={"Cell Phone"}
            placeHolder={"Cell Phone"}
            value={state.phone}
            type={"text"}
            onChange={handleOnChange}
          />
        </div>
        <div>
          <SelectCustom
            label={"Gender"}
            id={"gender"}
            name={"gender"}
            options={genders}
            handleOnChange={handleOnChange}
          />
        </div>
        <div>
          <InputCustom
            id={"address"}
            name={"address"}
            label={"Address"}
            placeHolder={"Address"}
            value={state.address}
            type={"text"}
            onChange={handleOnChange}
          />
        </div>
        <div>
          <SelectCustom
            id={"country"}
            name={"country"}
            label={"Country"}
            options={countriesOptions}
            handleOnChange={handleOnChange}
          />
        </div>
        <div>
          <InputCustom
            id={"city"}
            name={"city"}
            label={"City"}
            placeHolder={"City"}
            value={state.city}
            type={"text"}
            onChange={handleOnChange}
          />
        </div>
        <div>
          <InputCustom
            id={"region"}
            name={"region"}
            label={"Region"}
            placeHolder={"Region"}
            value={state.region}
            type={"text"}
            onChange={handleOnChange}
          />
        </div>
        <div>
          <InputCustom
            id={"zipcode"}
            name={"zipcode"}
            label={"Zip Code"}
            placeHolder={"Zip Code"}
            value={state.zipcode}
            type={"text"}
            onChange={handleOnChange}
          />
        </div>
      </div>
      <div className="tw-sticky tw-bottom-0 tw-z-10 sm:tw-bg-accent-gray -tw-m-2">
        <div className="tw-w-full tw-py-4 sm:tw-px-0 tw-px-4">
          {enableEdit ? (
            <div className="tw-flex tw-flex-row">
              <ButtonCustom
                classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
                label="Update"
                icon={"pi pi-sync"}
                onClick={() => setVisible(!visible)}
              />
            </div>
          ) : (
            <ButtonCustom
              classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
              label="Edit"
              icon={"pi pi-user-edit"}
              onClick={() => setEnableEdit(!enableEdit)}
            />
          )}
        </div>
      </div>
      <div>
        <Dialog
          header="Confirmation"
          visible={visible}
          onHide={() => setVisible(false)}
          style={{ width: "28vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
          footer={footerContent}
        >
          <div>
            <InputCustom
              id={"password"}
              name={"password"}
              label={"Enter your password to validate your information"}
              placeHolder={"Password"}
              type={"password"}
              onChange={handleOnChange}
              showPassword={showPassword}
              handleShowPassword={handleShowPassword}
            />
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default UserEditProfile;

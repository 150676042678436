import React, { useEffect, useState } from "react";
import SessionService from "../../network/services/SessionService";
import AppLayout from "../../layouts/AppLayout";
import AppHeader from "../../components/frontv2.0/common/header/AppHeader";
import Loader from "../../components/frontv2.0/common/loader/Loader";
import SessionDetails from "../../components/frontv2.0/common/sessions/SessionDetails";
import SubHeaderOffLine from "../../components/frontv2.0/common/header/SubHeaderOffLine";
import { useParams } from "react-router";

const OpenedUnsecuredSessionsDetails = () => {
  const params = useParams()
  /**
   * States
   */
  const [currentSession, setCurrentSession] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Render
   */
  const getId = () => {
    let data = {
      session_id: parseInt(params.sessionId),
    };
    //this.setState({isLoading: true})
    SessionService.getOneUnsecuredSessionById(data, (response) => {
      if (response) {
        setCurrentSession(response.session);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getId();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <AppLayout>
      <AppLayout.Content>
        {isLoading ? (
          <div className="tw-flex tw-flex-col md:tw-justify-center tw-justify-center tw-items-center tw-py-16">
            <Loader />
          </div>
        ) : (
          <SessionDetails
            data={currentSession}
            checkoutLink="/open-sessions/details/checkout"
          />
        )}
      </AppLayout.Content>

      <AppLayout.Header>
        <AppHeader />
      </AppLayout.Header>

      <AppLayout.SubHeader>
        <SubHeaderOffLine
          data={[
            { value: "Home", url: "/" },
            {
              value: "Opened Sessions",
              url: "/open-sessions",
            },
            { value: currentSession?.name, url: "#" },
          ]}
        />
      </AppLayout.SubHeader>
    </AppLayout>
  );
};

export default OpenedUnsecuredSessionsDetails;

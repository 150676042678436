import React from "react";
import ButtonCustom from "../button/ButtonCustom";
import {IMAGE_2} from "../../../../constants/images";

const CardSmall = ({data, onClikAction}) => {
    return (
        <>
            <div
                className="tw-w-full tw-shadow-md tw-max-w-[358px] tw-overflow-tw-hidden hover:tw-bg-primary-200 tw-rounded-[12px] tw-bg-accent-gray">
                <img
                    className="tw-shadow-sm tw-object-cover tw-w-full tw-h-[221px] tw-rounded-[12px]"
                    src={`${IMAGE_2}`}
                    alt="Article"
                />

                <div
                    className="tw-flex tw-justify-between tw-items-center tw-px-[21px] tw-pb-[31px] tw-pt-[21px] tw-text-primary tw-w-full">
                    <div className="tw-flex tw-flex-col tw-text-sm">
                        <span className="tw-font-bold">March Session</span>
                        <span>Devops Expert Training</span>
                    </div>
                    <div className="tw-flex justify-end tw-text-green-600 tw-text-[16px]">
                        <div>
                            <p>
                                <span className="tw-mr-[6px] pi pi-lock-open"></span>
                                <span>Opened</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="tw-px-[21px] tw-pt-[27px] tw-text-primary">
                    <div className="tw-flex tw-items-center">
                        <span className="tw-mr-[6px] pi pi-calendar tw-text-green-700"></span>
                        <span>Start Date : 2023 March, 23</span>
                    </div>

                    <div className="tw-flex tw-items-center">
                        <span className="tw-mr-[6px] pi pi-calendar tw-text-primary"></span>
                        <span>End Date : 2023 June, 23</span>
                    </div>
                </div>
                <div className="tw-w-full tw-px-[15px] tw-pt-[11px]">
                    <ButtonCustom
                        onClick={onClikAction}
                        classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
                        label="Subscribe"
                    />
                </div>
            </div>
        </>
    );
};

export default CardSmall;

import AppLayout from "../../layouts/AppLayout";
import AppHeader from "../../components/frontv2.0/common/header/AppHeader";
import SubHeaderOffLine from "../../components/frontv2.0/common/header/SubHeaderOffLine";
import {useNavigate} from "react-router-dom";
import {OUPS_ILLUSTRATION} from "../../constants/images";
import ButtonCustom from "../../components/frontv2.0/common/button/ButtonCustom";

const PageStatusAlreadySubscription = () => {

    /**
     * States
     */
    const navigate = useNavigate();

    // eslint-disable-next-line
    const handleOnClickItem = () => {
        return navigate('/')
    };

    /**
     * Render
     */
    return (
        <AppLayout>
            <AppLayout.Content>
                <div
                    className="tw-flex tw-flex-col-reverse md:tw-flex-row tw-justify-between tw-items-center tw-h-full tw-px-8 tw-py-8 md:tw-py-0 md:tw-px-40"
                    style={{height: 'calc(100vh - 22rem)'}}>
                    <div className="tw-flex tw-flex-col tw-space-y-10 tw-text-primary tw-items-center md:items-start">
                        <p className="md:tw-w-2/3 tw-text-lg tw-font-medium tw-text-center md:tw-text-left"> You have
                            already subscribed to this course. </p>
                        <ButtonCustom
                            label="Go Back"
                            onClick={handleOnClickItem}
                            classes="tw-bg-primary hover:tw-bg-primary-900 tw-text-white tw-max-w-[237px] tw-max-h-[44px]"
                        />
                    </div>
                    <div className="tw-py-6 md:tw-mb-0">
                        <img className="w-[350px]" src={OUPS_ILLUSTRATION} alt="Already Subscribed"/>
                    </div>
                </div>
            </AppLayout.Content>

            <AppLayout.Header>
                <AppHeader/>
            </AppLayout.Header>

            <AppLayout.SubHeader>
                <SubHeaderOffLine data={[{value: 'Home', url: '/'}, {value: 'Already Subscribed', url: '#'}]}/>
            </AppLayout.SubHeader>
        </AppLayout>
    );
};

export default PageStatusAlreadySubscription;

import React from "react";

const StatsAmount = ({ status = "done", amount, totalInstallment }) => {
  // Status (done, all, remain)
  return (
    <>
      <div
        className={`
        ${status === "all" ? "tw-bg-primary tw-text-white " : ""}
            ${status === "done" ? " tw-bg-accent-blue tw-text-primary" : ""}
            ${status === "remain" ? "tw-bg-accent-gray tw-text-primary " : ""}
            tw-p-3 tw-rounded-lg tw-w-full`}
      >
        <p className=" tw-text-xl">
          {status === "all" && "Total price"}
          {status === "done" && "Payment Done"}
          {status === "remain" && "Remaining payment"}
        </p>
        <p className="tw-mb-2 tw-text-2xl tw-font-bold">
          {amount?.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </p>
        <p className=" tw-text-sm">
          {" "}
          {status === "all" && "Total Installment"}
          {status === "done" && "Installment Done"}
          {status === "remain" && "Installment Remaining"}
          {" "}
          <span className="tw-font-bold">{totalInstallment}</span>
        </p>
      </div>
    </>
  );
};

export default StatsAmount;

//Autor : FOKA CYRIAQUE
//Description of allowing a user to postpone

import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../../../../layouts/MainLayout";
import Header from "../../../../components/frontv2.0/common/header/Header";
import CardXLarge from "../../../../components/frontv2.0/common/card/CardXLarge";
import SubHeader from "../../../../components/frontv2.0/common/header/SubHeader";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { getUser } from "../../../../network/auth/Auth";
import SessionService from "../../../../network/services/SessionService";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from "moment/moment";
import { Toast } from "primereact/toast";
import ButtonCustom from "../../../../components/frontv2.0/common/button/ButtonCustom";
import './style.css';


const AlreadyTwoReports = () => {
  /**
   * States
   */
  const [isLoading, setIsLoading] = useState(true);
  const [sessionsData, setSessionsData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [subscriptionId, setsubscriptionId] = useState("");
  const [postponePaymentDate, setpostponePaymentDate] = useState("");
  const [visible, setVisible] = useState(false);
  const [session, setSession] = useState('');
  const [newDate, setNewDate] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [numberReport, setNumberReport] = useState("");
  const [message, setMessage] = useState("");
  const [responseBoole, setResponseBoole] = useState("");
  const [lengthUserInformation, setLengthUserInformation] = useState("");
  const [condition, setCondition] = useState("");
  const [nameSession, setNameSession] = useState("");
  const [firstPostponed, setFirstPostponed] = useState("");
  const [second_postponement_date, setSecond_postponement_date] = useState("");
  const [nextInstallmentDate, setNextInstallmentDate] = useState(null)
  const [sessionEndDate, setSessionEndDate] = useState("")
  const [minDate, setMinDate] = useState('');
  const [maxDate, setMaxDate] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  let [nextSessionsData, setNextSessionsData] = useState([]);


  const moment = require("moment");

  const futureDate = [
    { name: nameSession, category: "Done", date: moment(firstPostponed).format("MMMM DD, YYYY") },
    { name: nameSession, category: "Done", date: moment(second_postponement_date).utc().format("MMMM DD, YYYY") },
  ];


  const categoryBodyTemplate = (rowData) => {
    return <span style={{ color: 'green', fontWeight: "bold" }}>{rowData?.category}</span>;
  };

  const renderRows = () => {
    if (numberReport === 1) {
      return futureDate.slice(0, 1);
    } else if (numberReport === 2) {
      return futureDate;
    }
  }



  const getAllSessionsSubscribed = (id) => {
    setIsLoading(true);
    SessionService.getUserSessions(id, (data) => {
      if (data && data.success) {
        console.log(data);
        setSessionsData(data.data);
        setNextInstallmentDate(data.data[0].next_installment_date)
        console.log(data.data[0].session_end_date)
        console.log(data.data[0].next_installment_date)
        setSessionEndDate(data.data[0].session_end_date)
        setIsDataLoaded(true);
      }
      setIsLoading(false);
    });
  };


  const loadData = () => {
    let user = getUser();
    getAllSessionsSubscribed(user.id);
  };

  const AllUserHistory = () => {

    setIsLoading(true);
    SessionService.getUserHistoryReport((response) => {
      if (response) {
        setNumberReport(response.data.length)
        console.log(response)
        console.log(response.data.length)
        setNameSession(response.data[0].session_name)
        setFirstPostponed(response.data[0].postponement_date)
        setSecond_postponement_date(response.data[1].postponement_date)

      } else {
        console.log(response)

        setLengthUserInformation(response);
      }
      setIsLoading(false);
    });
  };



  const handleButtonClick = () => {
    setVisible(!visible);
  };



  const handleSessionChange = (event) => {
    const value = event.target.value;
    const selectedOption = event.target;
    const selectedOptionId = selectedOption.options[selectedOption.selectedIndex].dataset.optionId;
    console.log(selectedOptionId);
    setsubscriptionId(selectedOptionId);
    setSession(value);
    setIsSubmitDisabled(value === '' || newDate === '');
  };

  const handleNewDateChange = (event) => {
    setNewDate(event.target.value);
    console.log(newDate);
    const value = event.target.value;
    setpostponePaymentDate(value);
    setIsSubmitDisabled(session === '' || value === ''); // Vérifie si l'un des champs est vide
  }

  const handleClose = () => {
    setShowModal(false);
    setShowSuccessMessage(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Empêche le rechargement de la page
    if (session === '' || newDate === '') {
      setFormSubmitted(true);
      setIsSubmitDisabled(true);
      return;
    }

    // Affiche les valeurs des états locaux dans la console
    console.log("Session:", session);
    console.log("New Date:", newDate);


    localStorage.setItem('showSuccessMessage', JSON.stringify(true));
    setSession('');
    setVisible(false);
    // setFormSubmitted(true);
    // setIsSubmitDisabled(true);
  };


  const handleCancelClick = (event) => {
    event.preventDefault(); // Empêche le rechargement de la page
    setVisible(false); // Ferme la fenêtre de dialogue
  };


  const handleOnClickItem = () => {
    //
  };



  useEffect(() => {
    AllUserHistory();
    getAllSessionsSubscribed();
    loadData();

    const today = new Date();
    if (isDataLoaded && nextInstallmentDate) {
      const nextInstallmentDateObj = new Date(nextInstallmentDate);
      const sessionEndDateObj = new Date(sessionEndDate);

      console.log(nextInstallmentDate);
      console.log(sessionEndDate);


      const minDate = new Date(nextInstallmentDateObj);
      minDate.setDate(minDate.getDate() + 1);

      const maxDate = new Date(minDate);
      maxDate.setMonth(maxDate.getMonth() + 1);
      maxDate.setDate(maxDate.getDate() - 1);

      setMinDate(minDate.toISOString().split('T')[0]);
      setMaxDate(maxDate.toISOString().split('T')[0]);
    }
  }, [nextInstallmentDate, sessionEndDate]);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };


  window.addEventListener('DOMContentLoaded', function() {
    // Sélectionner le bouton par son ID ou sa classe
    const button = document.getElementById('button-view');
    
    button.disabled = true;
  });
  
  return (
    <>


      <div style={{ display: 'flex' }}>
        <div className="tw-w-2/3" style={{ marginTop: "2%", marginLeft: "1%" }}>
          <h1 className="tw-font-bold tw-text-bg-primary">
            Summary of your Deferred payment date
          </h1>
        </div>
        <div className="tw-w-2/3 tw-border tw-rounded-full tw-border-red-500" style={{ marginTop: "1%", padding: "0.4rem", color: "red", fontSize: "0.9rem", height: "2.3rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <p className="message">You've reached your maximum two attempts</p>
        </div>
        <div className="tw-w-1/3" style={{ marginTop: "1%", marginLeft: "10%" }}>
          <ButtonCustom
           id="button-view"
            onClick={() => {
              setVisible(true);
            }}
            classes="tw-bg-gray-400 tw-text-white sm:tw-max-w-[137px] tw-max-h-[44px]"
            label="New request"
            disabled={true}
          />
        </div>
      </div>
      <div className="tw-flex tw-flex-col" style={{ marginTop: "7%" }}>

        <div className="tw-flex tw-flex-col">
          <div className="tw-grid tw-grid-cols-0 tw-gap-2" style={{ marginLeft: "1%", marginBottom: "0%" }}>
            <DataTable value={renderRows()} tableStyle={{ minWidth: '10rem' }}>
              <Column field="name" header="Session"></Column>
              <Column field="date" header="Postponement requested"></Column>
              <Column field="category" header="Status" body={categoryBodyTemplate}></Column>
            </DataTable>
          </div>
        </div>
      </div>


      <MainLayout.Header>
        <Header />
      </MainLayout.Header>

      <MainLayout.SubHeader>
        <SubHeader
          data={[
            { value: "Home", url: "/workspace" },
            {
              value: "Ask Delay",
              url: "#",
            },
          ]}
        />
      </MainLayout.SubHeader>
    </>
  );
};

export default AlreadyTwoReports;


import React, {Component} from "react";
import TopBar from "./TopBar";
import LinkBar from "./LinkBar";

class Navbar extends Component {
    render() {
        return (
            <>
                <div className="header-section">

                    <TopBar/>

                    <LinkBar/>
                    <br></br>
                </div>

            </>
        )
    }
}

export default Navbar;
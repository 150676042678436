import * as React from "react";
import {forwardRef, useImperativeHandle} from "react";
import {MultiSelect} from "@progress/kendo-react-dropdowns";

const MultiSelectItem = forwardRef((props, ref) => {
    const sports = ['User', 'Email', 'Cell phone', 'Subscription reference', 'Expected to pay', 'Subscription price', 'Total paid', 'Remaining to pay', 'Course access', 'Access type', 'Payment date'];
    const [allowCustom, setAllowCustom] = React.useState(false);
    const [categoryIds, setCategoryIds] = React.useState([]);

    const onChange = event => {
        setAllowCustom(event.target.checked);
    };

    const [value, setValue] = React.useState([]);
    const onchange = (event) => {
        setValue([...event.value]);

        // props.parentCallback(value);
    };

    useImperativeHandle(ref, () => ({
        getValue:() =>{
            return value;
        }
    }),[value])

    return (
        <div className="example-wrapper">
            <div>
                <MultiSelect
                    data={sports}
                    onChange={onchange}
                    value={value}
                    style={{
                        width: "300px",
                    }}
                    placeholder="Please select fields to export"
                />
            </div>
        </div>
    )
});
export default MultiSelectItem;

import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonCustom from "../../../components/frontv2.0/common/button/ButtonCustom";
import InputCustom from "../../../components/frontv2.0/common/form/InputCustom";
import Illustration from "../../../components/frontv2.0/common/illustration/Illustration";
import Logo from "../../../components/frontv2.0/common/logo/Logo";
import TextHeader from "../../../components/frontv2.0/common/text/TextHeader";
import Alert from "../../../components/frontv2.0/common/alert/Alert";
import AuthLayout from "../../../layouts/AuthLayout";
import { FAVICON_WITHE, IMAGE_1 } from "../../../constants/images";
import UserService from "../../../network/services/UserService";
import {isAuthenticated, redirectionUrl, signIn} from "../../../network/auth/Auth";

const Admin2FA = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [callSuccess, setCallSuccess] = useState(false);
  const [callAttempt, setCallAttempt] = useState(false);
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/workspace/mysessions");
    }
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");
    const secretKey = queryParams.get("srt");

    if (email && email !== "" && secretKey && secretKey !== "") {
      setEmail(email);
      setSecretKey(secretKey);
    } else {
      navigate("/login");
    }
  }, [navigate, location.search]);

  const verifyAdminOtp = () => {
    setIsLoading(true);

    UserService.checkAdminOtp(
      { otp: code, email: email, srt: secretKey },
      (data) => {
        let message = "";

        if (data !== null) {
          message = data.message;

          if (data.success) {
            setCallSuccess(true);
            setIsLoading(false);
            setDisabled(true);
            signIn(data);
            let redirectUrl = redirectionUrl(data.role);
            navigate(redirectUrl);
          } else {
            setCallSuccess(false);
            setIsLoading(false);
          }
        } else {
          setCallSuccess(false);
          setIsLoading(false);
          message = "Error: Unable to connect.";
        }

        setMessage(message);
        setCallAttempt(true);
        setIsLoading(false);
      },
      (err) => {
        // console.log(err);
      }
    );
  };

  return (
    <AuthLayout>
      <AuthLayout.Body>
        <div className="tw-flex tw-flex-col tw-justify-center tw-px-6 tw-py-10 lg:tw-px-8 ">
          <div className="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-sm">
            <Logo width={200} />
          </div>
          <div className="tw-mt-10 sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-sm">
            <div className="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-sm">
              <div className="tw-w-full tw-mt-24">
                <TextHeader
                  label={"Login"}
                  textUnderline={"Security"}
                  size={4}
                />
                <p className="tw-mt-2 tw-mb-8 tw-text-gray-500 tw-text-[16px]">
                Enter the code sent to your email address.
                </p>
              </div>
            </div>
            {callAttempt && (
              <Alert
                type={callSuccess ? "success" : "danger"}
                message={message}
              />
            )}
            <form>
              <div className="tw-mt-[22px]">
                <InputCustom
                  name={"code_verification"}
                  id={"code_verification"}
                  onChange={(e) => setCode(e.target.value)}
                  value={code}
                  type={"text"}
                  placeHolder={"Verify code"}
                />
              </div>

              <div className="tw-mt-[42px]">
                <ButtonCustom
                  classes={
                    "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                  }
                  label={isLoading ? "Loading..." : "Confirm"}
                  icon={isLoading ? "pi pi-spin pi-spinner" : ""}
                  disabled={isLoading || disabled}
                  onClick={verifyAdminOtp}
                />
              </div>
            </form>
          </div>
        </div>
      </AuthLayout.Body>

      <AuthLayout.Illustration>
        <Illustration
          favIcon={FAVICON_WITHE}
          image={IMAGE_1}
          label={`"Linux, AWS and DevOps Training."`}
        />
      </AuthLayout.Illustration>
    </AuthLayout>
  );
};

export default Admin2FA;

import React, { useRef, useState } from "react";
import Avatar from "../avatar/Avatar";
import ButtonCustom from "../button/ButtonCustom";
import {
  USER_AVATAR_FEMALE,
  USER_AVATAR_MALE,
} from "../../../../constants/images";
import UserService from "../../../../network/services/UserService";
import { refreshAndGetUser } from "../../../../network/auth/Auth";
import { Toast } from "primereact/toast";
import { MEDIA_URL } from "../../../../network/urls/backendUrls";

const UserProfile = ({ userInfos, loadData }) => {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);

  const toast = useRef(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setPreview(URL.createObjectURL(selectedFile));
  };

  const notification = (message, status) => {
    toast.current.show({
      severity: status ? "success" : "error",
      summary: status ? "Success" : "Error",
      detail: message,
      life: 3000,
    });
  };

  const updateAvatar = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file, preview);
    try {
      UserService.avatar(formData, (response) => {
        if (response) {
          if (!response.success) {
            notification(response.message, false);
            setLoading(false);
            setPreview(null);
            setFile(null);
            return;
          }
          refreshAndGetUser().then(() => {
            notification(
              "Your profile picture has been successfully updated",
              true
            );
            setPreview(null);
            setFile(null);
            loadData();
            setLoading(false);
          });
        } else {
          notification("An error has occurred, please try again later", false);
          setPreview(null);
          setFile(null);
          setLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Toast ref={toast} position="top-right" />
      <div className="tw-flex tw-justify-between tw-items-center">
        <div className="tw-flex tw-space-x-4 tw-items-center">
          <Avatar
            classes={"sm:tw-h-[90px] sm:tw-w-[90px] tw-h-[70px] tw-w-[70px] "}
            alt={"avatar"}
            src={
              preview
                ? preview
                : `${
                    userInfos?.profilePicture
                      ? MEDIA_URL + userInfos.profilePicture
                      : userInfos?.gender && userInfos.gender === "F"
                      ? USER_AVATAR_FEMALE
                      : USER_AVATAR_MALE
                  }`
            }
          />
          <div className="tw-flex tw-flex-col tw-space-y-[2px]">
            <span className="sm:tw-text-xl tw-text-sm tw-font-bold">
              {userInfos?.name ? userInfos.name : ""}{" "}
              {userInfos?.firstName ? userInfos.firstName : ""}
            </span>
            <span className="tw-text-xs sm:tw-text-md">
              {userInfos?.email ? userInfos.email : ""}
            </span>
            <span className="tw-text-xs sm:tw-text-md tw-hidden sm:tw-block">
              {userInfos?.phone ? userInfos.phone : ""}
            </span>
            {preview ? (
              <div className="lg:tw-hidden tw-flex tw-flex-row tw-justify-center tw-space-x-2 ">
                <ButtonCustom
                  classes={
                    "tw-bg-white hover:tw-bg-red-100 tw-text-red-400 tw-border tw-border-red-400 tw-text-[11px] sm:tw-text-sm tw-flex tw-items-center tw-justify-center"
                  }
                  label="Cancel"
                  onClick={() => {
                    setPreview(null);
                    setFile(null);
                  }}
                />
                {loading ? (
                  <ButtonCustom
                    classes={
                      "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                    }
                    label="Loading..."
                    icon={"pi pi-spin pi-spinner"}
                    disabled={true}
                  />
                ) : (
                  <ButtonCustom
                    classes={
                      "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                    }
                    label="Save"
                    icon={"pi pi-save"}
                    onClick={updateAvatar}
                  />
                )}
              </div>
            ) : (
              <div className="tw-relative">
                <label
                  for="file-input"
                  className="tw-flex tw-items-center lg:tw-hidden tw-text-xs "
                >
                  <i
                    className="pi pi-camera tw-mr-1 tw-underline"
                    style={{ fontSize: "0.9rem" }}
                  ></i>
                  Change photo
                </label>
                <input
                  id="file-input"
                  name="file-input"
                  type="file"
                  className="tw-sr-only"
                  onChange={handleFileChange}
                />
              </div>
            )}
          </div>
        </div>
        <div className="tw-hidden lg:tw-block">
          {preview ? (
            <div className="tw-flex tw-flex-row tw-justify-center tw-space-x-2">
              <ButtonCustom
                classes={
                  "tw-bg-white hover:tw-bg-red-100 tw-text-red-400 tw-border tw-border-red-400 tw-text-[11px] sm:tw-text-sm tw-flex tw-items-center tw-justify-center"
                }
                label="Cancel"
                onClick={() => {
                  setPreview(null);
                  setFile(null);
                }}
              />
              {loading ? (
                <ButtonCustom
                  classes={
                    "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                  }
                  label="Loading..."
                  icon={"pi pi-spin pi-spinner"}
                  disabled={true}
                />
              ) : (
                <ButtonCustom
                  classes={
                    "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                  }
                  label="Save"
                  icon={"pi pi-save"}
                  onClick={updateAvatar}
                />
              )}
            </div>
          ) : (
            <div className="tw-relative">
              <label
                for="file-input"
                class="tw-cursor-pointer tw-space-x-1 tw-justify-center tw-bg-white hover:tw-bg-primary-100 tw-text-primary tw-border tw-border-primary tw-text-[11px] tw-px-3 tw-py-2 tw-rounded-[9px] tw-flex tw-items-center"
              >
                <i className="mr-2 pi pi-camera"></i>
                <span className="tw-truncate">Change photo</span>
              </label>
              <input
                id="file-input"
                name="file-input"
                type="file"
                className="tw-sr-only"
                onChange={handleFileChange}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserProfile;

import React, {Component} from "react";
import Footer from "../footer/Footer";
import {Link, useNavigate} from "react-router-dom";
import Navbar from "../header/Navbar";
import '../common/courseDetails.css';
import PageBanner from "../common/PageBanner";

class CourseDetailsWordpress extends Component {

    constructor(props) {
        super(props);

    }


    render() {

        return (
            <>


                <div>


                    <div className="main-wrapper">
                        <Navbar/>
                        <PageBanner/>

                        <div className="blocpaysuc">
                            <br></br><br></br><br></br>
                            <div className="container divcenter">

                                <div class="row align-items-center">

                                    <div class="p-1">
                                        <br></br>
                                        <p><i class="fa fa-exclamation-triangle fa-5x text-warning"></i><br/></p>
                                        <br></br>
                                        {/*  <h5 class="sub-title">This page is not accessible you are not authorized.</h5> */}
                                        <h6 class="main-title" style={{fontSize: "1.3em"}}>
                                            You cannot access this page, your payments <br></br>are incomplete.
                                            <br></br>Check your transactions by clicking on the <br></br>following
                                            button.
                                        </h6>
                                        {/* <p>follow this link to check your transactions </p> */}
                                        <br></br>
                                        <Link to="/subscription-to-pay"
                                              className="btn btn-primary align-center center ">check transactions</Link>

                                    </div>


                                </div>

                            </div>


                        </div>
                        <br></br>
                    </div>

                </div>


                <Footer/>
            </>
        )
    }
}

export default function (props) {
    const navigation = useNavigate();
    //const {state} = useLocation();
    return <CourseDetailsWordpress {...props} myNavigate={navigation}/>;
}

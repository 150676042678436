import React, { useEffect, useState } from "react";
import {useMediaQuery} from "react-responsive";
import {useLocation, useNavigate} from "react-router-dom";
import {TabMenu} from "primereact/tabmenu";
import Sidebar from "../sidebar/Sidebar";
import AdminHeader from "../header/AdminHeader";
import EditLogin from "./EditLogin";
import EditRegister from "./EditRegister";

const AuthSetup = () => {
    const isDesktop = useMediaQuery({ minWidth: 1024 });
    const location = useLocation();
    const [activeIndex, setActiveIndex] = useState(isDesktop ? 1 : 0);
    const navigate = useNavigate();

    const onTabChange = (e) => {
        setActiveIndex(e.index);
        navigate("/configure-login-register?tab=" + e.index);
    };

    const items = [
        { label: "Register configurations ", icon: "pi pi-fw pi-user" },
        { label: "Login configurations", icon: "pi pi-fw pi-lock" }
    ];

    const content = [
        <div key="tab1" className="sm:tw-px-8 tw-py-2 tw-px-[3px]">
            <EditRegister />
        </div>,
        <div key="tab2" className="sm:tw-px-8 tw-py-2 tw-px-[3px]">
            <EditLogin />
        </div>
    ];

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        let current = queryParams.get("tab");
        if (current) {
            setActiveIndex(parseInt(current));
        }
    }, [location.search]);

    return (
        <>
            <Sidebar/>
            <main className="mainAdmin r ms-100">
                <AdminHeader location="AuthSetup"/>
                <TabMenu
                    baseZIndex={0}
                    className="tw-rounded-t-md tw-text-xs md:tw-text-sm tw-w-full tw-z-10"
                    model={items}
                    activeIndex={activeIndex}
                    onTabChange={onTabChange}
                />
                <div className="tab-content">
                    {
                        content[
                            activeIndex
                            ]
                    }
                </div>
            </main>
        </>
    );
};

export default AuthSetup;

import React, {Component} from "react";
import Sidebar from "../sidebar/Sidebar";
import AdminFooter from "../footer/AdminFooter";
import SessionService from "../../../network/services/SessionService";
import moment from "moment";
import ReactPaginate from "react-paginate";
import AdminHeader from "../header/AdminHeader";

class SubscriptionIntentDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sessionId: '',
            sessions: '',
            users_sessions: [],
            users_subscription: [],
            selectedVal: "",
            response: '',
            elementPerPage: 10,
            currentPage: '',
            lastPage: '',
            userDetails: '',
            isLoading: false,
            requestLoading: false,
            clickLoading: false,
            disabledExportation: false,
            searchValue: '',
            researchIndicator: '',
            session: '',
            subscriptionToShow: ''
        }
    }


    /*This function is used to parse data in the format accept by the excel file generator*/
    transformData = (data) => {
        const tabData = [];
        data.forEach(element => {
            let details = {
                name: element.user.name,
                email: element.user.email,
                price: element.subscriptionPrice,
                paid: element.userActualPaidAmount,
                expected: element.userExpectedPaidAmount,
                remaining: element.subscriptionPrice - element.userActualPaidAmount
            }
            tabData.push(details);
        })
        return tabData;
    }

    handlePageClick = (data) => {
        this.setState({clickLoading: true, disabledExportation: true});
        const requestedPage = data.selected + 1;

        const queryParams = new URLSearchParams(window.location.search);
        let id = queryParams.get('sessionId');
        this.setState({sessionId: id});
        let toSend = {
            pageNumber: parseInt(requestedPage),
            session_id: parseInt(id),
            search: this.state.researchIndicator
        }

        SessionService.getAllSubscriptionIntent(toSend, (response) => {
            if (response) {
                if ((response.subscriptionIntent).length > 0) {
                    this.setState({
                        response: response,
                        currentPage: requestedPage,
                        users_sessions: response.subscriptionIntent,
                        session: response.subscriptionIntent[0].session,
                        lastPage: Math.ceil(response.total_items_count / response.items_per_page),
                    });
                } else {
                    this.setState({
                        response: response,
                        lastPage: response.page_actual,
                    });
                }
            }
            this.setState({clickLoading: false, disabledExportation: false})
        });
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        let id = queryParams.get('sessionId');

        this.setState({sessionId: id, isLoading: true});

        let data = {
            page: '',
            number_per_page: '',
            subscription_plan: '',
            sessionId: parseInt(id),
        }
        this.showSubscriptionIntents();
    }

    showSubscriptionIntents = () => {
        const queryParams = new URLSearchParams(window.location.search);
        let id = queryParams.get('sessionId');
        let data = {
            pageNumber: 1,
            session_id: parseInt(id),
        }
        this.setState({isLoading: true});

        SessionService.getAllSubscriptionIntent(data, (response) => {
            if (response) {
                if ((response.subscriptionIntent).length > 0) {
                    this.setState({
                        response: response,
                        users_sessions: response.subscriptionIntent,
                        session: response.subscriptionIntent[0].session,
                        lastPage: Math.ceil(response.total_items_count / response.items_per_page),
                    });
                } else {
                    this.setState({
                        response: response,
                        lastPage: response.page_actual,
                    });
                }
                this.setState({isLoading: false});
            }
        });

    }
    handleChange = (event) => {
        const queryParams = new URLSearchParams(window.location.search);
        //console.log(event.target.value.trim().length)
        this.setState({researchIndicator: event.target.value, isLoading: true});
        let id = queryParams.get('sessionId');

        let toSend = {
            pageNumber: 1,
            session_id: parseInt(id),
            search: event.target.value,
        }
        if (event.target.value.trim().length !== 0) {
            SessionService.getAllSubscriptionIntent(toSend, (response) => {
                if (response) {
                    if ((response.subscriptionIntent).length > 0) {
                        this.setState({
                            response: response,
                            users_sessions: response.subscriptionIntent,
                            session: response.subscriptionIntent[0].session,
                            lastPage: Math.ceil(response.total_items_count / response.items_per_page),
                        });
                    } else {
                        this.setState({
                            users_sessions: response.subscriptionIntent,
                            response: response,
                            lastPage: response.page_actual,
                        });
                    }
                }
            })
        } else {
            this.showSubscriptionIntents();
        }
        this.setState({isLoading: false});

        // UserService.getAllUsers(data, (response) => {
        //     console.log(response);
        //     this.setState({
        //         users_sessions: response.pageUsers,
        //         currentPage: response.pageNumber,
        //         //elementPerPage: response.numberOfUsers,
        //         totalUsers: response.totalNumberOfUsers,
        //         //  lastPage: Math.ceil(response.totalNumberOfUsers / response.pageNumberOfUsers),
        //         lastPage: Math.ceil(response.numberOfPages),
        //         isLoading: false
        //     });
        // });

    }

    render() {
        return (
            <div>
                <>
                    <Sidebar/>
                    <main className="mainAdmin r ms-100">
                        <AdminHeader location="Subscription intent"/>

                        <div className="container-fluid py-4">
                            <div className="row">
                                <div className="col-lg-12 col-md-10 mx-auto">

                                    <div className="card ">
                                        <div className="card-header p-3">
                                            <h5 className="mb-0">All subscription intent
                                                of {this.state.session.name} session </h5>
                                            <br/>

                                            <div className="form-group has-search">
                                                <form>
                                                    <span className="fa fa-search form-control-feedback"></span>
                                                    <input type="text" className="form-control" placeholder="Search"
                                                           value={this.state.researchIndicator}
                                                           name="search"
                                                           onChange={(event) => this.handleChange(event)}/>
                                                </form>
                                            </div>

                                        </div>
                                        <div className="card-body p-3">
                                            {/*<select className="form-select" aria-label="Default select example"*/}
                                            {/*        onChange={this.handleChange}>*/}
                                            {/*    <option selected>All user</option>*/}
                                            {/*    <option value={"having_paid"}>Users having paid everything</option>*/}
                                            {/*    <option value={"not_having_paid"}>Users not having paid everything*/}
                                            {/*    </option>*/}

                                            {/*</select>*/}
                                            <div className="table-responsive p-0">
                                                <table className="table align-items-center mb-0">

                                                    <thead>
                                                    <tr>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">User</th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Session
                                                        </th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Price
                                                        </th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Payment
                                                            amount
                                                        </th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Status
                                                        </th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Charge
                                                            failure code
                                                        </th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Charge
                                                            Failure Message
                                                        </th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Payment
                                                            date
                                                        </th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">...
                                                        </th>

                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        this.state.isLoading ? (
                                                                <div className="spinner-border text-primary" role="status">
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>

                                                            ) :
                                                            null
                                                    }
                                                    {/*{*/}
                                                    {/*    this.state.clickLoading ?*/}
                                                    {/*        <div className="spinner-border text-primary" role="status">*/}
                                                    {/*            <span className="sr-only">Loading...</span>*/}
                                                    {/*        </div> : null*/}
                                                    {/*}*/}
                                                    {this.state.users_sessions && this.state.users_sessions.map((users_session) => (
                                                        <tr>
                                                            <td>
                                                                <div className="d-flex px-2 py-1">

                                                                    <div
                                                                        className="d-flex flex-column justify-content-center">
                                                                        <h6 className="mb-0 text-sm">{users_session.userEmail}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <p className="text-xs font-weight-bold ">{this.state.session.name}</p>
                                                            </td>
                                                            <td>
                                                                <p className="text-xs font-weight-bold mb-0">{'$' + users_session.price}</p>
                                                            </td>
                                                            <td>
                                                                <p className="text-xs font-weight-bold mb-0">{'$' + users_session.payment_amount}</p>
                                                            </td>
                                                            <td>

                                                                <p className="text-xs font-weight-bold mb-0">
                                                                    {users_session.complete === true ? <span
                                                                            className="badge badge-success"> success
                                                                    </span> :
                                                                        <span
                                                                            className="badge badge-pill badge-danger">failed</span>}
                                                                </p>
                                                            </td>
                                                            <td>{users_session.stripeChargeFailureCode}</td>
                                                            <td>{users_session.stripeChargeFailureMessage}</td>
                                                            <td>
                                                                <p className="text-xs font-weight-bold mb-0">
                                                                    {moment(users_session.created_at).utc().format('DD-MM-YYYY')}</p>

                                                            </td>
                                                            <td>
                                                                <a
                                                                    href="javascript:;"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#exampleModal"
                                                                >
                                                                    <button type="button"
                                                                            className="btn btn-secondary"
                                                                            onClick={() => this.setState({subscriptionToShow: users_session})}
                                                                    >more details
                                                                    </button>
                                                                </a>
                                                            </td>
                                                            {/* <td className="align-middle text-center text-sm">
                                                                <p className="text-xs font-weight-bold mb-0">{users_sessions.price} $</p>
                                                            </td>

                                                            <td className="align-middle text-center">
                                                                <span
                                                                    className="badge badge-sm bg-gradient-success">Pay in {users_sessions.number_installment} Installment</span>
                                                            </td>*/}


                                                        </tr>
                                                    ))}
                                                    {
                                                        this.state.clickLoading ?

                                                            <div className="spinner-border text-primary" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div> : null
                                                    }

                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="modal fade"
                                id={"exampleModal"}
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true"
                            >
                                <div
                                    className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div
                                            className="modal-header">
                                            <h5
                                                className="modal-title"
                                                id="exampleModalLabel"
                                            >
                                                Subscription intent details of : <span
                                                className="text-primary text-xl font-weight-bold mb-0">{this.state.subscriptionToShow.userEmail}</span>
                                            </h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            ></button>
                                        </div>
                                        {/*<div className="modal-body">*/}
                                        <div className="row">
                                            <center>
                                                <table className="table center">
                                                    <tbody>
                                                    <tr>
                                                        <td><p
                                                            className="text-secondary font-weight-bold text-xl mt-1 mb-0">Charge
                                                            failure code </p></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><span
                                                            className="text-primary text-xl font-weight-bold mb-0">{this.state.subscriptionToShow.stripeChargeFailureCode}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><p
                                                            className="text-secondary font-weight-bold text-xl mt-1 mb-0">Payment
                                                            intent decline code </p></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><span
                                                            className="text-primary text-xl font-weight-bold mb-0">{this.state.subscriptionToShow.stripePaymentIntentDeclineCode} </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><p
                                                            className="text-secondary font-weight-bold text-xl mt-1 mb-0">Charge
                                                            failure message</p></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><span
                                                            className="text-primary text-xl font-weight-bold mb-0">{this.state.subscriptionToShow.stripeChargeFailureMessage} </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><p
                                                            className="text-secondary font-weight-bold text-xl mt-1 mb-0">Charge
                                                            outcome type </p></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><span
                                                            className="text-primary text-xl font-weight-bold mb-0">{this.state.subscriptionToShow.stripeChargeOutcomeType} </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><p
                                                            className="text-secondary font-weight-bold text-xl mt-1 mb-0">Charge
                                                            outcome network status </p></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><span
                                                            className="text-primary text-xl font-weight-bold mb-0">{this.state.subscriptionToShow.stripeChargeOutcomeNetworkStatus}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><p
                                                            className="text-secondary font-weight-bold text-xl mt-1 mb-0">Charge
                                                            outcome seller message </p></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><span
                                                            className="text-primary text-xl font-weight-bold mb-0">{this.state.subscriptionToShow.stripeChargeOutcomeSellerMessage}</span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><p
                                                            className="text-secondary font-weight-bold text-xl mt-1 mb-0">Payment
                                                            date </p></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><span
                                                            className="text-primary text-xl font-weight-bold mb-0">{moment(this.state.subscriptionToShow.createdAt).utc().format('DD-MM-YYYY')}</span>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </center>
                                        </div>
                                        {/*</div>*/}
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                data-bs-dismiss="modal"
                                            >
                                                Close
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <AdminFooter/>
                        </div>
                        <div className="card-footer py-4">
                            <nav aria-label="...">
                                <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={'...'}
                                    pageCount={this.state.lastPage}
                                    marginPagesDisplayed={3}
                                    pageRangeDisplayed={6}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={"pagination justify-content-end mb-0"}
                                    pageClassName={"page-item"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    pageLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            </nav>
                        </div>
                    </main>
                </>
            </div>
        )
    }
}

export default SubscriptionIntentDetails
const routeAdmin = [
    {
        path: '/admin',
        name: 'Dashboard',
        icon: 'dashboard',
        section: 1
    },
    {
        path: '/sessions',
        name: 'Courses sessions',
        icon: 'school',
        section: 1
    },
    {
        path: '/admin-courses',
        name: 'Courses',
        icon : 'book',
        section: 1
    },
    {
        path: '/plans',
        name: "Subscriptions plans",
        icon: 'subscriptions',
        section: 1
    },
    {
        path: '/transactions',
        name: "All transactions",
        icon: 'list',
        section: 1
    },
    {
        path: '/subscription-intent',
        name: "subscription intents",
        icon: 'credit_score',
        section: 1
    },
    {
        path: '/search-user',
        name: 'Transactions',
        icon: 'list',
        section: 1
    },
    {
        path: '/admin-resources',
        name: 'Resources',
        icon : 'attach_file',
        section: 1
    },
    // {
    //     path: '/discounts',
    //     name: "Discounts",
    //     icon: 'discount',
    //     section: 1
    // },
    {
        path: '/users_administration',
        name: 'User administration',
        icon : 'supervisor_account',
        section: 2
    },
    {
        path: '/newsletter-subscribers',
        name: 'Newsletter Subscriber',
        icon : 'supervisor_account',
        section: 2
    },
    {
        path: '/currency',
        name: 'Currency',
        icon : 'local_convenience_store',
        section: 3
    },
    {
        path: '/channels',
        name: 'Channels',
        icon : 'subscriptions',
        section: 3
    },
    {
        path: '/configure-login-register',
        name: 'Authentication Setup',
        icon: 'settings_applications',
        section: 3
    },
    {
        path: '/account-synchronization',
        name: 'Migrate accounts',
        icon: 'admin_panel_settings',
        section: 3
    },

    {
        path: '/config-delay-discount',
        name: 'General configurations',
        icon: 'settings_applications',
        section: 3
    },
    {
        path: '/discount-coupons',
        name: 'Discount coupons',
        icon: 'settings_applications',
        section: 3
    },
];
export default routeAdmin;

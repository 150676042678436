import React, { useEffect, useState } from "react";
import { IMAGE_2, MOTIF_PAYMENT } from "../../../../../constants/images";
import ButtonCustom from "../../../../../components/frontv2.0/common/button/ButtonCustom";
import SessionService from "../../../../../network/services/SessionService";
import { getUser } from "../../../../../network/auth/Auth";
import moment from "moment/moment";
import { useNavigate } from "react-router";
import TransactionLoader from "../../../../../components/frontv2.0/common/loader/TransactionLoader";
import { MEDIA_URL_ACCOUNTING } from "../../../../../network/urls/backendUrls";
import { encodeUrl } from "../../../../../constants/Constants";

const CurrentSession = () => {
  const [sessions, setSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const showCurrentSession = () => {
    setIsLoading(true);
    let user = getUser();
    SessionService.getCurrentUserSessions(user.id, (data) => {
      setSessions(data);
      setIsLoading(false);
    });
  };

  const handleTransactiondetails = (sessionId, subscriptionId) => {
    navigate({
      pathname: "/workspace/transactions/details",
      search: `?sessionId=${sessionId}&subscriptionId=${subscriptionId}`,
    });
  };

  useEffect(() => {
    showCurrentSession();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-1 lg:tw-grid-cols-1">
          {[1, 2, 3, 4, 5, 6, 7, 8].map(() => {
            return <TransactionLoader />;
          })}
        </div>
      ) : (
        <>
          {!(sessions.length > 0) ? (
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-h-full tw-p-6">
              <img src={`${MOTIF_PAYMENT}`} alt="" />
              <div>
                <h1>Empty transactions</h1>
              </div>
            </div>
          ) : (
            <div className="tw-h-full tw-space-y-4">
              {sessions.map((item) => {
                return (
                  <div className="tw-flex-col tw-w-full md:tw-flex md:tw-flex-row  tw-rounded-[12px]  ">
                    <div
                      className="tw-hidden sm:tw-block tw-bg-cover tw-bg-center tw-rounded-[12px]  md:tw-w-[325px] tw-w-full"
                      style={{
                        backgroundImage: `url(${
                          !item?.image
                            ? IMAGE_2
                            : encodeUrl(
                                `${MEDIA_URL_ACCOUNTING}/sessions/${item?.image}`
                              )
                        })`,
                      }}
                    />
                    <img
                      className="md:tw-hidden md:tw-m-3 tw-bg-cover tw-bg-center tw-rounded-[12px] tw-max-h-[220px]  md:tw-w-[325px] tw-w-full"
                      src={
                        !item?.image
                          ? IMAGE_2
                          : encodeUrl(
                              `${MEDIA_URL_ACCOUNTING}/sessions/${item?.image}`
                            )
                      }
                      alt="session"
                    />
                    <div className="tw-flex tw-flex-col tw-w-full tw-p-4 md:tw-ml-3 tw-bg-accent-gray tw-rounded-b-[12px] md:tw-rounded-[12px] hover:tw-bg-primary-200 hover:tw-transition hover:tw-ease-in-out hover:tw-delay-150 hover:tw-duration-250">
                      <div className="tw-w-full tw-h-full md:tw-flex md:tw-items-center md:tw-justify-between">
                        <div>
                          <div className="tw-flex tw-flex-col tw-items-start tw-justify-between tw-w-full tw-text-primary">
                            <div className="tw-flex tw-flex-col sm:tw-text-lg tw-text-sm">
                              <span className="tw-font-bold">{item?.name}</span>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item?.description !== ""
                                      ? item?.description
                                      : "",
                                }}
                              />
                            </div>
                            {item.isInGoodStanding ? (
                              <span className="tw-text-green-700 tw-mt-2 tw-bg-accent-green tw-px-2 tw-py-1 tw-font-bold tw-rounded-[16px] tw-text-xs tw-border  tw-w-auto">
                                Access to course
                              </span>
                            ) : (
                              <span className="tw-text-red-700 tw-mt-2 tw-bg-accent-red tw-px-2 tw-py-1 tw-font-bold tw-rounded-[16px] tw-text-xs tw-border tw-w-auto">
                                No access to course
                              </span>
                            )}
                          </div>
                          <div className="tw-flex tw-flex-col tw-w-full md:tw-items-center md:tw-flex-row md:tw-justify-between">
                            <div className="tw-flex tw-flex-col tw-pt-4 tw-text-primary tw-text-xs sm:tw-text-md tw-space-y-2">
                              <div className="tw-flex tw-items-center">
                                <span className="tw-mr-[6px] pi pi-calendar tw-text-green-700"></span>
                                <span>
                                  Start date :{" "}
                                  {moment(item.start_date)
                                    .utc()
                                    .format("MMMM Do, YYYY")}
                                </span>
                              </div>

                              <div className="tw-flex tw-items-center">
                                <span className="tw-mr-[6px] pi pi-calendar tw-text-primary"></span>
                                <span>
                                  End date :{" "}
                                  {moment(item.end_date)
                                    .utc()
                                    .format("MMMM Do, YYYY")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tw-mt-3">
                          <ButtonCustom
                            value={item.id}
                            onClick={() =>
                              handleTransactiondetails(
                                item.sessionId,
                                item.subscriptionId
                              )
                            }
                            classes={
                              "tw-bg-primary hover:tw-bg-primary-900 tw-text-white tw-text-xs sm:tw-text-lg"
                            }
                            label="View transaction"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CurrentSession;

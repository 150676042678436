import React, {useEffect, useRef, useState} from "react";
import ButtonCustom from "../../frontv2.0/common/button/ButtonCustom";
import InputCustom from "../../frontv2.0/common/form/InputCustom";
import UserService from "../../../network/services/UserService";
import { Toast } from "primereact/toast";
import {MEDIA_URL_ACCOUNTING_AUTH_SETTINGS} from "../../../network/urls/backendUrls";
import Avatar from "../../frontv2.0/common/avatar/Avatar";
import {Dialog} from "primereact/dialog";
import Loader from "../../frontv2.0/common/loader/Loader";
import {isSettingSavable, isSettingsNotSendable} from "../../../constants/Constants";

const EditLogin = () => {

    const toast = useRef(null);
    const [image, setImage] = useState(null);
    const [urlImage, setUrlImage] = useState("");
    const [message, setMessage] = useState("");
    const [id, setId] = useState("");
    const [fileUrl, setfileUrl] = useState("");
    const [preview, setPreview] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const show = (e) => {
        e.preventDefault();
        setVisible(true);
    };
    const saveLoginSettings = (event) => {
        event.preventDefault();
        const formData = new FormData();
        const fileName = image ? image.name : "";
        const imageName = image ? image.name : "";
        // const fileName = image.name;
        //if (message !== "" || image !== null) {
        if (0 === 0) {
            formData.append('page', "AUTH_LOGIN");
            formData.append('file', image);
            formData.append('message', message);

            if (imageName) {
                formData.append('fileName', imageName);
            }

            UserService.createAuthSetting(formData, (response) => {
                if (response) {
                    if (response.success) {
                        notification("Settings successfully saved", true);
                        setfileUrl(response.data.file);
                        setId(response.data.id);
                        setPreview(null);
                    }
                }
            });
        }
    };

    const notification = (message, status) => {
        toast.current.show({
            severity: status ? "success" : "error",
            summary: status ? "Success" : "Error",
            detail: message,
            life: 3000,
        });
    };
    const clearImage = (e) => {
        setUrlImage("");
        setImage(null);
        setPreview(null);
        //setfileUrl("");
    };

    const deleteMessage = () => {
        UserService.deleteAuthSetting(id, (response) => {
            setIsLoading(true);
            if (response) {
                if (response.success) {
                    notification("Settings successfully removed", true);
                    setfileUrl("");
                    setId("");
                    setPreview(null);
                }
                //setIsLoading(false);
                setVisible(false);
                getLoginAuthSettings();
            }
        });
    };
    
    const onMessageChange = (e) => {
        const message = e.target.value;
        setMessage(message);
    };

    const getLoginAuthSettings = () => {
        setIsLoading(true);

        const AUTH_LOGIN = "AUTH_LOGIN"

        UserService.getAuthSetting( (response) => {
            if (response) {
                if (response.success) {
                    setMessage(response.data.message)
                    setfileUrl(response.data.file);
                    setId(response.data.id);
                } else {
                    setMessage("")
                    setfileUrl(null)
                }
            }
        },AUTH_LOGIN);
        setIsLoading(false);
    };
    const handleUploadImage = (event) => {
        const uploadedFile = event.target.files[0];
        if (uploadedFile) {
            const imageUrl = URL.createObjectURL(uploadedFile);
            setUrlImage(imageUrl);
            setImage(uploadedFile);
            setPreview(URL.createObjectURL(uploadedFile));
        }
    };

    useEffect(() => {
        getLoginAuthSettings();
    }, []);

    const footerContent = (
        <div className="tw-flex tw-flex-row tw-space-x-4 ">
            <ButtonCustom
                label="Close"
                icon={"pi pi-times-circle"}
                onClick={() => setVisible(false)}
                autoFocus
                classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
            />
            {isLoading ? (
                <ButtonCustom
                    classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
                    label="Loading..."
                    icon={"pi pi-spin pi-spinner"}
                    disabled={true}
                />
            ) : (
                <ButtonCustom
                    classes={"tw-bg-inherit hover:tw-bg-inherit-900 tw-text-rose-500"}
                    label="Confirm"
                    icon={"pi pi-times"}
                    onClick={deleteMessage}
                />
            )}
        </div>
    );
    return (
        <>
            <Toast ref={toast} position="top-right" />
            {isLoading ? (
                    <div className="tw-flex tw-h-full tw-flex-col md:tw-justify-center tw-justify-center tw-items-center">
                        <Loader />
                    </div>
                ) :
                <div>
                    <form
                        id="create-session-form">

                        <div style={{marginTop: "1%"}}>
                            <span>Enter the message for the login page</span>
                            <InputCustom
                                id={"registerText"}
                                name={"message"}
                                placeHolder={"Enter the message for the login page"}
                                type={"text"}
                                value={message}
                                onChange={onMessageChange}
                                // value={state.loginText}
                            />
                        </div>

                        {(preview || fileUrl) &&
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "16vh"}}>

                                <Avatar
                                    classes={"sm:tw-h-[90px] sm:tw-w-[90px] tw-h-[70px] tw-w-[70px] "}
                                    alt={"avatar"}
                                    src={
                                        preview
                                            ? preview
                                            : MEDIA_URL_ACCOUNTING_AUTH_SETTINGS + fileUrl
                                    }

                                />
                                {preview && (<span
                                    className="material-icons danger"
                                    value={id}
                                    onClick={clearImage}
                                    style={{
                                        cursor: "pointer",
                                        color: "firebrick",
                                    }}
                                >
                                cancel
                            </span>)}
                            </div>}

                        <div>
                            <Dialog
                                header="Confirmation"
                                visible={visible}
                                onHide={() => setVisible(false)}
                                footer={footerContent}
                                style={{ width: "25vw" }}
                                breakpoints={{ "960px": "75vw", "641px": "100vw" }}
                            >
                                <p className="m-0">Are you sure you want to remove these settings?</p>
                            </Dialog>
                        </div>
                        <div style={{marginTop: "1%"}}>
                            <label
                                htmlFor="file-input"
                                className="tw-cursor-pointer tw-space-x-1 tw-justify-center tw-bg-white hover:tw-bg-primary-100 tw-text-primary tw-border tw-border-primary tw-text-[11px] tw-px-3 tw-py-2 tw-rounded-[9px] tw-flex tw-items-center"
                            >
                                <i className="mr-2 pi pi-camera"></i>
                                <span className="tw-truncate">Choose an image</span>
                            </label>
                            <input
                                id="file-input"
                                name="file"
                                type="file"
                                className="tw-sr-only"
                                // onChange={handleUploadImage}
                                onChange={handleUploadImage}

                                value=""
                            />
                        </div>
                        <ButtonCustom
                            classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
                            label="Save"
                            icon={"pi pi-sync"}
                            disabled={isSettingSavable(message,preview)}
                            onClick={saveLoginSettings}
                        />
                        <ButtonCustom
                            classes={"tw-bg-inherit hover:tw-bg-inherit-900 tw-text-rose-500"}
                            label="Remove"
                            icon={"pi pi-times"}
                            disabled={!(id!== "")}
                            onClick={(e) => show(e)}
                        />
                    </form>
                </div>}
        </>
    );
};

export default EditLogin;



import React from "react";
import { IMAGE_4 } from "../../../../constants/images";
import { MEDIA_URL_ACCOUNTING } from "../../../../network/urls/backendUrls";
import SubscriptionPlan from "../subscriptions/SubscriptionPlan";
import { useNavigate } from "react-router";

const CourseDetails = (props) => {
  const navigate = useNavigate();

  const handleClick = (planId) => {
    navigate(props.checkoutLink + "/" + props.data.id + "/" + planId);
  };

  const encodeUrl = (url) => {
    return url.replace(/ /g, "%20");
  };
  return (
    <>
      <div className="tw-items-center tw-justify-center tw-w-full">
        <div className="lg:tw-flex-row lg:tw-flex ">
          <div className=" tw-h-[full] tw-mt-8 lg:tw-w-1/2 tw-px-3 lg:tw-mt-0 sm:tw-bg-accent-gray tw-py-[16px] lg:tw-px-[36px] tw-rounded-[12px]">
            <div className="tw-flex tw-w-full tw-text-primary tw-mb-4">
              <div className="tw-flex tw-flex-col tw-text-lg">
                <span className="tw-font-bold lg:tw-text-[22px] tw-mb-1">
                  {props.data.name}
                </span>
                <span className="tw-font-semiold tw-text-sm">
                  
                </span>
                <span className="tw-text-sm lg:tw-text-md" />
              </div>
            </div>
            <div className="tw-text-primary">
              <h1 className="tw-text-lg tw-font-bold">Description</h1>
              <p>{props.data.description}</p>
            </div>
          </div>
          <img
            className="tw-shadow-md lg:tw-ml-[25px] tw-object-cover tw-object-center lg:tw-w-1/2 tw-w-full tw-max-h-[280px] tw-rounded-[12px] "
            src={
              props.data.image === null
                ? IMAGE_4
                : encodeUrl(
                    `${MEDIA_URL_ACCOUNTING}/courses/${props.data.image}`
                  )
            }
            alt="image_6"
            height={100}
          />
        </div>
        <div
          className="tw-flex tw-font-bold tw-items-center tw-justify-center tw-font-semibold tw-text-center tw-h-20 md:tw-text-[32px] tw-text-[24px]  tw-my-[32px] tw-bg-center tw-bg-no-repeat tw-bg-cover"
          style={{ background: 'url("/media/images/motifs/motif-4.svg")' }}
        >
          <span
            className="tw-text-primary"
            style={{ fontFamily: '"Advent Pro"' }}
          >
            
          </span>
          <span
            className="tw-text-green-700"
            style={{ fontFamily: '"Advent Pro"' }}
          >
            {" "}
            Payment Option
          </span>
        </div>
        <div className={`${props.data?.subscriptionPlans?.length > 2 ? "tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 lg:tw-grid-cols-3" : "tw-space-y-3"} `}>
          {props.data.subscriptionPlans &&
          props.data.subscriptionPlans.length > 0 ? (
            props.data.subscriptionPlans.map((item, index) => {
              return (
                <div className="" key={index}>
                  <SubscriptionPlan
                    subLength={props.data.subscriptionPlans.length}
                    index={index}
                    type={`${
                      item.numberInstallment === 6
                        ? "classic"
                        : item.numberInstallment === 3
                        ? "medium"
                        : "premium"
                    }`}
                    onClick={() => handleClick(item.id)}
                    data={item}
                    sessionName={props.data.name}
                    allPricesPlan={[]}
                  />
                </div>
              );
            })
          ) : (
            <p>Subscription plan not available</p>
          )}
        </div>
      </div>
    </>
  );
};

export default CourseDetails;

import React from "react";
import { Link } from "react-router-dom";
import Logo from "../logo/Logo";

const Footer = ({ auth = false, globalStyleOfflineMode }) => {
  /**
   * Render
   */
  return (
    <>
      {auth ? (
        <div
          className={`tw-container tw-flex md:tw-items-center tw-justify-center md:tw-justify-between tw-text-gray-600 tw-h-[32px] tw-w-full tw-text-xs tw-px-3 `}
        >
          <p className="tw-hidden lg:tw-block">
            <span className="tw-text-gray-700">© All rights reserved </span>|{" "}
            <Link to="">
              <span className="tw-text-orange-900 hover:tw-underline">
              Terms of Use
              </span>
            </Link>{" "}
            |{" "}
            <Link to="">
              <span className="tw-text-primary hover:tw-underline">
              Privacy Policy
              </span>
            </Link>{" "}
            |{" "}
            <Link to="">
              <span className="tw-text-green-800 hover:tw-underline">
              Return Policy
              </span>
            </Link>
          </p>
          <p className="tw-flex tw-items-center tw-text-gray-700 lg:tw-block">
            <span className="sm:tw-hidden">©</span> &nbsp; +1 (302) 689 3440 |
            contact@utrains.org
          </p>
        </div>
      ) : (
        <footer
          className={`tw-bottom-0 tw-w-full tw-py-[20px] tw-bg-accent-gray`}
        >
          <div className={`tw-container tw-mx-auto ${globalStyleOfflineMode}`}>
            <div className="lg:tw-flex">
              <div className="tw-w-full lg:tw-w-2/5 ">
                <div className="">
                  <Link href="#">
                    <Logo />
                  </Link>
                  <p className="tw-max-w-sm tw-mt-2 tw-mb-3 tw-text-gray-500">
                  Welcome to the Linux and DevOps System Administration and DevOps Training Program.
                  </p>
                  <p className="tw-max-w-sm tw-text-gray-500">
                  Some of you may be wondering what Linux is. Well, it's fair to ask if you're not an IT professional. If you are, then...
                  </p>
                </div>
              </div>
              <div className="tw-grid tw-grid-cols-1 tw-gap-6 sm:tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-2 lg:tw-flex-1">
                <div>
                  <h3
                    className="tw-text-[22px] tw-font-bold tw-text-primary tw-py-[12px]"
                    style={{
                      fontFamily: "Advent Pro",
                    }}
                  >
                    Contacts
                  </h3>
                  <Link
                    href="#"
                    className="tw-block tw-mt-2 tw-text-sm tw-text-gray-600 hover:tw-underline"
                  >
                    <div className="tw-flex tw-items-center">
                      <i
                        className="tw-m-1 pi pi-phone"
                        style={{ fontSize: "1.2rem" }}
                      ></i>
                      <span className="tw-mx-[8px]">
                        Missouri City, Texas 77459-5225
                      </span>
                    </div>
                  </Link>
                  <Link
                    href="#"
                    className="tw-block tw-mt-2 tw-text-sm tw-text-gray-600 hover:tw-underline"
                  >
                    <div className="tw-flex tw-items-center">
                      <i
                        className="tw-m-1 pi pi-envelope"
                        style={{ fontSize: "1.2rem" }}
                      ></i>
                      <span className="tw-mx-[8px]">+1 (302) 689 3440</span>
                    </div>
                  </Link>
                  <Link
                    href="#"
                    className="tw-block tw-mt-2 tw-text-sm tw-text-gray-600 hover:tw-underline"
                  >
                    <div className="tw-flex tw-items-center">
                      <i
                        className="tw-m-1 pi pi-map-marker"
                        style={{ fontSize: "1.2rem" }}
                      ></i>
                      <span className="tw-mx-[8px]">contact@utrains.org</span>
                    </div>
                  </Link>
                </div>
                <div>
                  <h3
                    className="tw-font-bold tw-text-primary tw-text-[22px] tw-py-[12px]"
                    style={{
                      fontFamily: "Advent Pro",
                      fontWeight: 700,
                    }}
                  >
                    Links
                  </h3>
                  <div className="tw-hidden sm:tw-block">
                    <Link
                      href="#"
                      className="tw-block tw-mt-2 tw-text-sm tw-text-gray-600 hover:tw-underline"
                    >
                      Home
                    </Link>
                    <Link
                      href="#"
                      className="tw-block tw-mt-2 tw-text-sm tw-text-gray-600 hover:tw-underline"
                    >
                      Support
                    </Link>
                    <Link
                      href="#"
                      className="tw-block tw-mt-2 tw-text-sm tw-text-gray-600 hover:tw-underline"
                    >
                      Back
                    </Link>

                    <Link
                      href="#"
                      className="tw-block tw-mt-2 tw-text-sm tw-text-gray-600 hover:tw-underline"
                    >
                      Testimonials
                    </Link>

                    <Link
                      href="#"
                      className="tw-block tw-mt-2 tw-text-sm tw-text-gray-600 hover:tw-underline"
                    >
                      FAQ
                    </Link>
                  </div>
                  <div className="tw-grid tw-grid-cols-3 sm:tw-hidden">
                    <Link
                      href="#"
                      className="tw-block tw-mt-2 tw-text-sm tw-text-gray-600 hover:tw-underline"
                    >
                      Home
                    </Link>
                    <Link
                      href="#"
                      className="tw-block tw-mt-2 tw-text-sm tw-text-gray-600 hover:tw-underline"
                    >
                      Support
                    </Link>
                    <Link
                      href="#"
                      className="tw-block tw-mt-2 tw-text-sm tw-text-gray-600 hover:tw-underline"
                    >
                      Back
                    </Link>

                    <Link
                      href="#"
                      className="tw-block tw-mt-2 tw-text-sm tw-text-gray-600 hover:tw-underline"
                    >
                      Testimonials
                    </Link>

                    <Link
                      href="#"
                      className="tw-block tw-mt-2 tw-text-sm tw-text-gray-600 hover:tw-underline"
                    >
                      FAQ
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className=" tw-text-gray-600 tw-h-[37px] tw-pt-[30px] tw-w-full tw-hidden sm:tw-block tw-text-sm">
              <p className="tw-flex ">
                <span className="tw-hidden tw-text-gray-700 sm:tw-block">
                  © All rights reserved &nbsp; | &nbsp;
                </span>
                <Link to="">
                  <span className="tw-hidden tw-text-orange-900 hover:tw-underline sm:tw-block">
                  Terms of Use &nbsp; | &nbsp;
                  </span>
                </Link>
                <Link to="">
                  <span className="tw-hidden tw-text-primary hover:tw-underline sm:tw-block">
                  Privacy Policy &nbsp; | &nbsp;
                  </span>
                </Link>
                <Link to="">
                  <span className="tw-hidden tw-text-green-800 hover:tw-underline sm:tw-block">
                  Return Policy
                  </span>
                </Link>
              </p>
            </div>
            <div className="tw-flex tw-justify-center tw-mt-[30px] sm:tw-hidden">
              <span className="tw-text-gray-400">
                © All rights reserved - Utrains
              </span>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;

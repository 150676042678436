import React from "react";

const StatsInstallmentDate = ({numberDate}) => {
    return (
        <>
            <div className="tw-flex tw-flex-col tw-items-center tw-space-x-2 tw-primary tw-h-full ">
                <div
                    className="tw-flex tw-flex-col tw-items-center sm:tw-text-lg tw-text-xl tw-h-20 tw-w-20 tw-font-bold tw-rounded-full tw-bg-accent-gray tw-text-center tw-p-auto tw-justify-center">
                    <p className="!tw-font-bold">
                        {numberDate} <br/> Days
                    </p>
                </div>
                <span className="tw-text-xl tw-text-center tw-truncate ...">Next payment date</span>
            </div>
        </>
    );
};

export default StatsInstallmentDate;

import {sDELETE, sGET, sPOST, sPUT, spGET, sPOST_FILE, sGET_FETCH, GET} from "../protocol/HTTP";
import backendUrls from "../urls/backendUrls";

const CourseService={
    getAllByPage: function (page_id,nbreItems,cb){
        //  const url=backendUrls.SessionPlan.currency;
        const  url=backendUrls.COURSE.GET_ALL+"?page="+`${page_id}`+"&itemsPerPage="+`${nbreItems}`;
        spGET(url,cb);

    },
    /*  getAll: function (cb){
         const url=backendUrls.COURSE.GET_ALL;
         sGET(url,cb);
     }, */

    getAllSessions: function (cb){
        const url=backendUrls.COURSE.SESSION;
        sGET(url,cb);

    },
    getCourseAndSubscriptionPlan: function (data,cb){
        const url=backendUrls.COURSE.COURSE_AND_SUBSCRIPTION_PLAN_CHECKOUT;
        console.log(url);
        sPOST(url,data,cb);
    },

    allCourses: function (cb){
        //const url=backendUrls.COURSE.ALL_COURSES +'?page='+`${page}`
        const url=backendUrls.COURSE.ALL_COURSES_WITHOUT_PAGINATION;
        sGET(url,cb);

    },

    allUnsecureCourses: function (page,cb){
        const url=backendUrls.COURSE.ALL_UNSECURE_COURSES +'?page='+`${page}`
        GET(url,cb);
    },

    /*  getById: function (id,cb){
         const url=backendUrls.COURSE.GET_BY_ID+"/"+`${id}`;
         console.log('Voici l url '+url);
         sGET(url,cb);
     }, */

    getOneById: function (id,cb){
        const url=backendUrls.COURSE.GET_ONE+"/"+`${id}`;
        sGET(url,cb);
    },

    getOneUnsecureById: function (id,cb){
        const url=backendUrls.COURSE.GET_ONE_UNSECURED+"/"+`${id}`;
        GET(url,cb);
    },

   

     refundPayment: function (data,cb){
        const url=backendUrls.COURSE.REFUND_TRANSACTION;
        console.log(url);
        sPOST(url,data,cb);
    },


    userGetById: function (data,cb){
        const url=backendUrls.COURSE.GET_USER_ONE_COURSE;
        sPOST(url,data,cb);
    },

    
    getUserCourse: function (data,cb){
        const url=backendUrls.COURSE.GET_USER_ALL_COURSE;
        sPOST(url,data,cb);
    },

    getCourseById: function (data,cb){
        const url=backendUrls.COURSE.GET_USER_ALL_COURSE_DETAILS;
        sPOST(url,data,cb);
    },


    
    updatePlanSubscription: function (data,cb){
        const url=backendUrls.COURSE.GET_PLAN_SUBSCRIPTION;
        console.log(url )
        sPOST(url,data,cb);
    },

    createSimpleCourses: function (data,cb){
        const url=backendUrls.COURSE.CREATE
        sPOST_FILE(url,data,cb);
    },

    GetAllSimpleCourses: function (pg,limit,cb){
        const url=backendUrls.COURSE.GET_ALL_CREATED_COURSE +'?page='+`${pg}`+'&limit='+limit;
        console.log('page '+pg )
        console.log(url);
        sGET(url,cb);
    },

    getCourseFilter: function (data,cb){
        const url=backendUrls.COURSE.GET_BY_FILTER;
        console.log(url)
        sPOST(url,data,cb);
    },

    /* GetAllSimpleCourses: function (pg, cb){
       const url=backendUrls.COURSE.GET_ALL_CREATED_COURSE +'?page='+`${pg}`;
       console.log('page '+pg )
       console.log(url);
       sGET(url,cb);
   },  */

    /* deleteCourses: function (id,cb){
         const url=backendUrls.COURSE.DELETE_COURSE;
         console.log(url);
         sDELETE(url,cb);
     },  */



    /*  create: function (data,cb){
         const url=backendUrls.COURSE.CREATE;
         sPOST(url,data,cb);
     }, */

    delete: function (id,cb){
        const url=backendUrls.COURSE.DELETE+"/"+`${id}`;
        sDELETE(url,cb);
    },

    deletePlan: function (data,cb){
        const url=backendUrls.COURSE.DELETE_PLAN;
        console.log(url)
        sPOST(url,data,cb);
    },




    /*  update: function (id,data,cb){
         const url=backendUrls.COURSE.UPDATE+"/"+`${id}`;
         sPUT(url,data,cb);
     }, */

    /* updateCourse: function (id,data,cb){
        const url=backendUrls.COURSE.UPDATE_COURSE+"/"+`${id}`;
        sPOST(url,data,cb);
    }, */


    updateCourseSettings: function (data,cb){
        const url=backendUrls.COURSE.SETTINGS;
        console.log(url);
        sPOST(url,data,cb);
    },

    updateCourse: function (id,data,cb){
        const url=backendUrls.COURSE.UPDATE+"/"+`${id}`;
        console.log(data);
        sPOST_FILE(url,data,cb);
    },

    CheckAccesCourse: function (id1,id2,cb){
        const url=backendUrls.COURSE.CHECK_ACCES_COURSE_SECURE+"/"+`${id1}`+"/"+`${id2}`;
        sGET(url,cb);
    },

    CheckAccesCourseUserAuth: function (id1,cb){
        const url=backendUrls.COURSE.CHECK_ACCES_COURSE_SECURE_USER_AUTH;
        sGET(url,cb);
    },

    CheckAccesCourseWithUserAndSession: function (data,cb){
        const url=backendUrls.COURSE.CHECK_ACCESS_COURSE_SECURE_USER_WITH_USER_AND_SESSION;
        sPOST(url,data,cb);
    },

    CheckAccesCourseWithUserAndCourse: function (data,cb){
        const url=backendUrls.COURSE.CHECK_ACCESS_COURSE_SECURE_USER_WITH_USER_AND_COURSE;
        sPOST(url,data,cb);
    },

   
    CheckAccessType: function (data,cb){
        const url=backendUrls.COURSE.CHECK_ACCESS_TYPE_SECURE;
        sPOST(url,data,cb);
    },

    CheckAccessTypeCourse: function (data,cb){
        const url=backendUrls.COURSE.CHECK_ACCESS_TYPE_COURSE_SECURE;
        sPOST(url,data,cb);
    },
  

    ChangeAccessTypeToNormal: function (data,cb){
        const url=backendUrls.COURSE.CHANGE_ACCESS_TYPE_TO_NORMAL_SECURE;
        sPOST(url,data,cb);
    },

    ChangeAccessTypeToManual: function (data,cb){
        const url=backendUrls.COURSE.CHANGE_ACCESS_TYPE_TO_MANUAL_SECURE;
        sPOST(url,data,cb);
    },

    ChangeAccessTypeToNormalCourse: function (data,cb){
        const url=backendUrls.COURSE.CHANGE_ACCESS_TYPE_TO_NORMAL_COURSE_SECURE;
        sPOST(url,data,cb);
    },

    ChangeAccessTypeToManualCourse: function (data,cb){
        const url=backendUrls.COURSE.CHANGE_ACCESS_TYPE_TO_MANUAL_COURSE_SECURE;
        sPOST(url,data,cb);
    },


    modifyManualAccess: function (data,cb){
        const url=backendUrls.COURSE.MODIFY_MANUAL_ACCESS;
        sPOST(url,data,cb);
    },
   
    getDatePaymentSecureAdmin: function (data,cb){
        const url=backendUrls.PaymentHistory.GET_DATE_PAYMENT_SECURE_ADMIN_COURSE;
        sPOST(url,data,cb);
    },
    getHistoryFailSecureAdmin: function (data,cb){
        const url=backendUrls.PaymentHistory.GET_PAYMENT_HISTORY_FAIL_SECURE_ADMIN_COURSE;
        sPOST(url,data,cb);
    },
    
}

export default CourseService;

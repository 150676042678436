import React, { Component } from "react";
import Sidebar from "../sidebar/Sidebar";
import AdminFooter from "../footer/AdminFooter";
import "../../../assets/subscription_plan.css";
import SubscriptionPlanService from "../../../network/services/SubscriptionPlanService";
import moment from "moment";
import AdminHeader from "../header/AdminHeader";
import '../Dashboard/AdminHome.css';

class EditPlan extends Component {
    constructor(props) {
        super(props);
        this.refreshList = this.refreshList.bind(this);
        this.saveSubscriptionPlan = this.saveSubscriptionPlan.bind(this);
        this.updateSubscriptionPlan = this.updateSubscriptionPlan.bind(this);
        this.getId = this.getId.bind(this);
        this.onChangePaymentAmount = this.onChangePaymentAmount.bind(this);
        this.onChangePrice = this.onChangePrice.bind(this);
        this.onChangeNumberInstallment = this.onChangeNumberInstallment.bind(this);
        this.onChangeIntervalBtwInstallment =
            this.onChangeIntervalBtwInstallment.bind(this);
        this.onUpdatePaymentAmount = this.onUpdatePaymentAmount.bind(this);
        this.onUpdatePrice = this.onUpdatePrice.bind(this);
        this.onUpdateNumberInstallment = this.onUpdateNumberInstallment.bind(this);
        this.onUpdateUserDisplayName = this.onUpdateUserDisplayName.bind(this);
        this.onUpdateIntervalBtwInstallment =
            this.onUpdateIntervalBtwInstallment.bind(this);
        this.deleteSubscriptionPlan = this.deleteSubscriptionPlan.bind(this);
        this.onChangeUserDisplayName = this.onChangeUserDisplayName.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onUpdateName = this.onUpdateName.bind(this);
        this.state = {
            subscriptionPlans: [],
            paymentAmount: null,
            price: null,
            numberInstallment: null,
            intervalBtwInstallment: null,
            paymentIntervalType: null,
            paymentIntervalTypeUpdate: null,
            name: null,
            message: '',
            userDisplayName: null,
            currentSubscriptionPlan: {
                id: null,
                paymentAmount: null,
                price: null,
                numberInstallment: null,
                intervalBtwInstallment: null,
                paymentIntervalType: null,
                name: null,
                userDisplayName: null,
            },

            currentSubscriptionPlanUserDisplayName: { userDisplayName: null, },
            options: [
                { label: "days", value: "days" },
                { label: "weeks", value: "weeks" },
                { label: "months", value: "months" },
                { label: "years", value: "years" }
            ]
        };
    }

    onChangePaymentAmount(e) {
        this.setState({
            paymentAmount: e.target.value,
        });
    }

    onUpdatePaymentAmount(e) {
        const paymentAmount = e.target.value;
        this.setState(function (prevState) {
            return {
                currentSubscriptionPlan: {
                    ...prevState.currentSubscriptionPlan,
                    paymentAmount: paymentAmount,
                },
            };
        });
    }



    onChangePrice(e) {
        this.setState({
            price: e.target.value,
        });
    }

    onUpdatePrice(e) {
        const price = e.target.value;

        this.setState(function (prevState) {
            return {
                currentSubscriptionPlan: {
                    ...prevState.currentSubscriptionPlan,
                    price: price,
                },
            };
        });
    }

    onChangeNumberInstallment(e) {
        this.setState({
            numberInstallment: e.target.value,
        });
    }

    onUpdateNumberInstallment(e) {
        const numberInstallment = e.target.value;

        this.setState(function (prevState) {
            return {
                currentSubscriptionPlan: {
                    ...prevState.currentSubscriptionPlan,
                    numberInstallment: numberInstallment,
                },
            };
        });
    }

    onChangeIntervalBtwInstallment(e) {
        this.setState({
            intervalBtwInstallment: e.target.value,
        });
    }

    onUpdateIntervalBtwInstallment(e) {
        const intervalBtwInstallment = e.target.value;

        this.setState(function (prevState) {
            return {
                currentSubscriptionPlan: {
                    ...prevState.currentSubscriptionPlan,
                    intervalBtwInstallment: intervalBtwInstallment,
                },
            };
        });
    }


    onUpdateUserDisplayName(e) {
        const userDisplayName = e.target.value;

        this.setState(function (prevState) {
            return {
                currentSubscriptionPlanUserDisplayName: {
                    // ...prevState.currentSubscriptionPlan,
                    ...prevState.currentSubscriptionPlanUserDisplayName,

                    userDisplayName: userDisplayName,
                },
            };
        });
    }


    onChangeUserDisplayName(e) {
        this.setState({
            userDisplayName: e.target.value,
        });
        // console.log(e.target.value)
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value,
        });
    }

    onUpdateName(e) {
        const name = e.target.value;
        this.setState(function (prevState) {
            return {
                currentSubscriptionPlan: {
                    ...prevState.currentSubscriptionPlan,
                    name: name,
                },
            };
        });
    }


    getId(e) {
        const id = e.currentTarget.id;
        // const id = e.target.getAttribute("id");
        console.log(id)
        this.setState({
            isLoading: true
        });
        SubscriptionPlanService.getById(id, (data) => {
            // console.log(data);
            this.setState({
                currentSubscriptionPlan: data,
                currentSubscriptionPlanUserDisplayName: data.subscriptionPlanDetails,

                isLoading: false
            });


        });
    }

    saveSubscriptionPlan() {
        let d = new Date();
        let date = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
        let hours = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
        let fullDate = date + 'T' + hours + 'Z';

        if (parseInt(this.state.intervalBtwInstallment) && parseInt(this.state.paymentAmount) && parseInt(this.state.price) && parseInt(this.state.numberInstallment) && this.state.name) {
            let data = {
                intervalBtwInstallment: parseInt(this.state.intervalBtwInstallment),
                paymentIntervalType: this.state.paymentIntervalType,
                paymentAmount: parseInt(this.state.paymentAmount),
                price: parseInt(this.state.price),
                numberInstallment: parseInt(this.state.numberInstallment),
                name: this.state.name,
                updatedAt: fullDate,
                createdAt: fullDate,
                userDisplayName: this.state.userDisplayName,

            };
            // console.log(data);
            SubscriptionPlanService.create(data, (data) => {
                if (data) {
                    this.setState({
                        intervalBtwInstallment: '',
                        paymentIntervalType: '',
                        paymentAmount: '',
                        price: '',
                        numberInstallment: '',
                        userDisplayName: '',
                        name: '',
                        updatedAt: '',
                        createdAt: '',
                        submitted: false,
                        edited: false,
                        successMessage: true,
                        message: 'Subscription plan successfully created.'
                    });
                    const form = document.getElementById('create-subscription-plan');
                    form.reset();
                    this.refreshList();
                }
            });
        }

    }

    updateSubscriptionPlan() {
        let d = new Date();
        let date = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
        let hours = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
        let fullDate = date + 'T' + hours + 'Z';
        let data = {
            intervalBtwInstallment: parseInt(
                this.state.currentSubscriptionPlan.intervalBtwInstallment
            ),
            paymentIntervalType: this.state.paymentIntervalTypeUpdate ? this.state.paymentIntervalTypeUpdate : this.state.currentSubscriptionPlan.paymentIntervalType,
            paymentAmount: parseInt(this.state.currentSubscriptionPlan.paymentAmount),
            price: parseInt(this.state.currentSubscriptionPlan.price),
            numberInstallment: parseInt(
                this.state.currentSubscriptionPlan.numberInstallment
            ),
            name: this.state.currentSubscriptionPlan.name,
            userDisplayName: this.state.currentSubscriptionPlanUserDisplayName.userDisplayName,
            updatedAt: fullDate,
        };
        // console.log(data)
        SubscriptionPlanService.update(
            this.state.currentSubscriptionPlan.id,
            data,
            (dat) => {
                if (dat) {
                    // console.log(dat)
                    this.setState({ updateMessage: true });
                    this.refreshList();
                }
                this.setState({
                    currentSubscriptionPlanUserDisplayName: {
                        userDisplayName: ""
                    }
                });
            }
        );
    }

    deleteSubscriptionPlan(e) {
        const id = e.target.getAttribute("id");

        SubscriptionPlanService.delete(id, (data) => {
            //console.log('reponse du serveur')
            //console.log(data)
            if (data) {
                if (!data.status) {
                    this.setState({
                        deleteMessage: true,
                    });
                    this.refreshList();
                }
            } else {
                this.setState({
                    successMessage: true,
                    message: "Subscription plan successfully deleted"
                });
                this.refreshList();
            }
        });
    }

    retrieveSubscriptionPlan() {
        this.setState({ isLoading: true });
        SubscriptionPlanService.getAll((data) => {
            // console.log(data);
            this.setState({ subscriptionPlans: data, isLoading: false });
        });
    }

    refreshList() {
        this.retrieveSubscriptionPlan();
    }

    getMostUsedPlan() {
        SubscriptionPlanService.getMostUsedPlan((data) => {
            if (data) {
                // console.log(data.subscription_plan_price)
                // console.log(data.subscription_plan_name)
                // console.log(data.subscription_plan_created_at)
                this.setState({
                    name_subscription_plan: data.subscription_plan_name,
                    price_subscription_plan: data.subscription_plan_price,
                    created_at_subscription_plan: data.subscription_plan_created_at,
                });
            }
        });


    }

    componentDidMount() {
        this.retrieveSubscriptionPlan();

        this.getMostUsedPlan();
    }

    resetUserDisplayName = () => {
        this.setState({
            currentSubscriptionPlanUserDisplayName: {
                userDisplayName: ""
            }
        });
    };

    render() {
        const { currentSubscriptionPlan } = this.state;
        const { currentSubscriptionPlanUserDisplayName } = this.state;
        return (
            <div class="g-sidenav-show  bg-gray-200">
                <Sidebar />
                <main className="mainAdmin r ms-100">
                    <AdminHeader location="Subscription plan" />


                    <div className="position-fixed bottom-1 end-1 z-index-2">
                        {this.state.successMessage ? (
                            <div className="toast fade show p-2 mt-2 bg-gradient-info" role="alert"
                                aria-live="assertive" id="infoToast" aria-atomic="true">
                                <div className="toast-header bg-transparent border-0">
                                    <i className="material-icons text-white me-2">
                                        check
                                    </i>
                                    <span className="me-auto text-white font-weight-bold">Subscription plan </span>
                                    <small className="text-white">just now</small>
                                    <i className="fas fa-times text-md text-white ms-3 cursor-pointer"
                                        data-bs-dismiss="toast" aria-label="Close"
                                        onClick={(e) => this.setState({ successMessage: false })}
                                    ></i>
                                </div>
                                <hr className="horizontal light m-0" />
                                <div className="toast-body text-white">
                                    {this.state.message}
                                </div>
                            </div>
                        ) :
                            (<></>)}
                        {this.state.updateMessage ? (
                            <div className="toast fade show p-2 mt-2 bg-white" role="alert" aria-live="assertive"
                                id="warningToast" aria-atomic="true">
                                <div className="toast-header border-0">
                                    <i className="material-icons text-warning me-2">
                                        check
                                    </i>
                                    <span className="me-auto font-weight-bold">Subscription plan </span>
                                    <small className="text-body">just now</small>
                                    <i className="fas fa-times text-md ms-3 cursor-pointer" data-bs-dismiss="toast"
                                        aria-label="Close"
                                        onClick={(e) => this.setState({ updateMessage: false })}
                                    ></i>
                                </div>
                                <hr className="horizontal dark m-0" />
                                <div className="toast-body">
                                    Subscription plan updated successfully.
                                </div>
                            </div>
                        ) :
                            (<></>)}

                        {this.state.deleteMessage ? (
                            <div className="toast fade show p-2 mt-2 bg-gradient-danger" role="alert"
                                aria-live="assertive" id="infoToast" aria-atomic="true">
                                <div className="toast-header bg-transparent border-0">
                                    <i className="material-icons text-white me-2">
                                        check
                                    </i>
                                    <span className="me-auto text-white font-weight-bold">Subscription plan</span>
                                    <small className="text-white">just now</small>
                                    <i className="fas fa-times text-md ms-3 cursor-pointer"
                                        data-bs-dismiss="toast" aria-label="Close"
                                        onClick={(e) => this.setState({ deleteMessage: false })}
                                    ></i>
                                </div>
                                <hr className="horizontal light m-0" />
                                <div className="toast-body text-white">
                                    Impossible to delete the subscription plan! The subscription plan already has to
                                    subscriptions.
                                </div>
                            </div>
                        ) :
                            (<></>)}
                    </div>


                    <div
                        className="modal fade"
                        id="exampleModal"
                        tabindex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        Add Subscription Plan
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    <form id="create-subscription-plan"
                                        action="app/src/components/Admin/Subscription plan/Plan#">
                                        <div className="single-form">
                                            <label>Title</label>
                                            <input
                                                type="text"
                                                placeholder="Title"
                                                id="name"
                                                name="name"
                                                value={this.state.name}
                                                onChange={this.onChangeName}
                                                required
                                            />
                                        </div>
                                        <div className="single-form">
                                            <label>Payment Amount</label>
                                            <input
                                                type="text"
                                                placeholder="Payment Amount"
                                                id="paymentAmount"
                                                name="paymentAmount"
                                                value={this.state.paymentAmount}
                                                onChange={this.onChangePaymentAmount}
                                                required
                                            />
                                        </div>
                                        <div className="single-form">
                                            <label>Price</label>
                                            <input
                                                type="text"
                                                placeholder="Price"
                                                name="price"
                                                value={this.state.price}
                                                onChange={this.onChangePrice}
                                                required
                                            />
                                        </div>
                                        <div className="single-form">
                                            <label>Number Of Installment</label>
                                            <input
                                                type="text"
                                                placeholder="Number Installment"
                                                name="numberInstallment"
                                                value={this.state.numberInstallment}
                                                onChange={this.onChangeNumberInstallment}
                                                required
                                            />
                                        </div>
                                        <div className="single-form">
                                            <label>Interval Between Installment</label>
                                            <input
                                                type="text"
                                                placeholder="Interval Btw Installment"
                                                name="intervalBtwInstallment"
                                                value={this.state.intervalBtwInstallment}
                                                onChange={this.onChangeIntervalBtwInstallment}
                                                required
                                            />
                                        </div>

                                        <div className="single-form">
                                            <label>User display name</label>
                                            <input
                                                type="text"
                                                placeholder="User display name"
                                                id="userDisplayName"
                                                name="userDisplayName"
                                                value={this.state.userDisplayName}
                                                onChange={this.onChangeUserDisplayName}

                                            />
                                        </div>
                                        {/*<div className="single-form">*/}
                                        {/*    <label>Currency</label>*/}

                                        {/*    <Select options={this.state.options}   onChange={(e) => this.setState({paymentIntervalType: e.target.value})}/>*/}

                                        {/*</div>*/}
                                        <div className="row" style={{ size: '100px' }}>
                                            <div className="col-12 mb-3">
                                                <label className="form-label">payment Interval Type</label>
                                                <select
                                                    onChange={(e) => this.setState({ paymentIntervalType: e.target.value })}
                                                    className="form-control form-control-lg selectpicker">
                                                    <option>--Please choose an option--</option>
                                                    {this.state.options && this.state.options.map((option) => (
                                                        <option key={option.id}>
                                                            {option.value}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>


                                        <div className="single-form">
                                            <button
                                                type="button"
                                                className="btn btn-success btn-lg btn-block"
                                                onClick={this.saveSubscriptionPlan}
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                Add
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div class="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-bs-dismiss="modal"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid py-4">
                        <div className="row">
                            <div className="col-md-7 mt-4">
                                <div className="card">
                                    <div className="card-header pb-0 p-3">
                                        <div className="row">
                                            <div className="col-6 d-flex align-items-center">
                                                <h6 className="mb-0">Subscription plan </h6>
                                            </div>
                                            <div className="col-6 text-end">
                                                <a
                                                    className="btn bg-gradient-dark mb-0"
                                                    href="javascript:;"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#exampleModal"
                                                >
                                                    <i className="material-icons text-sm">add</i>
                                                    &nbsp;&nbsp;Add Subscription plan
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body pt-4 p-3">
                                        {
                                            this.state.isLoading ? (
                                                <div className="spinner-border text-primary" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>

                                            ) : (
                                                <></>
                                            )
                                        }
                                        <ul className="list-group">
                                            {this.state.subscriptionPlans && this.state.subscriptionPlans.map((subscriptionPlan) => (
                                                <li
                                                    className="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
                                                    key={subscriptionPlan.id}
                                                >
                                                    <div className="d-flex flex-column">
                                                        <h6 className="mb-3 text-sm"></h6>
                                                        <span className="mb-2 text-xs">
                                                            Title:
                                                            <span className="text-dark font-weight-bold ms-sm-2">
                                                                {subscriptionPlan.name}
                                                            </span>
                                                        </span>
                                                        <span className="mb-2 text-xs">
                                                            Payment Amount:
                                                            <span className="text-dark font-weight-bold ms-sm-2">
                                                                {subscriptionPlan.paymentAmount}
                                                            </span>
                                                        </span>
                                                        <span className="mb-2 text-xs">
                                                            Price:
                                                            <span className="text-dark ms-sm-2 font-weight-bold">
                                                                {subscriptionPlan.price}
                                                            </span>
                                                        </span>
                                                        <span className="mb-2 text-xs">
                                                            Number Installment:
                                                            <span className="text-dark ms-sm-2 font-weight-bold">
                                                                {subscriptionPlan.numberInstallment}
                                                            </span>
                                                        </span>
                                                        <span className="mb-2 text-xs">
                                                            Interval Between Installment :
                                                            <span className="text-dark ms-sm-2 font-weight-bold">
                                                                {subscriptionPlan.intervalBtwInstallment}
                                                            </span>
                                                        </span>
                                                        <span className="mb-2 text-xs">
                                                            Type Interval of payment :
                                                            <span className="text-dark ms-sm-2 font-weight-bold">
                                                                {subscriptionPlan.paymentIntervalType}
                                                            </span>
                                                        </span>

                                                        <span className="text-xs">
                                                            User display name :
                                                            <span className="text-dark ms-sm-2 font-weight-bold">
                                                                {subscriptionPlan.subscriptionPlanDetails.userDisplayName}                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div className="ms-auto text-end">
                                                        <span
                                                            className="btn btn-link text-danger text-gradient px-3 mb-0"
                                                            data-bs-toggle="modal"
                                                            data-bs-target={
                                                                "#exampleModalDelete" + subscriptionPlan.id
                                                            }

                                                            id={subscriptionPlan.id}
                                                        >
                                                            <i className="material-icons text-sm me-2">
                                                                delete
                                                            </i>
                                                            Delete
                                                        </span>
                                                        <a
                                                            className="btn btn-link text-dark px-3 mb-0"
                                                            href="javascript:;"
                                                            data-bs-toggle="modal"
                                                            data-bs-target={
                                                                "#exampleModal" + subscriptionPlan.id
                                                            }
                                                            onClick={this.getId}
                                                            id={subscriptionPlan.id}
                                                        >
                                                            <i className="material-icons text-sm me-2">
                                                                edit
                                                            </i>

                                                            Edit
                                                        </a>


                                                    </div>

                                                    <div
                                                        className="modal fade"
                                                        id={"exampleModal" + subscriptionPlan.id}
                                                        tabIndex="-1"
                                                        aria-labelledby="exampleModalLabel"
                                                        aria-hidden="true"
                                                    >
                                                        <div className="modal-dialog modal-dialog-centered">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5
                                                                        className="modal-title"
                                                                        id="exampleModalLabel"
                                                                    >
                                                                        Edit Subscription Plan
                                                                    </h5>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close"
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"
                                                                        onClick={this.resetUserDisplayName}
                                                                    ></button>
                                                                    {/* <button
                                                                        type="button"
                                                                        className="btn btn-secondary"
                                                                        data-bs-dismiss="modal"
                                                                        onClick={this.resetUserDisplayName}
                                                                    >
                                                                        Close
                                                                    </button> */}
                                                                </div>
                                                                <div className="modal-body">
                                                                    {
                                                                        this.state.isLoading ? (
                                                                            <div className="spinner-border text-primary"
                                                                                role="status">
                                                                                <span
                                                                                    className="sr-only">Loading...</span>
                                                                            </div>

                                                                        ) : (
                                                                            <></>
                                                                        )
                                                                    }

                                                                    <form
                                                                        action="app/src/components/Admin/Subscription plan/Plan#">
                                                                        <div className="single-form">
                                                                            <label>Title</label>
                                                                            <input
                                                                                type="text"
                                                                                placeholder="title"
                                                                                name="name"
                                                                                value={
                                                                                    currentSubscriptionPlan.name
                                                                                }
                                                                                onChange={this.onUpdateName}
                                                                            />
                                                                        </div>
                                                                        <div className="single-form">
                                                                            <label>Payment Amount</label>
                                                                            <input
                                                                                type="text"
                                                                                placeholder="Payment Amount"
                                                                                name="paymentAmount"
                                                                                value={
                                                                                    currentSubscriptionPlan.paymentAmount
                                                                                }
                                                                                onChange={this.onUpdatePaymentAmount}
                                                                            />
                                                                        </div>
                                                                        <div className="single-form">
                                                                            <label>Price</label>
                                                                            <input
                                                                                type="text"
                                                                                placeholder="Price"
                                                                                name="price"
                                                                                value={currentSubscriptionPlan.price}
                                                                                onChange={this.onUpdatePrice}
                                                                            />
                                                                        </div>
                                                                        <div className="single-form">
                                                                            <label>Number of Installment</label>
                                                                            <input
                                                                                type="text"
                                                                                placeholder="Number Installment"
                                                                                name="numberInstallment"
                                                                                value={
                                                                                    currentSubscriptionPlan.numberInstallment
                                                                                }
                                                                                onChange={
                                                                                    this.onUpdateNumberInstallment
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="single-form">
                                                                            <label>Interval Between Installment</label>
                                                                            <input
                                                                                type="text"
                                                                                placeholder="Interval Btw Installment"
                                                                                name="intervalBtwInstallment"
                                                                                value={
                                                                                    currentSubscriptionPlan.intervalBtwInstallment
                                                                                }
                                                                                onChange={
                                                                                    this.onUpdateIntervalBtwInstallment
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <div className="single-form">
                                                                            <label>User Display name</label>
                                                                            <input
                                                                                type="text"
                                                                                placeholder="User Display name"
                                                                                name="userDisplayName"
                                                                                value={
                                                                                    currentSubscriptionPlanUserDisplayName.userDisplayName}
                                                                                onChange={this.onUpdateUserDisplayName}
                                                                            />
                                                                        </div>
                                                                        <div className="single-form">
                                                                            <div className="row"
                                                                                style={{ size: "100px" }}>
                                                                                <div className="col-12 mb-3">
                                                                                    <label className="form-label">payment
                                                                                        Interval Type</label>
                                                                                    <select
                                                                                        onChange={(e) => this.setState({ paymentIntervalTypeUpdate: e.target.value })}
                                                                                        className="form-control form-control-lg selectpicker">
                                                                                        {this.state.options && this.state.options.map((option) => (
                                                                                            <option key={option.id}
                                                                                                selected={option.value === currentSubscriptionPlan.paymentIntervalType}>
                                                                                                {option.value}
                                                                                            </option>
                                                                                        ))}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="single-form">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-success btn-lg btn-block"
                                                                                onClick={this.updateSubscriptionPlan}
                                                                                id={subscriptionPlan.id}
                                                                                data-bs-dismiss="modal"
                                                                                aria-label="Close"
                                                                            >
                                                                                Update
                                                                            </button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-secondary"
                                                                        data-bs-dismiss="modal"
                                                                    >
                                                                        Close
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="modal fade"
                                                        id={"exampleModalDelete" + subscriptionPlan.id}
                                                        tabIndex="-1"
                                                        aria-labelledby="exampleModalLabel"
                                                        aria-hidden="true"
                                                    >
                                                        <div
                                                            className="modal-dialog modal-dialog-centered">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title"
                                                                        id="exampleModalLabel">
                                                                        Delete subscription plan
                                                                    </h5>
                                                                    <button
                                                                        type="button"
                                                                        className="btn-close"
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"
                                                                    ></button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    Do you want to delete the selected subscription plan
                                                                    ?
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-danger  btn-block"
                                                                        onClick={this.deleteSubscriptionPlan}
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"
                                                                        id={subscriptionPlan.id}
                                                                    >
                                                                        Confirm
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-secondary btn-block"
                                                                        data-bs-dismiss="modal"
                                                                        aria-label="Close"
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 mt-4">
                                <div className="card h-100 mb-4">
                                    <div className="card-header pb-0 px-3">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h6 className="mb-0">Most used plans</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body pt-4 p-3">
                                        <h6 className="text-uppercase text-body text-xs font-weight-bolder mb-3">
                                            Newest
                                        </h6>
                                        <ul className="list-group">
                                            <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                                <div className="d-flex align-items-center">
                                                    <button
                                                        className="btn btn-icon-only btn-rounded btn-outline-danger mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center">
                                                        <i className="material-icons text-lg">
                                                            expand_more
                                                        </i>
                                                    </button>
                                                    <div className="d-flex flex-column">
                                                        <h6 className="mb-1 text-dark text-sm">{this.state.name_subscription_plan}</h6>
                                                        <span className="text-xs">
                                                            {moment.utc(new Date(this.state.created_at_subscription_plan)).format('DD  MMMM  YYYY')}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    className="d-flex align-items-center text-danger text-gradient text-sm font-weight-bold">
                                                    {this.state.price_subscription_plan}$
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AdminFooter />
                    </div>
                </main>
                <div className="fixed-plugin">
                    <a className="fixed-plugin-button text-dark position-fixed px-3 py-2">
                        <i className="material-icons py-2">settings</i>
                    </a>
                    <div className="card shadow-lg">
                        <div className="card-header pb-0 pt-3">
                            <div className="float-start">
                                <h5 className="mt-3 mb-0">Material UI Configurator</h5>
                                <p>See our dashboard options.</p>
                            </div>
                            <div className="float-end mt-4">
                                <button className="btn btn-link text-dark p-0 fixed-plugin-close-button">
                                    <i className="material-icons">clear</i>
                                </button>
                            </div>
                        </div>
                        <hr className="horizontal dark my-1" />
                        <div className="card-body pt-sm-3 pt-0">
                            <div>
                                <h6 className="mb-0">Sidebar Colors</h6>
                            </div>
                            <a
                                href="javascript:void(0)"
                                className="switch-trigger background-color"
                            >
                                <div className="badge-colors my-2 text-start">
                                    <span
                                        className="badge filter bg-gradient-primary active"
                                        data-color="primary"
                                    ////onClick="sidebarColor(this)"
                                    ></span>
                                    <span
                                        className="badge filter bg-gradient-dark"
                                        data-color="dark"
                                    ////onClick="sidebarColor(this)"
                                    ></span>
                                    <span
                                        className="badge filter bg-gradient-info"
                                        data-color="info"
                                    //onClick="sidebarColor(this)"
                                    ></span>
                                    <span
                                        className="badge filter bg-gradient-success"
                                        data-color="success"
                                    //onClick="sidebarColor(this)"
                                    ></span>
                                    <span
                                        className="badge filter bg-gradient-warning"
                                        data-color="warning"
                                    //onClick="sidebarColor(this)"
                                    ></span>
                                    <span
                                        className="badge filter bg-gradient-danger"
                                        data-color="danger"
                                    //onClick="sidebarColor(this)"
                                    ></span>
                                </div>
                            </a>

                            <div className="mt-3">
                                <h6 className="mb-0">Sidenav Type</h6>
                                <p className="text-sm">
                                    Choose between 2 different sidenav types.
                                </p>
                            </div>
                            <div className="d-flex">
                                <button
                                    className="btn bg-gradient-dark px-3 mb-2 active"
                                    data-class="bg-gradient-dark"
                                //onClick="sidebarType(this)"
                                >
                                    Dark
                                </button>
                                <button
                                    className="btn bg-gradient-dark px-3 mb-2 ms-2"
                                    data-class="bg-transparent"
                                //onClick="sidebarType(this)"
                                >
                                    Transparent
                                </button>
                                <button
                                    className="btn bg-gradient-dark px-3 mb-2 ms-2"
                                    data-class="bg-white"
                                //onClick="sidebarType(this)"
                                >
                                    White
                                </button>
                            </div>
                            <p className="text-sm d-xl-none d-block mt-2">
                                You can change the sidenav type just on desktop view.
                            </p>

                            <div className="mt-3 d-flex">
                                <h6 className="mb-0">Navbar Fixed</h6>
                                <div className="form-check form-switch ps-0 ms-auto my-auto">

                                    <input
                                        className="form-check-input mt-1 ms-auto"
                                        type="checkbox"
                                        id="navbarFixed"
                                    //onClick="navbarFixed(this)"
                                    />
                                </div>
                            </div>
                            <hr className="horizontal dark my-3" />
                            <div className="mt-2 d-flex">
                                <h6 className="mb-0">Light / Dark</h6>
                                <div className="form-check form-switch ps-0 ms-auto my-auto">
                                    <input
                                        className="form-check-input mt-1 ms-auto"
                                        type="checkbox"
                                        id="dark-version"
                                    // onClick="darkMode(this)"
                                    />
                                </div>
                            </div>
                            <hr className="horizontal dark my-sm-4" />
                            <a className="btn btn-outline-dark w-100"
                                href="app/src/components/Admin/Subscription plan/Plan">
                                View documentation
                            </a>
                            <div className="w-100 text-center">
                                <a
                                    className="github-button"
                                    href="https://github.com/creativetimofficial/material-dashboard"
                                    data-icon="octicon-star"
                                    data-size="large"
                                    data-show-count="true"
                                    aria-label="Star creativetimofficial/material-dashboard on GitHub"
                                >
                                    Star
                                </a>
                                <h6 className="mt-3">Thank you for sharing!</h6>
                                <a
                                    href="https://twitter.com/intent/tweet?text=Check%20Material%20UI%20Dashboard%20made%20by%20%40CreativeTim%20%23webdesign%20%23dashboard%20%23bootstrap5&amp;url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fsoft-ui-dashboard"
                                    className="btn btn-dark mb-0 me-2"
                                    target="_blank"
                                >
                                    <i className="fab fa-twitter me-1" aria-hidden="true"></i>{" "}
                                    Tweet
                                </a>
                                <a
                                    href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/material-dashboard"
                                    className="btn btn-dark mb-0 me-2"
                                    target="_blank"
                                >
                                    <i
                                        className="fab fa-facebook-square me-1"
                                        aria-hidden="true"
                                    ></i>{" "}
                                    Share
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditPlan;

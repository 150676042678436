import * as React from "react";
import {forwardRef, useEffect, useImperativeHandle} from "react";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import SubscriptionPlanService from "../../../../network/services/SubscriptionPlanService";

const PlanList = forwardRef((props, ref) => {
    const [planData, setPlanData] = React.useState([]);
    const [allowCustom, setAllowCustom] = React.useState(false);
    const [plans, setPlans] = React.useState([]);
    const [value, setValue] = React.useState(null);

    const forwardValue = (value) => {
        let result = plans.find(item => item.name === value.value);
        if (result) {
            props.parentCallback(result.id)
        }
    }

    const retrieveSubscriptionPlan = () => {
        SubscriptionPlanService.getAll((data) => {
            setPlans(data);
            data.map(plan => planData.push(plan.name))
        });

    }

    useImperativeHandle(ref, () => ({
        clear() {
            // retrieveSubscriptionPlan();
        },
    }))

    useEffect(() => {
        retrieveSubscriptionPlan();
    }, [planData]);

    return (
        <div className="example-wrapper">
            <div>
                <ComboBox
                    style={{
                        width: '350px'
                    }}

                    data={planData}
                    onChange={forwardValue}
                    //onChange={onChange}
                    allowCustom={true}
                    placeholder="Please choose a subscription plan"
                />
            </div>
        </div>
    )
});
export default PlanList;

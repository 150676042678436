import Banner from "../components/frontv2.0/common/banner/Banner";
import Footer from "../components/frontv2.0/common/footer/Footer";
import {GLOBAL_PADDING_STYLE} from "../constants/styles";

const AppLayout = (props) => {

    /**
     * States
     */
    const {children} = props;
    const globalStyle = GLOBAL_PADDING_STYLE;

    /**
     * Comportments
     */
    const content = Array.isArray(children)
        ? children.find((child) => child.type === Content)
        : children.type === Content
            ? children
            : null;
    const subHeader = Array.isArray(children)
        ? children.find((child) => child.type === SubHeader)
        : children.type === SubHeader
            ? children
            : null;
    const header = Array.isArray(children)
        ? children.find((child) => child.type === Header)
        : children.type === Header
            ? children
            : null;

    /**
     * Render
     */
    return (
        <div className={`tw-flex tw-flex-col tw-text-white `}>
            <div className="tw-h-[32px] md:tw-h-[44px] tw-sticky tw-top-0 tw-z-50">
                {/* Top Section */}
                <Banner/>
            </div>

            {/* Bottom Section */}
            <div className="tw-flex tw-h-full">
                <section className="tw-flex tw-flex-col tw-w-full">
                    {/* Top Section */}
                    <header
                        className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-bg-gray-500 tw-top-[32px] md:tw-top-[44px] tw-sticky tw-z-50">
                        {/* Header and SubHeader Section */}
                        <div
                            className="tw-flex tw-items-center tw-justify-center tw-w-full tw-h-full tw-bg-accent-gray">
                            {header
                                ? header.props.children
                                : "Please define the MainLayout.Header slot in this component"}
                        </div>

                        {/* Header and SubHeader Section */}
                        <div className={`tw-flex tw-items-center tw-justify-center tw-w-full tw-bg-accent-gray tw-border tw-border-t-1`}>
                            {subHeader
                                ? subHeader.props.children
                                : "Please define the MainLayout.SubHeader slot in this component"}
                        </div>
                    </header>

                    {/* Bottom Section */}
                    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-w-full tw-h-full">
                        {/* Content Section */}
                        <div className={`tw-w-full tw-h-full tw-overflow-y-auto tw-py-6 tw-container ${globalStyle}`}>
                            {content
                                ? content.props.children
                                : "Please define the MainLayout.Content slot in this component"}
                        </div>

                        {/* Footer Section */}
                        <footer className={` tw-flex tw-items-center tw-justify-center tw-bg-white tw-w-full`}>
                            <Footer auth={false} globalStyleOfflineMode={globalStyle}/>
                        </footer>
                    </div>
                </section>
            </div>
        </div>
    );
};

// SubComponents to simulate slots
const Header = () => null;
const SubHeader = () => null;
const Content = () => null;

// We introduce the subcomponents in the current component
AppLayout.Header = Header;
AppLayout.SubHeader = SubHeader;
AppLayout.Content = Content;

export default AppLayout;

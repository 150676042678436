import React from "react";
import ButtonCustom from "../button/ButtonCustom";
import { IMAGE_4 } from "../../../../constants/images";
import { useNavigate } from "react-router-dom";
import { MEDIA_URL_ACCOUNTING } from "../../../../network/urls/backendUrls";

const CardXLarge = ({ data }) => {
  /**
   * States
   */
  const navigate = useNavigate();

  /**
   * Comportments
   */
  const handleClick = () => {
    navigate(data.urlDetails);
  };

  const encodeUrl = (url) => {
    return url.replace(/ /g, "%20");
  };

  const shortenText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    } else {
      return text;
    }
  };

  /**
   * Render
   */
  return (
    <>
      <div
        className="md:tw-flex tw-shadow-md md:tw-flex-row tw-flex-col tw-w-full tw-overflow-tw-hidden tw-bg-gray-100 hover:tw-bg-primary-200 tw-rounded-[12px]  hover:tw-transition hover:tw-ease-in-out hover:tw-delay-150 hover:tw-duration-250"
        id={data.id}
      >
        <div
          className="tw-shadow-sm tw-hidden sm:tw-block md:tw-m-3 tw-bg-cover tw-bg-center tw-rounded-[12px]  md:tw-w-[325px] tw-w-full"
          style={{
            backgroundImage: `url(${
              data.image === null
                ? IMAGE_4
                : encodeUrl(`${MEDIA_URL_ACCOUNTING}/sessions/${data.image}`)
            })`,
          }}
        />
        <img
          className="tw-shadow-sm md:tw-hidden md:tw-m-3 tw-bg-cover tw-bg-center tw-rounded-[12px] tw-max-h-[220px]  md:tw-w-[325px] tw-w-full"
          src={
            data.image === null
              ? IMAGE_4
              : encodeUrl(`${MEDIA_URL_ACCOUNTING}/sessions/${data.image}`)
          }
          alt="session"
        />
        <div className="tw-flex tw-flex-col tw-w-full tw-p-2 sm:tw-p-3">
          <div className="tw-flex tw-flex-col tw-py-[10px] tw-text-primary">
            <div>
              {data.isCancelled ? <span
                  className="tw-text-yellow-700 tw-bg-yellow-200 tw-px-2 tw-py-1 tw-font-bold tw-rounded-[16px] tw-text-xs tw-border tw-mr-3">
                Subscription canceled
              </span>:<span
                  className="tw-text-green-700 tw-bg-accent-green tw-px-2 tw-py-1 tw-font-bold tw-rounded-[16px] tw-text-xs tw-border tw-mr-3">
                Registered
              </span>}
              {data.isInGoodStanding ? (
                <span className="tw-text-green-700 tw-bg-accent-green tw-px-2 tw-py-1 tw-font-bold tw-rounded-[16px] tw-text-xs tw-border ">
                  Access to course
                </span>
              ) : (
                <span className="tw-text-red-700 tw-bg-accent-red tw-px-2 tw-py-1 tw-font-bold tw-rounded-[16px] tw-text-xs tw-border">No access to course</span>
              )}
            </div>
            <div className="tw-flex tw-flex-col tw-text-lg tw-mt-2 ">
              <span className="tw-font-bold">
                {data.name !== "" ? data.name : ""}
              </span>
              <div dangerouslySetInnerHTML={{ __html: shortenText(data.description, 70) }} />
            </div>
          </div>
          <div className="tw-flex tw-flex-col sm:tw-flex-row tw-justify-center sm:tw-justify-between tw-mt-2">
            <div className="tw-flex tw-flex-row  tw-text-primary tw-space-x-[16px] sm:tw-mb-0 tw-mb-4 tw-w-full">
              <div className="tw-flex tw-items-center tw-border tw-text-green-700 tw-bg-accent-green tw-w-full sm:tw-w-auto tw-max-h-[44px] tw-p-4 tw-rounded-[6px]">
                <span
                  className="tw-mr-[3px] pi pi-calendar"
                  style={{ fontSize: "1.9rem" }}
                ></span>
                <div className="tw-flex tw-flex-col tw-text-sm ">
                  <span className="tw-truncate ...">Start date</span>
                  <span className="tw-font-bold tw-text-[9px] sm:tw-text-xs tw-truncate ...">
                    {data.startDate}
                  </span>
                </div>
              </div>

              <div className="tw-flex tw-items-center tw-text-white tw-bg-primary   tw-w-full sm:tw-w-auto tw-max-h-[44px] tw-p-4 tw-rounded-[6px]">
                <span
                  className="tw-mr-[3px] pi pi-calendar"
                  style={{ fontSize: "1.9rem" }}
                ></span>
                <div className="tw-flex tw-flex-col tw-text-sm ">
                  <span className="tw-truncate ...">End date</span>
                  <span className="tw-font-bold tw-text-[9px] sm:tw-text-xs tw-truncate ...">
                    {data.endDate}
                  </span>
                </div>
              </div>
            </div>
            <ButtonCustom
              onClick={handleClick}
              classes={
                "tw-bg-primary hover:tw-bg-primary-900 tw-text-white sm:tw-max-w-[137px] tw-max-h-[44px]"
              }
              label="View Details"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CardXLarge;

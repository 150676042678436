import React from "react";
import ButtonCustom from "../button/ButtonCustom";
import InputCustom from "../form/InputCustom";
import SelectCustom from "../form/SelectCustom";
import countries from "../../../../constants/countries";

const BillingDetailCheckOut = ({
  userEmail,
  data,
  isNextStep,
  onClickActionToPreviousStep,
  handleOnChange,
  sessionName,
  errors,
}) => {
  /**
   * Render
   */

  const countriesOptions = countries.map((value, index) => {
    if (value.value === data?.country) {
      return (
        <option selected key={index} value={value.value}>
          {value.label}
        </option>
      );
    } else {
      return (
        <option key={index} value={value.value}>
          {value.label}
        </option>
      );
    }
  });
  return (
    <>
      <div
        className={`xl:tw-w-1/2 xl:tw-bg-accent-gray tw-rounded-[12px] xl:tw-p-8  tw-w-full tw-mt-10 xl:tw-mt-0 ${
          !isNextStep ? "xl:tw-block" : "tw-hidden"
        }`}
      >
        <p className="tw-text-lg tw-font-semibold tw-text-primary tw-mb-2">
          {" "}
          {"Billing details of " + sessionName}{" "}
        </p>
        <div className="tw-flex tw-flex-col tw-mt-4 tw-text-gray-700">
          <form noValidate>
            <div className="tw-w-full tw-space-y-3">
              <div>
                <InputCustom
                  onChange={handleOnChange}
                  label={"E-mail Address"}
                  required={true}
                  placeHolder={"abc@email.com"}
                  value={data?.email || userEmail}
                  name="email"
                  type={"text"}
                  disabled={true}
                />
              </div>
              <div>
                <InputCustom
                  onChange={handleOnChange}
                  label={"Mobile Phone"}
                  required={true}
                  placeHolder={"Mobile Phone"}
                  value={data?.phone}
                  name="phone"
                  type={"tel"}
                  error={errors?.phone ? errors.phone : ""}
                />
              </div>
              <div>
                <InputCustom
                  onChange={handleOnChange}
                  label={"City"}
                  required={true}
                  placeHolder={"City"}
                  value={data?.city}
                  name="city"
                  type={"text"}
                  error={errors?.city ? errors.city : ""}
                />
              </div>
              <div>
                <InputCustom
                  onChange={handleOnChange}
                  label={"Region"}
                  required={true}
                  placeHolder={"Region"}
                  value={data?.region}
                  name="region"
                  type={"text"}
                  error={errors?.region ? errors.region : ""}
                />
              </div>
              <div>
                <SelectCustom
                  id={"country"}
                  required={true}
                  name={"country"}
                  label={"Country"}
                  options={countriesOptions}
                  handleOnChange={handleOnChange}
                  error={errors?.country ? errors.country : ""}
                />
              </div>
              <div>
                <InputCustom
                  onChange={handleOnChange}
                  label={"Zip Code"}
                  required={true}
                  placeHolder={"Zip Code"}
                  name="zipcode"
                  value={data?.zipcode}
                  type={"text"}
                  error={errors?.zipcode ? errors.zipcode : ""}
                />
              </div>
            </div>
          </form>

          <div className="tw-flex tw-justify-between tw-items-center tw-my-8 md:tw-hidden">
            <p className="tw-text-primary tw-font-bold">Step 1/2</p>
            <ButtonCustom
              classes={
                "tw-bg-primary hover:tw-bg-primary-900 tw-text-white tw-w-2/6"
              }
              label="Next"
              iconR={"pi pi-arrow-right tw-pl-4"}
              onClick={onClickActionToPreviousStep}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingDetailCheckOut;

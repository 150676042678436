import React from "react";
import { OUPS_ILLUSTRATION } from "../../../../constants/images";
import ButtonCustom from "../button/ButtonCustom";

const PageOops = ({text, onClick, textBtn = ''}) => {
  return (
    <>
      <div className="tw-flex sm:tw-space-x-8 tw-flex-col sm:tw-flex-row tw-w-full tw-justify-between sm:tw-px-48 tw-items-center tw-space-y-6 sm:tw-space-y-0">
        <div className="sm:tw-hidden tw-text-primary-100">
          <div>
            <img src={OUPS_ILLUSTRATION} alt="" />
          </div>
        </div>
        <div className="tw-space-y-6 tw-max-w-[270px]">
          <p className="tw-text-primary tw-font-bold tw-text-center ">{text}</p>
          <ButtonCustom
            classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
            label={textBtn}
            onClick={onClick}
          />
        </div>
        <div className="tw-hidden sm:tw-block tw-text-primary-100">
          <div>
            <img src={OUPS_ILLUSTRATION} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default PageOops;

import React, {Component} from "react";
import './Error.css';
import {Link} from "react-router-dom";

class AlreadySubscripePage extends Component {
    render() {
        return (
            <div className="main-wrapper">

                {/*<Navbar />*/}
                {/*<PageBanner/>*/}
                <br></br><br></br><br></br>
                <br></br>
                <br></br>
                <div className="bloc">
                    <img src="assets/images/errors.png" width="600px" height="170px"/>
                    <p class="text">You already have a subscription</p>
                    <Link to={{
                        pathname: "/workspace/mysessions"
                    }}>
                        <button type="button" className="btn-lg buttons">Go Back</button>
                    </Link>
                </div>
                <br></br><br></br>
                <br></br><br></br>
                <br></br>
                <br></br>
                <br></br>
            </div>

        )
    }
}

export default AlreadySubscripePage
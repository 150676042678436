import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout, refreshAndGetUser } from "../../../network/auth/Auth";
import UserService from "../../../network/services/UserService";
import AuthLayout from "../../../layouts/AuthLayout";
import Illustration from "../../../components/frontv2.0/common/illustration/Illustration";
import { FAVICON_WITHE, IMAGE_4 } from "../../../constants/images";
import Logo from "../../../components/frontv2.0/common/logo/Logo";
import TextHeader from "../../../components/frontv2.0/common/text/TextHeader";
import ButtonCustom from "../../../components/frontv2.0/common/button/ButtonCustom";
import Alert from "../../../components/frontv2.0/common/alert/Alert";
import Loader from "../../../components/frontv2.0/common/loader/Loader";

function EmailConfirmation(props) {
  const navigation = useNavigate();
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [registrationAttempt, setRegistrationAttempt] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const queryParams = new URLSearchParams(window.location.search);
    let emailValue = queryParams.get("email");
    let tokenValue = queryParams.get("token");

    if (emailValue && tokenValue) {
      setLoading(true);
      UserService.changeEmail(
        { email: emailValue, token: tokenValue },
        async (response) => {
          setRegistrationAttempt(true);
          if (response.success) {
            setRegistrationSuccess(true);
            setMessage(response.message);
            await refreshAndGetUser();
            await logout();
            setLoading(false);
          } else {
            setRegistrationSuccess(false);
            setMessage(response.message);
            setLoading(false);
          }
        }
      );
    }
  }, [navigation]);

  return (
    <AuthLayout>
      <AuthLayout.Body>
        <div className="tw-flex tw-flex-col tw-justify-center tw-px-6 tw-py-10 lg:tw-px-8 tw-items-center">
          <div className="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-sm">
            <Logo width={190} height={57} />
          </div>
          <div className="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-sm">
            <div className="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-sm">
              <div className="tw-w-full tw-mt-[42px]">
                <TextHeader label={"Confirmation E-mail"}/>
              </div>
            </div>
            {loading ? (
              <div className="tw-h-full tw-flex md:tw-justify-center tw-justify-center tw-items-center tw-mt-8">
                <Loader />
              </div>
            ) : registrationAttempt ? (
              <>
                <Alert
                  type={registrationSuccess ? "success" : "danger"}
                  message={message}
                />
                <div className="tw-mt-[42px]">
                  <Link to="/login">
                    <ButtonCustom
                      classes={
                        "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                      }
                      label="Log in"
                    />
                  </Link>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </AuthLayout.Body>

      <AuthLayout.Illustration>
        <Illustration
          favIcon={FAVICON_WITHE}
          image={IMAGE_4}
          label={`"Linux, AWS and DevOps Training."`}
        />
      </AuthLayout.Illustration>
    </AuthLayout>
  );
}

export default EmailConfirmation;

import React from "react";
import { GRADIEN_LOGIN } from "../../../../constants/images";
import Logo from "../logo/Logo";

const Illustration = ({ label, image, favIcon }) => {
  return (
    <>
      <div className="tw-h-full tw-py-10 tw-pr-10">
        <div className="tw-relative tw-h-full">
          <img
            className="tw-w-full tw-h-full tw-bg-center tw-object-cover tw-rounded-[32px] tw-bg-cover  tw-shadow-sm"
            src={image}
            alt="illustration"
          />
          <div
            className="tw-absolute tw-bottom-0 tw-left-0 tw-w-full tw-h-[50%]  tw-bg-cover  tw-rounded-[32px] tw-right-0  tw-shadow-sm"
            style={{
              backgroundImage: `url(${GRADIEN_LOGIN})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <div className="tw-absolute tw-inset-x-0 tw-bottom-0 tw-px-8 tw-py-8">
              <div className="tw-flex tw-flex-row tw-justify-between">
                <div className="tw-w-2/3">
                  <h3
                    className="tw-text-[32px] tw-italic tw-text-white tw-font-bold"
                    style={{ font: "normal normal 600 32px/48px Poppins" }}
                  >
                    {label}
                  </h3>
                </div>

                <Logo src={favIcon} width={107} />
              </div>
              <div className="tw-flex tw-flex-row tw-justify-between">
                <p className="tw-mt-2 tw-text-sm tw-text-white">
                  +1 (302) 689 3440 | contact@utrains.org
                </p>

                <p className="tw-mt-2 tw-text-sm tw-text-white">
                  © All rights reserved - Utrains.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Illustration;

import React, {Component} from "react";
import Sidebar from "../sidebar/Sidebar";
import AdminFooter from "../footer/AdminFooter";
import AdminHeader from "../header/AdminHeader";
import Other from "./other/Other";
import SessionService from "../../../network/services/SessionService";
import CourseService from "../../../network/services/CourseService";
import moment from "moment";
import '../Dashboard/AdminHome.css';
import DatePicker from "react-datepicker";
import SubscriptionService from "../../../network/services/SubscriptionService";
import {computeDateToTimeZone} from "../../../constants/Constants";

//const queryParams = new URLSearchParams(window.location.search);

class PayementManagement extends Component {

    constructor(props) {
        super(props);
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        //console.log(timezone);
        const queryParams = new URLSearchParams(window.location.search);
        this.childCompRef = React.createRef();
        this.state = {
            message_failed: 'unauthorized access to courses',
            message_ok: 'authorized course access',
            isInGoodStanding: false,
            subscriptionId: undefined,
            isChecked: false,
            isLoadingAccessType: false,
            updatePaymentDate: false,
            isLoading: false,
            openPreview: false,
            successMessage: false,
            deleteMessage: false,
            getAccessType: '',
            session: '',
            subscription: '',
            motive: '',
            deadline: null,
            user: '',
            sessionId: queryParams.get('sessionId'),
            userId: queryParams.get('userId'),
            startDate: new Date(),
            customPaymentDate: null,
            customPaymentDate2: null,
            installmentAmount: '',
            installmentRemaining: null,
            installmentTotal: null,
            totalPaid: null,
            previousTotal: null,
            message: '',
            showReduction: false,
            showUserDetails: false,
            updatePaymentReduction: false,
            timezone: timezone,
            canSendMail: false,
            discountSuccess: false,
            hasDiscount: false,
        }
        this.toggleChange = this.toggleChange.bind(this)
    }

    toggleChange = () => {
        this.setState({
            isChecked: !this.state.isChecked,
        }, () => {

            if (this.state.isChecked === false) {
                this.setState({getAccessType: "MANUAL"});
                this.changeAccessTypeToManual();

            } else if (this.state.isChecked === true) {
                this.setState({getAccessType: "NORMAL"})
                this.changeAccessTypeToNormal();
            }
        })
    }

    checkCourse = () => {
        // const queryParams = new URLSearchParams(window.location.search);
        // let sessionId = queryParams.get('sessionId');
        // let userId = queryParams.get('userId');
        CourseService.CheckAccesCourseWithUserAndSession({
            user_id: parseInt(this.state.userId),
            session_id: parseInt(this.state.sessionId)
        }, (response) => {
            if (response) {
                // console.log("--------------------------------------------");
                // console.log('response du check course');
                // console.log(response);
                this.setState({isInGoodStanding: response.isInGoodStanding, subscriptionId: response.subscription_id});

            }
        });
    }

    checkAccessType = () => {
        this.setState({isLoading: true})
        // const queryParams = new URLSearchParams(window.location.search);
        // let sessionId = queryParams.get('sessionId');
        // let userId = queryParams.get('userId');
        CourseService.CheckAccessType({
            user_id: parseInt(this.state.userId),
            session_id: parseInt(this.state.sessionId)
        }, (response) => {
            if (response) {
                const subscription = response.subscription;
                this.setState({session: response.subscription.session});
                this.setState({
                    subscription: subscription,
                    hasDiscount: subscription.discount !== null,
                    updatePaymentDate: (subscription?.customPaymentDate !== null)
                });
                this.setState({user: response.user});
                if (response.access_type === "NORMAL") {
                    this.setState({isChecked: true, getAccessType: "NORMAL"});
                } else if (response.access_type === "MANUAL") {
                    this.setState({isChecked: false, getAccessType: "MANUAL"});
                } else {
                    this.setState({isChecked: false, getAccessType: "CANCELLED"});
                }
            }
            this.setState({isLoading: false})
        });
    }

    changeAccessTypeToManual = () => {
        this.setState({isLoadingAccessType: true})
        const queryParams = new URLSearchParams(window.location.search);
        // let sessionId = queryParams.get('sessionId');
        // let userId = queryParams.get('userId');
        CourseService.ChangeAccessTypeToManual({
            user_id: parseInt(this.state.userId),
            session_id: parseInt(this.state.sessionId),
            motive: this.state.motive,
            deadline: moment(this.state.startDate).format("YYYY-MM-DDTh:mm"),
        }, (response) => {
            if (response) {
                // console.log(' reponse du accest type dans change tu manual')
                // console.log(response)
                // console.log("Access type " + response.subscriptions[0].accessType)
                if (response.subscriptions[0].accessType === "NORMAL") {
                    this.setState({isChecked: true, getAccessType: "NORMAL"});
                } else {
                    this.setState({isChecked: false, getAccessType: "MANUAL"});
                }
                // console.log("le retour de la fonction to manual")
                // console.log(response);
                this.checkCourse();
                this.checkAccessType();
            }
            this.setState({isLoadingAccessType: false, isInGoodStanding: true, motive: '', deadline: null})
            // console.log(this.state.motive);
            // console.log(this.state.deadline);
        });
    }

    changeAccessTypeToNormal = () => {
        this.setState({isLoadingAccessType: true})
        const queryParams = new URLSearchParams(window.location.search);
        // let sessionId = queryParams.get('sessionId');
        // let userId = queryParams.get('userId');
        CourseService.ChangeAccessTypeToNormal({
            user_id: parseInt(this.state.userId),
            session_id: parseInt(this.state.sessionId)
        }, (response) => {
            if (response) {
                // console.log(' reponse du accest type dans change tu normal')
                // console.log(response)
                // console.log("Access type " + response.subscriptions[0].accessType)
                if (response.subscriptions[0].accessType === "NORMAL") {
                    this.setState({isChecked: true, getAccessType: "NORMAL"});
                } else {
                    this.setState({isChecked: false, getAccessType: "MANUAL"});
                }
                // console.log("le retour de la fonction to normal")
                // console.log(response);
                this.checkCourse();
                this.checkAccessType();
            }
            this.setState({isLoadingAccessType: false, motive: null})
        });
    }

    manageManualSubscription = (e) => {
        const id = e.target.getAttribute("identifier");
        SessionService.modifyManualAccess({subscription_id: parseInt(id)}, (data) => {
            this.checkCourse();
        });
    }

    setCustomPaymentDate = () => {
        const data = {
            subscription_id: this.state.subscriptionId,
            custom_payment_date: this.state.customPaymentDate2
        }
        SessionService.modifyPaymentDate(data, (response) => {
            if (response) {
                if (response.success) {
                    this.setState({updatePaymentDate: !this.state.updatePaymentDate});
                    this.checkCourse();
                    this.checkAccessType();
                    this.childCompRef.current.showPayDate();
                    this.childCompRef.current.showHistoryFail();
                }
            }
        });
    }

    handleChange = (date) => {
        console.log(date)
        this.setState({
            startDate: date
        })
    }

    handleChangeCustomPaymentDate = (date) => {
        const lastDate = computeDateToTimeZone(date, this.state.timezone);

        this.setState({
            customPaymentDate: date,
            customPaymentDate2: new Date(lastDate)
        })
    }

    componentWillMount() {
        this.checkCourse();
        this.checkAccessType();
        //console.log(this.state.isChecked)
    }

    handleAccessChange = async () => {
        await this.checkCourse();
        await this.checkAccessType();
        //console.log(this.state.isChecked)
    }

    updatePaymentStatus = () => {
        if (this.state.customPaymentDate2 !== null || this.state.subscription.customPaymentDate !== null) {
            this.setState({updatePaymentDate: !this.state.updatePaymentDate});
        }
    }

    /*  componentDidUpdate(){
         if(this.state.isChecked){
             this.setState({getAccessType:"NORMAL"})
         }else{
             this.setState({getAccessType:"MANUAL"})

         }
     } */

    /*   checkCourse = (e) => {
          const queryParams = new URLSearchParams(window.location.search);
          let sessionId = queryParams.get('sessionId');
            CourseService.CheckAccesCourse(sessionId,userId,(data) => {
          let userId = queryParams.get('userId');

              this.setState({isInGoodStanding:data.isInGoodStanding});
              console.log(data.isInGoodStanding);
            });

        }
   */

    onChangeInstallmentAmount = (e) => {
        this.setState({
            installmentAmount: e.target.value,
        });
    }

    togglePreview = () => {
        this.setState({openPreview: !this.state.openPreview});
    }

    cancelPreview = () => {
        this.setState({
            openPreview: !this.state.openPreview,
            installmentAmount: ''
        });
    }

    cancelReductionView = () => {
        this.setState({
            updatePaymentReduction: !this.state.updatePaymentReduction
        });
    }

    getTransactionDetailsFromChild = (data) => {
        this.setState({
            installmentRemaining: data.installment_remaining,
            installmentTotal: data.installment_total,
            totalPaid: data.total_paid,
            previousTotal: data.price_total
        });
    }

    approveDiscount = () => {
        const data = {
            subscription_id: this.state.subscriptionId,
            user_id: this.state.userId,
            reduction_amount: this.state.installmentAmount,
            has_to_send_mail: this.state.canSendMail
        }
        SubscriptionService.addDiscount(data, (response) => {
            if (response) {
                if (response.success) {
                    this.setState({
                        message: response.message,
                        successMessage: true,
                        openPreview: !this.state.openPreview,
                        installmentAmount: '',
                        canSendMail: false,
                        subscription: response.subscription,
                        hasDiscount: true
                        // updatePaymentReduction: !this.state.updatePaymentReduction
                    });
                    if (response.subscription?.discount)
                        this.setState({
                            updatePaymentReduction: false
                        });
                    this.checkCourse();
                    this.checkAccessType();
                    this.childCompRef.current.showPayDate();
                    this.childCompRef.current.showHistoryFail();
                } else {
                    this.setState({
                        message: response.message,
                        deleteMessage: true
                    });
                }
            }
        });
    }

    validateReductionAmount = (reductionAmount, newTotal) => {
        let priceValidator = this.state.totalPaid <= newTotal;
        return (
            priceValidator ?
                reductionAmount > 0 ?
                    <>
                        <div
                            className="col-md-4 text-success">${reductionAmount}</div>
                        <br/>
                    </>
                    :
                    <>
                        <div
                            className="col-md-4 text-danger">${reductionAmount}</div>
                        <br/>
                        <span className='text-danger'>This operation is not allowed. You are increasing the amount instead of reducing it.</span>
                    </> :
                <>
                    <div
                        className="col-md-4 text-danger">${reductionAmount}</div>
                    <span className='text-danger'>
                        This operation is not allowed. The already paid amount cannot be greater than the remaining amount.
                    </span>
                </>
        )
    }

    render() {
        //const newTotal = this.state.totalPaid + (this.state.installmentAmount * this.state.installmentRemaining);
        const newTotal = this.state.installmentAmount * this.state.installmentTotal;
        const reductionAmount = this.state.previousTotal - newTotal;
        return (
            <div>
                <>
                    <Sidebar/>

                    <main className="mainAdmin r ms-100">
                        <AdminHeader location="Payment management / details"/>
                        <h5 className="mb-0 ml-3 fs-5 fw-bold">Session {this.state.session.name}</h5>
                        <div className="row mb-5 mt-0">
                            <div className="col-12">
                                <nav
                                    className="navbar navbar-expand-lg blur border-radius-lg top-0
                                    z-index-3 shadow position-absolute mt-4 py-2 start-0 end-0 mx-4">
                                    <div className="container-fluid ps-2 pe-0">
                                        <a className="navbar-brand font-weight-bolder ms-lg-0 ms-3 "
                                           href="app/src/components/Admin/payment-management/PayementManagement#">
                                            {this.state.isLoading ? (
                                                    <div className="spinner-border text-primary" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>

                                                ) :
                                                null} User details

                                        </a>
                                        <button className="navbar-toggler shadow-none ms-2" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#navigation2"
                                                aria-controls="navigation" aria-expanded="false"
                                                aria-label="Toggle navigation"
                                                onClick={() => this.setState({showUserDetails: !this.state.showUserDetails})}
                                        >
                                            {!this.state.showUserDetails ? "open" : "close"}
                                        </button>
                                        <div className="collapse navbar-collapse" id="navigation2">
                                            <ul className="navbar-nav mx-auto">
                                            </ul>
                                            <ul className="navbar-nav d-lg-block d-none">
                                                <li className="nav-item">
                                                    <span className="material-icons" style={{
                                                        cursor: 'pointer'
                                                    }}
                                                          onClick={() => this.setState({showUserDetails: !this.state.showUserDetails})}
                                                    >
                                                       {!this.state.showUserDetails ? "expand_more" : "expand_less"}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                        <div className="container-fluid py-4">
                            {this.state.showUserDetails ? <div className="row">
                                <div className="col-lg-12 col-md-10 mx-auto">
                                    {/*<h5 className="mb-0">Session {this.state.session.name}</h5>*/}
                                    <div className="card mt-4">
                                        <div className="card-header p-3">

                                            {/*<div className="row ">*/}
                                            {/*    <div className="col-6 d-flex align-items-center">*/}
                                            {/*        <h5 className="mb-0">User details</h5>*/}
                                            {/*        {*/}
                                            {/*            this.state.isLoading ? (*/}
                                            {/*                    <div className="spinner-border text-primary" role="status">*/}
                                            {/*                        <span className="sr-only">Loading...</span>*/}
                                            {/*                    </div>*/}

                                            {/*                ) :*/}
                                            {/*                null*/}
                                            {/*        }*/}
                                            {/*    </div>*/}
                                            {/*    <div className="col-6 text-end">*/}
                                            {/*                    <span className="material-icons" style={{*/}
                                            {/*                        cursor: 'pointer'*/}
                                            {/*                    }}*/}
                                            {/*                          onClick={() =>*/}
                                            {/*                              this.setState({showUserDetails: !this.state.showUserDetails})*/}
                                            {/*                          }>*/}
                                            {/*            {!this.state.showUserDetails ? "expand_more" : "priority_high"}</span>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}

                                            <div className="card-body p-3">
                                                <p className="text-sm mb-0">
                                                    <table className="table align-items-center mb-0">
                                                        <tbody>
                                                        <tr>
                                                            <td>Email</td>
                                                            <td>
                                                                <p className="text-xs font-weight-bold mb-0">{this.state.user.email}</p>
                                                            </td>

                                                            <td>Mobile phone</td>
                                                            <td>
                                                                <p className="text-xs font-weight-bold mb-0">{this.state.user.phone}</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Full name</td>
                                                            <td>
                                                                <p className="text-xs font-weight-bold mb-0">{this.state.user.firstName + ' ' + this.state.user.name}</p>
                                                            </td>

                                                            <td>Role</td>
                                                            <td>
                                                                <p className="text-xs font-weight-bold mb-0">{this.state.user.role}</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>City</td>
                                                            <td>
                                                                <p className="text-xs font-weight-bold mb-0">{this.state.user.city}</p>
                                                            </td>
                                                            <td>WordPress ID</td>
                                                            <td>
                                                                <p className="text-xs font-weight-bold mb-0">{this.state.user.userWpId}</p>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Country</td>
                                                            <td>
                                                                <p className="text-xs font-weight-bold mb-0">{this.state.user.country}</p>
                                                            </td>
                                                            <td>Join on</td>
                                                            <td>
                                                                <p className="text-xs font-weight-bold mb-0">{moment(this.state.user.createdAt).utc().format('DD-MM-YYYY')}</p>
                                                            </td>

                                                        </tr>
                                                        </tbody>
                                                    </table>

                                                </p>
                                            </div>
                                        </div>
                                        <div className="card-body p-3">
                                            <div className="row">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : null}
                            <div className="row mb-6 mt-0">
                                <div className="col-12">
                                    <nav
                                        className="navbar navbar-expand-lg blur border-radius-lg top-0
                                    z-index-3 shadow position-absolute mt-4 py-2 start-0 end-0 mx-4">
                                        <div className="container-fluid ps-2 pe-0">
                                            <a className="navbar-brand font-weight-bolder ms-lg-0 ms-3 "
                                               href="app/src/components/Admin/payment-management/PayementManagement#">
                                                Subscription details and discounts
                                            </a>
                                            <button className="navbar-toggler shadow-none ms-2" type="button"
                                                    data-bs-toggle="collapse" data-bs-target="#navigation2"
                                                    aria-controls="navigation" aria-expanded="false"
                                                    aria-label="Toggle navigation"
                                                    onClick={() => this.setState({showReduction: !this.state.showReduction})}
                                            >
                                                {!this.state.showReduction ? "open" : "close"}
                                            </button>
                                            <div className="collapse navbar-collapse" id="navigation2">
                                                <ul className="navbar-nav mx-auto">
                                                </ul>
                                                <ul className="navbar-nav d-lg-block d-none">
                                                    <li className="nav-item">
                                                    <span className="material-icons" style={{
                                                        cursor: 'pointer'
                                                    }}
                                                          onClick={() => this.setState({showReduction: !this.state.showReduction})}
                                                    >
                                                     {!this.state.showReduction ? "expand_more" : "expand_less"}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            {this.state.showReduction ? <div className="container-fluid py-4">
                                <div className="row">
                                    <div className="col-lg-7">
                                        <div className="card h-100">
                                            <div className="card-header pb-0 p-3">
                                                <div className="row">
                                                    <div className="col-6 d-flex align-items-center">
                                                        <h6 className="mb-0">Subscription details</h6>
                                                    </div>
                                                    <div className="col-6 text-end">
                                                        {/*<span className="material-icons" style={{*/}
                                                        {/*    cursor: 'pointer'*/}
                                                        {/*}}*/}
                                                        {/*      onClick={() =>*/}
                                                        {/*          this.setState({showSubscriptionDetails: !this.state.showSubscriptionDetails})*/}
                                                        {/*      }>*/}
                                                        {/*{!this.state.showSubscriptionDetails ? "expand_more" : "priority_high"}</span>*/}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body p-3 pb-0">
                                                <ul className="list-group">
                                                    <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                                        <div className="d-flex flex-column">
                                                            <h6 className="text-dark mb-1 font-weight-bold text-sm">Access
                                                                to course</h6>
                                                        </div>
                                                        <div className="d-flex align-items-center text-sm">
                                                            {this.state.isInGoodStanding === true ?
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input"
                                                                           type="checkbox"
                                                                           id="flexSwitchCheckDefault" checked
                                                                           identifier={this.state.subscriptionId}
                                                                           onClick={this.manageManualSubscription}
                                                                           disabled={true}
                                                                    />
                                                                    <label className="form-check-label"
                                                                           htmlFor="flexSwitchCheckDefault"> Access
                                                                        to
                                                                        course</label>
                                                                </div> :
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input"
                                                                           type="checkbox"
                                                                           id="flexSwitchCheckDefault"
                                                                           identifier={this.state.subscriptionId}
                                                                           onClick={this.manageManualSubscription}
                                                                           disabled={true}
                                                                    />
                                                                    <label className="form-check-label"
                                                                           htmlFor="flexSwitchCheckDefault"> No
                                                                        access
                                                                        to
                                                                        course</label>
                                                                </div>
                                                            }
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                                        <div className="d-flex flex-column">
                                                            <h6 className="text-dark mb-1 font-weight-bold text-sm">Access
                                                                Type</h6>
                                                        </div>
                                                        <div className="d-flex align-items-center text-sm">
                                                            <div className="form-check form-switch">
                                                                {this.state.getAccessType === 'NORMAL' && <>
                                                                    <a
                                                                        href="javascript:;"
                                                                        data-bs-toggle="modal"
                                                                        data-bs-target="#modalAddDeadline"
                                                                    >
                                                                        <input className="form-check-input"
                                                                               type="checkbox"
                                                                               id="flexSwitchCheckDefault"
                                                                               defaultChecked={this.state.isChecked}
                                                                            //onChange={this.toggleChange}
                                                                               checked={this.state.isChecked}/>
                                                                        <label className="form-check-label"
                                                                               htmlFor="flexSwitchCheckDefault"> {this.state.getAccessType}
                                                                        </label>
                                                                    </a>
                                                                </>}
                                                                {this.state.getAccessType === 'MANUAL' &&
                                                                    <>
                                                                        <input className="form-check-input"
                                                                               type="checkbox"
                                                                               id="flexSwitchCheckDefault"
                                                                               defaultChecked={this.state.isChecked}
                                                                               onChange={this.toggleChange}
                                                                               checked={this.state.isChecked}/>
                                                                        <label className="form-check-label"
                                                                               htmlFor="flexSwitchCheckDefault"> {this.state.getAccessType}
                                                                        </label>
                                                                    </>
                                                                }
                                                                {this.state.getAccessType === 'CANCELLED' &&
                                                                    <span
                                                                        className="badge badge-warning">  <b>{this.state.getAccessType}</b></span>
                                                                }
                                                            </div>
                                                            {this.state.isLoadingAccessType ? (
                                                                    <div className="spinner-border text-primary"
                                                                         role="status">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>

                                                                ) :
                                                                null
                                                            }
                                                        </div>
                                                    </li>
                                                    <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                                        <div className="d-flex flex-column">
                                                            <h6 className="text-dark mb-1 font-weight-bold text-sm">Subscription
                                                                type</h6>
                                                            {/*<span className="text-xs">#QW-103578</span>*/}
                                                        </div>
                                                        <div className="d-flex align-items-center text-sm">
                                                                <span
                                                                    className="badge badge-info">  <b>{this.state.getAccessType}</b></span>
                                                        </div>
                                                    </li>
                                                    {this.state.getAccessType === 'MANUAL' ? <li
                                                        className="list-group-item border-0 d-flex justify-content-between ps-0 border-radius-lg">
                                                        <div className="d-flex flex-column">
                                                            <h6 className="text-dark mb-1 font-weight-bold text-sm">Manual
                                                                access motive</h6>
                                                            {/*<span className="text-xs">#AR-803481</span>*/}
                                                        </div>
                                                        <div className="d-flex align-items-center text-sm">
                                                            {this.state.subscription?.manualAccessMotive}
                                                        </div>
                                                    </li> : null}
                                                    {this.state.subscription?.manualAccessMotive === 'POSTPONED_PAYMENT_DATE' ?
                                                        <li
                                                            className="list-group-item border-0 d-flex justify-content-between ps-0 border-radius-lg">
                                                            <div className="d-flex flex-column">
                                                                <h6 className="text-dark mb-1 font-weight-bold text-sm">Manual
                                                                    access deadline</h6>
                                                                {/*<span className="text-xs">#AR-803481</span>*/}
                                                            </div>
                                                            <div className="d-flex align-items-center text-sm">
                                                                {moment(this.state.subscription.manualAccessDeadline).utc().format('DD-MM-YYYY')}
                                                            </div>
                                                        </li> : null}
                                                    <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                                        <div className="d-flex flex-column">
                                                            <h6 className="text-dark mb font-weight-bold text-sm">Custom
                                                                payment date </h6>
                                                            {/*<span className="text-xs">(GMT)</span>*/}
                                                        </div>
                                                        <div className="d-flex align-items-center text-sm ">
                                                            {this.state.updatePaymentDate ?
                                                                <>
                                                                    <p>Every&nbsp;
                                                                        <span className="mb-0 bold text-xl">
                                                                      {moment.localeData().ordinal(parseInt(moment(this.state.subscription?.customPaymentDate).utc().format('DD ')))
                                                                          // +" "+ moment.utc(this.state.subscription.customPaymentDate).format("MMMM")
                                                                          + '  at '
                                                                          + moment.utc(this.state.subscription?.customPaymentDate).format("HH:mm")
                                                                          + " from " +
                                                                          moment.utc(this.state.subscription?.customPaymentDate).format("DD MMMM YYYY")
                                                                      }


                                                                </span>&nbsp;&nbsp;
                                                                        <i className="material-icons ms-auto text-dark cursor-pointer"
                                                                           data-bs-toggle="tooltip"
                                                                           data-bs-placement="top"
                                                                           title="Edit Card"
                                                                           onClick={() => this.setState({updatePaymentDate: !this.state.updatePaymentDate})}
                                                                        >edit</i>
                                                                        {/*<button className="btn btn-success"*/}
                                                                        {/*        type="button"*/}
                                                                        {/*        onClick={() => this.setState({updatePaymentDate: !this.state.updatePaymentDate})}*/}
                                                                        {/*>*/}
                                                                        {/*    edit*/}
                                                                        {/*</button>*/}
                                                                    </p>
                                                                </> :
                                                                <>
                                                                    <div style={{display: 'inline'}}>
                                                                        <div>
                                                                            <style>
                                                                                {`.date-picker input{
                                                                                       display: block;
                                                                                      width: 100%;
                                                                                      padding: 0.375rem 0.75rem;
                                                                                      margin-bottom: 0.75rem;
                                                                                      font-size: 1rem;
                                                                                      font-weight: 400;
                                                                                      line-height: 1.5;
                                                                                      color: #212529;
                                                                                      background-color: #fff;
                                                                                      background-clip: padding-box;
                                                                                      border: 1px solid #ced4da;
                                                                                      -webkit-appearance: none;
                                                                                         -moz-appearance: none;
                                                                                              appearance: none;
                                                                                      border-radius: 0.25rem;
                                                                                      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                                                                                  }`}
                                                                            </style>
                                                                            <DatePicker
                                                                                selected={this.state.customPaymentDate}
                                                                                onChange={this.handleChangeCustomPaymentDate}
                                                                                showTimeSelect
                                                                                timeFormat="HH:mm"
                                                                                timeIntervals={5}
                                                                                //  locale="en"
                                                                                timeCaption="time"
                                                                                dateFormat="MMMM d,yyyy h:mm aa"
                                                                                wrapperClassName="date-picker"
                                                                                popperPlacement="left"
                                                                                popperModifiers={{
                                                                                    flip: {
                                                                                        behavior: ["left"] // don't allow it to flip to be above
                                                                                    },
                                                                                    preventOverflow: {
                                                                                        enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                                                    },
                                                                                    hide: {
                                                                                        enabled: false // turn off since needs preventOverflow to be enabled
                                                                                    }
                                                                                }}
                                                                                placeholderText="Custom payment date"
                                                                                isClearable={true}
                                                                            />
                                                                            <br>
                                                                            </br>
                                                                            <button className="btn btn-primary"
                                                                                    type="button"
                                                                                    onClick={this.setCustomPaymentDate}
                                                                            >
                                                                                
                                                                                save
                                                                            </button>
                                                                            &nbsp;&nbsp;&nbsp;
                                                                            <button className="btn btn-secondary"
                                                                                    type="button"
                                                                                // disabled={!(this.state.updatePaymentDate || this.state.customPaymentDate2 === null)}
                                                                                    onClick={this.updatePaymentStatus}
                                                                            >
                                                                                cancel
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </>}
                                                        </div>
                                                    </li>
                                                    {/*<li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">*/}
                                                    {/*    <div className="d-flex flex-column">*/}
                                                    {/*        <h6 className="text-dark mb-1 font-weight-bold text-sm">Subscription type</h6>*/}
                                                    {/*    </div>*/}
                                                    {/*    <div className="d-flex align-items-center text-sm">*/}
                                                    {/*        <p>This user has*/}
                                                    {/*            a*/}
                                                    {/*            <span*/}
                                                    {/*                className="badge badge-info">  <b>{this.state.getAccessType}</b></span>*/}
                                                    {/*            subscription</p>*/}

                                                    {/*        {this.state.getAccessType === 'MANUAL' ? <>*/}
                                                    {/*            <p>The motive*/}
                                                    {/*                is <b>{this.state.subscription.manualAccessMotive}</b></p>*/}
                                                    {/*            {this.state.subscription.manualAccessMotive === 'POSTPONED_PAYMENT_DATE' ?*/}
                                                    {/*                <p>The deadline for this manual access is on &nbsp;*/}
                                                    {/*                    {moment(this.state.subscription.manualAccessDeadline).utc().format('DD-MM-YYYY')}*/}
                                                    {/*                </p> : null}*/}
                                                    {/*        </> : null*/}
                                                    {/*        }*/}
                                                    {/*    </div>*/}
                                                    {/*</li>*/}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="row">
                                            <div className="col-md-12 mb-lg-0 mb-4">
                                                <div className={this.state.showReduction ? "card" : ""}>
                                                    <div className="card-header pb-0 p-3">
                                                        <div className="row">
                                                            <div className="col-6 d-flex align-items-center">
                                                                <h6 className="mb-0">Manage discount</h6>
                                                            </div>
                                                            <div className="col-6 text-end">
                                                                {/*        <span className="material-icons" style={{*/}
                                                                {/*            cursor: 'pointer'*/}
                                                                {/*        }}*/}
                                                                {/*              onClick={() =>*/}
                                                                {/*                  this.setState({showReduction: !this.state.showReduction})*/}
                                                                {/*              }>*/}
                                                                {/*{!this.state.showReduction ? "expand_more" : "priority_high"}</span>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.state.hasDiscount ?
                                                        !this.state.updatePaymentReduction ?
                                                            <div className="card-body p-3">
                                                                <ul className="list-group">

                                                                    <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                                                        <div className="d-flex align-items-center">
                                                                            <button
                                                                                className="btn btn-icon-only btn-rounded btn-outline-success mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center">
                                                                                <i className="material-icons text-lg"
                                                                                   data-toggle="tooltip"
                                                                                   data-placement="top"
                                                                                   title="The plan name selected while subscribing">priority_high</i>
                                                                            </button>
                                                                            <div className="d-flex flex-column">
                                                                                <h6 className="text-dark text-sm">Subscription
                                                                                    plan name</h6>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="d-flex align-items-center text-success text-gradient text-sm font-weight-bold">
                                                                            <b>{this.state.subscription.subscriptionPlan.name}</b>
                                                                        </div>
                                                                    </li>
                                                                    <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                                                        <div className="d-flex align-items-center">
                                                                            <button
                                                                                className="btn btn-icon-only btn-rounded btn-outline-success mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center">
                                                                                <i className="material-icons text-lg"
                                                                                   data-toggle="tooltip"
                                                                                   data-placement="top"
                                                                                   title="This amount is the price of the user subscription before reduction">priority_high</i>
                                                                            </button>
                                                                            <div className="d-flex flex-column">
                                                                                <h6 className="text-dark text-sm">Subscription
                                                                                    plan price</h6>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="d-flex align-items-center text-success text-gradient text-sm font-weight-bold">
                                                                            <b>{this.state.subscription.subscriptionPlan.price}</b>
                                                                        </div>
                                                                    </li>
                                                                    <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                                                        <div className="d-flex align-items-center">
                                                                            <button
                                                                                className="btn btn-icon-only btn-rounded btn-outline-danger mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center">
                                                                                <i className="material-icons text-lg"
                                                                                   data-toggle="tooltip"
                                                                                   data-placement="top"
                                                                                   title="This amount is reduced from the subscription plan price to generate subscription price">expand_more</i>
                                                                            </button>
                                                                            <div className="d-flex flex-column">
                                                                                <h6 className="text-dark text-sm">Discount
                                                                                    amount</h6>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="d-flex align-items-center text-danger text-gradient text-sm font-weight-bold">
                                                                            - <b>${this.state.subscription.discount}</b>&nbsp;&nbsp;
                                                                            <i className="material-icons ms-auto text-dark cursor-pointer"
                                                                               data-bs-toggle="tooltip"
                                                                               data-bs-placement="top"
                                                                               title="Edit Card"
                                                                               onClick={() => this.setState({updatePaymentReduction: !this.state.updatePaymentReduction})}
                                                                            >edit</i>
                                                                        </div>
                                                                    </li>
                                                                    <li className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg">
                                                                        <div className="d-flex align-items-center">
                                                                            <button
                                                                                className="btn btn-icon-only btn-rounded btn-outline-success mb-0 me-3 p-3 btn-sm d-flex align-items-center justify-content-center">
                                                                                <i className="material-icons text-lg"
                                                                                   data-toggle="tooltip"
                                                                                   data-placement="top"
                                                                                   title="This amount is the new price of the user subscription after reduction">priority_high</i>
                                                                            </button>
                                                                            <div className="d-flex flex-column">
                                                                                <h6 className="text-dark text-sm">Subscription
                                                                                    price</h6>
                                                                            </div>
                                                                        </div>
                                                                        <div
                                                                            className="d-flex align-items-center text-success text-gradient text-sm font-weight-bold">
                                                                            <b>${this.state.subscription.price}</b>
                                                                        </div>
                                                                    </li>
                                                                </ul>

                                                            </div>
                                                            :
                                                            <div className="card-body p-3">
                                                                <div className="row">
                                                                    <label><b>The amount you will type here, represents
                                                                        the
                                                                        amount of each installment for this
                                                                        subscription</b></label>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6 mb-md-0 mb-4">
                                                                        <div className="single-form">

                                                                            <input
                                                                                type="number"
                                                                                placeholder="Installment amount"
                                                                                name="installmentAmount"
                                                                                value={this.state.installmentAmount}
                                                                                onChange={this.onChangeInstallmentAmount}
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                    <div className="col-md-6 mb-md-0 mt-4">
                                                                        {!this.state.openPreview ?
                                                                            <>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-success btn-lg btn-block"
                                                                                    onClick={this.togglePreview}
                                                                                    disabled={this.state.installmentAmount === ''}
                                                                                    data-bs-dismiss="modal"
                                                                                    aria-label="Close"
                                                                                >
                                                                                    Preview
                                                                                </button>
                                                                                {this.state.updatePaymentReduction ?
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-danger btn-md btn-block"
                                                                                        onClick={this.cancelReductionView}
                                                                                        //id={discount.id}
                                                                                        data-bs-dismiss="modal"
                                                                                        aria-label="Close"
                                                                                    >
                                                                                        Cancel
                                                                                    </button> : null}
                                                                            </> :
                                                                            <>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-warning btn-md btn-block"
                                                                                    //onClick={this.approveDiscount}
                                                                                    disabled={(reductionAmount <= 0) || (this.state.totalPaid > newTotal)}
                                                                                    //id={discount.id}
                                                                                    data-bs-target="#confirmationDiscountModal"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-dismiss="modal"
                                                                                    aria-label="Close"
                                                                                >
                                                                                    Approve
                                                                                </button>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-danger btn-md btn-block"
                                                                                    onClick={this.cancelPreview}
                                                                                    //id={discount.id}
                                                                                    data-bs-dismiss="modal"
                                                                                    aria-label="Close"
                                                                                >
                                                                                    Cancel
                                                                                </button>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                {this.state.openPreview ? <b>
                                                                    <div className="row">
                                                                        <h6>Discount preview</h6>
                                                                        <div className="row">
                                                                            <div className="col-md-8">New installment(s)
                                                                                amount
                                                                            </div>
                                                                            <div
                                                                                className="col-md-4">${this.state.installmentAmount}</div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-8">Number of
                                                                                installment(s)
                                                                                to consider
                                                                            </div>
                                                                            <div
                                                                                className="col-md-4">{this.state.installmentTotal}</div>
                                                                        </div>
                                                                        {/*<div className="row">*/}
                                                                        {/*    <div className="col-md-8">Subtotal</div>*/}
                                                                        {/*    <div*/}
                                                                        {/*        className="col-md-4">${this.state.installmentAmount * this.state.installmentRemaining}</div>*/}
                                                                        {/*</div>*/}
                                                                        <div className="row">
                                                                            <div className="col-md-8">New subscription
                                                                                total
                                                                            </div>
                                                                            <div
                                                                                className="col-md-4">${newTotal}</div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <hr/>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-8">Previous
                                                                                subscription
                                                                                total
                                                                            </div>
                                                                            <div
                                                                                className="col-md-4">${this.state.previousTotal}</div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <hr/>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-8">Reduction amount
                                                                            </div>
                                                                            {this.validateReductionAmount(reductionAmount, newTotal)}
                                                                        </div>
                                                                        <div className="row mt-4">
                                                                            <div className="col-md-4">
                                                                                <input
                                                                                    type='checkbox'
                                                                                    id='mailId'
                                                                                    name='canSendMail'
                                                                                    value={this.state.canSendMail}
                                                                                    style={{
                                                                                        width: '20px',
                                                                                        height: '20px'
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            canSendMail: !this.state.canSendMail,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-8">
                                                                                <h6 className="text-dark mb-1 font-weight-bold text-sm">
                                                                                    Do you want this user to receive a
                                                                                    notification email ?
                                                                                </h6></div>
                                                                        </div>
                                                                    </div>
                                                                </b> : null}
                                                            </div> :
                                                        <div className="card-body p-3">
                                                            <div className="row">
                                                                <label><b>The amount you will type here, represents
                                                                    the
                                                                    amount of each installment for this
                                                                    subscription</b></label>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6 mb-md-0 mb-4">
                                                                    <div className="single-form">

                                                                        <input
                                                                            type="number"
                                                                            placeholder="Installment amount"
                                                                            name="installmentAmount"
                                                                            value={this.state.installmentAmount}
                                                                            onChange={this.onChangeInstallmentAmount}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 mb-md-0 mt-4">
                                                                    {!this.state.openPreview ?
                                                                        <>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-success btn-lg btn-block"
                                                                                onClick={this.togglePreview}
                                                                                disabled={this.state.installmentAmount === ''}
                                                                                data-bs-dismiss="modal"
                                                                                aria-label="Close"
                                                                            >
                                                                                Preview
                                                                            </button>
                                                                            {this.state.updatePaymentReduction ?
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-danger btn-md btn-block"
                                                                                    onClick={this.cancelReductionView}
                                                                                    //id={discount.id}
                                                                                    data-bs-dismiss="modal"
                                                                                    aria-label="Close"
                                                                                >
                                                                                    Cancel
                                                                                </button> : null}
                                                                        </> :
                                                                        <>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-warning btn-md btn-block"
                                                                                //onClick={this.approveDiscount}
                                                                                disabled={(reductionAmount <= 0) || (this.state.totalPaid > newTotal)}
                                                                                //id={discount.id}
                                                                                data-bs-target="#confirmationDiscountModal"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-dismiss="modal"
                                                                                aria-label="Close"
                                                                            >
                                                                                Approve
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-danger btn-md btn-block"
                                                                                onClick={this.cancelPreview}
                                                                                //id={discount.id}
                                                                                data-bs-dismiss="modal"
                                                                                aria-label="Close"
                                                                            >
                                                                                Cancel
                                                                            </button>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <br/>
                                                            {this.state.openPreview ? <>
                                                                    <div className="row">
                                                                        <h6>Discount preview</h6>
                                                                        <div className="row">
                                                                            <div className="col-md-8">New installment(s)
                                                                                amount
                                                                            </div>
                                                                            <div
                                                                                className="col-md-4">${this.state.installmentAmount}</div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-8">Number of
                                                                                installment(s)
                                                                                to consider
                                                                            </div>
                                                                            <div
                                                                                className="col-md-4">{this.state.installmentTotal}</div>
                                                                        </div>
                                                                        {/*<div className="row">*/}
                                                                        {/*    <div className="col-md-8">Subtotal</div>*/}
                                                                        {/*    <div*/}
                                                                        {/*        className="col-md-4">${this.state.installmentAmount * this.state.installmentRemaining}</div>*/}
                                                                        {/*</div>*/}
                                                                        <div className="row">
                                                                            <div className="col-md-8">New subscription
                                                                                total
                                                                            </div>
                                                                            <div
                                                                                className="col-md-4">${newTotal}</div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <hr/>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-8">Previous
                                                                                subscription
                                                                                total
                                                                            </div>
                                                                            <div
                                                                                className="col-md-4">${this.state.previousTotal}</div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <hr/>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-8">Reduction amount
                                                                            </div>
                                                                            {this.validateReductionAmount(reductionAmount, newTotal)}
                                                                        </div>
                                                                        <div className="row mt-4">
                                                                            <div className="col-md-4">
                                                                                <input
                                                                                    type='checkbox'
                                                                                    id='mailId'
                                                                                    name='canSendMail'
                                                                                    value={this.state.canSendMail}
                                                                                    style={{
                                                                                        width: '20px',
                                                                                        height: '20px'
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        this.setState({
                                                                                            canSendMail: !this.state.canSendMail,
                                                                                        });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className="col-md-8">
                                                                                <h6 className="text-dark mb-1 font-weight-bold text-sm">
                                                                                    Do you want this user to receive a
                                                                                    notification email ?
                                                                                </h6></div>
                                                                        </div>
                                                                    </div>
                                                                </> :
                                                                null
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : null}
                            <br/>
                            <Other
                                ref={this.childCompRef}
                                parentCallback={this.handleAccessChange}
                                parentCallbackCheck={this.checkCourse}
                                parentCallbackData={this.getTransactionDetailsFromChild}/>
                            <AdminFooter/>
                            <div
                                className="modal fade"
                                id="modalAddDeadline"
                                tabIndex="-1"
                                aria-labelledby="modalAddDeadlineLabel"
                                aria-hidden="true">

                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">
                                                Manual access configuration
                                            </h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            ></button>
                                        </div>
                                        <div className="modal-body">
                                            <table className="table table-borderless">
                                                <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td> Choose a motive</td>
                                                    <td></td>
                                                    <td>
                                                        <select
                                                            value={this.state.motive}
                                                            onChange={(e) => this.setState({motive: e.target.value})}
                                                            className="form-control form-control-lg selectpicker">
                                                            <option selected>--Please choose a motive--
                                                            </option>
                                                            <option value='ALREADY_PAID_IN_CASH'> ALREADY_PAID_IN_CASH
                                                            </option>
                                                            <option
                                                                value='POSTPONED_PAYMENT_DATE'> POSTPONED_PAYMENT_DATE
                                                            </option>
                                                            <option
                                                                value='MANUAL_SUBSCRIPTION_FOR_TEST'> MANUAL_SUBSCRIPTION_FOR_TEST
                                                            </option>
                                                        </select>
                                                    </td>
                                                </tr>
                                                {this.state.motive === 'POSTPONED_PAYMENT_DATE' ?
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td> Choose the deadline</td>
                                                        <td></td>
                                                        <td>
                                                            <div
                                                                className="col-md-12">
                                                                <div
                                                                    className="col-md-12">
                                                                    <style>
                                                                        {`.date-picker input{
                                                                                       display: block;
                                                                                      width: 100%;
                                                                                      padding: 0.375rem 0.75rem;
                                                                                      font-size: 1rem;
                                                                                      font-weight: 400;
                                                                                      line-height: 1.5;
                                                                                      color: #212529;
                                                                                      background-color: #fff;
                                                                                      background-clip: padding-box;
                                                                                      border: 1px solid #ced4da;
                                                                                      -webkit-appearance: none;
                                                                                         -moz-appearance: none;
                                                                                              appearance: none;
                                                                                      border-radius: 0.25rem;
                                                                                      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                                                                                  }`}
                                                                    </style>
                                                                    <DatePicker
                                                                        selected={this.state.startDate}
                                                                        onChange={this.handleChange}
                                                                        showTimeSelect
                                                                        timeFormat="HH:mm"
                                                                        timeIntervals={5}
                                                                        timeCaption="time"
                                                                        dateFormat="MMMM d,yyyy h:mm aa"
                                                                        wrapperClassName="date-picker"
                                                                        popperPlacement="right"
                                                                        popperModifiers={{
                                                                            flip: {
                                                                                behavior: ["left"] // don't allow it to flip to be above
                                                                            },
                                                                            preventOverflow: {
                                                                                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                                                                            },
                                                                            hide: {
                                                                                enabled: false // turn off since needs preventOverflow to be enabled
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                                {/*<input type="date"*/}
                                                                {/*       className="form-control"*/}
                                                                {/*       name="deadline"*/}
                                                                {/*       placeholder="deadline"*/}
                                                                {/*       onChange={(e) => this.setState({deadline: e.target.value})}*/}

                                                                {/*/>*/}
                                                            </div>
                                                        </td>
                                                    </tr> : ""}
                                                {this.state.motive ? <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td> Confirm your choice here:</td>
                                                    <td></td>
                                                    <td>
                                                        <button type="button" className="btn btn-danger"
                                                                onClick={(e) => this.setState({
                                                                    motive: null
                                                                })}
                                                                data-bs-dismiss="modal"
                                                        >Cancel
                                                        </button>
                                                        &nbsp; &nbsp; &nbsp;
                                                        <button type="button" className="btn btn-success"
                                                                onClick={(e) => this.toggleChange()}
                                                                data-bs-dismiss="modal"
                                                            // disabled = {this.state.activateConfirmaButton}
                                                        >Confirm
                                                        </button>
                                                    </td>
                                                </tr> : ""}

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                data-bs-dismiss="modal"
                                            >
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    <div className="position-fixed bottom-1 end-1 z-index-2">


                        {this.state.successMessage ? (
                                <div className="toast fade show p-2 mt-2 bg-gradient-info" role="alert"
                                     aria-live="assertive" id="infoToast" aria-atomic="true">
                                    <div className="toast-header bg-transparent border-0">
                                        <i className="material-icons text-white me-2">
                                            check
                                        </i>
                                        <span className="me-auto text-white font-weight-bold">Payment management </span>
                                        <small className="text-white">just now</small>
                                        <i className="fas fa-times text-md text-white ms-3 cursor-pointer"
                                           data-bs-dismiss="toast" aria-label="Close"
                                           onClick={(e) => this.setState({successMessage: false})}
                                        ></i>
                                    </div>
                                    <hr className="horizontal light m-0"/>
                                    <div className="toast-body text-white">
                                        {this.state.message}
                                    </div>
                                </div>
                            ) :
                            (<></>)}
                        {this.state.deleteMessage ? (
                                <div className="toast fade show p-2 mt-2 bg-gradient-danger" role="alert"
                                     aria-live="assertive" id="infoToast" aria-atomic="true">
                                    <div className="toast-header bg-transparent border-0">
                                        <i className="material-icons text-white me-2">
                                            check
                                        </i>
                                        <span className="me-auto text-white font-weight-bold">Payment management</span>
                                        <small className="text-white">just now</small>
                                        <i className="fas fa-times text-md ms-3 cursor-pointer"
                                           data-bs-dismiss="toast" aria-label="Close"
                                           onClick={(e) => this.setState({deleteMessage: false})}
                                        ></i>
                                    </div>
                                    <hr className="horizontal light m-0"/>
                                    <div className="toast-body text-white">
                                        {this.state.message}
                                    </div>
                                </div>
                            ) :
                            (<></>)}
                    </div>
                    <div
                        className="modal fade"
                        id="confirmationDiscountModal"
                        tabIndex="-1"
                        aria-labelledby="confirmationDiscountModal"
                        aria-hidden="true"
                    >
                        <div
                            className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title"
                                        id="exampleModalLabel">
                                        Discount management
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    Do you want make a discount for the current subscription ?
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-danger  btn-block"
                                        onClick={this.approveDiscount}
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        Confirm
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-block"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </>
            </div>
        )
    }
}

export default PayementManagement
import React, {Component} from "react";
import Sidebar from "../sidebar/Sidebar";
import AdminFooter from "../footer/AdminFooter";
import {Link, useNavigate} from "react-router-dom";
import UserService from "../../../network/services/UserService";
import ReactPaginate from "react-paginate";
import AdminHeader from "../header/AdminHeader";
import '../Dashboard/AdminHome.css';
import './UserAdministration.css';
import {MEDIA_URL} from "../../../network/urls/backendUrls";

class UserAdministration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            sessionId: '',
            users_sessions: [],
            users_subscription: [],
            selectedVal: '',
            currentUserId: '',
            role: '',
            currentRole: '',
            elementPerPage: '',
            currentPage: '',
            lastPage: '',
            totalUsers: '',
            isLoading: true,
            successMessage: false,
            failedMessage: false,
            message: '',
            search_value: '',
        }

        this.handleChange = this.handleChange.bind(this);

    }

    handleChange(event) {
        this.setState({search_value: event.target.value});
        let data = {
            search: this.state.search_value,
            pageNumber: this.state.currentPage
        };

        UserService.getAllUsers(data, (response) => {
            // console.log(response);
            if (response) {
                this.setState({
                    users_sessions: response.pageUsers,
                    currentPage: response.pageNumber,
                    //elementPerPage: response.numberOfUsers,
                    totalUsers: response.totalNumberOfUsers,
                    //  lastPage: Math.ceil(response.totalNumberOfUsers / response.pageNumberOfUsers),
                    lastPage: Math.ceil(response.numberOfPages),
                    isLoading: false
                });
            }
        });
    }

    showUsers = () => {
        let data = {
            search: '',
            pageNumber: this.state.currentPage
        };

        UserService.getAllUsers(data, (response) => {
            // console.log(response);
            if (response) {
                console.log(response)
                this.setState({
                    users_sessions: response.pageUsers,
                    currentPage: response.pageNumber,
                    //elementPerPage: response.numberOfUsers,
                    totalUsers: response.totalNumberOfUsers,
                    lastPage: Math.ceil(response.totalNumberOfUsers / response.pageNumberOfUsers),
                    isLoading: false
                });
            }
        });
    }


    componentWillMount() {
        this.showUsers();

    }

    openProfil = (e) => {
        const id = e.target.getAttribute("id");
        const navigate = this.props.myNavigate;
        navigate({
            pathname: "/admin-profile-user",
            search: '?userId=' + id
        })
    }
    

    changeUserStatus = (e) => {
        const userId = e.target.getAttribute("id");
        let data = {
            userId: parseInt(userId)
        };

        UserService.changeUserStatus(data, (response) => {
            // if(response){
            this.notification("Account enabled successfully", true);
            this.showUsers();
//            }

        });
    }

    notification = (message, status) => {
        if (status) {
            this.setState({message: message, successMessage: true});
        } else {
            this.setState({message: message, deleteMessage: true});
        }

    }

    accountEnabled = () => {
        // const userId = e.target.getAttribute("id");
        const userId = this.state.currentUserId;
        let data = {
            userId: parseInt(userId)
        };

        UserService.enableUser(data, (response) => {
            //if(response){
            this.notification("User access modified successfully", true);
            this.showUsers();
            //}
            // console.log(response)

        });
    }

    saveUserWithRole = () => {
        let data = {
            userId: parseInt(this.state.currentUserId),
            role: this.state.role
        }
        UserService.changeUserRoleSimple(data, (response) => {
            if (response) {
                if (response.success) {
                    this.notification("Role changed successfully", true);
                    this.showUsers();
                } else {
                    this.notification(response.message, false);
                    this.showUsers();
                }
            }

        });
    }

    selectUser = (e) => {
        const id = e.target.getAttribute("id");
        const currentRole = e.target.getAttribute("role");
        //console.log("the selected user is: " + id);
        //console.log("the role user is: " + currentRole);
        this.setState({currentUserId: id, currentRole: currentRole});
    }

    addSubscription = (e) => {

        const id = e.target.getAttribute("id");

        const navigate = this.props.myNavigate;
        //alert(navigate);
        navigate({
            pathname: "/add-subscription",
            search: '?userId=' + id
        })
    }


    handlePageClick = (data) => {
        console.log(data.selected);
        const requestedPage = data.selected + 1;
        let toSend = {
            search: '',
            pageNumber: requestedPage
        };

        UserService.getAllUsers(toSend, (response) => {
            console.log(response);
            // this.setState({
            //     users_sessions: response.pageUsers,
            //     currentPage: response.pageNumber,
            //     totalUsers: response.totalNumberOfUsers,
            //     lastPage: Math.ceil(response.totalNumberOfUsers/(response.pageUsers).length),
            // });

            this.setState({
                users_sessions: response.pageUsers,
                currentPage: response.pageNumber,
                elementPerPage: response.numberOfUsers,
            });
        });
    }

    render() {
        return (
            <div>
                <>
                    <Sidebar/>
                    <main className="mainAdmin r ms-100">
                        <AdminHeader location="Users"/>

                        <div className="container-fluid py-4">
                            <div className="row">
                                <div className="col-lg-12 col-md-10 mx-auto">

                                    <div className="card mt-4">
                                        {/*<div className="card-header p-3">*/}
                                        {/*    <h5 className="mb-0">All users</h5>*/}
                                        {/*</div>*/}
                                        <div className="card-body p-3">
                                            {/*<select className="form-select" aria-label="Default select example"*/}
                                            {/*        onChange={this.handleChange}>*/}
                                            {/*    <option selected>All user</option>*/}
                                            {/*    <option value={"having_paid"}>Users having paid everything</option>*/}
                                            {/*    <option value={"not_having_paid"}>Users not having paid everything*/}
                                            {/*    </option>*/}

                                            {/*</select>*/}
                                            <div className="form-group has-search">
                                                <form>
                                                    <span className="fa fa-search form-control-feedback"></span>
                                                    <input type="text" className="form-control" placeholder="Search"
                                                           value={this.state.search_value} name="search"
                                                           onChange={(event) => this.handleChange(event)}/>
                                                </form>
                                            </div>
                                            <div className="table-responsive p-0">

                                                <table className="table align-items-center mb-0">

                                                    <thead>
                                                    <tr>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">ID</th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">General
                                                            informations
                                                        </th>
                                                        <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Address
                                                        </th>
                                                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> Phone
                                                            number
                                                        </th>
                                                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"> role</th>
                                                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Account
                                                            status
                                                        </th>
                                                        <th className="text-secondary opacity-7">Access</th>
                                                        <th className="text-secondary opacity-7">...</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        this.state.isLoading ? (
                                                            <div className="spinner-border text-primary" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>

                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {this.state.users_sessions && this.state.users_sessions.map((users_sessions) => (
                                                        <tr key={users_sessions.id}>
                                                            <td>
                                                                {users_sessions.id}
                                                            </td>
                                                            <td>
                                                                <div className="d-flex px-2 py-1">
                                                                    <div>
                                                                        {users_sessions.profilePicture === null ?
                                                                            <div>
                                                                                
                                                                                    <img src="../assets/img/team-2.jpg"
                                                                                         className="avatar avatar-sm me-3 border-radius-lg"
                                                                                         width="125" height="115"
                                                                                         alt="user1"
                                                                                         style={{cursor: "pointer"}}
                                                                                        id={users_sessions.id}
                                                                                        onClick={this.openProfil}
                                                                                         />
                                                                                
                                                                            </div>
                                                                            :
                                                                            
                                                                                <img
                                                                                    style={{cursor: "pointer"}}
                                                                                    id={users_sessions.id}
                                                                                    onClick={this.openProfil}
                                                                                    className="avatar avatar-sm me-3 border-radius-lg"
                                                                                    src={MEDIA_URL + users_sessions.profilePicture}
                                                                                    width="125" height="115"
                                                                                    alt="Shape"/>
                                                                            
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className="d-flex flex-column justify-content-center">
                                                                        <h6 className="mb-0 text-sm"
                                                                            style={{cursor: "pointer"}}
                                                                            id={users_sessions.id}
                                                                            onClick={this.openProfil}>{users_sessions.name + ' ' + users_sessions.firstName}</h6>
                                                                        <p className="text-xs text-secondary mb-0">{users_sessions.email}</p>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div
                                                                    className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-sm">{users_sessions.country}</h6>
                                                                    <p className="text-xs text-secondary mb-0">{users_sessions.province}</p>
                                                                    <p className="text-xs text-secondary mb-0">{users_sessions.city}</p>
                                                                </div>
                                                            </td>


                                                            <td className="align-middle text-center text-sm">
                                                                <p className="text-xs font-weight-bold mb-0">{users_sessions.phone}</p>
                                                            </td>

                                                            <td className="align-middle text-center">

                                                     <span
                                                         className="badge badge-sm bg-gradient-primary">{users_sessions.role}</span>

                                                                <div
                                                                    className="modal fade"
                                                                    id="exampleModalAdd"
                                                                    tabIndex="-1"
                                                                    aria-labelledby="exampleModalLabel"
                                                                    aria-hidden="true">

                                                                    <div className="modal-dialog modal-dialog-centered">
                                                                        <div className="modal-content">
                                                                            <div className="modal-header">
                                                                                <h5 className="modal-title"
                                                                                    id="exampleModalLabel">
                                                                                    ROLE OF USER
                                                                                </h5>
                                                                            </div>
                                                                            <div className="modal-body">
                                                                                <table
                                                                                    className="table table-borderless">
                                                                                    <thead>
                                                                                    {/*<tr>*/}
                                                                                    {/*    <th>ID</th>*/}
                                                                                    {/*    <th>Name</th>*/}
                                                                                    {/*    <th>...</th>*/}
                                                                                    {/*</tr>*/}
                                                                                    </thead>

                                                                                    <tbody>
                                                                                    <tr>
                                                                                        <td>CURRENT ROLE</td>
                                                                                        <td>{this.state.currentRole}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>GIVE ANOTHER ROLE</td>
                                                                                        <td>
                                                                                            <select
                                                                                                className="form-select"
                                                                                                aria-label="Default select example"
                                                                                                value={this.state.role}

                                                                                                onChange={(e) => this.setState({role: e.target.value})}>
                                                                                                <option
                                                                                                    value="...">...
                                                                                                </option>
                                                                                                <option
                                                                                                    value="USER">ROLE USER
                                                                                                </option>
                                                                                                <option
                                                                                                    value="SUPPORT">ROLE
                                                                                                    SUPPORT
                                                                                                </option>
                                                                                                <option
                                                                                                    value="ACCOUNTING">ROLE
                                                                                                    ACCOUNTING
                                                                                                </option>
                                                                                                <option
                                                                                                    value="COURSE">ROLE
                                                                                                    COURSE
                                                                                                </option>
                                                                                                <option
                                                                                                    value="ADMIN">ROLE
                                                                                                    ADMIN
                                                                                                </option>
                                                                                            </select>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td></td>
                                                                                        <td>

                                                                         


                                                                                            <button type="button"
                                                                                                    className="btn btn-sm btn-success" 
                                                                                                                   style={{
                                                                        
                                                                                                        borderRadius:"10px",
                                                                                                         fontSize:"12px",
                                                                                                         fontWeight:"bold"
                                 
                                                                                                     }}
                                                                                                    onClick={this.saveUserWithRole}
                                                                                                    data-bs-dismiss="modal"
                                                                                            >Save
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>

                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            <div className="modal-footer">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-secondary"
                                                                                    data-bs-dismiss="modal"
                                                                                >
                                                                                    Close
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <a
                                                                    href="javascript:;"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#exampleModalAdd"
                                                                >
                                                                    <span className='material-icons'
                                                                          style={{
                                                                              cursor: 'pointer',
                                                                              color: 'dodgerblue'
                                                                          }}
                                                                          id={users_sessions.id}
                                                                          role={users_sessions.role}
                                                                        //value={plan.id}
                                                                          onClick={this.selectUser}

                                                                    >edit</span>
                                                                </a>
                                                            </td>

                                                            <td className="align-middle text-center">
                                                                {users_sessions.enabled === true
                                                                    ?
                                                                    <p>Account enabled</p>
                                                                    :
                                                                    <button type="button"
                                                                    className="btn btn-sm btn-success" style={{
                                                                        
                                                                       borderRadius:"6px",
                                                                        marginBottom:"-2px",
                                                                        fontWeight:"bold"

                                                                    }}
                                                                            id={users_sessions.id}
                                                                            onClick={this.changeUserStatus}
                                                                    >Click to enable account
                                                                    </button>
                                                                }
                                                            </td>

                                                            <td className="align-middle">
                                                                {users_sessions.locked === false
                                                                    ?
                                                                    <button type="button"
                                                                            className="btn btn-sm btn-danger" style={{
                                                                                
                                                                               borderRadius:"6px",
                                                                                marginBottom:"-2px",
                                                                                fontWeight:"bold"
                                                                            }}
                                                                            id={users_sessions.id}
                                                                            onClick={this.selectUser}
                                                                            data-bs-target="#confirmationRemoveModal"
                                                                            data-bs-toggle="modal"
                                                                        //onClick={this.accountEnabled}
                                                                    >Block
                                                                    </button>
                                                                    :
                                                                    <button type="button"
                                                                    className="btn btn-sm btn-success" style={{
                                                                        cursor: 'pointer',
                                                                       borderRadius:"8px",
                                                                        marginBottom:"-2px",
                                                                        fontWeight:"bold",
                                                                       
                                                                    }}
                                                                            id={users_sessions.id}
                                                                            onClick={this.selectUser}
                                                                            data-bs-target="#confirmationRemoveModal"
                                                                            data-bs-toggle="modal"
                                                                        // onClick={this.accountEnabled}
                                                                    >Restore
                                                                    </button>
                                                                }

                                                            </td>
                                                            <td className="align-middle">
                                                            <button type="button"
                                                                    className="btn btn-sm btn-success" style={{
                                                                        cursor: 'pointer',
                                                                       borderRadius:"8px",
                                                                        marginBottom:"-2px",
                                                                        fontWeight:"bold"
                                                                    }}
                                                                        id={users_sessions.id}
                                                                        onClick={this.addSubscription}
                                                                >Manage subscription
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div
                                className="modal fade"
                                id={"confirmationRemoveModal"}
                                tabIndex="-1"
                                aria-labelledby="confirmationRemoveModal"
                                aria-hidden="true"
                            >
                                <div
                                    className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title"
                                                id="exampleModalLabel">
                                                User administration
                                            </h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            ></button>
                                        </div>
                                        <div className="modal-body">
                                            Do you want to update the selected user access ?
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-danger  btn-block"
                                                onClick={this.accountEnabled}
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                Confirm
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-secondary btn-block"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="position-fixed bottom-1 end-1 z-index-2">

                                {this.state.successMessage ? (
                                        <div className="toast fade show p-2 mt-2 bg-gradient-info" role="alert"
                                             aria-live="assertive" id="infoToast" aria-atomic="true">
                                            <div className="toast-header bg-transparent border-0">
                                                <i className="material-icons text-white me-2">
                                                    check
                                                </i>
                                                <span
                                                    className="me-auto text-white font-weight-bold">User Administration</span>
                                                <small className="text-white">just now</small>
                                                <i className="fas fa-times text-md text-white ms-3 cursor-pointer"
                                                   data-bs-dismiss="toast" aria-label="Close"></i>
                                            </div>
                                            <hr className="horizontal light m-0"/>
                                            <div className="toast-body text-white">
                                                {this.state.message}
                                            </div>
                                        </div>
                                    ) :
                                    (<></>)}
                                {this.state.failedMessage ? (
                                        <div className="toast fade show p-2 mt-2 bg-gradient-danger" role="alert"
                                             aria-live="assertive" id="infoToast" aria-atomic="true">
                                            <div className="toast-header bg-transparent border-0">
                                                <i className="material-icons text-white me-2">
                                                    check
                                                </i>
                                                <span className="me-auto text-white font-weight-bold">Contact us</span>
                                                <small className="text-white">just now</small>
                                                <i className="fas fa-times text-md text-white ms-3 cursor-pointer"
                                                   data-bs-dismiss="toast" aria-label="Close"></i>
                                            </div>
                                            <hr className="horizontal light m-0"/>
                                            <div className="toast-body text-white">
                                                Something went wrong. Please try again !
                                            </div>
                                        </div>
                                    ) :
                                    (<></>)}


                            </div>
                            <AdminFooter/>
                        </div>
                        <div className="card-footer py-4">
                            <nav aria-label="...">
                                <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={'...'}
                                    pageCount={this.state.lastPage}
                                    marginPagesDisplayed={3}
                                    pageRangeDisplayed={6}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={"pagination justify-content-end mb-0"}
                                    pageClassName={"page-item"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    pageLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}

                                />
                            </nav>
                        </div>
                    </main>
                </>
            </div>
        )
    }
}

export default function (props) {
    const navigation = useNavigate();
    //const {state} = useLocation();
    return <UserAdministration {...props} myNavigate={navigation}/>
}

import React, {Component} from "react";
import Sidebar from "../sidebar/Sidebar";
import "../../../assets/subscription_plan.css";
import ChannelService from "../../../network/services/ChannelService";
import CurrencyService from "../../../network/services/CurrencyService";
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import AdminHeader from "../header/AdminHeader";
import '../Dashboard/AdminHome.css';

class Channel extends Component {
    constructor(props) {
        super(props);

        this.refreshList = this.refreshList.bind(this);
        this.saveChannel = this.saveChannel.bind(this);
        this.updateChannel = this.updateChannel.bind(this);
        this.getId = this.getId.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onUpdateName = this.onUpdateName.bind(this);
        this.onChangeTax = this.onChangeTax.bind(this);
        this.onUpdateTax = this.onUpdateTax.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onUpdateCountry = this.onUpdateCountry.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onUpdateDescription = this.onUpdateDescription.bind(this);
        this.onChangeCurrency = this.onChangeCurrency.bind(this);
        this.onUpdateCurrency = this.onUpdateCurrency.bind(this);
        this.deleteChannel = this.deleteChannel.bind(this);
        this.onChangeValueSelect = this.onChangeValueSelect.bind(this);
        this.onUpdateValueSelect = this.onUpdateValueSelect.bind(this);
        this.state = {
            channels: [],
            currencys: [],
            name: null,
            tax: null,
            country: null,
            description: null,
            currency: null,
            currentChannel: {
                id: null,
                name: null,
                tax: null,
                country: null,
                description: null,
                currency: null
            },
        };
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value,
        });
    }

    onUpdateName(e) {
        const name = e.target.value;
        this.setState(function (prevState) {
            return {
                currentChannel: {
                    ...prevState.currentChannel,
                    name: name,
                },
            };
        });
    }

    onChangeTax(e) {
        this.setState({
            tax: e.target.value,
        });
    }

    onUpdateTax(e) {
        const tax = e.target.value;
        this.setState(function (prevState) {
            return {
                currentChannel: {
                    ...prevState.currentChannel,
                    tax: tax,
                },
            };
        });
    }

    onChangeCountry(e) {
        this.setState({
            country: e.target.value,
        });
    }

    onUpdateCountry(e) {
        const country = e.target.value;
        this.setState(function (prevState) {
            return {
                currentChannel: {
                    ...prevState.currentChannel,
                    country: country,
                },
            };
        });
    }


    onChangeDescription(e) {
        this.setState({
            description: e.target.value,
        });
    }

    onUpdateDescription(e) {
        const description = e.target.value;
        this.setState(function (prevState) {
            return {
                currentChannel: {
                    ...prevState.currentChannel,
                    description: description,
                },
            };
        });
    }


    onChangeCurrency(e) {
        this.setState({
            currency: e.target.value,
        });
    }

    onUpdateCurrency(e) {
        const currency = e.target.value;
        this.setState(function (prevState) {
            return {
                currentChannel: {
                    ...prevState.currentChannel,
                    currency: currency,
                },
            };
        });
    }

    getId(e) {
        const id = e.target.getAttribute("id");
        this.setState({
            isLoading: true
        });

        ChannelService.getById(id, (data) => {
            console.log(data);
            this.setState({
                currentChannel: data,
                isLoading: false
            });
            console.log(this.state.currentChannel.currency.id);
            const currency_id = parseInt(this.state.currentChannel.currency.id);
            CurrencyService.getById(currency_id, (data) => {
                console.log(data);
                this.setState({
                    currencie_id: data.id,
                    currencie_name: data.name,
                    isLoading: false
                });
            });
        });
    }

    saveChannel() {
        var d = new Date();
        var date = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
        var hours = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
        var fullDate = date + 'T' + hours + 'Z';

        const tab = "/api/currencies/" + this.state.currency;
        console.log(tab, this.state.currency);
        if (this.state.name && parseInt(this.state.tax) && this.state.country && this.state.description && tab) {
            var data = {
                name: this.state.name,
                tax: parseInt(this.state.tax),
                country: this.state.country,
                description: this.state.description,
                currency: tab,
                updatedAt: fullDate,
                createdAt: fullDate,
            };
            console.log(data);
            ChannelService.create(data, (dat) => {
                this.setState({
                    submitted: false,
                    edited: false,
                    successMessage: true
                });
                this.refreshList();

            });
        }

    }

    updateChannel() {
        var d = new Date();
        var date = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
        var hours = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
        var fullDate = date + 'T' + hours + 'Z';
        const tab = "/api/currencies/" + this.state.currency;
        console.log(tab, this.state.currency);
        var data = {
            name: this.state.currentChannel.name,
            tax: parseInt(this.state.currentChannel.tax),
            country: this.state.currentChannel.country,
            description: this.state.currentChannel.description,
            currency: tab,
            updatedAt: fullDate,

        };
        ChannelService.update(
            this.state.currentChannel.id,
            data,
            (dat) => {
                this.setState({
                    updateMessage: true,
                });
                this.refreshList();
            }
        );
    }

    deleteChannel(e) {
        const id = e.target.getAttribute("id");

        ChannelService.delete(id, (dat) => {
            this.setState({
                deleteMessage: true,
            });
            this.refreshList();
        });
    }

    retrieveChannel() {
        this.setState({isLoading: true});

        const page_url = 1;
        const nombre_items = 3;
        ChannelService.getAllByPage(page_url, nombre_items, (data) => {
            console.log(data)
            if (data["hydra:totalItems"] > 3) {
                console.log("superieur a " + data["hydra:totalItems"]);
                console.log("data");
                console.log(data["hydra:member"]);
                console.log(data["hydra:view"]["hydra:last"]);
                console.log(data["hydra:view"]["hydra:last"].slice(34));
                const datas = data["hydra:member"];
                const lPage = (data["hydra:view"]["hydra:last"]).slice(34);
                console.log(datas);
                this.setState({
                    channels: datas,
                    lastPage: lPage,
                    isLoading: false
                });
            } else {
                const datas = data["hydra:member"];
                console.log("egale a " + data["hydra:totalItems"]);
                this.setState({
                    channels: datas,
                    lastPage: 1,
                    isLoading: false
                });
            }
            console.log(data)
        })

    }

    refreshList() {
        this.retrieveChannel();
    }

    retrieveCurrency() {
        this.setState({isLoading: true});
        CurrencyService.getAll((data) => {
            console.log(data);
            this.setState({currencys: data["hydra:member"], isLoading: false});
        });
    }

    onChangeValueSelect(e) {
        console.log(e);
        this.setState({currency: e.value})
    }

    onUpdateValueSelect(e) {
        console.log(e);
        this.setState({currency: e.value})
    }

    componentDidMount() {
        this.retrieveChannel();
        this.retrieveCurrency();
    }

    render() {
        const {currentChannel} = this.state;

        const handlePageClick = (data) => {
            this.setState({
                isLoading: true
            });
            console.log(data.selected);
            const page_url = data.selected + 1;
            const nombre_items = 3;
            ChannelService.getAllByPage(page_url, nombre_items, (data) => {
                console.log(data["hydra:member"]);
                this.setState({channels: data["hydra:member"], isLoading: false});


            });
        }

        const options = this.state.currencys.map(d => ({
            "value": d.id,
            "label": d.name
        }))


        return (
            <div class="g-sidenav-show  bg-gray-200">
                <Sidebar/>
                <main className="mainAdmin r ms-100">
                    <AdminHeader location="Channel"/>


                    <div className="position-fixed bottom-1 end-1 z-index-2">
                        {this.state.successMessage ? (
                                <div className="toast fade show p-2 mt-2 bg-gradient-info" role="alert"
                                     aria-live="assertive" id="infoToast" aria-atomic="true">
                                    <div className="toast-header bg-transparent border-0">
                                        <i className="material-icons text-white me-2">
                                            check
                                        </i>
                                        <span className="me-auto text-white font-weight-bold">Channel </span>
                                        <small className="text-white">just now</small>
                                        <i className="fas fa-times text-md text-white ms-3 cursor-pointer"
                                           data-bs-dismiss="toast" aria-label="Close"></i>
                                    </div>
                                    <hr className="horizontal light m-0"/>
                                    <div className="toast-body text-white">
                                        channel successfully created.
                                    </div>
                                </div>
                            ) :
                            (<></>)}
                        {this.state.updateMessage ? (
                                <div className="toast fade show p-2 mt-2 bg-white" role="alert" aria-live="assertive"
                                     id="warningToast" aria-atomic="true">
                                    <div className="toast-header border-0">
                                        <i className="material-icons text-warning me-2">
                                            check
                                        </i>
                                        <span className="me-auto font-weight-bold">Channel</span>
                                        <small className="text-body">just now</small>
                                        <i className="fas fa-times text-md ms-3 cursor-pointer" data-bs-dismiss="toast"
                                           aria-label="Close"></i>
                                    </div>
                                    <hr className="horizontal dark m-0"/>
                                    <div className="toast-body">
                                        updated Channel .
                                    </div>
                                </div>
                            ) :
                            (<></>)}

                        {this.state.deleteMessage ? (
                                <div className="toast fade show p-2 mt-2 bg-white" role="alert" aria-live="assertive"
                                     id="warningToast" aria-atomic="true">
                                    <div className="toast-header border-0">
                                        <i className="material-icons text-warning me-2">
                                            check
                                        </i>
                                        <span className="me-auto font-weight-bold">Channel </span>
                                        <small className="text-body">just now</small>
                                        <i className="fas fa-times text-md ms-3 cursor-pointer" data-bs-dismiss="toast"
                                           aria-label="Close"></i>
                                    </div>
                                    <hr className="horizontal dark m-0"/>
                                    <div className="toast-body">
                                        Channel delete.
                                    </div>
                                </div>
                            ) :
                            (<></>)}
                    </div>


                    <div className="container-fluid py-4">


                        <div class="row mb-4">
                            <div class="col-lg-12 col-md-6 mb-md-0 mb-4">
                                <div class="card">
                                    <div class="card-header pb-0">
                                        <div class="row">
                                            <div class="col-lg-6 col-7">
                                                <h6>Channel</h6>
                                            </div>
                                            <div class="col-lg-6 col-5 my-auto text-end">
                                                <div class="dropdown float-lg-end pe-4">

                                                    <button type="button"
                                                            class="btn btn-secondary" data-bs-toggle="modal"
                                                            data-bs-target="#exampleModal">New Channel
                                                    </button>


                                                    {/*  <a class="cursor-pointer" id="dropdownTable" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i class="fa fa-ellipsis-v text-secondary"></i>
                                                            </a>
                                                            <ul class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5" aria-labelledby="dropdownTable">
                                                                <li><a className="dropdown-item border-radius-md" href="javascript:;">Action</a></li>
                                                                <li><a className="dropdown-item border-radius-md" href="javascript:;">Another action</a></li>
                                                                <li><a className="dropdown-item border-radius-md" href="javascript:;">Something else here</a></li>
                                                            </ul> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body px-0 pb-2">
                                        <div class="table-responsive">
                                            <table class="table align-items-center mb-0">
                                                <thead>
                                                <tr>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">ID</th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Tax</th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Country</th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">currency</th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">...</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    this.state.isLoading ? (
                                                        <div class="spinner-border text-primary" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>

                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                <>
                                                    {this.state.channels && this.state.channels.map((channel) => (
                                                        <tr key={channel.id}>
                                                            <td>
                                                                <div className="d-flex px-2 py-1">
                                                                    <div
                                                                        className="d-flex flex-column justify-content-center">
                                                                        <h6 className="mb-0 text-sm">{channel.id}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">
                                                                    <div>
                                                                        <img src="./assets/img/small-logos/logo-xd.svg"
                                                                             class="avatar avatar-sm me-3" alt="xd"/>
                                                                    </div>
                                                                    <div
                                                                        class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-sm">{channel.name}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div
                                                                        class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-sm">{channel.tax}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div
                                                                        class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-sm">{channel.country}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div
                                                                        class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-sm">{channel.currency.name}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td>


                                                                <div className="d-flex px-2 py-1">
                                                                    <div
                                                                        className="d-flex flex-column justify-content-center">
                                                                        <span className="material-icons text-primary"
                                                                              data-bs-toggle="modal"
                                                                              data-bs-target={
                                                                                  "#exampleModal" + channel.id
                                                                              }
                                                                              onClick={this.getId}
                                                                              id={channel.id}
                                                                              style={{cursor: "pointer"}}>edit</span>
                                                                        <span className='material-icons text-danger'
                                                                              data-bs-toggle="modal"
                                                                              data-bs-target={
                                                                                  "#exampleModalDelete" + channel.id
                                                                              }
                                                                              id={channel.id}
                                                                              style={{cursor: "pointer"}}>delete</span>

                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <div
                                                                className="modal fade"
                                                                id={"exampleModal" + channel.id}
                                                                tabindex="-1"
                                                                aria-labelledby="exampleModalLabel"
                                                                aria-hidden="true"
                                                            >
                                                                <div className="modal-dialog modal-dialog-centered">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5
                                                                                className="modal-title"
                                                                                id="exampleModalLabel"
                                                                            >
                                                                                Edit Channel
                                                                            </h5>
                                                                            <button
                                                                                type="button"
                                                                                className="btn-close"
                                                                                data-bs-dismiss="modal"
                                                                                aria-label="Close"
                                                                            ></button>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            {
                                                                                this.state.isLoading ? (
                                                                                    <div
                                                                                        class="spinner-border text-primary"
                                                                                        role="status">
                                                                                        <span
                                                                                            class="sr-only">Loading...</span>
                                                                                    </div>

                                                                                ) : (
                                                                                    <></>
                                                                                )
                                                                            }

                                                                            <form
                                                                                action="app/src/components/Admin/Channel/Channel#">
                                                                                <div className="single-form">
                                                                                    <label>Name</label>
                                                                                    <input
                                                                                        type="text"
                                                                                        placeholder="name"
                                                                                        name="name"
                                                                                        value={
                                                                                            currentChannel.name
                                                                                        }
                                                                                        onChange={this.onUpdateName}
                                                                                    />
                                                                                </div>
                                                                                <div className="single-form">
                                                                                    <label>Tax</label>
                                                                                    <input
                                                                                        type="text"
                                                                                        placeholder="tax"
                                                                                        name="tax"
                                                                                        value={
                                                                                            currentChannel.tax
                                                                                        }
                                                                                        onChange={this.onUpdateTax}
                                                                                    />
                                                                                </div>
                                                                                <div className="single-form">
                                                                                    <label>Country</label>
                                                                                    <input
                                                                                        type="text"
                                                                                        placeholder="country"
                                                                                        name="country"
                                                                                        value={
                                                                                            currentChannel.country
                                                                                        }
                                                                                        onChange={this.onUpdateCountry}
                                                                                    />
                                                                                </div>
                                                                                <div className="single-form">
                                                                                    <label>Currency</label>


                                                                                    <Select options={options}
                                                                                            onChange={this.onUpdateValueSelect}/>

                                                                                </div>
                                                                                <div className="single-form">
                                                                                    <label>Description</label>
                                                                                    <input
                                                                                        type="text"
                                                                                        placeholder="description"
                                                                                        name="description"
                                                                                        value={
                                                                                            currentChannel.description
                                                                                        }
                                                                                        onChange={this.onUpdateDescription}
                                                                                    />
                                                                                </div>


                                                                                <div className="single-form">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-success btn-lg btn-block"
                                                                                        onClick={this.updateChannel}
                                                                                        id={channel.id}
                                                                                        data-bs-dismiss="modal"
                                                                                        aria-label="Close"
                                                                                    >
                                                                                        Update
                                                                                    </button>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                        <div class="modal-footer">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-secondary"
                                                                                data-bs-dismiss="modal"
                                                                            >
                                                                                Close
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div
                                                                className="modal fade"
                                                                id={"exampleModalDelete" + channel.id}
                                                                tabindex="-1"
                                                                aria-labelledby="exampleModalLabel"
                                                                aria-hidden="true"
                                                            >
                                                                <div className="modal-dialog modal-dialog-centered">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title"
                                                                                id="exampleModalLabel">
                                                                                Delete channel
                                                                            </h5>
                                                                            <button
                                                                                type="button"
                                                                                className="btn-close"
                                                                                data-bs-dismiss="modal"
                                                                                aria-label="Close"
                                                                            ></button>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            Do you want to delete
                                                                        </div>
                                                                        <div class="modal-footer">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-secondary"
                                                                                data-bs-dismiss="modal"
                                                                            >
                                                                                No
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-danger  btn-block"
                                                                                onClick={this.deleteChannel}
                                                                                data-bs-dismiss="modal"
                                                                                aria-label="Close"
                                                                                id={channel.id}
                                                                            >
                                                                                <span
                                                                                    className='material-icons text-white'
                                                                                    data-bs-toggle="modal">delete</span>Yes
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </tr>
                                                    ))}
                                                </>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-footer py-4">
                            <nav aria-label="...">
                                <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={'...'}
                                    pageCount={this.state.lastPage}
                                    marginPagesDisplayed={3}
                                    pageRangeDisplayed={6}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination justify-content-end mb-0"}
                                    pageClassName={"page-item"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    pageLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}

                                />
                            </nav>
                        </div>

                    </div>
                </main>


                <div
                    className="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Add Channel
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <form action="app/src/components/Admin/Channel/Channel#">
                                    <div className="single-form">
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            placeholder="name"
                                            name="name"
                                            value={this.state.name}
                                            onChange={this.onChangeName}
                                            required
                                        />
                                    </div>
                                    <div className="single-form">
                                        <label>Tax</label>
                                        <input
                                            type="text"
                                            placeholder="tax"
                                            name="tax"
                                            value={
                                                this.state.tax
                                            }
                                            onChange={this.onChangeTax}
                                            required
                                        />
                                    </div>
                                    <div className="single-form">
                                        <label>Country</label>
                                        <input
                                            type="text"
                                            placeholder="country"
                                            name="country"
                                            value={
                                                this.state.country
                                            }
                                            onChange={this.onChangeCountry}
                                            required
                                        />
                                    </div>
                                    <div className="single-form">
                                        <label>Currency</label>


                                        <Select options={options} onChange={this.onChangeValueSelect}/>

                                    </div>

                                    <div className="single-form">
                                        <label>Description</label>
                                        <input
                                            type="text"
                                            placeholder="description"
                                            name="description"
                                            value={
                                                this.state.description
                                            }
                                            onChange={this.onChangeDescription}
                                            required
                                        />
                                    </div>


                                    <div className="single-form">
                                        <button
                                            type="button"
                                            className="btn btn-success btn-lg btn-block"
                                            onClick={this.saveChannel}
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Add
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        );
    }
}

export default Channel;

import BreadCrumb from "./BreadCrumb";

const SubHeader = ({ data }) => {
  /**
   * States
   */

  /**
   * Render
   */
  return (
    <>
      <div className="tw-w-full tw-bg-accent-gray tw-h-[56px] tw-px-[24px] tw-shadow-sm">
        <BreadCrumb data={data} />
      </div>
    </>
  );
};

export default SubHeader;

import {GLOBAL_PADDING_STYLE} from "../../../../constants/styles";
import {MOTIF_SUB_HEADER} from "../../../../constants/images";
import BreadCrumb from "./BreadCrumb";

const SubHeaderOffLine = ({data}) => {

    /**
     * States
     */
    const globalStyle = GLOBAL_PADDING_STYLE;

    /**
     * Render
     */
    return (
        <>
            <div
                className={`tw-container tw-w-full tw-h-full tw-bg-center tw-bg-no-repeat tw-bg-cover ${globalStyle}`}
                style={{backgroundImage: `url(${MOTIF_SUB_HEADER})`}}
            >
                <div className="tw-flex tw-items-center tw-overflow-x-auto tw-whitespace-nowrap ">
                    <BreadCrumb data={data}/>
                </div>
            </div>
        </>
    );
};

export default SubHeaderOffLine;



import React, {Component} from "react";
import './header.css';

class TopBar extends Component {
    render() {
        return (
            <div className="header-top d-none header d-lg-block">
                <div className="container">

                    <div className="header-top-wrapper ">

                        <div className="header-top-left">
                            {/*<p>All course of <a href="#" className="textdecoration">utrains.</a></p>*/}
                        </div>

                        <div className="header-top-medal">
                            <div className="top-info">
                                <p><i className="flaticon-phone-call"></i> <a href="tel:9702621413"
                                                                              className="textdecoration">+1 (302) 689
                                    3440</a></p>
                                <p><i className="flaticon-email"></i> <a
                                    href="mailto:address@gmail.com" className="textdecoration">contact@utrains.org</a>
                                </p>
                            </div>
                        </div>

                        <div className="header-top-right">
                            <ul className="social">
                                {/*
                                <li><a href="#"><i className="flaticon-facebook"></i></a></li>
                                <li><a href="#"><i className="flaticon-twitter"></i></a></li>
                                <li><a href="#"><i className="flaticon-skype"></i></a></li>
                                <li><a href="#"><i className="flaticon-instagram"></i></a></li>
                                */}
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default TopBar;

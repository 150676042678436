import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import SessionService from "../../network/services/SessionService";
import AppLayout from "../../layouts/AppLayout";
import CardLarge from "../../components/frontv2.0/common/card/CardLarge";
import AppHeader from "../../components/frontv2.0/common/header/AppHeader";
import SubHeaderOffLine from "../../components/frontv2.0/common/header/SubHeaderOffLine";
import SessionLoader from "../../components/frontv2.0/common/loader/SessionLoader";

const OpenedUnsecuredSessions = () => {
  const navigate = useNavigate();
  /**
   * States
   */
  const [allSessionsOpened, setAllSessionsOpened] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [itemPerPage, setItemPerPage] = useState(10);

  /**
   * Comportments
   */
  const openedSessions = () => {
    setIsLoading(true);
    SessionService.getAllUnsecuredSessionsOpened(1, 10, (response) => {
      if (response) {
        setAllSessionsOpened(response.data);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    openedSessions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClickItem = (id) => {
    navigate(`/open-sessions/details/${id}`);
  };
  /**
   * Render
   */
  return (
    <AppLayout>
      <AppLayout.Content>
        {/* py-[20px]  tw-w-full tw-px-3 sm:tw-px-0  */}
        <div className="tw-flex tw-flex-col tw-space-y-4">
          {isLoading ? (
            <>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((item, key) => {
              return <SessionLoader />;
            })}
          </>
          ) : (
            allSessionsOpened.map((item, key) => {
              return (
                <CardLarge
                  data={item}
                  onClickAction={handleOnClickItem}
                  key={key}
                />
              );
            })
          )}
        </div>
      </AppLayout.Content>

      <AppLayout.Header>
        <AppHeader />
      </AppLayout.Header>

      <AppLayout.SubHeader>
        <SubHeaderOffLine
          data={[
            { value: "Home", url: "/" },
            { value: "Opened Sessions", url: "#" },
          ]}
        />
      </AppLayout.SubHeader>
    </AppLayout>
  );
};

export default OpenedUnsecuredSessions;

import React from "react";
import ButtonCustom from "../button/ButtonCustom";
import { IMAGE_2 } from "../../../../constants/images";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { MEDIA_URL_ACCOUNTING } from "../../../../network/urls/backendUrls";
import { encodeUrl } from "../../../../constants/Constants";

const CardXSmall = ({ data, key }) => {
  const goToSessionPaidDetail = "/workspace/make-payment/details";
  const navigate = useNavigate();

  /**
   * Comportments
   */
  const handleCLick = (e) => {
    const id = e.target.value;
    navigate({
      pathname: goToSessionPaidDetail,
      search: "?subscriptionId=" + id,
    });
  };

  return (
    <>
      <div
        className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-w-full tw-overflow-tw-hidden hover:tw-bg-primary-200 tw-rounded-[12px] tw-shadow-md tw-bg-accent-gray"
        key={key}
      >
        <div className="tw-flex tw-flex-row tw-items-center tw-px-[10px] md:tw-px-[21px] tw-py-2 md:tw-py-5 tw-text-primary tw-w-full tw-space-x-3 tw-h-full">
          <img
            className="tw-shadow-sm tw-object-cover w-[50px] tw-h-[50px] md:tw-w-[100px] md:tw-h-[100px] tw-rounded-full"
            src={`${
              !data?.subscription.session?.image
                ? IMAGE_2
                : encodeUrl(
                    `${MEDIA_URL_ACCOUNTING}/sessions/${data?.subscription.session?.image}`
                  )
            }`}
            alt="Article"
          />
          <div className="tw-flex tw-flex-col tw-justify-between tw-h-full tw-py-2">
            <div className="tw-flex tw-flex-col tw-h-full">
              <div className="tw-flex tw-flex-row md:tw-space-x-3 tw-items-center">
                <p className="tw-font-bold tw-text-[8px] md:tw-text-base lg:tw-text-base xl:tw-text-base">
                  {data?.subscription.session.name}
                </p>
                {data.isInGoodStanding ? (
                  <div className="tw-hidden md:tw-block tw-bg-accent-green tw-text-secondary-green tw-rounded-lg tw-px-2 tw-py-1 tw-text-[8px] md:tw-text-base">
                    Access to the course
                  </div>
                ) : (
                  <div className="tw-hidden md:tw-block tw-bg-accent-red tw-text-secondary-red tw-rounded-lg tw-px-2 tw-py-1 tw-text-[8px] md:tw-text-base">
                    No access to the course
                  </div>
                )}
              </div>
              {/*<span className="tw-text-[8px] md:tw-text-base lg:tw-text-base xl:tw-text-base">Devops Expert Training</span>*/}
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.subscription.session.description,
                }}
              />
            </div>

            <div className="tw-flex tw-items-center">
              <span className=" tw-mr-[6px] pi pi-calendar tw-text-green-700"></span>
              <span className="tw-text-[8px] md:tw-text-normal lg:tw-text-base xl:tw-text-base">
                {moment(data?.subscription.session.startDate)
                  .utc()
                  .format("MMMM Do, YYYY")}
              </span>
            </div>
          </div>
        </div>

        <div className="tw-px-2 md:tw-px-10">
          <ButtonCustom
            value={data?.subscription.id}
            onClick={handleCLick}
            classes={
              "tw-bg-primary hover:tw-bg-primary-900 tw-text-white tw-text-[8px]  md:tw-text-base tw-truncate ..."
            }
            label="View Details"
          />
        </div>
      </div>
    </>
  );
};

export default CardXSmall;

import MainLayout from "../../../../layouts/MainLayout";
import Header from "../../../../components/frontv2.0/common/header/Header";
import SubHeader from "../../../../components/frontv2.0/common/header/SubHeader";
import CardLarge from "../../../../components/frontv2.0/common/card/CardLarge";
import SessionService from "../../../../network/services/SessionService";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import SessionLoader from "../../../../components/frontv2.0/common/loader/SessionLoader";

const NextSession = () => {
  const navigate = useNavigate();
  /**
   * States
   */
  let [isLoading, setIsLoading] = useState(false);
  let [nextSessionsData, setNextSessionsData] = useState([]);
  // eslint-disable-next-line
  let [nextSessionsDataError, setNextSessionsDataError] = useState(null);

  const getAllNextSessions = () => {
    setIsLoading(true);
    SessionService.getAllSessionsOpened((data) => {
      if (data != null) {
        console.log(data)
        
        setNextSessionsData(data.sessions.reverse());
      } else if (data?.success && data?.success === true) {
        setNextSessionsDataError(data);
      } else {
        setNextSessionsDataError({ message: "Erreur Inconnu" });
      }
      setIsLoading(false);
    });
  };

  const handleOnClickItem = (id) => {
    navigate(`/workspace/next-sessions/details/${id}`);
  };

  useEffect(() => {
    getAllNextSessions();
    window.scrollTo(0, 0);

    // eslint-disable-next-line
  }, []);

  /**
   * render
   */
  return (
    <>
      <MainLayout>
        <MainLayout.Content>
          {!isLoading ? (
            // nextSessionsData ?
            nextSessionsData.length > 0 ? (
              <div className="tw-grid tw-grid-cols-1 tw-gap-6 md:tw-grid-cols-1 lg:tw-grid-cols-1 xl:tw-grid-cols-1">
                {nextSessionsData.map((item, key) => {
                  return (
                    <CardLarge
                      key={key}
                      onClickAction={handleOnClickItem}
                      data={item}
                    />
                  );
                })}
              </div>
            ) : (
              <p className="tw-text-primary">No Next Sessions Available</p>
            )
          ) : (
            <>
              <div className="tw-grid tw-grid-cols-1 tw-gap-6 md:tw-grid-cols-1 lg:tw-grid-cols-1 xl:tw-grid-cols-1">
                {[1, 2, 3, 4, 5, 6, 7, 8].map((item, key) => {
                  return <SessionLoader />;
                })}
              </div>
            </>
          )}
        </MainLayout.Content>

        <MainLayout.Header>
          <Header />
        </MainLayout.Header>

        <MainLayout.SubHeader>
          <SubHeader
            data={[
              { value: "Home", url: "/workspace" },
              { value: "Next Sessions", url: "#" },
            ]}
          />
        </MainLayout.SubHeader>
      </MainLayout>
    </>
  );
};

export default NextSession;

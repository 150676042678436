import React, { Component } from "react";

import Sidebar from "../sidebar/Sidebar";
import AdminFooter from "../footer/AdminFooter";
import SubscriptionPlanService from "../../../network/services/SubscriptionPlanService";
import SessionService from "../../../network/services/SessionService";
import moment from "moment/moment";
import UserService from "../../../network/services/UserService";
import { Link } from "react-router-dom";
import AdminHeader from "../header/AdminHeader";
import DatePicker from 'react-datepicker';
import InputCustom from "../../frontv2.0/common/form/InputCustom";
import ButtonCustom from "../../frontv2.0/common/button/ButtonCustom"
import { Dialog } from 'primereact/dialog';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import CourseService from "../../../network/services/CourseService";

import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { createRef } from 'react';



class AddSubscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            message: "",
            isLoading: false,
            subscription_id: '',
            sessions: [],
            subscriptionAssociatedSession: [],
            selectedPlanName: "",
            selectedPlanId: "",
            previewSubscriptionPlanId: "",
            session: '',
            course: '',
            nextSessionName: '',
            plan: '',
            coursePlan: '',
            motive: '',
            courseMotive: '',
            edition: false,
            deadline: '',
            sessionId: null,
            courseId: '',
            nextSessionId: '',
            planId: null,
            coursePlanId: '',
            subscriptionPlans: [],
            subscriptions: [],
            courseSubscriptions: [],
            sessionSubscriptions: [],
            courses: [],
            selectedUser: '',
            currentSession: '',
            options: [
                { label: "days", value: "days" },
                { label: "weeks", value: "weeks" },
                { label: "months", value: "months" },
                { label: "years", value: "years" }
            ],
            successMessage: false,
            failedMessage: false,
            isSendCourse: false,
            isSend: false,
            disabled: false,
            message: null,
            user: '',
            startDate: new Date(),

            visible: false,
            session: '',
            newDate: '',
            subscriptionPlanId: "",
            subscriptionId: ""

        }
        this.toast = createRef();
    }

    notification = (message) => {
        this.setState({ message: message, successMessage: true });
    }

    forwardData = (e) => {
        const planId = parseInt(e.target.value);
        this.setState({ planId: planId });
        let session = this.state.sessions.filter(e => e.id === this.state.sessionId)[0];
        let plan = this.state.subscriptionPlans.filter(e => e.id === planId)[0];
        this.setState({ session: session });
        this.setState({ plan: plan });
    }

    forwardDataCourses = (e) => {
        const planId = parseInt(e.target.value);
        this.setState({ coursePlanId: planId });
        let course = this.state.courses.filter(e => e.id === this.state.courseId)[0];
        let coursePlan = this.state.subscriptionPlans.filter(e => e.id === planId)[0];
        this.setState({ course: course });
        this.setState({ coursePlan: coursePlan });
    }

    addSubscription = () => {

        //this.state.sessionSubscriptions.filter(e => console.log(e.session.id));

        let tabPlanNew = this.state.sessionSubscriptions.filter(e => e.session.id === this.state.sessionId);

        if (tabPlanNew.length < 1) {
            const queryParams = new URLSearchParams(window.location.search);
            let id = queryParams.get('userId');

            let data = {
                global_id: parseInt(id),
                plan_id: this.state.planId,
                session_id: this.state.sessionId,
                first_name: this.state.user.firstName,
                name: this.state.user.name,
                email: this.state.user.email,
                phone_number: this.state.user.phone,
                street_address: this.state.user.streetAdress,
                city: this.state.user.city,
                country: this.state.user.country,
                motive: this.state.motive,
                // deadline: this.state.deadline,
                deadline: moment(this.state.startDate).format("YYYY-MM-DDTh:mm"),
            }

            this.setState({ isSend: true, disabled: true })

            UserService.addSubscription(data, (response) => {
                if (response) {
                    this.notification("Subscription has been done successfully");
                    this.setState({
                        sessionId: null,
                        planId: null,
                        deadline: null,
                        motive: null,
                        isSend: false,
                        disabled: false
                    }, () => {
                        this.showUserSession();
                    })
                }
            });
        } else {
            this.notification("The user already has a subscription");
        }
    }

    addCourseSubscription = () => {

        let data = {
            global_id: parseInt(this.state.id),
            plan_id: this.state.coursePlanId,
            course_id: this.state.courseId,
            first_name: this.state.user.firstName,
            name: this.state.user.name,
            email: this.state.user.email,
            phone_number: this.state.user.phone,
            street_address: this.state.user.streetAdress,
            city: this.state.user.city,
            country: this.state.user.country,
            motive: this.state.courseMotive,
            deadline: ''
        }

        this.setState({ isSendCourse: true, disabled: true })

        UserService.addCourseSubscription(data, (response) => {
            if (response) {
                if (response.success) {
                    this.notification("Subscription has been done successfully");
                    this.setState({
                        courseId: '',
                        courseMotive: '',
                        coursePlanId: '',
                        deadline: null,
                        coursePlan: '',
                        isSendCourse: false,
                        disabled: false
                    }, () => {
                        this.showUserSession();
                    })
                } else {
                    this.notification("You have already subscribed to this course.");
                    this.setState({
                        courseId: '',
                        courseMotive: '',
                        coursePlanId: '',
                        deadline: null,
                        coursePlan: '',
                        isSendCourse: false,
                        disabled: false
                    })
                }
            }
        });

        // else {
        //     this.notification("The user already has a subscription");
        // }

    }

    editSubscription = () => {

        let data = {
            global_user_id: parseInt(this.state.id),
            subscription_id: this.state.subscription_id,
            session_id: this.state.nextSessionId,
        }

        this.setState({ isSend: true, disabled: true })

        UserService.editSubscription(data, (response) => {
            if (response) {
                this.notification("Subscription has been edited successfully");
                this.clearSubscriptionEdition();
                this.showUserSession();
            }
        });
    }

    showSession = () => {
        let data = {
            session_subscription_status: "",
            session_publication_status: "",
            session_progression_status: "",
        }
        SessionService.getSessionFilter(data, (response) => {
            if (response) {

                const datas = response["data"];

                this.setState({
                    sessions: datas
                });

            }
        });

        // SessionService.getAll((data) => {
        //     console.log("les sessions")
        //     this.setState({sessions: data});
        // })
    }

    retrieveSubscriptionPlan = () => {
        // this.setState({isLoading: true});
        SubscriptionPlanService.getAll((data) => {
            this.setState({ subscriptionPlans: data });
        });
    }

    getId = (id) => {
        UserService.getById(id, (data) => {
            this.setState({
                user: data,
            });
        });
    }

    showUserSession = () => {
        const queryParams = new URLSearchParams(window.location.search);
        let id = queryParams.get('userId');
        SessionService.getUserSessionsWithId({ user_id: parseInt(id) }, (data) => {
            if(data){
                let tabCourses = data.filter((e) => e.course !== null);
                let tabSessions = data.filter((e) => e.session !== null);
               
                this.setState({
                    subscriptions: data,
                    courseSubscriptions: tabCourses,
                    sessionSubscriptions: tabSessions,
                    subscriptionAssociatedSession: data[0].session.subscriptionPlans,
                    // previewSubscriptionPlanId: data[0].subscriptionPlan.id,
                    subscriptionId: data[0].id,
                    subscriptionPlanId: data[0].subscriptionPlan.id
    
                });
            }
            
        });
    }

    manageManualSubscription = (e) => {
        const id = e.target.getAttribute("identifier");
        SessionService.modifyManualAccess({ subscription_id: parseInt(id) }, (data) => {
            if (data) {
                if (data.accessType === 'MANUAL') {
                    this.notification("Manual access granted")
                } else {
                    this.notification("Manual access removed")
                }
            }

            this.showUserSession();
        });
    }

    handleChange = (date) => {
        this.setState({
            startDate: date
        })
    }

    clearSubscriptionEdition = () => {
        this.setState({
            edition: false,
            sessionId: null,
            planId: null,
            deadline: null,
            motive: null,
            isSend: false,
            disabled: false,
            nextSessionName: '',
            nextSessionId: ''
        })
    }

    retrieveCourses = () => {
        // setIsLoading(true);
        CourseService.allCourses((response) => {
            if (response) {
                this.setState({
                    courses: response.data,
                });
            }
        });
    };


    handleCancelClick = () => {
        this.setState({ visible: false });
        this.resetForm();
    };


    handleNewDateChange = (event) => {
        this.setState({ newDate: event.target.value });
    };

    handleButtonClick = () => {
        this.setState({ visible: true });
    };


    resetForm = () => {
        this.setState({
            selectedPlanId: "",
        });
    };



    onHide = () => {
        this.setState({ visible: false });
    };



    confirm1 = (event) => {
        event.preventDefault();
        confirmPopup({
            target: event.currentTarget,
            message: 'Are you sure you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            // accept: this.state.accept,
            accept: () => {

                this.setState({ isLoading: true });

                let data = {
                    subscription_id: this.state.subscriptionId,
                    subscription_plan_id: this.state.selectedPlanId
                }
                SessionService.modifySubscriptionPlan(data, (response) => {
                    if (response) {
                        if (response.success) {
                            //setShowSuccessMessage(true);
                            this.setState({ message: response.message });
                            this.showSuccess(response.message);

                        }

                    } else {

                        this.showError("Action not authorized because the amount already paid is greater than the amount of the subscription plan!!");
                    }
                    this.showUserSession();
                });
                this.resetForm();
                this.setState({ visible: false });
                // console.log('User clicked on Yes');
                this.setState({ isLoading: false });
            },

            reject: () => {
                this.setState({ visible: false });
                this.resetForm();
                // console.log('User clicked on No');

            }
        });
    };


    showSuccess = (message) => {
        if (this.toast.current) {
            this.toast.current.show({
                severity: "success",
                summary: "Success",
                detail: message,
                life: 5000,
            });
        }
    };

    showError = (message) => {
        if (this.toast.current) {
            this.toast.current.show({
                severity: "error",
                summary: "Error",
                detail: message,
                life: 3000,
            });
        }
    };



    handlePlanChange = (event) => {
        const selectedPlanId = event.target.value;
        const selectedPlanName = event.target.options[event.target.selectedIndex].text;
        this.setState({ selectedPlanId, selectedPlanName });
    }



    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        let id = queryParams.get('userId');
        this.setState({ id: id })
        this.getId(id);
        this.retrieveSubscriptionPlan();
        this.showSession();
        this.retrieveCourses();
        this.showUserSession();
    }

    render() {
        const { visible } = this.state;
        return (

            <>
                <Toast ref={this.toast} position="top-right" />

                <div className="g-sidenav-show  bg-gray-200">
                    <Sidebar />
                    <main className="mainAdmin r ms-100">
                        <AdminHeader location="Users administration / Add subscription" />

                        <div className="container-fluid py-4">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">

                                        <div className="col-md-12 mb-lg-0 mb-4">
                                            <div className="card mt-4">
                                                {/*<div className="card-header pb-0 p-3">*/}
                                                {/*    <div className="row">*/}
                                                {/*        <div className="col-6 d-flex align-items-center">*/}
                                                {/*            <h6 className="mb-0">Details on Session*/}
                                                {/*            </h6>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                <div className="card-header p-3">
                                                    <h5 className="text-lg font-weight-bold mb-0"

                                                    >User details
                                                    </h5>
                                                    {
                                                        this.state.isLoading ? (
                                                            <div className="spinner-border text-primary" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>

                                                        ) :
                                                            null
                                                    }
                                                    <br />
                                                    <br />
                                                    <p className="text-sm mb-0">
                                                        <table className="table align-items-center mb-0">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Email</td>
                                                                    <td>
                                                                        <p className="text-xs font-weight-bold mb-0">{this.state.user.email}</p>
                                                                    </td>

                                                                    <td>Mobile phone</td>
                                                                    <td>
                                                                        <p className="text-xs font-weight-bold mb-0">{this.state.user.phone}</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Full name</td>
                                                                    <td>
                                                                        <p className="text-xs font-weight-bold mb-0">{this.state.user.firstName + ' ' + this.state.user.name}</p>
                                                                    </td>

                                                                    <td>Role</td>
                                                                    <td>
                                                                        <p className="text-xs font-weight-bold mb-0">{this.state.user.role}</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>City</td>
                                                                    <td>
                                                                        <p className="text-xs font-weight-bold mb-0">{this.state.user.city}</p>
                                                                    </td>
                                                                    <td>WordPress ID</td>
                                                                    <td>
                                                                        <p className="text-xs font-weight-bold mb-0">{this.state.user.user_wp_id}</p>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Country</td>
                                                                    <td>
                                                                        <p className="text-xs font-weight-bold mb-0">{this.state.user.country}</p>
                                                                    </td>
                                                                    {/*<td>Join on</td>*/}
                                                                    {/*<td>*/}
                                                                    {/*    <p className="text-xs font-weight-bold mb-0">{moment(this.state.user.createdAt).utc().format('DD  MMMM  YYYY')}</p>*/}
                                                                    {/*</td>*/}

                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">

                                        <div className="col-md-12 mb-lg-0 mb-4">
                                            <div className="card mt-4">
                                                {/*<div className="card-header pb-0 p-3">*/}
                                                {/*    <div className="row">*/}
                                                {/*        <div className="col-6 d-flex align-items-center">*/}
                                                {/*            <h6 className="mb-0">Details on Session*/}
                                                {/*            </h6>*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                <div className="card-header p-3">
                                                    <h5 className="text-lg font-weight-bold mb-0" >Manual subscription interface</h5>
                                                </div>
                                                <div className="col-md-12">
                                                    <table className="table table-borderless">

                                                        <tbody>
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td> Choose a session</td>
                                                                <td></td>
                                                                <td>
                                                                    <select
                                                                        onChange={(e) => this.setState({ sessionId: parseInt(e.target.value) })}
                                                                        className="form-control form-control-lg selectpicker">
                                                                        <option>--Please choose a session--</option>
                                                                        {this.state.sessions && this.state.sessions.map((session) => (
                                                                            <option key={session.id} value={session.id}>
                                                                                {session.name}
                                                                            </option>
                                                                        ))}
                                                                    </select></td>
                                                            </tr>
                                                            {this.state.sessionId ?
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td> Choose a subscription plan</td>
                                                                    <td></td>
                                                                    <td>
                                                                        <select
                                                                            onChange={(e) => this.forwardData(e)}
                                                                            className="form-control form-control-lg selectpicker">
                                                                            <option>--Please choose an subscription plan--
                                                                            </option>
                                                                            {this.state.subscriptionPlans && this.state.subscriptionPlans.map((subscriptionPlan) => (
                                                                                <option key={subscriptionPlan.id}
                                                                                    value={subscriptionPlan.id}>
                                                                                    {subscriptionPlan.name}
                                                                                </option>
                                                                            ))}
                                                                        </select></td>
                                                                </tr> : ""}
                                                            {this.state.planId ?
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td> Choose a motive</td>
                                                                    <td>
                                                                    </td>
                                                                    <td>
                                                                        <select
                                                                            onChange={(e) => this.setState({ motive: e.target.value })}
                                                                            className="form-control form-control-lg selectpicker">
                                                                            <option>--Please choose a motive--
                                                                            </option>
                                                                            <option> ALREADY_PAID_IN_CASH</option>
                                                                            <option> POSTPONED_PAYMENT_DATE</option>
                                                                            <option> MANUAL_SUBSCRIPTION_FOR_TEST</option>
                                                                        </select>
                                                                    </td>
                                                                </tr> : ""}
                                                            {this.state.motive === 'POSTPONED_PAYMENT_DATE' ?
                                                                <tr>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td> Choose the deadline</td>
                                                                    <td></td>
                                                                    <td>
                                                                        <div
                                                                            className="col-md-12">
                                                                            <style>
                                                                                {`.date-picker input{
                                                                                       display: block;
                                                                                      width: 100%;
                                                                                      padding: 0.375rem 0.75rem;
                                                                                      font-size: 1rem;
                                                                                      font-weight: 400;
                                                                                      line-height: 1.5;
                                                                                      color: #212529;
                                                                                      background-color: #fff;
                                                                                      background-clip: padding-box;
                                                                                      border: 1px solid #ced4da;
                                                                                      -webkit-appearance: none;
                                                                                         -moz-appearance: none;
                                                                                              appearance: none;
                                                                                      border-radius: 0.25rem;
                                                                                      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                                                                                  }`}
                                                                            </style>
                                                                            <DatePicker
                                                                                selected={this.state.startDate}
                                                                                onChange={this.handleChange}
                                                                                showTimeSelect
                                                                                timeFormat="HH:mm"
                                                                                timeIntervals={5}
                                                                                timeCaption="time"
                                                                                dateFormat="MMMM d,yyyy h:mm aa"
                                                                                wrapperClassName="date-picker"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr> : ""}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.motive ?
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row">

                                            <div className="col-md-12 mb-lg-0 mb-4">
                                                <div className="card mt-4">
                                                    <div className="card-header pb-0 p-3">
                                                        <div className="row">
                                                            <div className="col-6 d-flex align-items-center">
                                                                <h6 className="mb-0">Details on Session</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="d-flex flex-column">
                                                            <br />
                                                            <center>
                                                                <h2>You want to add a new subscription with
                                                                    those details :</h2>
                                                                <table className="table center">

                                                                    <tbody>
                                                                        {/*<tr>*/}
                                                                        {/*    <td>*/}
                                                                        {/*        <hr/>*/}
                                                                        {/*    </td>*/}
                                                                        {/*    <td>*/}
                                                                        {/*        <hr/>*/}
                                                                        {/*    </td>*/}
                                                                        {/*</tr>*/}
                                                                        <tr>
                                                                            <td> Session name</td>
                                                                            <td>{this.state.session.name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td> Start date</td>
                                                                            <td> {moment(this.state.session.startDate).utc().format('DD  MMMM  YYYY')}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td> End date</td>
                                                                            <td> {moment(this.state.session.endDate).utc().format('DD  MMMM  YYYY')}</td>
                                                                        </tr>
                                                                        {/*<tr>*/}
                                                                        {/*    <td>*/}
                                                                        {/*        <hr/>*/}
                                                                        {/*    </td>*/}
                                                                        {/*    <td>*/}
                                                                        {/*        <hr/>*/}
                                                                        {/*    </td>*/}
                                                                        {/*</tr>*/}
                                                                        <tr>
                                                                            <td> Last name</td>
                                                                            <td> {this.state.user.name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td> First name</td>
                                                                            <td> {this.state.user.firstName}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td> Email</td>
                                                                            <td> {this.state.user.email}</td>
                                                                        </tr>
                                                                        {/*<tr>*/}
                                                                        {/*    <td>*/}
                                                                        {/*        <hr/>*/}
                                                                        {/*    </td>*/}
                                                                        {/*    <td>*/}
                                                                        {/*        <hr/>*/}
                                                                        {/*    </td>*/}
                                                                        {/*</tr>*/}
                                                                        <tr>
                                                                            <td> Subscription name</td>
                                                                            <td>{this.state.plan.name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td> Subscription price</td>
                                                                            <td>{this.state.plan.price}</td>
                                                                        </tr>
                                                                        {/*<tr>*/}
                                                                        {/*    <td>*/}
                                                                        {/*        <hr/>*/}
                                                                        {/*    </td>*/}
                                                                        {/*    <td>*/}
                                                                        {/*        <hr/>*/}
                                                                        {/*    </td>*/}
                                                                        {/*</tr>*/}
                                                                        <tr>
                                                                            <td> Manual access motive</td>
                                                                            <td>{this.state.motive}</td>
                                                                        </tr>
                                                                        {this.state.deadline ? <tr>
                                                                            <td> Deadline on</td>
                                                                            <td>{this.state.deadline}</td>
                                                                        </tr> : ''}
                                                                        {/*<tr>*/}
                                                                        {/*    <td>*/}
                                                                        {/*        <hr/>*/}
                                                                        {/*    </td>*/}
                                                                        {/*    <td>*/}
                                                                        {/*        <hr/>*/}
                                                                        {/*    </td>*/}
                                                                        {/*</tr>*/}

                                                                    </tbody>
                                                                </table>
                                                                <h3> Confirm your choice here:</h3>
                                                                <button type="button" className="btn btn-danger"
                                                                    disabled={this.state.disabled}
                                                                    onClick={(e) => this.setState({
                                                                        sessionId: null,
                                                                        planId: null,
                                                                        deadline: null,
                                                                        motive: null,
                                                                        session: '',
                                                                        plan: '',
                                                                    })}>Cancel
                                                                </button>
                                                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                                <button type="button" className="btn btn-success"
                                                                    onClick={(e) => this.addSubscription()}
                                                                    disabled={this.state.disabled}>Confirm
                                                                </button>
                                                                &nbsp; &nbsp; &nbsp;
                                                                {
                                                                    this.state.isSend ? (
                                                                        <div className="spinner-border text-primary"
                                                                            role="status">
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    ) :
                                                                        null
                                                                }
                                                            </center>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''}
                        </div>

                        <div className="row">
                            <div className="col-md-12 mt-4">
                                <div className="card">
                                    <div className="card-header pb-0 px-3">
                                        <h6 className="mb-0">All sessions subscriptions</h6>
                                    </div>
                                    <div className="card-body pt-4 p-3">
                                        <table className="table">
                                            <thead>
                                                {/*<tr>*/}
                                                {/*    <th>ID</th>*/}
                                                {/*    <th>Name</th>*/}
                                                {/*    <th>...</th>*/}
                                                {/*</tr>*/}
                                            </thead>
                                            <tbody>
                                                {this.state.sessionSubscriptions && this.state.sessionSubscriptions.map((subscription) => (

                                                    <tr key={subscription.id}>

                                                        <td>

                                                            <Link to={{
                                                                pathname: "/details",
                                                                search: '?sessionId=' + subscription.session?.id
                                                            }}>
                                                                <div className="courses">
                                                                    <h5 className="font-weight-bold mb-0" style={{ textDecoration: "underline" }}>{subscription.session?.name}</h5>
                                                                </div>
                                                            </Link>
                                                        </td>

                                                        <td>
                                                            <div className="student">
                                                                <span>Start date <br />{moment(subscription.session?.startDate).utc().format('DD  MMMM  YYYY')}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="student">
                                                                <span>End date  < br />{moment(subscription.session?.endDate).utc().format('DD  MMMM  YYYY')}</span>
                                                            </div>
                                                        </td>
                                                        <td className="student">

                                                            {subscription.accessType === "MANUAL" ?
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox"
                                                                        id="flexSwitchCheckDefault" checked
                                                                        identifier={subscription.id}
                                                                        onClick={this.manageManualSubscription} />
                                                                    <label className="form-check-label"
                                                                        htmlFor="flexSwitchCheckDefault">Click to remove
                                                                        access to course</label>
                                                                </div> :
                                                                <div className="form-check form-switch">
                                                                    <input className="form-check-input" type="checkbox"
                                                                        id="flexSwitchCheckDefault"
                                                                        identifier={subscription.id}
                                                                        onClick={this.manageManualSubscription} />
                                                                    <label className="form-check-label"
                                                                        htmlFor="flexSwitchCheckDefault">Click to give access
                                                                        to course</label>
                                                                </div>
                                                            }
                                                        </td>

                                                        {!this.state.edition ? (
                                                            <td>
                                                                <div className="student">
                                                                    <div className="button-column">

                                                                        <div className="tw-flex tw-flex-row tw-space-x-4 mb-3">
                                                                            <ButtonCustom

                                                                                onClick={() =>
                                                                                    this.setState({
                                                                                        edition: !this.state.edition,
                                                                                        subscription_id: subscription.id,
                                                                                        currentSession: subscription?.session,
                                                                                    })
                                                                                }
                                                                                label="Edit"
                                                                                // icon={"pi pi-times-circle"}
                                                                                // onClick={() => setVisible(false)}
                                                                                autoFocus
                                                                                classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
                                                                            />
                                                                        </div>

                                                                        {/* <button
                                                                        className="tw-bg-secondary tw-text-white tw-py-2 tw-px-4 tw-rounded"
                                                                        type="button"
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                edition: !this.state.edition,
                                                                                subscription_id: subscription.id,
                                                                                currentSession: subscription?.session,
                                                                            })
                                                                        }
                                                                    >
                                                                        Edit
                                                                    </button> */}

                                                                    </div>

                                                                    <div>
                                                                        <div className="tw-flex tw-flex-row tw-space-x-4 ">
                                                                            <ButtonCustom
                                                                                label="Change subscription plan"
                                                                                // icon={"pi pi-times-circle"}
                                                                                onClick={this.handleButtonClick}

                                                                                classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
                                                                            />
                                                                        </div>

                                                                        <Dialog
                                                                            header="Subscription plan change form for this user"
                                                                            visible={visible}
                                                                            style={{
                                                                                width: '60vw',
                                                                                height: '77vh',
                                                                                marginLeft: '10vh',
                                                                            }}
                                                                            onHide={this.onHide}
                                                                        >
                                                                            <form>
                                                                                <div className="tw-mb-1">
                                                                                    <h3 className="tw-block tw-font-bold tw-text-primary-800 mt-3 tw-text-center tw-text-xl">
                                                                                        <hr />
                                                                                        Information about the user's subscription plan.
                                                                                    </h3>

                                                                                </div>


                                                                                <table >
                                                                                    <tbody>
                                                                                        {this.state.sessionSubscriptions && this.state.sessionSubscriptions.map((sessionSubscriptions) => (
                                                                                            <>
                                                                                                <tr key={sessionSubscriptions.id}>
                                                                                                    <td className="tw-w-1/3">Session name &nbsp;&nbsp;</td>
                                                                                                    <td className="tw-w-2/3 tw-font-bold tw-ml-auto tw-text-right">
                                                                                                        {sessionSubscriptions.session?.name}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td className="tw-w-1/3">Current subscription plan </td>
                                                                                                    <td className="tw-w-2/3 tw-font-bold tw-ml-auto tw-text-right">{sessionSubscriptions.subscriptionPlan?.name}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td className="tw-w-1/3">Payment amount </td>
                                                                                                    <td className="tw-w-2/3 tw-font-bold tw-ml-auto tw-text-right">${sessionSubscriptions.subscriptionPlan?.paymentAmount}</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td className="tw-w-1/3">Subscription plan amount  &emsp;&emsp; &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&emsp;&emsp;</td>
                                                                                                    <td className="tw-w-2/3 tw-font-bold tw-ml-auto tw-text-right">
                                                                                                        ${sessionSubscriptions.subscriptionPlan?.price}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>

                                                                                {
                                                                                    this.state.isLoading ? (
                                                                                        <div className="text-right">
                                                                                            <div className="spinner-border text-primary" role="status">
                                                                                                <span className="sr-only">Loading...</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : (
                                                                                        this.state.selectedPlanId ? (
                                                                                            <div>
                                                                                                {this.state.sessionSubscriptions && this.state.sessionSubscriptions.map((sessionSubscriptions) => (
                                                                                                    <>
                                                                                                        <h3 className="tw-block tw-font-bold mt-3 tw-text-primary-800 tw-text-center">
                                                                                                            Are you sure you want to change the subscription plan from &nbsp;&nbsp;{sessionSubscriptions.subscriptionPlan?.name} to&nbsp;&nbsp;{this.state.selectedPlanName}?
                                                                                                        </h3>
                                                                                                    </>
                                                                                                ))}

                                                                                                <div className="tw-flex tw-justify-center mt-3">
                                                                                                    <ConfirmPopup />
                                                                                                    <div className="card button-container">
                                                                                                        <Button onClick={this.handleCancelClick} icon="pi pi-times" label="Cancel" className="p-button-danger"></Button>
                                                                                                    </div>
                                                                                                    &emsp;
                                                                                                    <Button onClick={this.confirm1} icon="pi pi-check" label="Confirm"></Button>
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div>
                                                                                                <h3 className="tw-block tw-font-bold tw-text-primary-800 mt-4 tw-text-center">
                                                                                                    Choose a new subscription plan for this user
                                                                                                </h3>
                                                                                                <select
                                                                                                    style={{ marginBottom: "5%" }}
                                                                                                    className="tw-border tw-border-gray-300 tw-rounded-lg tw-py-2 tw-px-1 tw-w-full tw-bg-white focus:tw-ring-2 focus:tw-ring-blue-500 tw-outline-none "
                                                                                                    onChange={this.handlePlanChange}
                                                                                                    value={this.state.selectedPlanId}
                                                                                                >
                                                                                                    <option value="">Choose a new subscription plan</option>
                                                                                                    {this.state.subscriptionAssociatedSession && this.state.subscriptionAssociatedSession.map((subscriptionAssociatedSession) => (
                                                                                                        <option key={subscriptionAssociatedSession.id} data-option-id={subscriptionAssociatedSession.subscription_id} value={subscriptionAssociatedSession.id}>
                                                                                                            {subscriptionAssociatedSession.name}
                                                                                                        </option>
                                                                                                    ))}
                                                                                                </select>
                                                                                            </div>
                                                                                        )
                                                                                    )
                                                                                }


                                                                            </form>
                                                                        </Dialog>

                                                                    </div>
                                                                </div>
                                                                <div className="clearfix"></div>
                                                            </td>
                                                        ) : null}

                                                        {(this.state.edition && this.state.subscription_id === subscription.id) ?
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="row">

                                                                        <div className="col-md-12 mb-lg-0 mb-4">
                                                                            <div className="card mt-4">
                                                                                <div className="card-header pb-0 p-3">
                                                                                    <div className="row">
                                                                                        <div
                                                                                            className="col-6 d-flex align-items-center">
                                                                                            <h6 className="mb-0"> Edit
                                                                                                subscription </h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-12">
                                                                                    <div className="d-flex flex-column">
                                                                                        <br />
                                                                                        <center>
                                                                                            <h5>You want to remove user <span
                                                                                                className="text-warning">{(this.state.user?.firstName).toUpperCase() + " "}</span>
                                                                                                from session <span
                                                                                                    className="text-warning">{this.state.currentSession?.name}</span> to
                                                                                                add him to another session.
                                                                                            </h5>
                                                                                            <table className="table center">
                                                                                                <tbody>
                                                                                                    {/*<tr>*/}
                                                                                                    {/*    <td>*/}
                                                                                                    {/*        <hr/>*/}
                                                                                                    {/*    </td>*/}
                                                                                                    {/*    <td>*/}
                                                                                                    {/*        <hr/>*/}
                                                                                                    {/*    </td>*/}
                                                                                                    {/*</tr>*/}
                                                                                                    <tr>
                                                                                                        <td> Choose a session</td>
                                                                                                        <td>
                                                                                                            <select
                                                                                                                onChange={(e) => {
                                                                                                                    const ses = JSON.parse(e.target.value);
                                                                                                                    this.setState({
                                                                                                                        nextSessionId: parseInt(ses.id),
                                                                                                                        nextSessionName: ses.name
                                                                                                                    }
                                                                                                                    )
                                                                                                                }}
                                                                                                                className="form-control form-control-lg selectpicker">
                                                                                                                <option>--Please
                                                                                                                    choose a
                                                                                                                    session--
                                                                                                                </option>
                                                                                                                {this.state.sessions && this.state.sessions.map((session) => (
                                                                                                                    <option
                                                                                                                        key={session.id}
                                                                                                                        value={JSON.stringify(session)}>
                                                                                                                        {session.name}
                                                                                                                    </option>
                                                                                                                ))}
                                                                                                            </select></td>
                                                                                                    </tr>
                                                                                                    {/*<tr>*/}
                                                                                                    {/*    <td> Choose a subscription*/}
                                                                                                    {/*        plan*/}
                                                                                                    {/*    </td>*/}
                                                                                                    {/*    <td>*/}
                                                                                                    {/*        <select*/}
                                                                                                    {/*            onChange={(e) => this.forwardData(e)}*/}
                                                                                                    {/*            className="form-control form-control-lg selectpicker">*/}
                                                                                                    {/*            <option>--Please*/}
                                                                                                    {/*                choose an*/}
                                                                                                    {/*                subscription*/}
                                                                                                    {/*                plan--*/}
                                                                                                    {/*            </option>*/}
                                                                                                    {/*            {this.state.subscriptionPlans && this.state.subscriptionPlans.map((subscriptionPlan) => (*/}
                                                                                                    {/*                <option*/}
                                                                                                    {/*                    key={subscriptionPlan.id}*/}
                                                                                                    {/*                    value={subscriptionPlan.id}>*/}
                                                                                                    {/*                    {subscriptionPlan.name}*/}
                                                                                                    {/*                </option>*/}
                                                                                                    {/*            ))}*/}
                                                                                                    {/*        </select></td>*/}
                                                                                                    {/*</tr>*/}
                                                                                                    {/*<tr>*/}
                                                                                                    {/*    <td> Choose a motive</td>*/}
                                                                                                    {/*    <td>*/}
                                                                                                    {/*        <select*/}
                                                                                                    {/*            onChange={(e) => this.setState({motive: e.target.value})}*/}
                                                                                                    {/*            className="form-control form-control-lg selectpicker">*/}
                                                                                                    {/*            <option>--Please*/}
                                                                                                    {/*                choose a*/}
                                                                                                    {/*                motive--*/}
                                                                                                    {/*            </option>*/}
                                                                                                    {/*            <option> ALREADY_PAID_IN_CASH</option>*/}
                                                                                                    {/*            <option> POSTPONED_PAYMENT_DATE</option>*/}
                                                                                                    {/*            <option> MANUAL_SUBSCRIPTION_FOR_TEST</option>*/}
                                                                                                    {/*        </select>*/}
                                                                                                    {/*    </td>*/}
                                                                                                    {/*</tr>*/}
                                                                                                    {/*<tr>*/}
                                                                                                    {/*    <td>*/}
                                                                                                    {/*        <hr/>*/}
                                                                                                    {/*    </td>*/}
                                                                                                    {/*    <td>*/}
                                                                                                    {/*        <hr/>*/}
                                                                                                    {/*    </td>*/}
                                                                                                    {/*</tr>*/}

                                                                                                </tbody>
                                                                                            </table>
                                                                                            <h3> Confirm your choice here:</h3>
                                                                                            <button type="button"
                                                                                                className="btn btn-danger"
                                                                                                disabled={this.state.disabled}
                                                                                                onClick={() => {
                                                                                                    this.setState({
                                                                                                        edition: !this.state.edition,
                                                                                                    })
                                                                                                    this.clearSubscriptionEdition();
                                                                                                }}>Cancel
                                                                                            </button>
                                                                                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;

                                                                                            <button type="button"
                                                                                                className="btn btn-success"
                                                                                                data-bs-target="#updateSUbscriptionModal"
                                                                                                data-bs-toggle="modal"
                                                                                                // onClick={() => this.editSubscription()}
                                                                                                disabled={this.state.disabled}>Confirm
                                                                                            </button>
                                                                                            &nbsp; &nbsp; &nbsp;
                                                                                            {
                                                                                                this.state.isSend ? (
                                                                                                    <div
                                                                                                        className="spinner-border text-primary"
                                                                                                        role="status">
                                                                                                        <span
                                                                                                            className="sr-only">Loading...</span>
                                                                                                    </div>
                                                                                                ) :
                                                                                                    null
                                                                                            }
                                                                                        </center>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ''}

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div
                                    className="modal fade"
                                    id={"updateSUbscriptionModal"}
                                    tabIndex="-1"
                                    aria-labelledby="updateSUbscriptionModal"
                                    aria-hidden="true"
                                >
                                    <div
                                        className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5
                                                    className="modal-title"
                                                    id="exampleModalLabel"
                                                >
                                                    Update subscription
                                                </h5>
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                ></button>
                                            </div>
                                            <div className="modal-body">
                                                Do you really want to remove user <span
                                                    className="text-warning">{(this.state.user?.firstName) + " "}</span>
                                                from session <span
                                                    className="text-warning">{this.state.currentSession?.name}</span> to
                                                add him to session <span
                                                    className="text-warning">{this.state.nextSessionName}</span> .
                                            </div>
                                            <div className="modal-footer">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger  btn-block"
                                                    onClick={this.editSubscription}
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    Confirm
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary btn-block"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="position-fixed bottom-1 end-1 z-index-2">

                                    {this.state.successMessage ? (
                                        <div className="toast fade show p-2 mt-2 bg-gradient-info"
                                            role="alert"
                                            aria-live="assertive" id="infoToast" aria-atomic="true">
                                            <div className="toast-header bg-transparent border-0">
                                                <i className="material-icons text-white me-2">
                                                    check
                                                </i>
                                                <span
                                                    className="me-auto text-white font-weight-bold">Subscription management</span>
                                                <small className="text-white">just now</small>
                                                <i className="fas fa-times text-md text-white ms-3 cursor-pointer"
                                                    data-bs-dismiss="toast" aria-label="Close"
                                                    onClick={(e) => this.setState({ successMessage: false })}></i>
                                            </div>
                                            <hr className="horizontal light m-0" />
                                            <div className="toast-body text-white">
                                                {this.state.message}
                                            </div>
                                        </div>
                                    ) :
                                        (<></>)}
                                    {this.state.failedMessage ? (
                                        <div className="toast fade show p-2 mt-2 bg-gradient-danger"
                                            role="alert"
                                            aria-live="assertive" id="infoToast" aria-atomic="true">
                                            <div className="toast-header bg-transparent border-0">
                                                <i className="material-icons text-white me-2">
                                                    check
                                                </i>
                                                <span className="me-auto text-white font-weight-bold">Contact us</span>
                                                <small className="text-white">just now</small>
                                                <i className="fas fa-times text-md text-white ms-3 cursor-pointer"
                                                    data-bs-dismiss="toast" aria-label="Close"
                                                    onClick={(e) => this.setState({ failedMessage: false })}></i>
                                            </div>
                                            <hr className="horizontal light m-0" />
                                            <div className="toast-body text-white">
                                                Something went wrong. Please try again !
                                            </div>
                                        </div>
                                    ) :
                                        (<></>)}
                                </div>
                            </div>


                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row">

                                    <div className="col-md-12 mb-lg-0 mb-4">
                                        <div className="card mt-4">
                                            {/*<div className="card-header pb-0 p-3">*/}
                                            {/*    <div className="row">*/}
                                            {/*        <div className="col-6 d-flex align-items-center">*/}
                                            {/*            <h6 className="mb-0">Details on Session*/}
                                            {/*            </h6>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            <div className="card-header p-3">
                                                <h5 className="text-lg font-weight-bold mb-0">Manual subscription interface</h5>
                                            </div>
                                            <div className="col-md-12">
                                                <table className="table table-borderless">

                                                    <tbody>
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td> Choose a course</td>
                                                            <td></td>
                                                            <td>
                                                                <select
                                                                    value={this.state.courseId}
                                                                    onChange={(e) => this.setState({ courseId: parseInt(e.target.value) })}
                                                                    className="form-control form-control-lg selectpicker">
                                                                    <option>--Please choose a course--</option>
                                                                    {this.state.courses && this.state.courses.map((session) => (
                                                                        <option key={session.id} value={session.id}>
                                                                            {session.name}
                                                                        </option>
                                                                    ))}
                                                                </select></td>
                                                        </tr>
                                                        {this.state.courseId ?
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td> Choose a subscription plan</td>
                                                                <td></td>
                                                                <td>
                                                                    <select
                                                                        value={this.state.coursePlanId}
                                                                        onChange={(e) => this.forwardDataCourses(e)}
                                                                        className="form-control form-control-lg selectpicker">
                                                                        <option>--Please choose an subscription plan--
                                                                        </option>
                                                                        {this.state.subscriptionPlans && this.state.subscriptionPlans.map((subscriptionPlan) => (
                                                                            <option key={subscriptionPlan.id}
                                                                                value={subscriptionPlan.id}>
                                                                                {subscriptionPlan.name}
                                                                            </option>
                                                                        ))}
                                                                    </select></td>
                                                            </tr> : ""}
                                                        {this.state.coursePlan ?
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td> Choose a motive</td>
                                                                <td>
                                                                </td>
                                                                <td>
                                                                    <select
                                                                        onChange={(e) => this.setState({ courseMotive: e.target.value })}
                                                                        className="form-control form-control-lg selectpicker">
                                                                        <option>--Please choose a motive--
                                                                        </option>
                                                                        <option> ALREADY_PAID_IN_CASH</option>
                                                                        <option> MANUAL_SUBSCRIPTION_FOR_TEST</option>
                                                                    </select>
                                                                </td>
                                                            </tr> : ""}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.courseMotive ?
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="row">

                                        <div className="col-md-12 mb-lg-0 mb-4">
                                            <div className="card mt-4">
                                                <div className="card-header pb-0 p-3">
                                                    <div className="row">
                                                        <div className="col-6 d-flex align-items-center">
                                                            <h6 className="mb-0">Details on course</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="d-flex flex-column">
                                                        <br />
                                                        <center>
                                                            <h2>You want to add a new subscription with
                                                                those details :</h2>
                                                            <table className="table center">

                                                                <tbody>
                                                                    {/*<tr>*/}
                                                                    {/*    <td>*/}
                                                                    {/*        <hr/>*/}
                                                                    {/*    </td>*/}
                                                                    {/*    <td>*/}
                                                                    {/*        <hr/>*/}
                                                                    {/*    </td>*/}
                                                                    {/*</tr>*/}
                                                                    <tr>
                                                                        <td> Course name</td>
                                                                        <td>{this.state.course.name}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> Last name</td>
                                                                        <td> {this.state.user.name}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> First name</td>
                                                                        <td> {this.state.user.firstName}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> Email</td>
                                                                        <td> {this.state.user.email}</td>
                                                                    </tr>
                                                                    {/*<tr>*/}
                                                                    {/*    <td>*/}
                                                                    {/*        <hr/>*/}
                                                                    {/*    </td>*/}
                                                                    {/*    <td>*/}
                                                                    {/*        <hr/>*/}
                                                                    {/*    </td>*/}
                                                                    {/*</tr>*/}
                                                                    <tr>
                                                                        <td> Subscription name</td>
                                                                        <td>{this.state.coursePlan.name}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> Subscription price</td>
                                                                        <td>{this.state.coursePlan.price}</td>
                                                                    </tr>
                                                                    {/*<tr>*/}
                                                                    {/*    <td>*/}
                                                                    {/*        <hr/>*/}
                                                                    {/*    </td>*/}
                                                                    {/*    <td>*/}
                                                                    {/*        <hr/>*/}
                                                                    {/*    </td>*/}
                                                                    {/*</tr>*/}
                                                                    <tr>
                                                                        <td> Manual access motive</td>
                                                                        <td>{this.state.courseMotive}</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                            <h3> Confirm your choice here:</h3>
                                                            <button type="button" className="btn btn-danger"
                                                                disabled={this.state.disabled}
                                                                onClick={(e) => this.setState({
                                                                    courseId: null,
                                                                    coursePlanId: null,
                                                                    courseMotive: null,
                                                                    coursePlan: '',
                                                                    course: ''
                                                                })}>Cancel
                                                            </button>
                                                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                            <button type="button" className="btn btn-success"
                                                                onClick={(e) => this.addCourseSubscription()}
                                                                disabled={this.state.disabled}>Confirm
                                                            </button>
                                                            &nbsp; &nbsp; &nbsp;
                                                            {
                                                                this.state.isSendCourse ? (
                                                                    <div className="spinner-border text-primary"
                                                                        role="status">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>
                                                                ) :
                                                                    null
                                                            }
                                                        </center>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''}
                        <hr />
                        <div className="row">
                            <div className="col-md-12 mt-4">
                                <div className="card">
                                    <div className="card-header pb-0 px-3">
                                        <h6 className="mb-0">All courses subscriptions</h6>
                                    </div>
                                    <div className="card-body pt-4 p-3">
                                        <table className="table">
                                            <thead>
                                                {/*<tr>*/}
                                                {/*    <th>ID</th>*/}
                                                {/*    <th>Name</th>*/}
                                                {/*    <th>...</th>*/}
                                                {/*</tr>*/}
                                            </thead>
                                            <tbody>
                                                {this.state.courseSubscriptions && this.state.courseSubscriptions.map((subscription) => (

                                                    <tr key={subscription.id}>

                                                        <td>

                                                            <Link to={{
                                                                pathname: "/detailpage",
                                                                search: '?courseId=' + subscription.course?.id
                                                            }}>
                                                                <div className="courses">
                                                                    <h5 className="text-lg font-weight-bold mb-0" style={{ textDecoration: "underline" }}>{subscription.course?.name}</h5>
                                                                </div>
                                                            </Link>
                                                        </td>
                                                        <td>
                                                            <div className="student">
                                                                <span>Subscription plan <br />{subscription.subscriptionPlan.name}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="student">
                                                                <span>Motive <br />
                                                                    {subscription.manualAccessMotive}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div
                                    className="modal fade"
                                    id={"updateSUbscriptionModal"}
                                    tabIndex="-1"
                                    aria-labelledby="updateSUbscriptionModal"
                                    aria-hidden="true"
                                >
                                    <div
                                        className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <h5
                                                    className="modal-title"
                                                    id="exampleModalLabel"
                                                >
                                                    Update subscription
                                                </h5>
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                ></button>
                                            </div>
                                            <div className="modal-body">
                                                Do you really want to remove user <span
                                                    className="text-warning">{(this.state.user?.firstName) + " "}</span>
                                                from session <span
                                                    className="text-warning">{this.state.currentSession?.name}</span> to
                                                add him to session <span
                                                    className="text-warning">{this.state.nextSessionName}</span> .
                                            </div>
                                            <div className="modal-footer">
                                                <button
                                                    type="button"
                                                    className="btn btn-danger  btn-block"
                                                    onClick={this.editSubscription}
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    Confirm
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary btn-block"
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="position-fixed bottom-1 end-1 z-index-2">

                                    {this.state.successMessage ? (
                                        <div className="toast fade show p-2 mt-2 bg-gradient-info"
                                            role="alert"
                                            aria-live="assertive" id="infoToast" aria-atomic="true">
                                            <div className="toast-header bg-transparent border-0">
                                                <i className="material-icons text-white me-2">
                                                    check
                                                </i>
                                                <span
                                                    className="me-auto text-white font-weight-bold">Subscription management</span>
                                                <small className="text-white">just now</small>
                                                <i className="fas fa-times text-md text-white ms-3 cursor-pointer"
                                                    data-bs-dismiss="toast" aria-label="Close"
                                                    onClick={(e) => this.setState({ successMessage: false })}></i>
                                            </div>
                                            <hr className="horizontal light m-0" />
                                            <div className="toast-body text-white">
                                                {this.state.message}
                                            </div>
                                        </div>
                                    ) :
                                        (<></>)}
                                    {this.state.failedMessage ? (
                                        <div className="toast fade show p-2 mt-2 bg-gradient-danger"
                                            role="alert"
                                            aria-live="assertive" id="infoToast" aria-atomic="true">
                                            <div className="toast-header bg-transparent border-0">
                                                <i className="material-icons text-white me-2">
                                                    check
                                                </i>
                                                <span className="me-auto text-white font-weight-bold">Contact us</span>
                                                <small className="text-white">just now</small>
                                                <i className="fas fa-times text-md text-white ms-3 cursor-pointer"
                                                    data-bs-dismiss="toast" aria-label="Close"
                                                    onClick={(e) => this.setState({ failedMessage: false })}></i>
                                            </div>
                                            <hr className="horizontal light m-0" />
                                            <div className="toast-body text-white">
                                                Something went wrong. Please try again !
                                            </div>
                                        </div>
                                    ) :
                                        (<></>)}
                                </div>
                            </div>
                        </div>
                        <AdminFooter />
                    </main>
                </div>
            </>
        )
    }
}

export default AddSubscription;



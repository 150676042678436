import React from "react";

const Alert = ({type = "success", message, position}) => {
    // List type : success, danger, warning, info
    return (
        <>
            <div
                className={`
            ${type === "success" && "tw-bg-green-300"} 
            ${type === "danger" && "tw-bg-red-300"} 
            ${type === "warning" && "tw-bg-yellow-300"} 
            ${type === "info" && "tw-bg-blue-300"} 
            tw-p-2 tw-rounded-[6px] tw-mb-2`}
            >
                <p
                    className={`
            ${type === "success" && "tw-text-green-700"} 
            ${type === "danger" && "tw-text-red-700"} 
            ${type === "warning" && "tw-text-yellow-700"} 
            ${type === "info" && "tw-text-blue-700"} 
            tw-m-1 tw-text-[16px] ${position}`}
                >
                    {message}
                </p>
            </div>
        </>
    );
};

export default Alert;

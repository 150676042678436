import React from "react";
import ButtonCustom from "../button/ButtonCustom";
import { IMAGE_4 } from "../../../../constants/images";
import moment from "moment";
import { MEDIA_URL_ACCOUNTING } from "../../../../network/urls/backendUrls";

const CardLarge = ({ data, auth = false, onClickAction }) => {
  /**
   * States
   */

  /**
   * Comportments
   */
  const handleOnClick = () => {
    onClickAction(data.id);
  };

  const encodeUrl = (url) => {
    return url.replace(/ /g, "%20");
  };

  const shortenText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    } else {
      return text;
    }
  };

  /**
   * Render
   */
  return (
    <>
      <div className="sm:tw-flex tw-shadow-md sm:tw-flex-row tw-flex-col tw-w-full tw-overflow-tw-hidden tw-bg-gray-100 hover:tw-bg-primary-200 tw-rounded-[12px]  hover:tw-transition hover:tw-ease-in-out hover:tw-delay-150 hover:tw-duration-250">
        <div
          className="tw-shadow-sm  tw-hidden sm:tw-block sm:tw-m-3 tw-bg-cover tw-bg-center tw-rounded-[12px]  sm:tw-w-[325px] tw-w-full"
          style={{
            backgroundImage: `url(${
              data.image === null
                ? IMAGE_4
                : encodeUrl(`${MEDIA_URL_ACCOUNTING}/sessions/${data.image}`)
            })`,
          }}
        />
        <img
          className="tw-shadow-sm sm:tw-hidden sm:tw-m-3 tw-bg-cover tw-bg-center tw-rounded-[12px] tw-max-h-[220px]  sm:tw-w-[325px] tw-w-full"
          src={
            data.image === null
              ? IMAGE_4
              : encodeUrl(`${MEDIA_URL_ACCOUNTING}/sessions/${data.image}`)
          }
          alt="session"
        />
        <div className="tw-flex tw-flex-col tw-w-full tw-py-8 tw-px-4 ">
          {auth ? (
            <>
              <div className="tw-flex tw-justify-between tw-items-center tw-pb-[31px] tw-pt-[10px] tw-text-primary tw-w-full">
                <div className="tw-flex tw-flex-col tw-text-lg">
                  <span className="tw-font-bold">
                    {data?.name ?? ""}
                  </span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        data?.description !== ""
                          ? shortenText(data?.description, 70)
                          : "",
                    }}
                  />
                </div>
              </div>
              <div className="tw-flex tw-items-center tw-justify-between tw-mt-2">
                <div className="tw-flex tw-flex-row  tw-text-primary tw-space-x-[16px]">
                  <div className="tw-flex tw-items-center tw-text-green-700 tw-bg-accent-green tw-max-h-[44px] tw-p-4 tw-rounded-[6px]">
                    <span
                      className="tw-mr-[6px] pi pi-calendar"
                      style={{ fontSize: "1.9rem" }}
                    ></span>
                    <div className="tw-flex tw-flex-col tw-text-sm ">
                      <span>Start date</span>
                      <span className="tw-font-bold tw-text-sm lg:tw-text-[12px]">
                        {moment(data?.startDate).format("MMMM Do, YYYY") ??
                          "23, March 2023"}
                      </span>
                    </div>
                  </div>

                  <div className="tw-flex tw-items-center tw-text-primary tw-bg-accent-gray tw-max-h-[44px] tw-p-4 tw-rounded-[6px]">
                    <span
                      className="tw-mr-[6px] pi pi-calendar"
                      style={{ fontSize: "1.9rem" }}
                    ></span>
                    <div className="tw-flex tw-flex-col tw-text-sm ">
                      <span>End date</span>
                      <span className="tw-font-bold tw-text-sm lg:tw-text-[12px]">
                        {moment(data?.endDate).format("MMMM Do, YYYY") ??
                          "23, March 2023"}
                      </span>
                    </div>
                  </div>
                </div>
                <ButtonCustom
                  onClick={handleOnClick}
                  classes={
                    "tw-bg-primary hover:tw-bg-primary-900 tw-text-white tw-max-w-[137px] tw-max-h-[44px]"
                  }
                  label="Subscribe"
                />
              </div>
            </>
          ) : (
            <div className="tw-w-full tw-h-full">
              <div className="tw-flex tw-justify-between tw-w-full tw-text-primary ">
                <div className="tw-flex tw-flex-col tw-text-lg tw-mr-16">
                  <span className="tw-font-bold">{data?.name}</span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        data?.description !== ""
                          ? shortenText(data?.description, 70)
                          : "",
                    }}
                  />
                </div>
                <div
                  className={`tw-flex justify-end ${
                    data?.openStatus === "OPENED"
                      ? "tw-text-green-600"
                      : "tw-text-primary"
                  }  tw-text-[16px]`}
                >
                  <div className="tw-flex tw-flex-row tw-items-center">
                      <span
                        className={`tw-mr-[6px] pi  ${
                          data?.openStatus === "OPENED"
                            ? "pi-lock-open"
                            : "pi-lock"
                        } `}
                      ></span>
                      <span>
                        {data?.openStatus === "OPENED" ? "Opened" : "Closed"}
                      </span>
                  </div>
                </div>
              </div>
              <div className="tw-flex tw-flex-col tw-w-full sm:tw-items-center sm:tw-flex-row sm:tw-justify-between">
                <div className="tw-flex tw-flex-col tw-pt-4 tw-pb-4 sm:tw-pb-0 tw-text-primary tw-text-md">
                  <div className="tw-flex tw-items-center">
                    <span className="tw-mr-[6px] pi pi-calendar tw-text-green-700"></span>
                    <span>
                      Start date :{" "}
                      {moment(data?.startDate).format("MMMM Do, YYYY") ??
                        "23, March 2023"}
                    </span>
                  </div>

                  <div className="tw-flex tw-items-center">
                    <span className="tw-mr-[6px] pi pi-calendar tw-text-primay"></span>
                    <span>
                      End date :{" "}
                      {moment(data?.endDate).format("MMMM Do, YYYY") ??
                        "23, March 2023"}
                    </span>
                  </div>
                </div>
                <div>
                  <ButtonCustom
                    onClick={handleOnClick}
                    classes={
                      "tw-bg-primary hover:tw-bg-primary-900 tw-text-white w-[137px]"
                    }
                    label="Subscribe"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CardLarge;

const routeSupport = [

    {
        path: '/search-user',
        name: 'Transactions',
        icon: 'list',
        section: 1
    },
    {
        path: '/configure-login-register',
        name: 'Authentication Setup',
        icon: 'list',
        section: 3
    },
];
export default routeSupport;

import Illustration from "../../../components/frontv2.0/common/illustration/Illustration";
import AuthLayout from "../../../layouts/AuthLayout";
import { FAVICON_WITHE, IMAGE_4 } from "../../../constants/images";
import ButtonCustom from "../../../components/frontv2.0/common/button/ButtonCustom";
import InputCustom from "../../../components/frontv2.0/common/form/InputCustom";
import TextHeader from "../../../components/frontv2.0/common/text/TextHeader";
import Logo from "../../../components/frontv2.0/common/logo/Logo";
import { useEffect, useState } from "react";
import Alert from "../../../components/frontv2.0/common/alert/Alert";
import { Link, useNavigate } from "react-router-dom";
import UserService from "../../../network/services/UserService";
import { isAuthenticated, logout } from "../../../network/auth/Auth";
import { PASSWORD_REGEX } from "../../../constants/Constants";

const NewPassword = () => {
  const [email, setEmail] = useState(null);
  const [token, setToken] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [requestAttempt, setRequestAttempt] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [showPassword, setShowPassword] = useState(false);  
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();

  const validatePassword = (password) => {
    const passwordRegex = PASSWORD_REGEX;
    return passwordRegex.test(password);
  };

  const handleOnChangePassword = (e) => {
    e.preventDefault();
    let message = "";
    const errorObj = {};

    if (!validatePassword(password)) {
      errorObj.password =
        "The password must be at least 6 characters and contain at least one uppercase letter, one lowercase letter and one number.";
    }

    if (password !== confirmPassword) {
      errorObj.confirmPassword = "Passwords do not match.";
    }

    if (Object.keys(errorObj).length === 0) {
      if (password === confirmPassword) {
        setIsLoading(true);
        UserService.resetPassword(
          {
            email: email,
            token: token,
            password: password,
          },
          (data) => {
            if (data !== null) {
              message = data.message;
              if (data.success) {
                setRequestSuccess(true);
                setIsLoading(false);
                logout()
                //navigate to login
                navigate({
                  pathname: "/login",
                  search:
                    "?success=" +
                    encodeURI(data.success) +
                    "&message=" +
                    encodeURI(data.message),
                });
              } else {
                setRequestSuccess(false);
                setIsLoading(false);
              }
            } else {
              setRequestSuccess(false);
              setIsLoading(false);
              message = "Error : Unable to connect.";
            }
            setMessage(message);
            setRequestAttempt(true);
            setIsLoading(false);
          },
          (err) => {
            console.log(err);
          }
        );
      }
    }else {
      setErrors(errorObj);
      setIsLoading(false);
    }
  };

  function handleOnChange(e) {
    const { name, value } = e.target;

    setErrors({
      ...errors,
      [name]: "",
    });

    if (name === "password") {
      setPassword(value);
    }
    if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  }

  const renderContent = () => {
    return (
      <>
        <div>
          <InputCustom
            id={"password"}
            name={"password"}
            type={"password"}
            icon={"pi pi-lock"}
            placeHolder={"Password"}
            onChange={handleOnChange}
            error={errors?.password ? errors.password : ""}
            showPassword={showPassword}
            handleShowPassword={handleShowPassword}
          />
        </div>

        <div className="tw-mt-[22px]">
          <InputCustom
            id={"confirmPassword"}
            name={"confirmPassword"}
            type={"password"}
            icon={"pi pi-lock"}
            placeHolder={"Confirm password"}
            onChange={handleOnChange}
            error={errors?.confirmPassword ? errors.confirmPassword : ""}
            showPassword={showPassword}
            handleShowPassword={handleShowPassword}
          />
        </div>

        <div className="tw-mt-[42px]">
          {isLoading ? (
            <ButtonCustom
              classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
              label="Loading..."
              icon={"pi pi-spin pi-spinner"}
              disabled={true}
            />
          ) : (
            <ButtonCustom
              onClick={handleOnChangePassword}
              classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
              label="Change password"
            />
          )}
        </div>
        <p className="tw-mt-10 tw-text-sm tw-text-center tw-text-gray-500">
          I have an account,{" "}
          <Link to="/login">
            <span className="tw-text-green-800 tw-underline hover:tw-text-primary-700">
              login
            </span>
          </Link>
        </p>
      </>
    );
  };

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/workspace/mysessions");
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    const queryParams = new URLSearchParams(window.location.search);
    let tokenRes = queryParams.get("tokenres");
    let email = queryParams.get("email");
    setToken(tokenRes);
    setEmail(email);
    setPassword("");
    setConfirmPassword("");
    setRequestSuccess(false);
    setRequestAttempt(false);
    setMessage("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthLayout>
      <AuthLayout.Body>
        {/* <div className="tw-flex tw-flex-col tw-justify-center tw-px-6 tw-py-10 lg:tw-px-8 tw-h-full">
          <div className="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-sm tw-items-center tw-flex tw-justify-center sm:tw-justify-start">
            <Logo width={200} />
          </div>
          <div className="tw-h-full tw-mt-10 sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-sm">
            <div className="tw-w-full">
              <div className="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-sm">
                <div className="tw-w-full tw-mt-24">
                  <p className="tw-mt-2 tw-mb-8 tw-text-gray-500 tw-text-[16px]"></p>
                  <TextHeader label={"Change"} textUnderline={"Now"} size={4} />
                  <p className="tw-mt-2 tw-mb-8 tw-text-gray-500 tw-text-[16px]">
                    {"Below enter a new password and confirm it."}
                  </p>
                </div>
              </div> */}

        <div className="tw-flex tw-flex-col tw-justify-center tw-px-6 tw-py-10 lg:tw-px-8 tw-h-full">
          <div className="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-sm tw-items-center tw-flex tw-justify-center sm:tw-justify-start">
            <Logo width={200} />
          </div>
          <div className="tw-w-full sm:tw-mx-auto sm:tw-max-w-sm tw-h-full tw-flex sm:tw-items-center sm:tw-my-auto ">
            <div className="tw-w-full">
              <div className="sm:tw-mx-auto sm:tw-w-full sm:tw-max-w-sm sm:tw-justify-start tw-flex tw-justify-center tw-items-center tw-mt-8 sm:tw-mt-0 ">
                <div className="tw-w-full tw-justify-center tw-items-center tw-flex tw-flex-col sm:tw-block">
                  <TextHeader label={"Change"} textUnderline={"Now"} size={4} />
                  <p className="tw-mt-2 tw-mb-8 tw-text-gray-500 tw-text-[16px] tw-font-normal tw-text-center sm:tw-text-start">
                    {"Enter and confirm a new password below."}
                  </p>
                </div>
              </div>
              <div className="tw-w-full">
                {requestAttempt && !isLoading && (
                  <Alert
                    type={requestSuccess ? "success" : "danger"}
                    message={message}
                  />
                )}
                <form className="">{renderContent()}</form>
              </div>
            </div>
          </div>
        </div>
      </AuthLayout.Body>

      <AuthLayout.Illustration>
        <Illustration
          favIcon={FAVICON_WITHE}
          image={IMAGE_4}
          label={`"Linux, AWS and DevOps Training."`}
        />
      </AuthLayout.Illustration>
    </AuthLayout>
  );
};

export default NewPassword;

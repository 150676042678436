import React from 'react';

const Banner = ({bgColor, textColor}) => {
    return (
        <>
            <div
                className={`tw-w-full tw-text-center sm:tw-text-white sm:tw-bg-primary tw-text-green-700 tw-bg-white`}>
                <div className="tw-flex tw-items-center tw-justify-center tw-px-6 tw-py-[4px] md:tw-py-[10px] sm:tw-px-3.5">
                    <p className="tw-flex tw-justify-center tw-items-center tw-mr-4 tw-text-[11px] md:tw-text-[12px] tw-leading-6">
                        <i className="tw-mr-2 pi pi-phone tw-text-[11px] md:tw-text-[12px]"></i>
                        <span>+1 (302) 689 3440</span>
                    </p>

                    <p className="tw-flex tw-justify-center tw-items-center tw-text-[11px] md:tw-text-[12px] tw-leading-6">
                        <i className="tw-mr-2 pi pi-envelope tw-text-[11px] md:tw-text-[12px]"></i>
                        <span>contact@utrains.org</span>
                    </p>
                </div>
            </div>
        </>
    );
};

export default Banner;
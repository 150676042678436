import React, { useEffect, useState } from "react";
import MainLayout from "../../../../layouts/MainLayout";
import Header from "../../../../components/frontv2.0/common/header/Header";
import CardXLarge from "../../../../components/frontv2.0/common/card/CardXLarge";
import SubHeader from "../../../../components/frontv2.0/common/header/SubHeader";
import SessionService from "../../../../network/services/SessionService";
import { getUser } from "../../../../network/auth/Auth";
import moment from "moment/moment";
import SessionLoader from "../../../../components/frontv2.0/common/loader/SessionLoader";
import Welcome from "../../../../components/frontv2.0/common/welcome/Welcome";





const MySessions = () => {
  /**
   * States
   */
  const [isLoading, setIsLoading] = useState(true);
  const [sessionsData, setSessionsData] = useState([]);
  const [showForm, setShowForm] = useState(true);

 

  const handleOnClickItem = () => {
    //
  };
  const getAllSessionsSubscribed = (id) => {
    setIsLoading(true);
    SessionService.getUserSessions(id, (data) => {
      if (data && data.success) {
        setSessionsData(
          data.subscriptions.filter((e) => e.subscription.session !== null)
        );

      }
      setIsLoading(false);
    });
  };

  const loadData = () => {
    let user = getUser();
    getAllSessionsSubscribed(user.id);
  };

 
  
 
  

  const handleClose = () => {
    setShowForm(false);
  };

  useEffect(() => {
    
    loadData();
    setIsLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  /**
   * Render
   */
  return (
    <>
      <MainLayout>
        <MainLayout.Content>
          <div className="tw-w-full tw-py-auto">
            {!isLoading ? (
              sessionsData.length > 0 ? (
                <div className="tw-grid tw-grid-cols-1 tw-gap-6 md:tw-grid-cols-1 lg:tw-grid-cols-1 xl:tw-grid-cols-1">
                  {sessionsData &&
                    sessionsData.map((item, key) => {
                      return (
                        <><CardXLarge

                          data={{
                            isInGoodStanding: item.isInGoodStanding,
                            isCancelled: item.subscription.accessType === 'CANCELLED',
                            id: item.subscription.id,
                            urlDetails: `/workspace/mysessions/details?sessionId=${item.subscription.session.id}`,
                            name: item.subscription.session.name,
                            description: item.subscription.session.description,
                            startDate: moment(
                              item.subscription.session.startDate
                            )
                              .utc()
                              .format("MMMM Do, YYYY"),
                            endDate: moment(item.subscription.session.endDate)
                              .utc()
                              .format("MMMM Do, YYYY"),
                            image: item.subscription.session.image,
                          }}
                          onClikAction={handleOnClickItem}
                          key={key} />
                       </>
                      );
                    })}
                </div>
              ) : (
                <div className="sm:tw-flex">
                  <Welcome link={"/workspace/next-sessions"} />
                </div>
              )
            ) : (
              <div className="tw-grid tw-grid-cols-1 tw-gap-6 md:tw-grid-cols-1 lg:tw-grid-cols-1 xl:tw-grid-cols-1">
                {[1, 2, 3, 4, 5, 6, 7, 8].map((item, key) => {
                  return <SessionLoader />;
                })}
              </div>
            )}
          </div>
        </MainLayout.Content>

        <MainLayout.Header>
          <Header />
        </MainLayout.Header>

        <MainLayout.SubHeader>
          <SubHeader
            data={[
              { value: "Home", url: "/workspace" },
              {
                value: "My Sessions",
                url: "#",
              },
            ]}
          />
        </MainLayout.SubHeader>
      </MainLayout>
    </>
  );
};

export default MySessions;

const LOGO_BLUE = "/media/images/logo/Logo-1.svg";
const LOGO_WHITE = "/media/images/logo/logo-white.svg";
const SHAPE_UNDERLINE = "/media/images/motifs/shape-4.png";
const MOTIF_LOGIN = "/media/images/motifs/motif-2.svg";
const MOTIF_SUB_HEADER = "/media/images/motifs/motif-3.svg";
const MOTIF_4 = "/media/images/motifs/motif-4.svg";
const MOTIF_5 = "/media/images/motifs/motif-5.svg";
const MOTIF_PAYMENT = "/media/images/motifs/motif-payments.svg";
const GRADIEN_LOGIN = "/media/images/motifs/blue-gradient.png";
const USER_AVATAR_MALE = "/media/images/motifs/user_male.png";
const USER_AVATAR_FEMALE = "/media/images/motifs/user_female.png";

const IMAGE_1 = "/media/photos/coup-moyen-femme-tenant-ordinateur-portable.jpg";
const IMAGE_2 = "/media/photos/pexels-cottonbro-studio-8721339.jpg";
const IMAGE_3 = "/media/photos/pexels-christina-morillo-1181414.jpg";
const IMAGE_4 = "/media/photos/pexels-andrea-piacquadio-838413.jpg";

const WELCOMING = "/media/images/illustrations/welcoming.svg";
const FAVICON_WITHE = "/media/images/logo/favicon-logo-white.svg";
const FAVICON_BLUE = "/media/images/logo/favicon-blue.svg";
const FAVICON_LAUGHT = "/media/images/motifs/laugh-wink-solid.svg";
const FAVICON_HAT = ``

const _404_ILLUSTRATION = "/media/images/illustrations/404-illustration.png";
const WARNING_ILLUSTRATION = "/media/images/illustrations/warning-illustration.svg";
const OUPS_ILLUSTRATION = "/media/images/illustrations/oups-illustration.png";
const STUDENT_ONBOARD =  "/media/images/illustrations/student-onboard-illustration.svg"

export {
    STUDENT_ONBOARD,
    LOGO_BLUE,
    LOGO_WHITE,
    SHAPE_UNDERLINE,
    MOTIF_LOGIN,
    MOTIF_SUB_HEADER,
    MOTIF_4,
    MOTIF_5,
    MOTIF_PAYMENT,
    GRADIEN_LOGIN,
    IMAGE_1,
    IMAGE_2,
    IMAGE_3,
    IMAGE_4,
    WELCOMING,
    FAVICON_WITHE,
    FAVICON_BLUE,
    FAVICON_LAUGHT,
    FAVICON_HAT,
    _404_ILLUSTRATION,
    WARNING_ILLUSTRATION,
    OUPS_ILLUSTRATION,
    USER_AVATAR_MALE,
    USER_AVATAR_FEMALE,
};

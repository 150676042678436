import React, {Component} from "react";
import Sidebar from "../sidebar/Sidebar";
import "../../../assets/subscription_plan.css";
import CurrencyService from "../../../network/services/CurrencyService";
import ReactPaginate from 'react-paginate';
import backendUrls from "../../../network/urls/backendUrls";
import AdminHeader from "../header/AdminHeader";
import '../Dashboard/AdminHome.css';

class Currency extends Component {
    constructor(props) {
        super(props);

        this.refreshList = this.refreshList.bind(this);
        this.saveCurrency = this.saveCurrency.bind(this);
        this.updateCurrency = this.updateCurrency.bind(this);
        this.getId = this.getId.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onUpdateName = this.onUpdateName.bind(this);
        this.deleteCurrency = this.deleteCurrency.bind(this);
        this.onFileChange = this.onFileChange.bind(this);

        this.state = {
            currencies: [],
            selectedFile: null,
            name: null,
            url_image: backendUrls.File.imageLocation,
            currentPage: null,
            currentCurrency: {
                id: null,
                name: null,
            },
        };
    }


    onChangeName(e) {
        this.setState({
            name: e.target.value,
        });
    }

    onUpdateName(e) {
        const name = e.target.value;
        this.setState(function (prevState) {
            return {
                currentCurrency: {
                    ...prevState.currentCurrency,
                    name: name,
                },
            };
        });
    }


    getId(e) {
        const id = e.target.getAttribute("id");
        console.log(id);

        this.setState({
            isLoading: true
        });
        CurrencyService.getById(id, (data) => {
            console.log(data);
            this.setState({
                currentCurrency: data,
                isLoading: false
            });
        });
    }

    saveCurrency() {
        var d = new Date();
        var date = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
        var hours = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
        var fullDate = date + 'T' + hours + 'Z';
        if (this.state.name && this.state.selectedFile) {
            const formdata = new FormData();
            formdata.append("name", this.state.name);
            formdata.append("file", this.state.selectedFile, this.state.selectedFile.name);

            CurrencyService.create(formdata, (dat) => {
                this.setState({
                    submitted: false,
                    edited: false,
                    successMessage: true
                });
                this.refreshList();
            });
        }

    }

    updateCurrency() {
        var d = new Date();
        var date = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
        var hours = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
        var fullDate = date + 'T' + hours + 'Z';
        const formdata = new FormData();
        formdata.append("name", this.state.currentCurrency.name);
        formdata.append("file", this.state.selectedFile, this.state.selectedFile.name);
        CurrencyService.update(
            this.state.currentCurrency.id,
            formdata,
            (dat) => {
                this.setState({
                    updateMessage: true,
                });
                this.refreshList();
            }
        );
    }

    deleteCurrency(e) {
        const id = e.target.getAttribute("id");

        CurrencyService.delete(id, (dat) => {
            this.setState({
                deleteMessage: true,
            });
            this.refreshList();
        });
    }

    retrieveCurrency() {
        this.setState({
            isLoading: true
        });
        const page_url = 1;
        const nombre_items = 3;
        CurrencyService.getAllByPage(page_url, nombre_items, (data) => {
            console.log(data)
            if (data["hydra:totalItems"] > 3) {
                console.log("superieur a " + data["hydra:totalItems"]);
                console.log("data");
                console.log(data["hydra:member"]);
                console.log(data["hydra:view"]["hydra:last"]);
                console.log(data["hydra:view"]["hydra:last"].slice(36));
                const datas = data["hydra:member"];
                const lPage = (data["hydra:view"]["hydra:last"]).slice(36);
                console.log(datas);
                this.setState({
                    currencies: datas,
                    lastPage: lPage,
                    isLoading: false
                });
            } else {
                const datas = data["hydra:member"];
                console.log("egale a " + data["hydra:totalItems"]);
                this.setState({
                    currencies: datas,
                    lastPage: 1,
                    isLoading: false
                });
            }
            console.log(data)
        })

    }

    getCurrencyByPage(e) {

        console.log("id")
    }

    refreshList() {

        this.retrieveCurrency();
    }

    componentDidMount() {
        this.retrieveCurrency();
    }

    // On file select (from the pop up)
    onFileChange(event) {

        // Update the state
        let file = event.target.files[0];
        this.setState({selectedFile: file});

    };

    render() {
        const {currentCurrency} = this.state;


        const handlePageClick = (data) => {
            this.setState({
                isLoading: true
            });
            console.log(data.selected);
            const page_url = data.selected + 1;
            const nombre_items = 3;
            CurrencyService.getAllByPage(page_url, nombre_items, (data) => {
                console.log(data["hydra:member"]);
                this.setState({currencies: data["hydra:member"], isLoading: false});


            });
        }

        return (
            <div class="g-sidenav-show  bg-gray-200">
                <Sidebar/>
                <main className="mainAdmin r ms-100">
                    <AdminHeader location="Currency"/>


                    <div className="position-fixed bottom-1 end-1 z-index-2">
                        {this.state.successMessage ? (
                                <div className="toast fade show p-2 mt-2 bg-gradient-info" role="alert"
                                     aria-live="assertive" id="infoToast" aria-atomic="true">
                                    <div className="toast-header bg-transparent border-0">
                                        <i className="material-icons text-white me-2">
                                            check
                                        </i>
                                        <span className="me-auto text-white font-weight-bold">Currency</span>
                                        <small className="text-white">just now</small>
                                        <i className="fas fa-times text-md text-white ms-3 cursor-pointer"
                                           data-bs-dismiss="toast" aria-label="Close"></i>
                                    </div>
                                    <hr className="horizontal light m-0"/>
                                    <div className="toast-body text-white">
                                        currency successfully created.
                                    </div>
                                </div>
                            ) :
                            (<></>)}
                        {this.state.updateMessage ? (
                                <div className="toast fade show p-2 mt-2 bg-white" role="alert" aria-live="assertive"
                                     id="warningToast" aria-atomic="true">
                                    <div className="toast-header border-0">
                                        <i className="material-icons text-warning me-2">
                                            check
                                        </i>
                                        <span className="me-auto font-weight-bold">Currency</span>
                                        <small className="text-body">just now</small>
                                        <i className="fas fa-times text-md ms-3 cursor-pointer" data-bs-dismiss="toast"
                                           aria-label="Close"></i>
                                    </div>
                                    <hr className="horizontal dark m-0"/>
                                    <div className="toast-body">
                                        updated currency .
                                    </div>
                                </div>
                            ) :
                            (<></>)}

                        {this.state.deleteMessage ? (
                                <div className="toast fade show p-2 mt-2 bg-white" role="alert" aria-live="assertive"
                                     id="warningToast" aria-atomic="true">
                                    <div className="toast-header border-0">
                                        <i className="material-icons text-warning me-2">
                                            check
                                        </i>
                                        <span className="me-auto font-weight-bold">Currency</span>
                                        <small className="text-body">just now</small>
                                        <i className="fas fa-times text-md ms-3 cursor-pointer" data-bs-dismiss="toast"
                                           aria-label="Close"></i>
                                    </div>
                                    <hr className="horizontal dark m-0"/>
                                    <div className="toast-body">
                                        currency delete.
                                    </div>
                                </div>
                            ) :
                            (<></>)}
                    </div>

                    <div className="container-fluid py-4">


                        <div class="row mb-4">
                            <div class="col-lg-12 col-md-6 mb-md-0 mb-4">
                                <div class="card">
                                    <div class="card-header pb-0">
                                        <div class="row">
                                            <div class="col-lg-6 col-7">
                                                <h6>Currency</h6>
                                            </div>
                                            <div class="col-lg-6 col-5 my-auto text-end">
                                                <div class="dropdown float-lg-end pe-4">

                                                    <button type="button"
                                                            class="btn btn-secondary" data-bs-toggle="modal"
                                                            data-bs-target="#exampleModal">New currency
                                                    </button>


                                                    {/*  <a class="cursor-pointer" id="dropdownTable" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i class="fa fa-ellipsis-v text-secondary"></i>
                                                            </a>
                                                            <ul class="dropdown-menu px-2 py-3 ms-sm-n4 ms-n5" aria-labelledby="dropdownTable">
                                                                <li><a className="dropdown-item border-radius-md" href="javascript:;">Action</a></li>
                                                                <li><a className="dropdown-item border-radius-md" href="javascript:;">Another action</a></li>
                                                                <li><a className="dropdown-item border-radius-md" href="javascript:;">Something else here</a></li>
                                                            </ul> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-body px-0 pb-2">
                                        <div class="table-responsive">
                                            <table class="table align-items-center mb-0">
                                                <thead>
                                                <tr>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">ID</th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Name</th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Image</th>
                                                    <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">...</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    this.state.isLoading ? (
                                                        <div class="spinner-border text-primary" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>

                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                <>
                                                    {this.state.currencies && this.state.currencies.map((currencie) => (
                                                        <tr key={currencie.id}>
                                                            <td>
                                                                <div className="d-flex px-2 py-1">
                                                                    <div
                                                                        className="d-flex flex-column justify-content-center">
                                                                        <h6 className="mb-0 text-sm">{currencie.id}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div
                                                                        class="d-flex flex-column justify-content-center">
                                                                        <h6 class="mb-0 text-sm">{currencie.name}</h6>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div class="d-flex px-2 py-1">

                                                                    <div
                                                                        class="d-flex flex-column justify-content-center">
                                                                        <img
                                                                            src={`${this.state.url_image}${currencie.filePath}`}
                                                                            class="avatar avatar-sm me-3" alt="xd"/>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td>


                                                                <div className="d-flex px-2 py-1">
                                                                    <div
                                                                        className="d-flex flex-column justify-content-center">
                                                                        <span className="material-icons text-primary"
                                                                              data-bs-toggle="modal"
                                                                              data-bs-target={
                                                                                  "#exampleModal" + currencie.id
                                                                              }
                                                                              onClick={this.getId}
                                                                              id={currencie.id}
                                                                              style={{cursor: "pointer"}}>edit</span>
                                                                        <span className='material-icons text-danger'
                                                                              data-bs-toggle="modal"
                                                                              data-bs-target={
                                                                                  "#exampleModalDelete" + currencie.id
                                                                              }
                                                                              style={{cursor: "pointer"}}>delete</span>

                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <div
                                                                className="modal fade"
                                                                id={"exampleModal" + currencie.id}
                                                                tabindex="-1"
                                                                aria-labelledby="exampleModalLabel"
                                                                aria-hidden="true"
                                                            >
                                                                <div className="modal-dialog modal-dialog-centered">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5
                                                                                className="modal-title"
                                                                                id="exampleModalLabel"
                                                                            >
                                                                                Edit Currency
                                                                            </h5>
                                                                            <button
                                                                                type="button"
                                                                                className="btn-close"
                                                                                data-bs-dismiss="modal"
                                                                                aria-label="Close"
                                                                            ></button>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            {
                                                                                this.state.isLoading ? (
                                                                                    <div
                                                                                        class="spinner-border text-primary"
                                                                                        role="status">
                                                                                        <span
                                                                                            class="sr-only">Loading...</span>
                                                                                    </div>

                                                                                ) : (
                                                                                    <></>
                                                                                )
                                                                            }

                                                                            <form
                                                                                action="app/src/components/Admin/Currency/Currency#">
                                                                                <div className="single-form">
                                                                                    <label>Name</label>
                                                                                    <input
                                                                                        type="text"
                                                                                        placeholder="name"
                                                                                        name="name"
                                                                                        value={
                                                                                            currentCurrency.name
                                                                                        }
                                                                                        onChange={this.onUpdateName}
                                                                                    />
                                                                                </div>

                                                                                <div class="mb-3">
                                                                                    <label for="formFile"
                                                                                           class="form-label">Image</label>
                                                                                    <input class="form-control"
                                                                                           type="file" id="formFile"
                                                                                           onChange={this.onFileChange}/>
                                                                                </div>


                                                                                <div className="single-form">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-success btn-lg btn-block"
                                                                                        onClick={this.updateCurrency}
                                                                                        id={currencie.id}
                                                                                        data-bs-dismiss="modal"
                                                                                        aria-label="Close"
                                                                                    >
                                                                                        Update
                                                                                    </button>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                        <div class="modal-footer">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-secondary"
                                                                                data-bs-dismiss="modal"
                                                                            >
                                                                                Close
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div
                                                                className="modal fade"
                                                                id={"exampleModalDelete" + currencie.id}
                                                                tabindex="-1"
                                                                aria-labelledby="exampleModalLabel"
                                                                aria-hidden="true"
                                                            >
                                                                <div className="modal-dialog modal-dialog-centered">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title"
                                                                                id="exampleModalLabel">
                                                                                Delete currency
                                                                            </h5>
                                                                            <button
                                                                                type="button"
                                                                                className="btn-close"
                                                                                data-bs-dismiss="modal"
                                                                                aria-label="Close"
                                                                            ></button>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            Do you want to delete
                                                                        </div>
                                                                        <div class="modal-footer">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-secondary"
                                                                                data-bs-dismiss="modal"
                                                                            >
                                                                                No
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-danger  btn-block"
                                                                                onClick={this.deleteCurrency}
                                                                                data-bs-dismiss="modal"
                                                                                aria-label="Close"
                                                                                id={currencie.id}
                                                                            >
                                                                                <span
                                                                                    className='material-icons text-white'
                                                                                    data-bs-toggle="modal">delete</span>Yes
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </tr>
                                                    ))}
                                                </>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-footer py-4">
                            <nav aria-label="...">
                                <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={'...'}
                                    pageCount={this.state.lastPage}
                                    marginPagesDisplayed={3}
                                    pageRangeDisplayed={6}
                                    onPageChange={handlePageClick}
                                    containerClassName={"pagination justify-content-end mb-0"}
                                    pageClassName={"page-item"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    pageLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}

                                />
                            </nav>
                        </div>

                    </div>
                </main>


                <div
                    className="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Add currency
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <form action="app/src/components/Admin/Currency/Currency#">
                                    <div className="single-form">
                                        <label>Name</label>
                                        <input
                                            type="text"
                                            placeholder="name"
                                            name="name"
                                            value={this.state.name}
                                            onChange={this.onChangeName}

                                        />
                                    </div>


                                    <div class="mb-3">
                                        <label for="formFile" class="form-label">Image</label>
                                        <input class="form-control" type="file" id="formFile"
                                               onChange={this.onFileChange} required/>
                                    </div>


                                    <div className="single-form">
                                        <button
                                            type="submit"
                                            className="btn btn-success btn-lg btn-block"
                                            onClick={this.saveCurrency}
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Add
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        );
    }
}

export default Currency;
import React, {useEffect, useRef, useState} from "react";
import Sidebar from "../sidebar/Sidebar";
import AdminFooter from "../footer/AdminFooter";
import moment from "moment";
import ReactPaginate from "react-paginate";
import AdminHeader from "../header/AdminHeader";
import TransactionService from "../../../network/services/TransactionService";
import FilterComponent2 from "./FilterComponent";

const TransactionLogs = () => {

    const [recentCommands, setRecentCommands] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [totalItems, setTotalItems] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10)
    const [itemPerPage2, setItemPerPage2] = useState(10);
    const [sessionId, setSessionId] = useState(null);
    const [paymentHistoryStatus, setPaymentHistoryStatus] = useState(null);
    const [researchIndicator, setResearchIndicator] = useState('');
    const [pageNumber, setPageNumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handlePageClickCustom = () => {

        let toSend = {
            search: researchIndicator,
            item_per_page: itemPerPage2,
            session_id: sessionId,
            status: paymentHistoryStatus,
        }

        setIsLoading(true);

        TransactionService.getAll(toSend, pageNumber, (response) => {
            if (response) {
                // console.log("les donnes")
                // console.log(response)
                if (response) {
                    setRecentCommands(response.data);
                    setCurrentPage(parseInt(pageNumber));
                    setLastPage(response.total_pages);
                    setTotalItems(response.total_items_count);
                    setItemPerPage(response.items_per_page);
                }
                setIsLoading(false);
            }
        });
    }

    const handlePageClick = (data) => {

        const requestedPage = data.selected + 1;

        let toSend = {
            search: researchIndicator,
            item_per_page: itemPerPage2,
            session_id: sessionId,
            status: paymentHistoryStatus,
        }

        setIsLoading(true);

        TransactionService.getAll(toSend, requestedPage, (response) => {
            if (data) {
                // console.log("les donnes")
                // console.log(response)
                if (data) {
                    setRecentCommands(response.data);
                    setCurrentPage(response.page_actual);
                    setLastPage(response.total_pages);
                    setTotalItems(response.total_items_count);
                    setItemPerPage(response.items_per_page);
                }
                setIsLoading(false);
            }
        });
    }

    useEffect(() => {
        showTransactions();
    }, []);


    const showTransactions = () => {

        let data = {
            session_id: "",
            status: "",
            search: "",
            item_per_page: "",
        }

        setIsLoading(true);

        TransactionService.getAll(data, null, (response) => {
            if (data) {
                // console.log("les donnes")
                // console.log(response)
                if (data) {
                    setRecentCommands(response.data);
                    setCurrentPage(response.page_actual);
                    setLastPage(response.total_pages);
                    setTotalItems(response.total_items_count);
                    setItemPerPage(response.items_per_page);
                }
                setIsLoading(false);
            }
        });
    }

    const handleChange = (event) => {
        setResearchIndicator(event.target.value);
    }
    const handleSearch = (e) => {
        let nb = e.target.value;
        setItemPerPage2(nb)
    }

    const setPaymentHistoryStatusValue = (obj) => {
        console.log("le status " + obj.ContactName)
        if (obj.ContactName === 'SUCCEEDED') {
            setPaymentHistoryStatus('succeed');
            return;
        }
        if (obj.ContactName === 'FAILED') {
            setPaymentHistoryStatus('fail');
            return;
        }
        if (obj.ContactName === 'REFUNDED') {
            setPaymentHistoryStatus('refunded');
        }
    }

    const setSessionIdValue = (obj) => {
        setSessionId(obj.CustomerID);
    }

    const clear = () => {
        this.setState({sessionId: '', paymentHistoryStatus: '', researchIndicator: ""});
        this.setState({isClear: true})
    }

    const clearAll = () => {
        setSessionId(null);
        setPaymentHistoryStatus(null);
        setResearchIndicator('');
        childCompRef.current.clear();
        childCompRef2.current.clear();
        setItemPerPage(10);
        setItemPerPage2(10);
        setCurrentPage(1);
        let select_box = document.getElementById("myselectbox");
        select_box.selectedIndex = 1;
        showTransactions();
    }

    const filter = () => {

        const data = {
            search: researchIndicator,
            item_per_page: itemPerPage2,
            session_id: sessionId,
            status: paymentHistoryStatus,
        }

        TransactionService.getAll(data, null, (response) => {
            if (response !== undefined) {
                if (data) {
                    setRecentCommands(response.data);
                    setCurrentPage(response.page_actual);
                    setLastPage(response.total_pages);
                    setTotalItems(response.total_items_count);
                    setItemPerPage(response.items_per_page);
                }
            }
            setIsLoading(false);
            // this.setState({isLoading: false, isSend: false, isClear: false})
        });
    }

    const tabStatus = [
        {CustomerID: '1', ContactName: 'SUCCEEDED'},
        {CustomerID: '2', ContactName: 'FAILED'},
        {CustomerID: '3', ContactName: 'REFUNDED'}
    ]
    const childCompRef = useRef();
    const childCompRef2 = useRef();

    const currentNumberElements = (currentPage * itemPerPage);

    const start = 1 + ((currentPage * itemPerPage) - itemPerPage);

    const limit = (currentNumberElements < totalItems) ? currentNumberElements : totalItems;
    return (
        <div>
            <>
                <Sidebar/>
                <main className="mainAdmin r ms-100">
                    <AdminHeader location="List of transactions"/>

                    <div className="container-fluid py-4">
                        <div className="row">
                            <div className="col-lg-12 col-md-10 mx-auto">
                                <div className="card ">
                                    <div className="card-header p-3">
                                        <h5 className="mb-0">Filter transactions here... </h5>
                                        <br/>
                                        <div className="row">
                                            <div className="col-3"><FilterComponent2
                                                parentCallback={setSessionIdValue}
                                                parentTitle="Select session"
                                                // parentClear={isClear}
                                                ref={childCompRef}
                                            /></div>
                                            <div className="col-2">
                                                <FilterComponent2
                                                    parentCallback={setPaymentHistoryStatusValue}
                                                    parentData={tabStatus}
                                                    parentTitle="Select transaction status"
                                                    //  parentClear={isClear}
                                                    ref={childCompRef2}
                                                /></div>
                                            <div className="col-3">
                                                <div className="form-group has-search">
                                                    <form>
                                                        <span className="fa fa-search form-control-feedback"></span>
                                                        <input type="text" className="form-control" placeholder="Search"
                                                               value={researchIndicator}
                                                               name="search"
                                                               onChange={(event) => handleChange(event)}/>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="col-1">
                                                <div className="form-group has-search" style={{marginRight: "-8px"}}>
                                                    <form>
                                                        <select className="form-control" id="myselectbox"
                                                                onClick={(e) => {
                                                                    // console.log(e.target.value);
                                                                    handleSearch(e)
                                                                }}>
                                                            <option value="5">5</option>
                                                            <option value="10" selected>10</option>
                                                            <option value="15">15</option>
                                                            <option value="20">20</option>
                                                            <option value="25">25</option>
                                                            <option value="50">50</option>
                                                        </select>
                                                    </form>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={filter}
                                                >
                                                    Apply filters
                                                </button>
                                                &nbsp;&nbsp;&nbsp;
                                                <button type="button"
                                                        className="btn btn-outline-secondary"
                                                        onClick={clearAll}
                                                >Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body p-3">
                                        {/*<select className="form-select" aria-label="Default select example"*/}
                                        {/*        onChange={this.handleChange}>*/}
                                        {/*    <option selected>All user</option>*/}
                                        {/*    <option value={"having_paid"}>Users having paid everything</option>*/}
                                        {/*    <option value={"not_having_paid"}>Users not having paid everything*/}
                                        {/*    </option>*/}

                                        {/*</select>*/}
                                        <div className="table-responsive p-0">
                                            <table className="table align-items-center mb-0">
                                                <thead>
                                                <tr>
                                                    {/*<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">N°*/}
                                                    {/*    Order*/}
                                                    {/*</th>*/}
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">session
                                                        name
                                                    </th>
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">username</th>
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Email</th>
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Status</th>
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Amount</th>
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Payment
                                                        Date
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    recentCommands && recentCommands.map((order) => (

                                                        <tr key={order.id}>
                                                            <td className="align-middle text-center text-sm">
                                                                    <span
                                                                        className="text-xs font-weight-bold"> {order.session_name} </span>
                                                            </td>
                                                            <td className="align-middle text-center text-sm">
                                                                    <span
                                                                        className="text-xs font-weight-bold"> {order.name} </span>
                                                            </td>
                                                            <td className="align-middle text-center text-sm">
                                                                    <span
                                                                        className="text-xs font-weight-bold"> {order.email} </span>
                                                            </td>
                                                            <td className="align-middle text-center text-sm">
                                                                <p className="text-xs font-weight-bold mb-0">
                                                                    {order.status === 'succeed' && <span
                                                                        className="badge badge-success"> succeeded
                                                                    </span>}
                                                                    {order.status === 'fail' && <span
                                                                        className="badge badge-pill badge-danger">failed</span>}
                                                                    {order.status === 'refunded' && <span
                                                                        className="badge badge-pill badge-warning">refunded</span>}
                                                                </p>
                                                            </td>
                                                            <td className="align-middle text-center text-sm">
                                                                    <span
                                                                        className="text-xs font-weight-bold"> {order.amount} </span>
                                                            </td>
                                                            <td className="align-middle text-center text-sm">
                                                                    <span
                                                                        className="text-xs font-weight-bold">
                                                                        {moment(order.payment_date).utc().format('DD-MM-YYYY')}
                                                                </span></td>
                                                        </tr>
                                                    ))
                                                }
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-6">
                                {isLoading ? (
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>

                                    ) :
                                    null}
                            </div>
                            <div className="col-6">
                                <p align="right">{totalItems ? start + "-" + limit + " of " + totalItems : null}</p>
                            </div>
                        </div>
                        <AdminFooter/>
                    </div>
                    <div className="row">
                        <div className="col-md-2">

                        </div>
                        <div className="col-md-6">
                            <div className="card-footer py-4">
                                <nav aria-label="...">
                                    <ReactPaginate
                                        previousLabel={"<"}
                                        nextLabel={">"}
                                        breakLabel={'...'}
                                        pageCount={lastPage}
                                        // forcePage={1}
                                        marginPagesDisplayed={3}
                                        pageRangeDisplayed={6}
                                        onPageChange={handlePageClick}
                                        containerClassName={"pagination justify-content-end mb-0"}
                                        pageClassName={"page-item"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        pageLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        activeClassName={"active"}

                                    />
                                </nav>
                            </div>
                        </div>
                        <div className="col-md-3">
                            {/*<form className="mt-3">*/}
                            {/*    <div className="row">*/}
                            {/*        <div className="col-md-6"><input type="number" size="10" className="form-control"*/}
                            {/*                                         placeholder="Page number"*/}
                            {/*                                         value={pageNumber}*/}
                            {/*                                         name="pageNumber"*/}
                            {/*                                         onChange={(event) => setPageNumber(event.target.value)}/></div>*/}
                            {/*        <div className="col-md-6">*/}
                            {/*            <button*/}
                            {/*                type="button"*/}
                            {/*                className="btn btn-primary"*/}
                            {/*                onClick={handlePageClickCustom}*/}
                            {/*            >*/}
                            {/*                search*/}
                            {/*            </button>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    */}
                            {/*</form>*/}
                        </div>
                    </div>
                </main>
            </>
        </div>
    )

}

export default TransactionLogs;
import {sPOST_FETCH} from "../protocol/HTTP";
import backendUrls from "../urls/backendUrls";

const RefundCourseService = {

    refunding: async function (data) {
        const url=backendUrls.COURSE.REFUND_URL;
        return await sPOST_FETCH(url, data).then(r => r.json());
    },

    cancel: async function (data) {
        const url=backendUrls.COURSE.CANCEL_SUBSCRIPTION_URL;
        return await sPOST_FETCH(url, data).then(r => r.json());
    },

}
export default RefundCourseService;
import { useEffect, useRef, useState } from "react";
import OrderSessionCheckout from "../../../../components/frontv2.0/common/sessions/OrderSessionCheckout";
import BillingDetailCheckOut from "../../../../components/frontv2.0/common/sessions/BillingDetailCheckOut";
import { useNavigate, useParams } from "react-router-dom";
import { getUser } from "../../../../network/auth/Auth";
import UserService from "../../../../network/services/UserService";
import SessionService from "../../../../network/services/SessionService";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import CheckoutService from "../../../../network/services/CheckoutService";
import Loader from "../../../../components/frontv2.0/common/loader/Loader";
import { Toast } from "primereact/toast";
import AppLayout from "../../../../layouts/AppLayout";
import AppHeader from "../../../../components/frontv2.0/common/header/AppHeader";
import SubHeaderOffLine from "../../../../components/frontv2.0/common/header/SubHeaderOffLine";
import PageOops from "../../../../components/frontv2.0/common/opps/PageOops";
import { MOTIF_LOGIN } from "../../../../constants/images";

const OpenedSessionCheckOutApp = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const toast = useRef(null);
  const { planId, sessionId } = useParams();
  /**
   * States
   */
  const [user, setUser] = useState(null);
  const [firstLoader, setFirstLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSubscriptionPlan, setCurrentSubscriptionPlan] = useState({
    id: null,
    paymentAmount: null,
    price: null,
    numberInstallment: null,
    intervalBtwInstallment: null,
    name: null,
  });
  const [billingFormDetails, setBillingFormDetails] = useState({
    name: "",
    firstName: "",
    email: "",
    address: "",
    phone: "",
    city: "",
    region: "",
    zipcode: "",
    country: "United States",
    gender: "",
  });
  const [alreadySubscribed, setAlreadySubscribed] = useState(false);
  const [isAvailable, setIsAvailable] = useState(true);
  const [response, setResponse] = useState("");
  const [stepperEnabled, setStepperEnabled] = useState(false);
  const [isNextStep, setIsNextStep] = useState(false);
  const [errors, setErrors] = useState({});
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [idCode, setIdCode] = useState("");


  /**
   * Comportments
   */

  const showSuccess = (message) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };

  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };

  const notification = (message, status) => {
    if (status) {
      showSuccess(message);
    } else {
      showError(message);
    }
  };

  const handleClickToNextPreviousStep = () => {
    setIsNextStep(!isNextStep);
  };

  const handleValidateForm = () => {
    const errorObj = {};

    if (!billingFormDetails.email) errorObj.email = "Please enter your email";

    if (!billingFormDetails.phone) errorObj.phone = "Please enter your phone";

    if (!billingFormDetails.city) errorObj.city = "Please enter your city";

    if (!billingFormDetails.region)
      errorObj.region = "Please enter your region";

    if (!billingFormDetails.country)
      errorObj.country = "Please enter your country";

    if (!billingFormDetails.zipcode)
      errorObj.zipcode = "Please enter your zipcode";

    setErrors(errorObj);
  };

  const handleValidateForms = () => {
    const errorObj = {};

    if (!billingFormDetails.email) errorObj.email = "";

    if (!billingFormDetails.phone) errorObj.phone = "";

    if (!billingFormDetails.city) errorObj.city = "";

    if (!billingFormDetails.region) errorObj.region = "";

    if (!billingFormDetails.country) errorObj.country = "";

    if (!billingFormDetails.zipcode) errorObj.zipcode = "";

    setErrors(errorObj);
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setBillingFormDetails({
      ...billingFormDetails,
      [name]: value,
    });
    handleValidateForms();
  };

  const stripePaymentCheck = async () => {
    if (!stripe || !elements) {
      return;
    }

    const { subscriptionIntentId, stripeClientSecret } =
      await CheckoutService.createSubscriptionIntent({
        session_id: sessionId,
        subscription_plan_id: planId,
        country: billingFormDetails.country,
        region: billingFormDetails.region,
        city: billingFormDetails.city,
        zip_code: billingFormDetails.zipcode,
        phone: billingFormDetails.phone,
        address: billingFormDetails.address,
        couponId:idCode,
      });

    if (subscriptionIntentId == null && stripeClientSecret == null) {
      return {
        paymentSuccess: false,
        error: "",
        motive: "UNAVAILABLE",
      };
    }

    const result = await stripe.confirmCardPayment(stripeClientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: user.name,
        },
      },
    });

    if (result.error) {
      return {
        paymentSuccess: false,
        error: result.error.message,
        paymentMethod: "",
        motive: "",
        subscriptionIntentId: subscriptionIntentId,
      };
    } else {
      return {
        paymentSuccess: true,
        error: null,
        paymentMethod: result.payment_method,
        motive: "",
        subscriptionIntentId: subscriptionIntentId,
      };
    }
  };

  const saveSubscription = async () => {
    handleValidateForm();
    if (
      billingFormDetails.city &&
      billingFormDetails.email &&
      billingFormDetails.region &&
      billingFormDetails.phone &&
      billingFormDetails.zipcode
    ) {
      setIsLoading(true);

      /*
       * Because we want the subscriptionIntent to be confirmed in all cases, we
       * are not testing again the return value of true or false return by the stripePayment before confirming
       * */
      const { paymentSuccess, error, motive, subscriptionIntentId } =
        await stripePaymentCheck();

      if (!paymentSuccess && motive) {
        setIsAvailable(false);
        notification(motive, false);
        setIsLoading(false);
        return;
      }

      if (!paymentSuccess && error && !motive) {
        notification(error, false);
        setIsLoading(false);
      }

      const { status, message } =
        await CheckoutService.confirmSubscriptionIntent({
          subscription_intent_id: subscriptionIntentId,
          couponId:idCode,
        });

      if (status) {
        notification(message, true);
        setIsLoading(false);
        navigate("/open-sessions/details/checkout/success");
        return;
      }

      setIsLoading(false);
    }
  };

  /*This function is to load the user from localStorage*/
  const loadData = () => {
    let user = getUser();
    setUser(user);
    setBillingFormDetails({ ...billingFormDetails, email: user.email });
  };

  const verifyPaymentConfirmation = () => {
    setFirstLoader(true);
    UserService.subscriptionToConfirm((response) => {
      if (response) {
        if (response.subscription_intent_completed === true) {
          navigate("/open-sessions/details/checkout/success");
        }
        verifySessionNotAlreadySubscribed();
      }
    });
  };

  const verifySessionNotAlreadySubscribed = () => {
    const data = {
      sessionId: sessionId,
    };

    UserService.checkExistingSubscription(data, (response) => {
      if (response) {
        if (response.alreadySubscribed) {
          setAlreadySubscribed(response.alreadySubscribed);
          setFirstLoader(false);
        } else {
          let data = {
            session_id: sessionId,
            subscription_plan_id: planId,
          };
          SessionService.getSessionAndSubscriptionPlan(data, (response) => {
            if (response) {
              if (!response.isAvailable) {
                setIsAvailable(false);
                setFirstLoader(false);
              }
              setResponse(response);
              setCurrentSubscriptionPlan(response.plans);
              setFirstLoader(false);
            }
          });
        }
      }
    });
  };

  useEffect(() => {
    loadData();
    verifyPaymentConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
      if (windowSize[0] >= 768) setStepperEnabled(false);
      else setStepperEnabled(true);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize]);

  /**
   * Render
   */
  return (
    <>
      <Toast ref={toast} position="top-right" />
      <AppLayout>
        <AppLayout.Content>
          {firstLoader ? (
            <div className="tw-flex tw-h-full tw-flex-col md:tw-justify-center tw-justify-center tw-items-center">
              <Loader />
            </div>
          ) : (
            <div>
              {!alreadySubscribed ? (
                isAvailable ? (
                  <>
                    <div
                      className={`tw-flex xl:tw-flex-row tw-flex-col tw-w-full xl:tw-space-x-2 xl:tw-space-y-0'`}
                    >
                      {/* // Section Left : Billing Form */}
                      <BillingDetailCheckOut
                        userEmail={user.email}
                        isNextStep={isNextStep}
                        onClickActionToPreviousStep={
                          handleClickToNextPreviousStep
                        }
                        data={billingFormDetails}
                        handleOnChange={handleFormChange}
                        sessionName={response?.session_name}
                        errors={errors}
                      />
                      {/* SectionLeft */}
                      <OrderSessionCheckout
                       setIdCode={setIdCode}
                        data={currentSubscriptionPlan}
                        sessionName={response?.session_name}
                        firstPaymentDate={response?.start_date}
                        nextPaymentDate={response?.next_payment_date}
                        enableForm={true}
                        stepperEnabled={stepperEnabled}
                        isNextStep={isNextStep}
                        onClickAction={saveSubscription}
                        onClickActionToPreviousStep={
                          handleClickToNextPreviousStep
                        }
                        isLoading={isLoading}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="tw-flex tw-h-full tw-flex-col md:tw-justify-center tw-justify-center tw-items-center tw-py-10"
                      style={{ backgroundImage: `url(${MOTIF_LOGIN})` }}
                    >
                      <PageOops
                        text="Session is not Avalable."
                        textBtn="Go to My sessions"
                        onClick={() => {
                          navigate(`/workspace/mysessions`);
                        }}
                      />
                    </div>
                  </>
                )
              ) : (
                <>
                  <div
                    className="tw-flex tw-h-full tw-flex-col md:tw-justify-center tw-justify-center tw-items-center tw-py-10"
                    style={{ backgroundImage: `url(${MOTIF_LOGIN})` }}
                  >
                    <PageOops
                      text="You already have a subscription active."
                      textBtn="Go to My sessions"
                      onClick={() => {
                        navigate(`/workspace/mysessions`);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </AppLayout.Content>

        <AppLayout.Header>
          <AppHeader />
        </AppLayout.Header>

        <AppLayout.SubHeader>
          <SubHeaderOffLine
            data={
              !alreadySubscribed
                ? [
                    { value: "Home", url: "/" },
                    {
                      value: "Opened Sessions",
                      url: "/open-sessions",
                    },
                    {
                      value: response?.session_name,
                      url: `/opened-sessions/details/${sessionId}`,
                    },
                    { value: "Checkout", url: "#" },
                  ]
                : [
                    { value: "Home", url: "/" },
                    {
                      value: "Opened Sessions",
                      url: "/open-sessions",
                    },
                    {
                      value: response?.session_name || "Details",
                      url: `/open-sessions/details/${sessionId}`,
                    },
                    { value: "Checkout", url: "#" },
                  ]
            }
          />
        </AppLayout.SubHeader>
      </AppLayout>
    </>
  );
};

export default OpenedSessionCheckOutApp;

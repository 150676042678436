import React, {Component} from "react";

import {useNavigate} from "react-router-dom";
import UserService from "../../../network/services/UserService";
import AdminFooter from "../footer/AdminFooter";
import Sidebar from "../sidebar/Sidebar";
import moment from "moment/moment";
import {Grid, GridColumn, GridToolbar} from '@progress/kendo-react-grid';
import {ExcelExport} from '@progress/kendo-react-excel-export';
import ReactPaginate from "react-paginate";
import AdminHeader from "../header/AdminHeader";

class AdminNewsletterSubscribers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscribers: [],
            totalSubscribers: '',
            elementPerPage: undefined,
            currentPage: '',
            lastPage: '',
        }
    }

    showSubscribers = () => {

        let data = {
            pageNumber: parseInt("1")
        }
        UserService.getAllSubscribers(data, (response) => {
            // console.log(response);
            this.setState({
                subscribers: response.subscribers,
                currentPage: response.pageNumber,
                totalSubscribers: response.totalNumberOfSubscribers,
                lastPage: Math.ceil(response.totalNumberOfSubscribers / (response.subscribers).length),
            })

        });
    }

    handlePageClick = (data) => {
        console.log(data.selected);
        const requestedPage = data.selected + 1;
        let toSend = {
            pageNumber: requestedPage
        };

        UserService.getAllSubscribers(toSend, (response) => {
            // console.log(response);
            this.setState({
                subscribers: response.subscribers,
                currentPage: response.pageNumber,
                totalSubscribers: response.totalNumberOfSubscribers,
                lastPage: Math.ceil(response.totalNumberOfSubscribers / (response.subscribers).length),
            })

        });
    }

    export = () => {
        if (this._export !== null) {
            this._export.save();
        }
    };

    exportInExcel = () => {

        return <ExcelExport data={this.state.subscribers} ref={exporter => this._export = exporter}>
            <Grid data={this.state.subscribers}
                  style={{
                      height: '420px'
                  }}>
                <GridToolbar>
                    <button title="Export Excel"
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            onClick={this.export}>
                        Export to Excel
                    </button>
                </GridToolbar>
                {/*<GridColumn field="user_id" title="User ID" width="100px"/>*/}
                <GridColumn field="email" title="Email" width="500px"/>
                <GridColumn field="createdAt" title="Created At" width="150px"/>
            </Grid>
        </ExcelExport>;
    };

    componentDidMount() {
        this.showSubscribers();
    }

    render() {
        return (
            <div>
                <>
                    <Sidebar/>
                    <main class="mainAdmin r ms-100">
                        <AdminHeader location="Newsletter subscribers"/>


                        <div className="container-fluid py-4">
                            <div className="row">
                                <div className="col-lg-12 col-md-10 mx-auto">

                                    <div className="card mt-4">
                                        {/*<div className="card-header p-3">*/}
                                        {/*    <h5 className="mb-0">All users</h5>*/}
                                        {/*</div>*/}
                                        <div className="card-body p-3">
                                            {/*<select class="form-select" aria-label="Default select example"*/}
                                            {/*        onChange={this.handleChange}>*/}
                                            {/*    <option selected>All user</option>*/}
                                            {/*    <option value={"having_paid"}>Users having paid everything</option>*/}
                                            {/*    <option value={"not_having_paid"}>Users not having paid everything*/}
                                            {/*    </option>*/}

                                            {/*</select>*/}
                                            <div class="table-responsive p-0">
                                                <table class="table align-items-center mb-0">

                                                    <thead>
                                                    <tr>
                                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">ID</th>
                                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">User
                                                            emails
                                                        </th>
                                                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Creation
                                                            date
                                                        </th>

                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        this.state.isLoading ? (
                                                            <div className="spinner-border text-primary" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>

                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                    {this.state.subscribers && this.state.subscribers.map((subscriber) => (
                                                        <tr key={subscriber.id}>
                                                            <td>
                                                                {subscriber.id}
                                                            </td>

                                                            <td>
                                                                <div
                                                                    className="d-flex flex-column justify-content-center">
                                                                    <h6 className="mb-0 text-sm">{subscriber.email}</h6>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div
                                                                    className="d-flex flex-column justify-content-center">
                                                                    <p className="text-xs font-weight-bold mb-0">{moment(subscriber.createdAt).utc().format('DD  MMMM  YYYY') + ' at ' + moment.utc(subscriber.createdAt).format("HH:mm")}</p>

                                                                </div>
                                                            </td>

                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                                <div style={{marginLeft: "85%", marginTop: "5%"}}>
                                                    <a
                                                        href="javascript:;"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                    >
                                                        <button type="button"
                                                                className="btn btn-info"
                                                            //onClick={this.exportInExcel}
                                                        >Export
                                                        </button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div
                                className="modal fade"
                                id="exampleModal"
                                tabIndex="-1"
                                aria-labelledby="exampleModalLabel"
                                aria-hidden="true">

                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">
                                                Data Exportation
                                            </h5>
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            ></button>
                                        </div>
                                        <div className="modal-body">
                                            {this.exportInExcel()}
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                data-bs-dismiss="modal"
                                            >
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <AdminFooter/>
                        </div>
                        <div className="card-footer py-4">
                            <nav aria-label="...">
                                <ReactPaginate
                                    previousLabel={"<"}
                                    nextLabel={">"}
                                    breakLabel={'...'}
                                    pageCount={this.state.lastPage}
                                    marginPagesDisplayed={3}
                                    pageRangeDisplayed={6}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={"pagination justify-content-end mb-0"}
                                    pageClassName={"page-item"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    pageLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}

                                />
                            </nav>
                        </div>
                    </main>
                </>
            </div>
        )
    }
}

export default function (props) {
    const navigation = useNavigate();
    //const {state} = useLocation();
    return <AdminNewsletterSubscribers {...props} myNavigate={navigation}/>
}

import React, {Component} from "react";

class AdminFooter extends Component {
    render() {
        return (
            <footer className="footer py-4  ">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-lg-between">
                        <div className="col-lg-6 mb-lg-0 mb-4">

                        </div>

                    </div>
                </div>
            </footer>
        )
    }
}

export default AdminFooter;


import { Toast } from "primereact/toast";
import Loader from "../../../../components/frontv2.0/common/loader/Loader";
import BillingDetailCheckOut from "../../../../components/frontv2.0/common/sessions/BillingDetailCheckOut";
//import OrderSessionCheckout from "../../../../components/frontv2.0/common/sessions/OrderSessionCheckout";
import OrderCourseCheckout from "../../../../components/frontv2.0/common/sessions/OrderCourseCheckout";
import { useEffect, useRef, useState } from "react";
import UserService from "../../../../network/services/UserService";
import { useNavigate, useParams } from "react-router";
import CourseService from "../../../../network/services/CourseService";
import { getUser } from "../../../../network/auth/Auth";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import CheckoutService from "../../../../network/services/CheckoutService";
import Header from "../../../../components/frontv2.0/common/header/Header";
import SubHeader from "../../../../components/frontv2.0/common/header/SubHeader";
import MainLayout from "../../../../layouts/MainLayout";
import PageOops from "../../../../components/frontv2.0/common/opps/PageOops";
import { MOTIF_LOGIN } from "../../../../constants/images";

const AvalailbleCourseCheckout = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const toast = useRef(null);
  const { planId, courseId } = useParams();
  // States
  const [user, setUser] = useState(null);
  const [firstLoader, setFirstLoader] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [alreadySubscribed, setAlreadySubscribed] = useState(false);
  const [response, setResponse] = useState({});
  const [isAvailable, setIsAvailable] = useState(true);
  const [stepperEnabled, setStepperEnabled] = useState(false);
  const [isNextStep, setIsNextStep] = useState(false);
  const [idCode, setIdCode] = useState("");
  const [errors, setErrors] = useState({});
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [currentSubscriptionPlan, setCurrentSubscriptionPlan] = useState({
    id: null,
    paymentAmount: null,
    price: null,
    numberInstallment: null,
    intervalBtwInstallment: null,
    name: null,
  });
  const [billingFormDetails, setBillingFormDetails] = useState({
    name: "",
    firstName: "",
    email: "",
    address: "",
    phone: "",
    city: "",
    region: "",
    zipcode: "",
    country: "United States",
    gender: "",
    reductionCode: ""
  });

  /**
   * Comportments
   */

  const handleClickToNextPreviousStep = () => {
    setIsNextStep(!isNextStep);
  };

  const showSuccess = (message) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };

  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };

  const loadData = () => {
    let user = getUser();
    setUser(user);
    setBillingFormDetails({ ...billingFormDetails, email: user.email });
  };

  const notification = (message, status) => {
    if (status) {
      showSuccess(message);
    } else {
      showError(message);
    }
  };

  const handleValidateForm = () => {
    const errorObj = {};

    if (!billingFormDetails.email) errorObj.email = "Please enter your email";

    if (!billingFormDetails.phone) errorObj.phone = "Please enter your phone";

    if (!billingFormDetails.city) errorObj.city = "Please enter your city";

    if (!billingFormDetails.region)
      errorObj.region = "Please enter your region";

    if (!billingFormDetails.country)
      errorObj.country = "Please enter your country";

    if (!billingFormDetails.zipcode)
      errorObj.zipcode = "Please enter your zipcode";

    setErrors(errorObj);
  };


  const handleValidateForms = () => {
    const errorObj = {};

    if (!billingFormDetails.email) errorObj.email = "";

    if (!billingFormDetails.phone) errorObj.phone = "";

    if (!billingFormDetails.city) errorObj.city = "";

    if (!billingFormDetails.region)
      errorObj.region = "";

    if (!billingFormDetails.country)
      errorObj.country = "";

    if (!billingFormDetails.zipcode)
      errorObj.zipcode = "";

    setErrors(errorObj);
  };




  const stripePaymentCheck = async () => {
    if (!stripe || !elements) {
      return;
    }

    const { subscriptionIntentId, stripeClientSecret } =
      await CheckoutService.createSubscriptionIntentCourse({
        course_id: courseId,
        subscription_plan_id: planId,
        country: billingFormDetails.country,
        region: billingFormDetails.region,
        city: billingFormDetails.city,
        zip_code: billingFormDetails.zipcode,
        phone: billingFormDetails.phone,
        address: "",
        couponId: idCode,
      });

    if (subscriptionIntentId == null && stripeClientSecret == null) {
      return {
        paymentSuccess: false,
        attachCardMessage: "",
        motive: "UNAVAILABLE",
      };
    }
    const result = await stripe.confirmCardPayment(stripeClientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: user.name,
        },
      },
    });

    if (result.error) {
      return {
        paymentSuccess: false,
        error: result.error.message,
        paymentMethod: "",
        motive: "",
        subscriptionIntentId: subscriptionIntentId,
      };
    } else {
      return {
        paymentSuccess: true,
        error: null,
        paymentMethod: result.payment_method,
        motive: "",
        subscriptionIntentId: subscriptionIntentId,

      };
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setBillingFormDetails({
      ...billingFormDetails,
      [name]: value,
    });
    handleValidateForms();
  };

  const verifyCourseNotAlreadySubscribed = () => {
    const data = {
      courseId: courseId,
    };

    UserService.checkExistingSubscriptionCourse(data, (response) => {
      if (response) {
        if (response.alreadySubscribed === true) {
          setAlreadySubscribed(response.alreadySubscribed);
        }
        let data = {
          course_id: courseId,
          subscription_plan_id: planId,
        };
        CourseService.getCourseAndSubscriptionPlan(data, (response) => {
          if (response) {
            if (!response.isAvailable) {
              setFirstLoader(false);
            }
            setResponse(response);
            setCurrentSubscriptionPlan(response.plans);
            setFirstLoader(false);
          }
        });
      }
    });
  };

  const verifyPaymentConfirmation = () => {
    setFirstLoader(true);

    UserService.courseSubscriptionToConfirm((response) => {
      if (response) {
        if (response.course_intent_completed === true) {
          navigate("/workspace/courses/details/checkout/success");
        }
        verifyCourseNotAlreadySubscribed();
      }
    });
  };

  const handleFormSubmit = async () => {
    handleValidateForm();
    if (
      billingFormDetails.city &&
      billingFormDetails.email &&
      billingFormDetails.region &&
      billingFormDetails.phone &&
      billingFormDetails.zipcode
    ) {
      setIsLoading(true);

      const { paymentSuccess, error, motive, subscriptionIntentId } =
        await stripePaymentCheck();

      if (!paymentSuccess && motive) {
        setIsAvailable(false);
        notification(motive, false);
        setIsLoading(false);
        return;
      }

      if (!paymentSuccess && error && !motive) {
        notification(error, false);
        setIsLoading(false);
      }

      const { status, message } =
        await CheckoutService.confirmSubscriptionIntentCourse({
          subscription_intent_course_id: subscriptionIntentId,
          couponId: idCode,

        });

      if (status) {
        notification(message, true);
        setIsLoading(false);
        navigate("/workspace/courses/details/checkout/success");
        return;
      }
      console.log("Éléments transmis côté serveur :", {
        paymentSuccess,
        error,
        motive,
        subscriptionIntentId,
        status,
        message,
      });

      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
      if (windowSize[0] >= 768) setStepperEnabled(false);
      else setStepperEnabled(true);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize]);

  useEffect(() => {
    loadData();
    verifyPaymentConfirmation();
  }, []);
  return (
    <>
      <Toast ref={toast} position="top-right" />
      <MainLayout>
        <MainLayout.Content>
          {firstLoader ? (
            <div className="tw-flex tw-h-full tw-flex-col md:tw-justify-center tw-justify-center tw-items-center">
              <Loader />
            </div>
          ) : (
            <>
              {!alreadySubscribed ? (
                isAvailable ? (
                  <>
                    <div
                      className={`tw-flex xl:tw-flex-row tw-flex-col tw-w-full xl:tw-space-x-2 xl:tw-space-y-0'`}
                    >
                      {/* // Section Left : Billing Form */}
                      <BillingDetailCheckOut
                        userEmail={user?.email}
                        isNextStep={isNextStep}
                        onClickActionToPreviousStep={
                          handleClickToNextPreviousStep
                        }
                        data={billingFormDetails}
                        handleOnChange={handleFormChange}
                        sessionName={response?.course_name}
                        errors={errors}
                      />
                      {/* SectionLeft */}
                      {/* <OrderSessionCheckout
                        isSession={false}
                        data={currentSubscriptionPlan}
                        sessionName={response?.course_name}
                        // firstPaymentDate={response?.start_date}
                        // nextPaymentDate={response?.next_payment_date}
                        enableForm={true}
                        stepperEnabled={stepperEnabled}
                        isNextStep={isNextStep}
                        onClickAction={handleFormSubmit}
                        onClickActionToPreviousStep={
                          handleClickToNextPreviousStep
                        }
                        isLoading={isLoading}
                      /> */}
                      <OrderCourseCheckout
                        isSession={false}
                        data={currentSubscriptionPlan}
                        sessionName={response?.course_name}
                        setIdCode={setIdCode}
                        // firstPaymentDate={response?.start_date}
                        // nextPaymentDate={response?.next_payment_date}
                        enableForm={true}
                        stepperEnabled={stepperEnabled}
                        isNextStep={isNextStep}
                        onClickAction={handleFormSubmit}
                        onClickActionToPreviousStep={
                          handleClickToNextPreviousStep
                        }
                        isLoading={isLoading}
                      />

                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="tw-flex tw-h-full tw-flex-col md:tw-justify-center tw-justify-center tw-items-center"
                      style={{ backgroundImage: `url(${MOTIF_LOGIN})` }}
                    >
                      <PageOops
                        text="Course is not Available."
                        textBtn="Go to My courses"
                        onClick={() => {
                          navigate(`/workspace/mycourses`);
                        }}
                      />
                    </div>
                  </>
                )
              ) : (
                <>
                  <div
                    className="tw-flex tw-h-full tw-flex-col md:tw-justify-center tw-justify-center tw-items-center"
                    style={{ backgroundImage: `url(${MOTIF_LOGIN})` }}
                  >
                    <PageOops
                      text="Sorry, you have already subscribed to this course."
                      textBtn="Go to My courses"
                      onClick={() => {
                        navigate(`/workspace/mycourses`);
                      }}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </MainLayout.Content>

        <MainLayout.Header>
          <Header />
        </MainLayout.Header>

        <MainLayout.SubHeader>
          <SubHeader
            data={[
              { value: "Home", url: "/workspace" },
              {
                value: "Avalaible Course",
                url: "/workspace/courses",
              },
              {
                value: response?.course_name,
                url: `/workspace/courses/details/${courseId}`,
              },
              { value: "Checkout", url: "#" },
            ]}
          />
        </MainLayout.SubHeader>
      </MainLayout>
    </>
  );
};

export default AvalailbleCourseCheckout;

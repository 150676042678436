import { FAVICON_BLUE, LOGO_WHITE } from "../../../constants/images";
import Alert from "./alert/Alert";
import Avatar from "./avatar/Avatar";
import ButtonCustom from "./button/ButtonCustom";
// import CardLarge from "./card/CardLarge";
import CardSmall from "./card/CardSmall";
import Footer from "./footer/Footer";
import InputCardNumber from "./form/InputCardNumber";
import InputCustom from "./form/InputCustom";
import Logo from "./logo/Logo";
// import SubscriptionPlan from "./subscriptions/SubscriptionPlan";
import TextHeader from "./text/TextHeader";
import StatsAmount from "./stats/StatsAmount";
import StatsInstallmentDate from "./stats/StatsInstallmentDate";
import Installments from "./table/Installments";
import SelectCustom from "./form/SelectCustom";

const sessions = [
  {
    name: "Session 1",
    description: "Lorem ipsum dolor sit amet",
    start_date: "2023-05-01",
    end_date: "2023-05-31",
    created_at: "2023-04-23T12:00:00Z",
    updated_at: "2023-04-23T12:00:00Z",
    customSessionPaymentDate: "2023-04-30",
    wordpressGroupId: 1,
    moodleGroupId: 1,
    sessionSubscriptionStatus: "active",
    sessionProgressionStatus: "in_progress",
    sessionPublicationStatus: "published",
    isCurrentSession: true,
    subscriptionPlans: [
      {
        name: "Basic",
        price: 10.99,
      },
      {
        name: "Premium",
        price: 19.99,
      },
    ],
  },
  {
    name: "Session 2",
    description: "Consectetur adipiscing elit",
    start_date: "2023-06-01",
    end_date: "2023-06-30",
    created_at: "2023-04-23T12:00:00Z",
    updated_at: "2023-04-23T12:00:00Z",
    customSessionPaymentDate: "2023-05-31",
    wordpressGroupId: 2,
    moodleGroupId: 2,
    sessionSubscriptionStatus: "inactive",
    sessionProgressionStatus: "not_started",
    sessionPublicationStatus: "draft",
    isCurrentSession: false,
    subscriptionPlans: [
      {
        name: "Basic",
        price: 9.99,
      },
      {
        name: "Premium",
        price: 17.99,
      },
    ],
  },
];

const datas = [
  {
    installment: "1",
    amount: 1000,
    status: true,
    date: "2023-04-02T15:47:14+00:00",
  },
  {
    installment: "1",
    amount: 1000,
    status: false,
    date: "2023-05-02T15:47:14+00:00",
  },
  {
    installment: "1",
    amount: 1000,
    status: false,
    date: "2023-08-02T15:47:14+00:00",
  },
  {
    installment: "1",
    amount: 1000,
    status: false,
    date: "2023-09-02T15:47:14+00:00",
  },
  {
    installment: "1",
    amount: 1000,
    status: false,
    date: "2023-10-02T15:47:14+00:00",
  },
];

const Components = () => {
  return (
    <>
      <div className="tw-flex tw-flex-col tw-justify-center tw-w-full min-tw-h-full tw-px-6 tw-py-12 tw-space-y-4 lg:tw-px-8">
        <div className="tw-mb-4">
          <h1>Stats pages</h1>
          <hr />
        </div>

        <div>
          <div class="tw-border tw-border-blue-300 tw-shadow tw-rounded-md tw-p-4 tw-max-w-sm tw-w-full tw-mx-auto">
            <div class="tw-animate-pulse tw-flex tw-space-x-4">
              <div class="tw-rounded-full tw-bg-slate-700 tw-h-10 tw-w-10"></div>
              <div class="tw-flex-1 tw-space-y-6 tw-py-1">
                <div class="tw-h-2 tw-bg-slate-700 tw-rounded"></div>
                <div class="tw-space-y-3">
                  <div class="tw-grid tw-grid-cols-3 tw-gap-4">
                    <div class="tw-h-2 tw-bg-slate-700 tw-rounded tw-col-span-2"></div>
                    <div class="tw-h-2 tw-bg-slate-700 tw-rounded tw-col-span-1"></div>
                  </div>
                  <div class="tw-h-2 tw-bg-slate-700 tw-rounded"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="tw-animate-pulse md:tw-flex md:tw-flex-row tw-flex-col tw-w-full tw-overflow-tw-hidden tw-bg-gray-100 tw-rounded-[12px] tw-border=gray-800">
            <div className="tw-hidden sm:tw-block md:tw-m-3 tw-bg-gray-400 tw-bg-cover tw-bg-center tw-rounded-[12px]  md:tw-w-[325px] tw-w-full" />
            <div className="tw-bg-slate-700 sm:tw-hidden md:tw-m-3 tw-bg-center tw-rounded-[12px] tw-max-h-[220px]  md:tw-w-[325px] tw-w-full" />
            <div className="tw-flex tw-flex-col tw-w-full tw-py-8 tw-px-4 ">
              <div className="tw-w-full tw-h-full">
                <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-text-primary tw-mb-8">
                  <div className="tw-flex tw-flex-col tw-text-lg tw-w-24 tw-space-y-1">
                    <div class="tw-h-2 tw-bg-gray-700 tw-rounded tw-col-span-2"></div>
                    <div class="tw-h-2 tw-bg-gray-700 tw-rounded tw-col-span-2"></div>
                  </div>
                  <div className="tw-flex justify-end tw-text-green-600  tw-text-[16px]">
                    <div>
                      <div class="tw-h-2 tw-bg-slate-700 tw-rounded tw-w-20" ></div>
                    </div>
                  </div>
                </div>
                <div className="tw-flex tw-flex-col tw-w-full md:tw-items-center md:tw-flex-row md:tw-justify-between">
                  <div className="tw-flex tw-flex-col tw-pt-4 tw-pb-4 md:tw-pb-0 tw-text-primary tw-text-md tw-w-56 tw-space-y-1">
                    <div className=" tw-items-center">
                      <div class="tw-h-2 tw-bg-slate-700 tw-rounded tw-col-span-1"></div>
                    </div>
                    <div className="">
                      <div class="tw-h-2 tw-bg-slate-700 tw-rounded tw-col-span-1"></div>
                    </div>
                  </div>
                  <div>
                    <div class=" tw-bg-gray-400 tw-h-10 tw-flex tw-justify-center tw-items-center tw-transition tw-ease-in-out tw-delay-100 tw-duration-200 tw-rounded-[9px] tw-px-4 tw-py-2  tw-font-bold tw-shadow-sm focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 tw-text-white tw-w-[137px]"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tw-mb-4">
          <h1>Stats pages</h1>
          <hr />
        </div>

        <div className="sm:tw-flex sm:tw-flex-row sm:tw-space-x-8">
          <StatsAmount status="all" amount={2000} totalInstallment={4} />
          <StatsAmount status="done" amount={2000} totalInstallment={4} />
          <StatsAmount status="remain" amount={2000} totalInstallment={4} />
          <StatsInstallmentDate numberDate={29} />
        </div>

        <div className="tw-mb-4">
          <h1>Error pages</h1>
          <hr />
        </div>
        <div className="tw-flex tw-flex-col tw-space-y-8">
          <div className="tw-flex tw-flex-row tw-text-primary">
            <div>
              <img src={FAVICON_BLUE} alt="" />
            </div>
            <div className="tw-flex tw-flex-col tw-h-full tw-space-y-0">
              <span className="tw-text-[41px] tw-p-0">Error</span>
              <span className="tw-text-[41px] tw-font-bold tw-p-0">404</span>
            </div>
          </div>

          <div className="tw-flex tw-flex-row tw-text-primary-100">
            <div>
              <img src={FAVICON_BLUE} alt="" />
            </div>
            <div className="tw-flex tw-flex-col tw-h-full tw-space-y-0 tw-bottom-0 tw-relative">
              <span className="tw-text-[51px] tw-font-bold tw-p-0">Oups</span>
            </div>
          </div>
        </div>

        <div className="tw-mb-4">
          <h1>Installments</h1>
          <hr />
        </div>
        <div className="tw-w-full">
          <Installments datas={datas} />
        </div>

        <div className="tw-mb-4">
          <h1>Instalments plans</h1>
          <hr />
        </div>

        <div className="tw-flex tw-flex-col tw-w-full tw-mb-4 sm:tw-flex-row sm:tw-justify-center">
          {/* <div className="tw-m-4">
                        <SubscriptionPlan type="classic"/>
                    </div>

                    <div className="tw-m-4">
                        <SubscriptionPlan type="premium"/>
                    </div>

                    <div className="tw-m-4">
                        <SubscriptionPlan type="medium"/>
                    </div> */}
        </div>

        <div className="tw-mb-4">
          <h1>CardSmall</h1>
          <hr />
        </div>

        <div className="tw-flex tw-flex-col tw-w-full tw-mb-4 sm:tw-flex-row sm:tw-justify-center sm:tw-space-x-8">
          <CardSmall datas={sessions} />
          <CardSmall datas={sessions} />
          <CardSmall datas={sessions} />
          <div className="tw-flex"></div>
        </div>

        <div className="tw-mb-4">
          <h1>CardLarge</h1>
          <hr />
        </div>

        <div className="tw-flex tw-flex-col tw-w-full tw-mb-4 tw-space-y-4">
          {/* {sessions &&
            sessions.map((item) => {
              return <CardLarge data={sessions} auth={false} />;
            })} */}
          {/* <CardLarge datas={sessions} auth={true} />
          <CardLarge datas={sessions} />
          <CardLarge datas={sessions} auth={false} />
          <CardLarge datas={sessions} />
          <CardLarge datas={sessions} /> */}
        </div>

        <div className="tw-mb-4">
          <h1>Avatar</h1>
          <hr />
        </div>
        <div className="tw-flex tw-flex-col tw-w-full tw-mb-4">
          <Avatar
            alt={"avatar"}
            size={"10px"}
            src={
              "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=4&w=880&h=880&q=100"
            }
          />
          <Avatar
            alt={"avatar"}
            size={"20px"}
            src={
              "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=4&w=880&h=880&q=100"
            }
          />
          <Avatar
            alt={"avatar"}
            size={"30px"}
            src={
              "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=4&w=880&h=880&q=100"
            }
          />
          <Avatar
            alt={"avatar"}
            size={"40px"}
            src={
              "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=4&w=880&h=880&q=100"
            }
          />
          <Avatar
            alt={"avatar"}
            size={"50px"}
            src={
              "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=4&w=880&h=880&q=100"
            }
          />
        </div>

        <div className="tw-mb-4">
          <h1>Text</h1>
          <hr />
        </div>

        <div className="tw-flex tw-flex-col tw-w-full tw-mb-4">
          <div className="tw-w-full tw-mb-4">
            <TextHeader label={"Login"} textUnderline={"Now"} size={2} />
          </div>

          <div className="tw-w-full tw-mb-4">
            <TextHeader label={"Registration"} textUnderline={"Now"} size={2} />
          </div>

          <div className="tw-w-full tw-mb-4">
            <TextHeader label={"Title header"} size={2} shape={false} />
          </div>
        </div>

        <div className="tw-mb-4">
          <h1>Logos</h1>
          <hr />
        </div>

        <div className="tw-flex tw-flex-col tw-w-full tw-mb-4">
          <div className="tw-w-full tw-mb-4">
            <Logo width={200} />
          </div>

          <div className="tw-w-full tw-p-2 tw-mb-4 tw-bg-primary">
            <Logo src={LOGO_WHITE} width={200} />
          </div>
        </div>

        <div className="tw-mb-4">
          <h1>Alerts</h1>
          <hr />
        </div>

        <div className="tw-flex tw-flex-col tw-w-full tw-mb-4">
          <div className="tw-w-full tw-mb-4">
            <Alert
              type="success"
              message="The account bzile.tankeu@utrains.org has been successfully activated."
            />
          </div>

          <div className="tw-w-full tw-mb-4">
            <Alert
              type="warning"
              message="The account bzile.tankeu@utrains.org has been successfully activated."
            />
          </div>

          <div className="tw-w-full tw-mb-4">
            <Alert
              type="danger"
              message="The account bzile.tankeu@utrains.org has been successfully activated."
            />
          </div>

          <div className="tw-w-full tw-mb-4">
            <Alert
              type="info"
              message="The account bzile.tankeu@utrains.org has been successfully activated."
            />
          </div>
        </div>

        <div className="tw-mb-4">
          <h1>Buttons</h1>
          <hr />
        </div>

        <div className="tw-flex tw-flex-col tw-w-full tw-mb-4">
          <div className="tw-w-full tw-mb-4">
            <ButtonCustom
              classes={
                "tw-bg-accent-blue hover:tw-bg-primary-100 tw-text-primary tw-border tw-border-dashed tw-border-primary"
              }
              label="Download Invoice"
              icon={"pi pi-download"}
            />
          </div>

          <div className="tw-w-full tw-mb-4">
            <ButtonCustom
              classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
              label="Loading..."
              icon={"pi pi-spin pi-spinner"}
              disabled={true}
            />
          </div>

          <div className="tw-w-full tw-mb-4">
            <ButtonCustom
              classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
              label="Login"
            />
          </div>

          <div className="tw-w-full tw-mb-4">
            <ButtonCustom
              classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
              label="Create an Account"
              icon={"pi pi-apple"}
            />
          </div>

          <div className="tw-w-full tw-mb-4">
            <ButtonCustom
              classes={
                "tw-border-[1.5px] tw-border-[#309255] tw-text-[#309255] hover:tw-text-white hover:tw-bg-[#309255]"
              }
              label="Sign up here"
            />
          </div>

          <div className="tw-w-full tw-mb-4">
            <ButtonCustom
              classes={"tw-text-white tw-bg-[#929292]"}
              label="Disabled"
              disabled={true}
            />
          </div>

          <div className="tw-w-full tw-mb-4">
            <ButtonCustom
              classes={
                "tw-border-[1.5px] tw-text-[#1E4E70] tw-bg-[#FFFFFF] hover:tw-bg-[#FBFBFB] "
              }
              label="Enroll Now"
            />
          </div>

          <div className="tw-w-full tw-mb-4">
            <ButtonCustom
              classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
              label="Enroll Now"
            />
          </div>

          <div className="tw-w-full tw-mb-4">
            <ButtonCustom
              classes={"tw-text-white tw-bg-[#309255] hover:tw-bg-[#055021]"}
              label="Enroll Now"
            />
          </div>
        </div>

        <div className="tw-mb-4">
          <h1>Forms Inputs</h1>
          <hr />
        </div>

        <div className="tw-flex tw-flex-col tw-w-full tw-mb-4">
          <div className="tw-w-full tw-mb-4">
            <SelectCustom
              options={["Male", "Female"]}
              label={"Gender"}
              error={"Choisisr un genre"}
            />
          </div>

          <div className="tw-w-full tw-mb-4">
            <SelectCustom options={["Male", "Female"]} label={"Gender"} />
          </div>

          <div className="tw-w-full tw-mb-4">
            <InputCustom
              label={"E-mail"}
              type={"text"}
              icon={"pi pi-envelope"}
              placeHolder={"E-mail"}
            />
          </div>

          <div className="tw-w-full tw-mb-4">
            <InputCustom
              error={"Please enter a correct First Name"}
              type={"text"}
              icon={"pi pi-user"}
              placeHolder={"First Name"}
            />
          </div>

          <div className="tw-w-full tw-mb-4">
            <InputCustom
              type={"password"}
              icon={"pi pi-lock"}
              placeHolder={"Password"}
            />
          </div>

          <div className="tw-w-full tw-mb-4">
            <InputCustom
              type={"text"}
              icon={"pi pi-user"}
              placeHolder={"First name"}
            />
          </div>

          <div className="tw-w-full tw-mb-4">
            <InputCustom
              type={"text"}
              icon={"pi pi-user"}
              placeHolder={"Last name"}
            />
          </div>

          <div className="tw-w-full tw-mb-4">
            <InputCustom type={"text"} />
          </div>

          <div className="tw-w-full tw-mb-4">
            <InputCustom
              type={"text"}
              value={"christian.tchindebe@utrains.org"}
              disabled={true}
              placeHolder={"Last name"}
            />
          </div>

          <div className="tw-w-full tw-mb-4">
            <InputCardNumber type={"text"} placeHolder={"Card Number"} />
          </div>
        </div>
      </div>
      <div className="tw-mb-4">
        <h1>Footer auth</h1>
        <hr />
      </div>

      <Footer auth={true} />

      <div className="tw-mb-4">
        <h1>Footer not auth</h1>
        <hr />
      </div>

      <Footer auth={false} />
    </>
  );
};

export default Components;

import React, {Component, useEffect, useState} from "react";
import Sidebar from "../sidebar/Sidebar";
import AdminFooter from "../footer/AdminFooter";
import {useNavigate} from "react-router-dom";
import AdminHeader from "../header/AdminHeader";
import TransactionService from "../../../network/services/TransactionService";


const SearchUser = () => {

    const [result, setResult] = useState([]);
    const [message, setMessage] = useState('');
    const [notifications, setNotifications] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [totalItems, setTotalItems] = useState(null);
    const [researchIndicator, setResearchIndicator] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleChange = (event) => {
        setResearchIndicator(event.target.value);
        
      //setInputValue(researchIndicator);
      console.log(researchIndicator.length);
      const sold = researchIndicator.length +1;
      console.log("sold", sold);
      if (sold <= 4) {
        setMessage();
        setErrorMessage('Enter at least 5 characters for search');
        
      } else {
        
        setErrorMessage();
      }
     

    }

   const openDetails = (e) => {
    
        const email = e.target.getAttribute('email');
        const encodedEmail = encodeURIComponent(email);
        console.log("the selected user is: " + encodedEmail);
        //alert(navigate);
        navigate({
            pathname: "/transaction",
            search: "?userEmail=" + encodedEmail,
        });
    };
    const filter = () => {

        const research = researchIndicator;
        const stringLength = research.length;
        const data = {
            search_value: research,
        }
        console.log(stringLength);

        setIsLoading(true);
        TransactionService.searchUserTransaction(data, (response) => {
           
            
                if (response) {
                   
                        if(response.success == true)
                        {      
                             if(response.result != "")
                            {    
                                setResult(response.result);                             
                                setTotalItems(response.meta.totalResults);
                                setMessage();
                                console.log(successMessage, totalItems, response.meta.totalResults);
                                if(response.meta.totalResults > 10)
                                {
                                    setSuccessMessage(true);
                                    setNotifications("Some results are not displayed. Please refine your search by entering the student's e-mail address or telephone number.");
                                    console.log(successMessage, totalItems);
                                }
                            
                            }
                            else
                            {
                                setMessage("No result");
                                setResult();
                                setSuccessMessage();
                                setNotifications();
    
                            }                           
                        }
                        else{
                            setResult();
                        }
                    
                
                }
              //  setSuccessMessage();
                //setNotifications();
               // setMessage();
            setIsLoading(false);
            // this.setState({isLoading: false, isSend: false, isClear: false})
        });
    }
   

   
    return (
        <div>
            <>
                <Sidebar/>
                <main className="mainAdmin r ms-100">
                    <AdminHeader location="Transactions / Support"/>

                    <div className="container-fluid py-4">
                        <div className="row">
                            <div className="col-lg-12 col-md-10 mx-auto">
                                <div className="card ">
                                    <div className="card-header p-3">
                                        <h5 className="mb-0">Search students here... </h5>
                                        <br/>
                                        <div className="row">
                                            <div className="row">
                                                <div className="col-5">
                                                <span className="text-center"><h6>{message}{errorMessage}</h6></span> 
                                                </div>
                                                <div className="col-4">

                                                </div>
                                            </div>
                                            
                                            
                                            <div className="col-5">
                                                <div className="form-group has-search input-group-lg">
                                                    
                                                    <form>
                                                        <span className="fa fa-search form-control-feedback"></span>
                                                        <input type="text" className="form-control" placeholder="Enter at least 5 characters for search"
                                                               value={researchIndicator}
                                                               
                                                               name="search"
                                                               onChange={(event) => handleChange(event)}/>
                                                    </form>
                                                    
                                                </div>
                                            </div>
                                            
                                            <div className="col-4">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={filter}
                                                    disabled={!(researchIndicator.length >= 5)}
                                                >
                                                    Search
                                                </button>
                                               
                                            </div>
                                        </div>
                                        <div>
                                            
                                        </div>
                                    </div>
                                    <div className="card-body p-3">
                                        <div className="table-responsive p-0">
                                        <table className="table align-items-center mb-0">
                                                <thead>
                                                <tr>
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Student name</th>                                                  
                                                    <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">...</th>
                                                </tr>
                                                </thead>
                                                <tbody> 
                                                {
                                                    result && result.map((user) => (
                                                        
                                                        
                                                        <tr key={user.id}>
                                                            <td className="align-middle text-center text-sm">
                                                                    <span
                                                                        className="text-xs font-weight-bold"> {user.name} </span>
                                                            </td>
                                                            <td className="align-middle text-center text-sm">
                                                                <button
                                                                        type="button"
                                                                        className="btn btn-success"
                                                                        email={user.email}
                                                                         onClick={openDetails}
                                                                >
                                                                    Details here
                                                                </button>
                                                            </td>   
                                                        </tr>
                                                    ))
                                                }
                                                </tbody>
                                            </table>  
                                        
                                                                                  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col">
                                {isLoading ? (
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>

                                    ) :
                                    null}
                            </div>
                           
                        </div>
                        <AdminFooter/>
                    </div>
                    <div>
                    {successMessage == true ? (
                    <div className="position-fixed bottom-1 end-1 z-index-2">
                                        
                                            <div
                                                className="toast fade show p-2 mt-2 bg-gradient-warning"
                                                role="alert"
                                                aria-live="assertive"
                                                id="infoToast"
                                                aria-atomic="true"
                                            >
                                                <div className="toast-header bg-transparent border-0">
                                                    <i className="material-icons text-white me-2">
                                                        check
                                                    </i>
                                                    <span className="me-auto text-white font-weight-bold">
                            Notification{" "}
                          </span>
                                                    <small className="text-white">just now</small>
                                                    <i
                                                        className="fas fa-times text-md text-white ms-3 cursor-pointer"
                                                        data-bs-dismiss="toast"
                                                        aria-label="Close"
                                                        onClick={(e) =>
                                                            setSuccessMessage("false")
                                                        }
                                                    ></i>
                                                </div>
                                                <hr className="horizontal light m-0"/>
                                                <div className="toast-body text-white">
                                                    {notifications}
                                                </div>
                                            </div>
                                        
                                       
                                    </div>
                                    ) : (
                                        <></>
                                    )}
                                    </div>
                                
                            
                        
                    
                </main>
            </>
        </div>
    )

}
export default SearchUser;
import AppLayout from "../../layouts/AppLayout";
import AppHeader from "../../components/frontv2.0/common/header/AppHeader";
import SubHeaderOffLine from "../../components/frontv2.0/common/header/SubHeaderOffLine";
import { useNavigate } from "react-router-dom";
import { _404_ILLUSTRATION } from "../../constants/images";
import ButtonCustom from "../../components/frontv2.0/common/button/ButtonCustom";

const Page404 = () => {
  /**
   * States
   */
  const navigate = useNavigate();

  // eslint-disable-next-line
  const handleOnClickItem = () => {
    return navigate("/");
  };

  /**
   * Render
   */
  return (
    <AppLayout>
      <AppLayout.Content>
        <div className="tw-flex tw-flex-col-reverse md:tw-flex-row tw-justify-between tw-items-center tw-h-full tw-py-4 md:tw-py-0">
          <div className="tw-flex tw-items-center tw-flex-col tw-space-y-8 tw-text-primary md:tw-items-start md:items-start">
            <p className="md:tw-w-2/3 tw-text-lg tw-font-medium tw-text-center md:tw-text-left">
              {" "}
              The page you are looking for does not exists or it may have been
              moved.{" "}
            </p>
            <ButtonCustom
              label="Back to the Home Page"
              onClick={handleOnClickItem}
              classes="tw-bg-primary hover:tw-bg-primary-900 tw-text-white tw-max-w-[237px] tw-max-h-[44px]"
            />
          </div>
          <div className="">
            <img
              className="tw-w-[120px] md:tw-w-[420px]"
              src={_404_ILLUSTRATION}
              alt=""
            />
          </div>
        </div>
      </AppLayout.Content>

      <AppLayout.Header>
        <AppHeader />
      </AppLayout.Header>

      <AppLayout.SubHeader>
        <SubHeaderOffLine
          data={[
            { value: "Home", url: "/" },
            { value: "Page Not Found", url: "#" },
          ]}
        />
      </AppLayout.SubHeader>
    </AppLayout>
  );
};

export default Page404;

import React from 'react';

const RoundedIcon = ({icon, css, iconSize}) => {
    return (
        <>
            <div className={
                `tw-flex tw-items-center tw-justify-center  tw-rounded-full 
            ${css ? css : 'tw-w-6 tw-h-6 tw-bg-secondary-green tw-text-white'} }`
            }>
                <i
                    className={`pi ${icon ? icon : 'pi-check'}`}
                    style={{fontSize: iconSize ? iconSize : '0.8rem'}}
                ></i>
            </div>
        </>
    );
};

export default RoundedIcon;
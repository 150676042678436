import {useEffect} from "react";
import {useLocation} from "react-router-dom";

const ScrollToTop = (props) => {

    /**
     * States
     */
    const location = useLocation();
    // console.log('location: ', location);

    /**
     * Comportments
     */
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    /**
     * Render
     */
    return <>{props.children}</>
};

export default ScrollToTop;
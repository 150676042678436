import {sGET, sPOST} from "../protocol/HTTP";
import backendUrls from "../urls/backendUrls";

const TransactionService ={
    getAll: function (data, page, cb){

        if(page === null){
            const url=backendUrls.Transaction.logTransaction;
            sPOST(url, data, cb);
        }else {
            const url=backendUrls.Transaction.logTransaction+"?page="+page;
            sPOST(url, data, cb);
        }

    },

    searchTransaction:function (data, cb){
            const url=backendUrls.searchTransaction.GET_ALL;
            console.log(url);
            sPOST(url, data, cb);
    },
    searchUserTransaction:function (data, cb){
        const url=backendUrls.searchUserTransaction.GET_USER_TRANSACTION;
        console.log(url);
        sPOST(url, data, cb);
},

    getLightSessionForFilter : function(filter, cb){
       /* if(filter === null){
            const url=backendUrls.Transaction.getSessionFilter;
            sGET(url,cb);
        }else {
            const url=backendUrls.Transaction.getSessionFilter;
            sPOST(url,{search:filter},cb);
        }*/

        const url=backendUrls.Transaction.getSessionFilter;
        sGET(url,cb);

    },

    filterTransactions: function (data, cb) {
        const url = backendUrls.USER.filterBySubscriptionPlan;
        console.log(url);
        sPOST(url, data, cb);
    },
}

export default TransactionService;
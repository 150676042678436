import React, { useEffect, useState } from "react";
import MainLayout from "../../../../../layouts/MainLayout";
import Header from "../../../../../components/frontv2.0/common/header/Header";
import SubHeader from "../../../../../components/frontv2.0/common/header/SubHeader";
import CourseService from "../../../../../network/services/CourseService";
import { IMAGE_2, STUDENT_ONBOARD } from "../../../../../constants/images";
import { Link } from "react-router-dom";
import ButtonCustom from "../../../../../components/frontv2.0/common/button/ButtonCustom";
import Loader from "../../../../../components/frontv2.0/common/loader/Loader";
import { MEDIA_URL_ACCOUNTING } from "../../../../../network/urls/backendUrls";

const MyCoursesDetails = () => {
  const courseUrl = process.env.REACT_APP_WORDPRESS;
  /**
   * States
   */
  const queryParams = new URLSearchParams(window.location.search);
    const wordpressUrl = process.env.REACT_APP_WORDPRESS;
  const courseId = queryParams.get("courseId");
  const [isLoading, setIsLoading] = useState(false);
  const [courseData, setCourseData] = useState(null);
  const [courseDataError, setCourseDataError] = useState(null);

  /**
   * Comportment
   */
  const getSessionSubscribedDetails = () => {
    setIsLoading(true);
    CourseService.getOneUnsecureById(courseId, (data) => {
      if (data && data.status) {
        setCourseData(data.data);
      } else {
        setCourseDataError({ message: "Erreur Inconnu" });
      }
      setIsLoading(false);
    });
  };

  const encodeUrl = (url) => {
    return url.replace(/ /g, "%20");
  };

  useEffect(() => {
    getSessionSubscribedDetails();
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <MainLayout>
      <MainLayout.Content>
        <div className="tw-w-full tw-h-full ">
          {isLoading ? (
            <div className="tw-flex tw-flex-col md:tw-justify-center tw-justify-center tw-items-center tw-py-48">
              <Loader />
            </div>
          ) : courseData ? (
            <div className={'tw-h-full tw-space-y-4'}>
              <div className="tw-flex-col tw-h-auto tw-w-full md:tw-flex md:tw-flex-row  tw-rounded-[12px]">
                <div
                  className="tw-hidden sm:tw-block  tw-bg-cover tw-bg-center tw-rounded-[12px]  md:tw-w-[325px] tw-w-full"
                  style={{
                    backgroundImage: `url(${
                      courseData.image === null
                        ? IMAGE_2
                        : encodeUrl(
                            `${MEDIA_URL_ACCOUNTING}/courses/${courseData.image}`
                          )
                    })`,
                  }}
                ></div>
                <div className="tw-flex tw-flex-col tw-w-full md:tw-ml-3 tw-p-3 tw-bg-primary-200 tw-rounded-[12px] md:tw-rounded-[12px]">
                  <div className="tw-w-full tw-h-full md:tw-flex md:tw-items-center md:tw-justify-between">
                    <div>
                      <div className="tw-flex tw-items-center tw-justify-between tw-w-full tw-text-primary">
                        <div className="tw-flex tw-flex-col sm:tw-text-lg tw-text-sm">
                          <span className="tw-font-bold tw-text-lg lg:tw-text-2xl md:tw-text-xl">
                            {courseData.name}
                          </span>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                courseData.description === ""
                                  ? ""
                                  : courseData.description,
                            }}
                          />
                        </div>
                      </div>
                      <div className="tw-flex tw-flex-col tw-w-full md:tw-items-center md:tw-flex-row md:tw-justify-between">
                        <div className="tw-flex tw-flex-col tw-pt-4 tw-text-primary tw-text-xs sm:tw-text-md tw-space-y-2">
                          {courseData.access_type === "CANCELLED" ? (
                            <p className="tw-font-bold">
                              This session is no more available.
                            </p>
                          ) : (
                            <div className="tw-flex tw-flex-col tw-space-y-2">
                              <h2 className="tw-font-bold">
                                {" "}
                                Follow these <span> Instructions:</span>
                              </h2>
                              <ul className="tw-flex tw-flex-col tw-space-y-2">
                                <li className="tw-flex tw-space-x-2">
                                  <img
                                    className="tw-w-8"
                                    src="/assets/images/shape/shape-17.png"
                                    alt="Shape"
                                  />
                                  <span>Click on the course below</span>
                                </li>

                                <li className="tw-flex tw-space-x-2">
                                  <img
                                    className="tw-w-8"
                                    src="/assets/images/shape/shape-17.png"
                                    alt="Shape"
                                  />
                                  <span>
                                    {" "}
                                    Then get access to the course room.{" "}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tw-flex tw-py-6 tw-w-full tw-flex-col tw-items-center tw-rounded-[12px] sm:tw-bg-accent-gray tw-space-y-4">
                <img
                  className="tw-object-cover tw-max-w-[200px]"
                  src={STUDENT_ONBOARD}
                  alt=""
                />
                <Link to={courseUrl? courseUrl :wordpressUrl} target="_blank" rel="noreferrer">
                  <ButtonCustom
                    classes={
                      "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                    }
                    label="Course's Room"
                  />
                </Link>
              </div>
            </div >
          ) : (
            <div className="sm:tw-flex md:tw-mt-16 tw-justify-center tw-w-full tw-h-full tw-text-primary">
              {courseDataError?.message ?? "Course not Found"}
            </div>
          )}
        </div>
      </MainLayout.Content>

      <MainLayout.Header>
        <Header />
      </MainLayout.Header>

      <MainLayout.SubHeader>
        <SubHeader
          data={[
            { value: "Home", url: "/workspace" },
            {
              value: "My Courses",
              url: "/workspace/mycourses",
            },
            {
              value: courseData?.name,
              url: "#",
            },
          ]}
        />
      </MainLayout.SubHeader>
    </MainLayout>
  );
};

export default MyCoursesDetails;

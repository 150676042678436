import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "./Avatar";
import { MEDIA_URL } from "../../../../network/urls/backendUrls";
import { USER_AVATAR_FEMALE, USER_AVATAR_MALE } from "../../../../constants/images";
import { logout } from "../../../../network/auth/Auth";
import {logoutRedirectUrl} from "../../../../constants/Constants";

export default function UserProfileDropdownPrime({ user }) {
  const navigate = useNavigate();
  const [selectedCity, setSelectedCity] = useState(null);
  const groupedCities = [
    {
      label: "",
      code: "",
      items: [],
    },
  ];

  const handleLogOut = (e) => {
    // e.preventDefault();
    // logout();
    // navigate("/");
    e.preventDefault();
    logoutRedirectUrl();
  };

  const groupedItemTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div
          className="tw-transition-all tw-text-primary tw-duration-300 tw-right-0 tw-w-full tw-divide-y tw-divide-gray-100 tw-outline-none"
          aria-labelledby="headlessui-menu-button-1"
          id="headlessui-menu-items-117"
          role="menu"
        >
          <div className="tw-px-4 tw-py-3">
            <p className="tw-text-sm tw-leading-5">Signed in as</p>
            <p className="tw-text-xs tw-font-semibold tw-leading-5 tw-truncate">
              {user?.email}
            </p>
          </div>
          <div className="">
            <Link
              to="/workspace/profile"
              tabIndex={0}
              className="tw-flex tw-justify-between tw-w-full tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-text-left hover:tw-bg-accent-gray tw-text-primary"
              role="menuitem"
            >
              Profile
            </Link>
          </div>
          <div className="">
            <Link
                onClick={handleLogOut}
              tabIndex={3}
              className="tw-flex tw-items-center tw-w-full tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-text-left tw-text-red-400 tw-transition-colors tw-duration-300 tw-transform tw-rounded-b-md hover:bg-red-200 hover:tw-text-red-700"
              role="menuitem"
            >
              <i className="tw-w-5 tw-h-5 tw-mr-2 pi pi-sign-out"></i>
              Logout
            </Link>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="tw-flex tw-justify-center">
      <Dropdown
        value={selectedCity}
        onChange={(e) => setSelectedCity(e.value)}
        options={groupedCities}
        optionLabel="label"
        optionGroupLabel="label"
        optionGroupChildren="items"
        optionGroupTemplate={groupedItemTemplate}
        className="tw-w-full md:tw-w-[12rem] tw-border-none tw-outline-none tw-p-1 hover:tw-bg-transparent focus:tw-outline-none"
        placeholder={user?.firstName || user?.name ? user?.firstName + " " + user?.name : ""}
        dropdownIcon={
          <Avatar
            alt={"avatar"}
            size={"40px"}
            src={
              user?.profilePicture
                ? MEDIA_URL + user.profilePicture
                : user?.gender && user.gender === "F"
                ? USER_AVATAR_FEMALE
                : USER_AVATAR_MALE
            }
          />
        }
      />
    </div>
  );
}

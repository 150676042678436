import { useEffect } from "react";

const wordpress_home = process.env.REACT_APP_WORDPRESS;
const moodle_home = process.env.REACT_APP_MOODLE;
const course_home = process.env.REACT_APP_COURSE_HOME;

const Home = () => {
  const getCourseHome = () => {
    return course_home === "WP" ? wordpress_home : moodle_home;
  };
  useEffect(() => {
    window.location = getCourseHome();
  }, []);
  /**
   * Render
   */
  return <></>;
};

export default Home;

import React from "react";

const InputCardNumber = ({
  name,
  id,
  type,
  value,
  disabled = false,
  placeHolder,
  classes,
  icon,
  error,
  handleOnChange,
}) => {
  const getCardType = (number) => {
    const re = {
      electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
      maestro:
        /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
      dankort: /^(5019)\d+$/,
      interpayment: /^(636)\d+$/,
      unionpay: /^(62|88)\d+$/,
      visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      mastercard:
        /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
      amex: /^3[47][0-9]{13}$/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
    };

    for (var key in re) {
      if (re[key].test(number)) {
        return key;
      }
    }

    return "unknown";
  };

  const handleCHange = (e) => {
    let value = e.target.value;

    value && console.log(getCardType(value));
  };
  return (
    <>
      <div
        className={`tw-relative tw-mt-2 tw-w-full ${
          !disabled && "tw-text-gray-300"
        } tw-rounded-[1.5px] tw-shadow-sm`}
      >
        <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3 tw-pointed-events-none">
          <i
            className={`pi pi-credit-card tw-ml-1`}
            style={{ fontSize: "1.5rem" }}
          ></i>
        </div>
        <input
          name="card_number"
          onChange={handleCHange}
          value={value}
          id="card_number"
          className={`tw-block tw-w-full tw-h-[50px] tw-rounded-lg tw-border-0 tw-py-1.5  tw-pl-14 tw-pr-4 ${
            disabled ? "tw-text-gray-400 tw-bg-gray-200" : "tw-text-primary"
          } tw-ring-2 tw-ring-inset ${
            error
              ? "tw-ring-red-300 focus:tw-ring-red-300"
              : "tw-ring-gray-200 focus:tw-ring-primary-600"
          } placeholder:tw-text-gray-300 tw-text-xs sm:tw-text-sm sm:tw-leading-6 tw-outline-none `}
          placeholder={placeHolder}
          disabled={disabled}
          style={{ cursor: `${disabled ? "not-allowed" : "text"}` }}
          type="tel"
          pattern="\d*"
          maxlength="19"
        />
        <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3 ">
          <input
            name="expire_at"
            id="expire_at"
            className={`${
              disabled ? "tw-text-gray-400 tw-bg-gray-200" : "tw-text-primary"
            } tw-block sm:tw-w-[100px] tw-w-[50px] tw-h-[44px] ${
              error
                ? "tw-ring-red-300 focus:tw-ring-red-300"
                : "tw-ring-gray-200 focus:tw-ring-primary-600"
            } placeholder:tw-text-gray-300 tw-text-xs sm:tw-text-sm sm:tw-leading-6 tw-outline-none tw-border-0  tw-pl-2 `}
            placeholder="MM/AA"
            type="text"
          />
          <input
            name="cvc_number"
            id="cvc_number"
            type="number"
            className={`${
              disabled ? "tw-text-gray-400 tw-bg-gray-200" : "tw-text-primary"
            } tw-block sm:tw-w-[80px] tw-w-[50px] tw-h-[44px] ${
              error
                ? "tw-ring-red-300 focus:tw-ring-red-300"
                : "tw-ring-gray-200 focus:tw-ring-primary-600"
            } placeholder:tw-text-gray-300 tw-appearance-none tw-text-xs sm:tw-text-sm sm:tw-leading-6 tw-outline-none tw-border-0 tw-pl-2 `}
            placeholder="CVC"
          />
        </div>
      </div>
      {error && (
        <span className="tw-ml-1 tw-text-sm tw-text-red-300">{error}</span>
      )}
    </>
  );
};

export default InputCardNumber;

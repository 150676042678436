import React, {useEffect, useRef, useState} from "react";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import ButtonCustom from "../button/ButtonCustom";
import StripeService from "../../../../network/services/StripeService";
import {Dialog} from "primereact/dialog";
import InputCustom from "../form/InputCustom";
import {Toast} from "primereact/toast";
import Loader from "../loader/Loader";

const UserEditCardPayment = () => {
    const [state, setState] = useState({
        password: "",
        lastDigit: "",
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isFirstLoading, setIsFirstLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const toast = useRef(null);
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const stripe = useStripe();
    const elements = useElements();

    const show = () => {
        setVisible(true);
    };

    const showSuccess = (message) => {
        toast.current.show({
            severity: "success",
            summary: "Success",
            detail: message,
            life: 3000,
        });
    };

    const showError = (message) => {
        toast.current.show({
            severity: "error",
            summary: "Error",
            detail: message,
            life: 3000,
        });
    };

    const handleUpdatePaymentCredentials = async () => {
        setIsLoading(true);

        const responseMethod =
            await StripeService.requestPaymentMethodSecretKey({
                user_password: state.password,
            });

        if (responseMethod) {

            if (!responseMethod.success) {
                setIsLoading(false);
                setVisible(false);
                showError(responseMethod.message);
                elements.getElement(CardElement).clear();
                setState({...state, password: ""});
                return;
            }

            const client_secret = responseMethod.client_secret;
            const setup_intent_id = responseMethod.setup_intent_id;

            if (client_secret === undefined || setup_intent_id === undefined) {
                setIsLoading(false);
                setVisible(false);
                showError("Verify your payment information");
                return;
            }

            if (!stripe || !elements) {
                setIsLoading(false);
                setVisible(false);
                showError("Verify your payment information");
                return;
            }

            const {error} = await stripe.confirmCardSetup(client_secret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                },
            });
            if (error) {
                setIsLoading(false);
                setVisible(false);
                showError("Verify your payment information");
                return;
            } else {
                let resultStatus = await StripeService.updatePaymentMethod({
                    setup_intent_id: setup_intent_id,
                    user_password: state.password,
                });
                if (resultStatus.success) {
                    setIsLoading(false);
                    setVisible(false);
                    showSuccess("Your payment credential has been successfully updated !");
                } else {
                    setIsLoading(false);
                    setVisible(false);
                    showError(resultStatus.message);
                }
                elements.getElement(CardElement).clear();
                showLastDigit();
            }
        }
    };

    const showLastDigit = async () => {
        setIsFirstLoading(true);
        const response = await StripeService.getDigit(null);
        if (response) {
            if (response.last4 !== null) {
                setState({...state, lastDigit: response.last4});
            }
        }
        setIsFirstLoading(false);
    }

    useEffect(() => {
        showLastDigit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const footerContent = (
        <div className="tw-flex tw-flex-row tw-space-x-4 ">
            <ButtonCustom
                label="Close"
                icon={"pi pi-times-circle"}
                onClick={() => setVisible(false)}
                autoFocus
                classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
            />
            {isLoading ? (
                <ButtonCustom
                    classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
                    label="Loading..."
                    icon={"pi pi-spin pi-spinner"}
                    disabled={true}
                />
            ) : (
                <ButtonCustom
                    label="Save Changes"
                    icon={"pi pi-save"}
                    onClick={handleUpdatePaymentCredentials}
                    classes={"tw-text-white tw-bg-[#309255] hover:tw-bg-[#055021]"}
                    disabled={!(state.password.length > 0)}
                />
            )}
        </div>
    );

    return (
        <>
            <Toast ref={toast} position="top-right"/>
            {isFirstLoading ? (
                <div className="tw-flex tw-flex-col md:tw-justify-center tw-justify-center tw-items-center tw-py-44">
                    <Loader/>
                </div>
            ) : (
                <>
                    <div className="tw-py-4">
                        <p>
                            Your credit card preview is{" "}
                            <span>
                **** **** ****{" "}
                                {(state.lastDigit !== null && state.lastDigit !== "") ? state.lastDigit : "****"}
              </span>
                        </p>
                    </div>
                    <hr className="tw-h-1 tw-mb-4 tw-bg-gray-200"/>
                    <div className="">
                        <div
                            className="tw-block tw-justify-center tw-w-full tw-h-[50px] tw-rounded-lg tw-border-0 tw-py-3 tw-text-primary tw-ring-2 tw-ring-gray-300 tw-bg-white focus:tw-ring-primary-600 tw-ring-inset tw-px-4 tw-text-xs sm:tw-text-sm sm:tw-leading-6 tw-outline-none">
                            <CardElement
                                options={{
                                    style: {
                                        base: {
                                            iconColor: "#1e4e70",
                                            fontWeight: "400",
                                            fontFamily: "Poppins, Open Sans, Segoe UI, sans-serif",
                                            fontSize: "20px",
                                            fontSmoothing: "antialiased",
                                            ":-webkit-autofill": {
                                                color: "#1e4e70",
                                            },
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                    <div className="tw-sticky tw-bottom-0 tw-z-10">
                        <div className="tw-py-4">
                            <div className="tw-w-full tw-py-3">
                                <ButtonCustom
                                    classes={
                                        "tw-bg-primary hover:tw-bg-primary-900 tw-text-white"
                                    }
                                    label="Update"
                                    icon={"pi pi-sync"}
                                    onClick={show}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <Dialog
                            header="Confirmation"
                            visible={visible}
                            onHide={() => setVisible(false)}
                            footer={footerContent}
                            style={{width: "28vw"}}
                            breakpoints={{"960px": "75vw", "641px": "100vw"}}
                        >
                            <p className="tw-my-3">
                                You want to update your payment information, enter your password to confirm.
                            </p>
                            <div>
                                <InputCustom
                                    id={"password"}
                                    name={"password"}
                                    placeHolder={"Password"}
                                    type={"password"}
                                    onChange={(e) => {
                                        setState({...state, password: e.target.value});
                                    }}
                                    showPassword={showPassword}
                                    handleShowPassword={handleShowPassword}
                                />
                            </div>
                        </Dialog>
                    </div>
                </>
            )}
        </>
    );
};

export default UserEditCardPayment;

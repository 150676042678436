import {GET, POST, sDELETE, sGET, spGET, sPOST, sPOST_FILE} from "../protocol/HTTP";
import backendUrls from "../urls/backendUrls";
// import moment from "moment/moment";

const SessionService = {

    getAllByPage: function (page_id, nbreItems, cb) {
        //  const url=backendUrls.SessionPlan.currency;
        // eslint-disable-next-line
        const url = backendUrls.SESSION.GET_ALL + `?page=${page_id}&itemsPerPage=${nbreItems}`;
        spGET(url, cb);

    },
    pagination: function (page_id, nbreItems, cb) {
        //  const url=backendUrls.SessionPlan.currency;
        // eslint-disable-next-line
        const url = backendUrls.SESSION.PAGINATE + `?page=${page_id}&itemsPerPage=${nbreItems}`;
        spGET(url, cb);

    },
    getAll: function (cb) {
        const url = backendUrls.SESSION.GET_ALL;
        sGET(url, cb);
    },

    getById: function (id, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SESSION.GET_ONE + `/${id}`;
        sGET(url, cb);
    },

    getSessionById: function (id, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SESSION.GET_SESSION_USER_SPACE + `/${id}`;
        sGET(url, cb);
    },

    unsecureGetById: function (id, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SESSION.GET_ONE_UNSECURE + `/${id}`;
        GET(url, cb);
    },

    getNextSession: function (cb) {
        const url = backendUrls.SESSION.GET_NEXT_ONE;
        GET(url, cb);
        // console.log("url: " + url);
    },

    create: function (data, cb) {
        const url = backendUrls.SESSION.CREATE;
        sPOST_FILE(url, data, cb);
    },

      

    delete: function (id, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SESSION.DELETE + `/${id}`;
        sDELETE(url, cb);
    },
    DeleteDelays: function (id, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SESSION.DELETE_DELAY + `/${id}`;
        sGET(url, cb);
    },

    DeleteCoupon: function (id, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SESSION.DELETE_COUPON + `/${id}`;
        sGET(url, cb);
    },
    
   



    DeleteDiscount: function (id, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SESSION.DELETE_DISCOUNT + `/${id}`;
        sGET(url, cb);
    },

    update: function (id, data, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SESSION.UPDATE + `/${id}`;
        sPOST_FILE(url, data, cb);
    },

    delPlan: function (data, cb) {
        const url = backendUrls.SESSION.DELPLAN;
        //console.log(url, data);
        sPOST(url, data, cb);
    },

    atriplan: function (data, cb) {
        const url = backendUrls.SESSION.ATRIPLAN;
        sPOST(url, data, cb);
    },

    getUserSessions: function (id, cb) {
        const url=backendUrls.SESSION.GET_USER_SUBSCRIPTION;
        //const url = backendUrls.USER.GET_ALL_SESSION;
        sGET(url, cb);
    },

    getAllSessionOpen: function (id, cb) {
        const url = backendUrls.SESSION.GET_ALL_OPEN_SESSION;
        sGET(url, cb);
    },
    getAllUserOfSessions: function (id, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SESSION.GET_ALL_USER_OF_SESSION + `/${id}`;
        sGET(url, cb);
    },

    getAllSessionsOpened: function (cb) {
        const url = backendUrls.SESSION.GET_ALL_OPENED_SESSION;
        sGET(url, cb);
    },

    getAllSessionsDatePromotion: function (cb) {
        const url = backendUrls.SESSION.GET_ALL_SESSION_DATE_PROMOTION;
        sGET(url, cb);
    },
    

    getAllUnsecuredSessionsOpened: function (page, limit, cb) {
        const url = backendUrls.SESSION.GET_ALL_UNSECURED_OPENED_SESSION + '?page=' + `${page}` + '&limit=' + limit;
        GET(url, cb);
    },

    getAllUserOfSessionsHavingPaid: function (id, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SESSION.GET_ALL_USER_OF_SESSION_HAVING_PAID + `/${id}`;
        sGET(url, cb);
    },
    getAllSubscriptionIntent: function (data, cb) {
        const url = backendUrls.SESSION.GET_ALL_SUBSCRIPTION_INTENT;
        sPOST(url, data, cb);
    },
    searchSubscriptionIntent: function (data, cb) {
        const url = backendUrls.SESSION.SEARCH_SUBSCRIPTION_INTENT;
        sPOST(url, data, cb);
    },
    getAllUserOfSessionsNotHavingPaid: function (id, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SESSION.GET_ALL_USER_OF_SESSION_NOT_HAVING_PAID + `/${id}`;
        sGET(url, cb);
    },
    getStatutPayment: function (id1, id2, cb) {
        // eslint-disable-next-line
        const url = backendUrls.PaymentHistory.GET_HISTORY_PAYMENT + `/${id1}/${id2}`;
        sGET(url, cb);
    },


    getCurrentUserSessions: function (id, cb) {
        // const url = backendUrls.SESSION.GET_CURRENT_USER_SESSION_SECURE;
        const url = backendUrls.SESSION.GET_CURRENT_USER_SESSION_SECURE_V2;
        sGET(url, cb);
    },
    getPrevUserSessions: function (id, cb) {
        const url = backendUrls.SESSION.GET_PREV_USER_SESSION_SECURE;
        sGET(url, cb);
    },

    getDatePaymentSecureAdmin: function (id1, id2, cb) {
        // eslint-disable-next-line
        const url = backendUrls.PaymentHistory.GET_DATE_PAYMENT_SECURE_ADMIN + `/${id1}/${id2}`;

        sGET(url, cb);
    },
    getHistoryFailSecureAdmin: function (id1, id2, cb) {
        // eslint-disable-next-line
        const url = backendUrls.PaymentHistory.GET_PAYMENT_HISTORY_FAIL_SECURE_ADMIN + `/${id1}/${id2}`;
        sGET(url, cb);
    },

    getDatePaymentSecure: function (id1, cb) {
        // eslint-disable-next-line
        const url = backendUrls.PaymentHistory.GET_DATE_PAYMENT_SECURE + `/${id1}`;
        sGET(url, cb);
    },
    getCurrentSessionByUserSecure: function (id1, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SESSION.GET_CURRENT_SESSION_BY_USER + `/${id1}`;
        sGET(url, cb);
    },
    getCurrentSession: function (cb) {
        const url = backendUrls.SESSION.GET_CURRENT_SESSION;

        sGET(url, cb);
    },
    getSessionFilter: function (data, cb) {
        const url = backendUrls.SESSION.GET_SESSION_FILTER;

        sPOST(url, data, cb);
    },

    getCouponFilter: function (data, cb) {
        const url = backendUrls.SESSION.GET_COUPON_FILTER;

        sPOST(url, data, cb);
    },


    getSessionWithoutPagination: function (cb) {
        const url = backendUrls.SESSION.GET_SESSION_WITHOUT_PAGINATION;

        sGET(url, cb);
    },

    getAllCoupons: function (page,limit,cb) {
        const url = backendUrls.SESSION.GET_ALL_COUPONS +'?page='+`${page}`+'&limit='+limit;
        sGET(url, cb);
    },

    // getDiscountCode: function (datas, cb) {
    //     const url = `${backendUrls.SESSION.GET_COUPON_CODE}/${datas.code}/${datas.subscriptionPlanId}`;
    //     sGET(url, cb);
    //   },


    getSessionAndSubscriptionPlan: function (data, cb) {
        const url = backendUrls.SESSION.SESSION_AND_SUBSCRIPTION_PLAN_CHECKOUT;

        sPOST(url, data, cb);
    },

    CreateHistoryManually: function (data, cb) {
        const url = backendUrls.SESSION.CREATE_HISTORY_MANUAL;
        sPOST(url, data, cb);
    },

    CreateSettingsDelay: function (data, cb) {
        const url = backendUrls.SESSION.CREATE_SETTINGS_DELAYS;
        sPOST(url, data, cb);
    },

    EditCoupon: function (id, data, cb) {
        const url = backendUrls.SESSION.EDIT_COUPON + `/${id}`;
        sPOST(url, data, cb);
    },

    CreateCoupon: function (data, cb) {
        const url = backendUrls.SESSION.CREATE_COUPON;
        sPOST(url, data, cb);
    },

   

    CreateSettingsDiscount: function (data, cb) {
        const url = backendUrls.SESSION.CREATE_SETTINGS_DISCOUNT;
        sPOST(url, data, cb);
    },
    
    getUserSessionsWithId: function (data, cb) {
        const url = backendUrls.SESSION.GET_USER_SUBSCRIPTION_WITH_ID;
        sPOST(url, data, cb);
    },

    modifyManualAccess: function (data, cb) {
        const url = backendUrls.SESSION.MODIFY_MANUAL_ACCESS;

        sPOST(url, data, cb);
    },

    modifyPaymentDate: function (data, cb) {
        const url = backendUrls.SESSION.CUSTOM_PAYMENT_DATE;

        sPOST(url, data, cb);
    },

    modifySessionPaymentDate: function (data, cb) {
        const url = backendUrls.SESSION.CUSTOM_SESSION_PAYMENT_DATE;

        sPOST(url, data, cb);
    },

    updateCurrentSession: function (data, cb) {
        const url = backendUrls.SESSION.UPDATE_CURRENT_SESSION;

        sPOST(url, data, cb);
    },

    getOneSessionById: function (data, cb) {
        const url = backendUrls.SESSION.GET_ONE_OPENED_SESSION;
        sPOST(url, data, cb);
    },

    getOneUnsecuredSessionById: function (data, cb) {
        const url = backendUrls.SESSION.GET_ONE_UNSECURED_OPENED_SESSION;
        POST(url, data, cb);
    },

    getSettings: function (data, cb) {
        const url = backendUrls.SESSION.GET_SETTINGS_COUPON;
        sPOST(url, data, cb);
    },

    getPotsponed: function (data, cb) {
        const url = backendUrls.SESSION.POSTPONED_PAYMENT_DATE;
        sPOST(url, data, cb);
        console.log("Ici"+url)
    },
    getUserInformation: function ( cb) {
        //const url=backendUrls.SESSION.GET_USER_SUBSCRIPTION;
        const url = backendUrls.SESSION.GET_USER_INFORMATIONS ;
        sGET(url, cb);
    },

    toggleDatabaseValue: function ( cb) {
        //const url=backendUrls.SESSION.GET_USER_SUBSCRIPTION;
        const url = backendUrls.SESSION.GET_VALUE_TOGGLE ;
        sGET(url, cb);
    },
    

    // CheckEligibilityCoupon: function ( cb) {
    //     //const url=backendUrls.SESSION.GET_USER_SUBSCRIPTION;
    //     const url = backendUrls.SESSION.GET_ELIGIBILITY_COUPON ;
    //     sGET(url, cb);
    // },

    getUserHistoryReport: function ( cb) {
        //const url=backendUrls.SESSION.GET_USER_SUBSCRIPTION;
        const url = backendUrls.SESSION.GET_USER_HISTORY_REPORT ;
        sGET(url, cb);
    },
    modifySubscriptionPlan: function (data, cb) {
        // eslint-disable-next-line
        const url = backendUrls.SESSION.MODIFY_USER_SUBSCRIPTION_PLAN;
        sPOST(url, data, cb);
    },


}

export default SessionService;


import React from "react";

const SessionLoader = () => {
  return (
    <div>
      <div class="tw-flex tw-flex-col sm:tw-flex-row tw-w-full tw-mx-auto tw-overflow-hidden tw-bg-white tw-rounded-lg tw-border tw-animate-pulse tw-shadow-sm hover:tw-bg-accent-gray">
        <div className="sm:tw-w-1/4 tw-p-3 tw-h-[220px] sm:tw-h-auto tw-w-full">
          <div class="tw-rounded-[12px] tw-w-full tw-h-full tw-bg-gray-300"></div>
        </div>

        <div class="sm:tw-w-3/4 tw-w-full tw-p-4 tw-md:p-4">
          <span class="tw-w-40 tw-h-2 tw-bg-gray-200 tw-rounded-lg "></span>

          <p class="tw-w-48 tw-h-2 tw-mt-4 tw-bg-gray-200 tw-rounded-lg"></p>

          <div class="tw-flex tw-mt-4 tw-item-center tw-gap-x-2">
            <p class="tw-w-5 tw-h-2 tw-bg-gray-200 tw-rounded-lg"></p>
            <p class="tw-w-5 tw-h-2 tw-bg-gray-200 tw-rounded-lg"></p>
            <p class="tw-w-5 tw-h-2 tw-bg-gray-200 tw-rounded-lg"></p>
            <p class="tw-w-5 tw-h-2 tw-bg-gray-200 tw-rounded-lg"></p>
            <p class="tw-w-5 tw-h-2 tw-bg-gray-200 tw-rounded-lg"></p>
          </div>

          <div class="tw-flex tw-justify-between tw-mt-6 tw-item-center">
            <span class="tw-w-10 tw-h-2 tw-bg-gray-200 tw-rounded-lg "></span>

            <div class="tw-h-4 tw-bg-gray-200 tw-rounded-lg tw-w-28"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionLoader;


import React, {useRef, useState} from 'react';
import {Toast} from 'primereact/toast';
import {FileUpload} from 'primereact/fileupload';
import {ProgressBar} from 'primereact/progressbar';
import {Button} from 'primereact/button';
import {Tooltip} from 'primereact/tooltip';
import {Tag} from 'primereact/tag';
import Sidebar from "../sidebar/Sidebar";
import AdminHeader from "../header/AdminHeader";
import UserService from "../../../network/services/UserService";
import {refreshAndGetUser} from "../../../network/auth/Auth";
import backendUrls, {accountingBaseUrlSecure} from "../../../network/urls/backendUrls";
import SubscriptionService from "../../../network/services/SubscriptionService";
import Loader from "../../../components/frontv2.0/common/loader/Loader";
import { useNavigate } from "react-router-dom";



export default function AccountSynchronizationPanel() {
    const toast = useRef(null);
    const [totalSize, setTotalSize] = useState(0);
    const [resetKey, setResetKey] = useState(0);
    const fileUploadRef = useRef(null);
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isFileUploadReset, setIsFileUploadReset] = useState(false);

    const navigate = useNavigate();

    const upload = accountingBaseUrlSecure +'accounting/import-excel';
    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        setFile(e.files[0]);

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

   
    const onTemplateUpload = (e) => {
  let _totalSize = 0;

  e.files.forEach((file) => {
    _totalSize += file.size || 0;
  });

  setTotalSize(_totalSize);
  toast.current.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});

  setIsFileUploadReset(false);
};
      
   

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
        onTemplateClear();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
        setFile(null);
        setIsFileUploadReset(true);
        setResetKey(resetKey + 1);
      };
      
    const customBase64Uploader = async (event) => {
        // convert file to base64 encoded
        const file = event.files[0];
        // setFile(file);
    };

    const customUploader = () => {
        setIsLoading(true);
    
        const formData = new FormData();
        formData.append("excel_file", file);
        try {
            SubscriptionService.accountExcel(formData, (response) => {
                if (response) {
                    if (!response.success) {
                        toast.current.show({severity: 'info', summary: 'Success', detail: 'File Uploaded'});
                        setIsLoading(false);
                        setFile(null);
                        onTemplateClear();
                    }
                } else {
                    toast.current.show({severity: 'error', summary: 'Error', detail: 'No file has been uploaded or the uploaded file is incorrect.'});
                    setIsLoading(false);
                    onTemplateClear();
                }
            });
        } catch (error) {
            console.log(error);
        }
    };
    

    const headerTemplate = (options) => {
        const {className, chooseButton, uploadButton, cancelButton} = options;
        const value = totalSize / 10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                {/*{uploadButton}*/}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 1 MB</span>
                    <ProgressBar value={value} showValue={false} style={{width: '10rem', height: '12px'}}></ProgressBar>
                </div>
            </div>
        );
    };


    const itemTemplate = (file, props) => {
        

        return (
            <>
                {isLoading ? (
                    <div className="tw-flex tw-h-full tw-flex-col md:tw-justify-center tw-justify-center tw-items-center">
                        <Loader />
                        <p className="mt-2">Please wait a few seconds...</p>
                    </div>
                ) : (
                    <>
                        
                        <div className="flex align-items-center flex-wrap">
                            <div className="flex align-items-center" style={{ width: '40%' }}>
                                <img
                                    alt={file.name}
                                    role="presentation"
                                    src="assets/images/xlx-icon.png"
                                    width={100}
                                />
                                <span className="flex flex-column text-left ml-3">
                                    {file.name}
                                    <small className="ml-4">{new Date().toLocaleDateString()}</small>
                                </span>
                            </div>
                            <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                            <Button
                                type="button"
                                icon="pi pi-times"
                                className="p-button-outlined p-button-rounded p-button-danger ml-auto"
                                onClick={() => onTemplateRemove(file, props.onRemove)}
                            />
                            <br />
                            <Button label="Submit" onClick={customUploader} />
                        </div>
                    </>
                )}
            </>
        );
    };
    

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-image mt-3 p-5" style={{
                    fontSize: '5em',
                    borderRadius: '50%',
                    backgroundColor: 'var(--surface-b)',
                    color: 'var(--surface-d)'
                }}></i>
                <span style={{fontSize: '1.2em', color: 'var(--text-color-secondary)'}} className="my-5">
                    Drag and Drop File Here
                </span>
            </div>
        );
    };

    const chooseOptions = {
        icon: 'pi pi-fw pi-file',
        iconOnly: true,
        className: 'custom-choose-btn p-button-rounded p-button-outlined'
    };
    const uploadOptions = {
        icon: 'pi pi-fw pi-cloud-upload',
        iconOnly: true,
        className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined',
    };
    const cancelOptions = {
        icon: 'pi pi-fw pi-times',
        iconOnly: true,
        className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined'
    };

    return (
        <>
            <Sidebar/>
            <main className="mainAdmin r ms-100">
                <AdminHeader location="Account Synchronization"/>
                <div className="ml-2 mr-2">
                    <Toast ref={toast}></Toast>

                    <Tooltip target=".custom-choose-btn" content="Choose" position="bottom"/>
                    <Tooltip target=".custom-upload-btn" content="Upload" position="bottom"/>
                    <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom"/>

                    <FileUpload ref={fileUploadRef} name="excel_file"
                                url={upload}
                        //multiple
                        //accept="image/*"
                                accept=".xlsx,.xls"
                                maxFileSize={1000000}
                                onUpload={onTemplateUpload}
                                onSelect={onTemplateSelect} onError={onTemplateClear}
                                onClear={onTemplateClear}
                                headerTemplate={headerTemplate}
                                itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                                chooseOptions={chooseOptions} uploadOptions={uploadOptions}
                                cancelOptions={cancelOptions}
                                // key={isFileUploadReset}
                                
                                key={resetKey}
                                customUpload={customUploader}
                                uploadHandler={onTemplateUpload}
                                //uploadHandler={customBase64Uploader}
                    />
                </div>
            </main>
        </>
    )
}





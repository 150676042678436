import React, {Component} from "react";
import './PageBanner.css';

class PageBanner extends Component {
    render() {
        return (
            <div>

                <div className="banneheight section page-banner">

                    {/*<div className="slider-shape">*/}
                    {/*    <img className="shape-1 animation-round" src="assets/images/shape/shape-8.png" alt="Shape"/>*/}

                    {/*</div>*/}
                    <div className="container">
                        {/* <div className="page-banner-content">
                                   <br></br>
                                    <ul className="breadcrumb">
                                        <li><Link to="/" className="blue">Home</Link></li>
                                        <li className="active">{this.props.location} </li>
                                    </ul>
                                   
                                </div> */}
                    </div>

                    <div className="shape-icon-box">

                        {/*<img className="icon-shape-1 animation-left" src="assets/images/shape/shape-5.png"*/}
                        {/*     alt="Shape" />*/}

                        {/*<div className="box-content">*/}
                        {/*    <div className="box-wrapper">*/}
                        {/*        <i className="flaticon-badge"></i>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<img className="icon-shape-2" src="assets/images/shape/shape-6.png" alt="Shape" />*/}

                    </div>


                    {/*<img className="shape-author" src="assets/images/author/author-11.jpg" alt="Shape" />*/}

                </div>
            </div>


        )
    }
}

export default PageBanner;
import React from 'react';
import {Link} from 'react-router-dom';

const SubHeaderItem = ({label, href, handleOnClik}) => {
    return (
        <>
            <Link
                onClick={handleOnClik}
                to={href}
                className="tw-font-bold tw-text-primary hover:tw-underline"
                style={{
                    fontFamily: "Advent Pro",
                    fontWeight: 700,
                }}
            >
                {label}
            </Link>
        </>
    );
};

export default SubHeaderItem;
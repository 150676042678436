import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";

const Input = forwardRef(
  (
    {
      label,
      name,
      id,
      type,
      value,
      disabled = false,
      placeHolder,
      icon,
      error,
      onChange,
      onBlur,
      required=false,
      showPassword = false,
      handleShowPassword
    },
    ref
  ) => {
    const inputClasses = `tw-block tw-w-full tw-h-[36px] md:tw-h-[40px] tw-rounded-lg tw-border-0 tw-py-1.5 ${
      icon ? "tw-pl-12" : "tw-pl-4"
    } tw-pr-4 ${
      disabled ? "tw-text-gray-600 tw-bg-gray-200" : "tw-text-primary"
    } tw-ring-2 ring-inset ${
      error
        ? "tw-ring-red-300 focus:tw-ring-red-300 tw-text-red-400"
        : "tw-ring-gray-300 focus:tw-ring-primary-700"
    } placeholder:tw-text-gray-300 tw-text-xs sm:tw-text-sm tw-leading-6 tw-outline-none focus:tw-caret-primary`;

    return (
      <>
        {label && (
          <label
            htmlFor={id}
            className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-primary"
          >
            {label} {required && <span className="tw-text-red-600">*</span>} :
          </label>
        )}
        <div
          className={`tw-relative tw-mt-1 ${
            !disabled && "tw-text-gray-300"
          } tw-rounded-[1.5px] tw-shadow-sm`}
        >
          {icon && (
            <div className="tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3 tw-pointed-events-none">
              <i
                className={`${icon} tw-ml-1`}
                style={{ fontSize: "1.2rem" }}
              ></i>
            </div>
          )}
          <input
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
            type={showPassword ? "text" : type}
            name={name}
            value={value}
            id={id}
            className={inputClasses}
            placeholder={placeHolder}
            disabled={disabled}
            style={{ cursor: `${disabled ? "not-allowed" : "text"}` }}
            required={required}
          />
          {error && (
            <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3 tw-text-red-300 tw-pointed-events-none">
              <i
                className="tw-ml-1 tw-rotate-180 pi pi-info-circle"
                style={{ fontSize: "1.5rem" }}
              ></i>
            </div>
          )}
          {type === "password" && !error && (
            
            <span
              onClick={handleShowPassword}
              class="tw-absolute tw-inset-y-0 tw-end-0 tw-grid tw-place-content-center tw-px-4 tw-cursor-pointer"
            >
              {showPassword ? (
                <i
                  className="pi pi-eye-slash"
                  style={{ fontSize: "1.5rem" }}
                ></i>
              ) : (
                <i className="pi pi-eye" style={{ fontSize: "1.5rem" }}></i>
              )}
            </span>
          )}
        </div>
        {error && (
          <span className="tw-ml-1 tw-text-sm tw-text-red-300">{error}</span>
        )}
      </>
    );
  }
);

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  placeHolder: PropTypes.string,
  icon: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default Input;

const ButtonCustom = ({
  label,
  type,
  icon = null,
  iconR = null,
  disabled = false,
  onClick,
  classes,
  value = null,
}) => {
  return (
    <>
      <button
        className={`tw-flex sm:tw-h-[45px] sm:tw-text-[18px] tw-text-sm tw-justify-center tw-items-center tw-w-full tw-transition tw-ease-in-out tw-delay-100 tw-duration-200 tw-rounded-[9px] tw-px-4 tw-py-2  tw-font-bold tw-shadow-sm focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 ${classes} tw-truncate ...`}
        style={{
          fontFamily: "Advent Pro",
          fontWeight: 700,
          cursor: `${disabled ? "not-allowed" : "pointer"}`,
        }}
        disabled={disabled}
        onClick={onClick}
        type={type}
        value={value}
      >
        {icon && (
          <i className={`tw-mr-2 ${icon}`} style={{ fontSize: "1.2rem" }}></i>
        )}
        {label}
        {iconR && (
          <i className={`tw-ml-2 ${iconR}`} style={{ fontSize: "1.2rem" }}></i>
        )}
      </button>
    </>
  );
};

export default ButtonCustom;

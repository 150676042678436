import { useState } from "react";
import ButtonCustom from "../button/ButtonCustom";
import { Link } from "react-router-dom";
import CardXLarge from "../card/CardXLarge";
import { WELCOMING } from "../../../../constants/images";

const Welcome = ({existingSessionTitle, existingSessionMessage, existingSessionButton, link, type="sessions"}) => {
  /**
   * States
   */
  // eslint-disable-next-line
  let [showSessionsSubscribed, setShowSessionsSubscribed] = useState(false);
  const sessionsSubscribed = [1, 1, 1, 1, 1, 1];

  /**
   * Comportment
   */
  const handleClick = () => {
    setShowSessionsSubscribed(true);
  };
  const handleOnClickItem = () => {
    //
  };

  /**
   * Render
   */
  return (
    <>
      <div className="sm:tw-grid tw-grid-cols-1 md:tw-grid-cols-2 md:tw-mt-16 tw-w-full tw-h-full">
        <div className="tw-flex tw-items-center tw-justify-center tw-pr-9 ">
          <img
            className="md:tw-max-w-[259px] sm:tw-max-h-[296px] tw-object-center tw-hidden sm:tw-block"
            src={WELCOMING}
            alt="welcome"
          />
        </div>
        <div className=" tw-flex-col tw-items-center tw-mt-8 sm:tw-text-left sm:tw-px-6 tw-text-center md:text-center sm:tw-mt-0 tw-text-primary">
          <h1
            className="tw-text-[18px] tw-font-medium tw-text-primary lg:tw-text-3xl md:tw-text-xl"
            onClick={handleClick}
          >
            Welcome to Utrains. <br /> {existingSessionTitle ? existingSessionTitle : type === "sessions" ? "You have not yet subscribed to any session" : "You have not yet subscribed to any individual course"}.
          </h1>
          <p className="max-w-lg tw-mt-2 text-[14px] sm:tw-text-[18px] tw-text-gray-600">
            {existingSessionMessage ? existingSessionMessage :"Please click on the button below to subscribe"}.
          </p>
          <div className="tw-flex tw-items-center tw-justify-center tw-mt-12 lg:tw-justify-start">
            <Link to={link}>
              <ButtonCustom
                classes={
                  "tw-bg-primary hover:tw-bg-primary-900 tw-text-white tw-max-w-[194px]"
                }
                label={existingSessionButton? existingSessionButton :"Enroll Now"}
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;

import React, {useEffect, useState} from "react";
import MainLayout from "../../../../../layouts/MainLayout";
import SubHeader from "../../../../../components/frontv2.0/common/header/SubHeader";
import Header from "../../../../../components/frontv2.0/common/header/Header";
import {getUser} from "../../../../../network/auth/Auth";
import CourseService from "../../../../../network/services/CourseService";
import Welcome from "../../../../../components/frontv2.0/common/welcome/Welcome";
import CardCourse from "../../../../../components/frontv2.0/common/card/CardCourse";
import CourseLoader from "../../../../../components/frontv2.0/common/loader/CourseLoader";
import {useNavigate} from "react-router";
import SessionService from "../../../../../network/services/SessionService";

const MyCourses = () => {
    const navigate = useNavigate();
    const [response, setResponse] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sessionsData, setSessionsData] = useState([]);

    const showCourse = (id) => {
        let data = {
            userId: id,
        };
        CourseService.getUserCourse(data, (response) => {
            console.log(response)
            if (response && response.success) {
                let data = response.subscription;
                console.log(data)
                if (data.length > 0) {
                    let tabCourses = data.filter((e) => e.course !== null);
                    console.log(tabCourses)
                    setResponse(tabCourses);
                }
            }
            setIsLoading(false);
        });
    };

    const existingSessionTitle = "You don't have any individual course.";
    const existingSessionMessage = "You can access your session's courses by clicking on the button below.";
    const existingSessionButton = "Go to My sessions";

    const loadData = () => {
        let user = getUser();
        showCourse(user.id);
        SessionService.getUserSessions(user.id, (data) => {
            console.log(data)
            if (data && data.success) {
                setSessionsData(
                    data.subscriptions.filter((e) => e.subscription.session !== null)
                );
            }
        })
    };

    const handleOnClickItem = (id) => {
        return navigate(`/workspace/mycourses/details?courseId=${id}`);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        loadData();
        setIsLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <MainLayout>
            <MainLayout.Content>
                <div className="tw-w-full tw-py-auto">
                    {!isLoading ? (
                        response.length > 0 ? (
                            <div
                                className="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 2xl:tw-grid-cols-5">
                                {response.map((item, key) => {
                                    return (
                                        <CardCourse
                                            subscribe={true}
                                            key={key}
                                            onClickAction={handleOnClickItem}
                                            data={item.course}
                                        />
                                    );
                                })}
                            </div>
                        ) : (
                            sessionsData.length >= 1 ?
                                <div className="sm:tw-flex">
                                    <Welcome existingSessionTitle={existingSessionTitle}
                                             existingSessionMessage={existingSessionMessage}
                                             existingSessionButton={existingSessionButton}
                                             link={"/workspace/mysessions"}/>
                                </div>
                                :
                                <div className="sm:tw-flex">
                                    <Welcome link={"/workspace/courses"} type="course"/>
                                </div>
                        )
                    ) : (
                        <div
                            className="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4">
                            {[1, 2, 3, 4, 5, 6, 7, 8].map(() => {
                                return <CourseLoader/>;
                            })}
                        </div>
                    )}
                </div>
            </MainLayout.Content>

            <MainLayout.Header>
                <Header/>
            </MainLayout.Header>

            <MainLayout.SubHeader>
                <SubHeader
                    data={[
                        {value: "Home", url: "/workspace"},
                        {
                            value: "My Courses",
                            url: "#",
                        },
                    ]}
                />
            </MainLayout.SubHeader>
        </MainLayout>
    );
};

export default MyCourses;

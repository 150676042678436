import { useEffect, useRef, useState } from "react";
import InputCustom from "../form/InputCustom";
import { getUser } from "../../../../network/auth/Auth";
import UserService from "../../../../network/services/UserService";
import ButtonCustom from "../button/ButtonCustom";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { PASSWORD_REGEX } from "../../../../constants/Constants";

const UserEditPassword = () => {
  const [state, setState] = useState({
    user_id: "",
    currentPassword: "",
    newPassword: "",
    newConfirmedPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const validatePassword = (password) => {
    return PASSWORD_REGEX.test(password);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toast = useRef(null);

  const show = () => {
    setVisible(true);
  };

  const showSuccess = (message) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };

  const showError = (message) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const updateUserPassword = () => {
    if (
      state.currentPassword !== "" &&
      state.newPassword !== "" &&
      state.newConfirmedPassword !== ""
    ) {
      setIsLoading(true);
      if (state.newPassword !== state.newConfirmedPassword) {
        setVisible(false);
        showError("The two passwords you enter are different");
        setIsLoading(false);
        return;
      }
      if (!validatePassword(state.newPassword)) {
        setVisible(false);
        showError(
          "The password must be at least 6 characters and contain at least one uppercase letter, one lowercase letter and one number."
        );
        setIsLoading(false);
        return;
      }
      var data = {
        user_id: state.user_id,
        oldPassword: state.currentPassword,
        newPassword: state.newPassword,
      };
      UserService.updatePassword(state.user_id, data, (response) => {
        if (response.success) {
          setVisible(false);
          showSuccess("Password updated successfully");
          setIsLoading(false);
          setState({
            ...state,
            currentPassword: "",
            newPassword: "",
            newConfirmedPassword: "",
          });
        } else {
          setVisible(false);
          setIsLoading(false);
          showError("Your current password is incorrect");
        }
      });
    }
  };

  const loadData = () => {
    let user = getUser();
    setState({
      ...state,
      user_id: user.id,
    });
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const footerContent = (
    <div className="tw-flex tw-flex-row tw-space-x-4 ">
      <ButtonCustom
        label="Close"
        icon={"pi pi-times-circle"}
        onClick={() => setVisible(false)}
        autoFocus
        classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
      />
      {isLoading ? (
        <ButtonCustom
          classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
          label="Loading..."
          icon={"pi pi-spin pi-spinner"}
          disabled={true}
        />
      ) : (
        <ButtonCustom
          classes={"tw-text-white tw-bg-[#309255] hover:tw-bg-[#055021]"}
          label="Save Changes"
          icon={"pi pi-save"}
          onClick={updateUserPassword}
        />
      )}
    </div>
  );

  return (
    <>
      <Toast ref={toast} position="top-right" />
      <div className="tw-w-full tw-space-y-3 tw-my-4">
        <div>
          <span>Current password :</span>
          <InputCustom
            id={"currentPassword"}
            name={"currentPassword"}
            placeHolder={"Enter Your Current Password"}
            type={"password"}
            onChange={handleFormChange}
            value={state.currentPassword}
            showPassword={showPassword}
            handleShowPassword={handleShowPassword}
          />
        </div>
        <div>
          <span>New password :</span>
          <InputCustom
            id={"newPassword"}
            name={"newPassword"}
            placeHolder={"Enter Your New Password"}
            type={"password"}
            onChange={handleFormChange}
            value={state.newPassword}
            showPassword={showPassword}
            handleShowPassword={handleShowPassword}
          />
        </div>
        <div>
          <span>Confirm the new password:</span>
          <InputCustom
            id={"newConfirmedPassword"}
            name={"newConfirmedPassword"}
            placeHolder={"Confirm Your New Password"}
            type={"password"}
            onChange={handleFormChange}
            value={state.newConfirmedPassword}
            showPassword={showPassword}
            handleShowPassword={handleShowPassword}
          />
        </div>
      </div>

      <div className="tw-sticky tw-bottom-0 tw-z-10">
        <div className="tw-py-6">
          <div className="tw-w-full tw-py-3">
            <ButtonCustom
              classes={"tw-bg-primary hover:tw-bg-primary-900 tw-text-white"}
              label="Update Password"
              icon={"pi pi-sync"}
              disabled={
                !(
                  state.currentPassword.length > 0 &&
                  state.newConfirmedPassword.length > 0 &&
                  state.newPassword.length > 0
                )
              }
              onClick={() => show()}
            />
          </div>
        </div>
      </div>
      <div>
        <Dialog
          header="Confirmation"
          visible={visible}
          onHide={() => setVisible(false)}
          footer={footerContent}
          style={{ width: "25vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <p className="m-0">Are you sure you want to change your password?</p>
        </Dialog>
      </div>
    </>
  );
};

export default UserEditPassword;

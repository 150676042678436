import React from 'react';
import {LOGO_BLUE} from '../../../../constants/images';
import backendUrls from "../../../../network/urls/backendUrls";

const Logo = ({src, alt, width, height, classes}) => {
    return (
        <>
         <a href={backendUrls.Wordpress.root} >
            <img
                src={src ? src : LOGO_BLUE}
                alt={alt ? alt : "logo"}
                width={width}
                height={height}
                className={classes}
            />
            </a>
        </>
    );
};

export default Logo;
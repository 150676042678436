const routeAccounting = [
    {
        path: '/admin',
        name: 'Dashboard',
        icon: 'dashboard',
        section: 1
    },
    {
        path: '/sessions',
        name: 'Courses sessions',
        icon: 'school',
        section: 1
    },
    {
        path: '/plans',
        name: "Subscriptions plans",
        icon: 'subscriptions',
        section: 1
    },
    {
        path: '/subscription-intent',
        name: "subscription intents",
        icon: 'credit_score',
        section: 1
    },
    {
        path: '/search-user',
        name: 'Transactions',
        icon: 'list',
        section: 1
    },
    {
        path: '/currency',
        name: 'Currency',
        icon: 'local_convenience_store',
        section: 3
    },
    {
        path: '/channels',
        name: 'Channels',
        icon: 'subscriptions',
        section: 3
    },
    {
        path: '/configure-login-register',
        name: 'Authentication Setup',
        icon: 'list',
        section: 3
    },

];
export default routeAccounting;

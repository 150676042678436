import React from "react";
import { IMAGE_4 } from "../../../../constants/images";
import { MEDIA_URL_ACCOUNTING } from "../../../../network/urls/backendUrls";
import ButtonCustom from "../button/ButtonCustom";

const CardCourse = ({ data, onClickAction, subscribe = false }) => {
  /**
   * States
   */

  /**
   * Comportments
   */
  const handleOnClick = () => {
    onClickAction(data.id);
  };

  const encodeUrl = (url) => {
    return url.replace(/ /g, "%20");
  };

  const shortenText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    } else {
      return text;
    }
  };

  /**
   * Render
   */
  return (
    <div
      className={`tw-rounded-[12px] tw-shadow-md tw-min-w-[220px] tw-text-primary tw-bg-accent-gray tw-w-full hover:tw-bg-primary-200 hover:tw-transition hover:tw-ease-in-out hover:tw-delay-150 hover:tw-duration-250`}
    >
      <img
        className="tw-shadow-sm tw-bg-cover tw-bg-center tw-object-cover tw-rounded-[12px] tw-h-[221px] tw-w-full"
        src={
          data.image === null
            ? IMAGE_4
            : encodeUrl(`${MEDIA_URL_ACCOUNTING}/courses/${data.image}`)
        }
        alt="session"
      />

      <div className="tw-flex tw-flex-col sm:tw-justify-between tw-p-4">
        <div className="tw-mb-2 tw-flex tw-flex-col">
          {subscribe && (
            <div>
              <span className="tw-text-green-700 tw-bg-accent-green tw-px-2 tw-py-1 tw-font-bold tw-rounded-[16px] tw-text-xs tw-border tw-mr-3">
                Registered
              </span>
            </div>
          )}
          <span className="tw-font-bold tw-text-lg">
            {shortenText(data?.name ?? "", 20)}
          </span>
          <span className="tw-text-sm tw-text-gray-500 tw-text-justify">
            {data?.description !== "" ? shortenText(data?.description, 90) : ""}
          </span>
        </div>
        <div className="tw-flex tw-justify-end tw-items-end tw-w-full">
          <ButtonCustom
            onClick={handleOnClick}
            classes={
              "tw-bg-primary hover:tw-bg-primary-900 tw-text-white w-[137px]"
            }
            label={subscribe ? "View Details" : "Subscribe"}
          />
        </div>
      </div>
    </div>
  );
};

export default CardCourse;
